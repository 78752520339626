import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import BroadcastActions from '../component/BroadcastActions';
import BroadcastLeads from '../component/BroadcastLeads';
import DataCard from '../component/DataCard';
import { convertSecondsToMinSeconds } from '../../../common/utility/commonUtils';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import VoiceApiExportListPopup from '../component/VoiceApiExportListPopup';
const moment = require('moment-timezone');
const voiceApiUrl = process.env.REACT_APP_VOICE_API;

export function VoiceApiList(props) {
    let userRole = localStorage.getItem('doosra-biz-user-role');
    let organisationId = localStorage.getItem('doosra-biz-organisation-id');
    const [campaignsList, setCamapignsList] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [searchFor, setSearchFor] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [campaignsCount, setCampaignsCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [broadcastInfo, setBroadcastInfo] = useState({});
    const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
    const [metricsData, setMetricsData] = useState(null);
    const [filters, setFilters] = useState({});
    const history = useHistory();
    const rowCount = 15;

    const tabs = [];
    if (userRole === 'ADMIN') {
        tabs.push({
            title: "Voice API's List",
            onClick: () => props.history.push('/voice-api-list'),
            active: true,
        });
    }

    const searchHandler = async (data) => {

        setFilters(data);

        setSearchFor(searchFor);
        if (data?.searchKey === 'name') {
            setSearchValue(data?.searchCondition);
        }
        await fetchCampaignListHandler(1, data);
        if (data.search_key !== 'name') {
            await fetchVoiceApiMetrics(data);
        }
    };

    const fetchCampaignListHandler = async (pageNo, data) => {
        let input = {
            pageNo, pageSize: rowCount, searchFor: data?.search_key,
            searchValue: data?.searchCondition, organisationId
        };
        if (data?.searchKey === 'date') {
            input.from_date = data?.from_date;
            input.to_date = data?.to_date;
        }
        console.log(voiceApiUrl);
        setLoading(true);
        GET(voiceApiUrl + 'v1/voice/list', input)
            .then((res) => {
                if (searchFor) {
                    setSearchFor(searchFor);
                } else {
                    setSearchFor(null);
                }
                if (searchValue) {
                    setSearchValue(searchValue);
                } else {
                    setSearchValue(null);
                }
                setPageNo(pageNo);
                setCamapignsList(res?.data?.response?.list?.list);
                setCampaignsCount(res?.data?.response?.list?.count);

                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.error?.reason || 'An error occured');
            });
    };

    const fetchVoiceApiMetrics = async (data) => {
        let input = { searchFor: data?.search_key || "", organisationId };
        if (data?.search_key === 'date') {
            input.from_date = data?.from_date;
            input.to_date = data?.to_date;
        } else {
            input.searchValue = data?.searchCondition;
        }
        input.organisationId = organisationId;
        if (!input.searchFor) {
            delete input?.searchFor;
        }
        setLoading(true);
        GET(voiceApiUrl + 'v1/voice/metrics', input)
            .then((res) => {
                if (searchFor) {
                    setSearchFor(searchFor);
                } else {
                    setSearchFor(null);
                }
                if (searchValue) {
                    setSearchValue(searchValue);
                } else {
                    setSearchValue(null);
                }
                setMetricsData(res?.data?.response);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.error?.reason || 'An error occured');
            });
    };

    const resetHandler = async () => {
        await fetchCampaignListHandler(1, null);
        await fetchVoiceApiMetrics(null);
        setFilters({});
    };

    useEffect(() => {
        fetchCampaignListHandler(1, null);
    }, []);

    useEffect(() => {
        fetchVoiceApiMetrics(null);
    }, []);
    return (
        <div className={`${props.className}`}>
            <Sidebar />
            <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
                <Topbar />
                <div className="ContentContainer">
                    <TabSearchBar tabs={tabs}>
                        {isSuperAdminWithPermission('ADD') && <button
                            className="Otherbutton"
                            style={{ backgroundColor: '#07aa3f', color: 'white' }}
                            onClick={() => props.history.push('/create-voice-api')}
                        >
                            CREATE
                        </button>
                        }
                        <VoiceApiExportListPopup filters={filters} />
                        <SearchBar
                            type="voice-broadcast"
                            count={campaignsCount}
                            disableTitle
                            reset={resetHandler}
                            search={searchHandler}
                        />
                    </TabSearchBar>
                    {loading && <PreLoader />}
                    <BroadcastLeads
                        info={broadcastInfo}
                        open={isInfoPopupOpen}
                        close={() => {
                            setBroadcastInfo({});
                            setIsInfoPopupOpen(false);
                        }}
                    />
                    <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
                        <DataCard
                            title="Total campaigns"
                            count={metricsData?.totalCampaigns.toLocaleString('en-IN')}
                        />
                        <DataCard title="Total contacts" count={metricsData?.totalLeads.toLocaleString('en-IN')} />
                        <DataCard
                            title="Total answered calls"
                            count={metricsData?.totalConnectedCalls.toLocaleString('en-IN')}
                        />
                    </div>
                    <div style={{ display: 'flex', gap: '32px', padding: '0 16px 16px' }}>

                        <DataCard
                            title="Average call duration"
                            count={convertSecondsToMinSeconds(Math.floor(metricsData?.averageDuration / metricsData?.totalConnectedCalls) || 0)}
                        />
                        <DataCard
                            title="Total No. of calls under 3 seconds"
                            count={metricsData?.totalCallsUnder3Sec.toLocaleString('en-IN')}
                        />
                        <DataCard
                            title="Total No. of calls made"
                            count={metricsData?.totalCalls.toLocaleString('en-IN')}
                        />
                    </div>
                    <DataTable.Table>
                        <DataTable.Head>
                            <DataTable.CellHead width="20">#</DataTable.CellHead>
                            <DataTable.CellHead width="100">CAMPAIGN NAME</DataTable.CellHead>
                            <DataTable.CellHead width="140">DESCRIPTION</DataTable.CellHead>
                            <DataTable.CellHead width="140">CAMPAIGN ID</DataTable.CellHead>
                            <DataTable.CellHead width="40">TOTAL LEADS</DataTable.CellHead>
                            <DataTable.CellHead width="40">LEADS CALLED</DataTable.CellHead>
                            <DataTable.CellHead width="40">ANSWERED</DataTable.CellHead>
                            <DataTable.CellHead width="40">DTMF COUNT</DataTable.CellHead>
                            <DataTable.CellHead width="40">UNANSWERED</DataTable.CellHead>
                            <DataTable.CellHead width="40">CREATED AT</DataTable.CellHead>
                            <DataTable.CellHead width="40" style={{ textAlign: 'end' }}>
                                ACTIONS
                            </DataTable.CellHead>
                        </DataTable.Head>
                        <DataTable.Body>
                            {campaignsList?.length >= 0 &&
                                campaignsList.map((item, index) => (
                                    <DataTable.Row key={item._id}>
                                        <DataTable.Cell>{index + 1}</DataTable.Cell>
                                        <DataTable.Cell
                                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                            onClick={() => {
                                                history.push(`/voice-api-details?id=${item._id}`);
                                            }}
                                        >
                                            {item?.name}
                                        </DataTable.Cell>
                                        <DataTable.Cell>{item?.description}</DataTable.Cell>
                                        <DataTable.Cell>{item?._id}</DataTable.Cell>
                                        <DataTable.Cell>{item?.total_leads}</DataTable.Cell>
                                        <DataTable.Cell>{item?.leads_called}</DataTable.Cell>
                                        <DataTable.Cell>{item?.leads_answered}</DataTable.Cell>
                                        <DataTable.Cell>{item?.dtmfCount}</DataTable.Cell>
                                        <DataTable.Cell>{item?.leads_unanswered}</DataTable.Cell>
                                        <DataTable.Cell>{item?.createdAt
                                            ? moment(item?.createdAt).format(
                                                'DD/MM/YYYY, h:mm:ss a'
                                            )
                                            : '-'}</DataTable.Cell>
                                        <DataTable.Cell
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: '8px',
                                            }}
                                        >
                                            {(item.status === 'INPROGRESS' ||
                                                item.status === 'SCHEDULED') && (
                                                    <BroadcastActions
                                                        id={item._id}
                                                        status={item.status}
                                                        refetch={() => { fetchCampaignListHandler(1, filters) }}
                                                    />
                                                )}
                                        </DataTable.Cell>
                                    </DataTable.Row>
                                ))}
                        </DataTable.Body>
                    </DataTable.Table>
                    <Paginator
                        currentPage={pageNo}
                        lastPage={Math.ceil(campaignsCount / 15)}
                        getInfo={(value) => fetchCampaignListHandler(value, filters)}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    sidebarClosed: state.sidebar.sidebarClosed,
    userDetails: state.recovery.userDetails,
    isVoiceApiEnabled: state.topbar.isVoiceApiEnabled,
});

export default commonStyle(connect(mapStateToProps, {})(VoiceApiList));

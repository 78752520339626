import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import './Tooltip.css';
class DeactivateNumberConfirmationPopup extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ popupOpen: true });
  };
  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  render() {
    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Deactivate Number?</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupBodyTitle">
              Are you sure you want to deactivate this number?
            </p>
            <p className="PopupNote">
              Once deactivated, you will not be able to receive or view OTPs,
              SMSs from this number in the dashboard.
            </p>
            <button
              className="ButtonFullWidth BgRed"
              onClick={this.props.deactivate}
            >
              CONFIRM
            </button>
          </div>
        </div>
      </Popup>
    );

    return (
      <span>

        {!this?.props?.numberDetails?.ivr_id &&
          <div className="tooltip-container">
            <div className="tooltip-button">
              <button onClick={this.openPopup}
                className={this.props.nonIncomingDid || !isSuperAdminWithPermission('EDIT') ? "DisableButton" : "DeactivateButton"}
                disabled={this.props.nonIncomingDid || !isSuperAdminWithPermission('EDIT')}
              >
                DEACTIVATE
              </button>
            </div>
            {this.props.nonIncomingDid && <div className="tooltip-text left" data-tip-position="left">
              For outgoing calls only
              < span className="arrow-right"></span>
            </div>}
          </div>}
        {popper}
      </span >
    );
  }
}

export default DeactivateNumberConfirmationPopup;
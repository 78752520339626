import React, { Component } from 'react';
import RightArrow from '../../../assets/images/rightArrow.png';
import Logo from '../../../assets/images/alohaaLogoAndBottomText.png';
import Wall from '../../../assets/images/loginPageWall.png'
import '../../../assets/css/LoginNew.css';
class AccountDeactivatedPage extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="login-main">
        <div className="section-1">
          <div className='login-container'>
            <img className='login-container-logo' alt="" src={Logo} />
            <div className="AuthHeadContainer">
              {/* <p className="DoosraBusinessHeader">
                <span className="DoosraDefaultColor"></span>alohaa
              </p> */}
              <p className="AuthSubHeader">Your account has been deactivated</p>
              <p>
                Please contact support@alohaa.ai or your account manager for
                help.
              </p>
            </div>
            <form>
              <div className="SignIntoDashboardContainer">
                <p
                  className="SignIntoDashboard"
                  style={{ textAlign: 'center' }}
                >
                  CONTACT SUPPORT
                </p>
                <img
                  alt=""
                  className="SignIntoRightArrowIcon"
                  src={RightArrow}
                />
              </div>
            </form>
          </div>
        </div>
        <div>
          <img src={Wall} style={{ height: '100vh' }} />
        </div>
      </div>
    );
  }
}

export default AccountDeactivatedPage;

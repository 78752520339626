import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Logo from '../../../assets/images/alohaaLogoAndBottomText.png';
import Success from '../../../assets/images/greenSuccessBig.png';
import GreenTick from '../../../assets/images/greenTickSmall.png';
import InfoRed from '../../../assets/images/infoRed.png';
import invisible from '../../../assets/images/invisibleEye.png';
import RightArrow from '../../../assets/images/rightArrow.png';
import visible from '../../../assets/images/visibleEye.png';
import { POST, PUT } from '../../../services/HttpRequests';
import { generateRecaptcha } from '../../../services/Recaptcha';
import PasswordCheck from '../../../common/component/passwordCheck/PasswordCheckComponent';
import Wall from '../../../assets/images/loginPageWall.png';
import '../../../assets/css/LoginNew.css';
const userUrl = process.env.REACT_APP_USER_API;
class AdminSignupPage extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,

      adminName: '',
      adminNumber: '',
      adminEmail: '',

      otp: '',
      token: '',
      passwordVisible: false,
      confPasswordVisible: false,
      password: '',
      confPassword: '',
      otpResent: false,
      error: '',
    };
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    let recaptcha = await generateRecaptcha('BUSINESS_ADMIN_SIGNUP');
    const admin_id = query.get('id');
    if (
      localStorage.getItem('doosra-business-sessionToken') &&
      localStorage.getItem('doosra-business-sessionToken') !== '' &&
      localStorage.getItem('doosra-biz-user-id') === admin_id
    ) {
      this.props.history.push('/call-logs');
    }
    if (
      localStorage.getItem('doosra-business-sessionToken') &&
      localStorage.getItem('doosra-business-sessionToken') !== '' &&
      localStorage.getItem('doosra-biz-user-id') !== admin_id
    ) {
      localStorage.removeItem('doosra-business-sessionToken');
      localStorage.removeItem('doosra-biz-organisation-id');
      localStorage.removeItem('doosra-biz-user-id');
      localStorage.removeItem('doosra-biz-user-name');
      localStorage.removeItem('doosra-biz-user-email');
      localStorage.removeItem('doosra-biz-user-number');
      localStorage.removeItem('doosra-biz-user-role');
      localStorage.removeItem('doosra-biz-organisation-name');
      localStorage.removeItem('is_sms_webhook_enabled');
      localStorage.removeItem('is_call_webhook_enabled');
      localStorage.removeItem('is_voice_api_webhook_enabled');
      this.props.history.push(`/signup/admin?id=${admin_id}`);
    }
    PUT(`${userUrl}v1/user/verify-email`, {}, { id: admin_id, is_user: false })
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        let step = 1;
        let token = '';
        if (res.user.is_email_verified) step = 1;
        if (res.user.is_mobile_verified) step = 3;
        token = res.user.token;
        if (res.user.is_password_set) step = 4;
        token = res.user.token;
        this.setState({
          adminName: res.user.name,
          adminEmail: res.user.email,
          adminNumber: res.user.phone_number,
          orgId: res.user.organisation_id,
          adminId: res.user.user_id,
          step: step,
          token: token,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  passwordVisiblityToggleHandler = (second) => {
    if (second) {
      this.setState({ confPasswordVisible: !this.state.confPasswordVisible });
    } else {
      this.setState({ passwordVisible: !this.state.passwordVisible });
    }
  };

  backHandler = (e) => {
    e.preventDefault();
    this.setState({ step: this.state.step - 1 });
  };

  submitDetails = async (e) => {
    if (e) e.preventDefault();
    // let recaptcha = await generateRecaptcha('BUSINESS_ADMIN_SIGNUP_DETAILS');
    let reqBody = {
      name: this.state.adminName,
      email: this.state.adminEmail,
      phone_number: this.state.adminNumber,
      organisationId: this.state.orgId,
      userId: this.state.adminId,
      is_user: false,
    };
    POST(`${userUrl}v1/user/signup/send-otp`, reqBody)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({ step: 2, error: '' });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.code == 401
        ) {
          this.setState({ step: 3 });
        }
      });
  };

  resendOTPHandler = (e) => {
    e.preventDefault();
    this.submitDetails();
    this.setState({ otpResent: true });
  };

  otpHandler = async (e) => {
    e.preventDefault();
    // let recaptcha = await generateRecaptcha('BUSINESS_ADMIN_SIGNUP_OTP');
    let reqBody = {
      phone_number: this.state.adminNumber,
      otp: this.state.otp,
      organisationId: this.state.orgId,
      userId: this.state.adminId,
      is_user: false,
    };
    POST(`${userUrl}v1/user/signup/verify-otp`, reqBody)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({ step: 3, token: res.token, error: '' });
      })
      .catch((err) => {
        if (err?.response?.data?.error) {
          if (err.response.data.error.code == 401) {
            this.setState({ step: 3 });
          } else {
            this.setState({ error: err.response.data.error.reason });
          }
        }
      });
  };

  submitPassword = async (e) => {
    e.preventDefault();
    let recaptcha = await generateRecaptcha('BUSINESS_ADMIN_SIGNUP_SET');
    let reqBody = {
      password: this.state.password,
      confirm_password: this.state.confPassword,
      token: this.state.token,
      organisationId: this.state.orgId,
      userId: this.state.adminId,
      is_user: false,
    };
    POST(`${userUrl}v1/user/signup/set-password`, reqBody)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        if (res.result === 'password set successfully') {
          this.setState({ step: 4 });
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({ error: err.response.data.error.reason });
        }
      });
  };

  redirectToLogin = () => {
    this.props.history.push('/login');
  };

  render() {
    let CurrentScreen = '';
    switch (this.state.step) {
      case 4:
        CurrentScreen = (
          <React.Fragment>
            <div className="AuthHeadContainer">
              {/* <p className="DoosraBusinessHeader">
                  <span className="DoosraDefaultColor"></span>alohaa
                </p> */}
              <p className="AuthSubHeader">Signup complete!</p>
            </div>
            <form>
              <div className="AuthSuccessHolder">
                <img alt="" src={Success} />
              </div>
              <Link to="/login">
                <div className="SignIntoDashboardContainer">
                  <p className="SignIntoDashboard">SIGN INTO THE DASHBOARD</p>
                  <img className="SignIntoRightArrowIcon" src={RightArrow} />
                </div>
              </Link>
            </form>
          </React.Fragment>
        );
        break;
      case 3:
        CurrentScreen = (
          <React.Fragment>
            <div className="AuthHeadContainer">
              {/* <p className="DoosraBusinessHeader">
                  <span className="DoosraDefaultColor"></span>alohaa
                </p> */}
              <p className="AuthSubHeader">Create a password</p>
            </div>
            <form onSubmit={this.submitPassword}>
              <div className="PasswordInputHolder">
                <input
                  id="password"
                  className="PasswordInput"
                  value={this.state.password}
                  onChange={this.handleChange}
                  type={this.state.passwordVisible ? 'text' : 'password'}
                  placeholder="Enter your new password"
                />
                {this.state.passwordVisible ? (
                  <img
                    onClick={() => this.passwordVisiblityToggleHandler()}
                    className="PasswordInvisibleToggle"
                    src={invisible}
                  />
                ) : (
                  <img
                    onClick={() => this.passwordVisiblityToggleHandler()}
                    className="PasswordVisibleToggle"
                    src={visible}
                  />
                )}
              </div>
              <div className="PasswordInputHolder">
                <input
                  id="confPassword"
                  className="PasswordInput"
                  onChange={this.handleChange}
                  type={this.state.confPasswordVisible ? 'text' : 'password'}
                  placeholder="Re-enter your new password"
                />
                {this.state.confPasswordVisible ? (
                  <img
                    onClick={() => this.passwordVisiblityToggleHandler(true)}
                    className="PasswordInvisibleToggle"
                    src={invisible}
                  />
                ) : (
                  <img
                    onClick={() => this.passwordVisiblityToggleHandler(true)}
                    className="PasswordVisibleToggle"
                    src={visible}
                  />
                )}
              </div>

              <PasswordCheck password={this.state.password} />
              <div className="ErrorContainer ErrorContainerMargin">
                {this.state.error ? (
                  <React.Fragment>
                    <img
                      src={InfoRed}
                      style={{
                        paddingRight: '5px',
                        position: 'relative',
                        top: '-2px',
                      }}
                    />
                    {this.state.error}
                  </React.Fragment>
                ) : null}
              </div>

              <button className="AuthButton" type="submit">
                NEXT
              </button>
              <button
                className="AuthButton AuthButtonInvert"
                onClick={this.backHandler}
              >
                BACK
              </button>
            </form>
          </React.Fragment>
        );
        break;
      case 2:
        CurrentScreen = (
          <React.Fragment>
            <div className="AuthHeadContainer">
              {/* <p className="DoosraBusinessHeader">
                  <span className="DoosraDefaultColor"></span>alohaa
                </p> */}
              <p className="AuthSubHeader">Enter OTP</p>
            </div>
            <form onSubmit={this.otpHandler}>
              <input
                id="otp"
                className="InputBox"
                placeholder="OTP goes here"
                value={this.state.otp}
                onChange={this.handleChange}
              />
              <p
                className="AuthForgotPassword"
                style={{
                  cursor: !this.state.resendOtpBlocked ? 'pointer' : '',
                  color: this.state.resendOtpBlocked && 'grey',
                }}
                onClick={(e) => {
                  this.resendOTPHandler(e);
                  this.setState({ resendOtpBlocked: true });
                  setTimeout(() => {
                    this.setState({ resendOtpBlocked: false });
                  }, 10000);
                }}
              >
                Resend OTP
              </p>
              {this.state.otpResent && (
                <div className="OTPResent">
                  <img src={GreenTick} />
                  <p>OTP resent successfully</p>
                </div>
              )}
              <div className="ErrorContainer ErrorContainerMargin">
                {this.state.error ? (
                  <React.Fragment>
                    <img
                      src={InfoRed}
                      style={{
                        paddingRight: '5px',
                        position: 'relative',
                        top: '-2px',
                      }}
                    />
                    {this.state.error}
                  </React.Fragment>
                ) : null}
              </div>
              <button className="AuthButton" type="submit">
                NEXT
              </button>
              <button
                className="AuthButton AuthButtonInvert"
                onClick={this.backHandler}
              >
                BACK
              </button>
            </form>
          </React.Fragment>
        );
        break;
      default:
      case 1:
        CurrentScreen = (
          <React.Fragment>
            <div className="AuthHeadContainer">
              {/* <p className="DoosraBusinessHeader">
                  <span className="DoosraDefaultColor"></span>alohaa
                </p> */}
              <p className="AuthSubHeader">
                Create an account and <strong>get started today!</strong>
              </p>
            </div>
            <form onSubmit={this.submitDetails}>
              <input
                className="InputBox ReadOnlyInput"
                placeholder="Name"
                value={this.state.adminName}
                readOnly={true}
              />
              <input
                className="InputBox ReadOnlyInput"
                placeholder="Email"
                value={this.state.adminEmail}
                readOnly={true}
              />
              <input
                className="InputBox ReadOnlyInput"
                placeholder="Phone number"
                value={this.state.adminNumber}
                readOnly={true}
              />

              <div className="ErrorContainer ErrorContainerMargin">
                {this.state.error ? (
                  <React.Fragment>
                    <img
                      src={InfoRed}
                      style={{
                        paddingRight: '5px',
                        position: 'relative',
                        top: '-2px',
                      }}
                    />
                    {this.state.error}
                  </React.Fragment>
                ) : null}
              </div>

              <button className="AuthButton" type="submit">
                NEXT
              </button>
              <button
                className="AuthButton AuthButtonInvert"
                onClick={this.redirectToLogin}
              >
                LOGIN
              </button>
            </form>
          </React.Fragment>
        );
        break;
    }

    if (
      localStorage.getItem('doosra-business-sessionToken') &&
      localStorage.getItem('doosra-business-sessionToken') !== ''
    ) {
      return <Redirect to="call-logs" />;
    }

    return (
      <div className="login-main">
        <div className="section-1">
          <div className="login-container">
            <img className="login-container-logo" alt="" src={Logo} />
            {CurrentScreen}
          </div>
        </div>
        <div>
          <img src={Wall} style={{ height: '100vh' }} />
        </div>
      </div>
    );
  }
}

export default AdminSignupPage;

import React, { useEffect, useState } from 'react';

import { MinusRedIcon, PlusGreenIcon } from '../../../assets/Icons/Icons';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';


export default function AddDids(props) {
  const [allDids, setAllDids] = useState([]);
  const [selectedDids, setSelectedDids] = useState([]);
  const [remainDids, setRemainDids] = useState([]);
  const [arrangePriority, setArrangePriority] = useState(false);
  const [newGroup, setNewGroup] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.selectedDidsOfOrganisation?.length > 0) {
      setSelectedDids(
        props?.allActiveDids?.filter(
          (i) => props.selectedDidsOfOrganisation.indexOf(i.did) > -1
        )
      );
      setRemainDids(
        props?.allActiveDids?.filter(
          (i) => props.selectedDidsOfOrganisation.indexOf(i.did) === -1
        )
      );
    } else {
      setRemainDids(props?.allActiveDids);
    }
    setAllDids(props?.allActiveDids);
  }, [props.selectedDidsOfOrganisation]);

  const onClickAddDisToSelectedDid = (item) => {
    const newSelectedDids = [...selectedDids, item];
    const newRemainDids = remainDids.filter((did) => did.did !== item.did);

    setSelectedDids(newSelectedDids);
    setRemainDids(newRemainDids);
  };

  const onClickSelectedDidToAddDid = (item) => {
    const newRemainDid = [...remainDids, item];
    const newSelectedDids = selectedDids.filter((did) => did.did !== item.did);
    setSelectedDids(newSelectedDids);
    setRemainDids(newRemainDid);
  };

  const remainDidsListJSX = () => {
    const result = searchValue
      ? remainDids.filter((item) =>
          item.did.toLowerCase().includes(searchValue.toLowerCase())
        )
      : remainDids;
    return result.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {item.did}
          </p>
          <p style={{ height: '28px', width: '128px', margin: '8px 92px' }}>
            <PlusGreenIcon onClick={() => onClickAddDisToSelectedDid(item)} />
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };

  const selectedDidsListJSX = () => {
    const result = searchValue
      ? selectedDids?.filter((item) =>
          item.did.toLowerCase().includes(searchValue.toLowerCase())
        )
      : selectedDids;

    return result.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {item.did}
          </p>
          <p style={{ height: '28px', width: '128px', margin: '8px 92px' }}>
            <MinusRedIcon onClick={() => onClickSelectedDidToAddDid(item)} />
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };

  const onClickAssignDidNext = () => {
    setError('');
    const SelectedUsers = selectedDids || [];
    if (SelectedUsers?.length <= 0) {
      setError('Please add Dids');
    } else {
      setArrangePriority(true);
    }
  };

  const onSubmit = () => {
    setArrangePriority(false);
    props.setDidsOfOrganisation(selectedDids?.map((item) => item._id));
    props.setSelectedDidsOfOrganisation(selectedDids?.map((item) => item.did));
    props.close();
  };

  return (
    <Popup open={props.open} closeOnDocumentClick={false} closeOnEscape={false}>
      {!arrangePriority && (
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Add Dids to the Campaign</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={props.close}
            />
          </div>
          <div className="PopupBody">
            <div
              style={{
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <input
                placeholder="Search Did"
                className="PopupSearchInput"
                id="searchValue"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </div>
            {selectedDids?.length > 0 && (
              <>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '60% 40%',
                    textAlign: 'center',
                    fontSize: '12px',
                    'letter-spacing': '1.2px',
                  }}
                  className="PopupColumnHeaders"
                >
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'left',
                      paddingLeft: '20px',
                    }}
                  >
                    SELECTED DID's
                  </p>
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    ADD/REMOVE
                  </p>
                </div>
                <div
                  style={{
                    maxHeight: '150px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  {selectedDidsListJSX()}
                </div>
              </>
            )}
            {remainDids?.length > 0 && (
              <>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '60% 40%',
                    textAlign: 'center',
                    fontSize: '12px',
                    'letter-spacing': '1.2px',
                  }}
                  className="PopupColumnHeaders"
                >
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'left',
                      paddingLeft: '20px',
                    }}
                  >
                    AVAILABLE DID's
                  </p>
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    ADD/REMOVE
                  </p>
                </div>
                <div
                  style={{
                    maxHeight: '150px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  {remainDidsListJSX()}
                </div>
              </>
            )}
            <div className="PopupInputContainer" style={{ color: 'red' }}>
              {error}
            </div>
            {newGroup.routing_type === 'ROUND_ROBIN' ? (
              <button
                className={`ButtonFullWidth`}
                // onClick={onClickPriorityNext}
              >
                SAVE
              </button>
            ) : (
              <button
                className={`ButtonFullWidth`}
                onClick={onClickAssignDidNext}
              >
                Next
              </button>
            )}
            <button className="ButtonCancelFullWidth" onClick={props.close}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {arrangePriority && (
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Confirm DIDs</p>
            <img className="PopupClose" src={Close} onClick={props.close} />
          </div>
          <div className="PopupBody">
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '50% 50%',
                textAlign: 'center',
                fontSize: '12px',
                'letter-spacing': '1.2px',
              }}
              className="PopupColumnHeaders"
            >
              <p
                style={{
                  margin: '10px',
                  textAlign: 'left',
                  paddingLeft: '30px',
                }}
              >
                SNO
              </p>
              <p
                style={{
                  margin: '10px',
                  textAlign: 'left',
                  paddingRight: '30px',
                }}
              >
                DID
              </p>
            </div>
            <div
              style={{
                height: '170px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {selectedDids?.map((item, index) => (
                <>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '50% 50%',
                      textAlign: 'center',
                      'letter-spacing': '1.2px',
                    }}
                    className="dropColumnBg"
                  >
                    <p
                      style={{
                        margin: '10px',
                        textAlign: 'left',
                        paddingLeft: '30px',
                        fontSize: '15px',
                        color: '#33aabb',
                      }}
                    >
                      {index + 1}
                    </p>
                    <p
                      style={{
                        margin: '10px',
                        textAlign: 'left',
                        paddingRight: '30px',
                        fontSize: '15px',
                        color: '#33aabb',
                      }}
                    >
                      {item.did}
                    </p>
                  </div>
                  <hr
                    className="HR"
                    style={{ width: '90%', margin: '0 auto' }}
                  />
                </>
              ))}
            </div>
            <button className={`ButtonFullWidth`} onClick={onSubmit}>
              SAVE
            </button>
            <button
              className="ButtonCancelFullWidth"
              onClick={() => {
                setArrangePriority(false);
                props.close();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </Popup>
  );
}

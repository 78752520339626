import React, { useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import Delete from '../../../assets/images/deleteWRedBackground.png';

export default function DeleteContactPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);

  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="PopupContainer">
        <div className="PopupHeaderContainer">
          <p>Delete Auto Attendant</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => setPopupOpen(false)}
          />
        </div>
        <div className="PopupBody">
          <p className="PopupBodyTitle">
            Are you sure you want to remove this Auto attendant?
          </p>

          <button
            className="ButtonFullWidth BgRed"
            onClick={() => {
              props.deleteAutoAttendantHandler(props.id);
              setPopupOpen(false);
            }}
          >
            REMOVE
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <React.Fragment>
      <img alt="" onClick={() => setPopupOpen(true)} src={Delete} />
      {popper}
    </React.Fragment>
  );
}

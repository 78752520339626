import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import { toast } from 'react-toastify';
import './styles.css';
import Close from '../../../assets/images/popupClose.png';
import { isSuperAdminWithPermission } from '../../../utility/utility';
const overlayStyle = { background: 'rgba(0,0,0,0.9)' };

export default class AddLeadToLeadListPopup extends Component {
  constructor(props) {
    super(props);
    let initState = {};
    if (props.headers && props.headers.length > 0) {
      props.headers.forEach((header) => {
        initState[header] = '';
      });
    }
    this.state = initState;
    this.initialState = this.state;
  }

  // isValidEmailCheck = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   const isValidEmail = emailRegex.test(email);
  //   return isValidEmail;
  // }

  // isValidDate = (dateString) => {
  //   const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
  //   return dateRegex.test(dateString);
  // }

  selectTag = (e) => {
    this.setState({
      tag: e.target.value,
    });
  };

  reset = () => {
    this.setState(this.initialState);
    this.props.toggle();
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  addLeadHandler = (e) => {
    if (!this.state.name || this.state.name === '') {
      toast.error('Please Enter Name Of The Lead');
      return false;
    }
    if (
      !this.state.number ||
      this.state.number === '' ||
      this.state.number.length !== 10
    ) {
      toast.error('Please Enter Valid Number Of The Lead');
      return false;
    }
    if (
      this.state.secondaryNumber &&
      this.state.secondaryNumber.length !== 10
    ) {
      toast.error('Please Enter Valid Secondary Of The Lead');
      return false;
    }
    // if(this.state.email && !this.isValidEmailCheck(this.state.email)) {
    //   toast.error("Please Enter Valid Email Of The Lead");
    //   return false;
    // }
    // if(this.state.due && isNaN(parseInt(this.state.due, 10))) {
    //   toast.error("Please Enter Valid Due Of The Lead");
    //   return false;
    // }
    // if(this.state.date && !this.isValidDate(this.state.date)) {
    //   toast.error("Please Enter Date in YYYY-MM-DD format");
    //   return false;
    // }
    this.props.addLeadHandler(this.state);
    this.setState(this.initialState);
    this.props.toggle();
  };

  render() {
    let popper = (
      <Popup
        open={this.props.open}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        {...{ overlayStyle }}
      >
        <div className="AddCallRemarksPopup">
          <div className="PopupContainer" style={{ overflow: 'auto' }}>
            <div className="PopupHeaderContainer">
              <p>Add Lead</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.reset}
              />
            </div>
            <div className="PopupBody">
              {/* <div className="PopupInputContainer">
            <label className="PopupInputLabel">Name</label>
            <input
              className="PopupInput"
              id="name"
              onChange={this.changeHandler}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Primary No</label>
            <input
              className="PopupInput"
              id="number"
              onChange={this.changeHandler}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Secondary No</label>
            <input
              className="PopupInput"
              id="secondaryNumber"
              onChange={this.changeHandler}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Email</label>
            <input
              className="PopupInput"
              id="email"
              onChange={this.changeHandler}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Due Amount</label>
            <input
              className="PopupInput"
              id="due"
              onChange={this.changeHandler}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Due Date</label>
            <input
              className="PopupInput"
              id="date"
              onChange={this.changeHandler}
            />
          </div> */}
              {this.props.headers &&
                this.props.headers.length > 0 &&
                this.props.headers.map((header) => {
                  return (
                    <div className="PopupInputContainer">
                      <label className="PopupInputLabel">
                        {header.toUpperCase()}
                      </label>
                      <input
                        className="PopupInput"
                        id={`${header}`}
                        onChange={this.changeHandler}
                      />
                    </div>
                  );
                })}
            </div>
            {this.props.open === true ? (
              <button
                className="ButtonFullWidth"
                style={{ marginTop: '10px' }}
                type="submit"
                onClick={this.addLeadHandler}
              >
                SAVE
              </button>
            ) : (
              <button
                className="ButtonFullWidth"
                style={{ cursor: 'default', marginTop: '10px' }}
              >
                SAVE
              </button>
            )}
            <button className="ButtonCancelFullWidth" onClick={this.reset}>
              CANCEL
            </button>
          </div>
        </div>
      </Popup>
    );

    return (
      <React.Fragment>
        <div style={{ padding: '5px' }}>
          {/* <EditIcon onClick={this.props.toggle} /> */}
          <button
            className="Otherbutton"
            disabled={!isSuperAdminWithPermission('ADD')}
            style={{
              marginRight: 0,
              color: '#07aa3f',
              border: '1px solid #07aa3f',
              background: 'white',
            }}
            onClick={this.props.toggle}
          >
            ADD LEAD
          </button>
        </div>
        {popper}
      </React.Fragment>
    );
  }
}

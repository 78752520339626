import '../../../assets/css/UpgradeServices.css';
import { SmallMusicIcon, RoutingSVG } from '../../../assets/Icons/Icons';
import callLogIcon from '../../../assets/images/callLogs.svg';
import smsInboxIcon from '../../../assets/images/smsInbox.svg';
import recoveryXIcon from '../../../assets/images/recoveryX.svg';
import webhooksIcon from '../../../assets/images/webhooks.svg';
import checkmark from '../../../assets/images/checkmark-circle.svg';
import { PUT } from '../../../services/HttpRequests';
import React, { useState } from 'react';
import autoAttendantIcon from '../../../assets/images/autoAttendant.png';
import { isSuperAdminWithPermission } from '../../../utility/utility';
const userUrl = process.env.REACT_APP_USER_API;

const UpgradeServices = ({ icon, heading, text, emailSent = false }) => {
  const [isEmailSent, setIsEmailSent] = useState(emailSent);
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-30%, -50%)',
    width: '600px',
    height: '300px',
    padding: '1rem',
    backgroundColor: '#fff',
    borderRadius: '1px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const icons = {
    callerTune: <SmallMusicIcon color="#196cca" height="40" width="31" />,
    recoveryX: <img alt="" src={recoveryXIcon} />,
    callLogs: <img alt="" src={callLogIcon} />,
    smsInbox: <img alt="" src={smsInboxIcon} />,
    callRouting: <RoutingSVG color="#196cca" height="40" width="31" />,
    webhooks: <img alt="" src={webhooksIcon} />,
    autoAttendant: <img alt="" src={autoAttendantIcon} />,
    numberMasking: <img alt="" src={callLogIcon} />,
  };

  const upgradeServiceEmail = async (featureName, featureKey, sendEmail) => {
    const organisationId = localStorage.getItem('doosra-biz-organisation-id');
    const userId = localStorage.getItem('doosra-biz-user-id');
    await PUT(
      `${userUrl}v1/user/send-upgrade-service-email`,
      {},
      { organisationId, userId, featureName, featureKey, sendEmail }
    );
  };

  const sendEmail = async (icon) => {
    const featureName = {
      callerTune: 'Caller tunes',
      recoveryX: 'RecoveryX',
      callLogs: 'Call Logs & outgoing calling',
      smsInbox: 'SMS Inbox',
      callRouting: 'Call Routing',
      webhooks: 'Webhooks',
      autoAttendant: 'Auto Attendant',
      numberMasking: 'Number Masking',
      messageBroadcast: 'SMS Broadcast',
      voiceBroadcast: 'Voice Broadcast',
    };
    await upgradeServiceEmail(featureName[icon], icon, true);
    setIsEmailSent(true);
  };

  // const previousState = async () => {
  //   const featureName = {
  //     callerTune: 'Caller tunes',
  //     recoveryX: 'RecoveryX',
  //     callLogs: 'Call Logs & outgoing calling',
  //     smsInbox: 'SMS Inbox',
  //     callRouting: 'Call Routing',
  //     webhooks: 'Webhooks',
  //   };
  //   await upgradeServiceEmail(featureName[icon], icon, false);
  //   setIsEmailSent(false);
  // };

  const getSendEmailContainer = () => {
    return (
      <div className="info-container" style={containerStyle}>
        {icons[icon]}
        <h2 className="heading">{heading}</h2>
        <p className="text">{text}</p>
        <button
          className={
            !isSuperAdminWithPermission('ADD')
              ? 'DisableButton SearchButton'
              : 'SearchButton'
          }
          onClick={() => sendEmail(icon)}
        >
          UPGRADE
        </button>
      </div>
    );
  };

  const getThankYouContainer = () => {
    return (
      <div className="info-container" style={containerStyle}>
        <img alt="" height={'76px'} src={checkmark} />
        <h2 className="heading">Thank you for your interest!</h2>
        <p className="text">
          Our team will reach out to you shortly to upgrade your account.
        </p>
      </div>
    );
  };

  return isEmailSent ? getThankYouContainer() : getSendEmailContainer();
};

export default UpgradeServices;

import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import NumberMasking from '../../../assets/images/numberMaskingImage.png';
import Toggle from './toggle';
import Close from '../../../assets/images/popupClose.png';
import { GET, POST } from '../../../services/HttpRequests';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { isSuperAdminWithPermission } from '../../../utility/utility';

const numberMaskingUrl = process.env.REACT_APP_NUMBER_MASKING_API;
class BindPopUp extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      caller_number: '',
      receiver_number: '',
      expire_on: null,
      binding_type: '',
      bindings: [],
      pin_type: '',
      is_two_way: false,
      error: '',
      processing: false,
    };
    this.initialState = this.state;
  }

  openPopup = () => {
    this.setState({ popupOpen: true });
    this.BindingTypeHandler();
  };
  closePopup = () => {
    this.setState(this.initialState);
  };
  inputHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  isTwoWayHandler = (e) => {
    this.setState({ is_two_way: !this.state.is_two_way });
  };
  BindingTypeHandler = async () => {
    try {
      const organisation_id = localStorage.getItem(
        'doosra-biz-organisation-id'
      );
      const response = await GET(
        `${numberMaskingUrl}v1/organisation/get-features?organisation_id=${organisation_id}`
      );
      console.log(response.data);
      this.setState({ bindings: response?.data?.response });
    } catch (error) {
      console.error(error);
    }
  };
  configureBindingHandler = () => {
    if (this.state.processing) return;

    if (
      !(
        this.state.caller_number &&
        this.state.receiver_number &&
        this.state.expire_on &&
        this.state.binding_type &&
        this.state.pin_type
      )
    ) {
      toast.error('Kindly Check all inputs !!!');
      return;
    }

    this.setState({ processing: true });
    let data = {
      caller_number: this.state.caller_number,
      receiver_number: this.state.receiver_number,
      expire_on: Math.floor(new Date(this.state.expire_on).getTime() / 1000),
      is_two_way: this.state.is_two_way,
      binding_type: this.state.binding_type,
      pin_type: this.state.pin_type,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    };
    POST(`${numberMaskingUrl}v1/external/organisation/bind`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        console.log('the res is', res);
        const binding1 = res.length >= 1 ? res[0] : null;
        const binding2 = res.length > 1 ? res[1] : null;
        console.log('the bindings are', binding1, binding2);
        if (binding1)
          toast.success(
            `caller: ${binding1.caller_primary_number} \n did: ${binding1.did} \n passcode: ${binding1.passcode} \n receiver: ${binding1.receiver_primary_number}`
          );
        if (binding2)
          toast.success(
            `caller: ${binding2.caller_primary_number} \n did: ${binding2.did} \n passcode: ${binding2.passcode} \n  receiver: ${binding2.receiver_primary_number}`
          );
        if (res.length === 0) toast.error('binding is not done');
        this.closePopup();
        this.setState({
          popupOpen: false,
          caller_number: '',
          receiver_number: '',
          is_two_way: '',
          binding_type: '',
          pin_type: '',
          processing: false,
        });
        this.props.reloadData(1);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({
            error: err.response.data.error.reason,
            processing: false,
          });
          toast.error(err.response.data.error.reason);
        }
      });
  };

  render() {
    const { isFreeFormEnabled } = this.props;
    let currentView;
    currentView = (
      <div className="PopupContainer" style={{ overflow: 'auto' }}>
        <img
          alt=""
          className=""
          style={{
            position: 'absolute',
            top: '-20px',
            left: '96%',
            cursor: 'pointer',
            right: '-250px',
          }}
          src={Close}
          onClick={this.closePopup}
        />
        <div
          style={{
            color: 'var(--text-green-color)',
            textAlign: 'center',
            padding: '20px',
          }}
        >
          <img
            alt=""
            style={{ alignContent: 'center' }}
            src={NumberMasking}
            onClick={this.closePopup}
          />
          <p>Configure number masking</p>
        </div>
        <div className="PopupBody">
          <input
            id="caller_number"
            value={this.state.caller_number}
            onChange={this.inputHandler}
            className="PopupInput"
            style={{ margin: '10px', backgroundColor: 'white' }}
            placeholder="Callers number"
          />
          <input
            id="receiver_number"
            value={this.state.receiver_number}
            onChange={this.inputHandler}
            className="PopupInput"
            style={{ margin: '10px', backgroundColor: 'white' }}
            placeholder="Receivers number"
          />
          <input
            id="expire_on"
            type="datetime-local"
            value={this.state.expire_on}
            onChange={this.inputHandler}
            className="PopupInput"
            style={{ margin: '10px', backgroundColor: 'white' }}
            placeholder="Expiration (Unix time stamp in seconds)"
          />
          {/* <input
            id="binding_type"
            value={this.state.binding_type}
            className="PopupInput"
            style={{ margin: "10px", backgroundColor: 'white' }}
            placeholder="BindingType"
          /> */}
          <select
            className="PopupInput"
            style={{ margin: '10px' }}
            id="binding_type"
            value={this.state.binding_type}
            onChange={this.inputHandler}
          >
            <option disabled value="">
              Binding type
            </option>
            {this?.state?.bindings?.map((binding) => (
              <option value={binding.binding_type}>
                {binding.binding_type}
              </option>
            ))}
          </select>
          <select
            className="PopupInput"
            style={{ margin: '10px' }}
            id="pin_type"
            value={this.state.pin_type}
            onChange={this.inputHandler}
          >
            <option disabled value="">
              Pin type
            </option>
            {this?.state?.bindings?.map((binding) => (
              <option value={binding.pin_type}>{binding.pin_type}</option>
            ))}
          </select>
          <div
            style={{
              margin: '10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{}}>
              <label style={{ margin: '10px' }}>Two way binding</label>
            </div>
            <div style={{ marginRight: '28px' }}>
              <Toggle
                isOn={this.state.is_two_way}
                handleToggle={this.isTwoWayHandler}
              />
            </div>
          </div>
          {this.state.error ? (
            <div className="ErrorContainer ErrorContainerMargin">
              <img
                alt=""
                src={InfoRed}
                style={{
                  paddingRight: '5px',
                  position: 'relative',
                  top: '-2px',
                }}
              />
              {this.state.error}
            </div>
          ) : null}
          <button
            className="ButtonFullWidth BgGreen"
            onClick={this.configureBindingHandler}
            style={{
              marginTop: '20px',
              backgroundColor: 'var(--text-green-color)',
              color: 'white',
              border: 'solid 1px white',
            }}
          >
            SAVE CONFIGURATION
          </button>
        </div>
      </div>
    );

    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        {currentView}
      </Popup>
    );
    return (
      <div style={{ display: 'flex' }}>
        {this.state.processing && <PreLoader />}
        <span>
          {!isFreeFormEnabled && (
            <button
              className="Otherbutton"
              style={{ marginRight: 0 }}
              onClick={this.openPopup}
              disabled={!isSuperAdminWithPermission('EDIT')}
            >
              CONFIGURE
            </button>
          )}
          {popper}
        </span>
      </div>
    );
  }
}

export default BindPopUp;

import React, { useState } from "react";
import { Avatar, Button } from "@material-ui/core";
import "./WebRTCCallPopup.css"

const WebRTCCallPopup = ({ 
    isOpen,
    muteCall,
    unmuteCall,
    holdCall,
    resumeCall,
    endCall,
    RTCcallStatus,
    number,
    leadListContacts,
    name,
    value,
    batchCallHadler,
    isSingleCall,
    batchCallId,
}) => {
    const [muted, setMuted] = useState(false);
    const [held, setHeld] = useState(false);
    const [isBatchCallPaused, setIsBatchCallPaused] = useState(true);

    const handleRejectClick = () => {
        endCall();
    };

    const handleMuteClick = () => {
        !muted ? muteCall() : unmuteCall();
        setMuted(!muted);
    };

    const handleHoldClick = () => {
        !held ? holdCall() : resumeCall();
        setHeld(!held);
    };
    return (
        isOpen ? 
            <div className="popup">
                <div className="popup-header">
                    <div className="popup-profile">
                        <Avatar />
                    </div>
                    <div className="popup-name">{
                         `${name}  ${number}`
                    }</div>
                </div>
                <div className="popup-status">
                    <h6>{`call status - ${RTCcallStatus}`}</h6>
                </div>
                <div className="popup-buttons">
                    <Button variant="contained" color={muted ? "default" : "primary"} onClick={handleMuteClick}>
                        {muted ? "Unmute" : "Mute"}
                    </Button>
                    {/* <Button variant="contained" color={held ? "default" : "primary"} onClick={handleHoldClick}>
                        {held ? "Unhold" : "Hold"}
                    </Button> */}
                    <Button variant="contained" color="secondary" onClick={handleRejectClick}>
                        End
                    </Button>
                    {batchCallId ? 
                    <Button variant="contained" color={isBatchCallPaused ? "default" : "primary"} onClick={batchCallHadler}>
                    {value ? "Pause" : "UnPause"}
                </Button>: null}
                </div>
            </div> 
        :null
    );
}

export default WebRTCCallPopup;

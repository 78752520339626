/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import '../../../assets/css/SearchBar.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { POST, GET } from '../../../services/HttpRequests';
import Delete from '../../../assets/images/deleteWRedBackground.png';
let userRole = localStorage.getItem('doosra-biz-user-role');
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;

export default function PopulateLeadLists(props) {
  const [leadLists, setLeadLists] = useState([]);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   getLeadListsHandler();
  // }, []);
  // const getLeadListsHandler = () => {
  //   setLoading(true);
  //   const data = {
  //     organisationId: localStorage.getItem('doosra-biz-organisation-id'),
  //   };
  //   return GET(
  //     outGoingUrl + 'v1/organisations/outbound-call/get-lead-lists',
  //     data
  //   )
  //     .then((res) => {
  //       setLeadLists(res.data.leadLists);
  //       props.getLeadsCount(res.data.count);
  //       props.fetchLeadListContactsHandler({leadListId: res.data.leadLists[res.data.leadLists.length - 1]._id})
  //       setLoading(false);
  //       return true;
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       toast.error(err?.response?.data?.error?.reason);
  //       return false;
  //     });
  // };
  
  return props.type !== 'report' && props.leadLists.length > 0  ? (
    <div className="leadListSelect">
      <select onChange={props.getLeadListsHandler} className="Select-lead-list">
        {props.leadLists.map((list) => (
          <option value={list._id} selected>{list.leadListName}</option>
        ))}
        {(props.leadLists.length > 0 && userRole === "ADMIN")  ? <option value={"all"} >{"All Leads"}</option> : null}
      </select>
    </div>
  ) : null;
}

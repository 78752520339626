import React, { useState, useEffect } from 'react';
import phoneWhite from '../../../assets/images/phoneWhiteRecoveryCall.svg';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { isSuperAdminWithPermission } from '../../../utility/utility';

export default function SingleCallPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.keyCode === 27) {
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const initiateCall = (destinationNumber, name) => {
    props.WebRTCEnabled
      ? props.initiateWebRTCRecoveryCall(destinationNumber, name)
      : props.initiateRecoveryCall(destinationNumber, name);
    setPopupOpen(false);
  };

  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      (
      <div className="PopupContainer">
        <div className="PopupHeaderContainer">
          <p>CHOOSE DESTINATION NO</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => {
              setPopupOpen(false);
            }}
          />
        </div>
        <div className="PopupBody">
          <button
            className="AddUserFullWidth"
            style={{ marginTop: '15px' }}
            onClick={() => initiateCall(props.primaryNumber, props.name)}
          >
            PRIMARY MOBILE NO
          </button>
          <button
            className="AddUserFullWidth"
            style={{ marginTop: '15px' }}
            onClick={() => initiateCall(props.secondaryNumber, props.name)}
          >
            SECONDARY MOBILE NO
          </button>
        </div>
      </div>
      );
    </Popup>
  );

  return (
    <div style={{ display: 'flex' }}>
      <span>
        <button
          className="ActivateButton"
          disabled={
            !isSuperAdminWithPermission('ADD') ||
            (props.selectedAgentStatus !== 'active' && props.agentMetric)
          }
          style={{
            marginRight: 0,
            width: '32px',
            height: '32px',
            backgroundColor:
              props.selectedAgentStatus !== 'active' && props.agentMetric
                ? 'lightgrey'
                : '#07aa3f',
          }}
          onClick={() => {
            setPopupOpen(true);
          }}
        >
          <img
            alt=""
            src={phoneWhite}
            className="SidebarItemIcon"
            style={{ width: '16px', height: '16px' }}
          />
        </button>
        {popper}
      </span>
    </div>
  );
}

import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import { toast } from 'react-toastify';
import './styles.css';
import Close from '../../../assets/images/popupClose.png';
import { POST } from '../../../services/HttpRequests';
import { MenuItem, Select } from '@material-ui/core';
import { isSuperAdminWithPermission } from '../../../utility/utility';
const overlayStyle = { background: 'rgba(0,0,0,0.9)' };

export default class AddLeadToLeadListPopup extends Component {
  constructor(props) {
    super(props);
    let initState = {};
    if (props.headers && props.headers.length > 0) {
      const temp = {};
      for (let header of props.headers) {
        temp[header] = '';
      }
      initState = { ...temp, ...initState };
    }
    this.state = initState;
    this.initialState = this.state;
  }

  // isValidEmailCheck = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   const isValidEmail = emailRegex.test(email);
  //   return isValidEmail;
  // }

  // isValidDate = (dateString) => {
  //   const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
  //   return dateRegex.test(dateString);
  // }

  selectTag = (e) => {
    this.setState({
      tag: e.target.value,
    });
  };

  reset = () => {
    this.setState(this.initialState);
    this.props.setSelectedUser('');
    this.props.toggle();
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  addLeadHandler = (e) => {
    if (!this.state.name || this.state.name === '') {
      toast.error('Please Enter Name Of The Lead');
      return false;
    }
    if (
      !this.state.number ||
      this.state.number === '' ||
      this.state.number.length !== 10
    ) {
      toast.error('Please Enter Valid Number Of The Lead');
      return false;
    }
    if (
      this.state.secondaryNumber &&
      this.state.secondaryNumber.length !== 10
    ) {
      toast.error('Please Enter Valid Secondary Of The Lead');
      return false;
    }
    if (!this.props.selectedUser && this.props.campaignType === 'static') {
      toast.error('Please Select Agent !!! ');
      return;
    }

    let existingNumbers = new Set();
    for (let i = 0; i < this.props.campaignLeads.length; i++) {
      const lead = this.props.campaignLeads[i];
      existingNumbers.add(lead.number);
      if (lead.secondaryNumber) {
        existingNumbers.add(lead.secondaryNumber);
      }
    }
    existingNumbers = [...existingNumbers];
    if (existingNumbers.indexOf(this.state.number) >= 0) {
      toast.error('Number already Exist !!! ');
      return;
    }
    if (
      this.state.secondaryNumber &&
      existingNumbers.indexOf(this.state.secondaryNumber) >= 0
    ) {
      toast.error('Secondary Number already Exist!!! ');
      return;
    }
    this.props.addLeadHandler(this.state);
    this.setState(this.initialState);
    this.props.toggle();
  };

  render() {
    let popper = (
      <Popup
        open={this.props.open}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        {...{ overlayStyle }}
      >
        <div className="AddCallRemarksPopup">
          <div className="PopupContainer" style={{ overflow: 'auto' }}>
            <div className="PopupHeaderContainer">
              <p>Add Lead</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.reset}
              />
            </div>
            <div className="PopupBody">
              {this.props.headers &&
                this.props.headers.length > 0 &&
                this.props.headers.map((header) => {
                  return (
                    header !== 'agent' && (
                      <div className="PopupInputContainer">
                        <label className="PopupInputLabel">
                          {header.toUpperCase()}
                        </label>
                        <input
                          className="PopupInput"
                          id={`${header}`}
                          onChange={this.changeHandler}
                        />
                      </div>
                    )
                  );
                })}
              {this.props.campaignData.campaignType === 'static' && (
                <div className="PopupInputContainer">
                  <label className="PopupInputLabel">AGENT</label>
                  <br></br>
                  <select
                    labelId="dropdown-label"
                    id="dropdown"
                    className="PopupInput"
                    style={{ cursor: 'pointer' }}
                    value={this.props.selectedUser}
                    onChange={(e) => this.props.setSelectedUser(e.target.value)}
                    label="Select an option"
                  >
                    <option disabled value="">
                      Please Select Agent
                    </option>
                    {this.props.users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.zvr_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {this.props.open === true ? (
              <button
                className="ButtonFullWidth"
                style={{ marginTop: '10px' }}
                type="submit"
                onClick={this.addLeadHandler}
              >
                SAVE
              </button>
            ) : (
              <button
                className="ButtonFullWidth"
                style={{ cursor: 'default', marginTop: '10px' }}
              >
                SAVE
              </button>
            )}
            <button className="ButtonCancelFullWidth" onClick={this.reset}>
              CANCEL
            </button>
          </div>
        </div>
      </Popup>
    );

    return (
      <React.Fragment>
        <div style={{ padding: '5px' }}>
          {/* <EditIcon onClick={this.props.toggle} /> */}
          <button
            className="Otherbutton"
            disabled={!isSuperAdminWithPermission('ADD')}
            style={{
              marginRight: 0,
              color: '#07aa3f',
              border: '1px solid #07aa3f',
              background: 'white',
            }}
            onClick={this.props.toggle}
          >
            ADD LEAD
          </button>
        </div>
        {popper}
      </React.Fragment>
    );
  }
}

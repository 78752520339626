// import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './assets/custom.scss';
import MainComponent from './MainComponent';
import AuthProvider from './providers/AuthProvider';
import CustomeThemeProvider from './providers/customeThemeProvider';
import SocketProvider from './providers/SocketProvider';
import Posthog from './common/posthog/posthog';
import { setup_axios_interceptor } from './services/HttpRequests';
import {store} from './store';

setup_axios_interceptor(store);
toast.configure({ time: 2500 });

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <CustomeThemeProvider>
            <SocketProvider>
              <MainComponent />
              <Posthog />
            </SocketProvider>
          </CustomeThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

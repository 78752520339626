import React, { useEffect, useState } from 'react';
/* eslint-disable no-unused-vars */
import adapter from 'webrtc-adapter';
import { connect } from 'react-redux';
import JsSIP from 'jssip';
import { toast } from 'react-toastify';
import AlohaaLogoWhite from '../../assets/images/AlohaaLogoWhite.png';
import AlohaaWhiteLogoNoBackground from '../../assets/images/AlohaaWhiteLogoNoBackground.svg';
import HangupLogo from '../../assets/images/RecoveryHangup.webp';
import HoldLogo from '../../assets/images/PauseIcon.png';
import MuteLogo from '../../assets/images/MicLogo.png';
import activeStatus from '../../assets/images/activeStatus.svg';
import tea from '../../assets/images/tea.svg';
import lunch from '../../assets/images/lunch.svg';
import meeting from '../../assets/images/meeting.svg';
import training from '../../assets/images/training.svg';
import biobreak from '../../assets/images/biobreak.svg';
import offline from '../../assets/images/offline.svg';
import busy from '../../assets/images/busy.svg';
import ringing from '../../assets/images/ringingStatus.svg';
import { RiRefreshFill } from 'react-icons/ri';
import {
  DEV_INHOUSE_PBX_SERVERS,
  PROD_INHOUSE_PBX_SERVERS,
  TURN_SERVER_CONFIG,
} from '../../assets/constants';
import { GET, POST, PUT } from '../../services/HttpRequests';
import { useSocket } from '../../providers/SocketProvider';
import {
  setWebRtcConnectionStatus,
  setDialerCallId,
  setIsSingleCall,
  changeDispostionStatus,
  changeAgentActiveStatus,
  setWebRtcSessions,
} from '../../actions/recoveryXActions';
import PreLoader from '../../common/component/preLoader/PreLoader';
import { MenuItem, Select } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

JsSIP.debug.enable('JsSIP:*');
const INHOUSE_PBX_SERVERS =
  process.env.REACT_APP_ENV === 'DEV'
    ? DEV_INHOUSE_PBX_SERVERS
    : PROD_INHOUSE_PBX_SERVERS;
const sip_details = JSON.parse(localStorage.getItem('sip_details'));
const username = sip_details?.username;
const password = sip_details?.password;
const pbxServicesUrl = process.env.REACT_APP_PBX_SERVICES;
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
const webRTCOutGoingUrl = process.env.REACT_APP_WEBRTC_RECOVERY_API_URL;
const userUrl = process.env.REACT_APP_USER_API;
let userRole = localStorage.getItem('doosra-biz-user-role');

const ReceiveCall = () => {
  const [UA, setUA] = useState(null);
  const [RTCSession, setRTCSession] = useState(null);
  const [showAcceptButton, setShowAcceptButton] = useState(false);
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    if (isOnline) {
      const server = 'pbx1.dev.notring.com';
      // const username = '8446183906';
      // const password = '8446183906';

      const socket = new JsSIP.WebSocketInterface(`wss://${server}/ws`);
      const uaConfig = {
        uri: `sip:${username}@${server}`,
        password: password,
        sockets: [socket],
        connection_recovery_min_interval: 10,
      };
      const ua = new JsSIP.UA(uaConfig);
      setUA(ua);
      ua.start();
      ua.on('disconnected', (e) => {
        console.log('disconnected - ', e);
        setIsOnline(false);
      });
    } else {
      // Stop the socket when "Offline" button is clicked
      if (UA) {
        UA.stop();
      }
    }
    return () => {
      // Cleanup function to stop the UA when the component is unmounted
      if (UA) {
        UA.stop();
      }
    };
  }, [isOnline]);

  const options = {
    mediaConstraints: { audio: true, video: false },
    pcConfig: {
      iceServers: [
        {
          urls: ['stun:stun.l.google.com:19302'],
        },
        {
          urls: ['turn:36.255.252.90:3478'],
          username: 'doosra',
          credential: 'dmlqdHVybnNlcnZlcgo=',
        },
      ],
    },
  };

  useEffect(() => {
    if (UA) {
      UA.on('newRTCSession', (e) => {
        console.log('----------------------------------1');
        console.log(' newRTCSession', e);
        const newSession = e.session;
        setRTCSession(newSession);

        newSession.on('icecandidate', (candidate) => {
          console.log('iceCandidate gathered - ', candidate);
        });

        newSession.on('icegatheringstatechange', (event) => {
          console.log(
            'icegatheringstatechange - ',
            event.target.iceGatheringState
          );
        });

        if (newSession.direction === 'incoming' && newSession.status === 4) {
          setShowAcceptButton(true); // Show the Accept button for incoming calls
          console.log('----------------------------------4');
          newSession.on('accepted', function (event) {
            console.log('----------------------------------5');
            console.log(' Call Accepted ');
          });

          newSession.on('confirmed', function (event) {
            console.log('----------------------------------6');
            console.log('Call confirmed');
          });

          newSession.on('failed', (event) => {
            console.log('----------------------------------7');
            console.log(' Call failed ');
          });

          newSession.on('ended', (event) => {
            console.log(
              '----------------------------------8',
              'c1: call canceled from UI'
            );
            setRTCSession(null);
            setShowAcceptButton(false);
            console.log(' Call hangup ');
          });

          if (newSession.status === 4) {
            console.log('----------------------------------9');
            // newSession.answer(options);
          }
          if (newSession.connection) {
            newSession.connection.addEventListener('addstream', (e) => {
              console.log('----------------------------------10');
              var audio = new Audio();
              audio.srcObject = e.stream;
              audio.play();
            });
          }
        }
      });
    }
  }, [UA]);

  const acceptCall = () => {
    if (RTCSession) {
      RTCSession.answer(options);
      setShowAcceptButton(false); // Hide the Accept button after answering the call
    }
  };

  const endCall = () => {
    console.log('----------------------------------end');
    if (RTCSession) {
      RTCSession.terminate();
      setRTCSession(null);
      setShowAcceptButton(false); // Hide the Accept button when the call is ended
    }
  };

  const goOnlineHandler = async () => {
    await PUT(userUrl + 'v1/user/webrtc/status/toggle', null, { status: true })
      .then((res) => {
        setIsOnline(true);
        toast.success('Agent online');
      })
      .catch((err) => {
        toast.error('An error occured');
      });
  };

  const goOfflineHandler = async () => {
    await PUT(userUrl + 'v1/user/webrtc/status/toggle', null, { status: false })
      .then((res) => {
        setIsOnline(false);
        toast.success('Agent offline');
      })
      .catch((err) => {
        toast.error('An error occured');
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        maxWidth: '180px',
        margin: '24px 0 0 24px',
      }}
    >
      <h4 style={{ color: 'white' }}>Incoming calls</h4>
      {!isOnline ? (
        <button
          id="onlineBtn"
          disabled={isOnline}
          style={{
            border: '4px solid green',
            background: 'none',
            color: 'white',
            borderRadius: '8px',
            padding: '8px 16px',
          }}
          onClick={goOnlineHandler}
        >
          go Online
        </button>
      ) : (
        <button
          id="offlineBtn"
          disabled={!isOnline}
          style={{
            border: '4px solid red',
            background: 'none',
            color: 'white',
            borderRadius: '8px',
            padding: '8px 16px',
          }}
          onClick={goOfflineHandler}
        >
          go Offline
        </button>
      )}
      {showAcceptButton && (
        <button
          id="acceptBtn"
          style={{
            border: 'none',
            background: 'green',
            color: 'white',
            borderRadius: '8px',
            padding: '8px 16px',
          }}
          onClick={acceptCall}
        >
          Accept
        </button>
      )}
      {(showAcceptButton || RTCSession) && (
        <button
          id="endBtn"
          style={{
            border: 'none',
            background: 'red',
            color: 'white',
            borderRadius: '8px',
            padding: '8px 16px',
          }}
          onClick={endCall}
        >
          Call End
        </button>
      )}
    </div>
  );
};

export default ReceiveCall;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import AssignUserPopup from '../components/AssignUserPopup';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import UnassignConfirmationPopup from '../components/UnassignConfirmationPopup';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import DataTable from '../../../common/component/table/DataTable';
import { ADMIN_VIRTUAL_NUMBERS_TAB, ADMIN_OUTGOING_NUMBERS_TAB } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";

const vmnUrl = process.env.REACT_APP_VMN_API;
const priorityRoutingUrl = process.env.REACT_APP_PRIORITY_ROUTING_API;
class DoosraOutgoingNumbersPage extends Component {
  constructor() {
    super();
    this.state = {
      status: 'assigned',
      count: 0,
      vmnNumberCount: 0,
      numbersList: [],
      totalPages: 0,
      currentPage: 1,
      pageSize: 15,
      loading: false,
      searchData: {},
      groups: [],
      latestFreeTrialVmnStatus: '',
    };
  }

  async componentDidMount() {
    this.getFreshList();
    this.getVMNNumbersList();
  }

  getFreshList = async () => {
    this.setState({ loading: true });
    let data = {
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };

    await GET(`${vmnUrl}v1/did/numbers`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          count: res?.numbers?.count || 0,
          numbersList: res?.numbers?.docs || [],
          totalPages: Math.ceil(res?.numbers?.count / this.state.pageSize),
          pageSize: res.pageSize,
          loading: false,
          searchData: {},
        });
      })
      .catch((err) => {
        if (err) {
          this.setState({ loading: false });
        }
      });

    await GET(
      `${priorityRoutingUrl}v1/organisations/priority-routing/group/list`,
      {
        page: 0,
        page_size: 100,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      }
    ).then((res) => {
      const list = res?.data?.response?.items || [];
      const groupList = [];
      list.map((item) => {
        const dids = item?.dids || [];
        const primaries = item?.primaries || [];
        if (primaries.length <= 0 && dids.length <= 0) {
          const obj = { _id: item._id, name: item.name };
          groupList.push(obj);
        }
      });
      this.setState({ groups: groupList });
    });
  };

  getVMNNumbersList = () => {
    GET(`${vmnUrl}v1/organisations/numbers`, {
      pageNo: 1,
      pageSize: 15,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) =>
        this.setState({ vmnNumberCount: res?.numbers?.count || 0 })
      );
  };

  seachHandler = (data) => {
    data.pageNo = 1;
    data.pageSize = this.state.pageSize;
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    GET(`${vmnUrl}v1/did/numbers`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          count: res?.numbers?.count || 0,
          numbersList: res?.numbers?.docs || [],
          totalPages: Math.ceil(res?.numbers?.count / this.state.pageSize) || 0,
          pageSize: res.pageSize,
          searchData: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchPageData = (value) => {
    let data = {
      pageNo: value,
      pageSize: this.state.pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    data = { ...this.state.searchData, ...data };

    GET(`${vmnUrl}v1/did/numbers`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          count: res?.numbers?.count || 0,
          numbersList: res?.numbers?.docs || [],
          currentPage: value,
          pageSize: res.pageSize,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  assignUserHandler = (numberId, userId) => {
    let data = { user_id: userId, status: 'ASSIGN' };
    this.updateHandler(numberId, data);
  };

  assignGroupHandler = async (numberId, groupId, status = 'ASSIGN') => {
    const data = {
      id: numberId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      priorityRoutingGroupId: groupId,
      status,
      groupType: 'OUTGOINGCALL',
    };

    const response = await PUT(
      `${vmnUrl}v1/organisations/manage-vmn`,
      null,
      data
    );
    if (response) {
      this.getFreshList();
    }
  };

  reassignGroupHandler = async (numberId, groupId) => {
    const data = {
      vmnId: numberId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      priorityRoutingGroupId: groupId,
      groupType: 'OUTGOINGCALL',
    };
    const response = await POST(
      `${priorityRoutingUrl}v1/organisations/priority-routing/group/primaries`,
      data
    );
    if (response) {
      this.getFreshList();
    }
  };

  assignAutoAttendantHandler = async (numberId, autoAttendantId, status) => {
    const data = {
      id: numberId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      autoAttendantId: autoAttendantId,
      status: status,
      groupType: 'OUTGOINGCALL',
    };
    const response = await PUT(
      `${vmnUrl}v1/organisations/manage-vmn`,
      null,
      data
    );
    if (response) {
      this.getFreshList();
    }
  };

  reassignUserHandler = (numberId, userId) => {
    let data = { user_id: userId, status: 'REASSIGN' };
    this.updateHandler(numberId, data);
  };

  unassignUserHandler = (numberId, userId) => {
    let data = {
      user_id: userId,
      status: 'DEASSIGN',
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      id: numberId,
      groupType: 'OUTGOINGCALL',
    };

    PUT(`${vmnUrl}v1/organisations/manage-vmn`, null, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.getFreshList();
        toast.success(`Success`);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          toast.error(err.response.data.error.reason);
        }
      });
  };

  unassignGroupHandler = async (numberId, groupId) => {
    const data = {
      vmnId: numberId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      priorityRoutingGroupId: groupId,
      groupType: 'OUTGOINGCALL',
    };
    const response = await DELETE(
      `${priorityRoutingUrl}v1/organisations/priority-routing/group/primaries`,
      null,
      data
    );
    if (response) {
      this.getFreshList();
    }
  };

  updateHandler = (doosraNumberId, data) => {
    PUT(`${vmnUrl}v1/organisations/manage-vmn`, null, {
      ...data,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      id: doosraNumberId,
      groupType: 'OUTGOINGCALL',
    })
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.getFreshList();
        toast.success(`Success`);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          toast.error(err.response.data.error.reason);
        }
      });
  };

  assignedToName = (item) => {
    let name = '';
    if (item.group && item.group.length > 0 && item.group[0].name) {
      name = item.group[0].name;
    } else if (item.user && item.user.length > 0 && item.user[0].zvr_name) {
      name = item.user[0].zvr_name;
    } else if (item.ivr && item.ivr.length > 0 && item.ivr[0].ivr_name) {
      name = item.ivr[0].ivr_name;
    } else if (
      item.autoAttendant &&
      item.autoAttendant.length > 0 &&
      item.autoAttendant[0].name
    ) {
      name = item.autoAttendant[0].name;
    }
    return name.trim();
  };

  sortNumbers = (n1, n2) => {
    return this.assignedToName(n1).toLowerCase() >
      this.assignedToName(n2).toLowerCase()
      ? -1
      : 1;
  };

  switchNumberStatus = (doosraNumberId, value) => {
    let data = {
      status: value,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      id: doosraNumberId,
      groupType: 'OUTGOINGCALL',
    };

    PUT(`${vmnUrl}v1/organisations/manage-vmn`, {}, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        let newNumbersList = this.state.numbersList.map((item) => {
          if (res.vmn.v_mobile_no === item.v_mobile_no) {
            if (res.vmn.status === 'ACTIVE') item.status = res.vmn.status;
            if (res.vmn.status === 'INACTIVE') item.status = res.vmn.status;
          }
          return item;
        });
        this.setState({ numbersList: newNumbersList });
        toast.success(`Success`);
      })
      .catch((err) => {
        console.log('err');
      });
  };

  trimNumber = (number) => {
    if (number && number.length == 12) return number.substring(2);
    return number;
  };

  getNumberType = (item) => {
    if (item?.user?.length > 0) {
      return 'Agent';
    }
    if (item?.ivr?.length > 0) {
      return 'IVR';
    }
    if (item?.group?.length > 0) {
      return 'Group';
    }
    return '-';
  };

  updateFreeTrialVmnStatus = (status) => {
    let data = {
      status,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    PUT(`${vmnUrl}v1/organisations/free-trial-manage-vmn`, null, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({ latestFreeTrialVmnStatus: data.status });
        toast.success(`Success`);
        this.getFreshList();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          toast.error(err.response.data.error.reason);
        }
      });
  };

  render() {
    let displayData;
    const { call_forwarding_status } = this.props;

    const tabs = [];

    if (call_forwarding_status)
      tabs.push({
        title: 'Virtual number',
        subtitle: `${this.state.vmnNumberCount}`,
        onClick: () => {
          logPostHogEvent(ADMIN_VIRTUAL_NUMBERS_TAB);
          this.props.history.push('/virtual-numbers')
        },
      });

    tabs.push({
      title: 'Outgoing numbers',
      active: true,
      subtitle: `${this.state.count}`,
      onClick: () => {
        logPostHogEvent(ADMIN_OUTGOING_NUMBERS_TAB);
      },
    });
    if (this.state.loading) {
      displayData = <PreLoader />;
    }
    if (this.state.numbersList.length > 0) {
      displayData = this.state.numbersList
        .sort(this.sortNumbers)
        .map((item) => (
          <DataTable.Row>
            <DataTable.Cell style={{ paddingLeft: 25 }}>
              {this.trimNumber(item.virtualNumber)}
            </DataTable.Cell>
            <DataTable.Cell>
              {item.status === 'ASSIGNED' ? this.assignedToName(item) : '-'}
            </DataTable.Cell>
            <DataTable.Cell>
              {item.expireDate ? item.expireDate.substring(0, 10) : '-'}
            </DataTable.Cell>
            {/* <DataTable.Cell style={{ textAlign: 'center', width: '30%' }}>
              {item.status === 'ASSIGNED' ? (
                <>
                  <UnassignConfirmationPopup
                    unassignUser={() =>
                      this.unassignUserHandler(item._id, item.user_id)
                    }
                    unassignGroup={() =>
                      this.unassignGroupHandler(item._id, item.routingGroupId)
                    }
                    numberDetails={item}
                    groupType="OUTGOINGCALL"
                  />
                </>
              ) : (
                <>
                  <AssignUserPopup
                    NumToAssign={item.virtualNumber}
                    groups={this.state.groups}
                    assignGroup={(id) => this.assignGroupHandler(item._id, id)}
                    assignUser={(userId) =>
                      this.assignUserHandler(item._id, userId)
                    }
                    isAgentsEnabled={false}
                    isGroupsEnabled={true}
                  />
                </>
              )}
            </DataTable.Cell> */}
          </DataTable.Row>
        ));
    }
    console.log({ groups: this.state.groups });
    return (
      <div className={`${this.props.className}`}>
        <Sidebar />
        <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />
          <div className="ContentContainer">
            <TabSearchBar tabs={tabs}>
              {/* <SearchBar
                type="outGoingNumbers"
                disableTitle
                count={this.state.count}
                reset={this.getFreshList}
                search={this.seachHandler}
              /> */}
            </TabSearchBar>
            <DataTable.Table>
              <DataTable.Head>
                <DataTable.CellHead style={{ paddingLeft: 25 }}>
                  NUMBER
                </DataTable.CellHead>
                <DataTable.CellHead>ASSIGNED GROUP</DataTable.CellHead>
                <DataTable.CellHead>EXPIRE DATE</DataTable.CellHead>
                {/* <DataTable.CellHead
                  style={{ textAlign: 'center', width: '28%' }}
                >
                  ACTIONS
                </DataTable.CellHead> */}
              </DataTable.Head>
              <DataTable.Body>{displayData}</DataTable.Body>
            </DataTable.Table>

            <Paginator
              currentPage={this.state.currentPage}
              lastPage={this.state.totalPages}
              getInfo={(value) => this.fetchPageData(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  call_forwarding_status: state.topbar.call_forwarding_status,
});

export default commonStyle(
  connect(mapStateToProps, {})(DoosraOutgoingNumbersPage)
);

import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';
const broadcastUrl = process.env.REACT_APP_BROADCAST_API;
const organisationId = localStorage.getItem('doosra-biz-organisation-id');

export default function VoiceBroadcastExportPopup(props) {
  const [list, setList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';
  let columns = [
    { id: 'id', displayName: 'id' },
    { id: 'name', displayName: 'name' },
    { id: 'description', displayName: 'description' },
    { id: 'status', displayName: 'status' },
    { id: 'total_leads', displayName: 'total_leads' },
    { id: 'leads_called', displayName: 'leads_called' },
    { id: 'leads_answered', displayName: 'leads_answered' },
    { id: 'dtmf_count', displayName: 'dtmf_count' },
    { id: 'leads_unanswered', displayName: 'leads_unanswered' },
    { id: 'created_at', displayName: 'created_at' },
  ];

  const openPopup = () => {
    setIsPopupOpen(true);
    const data = props?.filters;
    let input = {
      pageNo: 1,
      pageSize: 500,
      searchFor: data?.search_key,
      searchValue: data?.searchCondition,
      organisationId,
    };
    if (data?.searchKey === 'date') {
      input.from_date = data?.from_date;
      input.to_date = data?.to_date;
    }
    GET(`${broadcastUrl}v1/voice-broadcast/list`, input)
      .then((res) => {
        return res.data.response.list;
      })
      .then((res) => {
        setList(res.list);
        // setBroadcastName(res.name);
        console.log('-----', res.name);
        setDataFetched(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <button
        className="Otherbutton"
        style={{ backgroundColor: '#07aa3f', color: 'white' }}
        onClick={openPopup}
      >
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download campaign logs</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsPopupOpen(false);
                setDataFetched(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file.
            </p>
            <p className="PopupNote">Max download limit - Recent 500 entries</p>
            {dataFetched ? (
              <CsvDownloader
                filename={`voiceBroadcastCampaigns`}
                separator=","
                columns={columns}
                datas={
                  list.length
                    ? list.map((item) => {
                        return {
                          id: item?._id,
                          name: item?.name,
                          description: item?.description || '-',
                          status: item?.status || '-',
                          total_leads: item?.totalLeads || '-',
                          leads_called: item?.leadsCalled || '-',
                          leads_answered: item?.leadsAnswered || '-',
                          dtmf_count: item?.dtmfCount || '-',
                          leads_unanswered: item?.leadsUnanswered || '-',
                          created_at: item?.createdAt
                            ? new Date(item?.createdAt)
                                .toLocaleString()
                                .replace(',', '')
                            : '-',
                        };
                      })
                    : [{}]
                }
                className="ButtonFullWidth BgGreen"
                text="Download CSV"
              />
            ) : (
              <button
                className="ButtonFullWidth BgGrey"
                style={{ cursor: 'default' }}
              >
                SAVE
              </button>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { EditIcon } from '../../../assets/Icons/Icons';
import { toast } from 'react-toastify';
export default function UpdateAutoAttendantPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [autoAttendName, setAutoAttendantName] = useState('');
  const [audioDescription, setAutoAttendantDescription] = useState('');
  const [audioFile, setAudioFile] = useState(null);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setAutoAttendantName(props.autoAttendantData.name);
    setAutoAttendantDescription(props.autoAttendantData.description);
  }, [props.autoAttendantData]);

  const updateAutoAttendantHandler = () => {
    if (processing) return;
    // if (!autoAttendName) return toast.error('Provide auto attendant name');
    // if (!audioFile) return toast.error('Provide auto attendant audio file');
    setProcessing(true);
    const data = {
      name: autoAttendName,
      description: audioDescription,
      audio: audioFile,
      id: props.autoAttendantData._id,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    props
      .updateAutoAttendantHandler(data)
      .then((res) => {
        // if (res === true) {
        toast.success('Auto Attendant Successfully Updated');
        setAutoAttendantName('');
        setAutoAttendantDescription('');
        setAudioFile(null);
        setPopupOpen(false);
        setProcessing(false);
        // }
      })
      .catch((err) => {
        setProcessing(false);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    let size = 1;
    if (
      localStorage.getItem('doosra-biz-organisation-id') ===
        'd9c67ec2-fe1d-4284-89e6-acb173c55229' ||
      localStorage.getItem('doosra-biz-organisation-id') ===
        '259150fd-6c5a-4886-9eb7-901690b9f024'
    ) {
      size = 2;
    }

    if (file && file.size <= size * 1024 * 1024) {
      setAudioFile(file);
      setError(null);
    } else {
      setAudioFile(null);
      setError('Please upload an audio file within the size limit (1 MB).');
    }
  };

  const closePopup = () => {
    if (processing) return;
    setPopupOpen(false);
  };

  const popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="PopupContainer">
        <div className="PopupHeaderContainer">
          <p>Update Auto Attendant</p>
          <img alt="" className="PopupClose" src={Close} onClick={closePopup} />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Auto Attendant Name</label>
            <input
              className="PopupInput"
              id="emp_id"
              value={autoAttendName}
              onChange={(e) => setAutoAttendantName(e.target.value)}
            />
          </div>
          <div
            className="PopupInputContainer"
            // style={{ height: '180px', overflow: 'auto' }}
          >
            <label className="PopupInputLabel">Description</label>
            <input
              className="PopupInput"
              id="emp_id"
              value={audioDescription}
              onChange={(e) => setAutoAttendantDescription(e.target.value)}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Current audio</label>
            <div className="audio-container">
              <audio
                // ref={(audio) => (currentAudioRef.current[row._id] = audio)}
                style={{
                  height: '24px',
                  marginTop: '3px',
                  marginBottom: '-2px',
                }}
                controls
                src={props.autoAttendantAudio}
                // onPlay={() => stopAllAudio(row._id)}
                // onPause={() => setCurrentAudioId(null)}
                id={props.autoAttendantData._id}
              />
            </div>
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">
              New audio file (Max. 1 MB, .WAV format)
            </label>
            <input type="file" accept="audio/wav" onChange={handleFileChange} />
          </div>
          <div className="ErrorContainer ErrorContainerMargin">
            {error && (
              <React.Fragment>
                <img
                  alt=""
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
                {error}
              </React.Fragment>
            )}
          </div>
          <button
            className="ButtonFullWidth"
            style={{ marginTop: '10px' }}
            type="submit"
            onClick={updateAutoAttendantHandler}
            disabled={processing}
          >
            {processing ? 'UPDATING...' : 'UPDATE'}
          </button>
          <button className="ButtonCancelFullWidth" onClick={closePopup}>
            CANCEL
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <React.Fragment>
      <div style={{ paddingRight: '8px' }}>
        <EditIcon onClick={() => setPopupOpen(true)} />
      </div>
      {popper}
    </React.Fragment>
  );
}

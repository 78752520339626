import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';
import { ADMIN_EXPORT_AGENTS } from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';
const userUrl = process.env.REACT_APP_USER_API;

export default function ExportUsersPopup() {
  const [list, setList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';
  let columns = [
    { id: 'emp_id', displayName: 'EMPLOYEE ID' },
    { id: 'zvr_name', displayName: 'AGENT NAME' },
    { id: 'v_mobile_no', displayName: 'VIRTUAL NO' },
    { id: 'zvr_mobile_no', displayName: 'PERSONAL NO' },
    { id: 'created_at_date', displayName: 'ADDED ON (DATE)' },
    { id: 'created_at_time', displayName: 'ADDED ON (TIME)' },
  ];

  const openPopup = () => {
    logPostHogEvent(ADMIN_EXPORT_AGENTS);
    setIsPopupOpen(true);
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    GET(userUrl + `v1/user`, {
      pageNo: 1,
      pageSize: 1000,
      organisationId: orgId,
    })
      .then((res) => {
        return res.data.response.users.docs;
      })
      .then((res) => {
        setList(res);
        setDataFetched(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <button className="Otherbutton" onClick={openPopup}>
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download User data</p>
            <img
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsPopupOpen(false);
                setDataFetched(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file
            </p>
            <p className="PopupNote">
              Max download limit - Recent 1000 entries
            </p>
            {dataFetched ? (
              <CsvDownloader
                filename={`${org_name}_agents_list_${new Date().toLocaleDateString()}`}
                separator=","
                columns={columns}
                datas={
                  list.length
                    ? list.map((detail) => {
                        return {
                          ...detail,
                          emp_id: detail?.organisation?.emp_id || 'NA',
                          created_at_date: new Date(
                            detail.created_at
                          ).toLocaleDateString(),
                          created_at_time: new Date(
                            detail.created_at
                          ).toLocaleTimeString(),
                        };
                      })
                    : [{}]
                }
                className="ButtonFullWidth BgGreen"
                text="Download CSV"
              />
            ) : (
              <button
                className="ButtonFullWidth BgGrey"
                style={{ cursor: 'default' }}
              >
                SAVE
              </button>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}

const axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const header = {
  'Content-Type': 'application/json',
  'x-metro-api-key': API_KEY,
  'Cache-Control': 'no-cache',
};

function checkForToken() {
  window.checkIsLogin && window.checkIsLogin();
  if (
    localStorage.getItem('doosra-business-sessionToken') &&
    localStorage.getItem('doosra-business-sessionToken') !== ''
  ) {
    header['x-metro-sessiontoken'] = localStorage.getItem(
      'doosra-business-sessionToken'
    );
  }
}

function sessionExpired(err) {
  if (
    err.response &&
    err.response.data &&
    err.response.data.error &&
    err.response.data.error.code === 1004
  ) {
    localStorage.removeItem('doosra-business-sessionToken');
    localStorage.removeItem('doosra-biz-organisation-id');
    localStorage.removeItem('doosra-biz-user-id');
    localStorage.removeItem('doosra-biz-user-name');
    localStorage.removeItem('doosra-biz-user-email');
    localStorage.removeItem('doosra-biz-user-number');
    localStorage.removeItem('doosra-biz-user-role');
    localStorage.removeItem('doosra-biz-organisation-name');
    localStorage.removeItem('is_sms_api_enabled');
    localStorage.removeItem('is_call_api_enabled');
    window.location.reload();
  }
}

function GET(url, params = {}) {
  checkForToken();
  return axios
    .get(url, { headers: header, params: params, withCredentials: true })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // sessionExpired(err);
      throw err;
    });
}

function POST(url, params = {}) {
  checkForToken();
  return axios
    .post(url, params, { headers: header, withCredentials: true })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // sessionExpired(err);
      throw err;
    });
}

function PUT(url, params, updates, additionalHeaders = {}) {
  checkForToken();
  return axios
    .put(url, updates, {
      headers: { ...header, ...additionalHeaders },
      params: params,
      withCredentials: true,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      sessionExpired(err);
      throw err;
    });
}

function DELETE(url, params, data) {
  checkForToken();
  return axios
    .delete(url, {
      headers: header,
      params: params,
      withCredentials: true,
      data: data,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      sessionExpired(err);
      throw err;
    });
}

function setup_axios_interceptor(store, props) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.data?.error?.reason === 'invalid session token') {
        localStorage.clear();
        window.location.reload();
      }
      if (
        error?.response?.status === 503 &&
        error?.response?.data?.error?.code === 'MAINTENANCE_MODE'
      ) {
        store.dispatch({
          type: 'MAINTAINANCE',
          payload: error.response.data.error.reason,
        });
      }
      return Promise.reject(error);
    }
  );
}

export { setup_axios_interceptor, GET, POST, PUT, DELETE };

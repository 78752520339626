import React, { useState, useEffect } from 'react';
import Logo from '../../assets/images/alohaa_logo.svg';
import VMNLogo from '../../assets/images/phone_call.svg';
import IVRLogo from '../../assets/images/ivr_level.svg';
import CloudCenterLogo from '../../assets/images/cloud_call_center.svg';
import RoundCheck from '../../assets/images/red-checkmark-circle.svg';
import { GET, POST } from '../../services/HttpRequests';
// import ChargebeePortalButton from '../../common/component/chargbee/ChargebeePortalButton';
import { useChargebeePortal } from '../../common/component/chargbee/ChargebeePortalButton';
// import ChargebeeCheckoutButton from '../../common/component/chargbee/ChargebeeCheckoutButton';
import PreLoader from '../../common/component/preLoader/PreLoader';
const planUrl = process.env.REACT_APP_PLAN_API;
const PlanTab = ({ tab, index, selectedTab, setSelectedTab }) => (
  <div
    key={index}
    className={`plan-tab ${selectedTab === index ? 'active' : ''}`}
    onClick={() => setSelectedTab(index)}
  >
    <div className="tab-content">
      <img src={tab.logo} alt={`${tab.title} Logo`} className="tab-logo" />
      <div className="tab-text-content">
        <div className="tab-title">{tab.title}</div>
        <div className="tab-price">{tab.plans[0].price}</div>
      </div>
    </div>
  </div>
);

const PlanCard = ({ plan, subscribe }) => (
  <div key={plan.id} className="plan-card">
    <h3 className="plan-card-title center">{plan.name}</h3>
    <ul className="plan-features">
      {plan.features.map((feature, featureIndex) => (
        <li key={featureIndex} className="feature-list-item">
          <img src={RoundCheck} alt="Check" className="check-mark" /> {feature}
        </li>
      ))}
    </ul>
    <div className="plan-card-bottom">
      <div className="plan-card-price">{plan.price}</div>
      <button className="get-started-btn" onClick={() => subscribe(plan)}>
        GET STARTED
      </button>
    </div>
  </div>
);

function PlansPage(props) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabData, setTabData] = useState([]);
  const [loading, setLoading] = useState(true);
  const chargebeePortal = useChargebeePortal();
  const plansList = [
    {
      title: 'Virtual Number',
      logo: VMNLogo,
      description:
        'The starter virtual number plan is best suited for digitally enabled small businesses like boutique niche instagram run businesses, or physical based brick and mortar stores.',
      plans: [
        // {
        //   name: 'Starter',
        //   price: '₹999/year',
        //   features: ['1 Virtual Number', 'Unlimited incoming SMSes'],
        //   id: '',
        // },
        {
          name: 'Advanced',
          price: '₹999/year',
          id: '',
          features: [
            '1 Virtual Number',
            'Unlimited incoming SMSes',
            'App and dashboard access',
          ],
        },
      ],
    },
    {
      title: 'Multi-level IVR',
      logo: IVRLogo,
      description:
        'The multi-level IVR plan is best suited for digitally enabled small businesses like boutique niche instagram run businesses, or physical based brick and mortar stores.',
      plans: [
        // {
        //   name: 'Starter',
        //   price: '₹999/year',
        //   id: '',
        //   features: ['1 Virtual Number', 'Unlimited incoming SMSes'],
        // },
        {
          name: 'Advanced',
          price: '₹999/year',
          id: '',
          features: [
            '1 Virtual Number',
            'Unlimited incoming SMSes',
            'App and dashboard access',
          ],
        },
      ],
    },
    {
      title: 'Cloud call centre',
      logo: CloudCenterLogo,
      description:
        'The cloud call centre plan is best suited for digitally enabled small businesses like boutique niche instagram run businesses, or physical based brick and mortar stores.',
      plans: [
        // {
        //   name: 'Starter',
        //   price: '₹999/year',
        //   id: '',
        //   features: ['1 Virtual Number', 'Unlimited incoming SMSes', 'Limited'],
        // },
        {
          name: 'Advanced',
          price: '₹25/month',
          id: '',
          features: [
            '1 Virtual Number',
            'Unlimited incoming SMSes',
            'Unlimited',
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    async function fetchPlans() {
      setLoading(true);
      try {
        const { data } = await GET(`${planUrl}v1/alohaa/chargebee-plans`);
        if (data && data.response && data.response.plans) {
          const responsePlans = data.response.plans.reverse();
          const updatedPlansList = plansList.map((plan, index) => {
            if (responsePlans[index]) {
              const responsePlan = responsePlans[index];
              const updatedDescription =
                responsePlan.item.description || plan.description;

              const updatedPlanItems = plan.plans.map((item, itemIndex) => {
                if (
                  responsePlan.itemPrices &&
                  responsePlan.itemPrices[itemIndex]
                ) {
                  const priceDetail = responsePlan.itemPrices[itemIndex];
                  const period = priceDetail.item_price.period_unit || 'year';
                  const price = (priceDetail.item_price.price / 100).toFixed(2);
                  const updatedPrice = `₹${price}/${period}`;
                  const updatedId = priceDetail.item_price.id || item.id;
                  return {
                    ...item,
                    price: updatedPrice,
                    id: updatedId,
                    period: period,
                  };
                }
                return item;
              });

              return {
                ...plan,
                description: updatedDescription,
                plans: updatedPlanItems,
              };
            }
            return plan;
          });

          setTabData(updatedPlansList);
        }
      } catch (error) {
        console.error('Error fetching plans:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchPlans();
  }, []);

  const getCustomerSubscription = async (customerId, plan) => {
    try {
      const { data } = await GET(
        `${planUrl}v1/alohaa/active-subscription?customerId=${customerId}&planId=${plan.id}`
      );
      return data && data.response ? data.response.subscription : null;
    } catch (error) {
      console.error('Error fetching subscription:', error);
      return null;
    }
  };

  const subscribe = async (plan) => {
    // const customerId = localStorage.getItem('doosra-biz-organisation-id');
    setLoading(true);
    // const subscription = await getCustomerSubscription(customerId, plan);

    // if (subscription && subscription.length) {
    //   localStorage.setItem("chargebee-customer-selected-planId", plan.id);
    //   setTimeout(chargebeePortal, 700);
    //   setTimeout(() => setLoading(false), 1400);
    // } else {
    //   try {
    //     // const data = {
    //     //   planId: plan.id,
    //     //   customerId,
    //     //   subscriptionType: plan.period === 'month' ? 'monthly' : 'yearly',
    //     //   addons: [],
    //     // };
    //     localStorage.setItem("chargebee-customer-selected-planId", plan.id);
    //     // await POST(`${planUrl}v1/alohaa/create-subscription`, data);
    //     setTimeout(chargebeePortal, 700);
    //     setTimeout(() => setLoading(false), 1200);
    //   } catch (error) {
    //     console.error('Error creating subscription:', error);
    //     setLoading(false);
    //   }
    // }
    try {
      localStorage.setItem('chargebee-customer-selected-planId', plan.id);
      setTimeout(chargebeePortal, 700);
      setTimeout(() => setLoading(false), 1200);
    } catch (error) {
      console.error('Error creating subscription:', error);
      setLoading(false);
    }
  };

  return (
    <div className="plan-container">
      {loading ? (
        <PreLoader />
      ) : (
        <>
          <img src={Logo} alt="Logo" className="plan-logo" />
          <p className="plan-intro-text">
            Choose a product that fits your business goals
          </p>
          <div className="plan-tabs">
            {tabData.map((tab, index) => (
              <PlanTab
                key={index}
                {...{ tab, index, selectedTab, setSelectedTab }}
              />
            ))}
          </div>
          <hr className="plan-separator" />
          <p className="plan-description">{tabData[selectedTab].description}</p>
          <div className="plan-cards">
            {tabData[selectedTab].plans.map((plan) => (
              <PlanCard key={plan.id} plan={plan} subscribe={subscribe} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default PlansPage;

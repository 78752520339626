import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import { MinusRedIcon, PlusGreenIcon } from '../../../assets/Icons/Icons';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import '../component/Tooltip.css';
import { isSuperAdminWithPermission } from '../../../utility/utility';

class BatchCallPopup extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      step: 1,
      error: '',
      notSelectedDispositions: [],
      selectedDispositions: [],
    };
    this.initialState = {
      popupOpen: false,
      step: 1,
      error: '',
      notSelectedDispositions: [],
      selectedDispositions: [],
    };
  }

  onClickSelectedDispositionToAddDisposition = (item) => {
    const selectedDispositions = this.state.selectedDispositions;
    const remainDispositions = this.state.notSelectedDispositions;
    remainDispositions.push(item);
    const index = selectedDispositions.findIndex(
      (disposition) => disposition === item
    );
    selectedDispositions.splice(index, 1);
    this.setState({
      selectedDispositions: selectedDispositions,
      notSelectedDispositions: remainDispositions,
    });
  };

  onClickAddDispositionToSelectedDisposition = (item) => {
    const userSelectedDispositions = this.state.selectedDispositions;
    userSelectedDispositions.push(item);
    const index = this.state.notSelectedDispositions.findIndex(
      (disposition) => disposition === item
    );
    const remainUser = this.state.notSelectedDispositions;
    remainUser.splice(index, 1);
    this.setState({
      selectedDispositions: userSelectedDispositions,
      notSelectedDispositions: remainUser,
    });
  };

  selectedDispositionsListJSX = () => {
    const result = this.state.selectedDispositions;
    return result.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {item}
          </p>
          <p style={{ height: '28px', width: '128px', margin: '8px 92px' }}>
            <MinusRedIcon
              onClick={() =>
                this.onClickSelectedDispositionToAddDisposition(item)
              }
            />
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };

  remainDispositionsListJSX = () => {
    const result = this.state.notSelectedDispositions;
    return result.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {item}
          </p>
          <p style={{ height: '28px', width: '128px', margin: '8px 92px' }}>
            <PlusGreenIcon
              onClick={() =>
                this.onClickAddDispositionToSelectedDisposition(item)
              }
            />
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };

  openPopup = () => {
    this.setState({ popupOpen: true });
    const dispositions = this.props.dispositions.map((item) => item.dispositon);
    this.setState({ notSelectedDispositions: dispositions });
  };
  closePopup = () => {
    this.setState({ selectedDispositions: [] });
    this.setState(this.initialState);
  };
  switchStepHandler = (step) => {
    this.setState({ step: step });
  };

  initiateBatchCallHandler = () => {
    this.props.batchCallHandler();
    this.closePopup();
  };

  resumeBatchCallHandler = () => {
    this.props.resumeBatchCallHanlder();
    this.closePopup();
  };

  filterDataHandler = () => {
    this.props.filterAndInitiatebatchCallHandler(
      this.state.selectedDispositions
    );
    this.closePopup();
  };

  render() {
    let currentView;
    switch (this.state.step) {
      case 3:
        currentView = (
          <div className="PopupContainer" style={{ overflow: 'auto' }}>
            <div className="PopupHeaderContainer">
              <p>ALERT</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            <div className="PopupBody">
              <div className="PopupInputContainer">
                <p style={{ margin: 'inherit', fontWeight: 500 }}>
                  {' '}
                  Are you sure you want to call all the leads ?
                </p>
              </div>
              {this.state.error ? (
                <div className="ErrorContainer ErrorContainerMargin">
                  <img
                    alt=""
                    src={InfoRed}
                    style={{
                      paddingRight: '5px',
                      position: 'relative',
                      top: '-2px',
                    }}
                  />
                  {this.state.error}
                </div>
              ) : null}
              <button
                className="ButtonFullWidth BgGreen"
                onClick={this.initiateBatchCallHandler}
                style={{ marginTop: '20px' }}
              >
                CALL
              </button>
            </div>
          </div>
        );
        break;
      case 4:
        currentView = (
          <div className="PopupContainer" style={{ overflow: 'auto' }}>
            <div className="PopupHeaderContainer">
              <p>ALERT</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            <div className="PopupBody">
              <div className="PopupInputContainer">
                <p style={{ margin: 'inherit', fontWeight: 500 }}>
                  {' '}
                  Are you sure resume the batch call ?
                </p>
              </div>
              {this.state.error ? (
                <div className="ErrorContainer ErrorContainerMargin">
                  <img
                    alt=""
                    src={InfoRed}
                    style={{
                      paddingRight: '5px',
                      position: 'relative',
                      top: '-2px',
                    }}
                  />
                  {this.state.error}
                </div>
              ) : null}
              {this.props.resumeBatchCallId.length === 0 ? (
                <button
                  className="ButtonFullWidth BgGreen"
                  onClick={this.resumeBatchCallHandler}
                  style={{ marginTop: '20px', opacity: '0.5' }}
                  disabled={true}
                >
                  RESUME
                </button>
              ) : (
                <button
                  className="ButtonFullWidth BgGreen"
                  onClick={this.resumeBatchCallHandler}
                  style={{ marginTop: '20px' }}
                >
                  RESUME
                </button>
              )}
            </div>
          </div>
        );
        break;
      case 2:
        currentView = (
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>Select Disposition</p>
              <img
                className="PopupClose"
                alt="Close Icon"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            <div className="PopupBody">
              {this.state.selectedDispositions.length > 0 && (
                <>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '60% 40%',
                      textAlign: 'center',
                      fontSize: '12px',
                      'letter-spacing': '1.2px',
                    }}
                    className="PopupColumnHeaders"
                  >
                    <p
                      style={{
                        margin: '10px',
                        textAlign: 'left',
                        paddingLeft: '20px',
                      }}
                    >
                      SELECTED DISPOSITION
                    </p>
                    <p
                      style={{
                        margin: '10px',
                        textAlign: 'right',
                        paddingRight: '10px',
                      }}
                    >
                      ADD/REMOVE
                    </p>
                  </div>
                  <div
                    style={{
                      maxHeight: '150px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    {this.selectedDispositionsListJSX()}
                  </div>
                </>
              )}
              {this.state.notSelectedDispositions.length > 0 && (
                <>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '60% 40%',
                      textAlign: 'center',
                      fontSize: '12px',
                      'letter-spacing': '1.2px',
                    }}
                    className="PopupColumnHeaders"
                  >
                    <p
                      style={{
                        margin: '10px',
                        textAlign: 'left',
                        paddingLeft: '20px',
                      }}
                    >
                      AVAILABLE DISPOSITIONS
                    </p>
                    <p
                      style={{
                        margin: '10px',
                        textAlign: 'right',
                        paddingRight: '10px',
                      }}
                    >
                      ADD/REMOVE
                    </p>
                  </div>
                  <div
                    style={{
                      maxHeight: '150px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    {this.remainDispositionsListJSX()}
                  </div>
                </>
              )}
              <div className="PopupInputContainer" style={{ color: 'red' }}>
                {this.state.error}
              </div>
              <button
                className={`ButtonFullWidth`}
                onClick={this.filterDataHandler}
              >
                FILTER OUT SELECTED
              </button>

              <button
                className="ButtonCancelFullWidth"
                onClick={this.closePopup}
              >
                Cancel
              </button>
            </div>
          </div>
        );
        break;
      default:
      case 1:
        currentView = (
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>START BATCH CALLS</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            <div className="PopupBody">
              <button
                className="AddUserFullWidth"
                style={{ marginTop: '15px' }}
                onClick={() => this.switchStepHandler(3)}
              >
                CALL ALL LEADS
              </button>
              <button
                className="AddUserFullWidth"
                style={{ marginTop: '15px' }}
                onClick={() => this.switchStepHandler(2)}
              >
                FILTER LEADS BASED ON DISPOSITION
              </button>
              <button
                className="AddUserFullWidth"
                style={{ marginTop: '15px' }}
                onClick={() => this.switchStepHandler(4)}
              >
                RESUME PREVIOUS BATCH CALL
              </button>
            </div>
          </div>
        );
        break;
    }

    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        {currentView}
      </Popup>
    );
    return (
      <div style={{ display: 'flex' }}>
        <span>
          <div className="tooltip-container">
            <div className="tooltip-button">
              <button
                className="Otherbutton"
                style={{
                  marginRight: 0,
                  width: 200,
                  color: 'white',
                  backgroundColor:
                    !this.props.isConnected ? 'lightgrey' : (this.props.isActive === false ||
                      this.props.isValidCampaign === false)
                      ? 'lightgrey'
                      : '#07aa3f',
                }}
                onClick={this.openPopup}
                disabled={
                  !this.props.isConnected ? true : (this.props.isActive === false ||
                    this.props.isValidCampaign === false)
                }
                title={!this.props.isConnected ? "Connect to server and start calling" : ""}
              >
                Start Calling Leads
              </button>
            </div>
            <div
              className="tooltip-text left"
              data-tip-position="left"
              style={{ fontSize: '12px' }}
            >
              {this.props.isActive === true
                ? this.props.isValidCampaign === true
                  ? 'Start Call'
                  : 'campaign is not valid'
                : 'You are in Inactive Status'}
              <span className="arrow-right"></span>
            </div>
          </div>
          {popper}
        </span>
      </div>
    );
  }
}

export default BatchCallPopup;

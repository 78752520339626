import React, { Component } from 'react';
import {Form, Pagination } from 'react-bootstrap';
import styled from '@emotion/styled';
import '../../../assets/css/Popups.css';
class PaginationBar extends Component {
  jumpTo(pageNo) {
    this.props.getInfo(pageNo);
  }

  render() {
    let selectOption = (
      <React.Fragment>
        <React.Fragment>
          {this.props.currentPage - 2 <= 0 ? (
            <Pagination.Item disabled={true}>.</Pagination.Item>
          ) : (
            <Pagination.Item
              onClick={() => this.jumpTo(this.props.currentPage - 2)}
            >
              {this.props.currentPage - 2}
            </Pagination.Item>
          )}
        </React.Fragment>
        <React.Fragment>
          {this.props.currentPage - 1 <= 0 ? (
            <Pagination.Item disabled={true}>.</Pagination.Item>
          ) : (
            <Pagination.Item
              onClick={() => this.jumpTo(this.props.currentPage - 1)}
            >
              {this.props.currentPage - 1}
            </Pagination.Item>
          )}
        </React.Fragment>
        <Pagination.Item active>{this.props.currentPage}</Pagination.Item>
        <React.Fragment>
          {this.props.currentPage + 1 <= this.props.lastPage ? (
            <Pagination.Item
              onClick={() => this.jumpTo(this.props.currentPage + 1)}
            >
              {this.props.currentPage + 1}
            </Pagination.Item>
          ) : (
            <Pagination.Item disabled={true}>.</Pagination.Item>
          )}
        </React.Fragment>
        <React.Fragment>
          {this.props.currentPage + 2 <= this.props.lastPage ? (
            <Pagination.Item
              onClick={() => this.jumpTo(this.props.currentPage + 2)}
            >
              {this.props.currentPage + 2}
            </Pagination.Item>
          ) : (
            <Pagination.Item disabled={true}>.</Pagination.Item>
          )}
        </React.Fragment>
      </React.Fragment>
    );

    return (
      <div className={`${this.props.className}`}>
        <div className="PaginationBarContainer">
          <div style={{ margin: 'auto' }}>
            <Pagination style={{ marginBottom: 0 }}>
              <Pagination.First
                onClick={() => this.jumpTo(1)}
                disabled={this.props.currentPage - 1 === 0}
              />
              <Pagination.Prev
                onClick={() => this.jumpTo(this.props.currentPage - 1)}
                disabled={this.props.currentPage - 1 === 0}
              />
              {selectOption}
              <Pagination.Next
                onClick={() => this.jumpTo(this.props.currentPage + 1)}
                disabled={this.props.currentPage === this.props.lastPage}
              />
              <Pagination.Last
                onClick={() => this.jumpTo(this.props.lastPage)}
                disabled={this.props.currentPage === this.props.lastPage}
              />
              {
                this.props.isRecoveryCallLogsPage ? 
                <div>
                <label >
                  Page Size :
                  <select className='SearchDropdown' onChange={this.props.setPaginationSize}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                  </select>
                </label>
                </div> : null
                }
            </Pagination>
          </div>
        </div>
      </div>
    );
  }
}

export default styled(PaginationBar)`
  .PaginationBarContainer {
    width: 100%;
    height: 48px;
    background-color: ${(props) => props.theme.colors.columnHeaderBg};
    display: flex;
    justify-content: center;
    margin-top: auto;
    position: fixed;
    bottom: 0;
  }

  .PaginationPageBox {
    max-width: 32px;
    max-height: 32px;
    margin: auto 5px;
    padding: 10px 15px;
    background-color: var(--mid-grey-color);
  }
  .pagination {
    margin-bottom: 0px;
    margin-right: 268px;
    display: flex;
    padding-left: 18px;
    list-style: none;
  }
  .page-item {
    margin-right: 16px;
  }

  .page-item.disabled .page-link {
    height: 32px;
    width: 32px;
    border-radius: 40px;
    display: inline-block;
    pointer-events: none;
    cursor: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.paginationBoxBgColor};
    border-color: ${(props) => props.theme.colors.paginationBoxBgColor};
    color: ${(props) => props.theme.colors.paginationBoxTextColor};
  }

  .page-item .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    height: 32px;
    width: 32px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: ${(props) => props.theme.colors.paginationBoxBgColor};
    border-color: ${(props) => props.theme.colors.paginationBoxBgColor};
    color: ${(props) => props.theme.colors.paginationBoxTextColor};
  }

  .page-link:focus {
    outline: none;
    box-shadow: none;
  }

  .page-item.active .page-link {
    height: 32px;
    width: 32px;
    border-radius: 40px;
    display: inline-block;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.paginationBoxBgColor};
    border-color: ${(props) => props.theme.colors.paginationBoxBgColor};
    color: ${(props) => props.theme.colors.paginationBoxTextColor};
  }

  .SearchDropdown {
    width: 136px;
    height: 32px;
    font-size: 13px;
    margin: auto 5px;
    padding: 0 5px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
    background-color: ${(props) => props.theme.colors.inputBgColor};
    color: ${(props) => props.theme.colors.inputPlaceholderColor};
  }

  @media only screen and (max-width: 900px) {
    .PaginationBarContainer {
      width: 798px;
    }
  }
`;

import { combineReducers } from 'redux';
import sidebar from './sidebar';
import system from './system';
import topbar from './topBar';
import recovery from './recoveryReducer';
import onboardingtour from './onboardingtour';
import incoming from './incomingReducer';
import dailPadReducer from './dailPadReducer';

const appReducer = combineReducers({
  sidebar: sidebar,
  topbar: topbar,
  system: system,
  recovery: recovery,
  onboardingtour: onboardingtour,
  incoming: incoming,
  dailpad: dailPadReducer,
});

export default appReducer;

export const PLACE_AN_OUTGOING_CALL_POPUP_BUTTON = "Place_An_Outgoing_Call_Popup_Button";
export const OUTGOING_CALL_BUTTON = "Outgoing_Call_Button";
export const SIDEBAR_BUTTON = "Sidebar_Button";
export const ADMIN_SIDEBAR_BUTTON = "Admin_Sidebar_Button";
export const AVAILABILITY_TOGGLE = "Availability_Toggle";
export const ADD_CONTACT_BUTTON = "Add_Contact_Button";
export const SAVE_CONTACT_BUTTON = "Save_Contact_Button";
export const ADMIN_SAVE_CONTACT_BUTTON = "Admin_Save_Contact_Button";
export const VIEW_CONTACT_BUTTON = "View_Contact_Button";
export const ADMIN_VIEW_CONTACT_BUTTON = "Admin_View_Contact_Button";
export const EDIT_CONTACT_BUTTON = "Edit_Contact_Button";
export const ADMIN_EDIT_CONTACT_BUTTON = "Admin_Edit_Contact_Button";
export const DELETE_CONTACT_BUTTON = "Delete_Contact_Button";
export const ADMIN_DELETE_CONTACT_BUTTON = "Admin_Delete_Contact_Button";
export const ADD_ANOTHER_CONTACT_BUTTON = "Add_Another_Contact_Button";
export const ADMIN_ADD_ANOTHER_CONTACT_BUTTON = "Admin_Add_Another_Contact_Button";
export const SEARCH_CONTACT_BUTTON = "Search_Contact_Button";
export const ADD_ANOTHER_TAG_BUTTON = "Add_Another_Tag_Button";
export const SAVE_TAG_BUTTON = "Save_Tag_Button";
export const DELETE_TAG_BUTTON = "Delete_Tag_Button";
export const ADD_ANOTHER_NOTES_BUTTON = "Add_Another_Notes_Button";
export const ADMIN_ADD_ANOTHER_NOTES_BUTTON = "Admin_Add_Another_Notes_Button";
export const SAVE_NOTES_BUTTON = "Save_Notes_Button";
export const DELETE_NOTES_BUTTON = "Delete_Notes_Button";
export const CALL_LOGS_EXPORT_BUTTON = "Call_Logs_Export_Button";
export const ADMIN_CALL_LOGS_EXPORT_BUTTON = "Admin_Call_Logs_Export_Button";
export const SEARCH_CALL_LOG_BUTTON = "Search_Call_Log_Button";
export const ADMIN_SEARCH_CALL_LOG_BUTTON = "Admin_Search_Call_Log_Button";
export const INCOMING_TAB = "Incoming_Tab";
export const OUTGOING_TAB = "Outgoing_Tab";
export const DID_CALL_BACK_TAB = "Did_Call_Back_Tab";
export const ADMIN_INCOMING_TAB = "Admin_Incoming_Tab";
export const ADMIN_OUTGOING_TAB = "Admin_Outgoing_Tab";
export const ADMIN_DID_CALL_BACK_TAB = "Admin_Did_Call_Back_Tab";
export const ADMIN_BLACKLIST_TAB = "Admin_Blacklist_Tab";
export const CALL_RECORDING_PLAY_BUTTON = "Call_Recording_Play_Button";
export const ADMIN_CALL_RECORDING_PLAY_BUTTON = "Admin_Call_Recording_Play_Button";
export const ADMIN_BLACKLIST_ADD_NUMBER_BUTTON = "Admin_Blacklist_Add_Number_Button";
export const ADMIN_BLACKLIST_EDIT_NUMBER_BUTTON = "Admin_Blacklist_Edit_Number_Button";
export const ADMIN_BLACKLIST_DELETE_NUMBER_BUTTON = "Admin_Blacklist_Delete_Number_Button";
export const ADMIN_BLACKLIST_BLOCK_NUMBER_TOGGLE = "Admin_Blacklist_Block_Number_Toggle";
export const ADMIN_BLACKLIST_UNBLOCK_NUMBER_TOGGLE = "Admin_Blacklist_Unblock_Number_Toggle";
export const ADMIN_VIRTUAL_NUMBERS_TAB = "Admin_Virtual_Numbers_Tab";
export const ADMIN_OUTGOING_NUMBERS_TAB = "Admin_Outgoing_Numbers_Tab";
export const ADMIN_SEARCH_VIRTUAL_NUMBERS_BUTTON = "Admin_Search_Virtual_Numbers_Button";
export const ADMIN_ASSIGN_NUMBER_BUTTON = "Admin_Assign_Number_Button";
export const ADMIN_REASSIGN_NUMBER_BUTTON = "Admin_Reassign_Number_Button";
export const ADMIN_UNASSIGN_NUMBER_BUTTON = "Admin_Unassign_Number_Button";
export const ADMIN_ASSIGN_NUMBER = "Admin_Assign_Number";
export const ADMIN_ADD_AGENT = "Admin_Add_Agent";
export const ADMIN_EXPORT_AGENTS = "Admin_EXPORTS_AGENTS";
export const ADMIN_SEARCH_AGENTS = "Admin_SEARCH_AGENTS";
export const ADMIN_ADD_AGENT_MANUALLY = "Admin_Add_Agent_Manually";
export const ADMIN_ADD_AGENT_UPLOAD_CSV = "Admin_Add_Agent_Upload_Csv";
export const ADMIN_AGENT_OUTGOING_TOGGLE = "Admin_Agent_Outgoing_Toggle";
export const ADMIN_AGENT_INCOMING_TOGGLE = "Admin_Agent_Incoming_Toggle";
export const ADMIN_AGENT_AVAILABILITY_TOGGLE = "Admin_Agent_Availability_Toggle";
export const ADMIN_AGENT_SMS_DELETION_TOGGLE = "Admin_Agent_Sms_Deletion_Toggle";
export const ADMIN_AGENT_UPDATE_CALL_BALANCE_BUTTON = "Admin_Agent_Update_Call_Balance_Button";
export const ADMIN_UPDATE_AGENT_BUTTON = "Admin_Update_Agent_Button";
export const ADMIN_UPLOAD_CONTACT_BUTTON = "Admin_Upload_Contact_Button";
export const ADMIN_UPLOAD_CONTACT_SAVE_BUTTON = "Admin_Upload_Contact_Save_Button";
export const ADMIN_EXPORT_CONTACT_BUTTON = "Admin_Export_Contact_Button";
export const ADMIN_SEARCH_CONTACT_BUTTON = "Admin_Search_Contact_Button";
export const ADMIN_GLOBAL_CALLERTUNE_TAB = "Admin_Global_Callertune_Tab";
export const ADMIN_UPLOAD_GLOBAL_CALLERTUNE_BUTTON = "Admin_Upload_Global_Callertune_Button";
export const ADMIN_REMOVE_GLOBAL_CALLERTUNE_BUTTON = "Admin_Remove_Global_Callertune_Button";
export const ADMIN_GROUP_CALLERTUNE_TAB = "Admin_Group_Callertune_Tab";
export const ADMIN_GROUP_CALLERTUNE_SEARCH_BUTTON = "Admin_Group_Callertune_Search_Button";
export const ADMIN_ADD_GROUP_CALLERTUNE_BUTTON = "Admin_Add_Group_Callertune_Button";
export const ADMIN_REMOVE_GROUP_CALLERTUNE_BUTTON = "Admin_Remove_Group_Callertune_Button";
export const ADMIN_GROUP_CALLERTUNE_STATUS_TOGGLE = "Admin_Group_Callertune_Status_Toggle";
export const ADMIN_GROUP_CALLERTUNE_UPLOAD_BUTTON = "Admin_Group_Callertune_Upload_Button";
export const ADMIN_AGENT_CALLERTUNE_TAB = "Admin_Agent_Callertune_Tab";
export const ADMIN_AGENT_CALLERTUNE_SEARCH_BUTTON = "Admin_Agent_Callertune_Search_Button";
export const ADMIN_ADD_AGENT_CALLERTUNE_BUTTON = "Admin_Add_Agent_Callertune_Button";
export const ADMIN_REMOVE_AGENT_CALLERTUNE_BUTTON = "Admin_Remove_Agent_Callertune_Button";
export const ADMIN_AGENT_CALLERTUNE_UPLOAD_BUTTON = "Admin_Agent_Callertune_Upload_Button";
export const ADMIN_GROUP_SEARCH_BUTTON = "Admin_Group_Search_Button";
export const ADMIN_ADD_GROUP_BUTTON = "Admin_ADD_Group_Button";
export const ADMIN_GROUP_TYPE_BUTTON = "Admin_ADD_Group_Button";
export const ADMIN_SAVE_GROUP_BUTTON = "Admin_Save_Group_Button";
export const ADMIN_EDIT_GROUP_BUTTON = "Admin_Edit_Group_Button";
export const ADMIN_EDIT_GROUP_MEMBERS_BUTTON = "Admin_Edit_Group_Members_Button";
export const ADMIN_GROUP_DELETE_BUTTON = "Admin_Group_Delete_Button";
export const ADMIN_INCOMING_WEBHOOK_DAILED_ON_AGENT_TOGGLE = "Admin_Incoming_Webhook_Dailed_On_Agent_Toggle";
export const ADMIN_WEBHOOK_EDIT_BUTTON = "Admin_Webhook_Edit_Button";
export const ADMIN_WEBHOOK_UPDATE_BUTTON = "Admin_Webhook_Update_Button";
export const ADMIN_ADD_AUTO_ATTENDANT_BUTTON = "Admin_Add_Auto_Attendant_Button";
export const ADMIN_SAVE_AUTO_ATTENDANT_BUTTON = "Admin_Save_Auto_Attendant_Button";
export const ADMIN_SEARCH_AUTO_ATTENDANT_BUTTON = "Admin_Search_Auto_Attendant_Button";
export const ADMIN_EDIT_AUTO_ATTENDANT_BUTTON = "Admin_Edit_Auto_Attendant_Button";
export const ADMIN_DELETE_AUTO_ATTENDANT_BUTTON = "Admin_Delete_Auto_Attendant_Button";
export const ADMIN_ADD_CALL_BALANCE_BUTTON = "Admin_Add_Call_Balance_Button";
export const ADMIN_ORGANIZATIONAL_HOUR_BUTTON = "Admin_Organizational_Hour_Button";
export const ADMIN_ORGANIZATIONAL_DAY_BUTTON = "Admin_Org_Day_Button";
export const ADMIN_ORGANIZATIONAL_HOLIDAY_ADD_BUTTON = "Admin_holiday_Add_Button";
export const ADMIN_ORGANIZATIONAL_HOLIDAY_DELETE_BUTTON = "Admin_holiday_Delete_Button";
export const ADMIN_ORGANIZATIONAL_HOUR_HOLIDAY_SAVE_BUTTON = "Admin_Org_Hour_Day_Holiday_Save_Button";

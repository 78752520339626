/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './styles.css';
import '../../../assets/css/Popups.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import historyIcon from '../../../assets/images/callHistoryRecoveryCall.svg';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import whiteLoader from '../../../assets/images/white_preloader_s.gif';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import DataTable from '../../../common/component/table/DataTable';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import AudioPlayer from './AudioPlayer';

export default function AllLeadsCallHistoryPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.keyCode === 27) {
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const hanldeClick = () => {
    setPopupOpen(true);
    setLoading(true);
    let data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      number: props.destinationNumber,
      secondaryNumber: props.secondaryNumber,
    };
    GET(outGoingUrl + 'v1/organisations/outbound-call/get-call-history', data)
      .then((res) => {
        setLoading(false);
        return res.data.response;
      })
      .then((res) => {
        setLoading(false);
        setSelectedData(res);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const statusMapper = {
    answered: 'Answered',
    not_answered: 'Not Answered',
  };
  const PopupContainer = {
    width: '1200px',
  };
  const scrollEffect = {
    overflowY: 'auto',
  };
  let displayData;
  // if (loading) displayData = <PreLoader />;
  if (selectedData.length > 0) {
    displayData = selectedData.map((row) => (
      <DataTable.Row>
        <DataTable.Cell>{row.userObj.zvr_name || '-'}</DataTable.Cell>
        <DataTable.Cell>{row.results.leadListName || '-'}</DataTable.Cell>
        <DataTable.Cell>{row.destinationNumber || '-'}</DataTable.Cell>
        <DataTable.Cell>{statusMapper[row.status] || '-'}</DataTable.Cell>
        <DataTable.Cell>{row.duration || '-'}</DataTable.Cell>
        <DataTable.Cell>
          {new Date(row.createdAt).toLocaleDateString() || '-'}
        </DataTable.Cell>
        <DataTable.Cell>
          {new Date(row.createdAt).toLocaleTimeString() || '-'}
        </DataTable.Cell>
        <DataTable.Cell>{row.callTag || '-'}</DataTable.Cell>
        <DataTable.Cell>{row.callComment || '-'}</DataTable.Cell>
        <DataTable.Cell>
          {row.recording ? (
            <AudioPlayer callRecordingUrl={row?.recording} callId={row._id} />
          ) : (
            '-'
          )}
        </DataTable.Cell>
      </DataTable.Row>
    ));
  }
  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div
        className="PopupContainer"
        style={{ width: '1200px', overflow: 'scroll' }}
      >
        <div className="PopupHeaderContainer" style={{ ...PopupContainer }}>
          <p>Call History</p>
          <img
            style={{ left: '95%' }}
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => setPopupOpen(false)}
          />
        </div>
        {loading && (
          <center>
            <img alt="" src={whiteLoader} />
          </center>
        )}
        {!loading && (
          <div className="PopupBody" style={{ ...PopupContainer }}>
            <DataTable.Table style={{ ...PopupContainer }}>
              <DataTable.Head>
                <DataTable.CellHead>INITIATOR NAME</DataTable.CellHead>
                <DataTable.CellHead>LEAD LIST NAME</DataTable.CellHead>
                <DataTable.CellHead>CUSTOMER PRIMARY NO</DataTable.CellHead>
                <DataTable.CellHead>CALL STATUS</DataTable.CellHead>
                <DataTable.CellHead>DURATION (SECONDS)</DataTable.CellHead>
                <DataTable.CellHead>DATE</DataTable.CellHead>
                <DataTable.CellHead>TIME</DataTable.CellHead>
                <DataTable.CellHead>DISPOSITION</DataTable.CellHead>
                <DataTable.CellHead>COMMENT</DataTable.CellHead>
                <DataTable.CellHead>RECORDING</DataTable.CellHead>
              </DataTable.Head>
              <DataTable.Body>{displayData}</DataTable.Body>
            </DataTable.Table>
          </div>
        )}
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex' }}>
      <span>
        <button
          className="Otherbutton"
          style={{
            marginRight: 0,
            width: '32px',
            height: '32px',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            setPopupOpen(true);
            hanldeClick();
          }}
        >
          <img
            alt=""
            src={historyIcon}
            style={{ width: '16px', height: '16px' }}
          />
        </button>
        {popper}
      </span>
    </div>
  );
}

import { GiRotaryPhone } from "react-icons/gi";
const adminSteps = [
  {
    content: (<div>
      <h2>Free Trial</h2>
      <hr />
      <p>Get started with the free trial here. Follow our steps to experience the best!</p>
    </div>),
    floaterProps: {
      autoOpen:true
    },
    spotlightPadding: 0,
    placement: 'right',
    target: '.sidebar1',
  },
  {
    content: (<div>
      <h2>Call Logs</h2>
      <hr />
      <p>View incoming and outgoing call activity of your organisation here along with playable call recordings.</p>
    </div>),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    placement: 'right',
    target: '.sidebar2',
  },
  // {
  //   content: (<div>
  //     <h2>SMS Inbox</h2>
  //     <hr />
  //     <p>View all SMSes being received by agents in your organisation here.</p>
  //   </div>),
  //   floaterProps: {
  //     disableAnimation: true,
  //   },
  //   spotlightPadding: 0,
  //   placement: 'right',
  //   target: '.sidebar3',
  // },
  {
    content: (<div>
      <h2>Numbers</h2>
      <hr />
      <p>View and manage virtual numbers owned by your organisation here.</p>
    </div>),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    placement: 'right',
    target: '.sidebar4',
  },
  {
    content: (<div>
      <h2>Agents</h2>
      <hr />
      <p>Add and manage agents in your organisation here. </p>
    </div>),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    placement: 'right',
    target: '.sidebar5',
  },
];
const userSteps = [
  {
    content: <h2>Let's begin our journey!</h2>,
    locale: { skip: <strong aria-label="skip">SKIP</strong> },
    placement: 'center',
    target: 'body',
  },
  {
    content: (<div>
      <h2>Call Logs</h2>
      <hr />
      <p>Here You can see all your call logs</p>
    </div>),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    placement: 'right',
    target: '.sidebar1',
  },
  // {
  //   content: (<div>
  //     <h2>SMS Inbox</h2>
  //     <hr />
  //     <p>Here You can see all your message logs</p>
  //   </div>),
  //   floaterProps: {
  //     disableAnimation: true,
  //   },
  //   spotlightPadding: 0,
  //   placement: 'right',
  //   target: '.sidebar2',
  // },
  // {
  //   content: (<div>
  //     <h2>Contacts</h2>
  //     <hr />
  //     <p>Here You can see your Contacts list.</p>
  //   </div>),
  //   floaterProps: {
  //     disableAnimation: true,
  //   },
  //   spotlightPadding: 0,
  //   placement: 'right',
  //   target: '.sidebar5',
  // },
  {
    content: (<div>
      <h2>Account Settings</h2>
      <hr />
      <p>Here You can change your account settings</p>
    </div>),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    placement: 'right',
    target: '.sidebar7',
  },
  {
    content: <h2><span style={{ color: "#D1D100" }}>-</span>&nbsp;&nbsp;
      <span style={{ color: "red", fontSize: "50px" }}><GiRotaryPhone /></span>&nbsp;&nbsp;
      <span style={{ color: "#D1D100" }}>-</span>
      <br />Welcome to Alohaa Family !!! </h2>,
    locale: { skip: <strong aria-label="skip">SKIP</strong> },
    placement: 'center',
    target: 'body',
  },
];

export const steps = [adminSteps, userSteps];

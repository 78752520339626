import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import '../../../assets/css/Content.css';
import { GET, DELETE } from '../../../services/HttpRequests';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import DataTable from '../../../common/component/table/DataTable';
import { commonStyle } from '../../../assets/css/css';
import { Redirect } from 'react-router-dom';
import UpgradeServices from '../../../common/component/upgrade-services/UpgradeServices';
import { toast } from 'react-toastify';
import DeleteMessagePopup from '../component/DeleteMessagePopup';
import {
  CheckboxChecked,
  CheckboxIntermediate,
  CheckboxUnchecked,
} from '../../../assets/Icons/Icons';

const messageUrl = process.env.REACT_APP_MESSAGE_API;

export function SMSInboxPage(props) {
  const userId = localStorage.getItem('doosra-biz-user-id');
  const userRole = localStorage.getItem('doosra-biz-user-role');
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [smsList, setSmsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [smsDeletionEnabled, setSmsDeletionEnabled] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    getFreshList();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('doosra-biz-user-deletion-enabled') === 'true') {
      setSmsDeletionEnabled(true);
    } else {
      setSmsDeletionEnabled(false);
    }
  }, [localStorage.getItem('doosra-biz-user-deletion-enabled')]);

  const getFreshList = () => {
    setLoading(true);
    let data = {
      pageNo: currentPage,
      pageSize: pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      isSmsInboxEnabled: null,
    };
    GET(`${messageUrl}v1/organisations/sms`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        let count = Math.ceil(res.payload.count / pageSize);
        if (count === 0) count = 1;
        setCount(res.payload.count);
        setSmsList(res.payload.docs);
        setTotalPages(count);
        setPageSize(res.pageSize);
        setLoading(false);
        setSearchData({});
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const seachHandler = (data) => {
    data.pageNo = 1;
    data.pageSize = pageSize;
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    GET(`${messageUrl}v1/organisations/sms`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        let count = Math.ceil(res.payload.count / pageSize);
        if (count === 0) count = 1;
        setCount(res.payload.count);
        setSmsList(res.payload.docs);
        setTotalPages(count);
        setPageSize(res.pageSize);
        setSearchData(data);
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
        }
      });
  };

  const fetchPageData = (value) => {
    let data = {
      pageNo: value,
      pageSize: pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    data = { ...searchData, ...data };

    GET(`${messageUrl}v1/organisations/sms`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        setCount(res.payload.count);
        setSmsList(res.payload.docs);
        setCurrentPage(value);
        setPageSize(res.pageSize);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const unicodeToChar = (text) => {
    const emojiString = text.replace(/\\u[\dA-F]{4}/gi, function (match) {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
    if (text === emojiString) {
      return text.replace(/\\u/g, '');
    }
    return emojiString;
  };

  const chunk = (str, n) => {
    var ret = [''];
    var i;
    var len;
    for (i = 0, len = str.length; i < len; i += n) {
      ret.push(str.substr(i, n));
    }
    return ret;
  };

  const replacePhoneNumberAndEmail = (message) => {
    let phone_numbers = [];
    let emails = [];
    const phone_regexp = new RegExp(
      '\\+?\\(?\\d*\\)? ?\\(?\\d+\\)?\\d*([\\s./-]?\\d{2,})+',
      'g'
    );
    const email_regexp =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
    phone_numbers = [...message.matchAll(phone_regexp)];
    console.log('phone_numbers', phone_numbers);
    for (const match of phone_numbers) {
      if (
        match[0].trim().length === 10 ||
        match[0].trim().length === 11 ||
        (match[0].trim().length === 13 && match[0].includes('+'))
      ) {
        message = message.replace(
          match[0],
          `<a href="tel:${match[0]}">${match[0]}</a>`
        );
      }
    }
    emails = [...message.matchAll(email_regexp)];
    for (const match of emails) {
      message = message.replace(
        match[0],
        `<a href="mailto:${match[0]}">${match[0]}</a>`
      );
    }
    return message;
  };

  const formatMessage = (message) => {
    let decodeMessage = message;
    if (message.length % 4 === 0 && isUniCodeUTF16(message)) {
      const chunksString = chunk(message, 4).join('\\u');
      decodeMessage = unicodeToChar(chunksString);
    }
    decodeMessage = replacePhoneNumberAndEmail(decodeMessage);
    return decodeMessage.replace(/'/g, '’');
  };

  const isUniCodeUTF16 = (str) => {
    const hexaDecimalRegex = /^[0-9a-fA-F]+$/;
    const whiteSpacesRegex = /\s/;
    if (whiteSpacesRegex.test(str)) {
      return false;
    } else {
      return hexaDecimalRegex.test(str);
    }
  };
  const trimNumber = (number) => {
    if (number && number.length === 12) return number.substring(2);
    return number;
  };

  const toggleCheckbox = (id, action) => {
    let newList = smsList.map((item) => {
      if (item._id === id) {
        if (action === 'CHECK') item.selected = true;
        if (action === 'UNCHECK') item.selected = false;
      }
      if (action === 'CHECK_ALL') item.selected = true;
      if (action === 'UNCHECK_ALL') item.selected = false;
      return item;
    });

    setSmsList(newList);
  };

  const deleteMessageHandler = () => {
    setProcessing(true);
    const selectedIds = smsList
      .filter((item) => item.selected === true)
      .map((item) => item._id);
    DELETE(messageUrl + 'v1/organisations/delete/sms', {}, { ids: selectedIds })
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        if (!res?.success) {
          toast.error('unable to delete message');
        } else {
          toast.success('SMS deleted successfully');
          fetchPageData(currentPage);
        }
        setProcessing(false);
      })
      .catch((err) => {
        setProcessing(false);
        toast.error('unable to delete message');
      });
  };

  const areSomeSelected = () => {
    const selectedCount = smsList.filter(
      (item) => item.selected === true
    ).length;
    return selectedCount > 0 && selectedCount < smsList.length;
  };

  const areAllSelected = () => {
    if (!smsList || smsList.length === 0) return false;
    return smsList.every((item) => item.selected === true);
  };

  function areAllUnselected() {
    if (!smsList || smsList.length === 0) return true;
    return smsList.every(
      (item) => item.selected === false || item.selected === undefined
    );
  }

  let displayData;
  if (loading) {
    displayData = <PreLoader />;
  }
  if (props.isSmsInboxEnabled === false) {
    return (
      <div className={`${props.className}`}>
        <Sidebar />
        <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />
          <div className="ContentContainer">
            <UpgradeServices
              icon="smsInbox"
              heading="SMS Inbox"
              text="Receive and read SMSes right from your Alohaa dashboard"
              emailSent={props.isSmsInboxEmailSent}
            />
          </div>
        </div>
      </div>
    );
  }
  if (smsList?.length > 0) {
    console.log(smsList, smsList.length);
    displayData = smsList.map((item) => (
      <DataTable.Row key={item._id}>
        {(userRole === 'ADMIN' || smsDeletionEnabled) && (
          <DataTable.Cell>
            {item.selected && (
              <CheckboxChecked
                onClick={() => {
                  toggleCheckbox(item._id, 'UNCHECK');
                }}
              />
            )}
            {!item.selected && (
              <CheckboxUnchecked
                onClick={() => {
                  toggleCheckbox(item._id, 'CHECK');
                }}
              />
            )}
          </DataTable.Cell>
        )}
        <DataTable.Cell>{item.s_mobile_no}</DataTable.Cell>
        <DataTable.Cell>{item.contact_name || '-'}</DataTable.Cell>
        {userRole === 'ADMIN' && (
          <>
            <DataTable.Cell>{item.zvr_name}</DataTable.Cell>
            <DataTable.Cell>{trimNumber(item.v_mobile_no)}</DataTable.Cell>
          </>
        )}
        <DataTable.Cell>{item.message}</DataTable.Cell>
        <DataTable.Cell>
          {moment(item.created_at).format('HH:mm:ss')}
        </DataTable.Cell>
        <DataTable.Cell>
          {moment(item.created_at).format('DD-MM-YYYY')}
        </DataTable.Cell>
        {/* {(userRole === 'ADMIN' || smsDeletionEnabled) && (
          <DataTable.Cell>
            {
              <DeleteMessagePopup
                key={item._id}
                deleteMessageHandler={(id, userId) =>
                  deleteMessageHandler(id, userId, 1)
                }
                id={item._id}
                userId={userId}
              />
            }
          </DataTable.Cell>
        )} */}
      </DataTable.Row>
    ));
  }

  return (
    <div className={`${props.className}`}>
      {processing && <PreLoader />}
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <SearchBar
            type="sms"
            deleteAction={
              smsList.filter((item) => item.selected === true).length
            }
            deleteMessageHandler={deleteMessageHandler}
            count={count}
            reset={getFreshList}
            search={seachHandler}
            isSenderWhiteListEnabled={props.isSenderWhiteListEnabled}
          />
          <DataTable.Table>
            <DataTable.Head>
              {(userRole === 'ADMIN' || smsDeletionEnabled) && (
                <DataTable.CellHead>
                  {areSomeSelected() && (
                    <CheckboxIntermediate
                      onClick={() => toggleCheckbox(null, 'UNCHECK_ALL')}
                    />
                  )}
                  {areAllSelected() && (
                    <CheckboxChecked
                      onClick={() => toggleCheckbox(null, 'UNCHECK_ALL')}
                    />
                  )}
                  {areAllUnselected() && (
                    <CheckboxUnchecked
                      onClick={() => toggleCheckbox(null, 'CHECK_ALL')}
                    />
                  )}
                </DataTable.CellHead>
              )}
              <DataTable.CellHead>SENDER</DataTable.CellHead>
              <DataTable.CellHead>CONTACT NAME</DataTable.CellHead>
              {userRole === 'ADMIN' && (
                <>
                  <DataTable.CellHead>AGENT NAME</DataTable.CellHead>
                  <DataTable.CellHead>VIRTUAL NO</DataTable.CellHead>
                </>
              )}
              <DataTable.CellHead>SMS BODY</DataTable.CellHead>
              <DataTable.CellHead>TIME</DataTable.CellHead>
              <DataTable.CellHead>DATE</DataTable.CellHead>
              {/* {(userRole === 'ADMIN' || smsDeletionEnabled) && (
                <DataTable.CellHead>ACTION</DataTable.CellHead>
              )} */}
            </DataTable.Head>
            <DataTable.Body>{displayData}</DataTable.Body>
          </DataTable.Table>
          <Paginator
            currentPage={currentPage}
            lastPage={totalPages}
            getInfo={(value) => fetchPageData(value)}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isSmsInboxEnabled: state.topbar.isSmsInboxEnabled,
  isSmsInboxEmailSent: state.topbar.isSmsInboxEmailSent,
  isSenderWhiteListEnabled: state.topbar.isSenderWhiteListEnabled,
});

export default commonStyle(connect(mapStateToProps, {})(SMSInboxPage));

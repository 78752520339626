import React, { useEffect, useState } from 'react';
import { MinusRedIcon, PlusGreenIcon } from '../../../assets/Icons/Icons';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';
const userUrl = process.env.REACT_APP_USER_API;
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
export default function AddAgent(props) {
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [remainUser, setRemainUser] = useState([]);
  const [arrangePriority, setArrangePriority] = useState(false);
  const [newGroup, setNewGroup] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState(null);
  useEffect(() => {
    getUsers();
  }, []);
  const getUsers = async () => {
    let agentIDs = [];
    if (props?.agents?.length > 0) {
      agentIDs = props.agents;
    }
    await GET(cloudCallCenterUrl + `campaign/eligible-agents-for-campaign`, {
      fromDate: props.fromDate,
      toDate: props.toDate,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => {
        const docs = res?.data?.response?.updatedUsers;
        const selectedUsers = docs.filter(
          (user) => agentIDs.indexOf(user._id) > -1
        );
        setSelectedUsers(selectedUsers);
        props.setAgentsData(selectedUsers);
        const remainedUsers = docs.filter(
          (user) => agentIDs.indexOf(user._id) === -1
        );
        setRemainUser(remainedUsers);
      })
      .catch((err) => console.log(err));
  };
  const onClickAddUserToSelectedUser = (item) => {
    const newSelectedUsers = [...selectedUsers, item];
    const newRemainUser = remainUser.filter((user) => user._id !== item._id);
    setSelectedUsers(newSelectedUsers);
    setRemainUser(newRemainUser);
  };
  const onClickSelectedUserToAddUser = (item) => {
    const newRemainUser = [...remainUser, item];
    const newSelectedUsers = selectedUsers.filter(
      (user) => user._id !== item._id
    );
    setSelectedUsers(newSelectedUsers);
    setRemainUser(newRemainUser);
  };
  const remainUsersListJSX = () => {
    const result = searchValue
      ? remainUser.filter((item) =>
          item.zvr_name.toLowerCase().includes(searchValue.toLowerCase())
        )
      : remainUser;
    return result.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {item.zvr_name}
          </p>
          {/* <p style={{ height: '28px', width: '128px', margin: '8px 92px', opacity: (item.isAvailableForNewCampaign ? 1 : 0.5) }} >
            <PlusGreenIcon onClick={() => item.isAvailableForNewCampaign ? onClickAddUserToSelectedUser(item) : null} />
          </p> */}
          <p
            style={{
              height: '28px',
              width: '128px',
              margin: '8px 92px',
              opacity: 1,
            }}
          >
            <PlusGreenIcon onClick={() => onClickAddUserToSelectedUser(item)} />
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };
  const selectedUsersListJSX = () => {
    const result = searchValue
      ? selectedUsers.filter((item) =>
          item.zvr_name.toLowerCase().includes(searchValue.toLowerCase())
        )
      : selectedUsers;
    return result.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {item.zvr_name}
          </p>
          <p style={{ height: '28px', width: '128px', margin: '8px 92px' }}>
            <MinusRedIcon onClick={() => onClickSelectedUserToAddUser(item)} />
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };
  const onClickAssignUserNext = () => {
    setError('');
    const SelectedUsers = selectedUsers || [];
    if (SelectedUsers?.length <= 0) {
      setError('Please add agents');
    } else {
      setArrangePriority(true);
    }
  };
  const onClickPriorityNext = () => {
    setArrangePriority(false);
    props.setAgents(selectedUsers.map((item) => item._id));
    props.setAgentsData(selectedUsers);
    props.close();
  };
  const onClick = () => {
    getUsers();
    props.setAddAgentPopup(false);
  };
  return (
    <Popup open={props.open} closeOnDocumentClick={false} closeOnEscape={false}>
      {!arrangePriority && (
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Add agents to the Campaign</p>
            <img className="PopupClose" src={Close} onClick={onClick} />
          </div>
          <div className="PopupBody">
            <div
              style={{
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <input
                placeholder="Search Agent"
                className="PopupSearchInput"
                id="searchValue"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </div>
            {selectedUsers.length > 0 && (
              <>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '60% 40%',
                    textAlign: 'center',
                    fontSize: '12px',
                    letterSpacing: '1.2px',
                  }}
                  className="PopupColumnHeaders"
                >
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'left',
                      paddingLeft: '20px',
                    }}
                  >
                    SELECTED AGENTS
                  </p>
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    ADD/REMOVE
                  </p>
                </div>
                <div
                  style={{
                    maxHeight: '150px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  {selectedUsersListJSX()}
                </div>
              </>
            )}
            {remainUser.length > 0 && (
              <>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '60% 40%',
                    textAlign: 'center',
                    fontSize: '12px',
                    letterSpacing: '1.2px',
                  }}
                  className="PopupColumnHeaders"
                >
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'left',
                      paddingLeft: '20px',
                    }}
                  >
                    AVAILABLE AGENTS
                  </p>
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    ADD/REMOVE
                  </p>
                </div>
                <div
                  style={{
                    maxHeight: '150px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  {remainUsersListJSX()}
                </div>
              </>
            )}
            <div className="PopupInputContainer" style={{ color: 'red' }}>
              {error}
            </div>
            {newGroup.routing_type === 'ROUND_ROBIN' ? (
              <button
                className={`ButtonFullWidth`}
                // onClick={onClickPriorityNext}
              >
                SAVE
              </button>
            ) : (
              <button
                className={`ButtonFullWidth`}
                onClick={onClickAssignUserNext}
              >
                Next
              </button>
            )}
            <button className="ButtonCancelFullWidth" onClick={props.close}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {arrangePriority && (
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Confirm Agents</p>
            <img className="PopupClose" src={Close} onClick={props.close} />
          </div>
          <div className="PopupBody">
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '50% 50%',
                textAlign: 'center',
                fontSize: '12px',
                letterSpacing: '1.2px',
              }}
              className="PopupColumnHeaders"
            >
              <p
                style={{
                  margin: '10px',
                  textAlign: 'left',
                  paddingLeft: '30px',
                }}
              >
                SNO
              </p>
              <p
                style={{
                  margin: '10px',
                  textAlign: 'left',
                  paddingRight: '30px',
                }}
              >
                AGENTS
              </p>
            </div>
            <div
              style={{
                height: '170px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {selectedUsers.map((item, index) => (
                <>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '50% 50%',
                      textAlign: 'center',
                      letterSpacing: '1.2px',
                    }}
                    className="dropColumnBg"
                  >
                    <p
                      style={{
                        margin: '10px',
                        textAlign: 'left',
                        paddingLeft: '30px',
                        fontSize: '15px',
                        color: '#33aabb',
                      }}
                    >
                      {index + 1}
                    </p>
                    <p
                      style={{
                        margin: '10px',
                        textAlign: 'left',
                        paddingRight: '30px',
                        fontSize: '15px',
                        color: '#33aabb',
                      }}
                    >
                      {item.zvr_name}
                    </p>
                  </div>
                  <hr
                    className="HR"
                    style={{ width: '90%', margin: '0 auto' }}
                  />
                </>
              ))}
            </div>
            <button className={`ButtonFullWidth`} onClick={onClickPriorityNext}>
              SAVE
            </button>
            <button
              className="ButtonCancelFullWidth"
              onClick={() => {
                setArrangePriority(false);
                props.close();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </Popup>
  );
}

import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import Button from '../../../common/component/button/Button';
import CsvDownloader from 'react-csv-downloader';
import { useEffect, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
import AddAgent from '../component/AddAgentV2';
import AddDids from '../component/AddDids';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import downloadIconGreen from '../../../assets/images/downloadIconGreen.png';
import cancelIcon from '../../../assets/images/cancelIconCircleGreen.png';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import DuplicateCampaignLeadsPopup from '../component/DuplicateCampaignLeadsPopup';
import PreviewCampaignAgentsPopup from '../component/PreviewCampaignAgentsPopupV2';
import PreviewDidPopup from '../component/PreviewDidPopup';
const moment = require('moment-timezone');
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

const Label = (props) => {
  return (
    <label
      style={{
        marginBottom: '16px',
        width: '100%',
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#282828',
      }}
    >
      {props.children}
    </label>
  );
};

const Input = (props) => {
  return (
    <input
      style={{
        fontSize: '16px',
        width: '100%',
        padding: '8px',
        border: 'solid 1px #939598',
        borderRadius: '4px',
        marginBottom: '24px',
      }}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const SampleFile = () => {
  return (
    <>
      <CsvDownloader
        style={{
          cursor: 'pointer',
          textDecoration: 'none',
          textAlign: 'center',
          marginBottom: '16px',
        }}
        filename="LeadList"
        datas={[
          {
            name: 'abc',
            number: '1234567890',
            secondaryNumber: '1234567890',
            agent: 'sample@sample.com',
          },
        ]}
      >
        <a
          style={{
            color: '#13ba0c',
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          Download Sample Sheet{' '}
          <img
            src={downloadIconGreen}
            alt=""
            style={{ margin: '0 0 3px 8px' }}
          ></img>
        </a>
      </CsvDownloader>

      <div
        style={{
          width: '100%',
          height: '56px',
          fontSize: '12px',
          color: '#939598',
          marginBottom: '24px',
          backgroundColor: '#f4f4f4',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        Download the sample sheet and fill it in the
        <br />
        same format without changing rows or columns
      </div>
    </>
  );
};

const Day = (props) => {
  return (
    <div
      className={
        props.weekDays.includes(props.thisDay) ? 'DaySelected' : 'DayDefault'
      }
      onClick={() => {
        props.handleWeekDaySelection(props.thisDay);
      }}
    >
      {props?.thisDay?.charAt(0)}
    </div>
  );
};

export function CreateCampaign(props) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const todayDate = moment().tz('Asia/kolkata').format('YYYY-MM-DD');
  const restrictStartTime = '08:00';
  const restrictEndTime = '19:00';
  const defaultPacingRatio = 1;
  const defaultCallingRetries = 0;
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [startTime, setStartTime] = useState(restrictStartTime);
  const [endTime, setEndTime] = useState(restrictEndTime);

  const [weekDays, setWeekDays] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agentsData, setAgentsData] = useState([]);
  const [files, setFiles] = useState([]);
  const [addAgentPopup, setAddAgentPopup] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [campaignLeads, setCampaignLeads] = useState([]);
  const [unfilteredCampaignLeads, setUnfilteredCampaignLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const edit = searchParams.get('edit');
  const duplicate = searchParams.get('duplicate');
  const [selectedCallingRetries, setSelectedCallingRetries] = useState(
    defaultCallingRetries
  );
  const [duplicateCampaignName, setDuplicateCampaignName] = useState('');
  const [selectedCampaignType, setSelectedCampaignType] = useState('');
  const [uploadCampaignWithOutLeads, setUploadCampaignWithOutLeads] =
    useState('false');
  const [selectedPacingRatio, setSelectedPacingRatio] =
    useState(defaultPacingRatio);
  const [selectedDialerType, setSelectedDialerType] = useState('simple');
  const [dispositions, setDispositions] = useState([]);
  const [didsOfOrganisation, setDidsOfOrganisation] = useState([]);
  const [totalActiveDids, setTotalActiveDids] = useState([]);
  const [selectedDidsOfOrganisation, setSelectedDidsOfOrganisation] = useState(
    []
  );
  const [addDidsPopup, setAddDidsPopup] = useState(false);
  const [popUpDuplicateCampiagnLeads, setPopUpDuplicateCampiagnLeads] =
    useState(false);
  const [oldCampaignType, setOldCampaignType] = useState('');
  const [isRingSoundEnabled, setIsRingSoundEnabled] = useState('true');
  const [selectedCallType, setSelectedCallType] = useState('');
  const [leadFilter, setLeadFilter] = useState('ALL');
  const [leadFilterdisposition, setLeadFilterDisposition] = useState([]);
  const [duplicateDeleteFilterLeads, setDuplicateDeleteFilterLeads] = useState(
    []
  );
  const [headers, setHeaders] = useState([]);
  const [oldCampaignDailerType, setOldCampaignDailerType] = useState('');
  const orgPacingRatio = parseInt(localStorage.getItem('rx_pacing_ratio'));
  const pacingRatioList = Array.from(
    { length: orgPacingRatio || 3 },
    (_, i) => i + 1
  );

  const handleOptionChange = (event) => {
    setSelectedCampaignType(event.target.value);
  };

  const handleCallTypeChange = (event) => {
    setSelectedCallType(event.target.value);
  };

  const handleStartDate = (event) => {
    const date = moment(event.target.value)
      .tz('Asia/Kolkata')
      .format('YYYY-MM-DD');
    if (date >= todayDate) {
      setStartDate(date);
      setEndDate(date);
    }
  };

  const handleEndDate = (event) => {
    const date = moment(event.target.value)
      .tz('Asia/Kolkata')
      .format('YYYY-MM-DD');
    if (date >= todayDate) {
      setEndDate(date);
    }
  };

  const handleStartTime = (event) => {
    if (
      event.target.value >= restrictStartTime &&
      event.target.value <= restrictEndTime
    ) {
      setStartTime(event.target.value);
    } else {
      toast.error(
        'According to government regulations, The time schedule should adhere to the designated time frames (between ' +
          restrictStartTime +
          ' and ' +
          restrictEndTime +
          ').'
      );
    }
  };

  const handleEndTime = (event) => {
    if (
      event.target.value >= restrictStartTime &&
      event.target.value <= restrictEndTime
    ) {
      setEndTime(event.target.value);
    } else {
      toast.error(
        'According to government regulations, The time schedule should adhere to the designated time frames (between ' +
          restrictStartTime +
          ' and ' +
          restrictEndTime +
          ').'
      );
    }
  };

  const handleFetchCampaignDetails = async (id) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: id,
    };
    setIsLoading(true);
    await GET(cloudCallCenterUrl + 'campaign/get-campaign', data)
      .then((res) => {
        const {
          campaignName,
          agentsData,
          campaignDescription,
          campaignStartDate,
          campaignEndDate,
          weekDays,
          campaignLeads,
          campaignType,
          headerKeys,
          dailerType,
          pacingRatio,
          maxRetries,
          dids,
          callType,
          uploadWithOutLeads,
        } = res?.data?.response;
        setName(campaignName);
        setDuplicateCampaignName(campaignName);
        setDescription(campaignDescription);
        const startDate = campaignStartDate.slice(0, 16).split('T');
        const endDate = campaignEndDate.slice(0, 16).split('T');
        setStartDate(startDate[0]);
        setEndDate(endDate[0]);
        setStartTime(startDate[1]);
        setEndTime(endDate[1]);
        if (edit === 'true') {
          setAgents(agentsData.map((item) => item.agentId));
          setSelectedDidsOfOrganisation(dids.map((i) => i.did));
          setDidsOfOrganisation(dids.map((i) => i._id.toString()));
        }
        setWeekDays(weekDays);
        setUploadCampaignWithOutLeads(uploadWithOutLeads);
        setUnfilteredCampaignLeads(campaignLeads);
        setCampaignLeads(campaignLeads);
        setSelectedCampaignType(campaignType);
        setOldCampaignType(campaignType);
        setSelectedCallType(callType);
        setOldCampaignDailerType(dailerType);
        setHeaders(headerKeys);
        setSelectedDialerType(dailerType);
        setSelectedPacingRatio(pacingRatio || defaultPacingRatio);
        setSelectedCallingRetries(maxRetries || defaultCallingRetries);
        if (edit === 'true' || duplicate === 'true') {
          getDispositions();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getActiveDids = async () => {
    setIsLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    await GET(cloudCallCenterUrl + 'campaign/get-all-active-dids', data)
      .then((res) => {
        let data = res.data.response;
        data = data.map((i) => ({ ...i, disable: false }));
        setTotalActiveDids(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getDispositions = () => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    console.log(data);
    GET(cloudCallCenterUrl + 'disposition', data)
      .then((res) => {
        setDispositions([
          ...res.data.response.dispositions,
          { dispositon: 'Other' },
        ]);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(false);
      await getActiveDids();
      if (edit === 'true' || duplicate === 'true') {
        await handleFetchCampaignDetails(id);
      }
    };

    fetchData();
  }, [edit, duplicate, id]);

  const submitFormHandler = async () => {
    try {
      if (uploading) return;
      console.log(
        'previous campaign filters',
        leadFilter,
        leadFilterdisposition,
        duplicateDeleteFilterLeads
      );
      if (!name) {
        toast.error('Campaign name is required');
        return;
      }
      if (!startDate || !endDate) {
        toast.error('Campaign should have both start and end dates');
        return;
      }
      if (startDate > endDate) {
        toast.error(
          'Campaign end date should be greater than or equal to start date'
        );
        return;
      }
      if (!startTime || !endTime) {
        toast.error('Campaign should have both start and end slot times');
        return;
      }
      if (startTime >= endTime) {
        toast.error(
          'Campaign end slot time should be greater than start slot time'
        );
        return;
      }
      if (!weekDays.length > 0) {
        toast.error('Please select atleast one weekday for campaign');
        return;
      }
      if (
        uploadCampaignWithOutLeads === 'false' ||
        uploadCampaignWithOutLeads === false
      ) {
        if (
          (!(files.length > 0) && edit !== 'true' && duplicate !== 'true') ||
          (files.length === 0 &&
            campaignLeads.length === 0 &&
            (duplicate === 'true' || edit === 'true'))
        ) {
          toast.error('Please upload one lead list for campaign');
          return;
        }
      }

      if (selectedCampaignType === '') {
        toast.error('Please select the campaign type');
        return;
      }

      if (
        (uploadCampaignWithOutLeads === 'true' ||
          selectedCampaignType === 'dynamic') &&
        !agents.length > 0
      ) {
        toast.error('Please select atleast one agent');
        return;
      }

      if (selectedCampaignType === 'dynamic' && selectedDialerType === '') {
        toast.error('Please select dialer type');
        return;
      }

      if (selectedCampaignType === 'dynamic' && selectedPacingRatio === '') {
        toast.error('Please select pacing ratio');
        return;
      }

      if (
        selectedCampaignType === 'dynamic' &&
        selectedDialerType === 'predictive' &&
        selectedCallingRetries === 0
      ) {
        toast.error('Please select calling retries');
        return;
      }

      if (selectedDidsOfOrganisation.length === 0) {
        toast.error("Please select Did's");
        return;
      }

      if (!selectedCallType) {
        toast.error('Please Select Call Type');
        return;
      }

      const duplicateCampaignInfo = {
        leadFilter,
        leadFilterdisposition,
        duplicateDeleteFilterLeads,
        id,
      };

      setUploading(true);
      let formData = new FormData();
      formData.append(
        'organisationId',
        localStorage.getItem('doosra-biz-organisation-id')
      );
      formData.append('campaignName', name);
      formData.append('campaignDescription', description);
      formData.append('campaignStartDate', startDate);
      formData.append('campaignEndDate', endDate);
      formData.append('campaignStartTime', startTime);
      formData.append('campaignEndTime', endTime);
      formData.append('weekDays', weekDays);
      formData.append('uploadWithOutLeads', uploadCampaignWithOutLeads);
      formData.append('agents', agents);
      formData.append('dids', didsOfOrganisation);
      if (duplicate === 'true') {
        formData.append(
          'duplicateCampaignInfo',
          JSON.stringify(duplicateCampaignInfo)
        );
      }
      formData.append('duplicate', duplicate === 'true' ? true : false);
      formData.append('campaignType', selectedCampaignType);
      formData.append('callType', selectedCallType);
      if (selectedCampaignType === 'dynamic') {
        formData.append('dailerType', selectedDialerType);
        formData.append('pacingRatio', selectedPacingRatio);
        formData.append('maxRetries', selectedCallingRetries);
        formData.append('currentRetry', 0);
        formData.append('isRingSoundEnabled', isRingSoundEnabled);
      }
      for (let i = 0; i < files.length; i++) {
        formData.append('leadLists', files[i]);
      }

      console.log('++++++++++++++++++++++++', didsOfOrganisation);

      let failedCampaignLeads = [];
      if (edit === 'true') {
        const res = await POST(
          `${cloudCallCenterUrl}campaigns/edit-campaign?id=${id}`,
          formData,
          { 'Content-Type': 'multipart/form-data' }
        );
        if (res.data.response?.failedCampaignLeads) {
          failedCampaignLeads = res.data.response.failedCampaignLeads;
        }
        toast.success(`Campaign Edited Succesfully !!!`);
      } else {
        const res = await POST(
          `${cloudCallCenterUrl}campaigns/create-campaign`,
          formData,
          { 'Content-Type': 'multipart/form-data' }
        );
        if (res.data.response?.failedCampaignLeads) {
          failedCampaignLeads = res.data.response.failedCampaignLeads;
        }
        toast.success(res.data.response.status);
      }

      if (failedCampaignLeads.length > 0) {
        let csvstring = 'name,number,agent,reason\n';
        for (let i = 0; i < failedCampaignLeads.length; i += 1) {
          const tempString = `${failedCampaignLeads[i].name},${failedCampaignLeads[i].number},${failedCampaignLeads[i].agent},${failedCampaignLeads[i].reason}`;
          csvstring = `${csvstring + tempString}\n`;
        }
        console.log(csvstring);
        const blob = new Blob([csvstring], { type: 'text/csv' });
        const URL = window.URL || window.webkitURL;
        const downloadFileUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadFileUrl;
        a.download = 'un_uploaded_leads.csv';
        a.style = 'display:none';
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

      setUploading(false);
      cancelCampaignCreation();
    } catch (err) {
      setUploading(false);
      console.log(err);
      toast.error(err?.response?.data?.error?.reason);
    }
  };

  // const getUserDetails = async (fromDate, toDate) => {
  //   let users = [];
  //   await GET(outGoingUrl + `v1/campaigns/eligible-agents-for-campaign`, {
  //     fromDate: fromDate,
  //     toDate: toDate,
  //     organisationId: localStorage.getItem('doosra-biz-organisation-id'),
  //   })
  //     .then((res) => {
  //       users = res?.data?.response?.updatedUsers;
  //     })
  //     .catch((err) => console.log(err));
  //   return users;
  // }

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const fileList = [...e.dataTransfer.files];
    // do something with the files, e.g. upload to server

    const updatedFiles = [...files];
    for (let i = 0; i < fileList.length; i++) {
      updatedFiles.push(fileList[i]);
    }

    setFiles(updatedFiles);
    // clear the input field to allow uploading of the same file again
    e.target.value = '';
  };

  const handleFileChange = (event) => {
    const fileList = event.target.files;
    const updatedFiles = [...files];
    const existingFileNames = updatedFiles.map((i) => i.name);
    for (let i = 0; i < fileList.length; i++) {
      if (!existingFileNames.includes(fileList[i].name)) {
        updatedFiles.push(fileList[i]);
      }
    }
    // clear the input field to allow uploading of the same file again
    event.target.value = '';
    setFiles(updatedFiles);
    // onFilesSelected(updatedFiles);
  };

  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = files.filter((file) => file !== fileToRemove);

    setFiles(updatedFiles);
    // onFilesSelected(updatedFiles);
  };

  const handleWeekDaySelection = (day) => {
    if (weekDays.includes(day)) {
      setWeekDays(weekDays.filter((val) => val !== day));
    } else {
      setWeekDays([...weekDays, day]);
    }
  };

  const cancelCampaignCreation = () => {
    history.push('/campaigns-v2');
  };

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={cancelCampaignCreation}
                alt=""
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {duplicate === 'true' ? (
                  <>Duplicating Campaign</>
                ) : edit === 'true' ? (
                  <>Edit Campaign</>
                ) : (
                  <>Create a Campaign</>
                )}
              </p>
            </div>
          </TabSearchBar>
          {isLoading && <PreLoader />}
          {!isLoading && (
            <div
              style={{ width: '594px', marginLeft: '40px', marginTop: '40px' }}
            >
              <Label>Campaign Title</Label>
              <input
                style={{
                  fontSize: '16px',
                  width: '100%',
                  padding: '8px',
                  border: 'solid 1px #939598',
                  borderRadius: '4px',
                  marginBottom: '24px',
                }}
                placeholder="Enter the campaign title here…"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={edit === 'true' ? true : false}
              />
              <Label>Campaign Description</Label>
              <Input
                placeholder="Enter the campaign description here…"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <br />
              <Label>Campaign Duration</Label>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  color: '#939598',
                  marginTop: '-8px',
                }}
              >
                Specify the campaign's start and end dates, and it will
                automatically switch to 'Completed' once the end date is reached
              </p>
              <br />
              <div
                style={{
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <div style={{ paddingRight: '20px' }}>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      color: '#939598',
                      marginTop: '-8px',
                    }}
                  >
                    Start Date
                  </div>
                  <div>
                    <input
                      style={{
                        padding: '10px',
                        border: '1px solid #939598',
                        borderRadius: '4px',
                        width: '200px',
                        paddingLeft: '20px',
                      }}
                      type="date"
                      min={moment.tz('Asia/Kolkata').format('YYYY-MM-DD')}
                      value={startDate}
                      onChange={handleStartDate}
                    />
                  </div>
                </div>
                <p
                  style={{
                    margin: 0,
                    fontSize: '16px',
                    color: '#939598',
                    fontWeight: 500,
                  }}
                >
                  to
                </p>
                <div style={{ paddingLeft: '20px' }}>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      color: '#939598',
                      marginTop: '-8px',
                    }}
                  >
                    End Date
                  </div>
                  <div>
                    <input
                      style={{
                        padding: '10px',
                        border: '1px solid #939598',
                        borderRadius: '4px',
                        width: '200px',
                        paddingLeft: '20px',
                      }}
                      type="date"
                      min={moment.tz('Asia/Kolkata').format('YYYY-MM-DD')}
                      value={endDate}
                      onChange={handleEndDate}
                    />
                  </div>
                </div>
              </div>
              <Label>Campaign Slot Time</Label>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  color: '#939598',
                  marginTop: '-8px',
                }}
              >
                Specify the campaign's start and end slot times, and it will be
                active within the times
              </p>
              <br />
              <div
                style={{
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <div style={{ paddingRight: '20px' }}>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      color: '#939598',
                      marginTop: '-8px',
                    }}
                  >
                    Start Time
                  </div>
                  <div>
                    <input
                      style={{
                        padding: '10px',
                        paddingLeft: '20px',
                        border: '1px solid #939598',
                        borderRadius: '4px',
                        width: '200px',
                      }}
                      type="time"
                      value={startTime}
                      onChange={handleStartTime}
                    />
                  </div>
                </div>
                <p
                  style={{
                    margin: 0,
                    fontSize: '16px',
                    color: '#939598',
                    fontWeight: 500,
                  }}
                >
                  to
                </p>
                <div style={{ paddingLeft: '20px' }}>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      color: '#939598',
                      marginTop: '-8px',
                    }}
                  >
                    End Time
                  </div>
                  <div>
                    <input
                      style={{
                        padding: '10px',
                        paddingLeft: '20px',
                        border: '1px solid #939598',
                        borderRadius: '4px',
                        width: '200px',
                      }}
                      type="time"
                      value={endTime}
                      onChange={handleEndTime}
                    />
                  </div>
                </div>
              </div>
              <br />
              <Label>Campaign Week Days</Label>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  color: '#939598',
                  marginTop: '-8px',
                }}
              >
                Set the days during the week when the campaign should run
              </p>
              <div
                style={{ display: 'flex', gap: '24px', marginBottom: '24px' }}
              >
                <Day
                  handleWeekDaySelection={handleWeekDaySelection}
                  weekDays={weekDays}
                  thisDay="MONDAY"
                />
                <Day
                  handleWeekDaySelection={handleWeekDaySelection}
                  weekDays={weekDays}
                  thisDay="TUESDAY"
                />
                <Day
                  handleWeekDaySelection={handleWeekDaySelection}
                  weekDays={weekDays}
                  thisDay="WEDNESDAY"
                />
                <Day
                  handleWeekDaySelection={handleWeekDaySelection}
                  weekDays={weekDays}
                  thisDay="THURSDAY"
                />
                <Day
                  handleWeekDaySelection={handleWeekDaySelection}
                  weekDays={weekDays}
                  thisDay="FRIDAY"
                />
                <Day
                  handleWeekDaySelection={handleWeekDaySelection}
                  weekDays={weekDays}
                  thisDay="SATURDAY"
                />
                <Day
                  handleWeekDaySelection={handleWeekDaySelection}
                  weekDays={weekDays}
                  thisDay="SUNDAY"
                />
              </div>
              <Label>Campaign Type</Label>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '7px',
                }}
              >
                <div className="radio-button-group">
                  <label style={{ fontSize: '16px' }}>
                    <input
                      type="radio"
                      value="static"
                      checked={selectedCampaignType === 'static'}
                      disabled={
                        edit === 'true' ||
                        (duplicate === 'true' &&
                          oldCampaignType === 'dynamic') ||
                        localStorage.getItem(
                          'is_campaigns_v2_static_enabled'
                        ) === 'false'
                      }
                      onChange={handleOptionChange}
                    />
                    &nbsp; Static
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label style={{ fontSize: '16px' }}>
                    <input
                      type="radio"
                      value="dynamic"
                      disabled={
                        edit === 'true' ||
                        (duplicate === 'true' &&
                          oldCampaignType === 'dynamic') ||
                        localStorage.getItem(
                          'is_campaigns_v2_preview_enabled'
                        ) === 'false'
                      }
                      checked={selectedCampaignType === 'dynamic'}
                      onChange={handleOptionChange}
                    />
                    &nbsp; Dynamic
                  </label>
                </div>
              </div>
              {selectedCampaignType === 'dynamic' && (
                <>
                  <div style={{ display: 'flex' }}>
                    <Label>Add Agents ({agents?.length})</Label>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      {edit === 'true' && (
                        <PreviewCampaignAgentsPopup
                          agentsData={agentsData}
                          agents={agents}
                        />
                      )}
                    </div>
                  </div>
                  <Button
                    label={
                      edit === 'true' || duplicate === 'true'
                        ? 'EDIT AGENTS'
                        : 'ADD AGENTS'
                    }
                    style={{ color: '#196cca' }}
                    onClick={() => setAddAgentPopup(true)}
                  />
                  <AddAgent
                    fromDate={startDate}
                    toDate={endDate}
                    open={addAgentPopup}
                    // getUserDetails={getUserDetails}
                    setAddAgentPopup={setAddAgentPopup}
                    close={() => setAddAgentPopup(false)}
                    setAgents={setAgents}
                    setAgentsData={setAgentsData}
                    agents={agents}
                  />
                </>
              )}
              {selectedCampaignType !== '' &&
                (selectedCampaignType === 'dynamic' ||
                  uploadCampaignWithOutLeads === 'false' ||
                  uploadCampaignWithOutLeads === false) && (
                  <>
                    <Label>
                      Add Leads &nbsp;
                      {(edit === 'true' || duplicate === 'true') &&
                        `(${campaignLeads.length})`}
                    </Label>
                    {/* <p
              style={{
                fontWeight: 500,
                fontSize: '16px',
                color: '#939598',
                marginTop: '8px',
              }}
            >
              check old leads
            </p> */}

                    {(edit === 'true' || duplicate === 'true') && (
                      <div style={{ display: 'flex' }}>
                        <ul style={{ listStyleType: 'none', padding: '0' }}>
                          <li>
                            <p
                              style={{
                                width: '595px',
                                height: '48px',
                                backgroundColor: 'rgba(7, 170, 63, 0.16)',
                                borderRadius: '4px',
                                textDecoration: 'none',
                                margin: 0,
                                fontSize: '16px',
                                fontWeight: 500,
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                              }}
                            >
                              {duplicateCampaignName + ' Leads'}
                            </p>
                          </li>

                          <li>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <DuplicateCampaignLeadsPopup
                                headers={headers}
                                leadFilter={leadFilter}
                                leadFilterdisposition={leadFilterdisposition}
                                setLeadFilter={setLeadFilter}
                                setLeadFilterDisposition={
                                  setLeadFilterDisposition
                                }
                                setCampaignLeads={setCampaignLeads}
                                dispositions={dispositions}
                                campaignLeads={campaignLeads}
                                id={id}
                                unfilteredCampaignLeads={
                                  unfilteredCampaignLeads
                                }
                                popUpDuplicateCampiagnLeads={
                                  popUpDuplicateCampiagnLeads
                                }
                                setDuplicateDeleteFilterLeads={
                                  setDuplicateDeleteFilterLeads
                                }
                                setPopUpDuplicateCampiagnLeads={
                                  setPopUpDuplicateCampiagnLeads
                                }
                                duplicate={duplicate}
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: '16px',
                        color: '#939598',
                        marginTop: '8px',
                      }}
                    >
                      You can add multiple CSV files to a campaign
                    </p>
                    <ul style={{ listStyleType: 'none', padding: '0' }}>
                      {files.map((file, index) => (
                        <li
                          key={index}
                          style={{
                            display: 'flex',
                            gap: '16px',
                            marginBottom: '8px',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              width: '562px',
                              height: '48px',
                              backgroundColor: 'rgba(7, 170, 63, 0.16)',
                              borderRadius: '4px',
                              textDecoration: 'none',
                              margin: 0,
                              fontSize: '16px',
                              fontWeight: 500,
                              display: 'flex',
                              alignItems: 'center',
                              padding: '10px',
                            }}
                          >
                            {file.name}
                          </p>
                          <img
                            src={cancelIcon}
                            onClick={() => handleRemoveFile(file)}
                          />
                        </li>
                      ))}
                    </ul>

                    <div
                      className={`drag-and-drop ${dragging ? 'dragging' : ''}`}
                      style={{ width: '100%' }}
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      // onClick={() => document.getElementById('fileInput').click()} // added this line to click the file input element
                      onChange={handleFileChange}
                    >
                      <input
                        className="dnd-file-input"
                        type="file"
                        id="fileInput"
                        // onChange={handleFileChange}
                        multiple
                      />
                      <label className="DnD-label" htmlFor="fileInput">
                        Drag and drop files here or click to select files
                      </label>
                    </div>
                    <SampleFile />
                  </>
                )}
              <br />
              <Label>Call Type</Label>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  color: '#939598',
                  marginTop: '-8px',
                }}
              >
                Opt for PSTN for incoming calls on agents' mobile devices, and
                utilize VoIP for agents to receive calls directly through their
                web browsers, offering versatile communication options.
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <div className="radio-button-group">
                  <label style={{ fontSize: '16px' }}>
                    <input
                      type="radio"
                      value="VOIP"
                      checked={selectedCallType === 'VOIP'}
                      disabled={
                        !(localStorage.getItem('webrtc_enabled') === 'true')
                      }
                      onChange={handleCallTypeChange}
                    />
                    &nbsp; WebRTC
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label style={{ fontSize: '16px' }}>
                    <input
                      type="radio"
                      value="PSTN"
                      checked={selectedCallType === 'PSTN'}
                      disabled={
                        !(localStorage.getItem('pstn_enabled') === 'true') ||
                        selectedDialerType === 'predictive'
                      }
                      onChange={handleCallTypeChange}
                    />
                    &nbsp; PSTN
                  </label>
                </div>
              </div>

              <>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                  <Label>
                    Select DIDs ({selectedDidsOfOrganisation?.length})
                  </Label>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {(edit === 'true' || duplicate === 'true') && (
                      <PreviewDidPopup dids={selectedDidsOfOrganisation} />
                    )}
                  </div>
                </div>
                <Button
                  label={
                    edit === 'true' || duplicate === 'true'
                      ? 'EDIT DIDs'
                      : 'ADD DIDs'
                  }
                  style={{ color: '#196cca' }}
                  onClick={() => setAddDidsPopup(true)}
                />
                <AddDids
                  open={addDidsPopup}
                  allActiveDids={totalActiveDids}
                  close={() => setAddDidsPopup(false)}
                  didsOfOrganisation={didsOfOrganisation}
                  setDidsOfOrganisation={setDidsOfOrganisation}
                  setSelectedDidsOfOrganisation={setSelectedDidsOfOrganisation}
                  dids={didsOfOrganisation}
                  isMultiPersist={
                    localStorage.getItem(
                      'is_cc_multi_persist_connection_enabled'
                    ) === 'true'
                      ? true
                      : false
                  }
                  selectedDidsOfOrganisation={selectedDidsOfOrganisation}
                />
              </>

              <div
                style={{
                  marginTop: '40px',
                  display: 'flex',
                  gap: '16px',
                  marginBottom: '50px',
                }}
              >
                <Button
                  label="SAVE CAMPAIGN"
                  style={{ backgroundColor: '#196cca', color: '#ffffff' }}
                  onClick={submitFormHandler}
                />
                <Button
                  onClick={cancelCampaignCreation}
                  label="CANCEL"
                  style={{ color: '#ed3833', borderColor: '#ed3833' }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(CreateCampaign));

import React, { useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import Notes from './Notes';
import Tags from './Tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { PUT, POST } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const userUrl = process.env.REACT_APP_USER_API;
const organisationId = localStorage.getItem('doosra-biz-organisation-id');

const ContactInput = (props) => {
  return (
    <div key={props.index} style={{ height: '62px' }}>
      <input
        className="PopupInput"
        id="emp_id"
        value={props.number}
        onChange={(e) => props.updateNumber(e.target.value)}
      />
      <img
        alt=""
        style={{
          position: 'relative',
          top: '-45px',
          left: '328px',
          cursor: 'pointer',
        }}
        onClick={props.removeHandler}
        src={Delete}
      />
    </div>
  );
};

export default function AddContactNotesTagsPopup(props) {
  const contactNumber = props.callerNo;
  const [popupOpen, setPopupOpen] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [contactUserId, setContactUserId] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [contactName, setContactName] = useState(null);
  const [contactNumbers, setContactNumbers] = useState([contactNumber]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const removeNumberHandler = (i) => {
    const updatedList = [...contactNumbers];
    updatedList.splice(i, 1);
    setContactNumbers(updatedList);
  };

  const addNumberHandler = () => {
    // if (contactNumbers[contactNumbers.length - 1] === '') return;
    const updatedList = [...contactNumbers, ''];
    setContactNumbers(updatedList);
  };

  const updateContactNumbers = (index, value) => {
    const list = [...contactNumbers];
    list[index] = value;
    setContactNumbers(list);
  };

  const saveContact = async () => {
    let wrongNos = false;
    contactNumbers.forEach((no) => {
      if (!no.match('^[0-9]{10}$')) {
        setError('Please Enter 10 digit number');
        wrongNos = true;
      }
    });

    if (!contactName) {
      setError('Please Enter Contact Name');
      wrongNos = true;
    }

    if (!wrongNos) {
      setLoading(true);
      const data = {
        name: contactName,
        phoneNumbers: contactNumbers,
        organisationId,
      };
      if (contactId && contactUserId) {
        data.id = contactId;
        data.userId = contactUserId;
        await PUT(userUrl + 'v1/user/contact', null, data)
          .then((res) => {
            console.log('PUT', { contactDate: res?.data?.response?.contact });
            setLoading(false);
            setContactId(res?.data?.response?.contact?._id);
            setContactUserId(res?.data?.response?.contact?.userId);
            setShowNotes(true);
            toast.success('Update Contact Successfully');
            setError(null);
            wrongNos = false;
          })
          .catch((err) => {
            setLoading(false);
            setContactId(null);
            setShowNotes(false);
            setError(null);
            wrongNos = false;
            toast.error(err?.response?.data?.error?.reason);
          });
      } else {
        await POST(userUrl + 'v1/user/contact', data)
          .then((res) => {
            console.log('POST', { contactDate: res?.data?.response?.contact });
            setLoading(false);
            setContactId(res?.data?.response?.contact?._id);
            setContactUserId(res?.data?.response?.contact?.userId);
            setShowNotes(true);
            setError(null);
            wrongNos = false;
            toast.success('Add Contact Successfully');
          })
          .catch((err) => {
            setLoading(false);
            setContactId(null);
            setShowNotes(false);
            setError(null);
            wrongNos = false;
            toast.error(err?.response?.data?.error?.reason);
          });
      }
    }
  };

  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div
        className="PopupContainer"
        style={{
          maxHeight: '600px',
          overflow: 'scroll',
          marginBottom: 30,
        }}
      >
        <div className="PopupHeaderContainer">
          <p>Add Contact Profile</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => {
              setPopupOpen(false);
              props.getList();
            }}
          />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Contact Name</label>
            <input
              className="PopupInput"
              id="emp_id"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Contact Numbers</label>
            {contactNumbers?.length > 0 &&
              contactNumbers.map((item, index) => (
                <ContactInput
                  number={item}
                  index={index}
                  updateNumber={(number) => updateContactNumbers(index, number)}
                  removeHandler={() => removeNumberHandler(index)}
                />
              ))}
            <div style={{ display: 'flex' }}>
              <div className="ContactAddBtn">
                {' '}
                <a onClick={addNumberHandler}> +Add another number </a>
              </div>
              <div
                onClick={(e) => {
                  saveContact();
                }}
                className="ContactSaveBtn"
              >
                Save Contact
              </div>
            </div>
          </div>
          {error ? (
            <div className="ErrorContainer ErrorContainerMargin">
              <img
                alt=""
                src={InfoRed}
                style={{
                  paddingRight: '5px',
                  position: 'relative',
                  top: '-2px',
                }}
              />
              {error}
            </div>
          ) : null}
          {loading && <PreLoader />}

          {showNotes && (
            <>
              <Tags contactId={contactId} action="ADD" />
              <Notes contactId={contactId} action="ADD" />
            </>
          )}
          <button
            className="ButtonCancelFullWidth"
            onClick={() => {
              setPopupOpen(false);
              props.getList();
            }}
            style={{ marginTop: 30 }}
          >
            CLOSE
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <React.Fragment>
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id={`tooltip-left`}>
            Add Contact
          </Tooltip>
        }
      >
        <button onClick={() => setPopupOpen(true)} className="GreenButton">
          <FontAwesomeIcon icon={faPlusCircle} style={{ fontSize: 17 }} />
        </button>
      </OverlayTrigger>
      {/* <Tooltip tooltipText="Add Contact" position="top">
        <button onClick={() => setPopupOpen(true)} className="GreenButton">
          <FontAwesomeIcon icon={faPlusCircle} style={{ fontSize: 17 }} />
        </button>
      </Tooltip> */}
      {popper}
    </React.Fragment>
  );
}

import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';
import Topbar from '../../../common/component/topbar/Topbar';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import YouTube from 'react-youtube';

export function VerifiedContact(props) {
  const history = useHistory();

  const handleRedirectToUserPage = () => {
    history.push('/users'); // Redirect to the "/user" page
  };
  const handleRedirectToNumberPage = () => {
    history.push('/virtual-numbers'); // Redirect to the "/user" page
  };
  console.log('props is here for test ', props);
  return (
    <div className={props.className}>
      <Sidebar />
      <div
        className={!props.sidebarClosed ? 'ContentFreeTrial' : 'contentFull'}
      >
        <Topbar />
        <div className="contentContainer">
          <div className="headerContainer">
            <div className="headerFlex">
              {/* <FaArrowLeft className="backIcon" /> */}
              <h2 className="freeTrialHeader">How to use the free trial</h2>
            </div>
            <div className="horizontalLineHeading"></div>

            <div className="good-to-know-container">
              <span className="good-to-know">
                Welcome {localStorage.getItem('doosra-biz-user-name')}!
              </span>
              <div className="presently-admin-dashboard">
                You are in the Alohaa Admin Dashboard.
              </div>
              <span className="presently-admin-dashboard">
                <span className="text-admin-bold"> Exciting news </span>– you
                have been assigned a free virtual number to explore your trial
                experience.
              </span>
              <span className="presently-admin-dashboard display-block">
                Here are easy-to-follow steps to guide you through this process
                :
              </span>
            </div>

            <YouTube videoId="ohCTwv2xF44" opts={{ width: '594' }} />

            <div className="theme-color-free-trial section-container-steps">
              <div className="theme-color-free-trial">
                <div className="invite-agent">1. Adding an Agent</div>
                <div className="invite-agent-paragraph">
                  To add an agent, go to the 'Agents' section on the left menu
                  and select 'ADD AGENT.' Fill in the required details and click
                  'SAVE.' The new agent will receive an email with all necessary
                  information for a smooth start.
                </div>
                {/* <div className="red-notes">
                  <span>
                    Note: The email and phone number entered for the agent here
                    should be different from the email and phone you used to
                    register your admin account.
                  </span>
                </div> */}
              </div>

              <div>
                <div className="invite-agent">2. Assigning a Numbert</div>
                <div className="invite-agent-paragraph">
                  Utilize your free virtual number by assigning it to a newly
                  added agent. In the Admin Dashboard, find 'Numbers,' select
                  'ASSIGN' beside your preferred number, and choose the recent
                  agent.
                </div>
              </div>

              <div>
                <div className="invite-agent">3. Agent Login</div>
                <div className="invite-agent-paragraph">
                  New agents will receive a welcome email from Alohaa. They can
                  register by following the link in the email. If accessing from
                  a computer, use an incognito tab to open the agent link.
                </div>
              </div>

              <div>
                <div className="invite-agent">4. Outgoing Calls to Admin</div>
                <div className="invite-agent-paragraph">
                  Agents can make outgoing calls to the admin by visiting the
                  "Outgoing Call" section in their interface. Note: During the
                  trial, agents can only make outgoing calls to the admin due to
                  regulatory constraints. Upgrading unlocks full call
                  capabilities.
                </div>
              </div>

              <div>
                <div className="invite-agent">5. Explore SMS Inbox</div>
                <div className="invite-agent-paragraph">
                  To receive an SMS, the Admin should send a message from their
                  phone to the Agent’s virtual phone number. After sending, both
                  the Admin and Agents can view the SMS in the 'SMS Inbox' tab
                  on their respective dashboards.
                </div>
              </div>

              <div>
                <div className="invite-agent">6. Upgrade your account</div>
                <div className="invite-agent-paragraph">
                  Ready for more? Upgrading your account opens up a complete
                  cloud call center with IVR, call routing, SMS and voice
                  broadcast, number masking, number randomization, integration
                  with Webhook and API and much more.
                </div>
              </div>

              <div className="good-to-know-container mt-5">
                <div className="presently-admin-dashboard">
                  Currently, you're exploring the trial version with limited
                  features.
                  <br />
                  <strong>When you upgrade</strong>, here's what awaits you in
                  the full account:
                </div>

                {/* <div className="presently-admin-dashboard mt-5">
                </div> */}
              </div>

              <table className="featureTable">
                <tr>
                  <th>Features</th>
                  <th>Free Trial</th>
                  <th>Upgrade</th>
                </tr>
                <tr>
                  <td>Agents</td>
                  <td>One</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <td>Virtual Mobile Number</td>
                  <td>One</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <td>Incoming Calls</td>
                  <td>From admin only</td>
                  <td>From anyone</td>
                </tr>
                <tr>
                  <td>Outgoing Calls</td>
                  <td>To admin only</td>
                  <td>To anyone</td>
                </tr>
                <tr>
                  <td>SMS inbox</td>
                  <td>Free trial</td>
                  <td>From anyone</td>
                </tr>
                <tr>
                  <td>IVR</td>
                  <td>{'\u2718'}</td>
                  <td>{'\u2714'}</td>
                </tr>
                <tr>
                  <td>Call Routing</td>
                  <td>{'\u2718'}</td>
                  <td>{'\u2714'}</td>
                </tr>
                <tr>
                  <td>Voice Broadcast</td>
                  <td>{'\u2718'}</td>
                  <td>{'\u2714'}</td>
                </tr>
                <tr>
                  <td>SMS Broadcast</td>
                  <td>{'\u2718'}</td>
                  <td>{'\u2714'}</td>
                </tr>
                <tr>
                  <td>Number Masking</td>
                  <td>{'\u2718'}</td>
                  <td>{'\u2714'}</td>
                </tr>
                <tr>
                  <td>Number Randomization (RecoveryX)</td>
                  <td>{'\u2718'}</td>
                  <td>{'\u2714'}</td>
                </tr>
                <tr>
                  <td>Integration</td>
                  <td>{'\u2718'}</td>
                  <td>{'\u2714'}</td>
                </tr>
                <tr>
                  <td>Support</td>
                  <td>{'\u2714'}</td>
                  <td>{'\u2714'}</td>
                </tr>
              </table>

              {/* <div className="creating-an-agent">
                <span>How to receive an incoming call</span>
              </div>

              <div className="invite-agent-paragraph">
                <span>
                  1. Open the ‘
                  <span
                    onClick={handleRedirectToUserPage}
                    className="text-admin-bold color-blue-under-line"
                  >
                    {' '}
                    Agents
                  </span>
                  ’ tab in the admin dashboard.
                </span>
              </div>
              <div className="invite-agent-paragraph">
                <span>
                  2. Call the ‘
                  <span className="text-admin-bold"> VIRTUAL NO</span>’ assigned
                  to your agent using the admins registered phone number.
                </span>
              </div>
              <div className="invite-agent-paragraph">
                <span>
                  Your call will then get forwarded to the Agent’s registered
                  phone number. Answer that phone to connect the call.
                </span>
              </div>

              <div className="creating-an-agent">
                <span>How to place an outgoing call</span>
              </div>
              <div className="invite-agent-paragraph">
                <span className="display-block">
                  Open and sign into the Agent dashboard in incognito.
                </span>
                Click the ‘
                <span className="text-admin-bold mt-2">Place an outgoing</span>’
                button.
                <span className="display-block">
                  Open and sign into the Agent dashboard in incognito.
                </span>
                Enter the{' '}
                <span className="text-admin-bold mt-2">
                  Admins registered phone number
                </span>{' '}
                and choose an outgoing number from the dropdown and click call.
              </div>

              <div className="invite-agent-paragraph">
                You will receive a call to your Agents registered phone number.
                Answer that call first, your Admin’s registered phone should
                ring immediately after. Answer that phone to connect the call.
              </div>

              <div className="creating-an-agent">
                <span>How to receive an SMS</span>
              </div>
              <div className="invite-agent-paragraph">
                <span className="display-block">
                  On your Admin’s phone send an SMS to the Agent’s virtual phone
                  number.
                </span>
                Both Admin and Agents should be able to view the SMS in the SMS
                tab on their respective dashboards.
              </div> */}
              <div className="invite-agent-paragraph">
                <span className="display-block">Need help?</span>
                Email us at{' '}
                <span className="color-blue-under-line">support@alohaa.ai</span>
              </div>
              <div style={{ marginBottom: '24px' }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
});

// export default commonStyle(connect(mapStateToProps, {})(VerifiedContact));

export default styled(connect(mapStateToProps, {})(VerifiedContact))`
  .contentFull {
    margin-left: 230px;
    height: 100vh;
    transition: margin 0.3s;
    background: ${(props) => props.theme.colors.tableBgColor};
  }
  .ContentFreeTrial {
    margin-left: 72px;
    height: 100vh;
    -webkit-transition: margin 0.3s;
    transition: margin 0.3s;
    background: ${(props) => props.theme.colors.tableBgColor};
  }
  .contentContainer {
    padding-left: 30px;
    padding-top: 40px;
    background: ${(props) => props.theme.colors.tableBgColor};
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  /* Style for the header flex container */
  .headerFlex {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: -20px;
  }

  .backIcon {
    cursor: pointer;
    font-size: 30px;
    padding: 5px;
    color: #196cca;
    margin-bottom: 7px;
  }

  .freeTrialHeader {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #196cca;
  }

  .horizontalLineHeading {
    width: 103%;
    height: 0;
    margin-left: -29px;
    border: solid 1px #d2d3d4;
    margin-bottom: 24px;
  }
  .good-to-know-container {
    width: 594px;
    padding: 12px 42px 30px 24px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    background: ${(props) => props.theme.colors.goodToKnowContainerBgColor};
    color: ${(props) => props.theme.colors.tableContentColor};
  }

  .start-tour {
    margin-left: 15px;
    margin-top: 20px;
    display: block;
    color: #007bff;
    border-color: #007bff;
  }

  .good-to-know {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    display: block;
    color: ${(props) => props.theme.colors.tableContentColor};
  }
  .presently-admin-dashboard {
    width: 528px;
    height: 19px;
    margin: 16px 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => props.theme.colors.tableContentColor};
  }

  .theme-color-free-trial {
    color: ${(props) => props.theme.colors.tableContentColor};
  }

  .text-admin-bold {
    font-weight: bold;
  }

  .creating-an-agent {
    font-size: 20px;
    font-weight: bold;
    margin-top: 24px;
  }

  .invite-agent {
    font-size: 18px;
    font-weight: bold;
    margin-top: 24px;
  }

  .section-container-steps {
    width: 594px;
  }
  .invite-agent-paragraph {
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
  }
  .red-notes {
    margin-top: 8px;
    font-size: 14px;
    color: #ea4b4b;
    font-weight: 500;
  }

  .display-block {
    display: block;
  }

  .color-blue-under-line {
    color: #196cca;
    text-decoration: underline;
    cursor: pointer;
  }

  .ContentFreeTrial {
    margin-left: 72px;
    height: 100vh;
    -webkit-transition: margin 0.3s;
    transition: margin 0.3s;
    background: #ffffff;
  }

  .featureTable {
    margin-top: 24px;
    width: 100%;
  }

  .featureTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    padding-left: 12px;
  }

  .featureTable td {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
  }

  .featureTable tr:nth-child(even) {
    background-color: #fde5e5;
  }
`;

import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../common/component/popups/popups';
import Close from '../../assets/images/popupClose.png';
import "./styles.css";

export default function ExportRecoveryAgents(props) {
  const [list, setList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [excelData, setExcelData] = useState([]);
  let columns = [
    { id: 'agentName', displayName: 'AGENT NAME' },
    { id: 'totalCalls', displayName: 'TOTAL CALLS' },
    { id: 'totalConnectedCalls', displayName: 'TOTAL CALLS - CONNECTED' },
    { id: 'incomingCalls', displayName: 'INCOMING CALLS' },
    { id: 'incomingConnectedCalls', displayName: 'INCOMING CALLS - CONNECTED' },
    { id: 'outgoingCalls', displayName: 'OUTGOING CALLS' },
    { id: 'outgoingConnectedCalls', displayName: 'OUTGOING CALLS - CONNECTED' },
  ];
  const openPopup = () => {
    setIsPopupOpen(true);
    setList(props.agentList || []);
    let data = [{}];
    if (props?.agentList?.length) {
      console.log(props?.agentList?.length);
      data = props?.agentList?.map((row) => ({
        agentName: row?.name || "-",
        totalCalls: ((row?.totalIncomingCalls ?? 0) + (row?.totalOutgoingCalls ?? 0)) || "-",
        totalConnectedCalls: ((row?.answeredIncomingCalls ?? 0) + (row?.answeredOutgoingCalls ?? 0)) || "-",
        incomingCalls: row?.totalIncomingCalls || "-",
        incomingConnectedCalls: row?.answeredIncomingCalls || "-",
        outgoingCalls: row?.totalOutgoingCalls || "-",
        outgoingConnectedCalls: row.answeredOutgoingCalls || "-"
      }));
    }
    setExcelData(data);
    console.log(data);
    setDataFetched(true);
  };
  return (
    <div>
      <button
        className="btn btn-outline-success exportbutton"
        onClick={openPopup}
      >
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download Agent List</p>
            <img
              className="PopupClose"
              src={Close}
              alt=""
              onClick={() => {
                setIsPopupOpen(false);
                setDataFetched(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file.
            </p>
            <p className="PopupNote">
              Max download limit - Recent 10,000 entries
            </p>
            {dataFetched ? (
              <CsvDownloader
                filename={`agent_report_list_between_${props.fromDate
                  ? props.fromDate
                  : new Date().toISOString().slice(0, 10)
                  }_and_${props.toDate
                    ? props.toDate
                    : new Date().toISOString().slice(0, 10)
                  }`}
                separator=","
                columns={columns}
                datas={excelData}
                className="ButtonFullWidth BgGreen"
                text="Download CSV"
              />
            ) : (
              <button
                className="ButtonFullWidth BgGrey"
                style={{ cursor: 'default' }}
              >
                SAVE
              </button>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}

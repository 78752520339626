import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import DataCard from '../component/DataCard';
import DataTable from '../../../common/component/table/DataTable';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import { GET } from '../../../services/HttpRequests';
import Online from '../../../assets/images/smallCircleGreen.png';
import Offline from '../../../assets/images/smallCircleYellow.png';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import moment from 'moment';
import { toast } from 'react-toastify';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import ExportMessageBroadcastPopup from '../component/ExportMessageBroadcastPopup';
const broadcastUrl = process.env.REACT_APP_BROADCAST_API;
const organisationId = localStorage.getItem('doosra-biz-organisation-id');

export function MesssageBroadcastDetails(props) {
  const [campaignData, setCampaignData] = useState({});
  const [fetching, setFetching] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [metricsData, setMetricsData] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  const fetchCampaignDetailsHandler = (id, pageNo) => {
    if (!id) return;
    setFetching(true);
    GET(broadcastUrl + 'v1/sms', { id, pageNo, pageSize: 15 })
      .then((res) => {
        setCampaignData(res?.data?.response.broadcast);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
        toast.error('An error occured');
      });
  };

  const fetchSmsBroadcastMetrics = async () => {
    setFetching(true);
    GET(broadcastUrl + 'v1/sms/metrics', {
      organisationId,
      campaignId: id
    })
      .then((res) => {
        setMetricsData(res?.data?.response);
      })
      .catch((err) => {
        setFetching(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      })
  }

  useEffect(() => {
    fetchCampaignDetailsHandler(id);
  }, [id]);

  useEffect(() => {
    fetchSmsBroadcastMetrics();
  }, []);

  const fetchCampaignCallDetails = (pageNo) => {
    fetchCampaignDetailsHandler(id, pageNo);
    setPageNo(pageNo);
  };

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/sms-broadcasts-list')}
                alt=""
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {campaignData?.name}
              </p>
            </div>
            <ExportMessageBroadcastPopup id={id} />
          </TabSearchBar>
          {fetching && <PreLoader />}
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            {/* <DataCard
              title="Campaign progress"
              count={(metricsData?.totalSmsDelivered / metricsData?.totalLeads) * 100}
            /> */}
            <DataCard
              title="Total Contacts"
              count={metricsData?.totalLeads}
            />
            <DataCard
              title="Total SMS’es delivered"
              count={metricsData?.totalSmsDelivered}
            />
            <DataCard
              title="Total SMS’es undelivered"
              count={metricsData?.totalSmsUndelivered}
            />
          </div>

          <DataTable.Table>
            <DataTable.Head>
              <DataTable.CellHead width="20">#</DataTable.CellHead>
              <DataTable.CellHead width="40">LEAD'S NUMBER</DataTable.CellHead>
              <DataTable.CellHead width="100">
                MESSAGE STATUS
              </DataTable.CellHead>
            </DataTable.Head>
            <DataTable.Body>
              {campaignData?.call_details?.length > 0 &&
                campaignData?.call_details?.map((number, index) => (
                  <DataTable.Row id={number.id}>
                    <DataTable.Cell>{1 + index}</DataTable.Cell>
                    <DataTable.Cell>{number.number?.leadNumber}</DataTable.Cell>
                    <DataTable.Cell>{number.number?.status}</DataTable.Cell>
                  </DataTable.Row>
                ))}
            </DataTable.Body>
          </DataTable.Table>
          <Paginator
            currentPage={pageNo}
            lastPage={Math.ceil(campaignData?.total_leads / 15)}
            getInfo={(value) => fetchCampaignCallDetails(value)}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(
  connect(mapStateToProps, {})(MesssageBroadcastDetails)
);

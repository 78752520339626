import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getTopBarData } from '../../../actions/topBarActions';
import DataTable from '../../../common/component/table/DataTable';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { commonStyle } from '../../../assets/css/css';
import { MusicIcon, PlayCallerTuneIcon } from '../../../assets/Icons/Icons';
import UploadPopup from './CallerTuneUploadPopup';
import RemovePopup from './RemoveCallerTunePopup';
import { PUT } from '../../../services/HttpRequests';
import PlayIcon from '../../../assets/images/playIconGreen.svg';
import { audioFileMaxSize } from '../../../common/constants';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import { ADMIN_UPLOAD_GLOBAL_CALLERTUNE_BUTTON, ADMIN_REMOVE_GLOBAL_CALLERTUNE_BUTTON } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";
const callerTuneUrl = process.env.REACT_APP_CALLER_TUNE_API;

class GlobalCallerTune extends Component {
  constructor() {
    super();
    this.state = {
      uploadPopup: false,
      audioPlayed: false,
      deletePopup: false,
      audio: null,
      uploading: false,
    };
  }

  onClickUploadFile = () => {
    this.setState({
      uploadPopup: true,
    });
  };

  closePopup = () => {
    this.setState({
      uploadPopup: false,
      deletePopup: false,
    });
  };

  onClickPlayAudio = () => {
    this.setState({
      audioPlayed: true,
    });
  };

  handelUploadedFile = async (formData) => {
    try {
      logPostHogEvent(ADMIN_UPLOAD_GLOBAL_CALLERTUNE_BUTTON);
      let formData = new FormData();
      this.setState({ uploading: true });

      formData.append('audio', this.state.audio);
      formData.append(
        'organisationId',
        localStorage.getItem('doosra-biz-organisation-id')
      );
      formData.append('type', 'organisation');
      formData.append('id', localStorage.getItem('doosra-biz-organisation-id'));
      const uploadRespose = await PUT(
        `${callerTuneUrl}v1/organisations/caller-tune-set`,
        null,
        formData,
        { 'Content-Type': 'multipart/form-data' }
      );
      this.setState({
        uploadPopup: false,
        uploading: false,
      });
      this.props.getTopBarData();
      toast.success(`Succesfully set Global CallerTune`);
    } catch (err) {
      this.setState({ uploading: false });
      toast.error(err.response.data.error.reason);
    }
  };

  onClickRemoveAudio = async () => {
    this.setState({
      deletePopup: true,
    });
  };

  handleRemoveCallerTune = async () => {
    try {
      logPostHogEvent(ADMIN_REMOVE_GLOBAL_CALLERTUNE_BUTTON);
      const removeGlobalAudio = await PUT(
        `${callerTuneUrl}v1/organisations/caller-tune-unset`,
        null,
        {
          organisationId: localStorage.getItem('doosra-biz-organisation-id'),
          id: localStorage.getItem('doosra-biz-organisation-id'),
          type: 'organisation',
        }
      );
      this.props.getTopBarData();
      this.setState({
        deletePopup: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  fileUploadHandler = (e) => {
    let audio = e.target.files;
    for (let i = 0; i <= audio.length - 1; i++) {
      audio = audio[i];
    }
    if (audio.type !== 'audio/wav') {
      return toast.error('use WAV format only');
    }

    if (audio.size > audioFileMaxSize) {
      return toast.error('use file size less then 500kB');
    }
    this.setState({ audio });
  };

  render() {
    const { uploadPopup, audioPlayed, deletePopup } = this.state;
    const { callerTuneUrl } = this.props;

    return (
      <div>
        <DataTable.Table>
          {false && <PreLoader />}
          <DataTable.Head>
            <DataTable.CellHead
              style={{ width: '2000px' }}
            ></DataTable.CellHead>
          </DataTable.Head>
        </DataTable.Table>
        <div className="globalCallerTuneContainer">
          <p>
            <MusicIcon />
          </p>
          <p className="callerTuneText">Global Callertune</p>
          <p className="setCallerTuneText">
            Set a Callertune that plays when agent or groups in your
            organisation are called
          </p>
          <p className="sloganText">
            You can override Global Callertune by customising Callertunes for
            <br /> each agent or group in their respective Callertune menus
            above.
          </p>
          {!callerTuneUrl ? (
            <div style={{ marginTop: '20px' }}>
              <div>
                <input
                  id="audio"
                  type="file"
                  name="audio"
                  accept="audio/wav"
                  disabled={!isSuperAdminWithPermission('ADD')}
                  onChange={this.fileUploadHandler}
                />
              </div>
              <p
                style={{ color: '#818a91', fontSize: '14px', marginTop: '3px' }}
              >
                {' '}
                WAV supported <br />
                Max size - 500kb, Max audio duration 20s
              </p>
              <div style={{ marginTop: '15px' }}>
                {this.state.uploading ? (
                  <button
                    className="UploadCallertune"
                    style={{ backgroundColor: '#196cca', width: 'fit-content' }}
                  >
                    Uploading ...
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      this.state.audio ? this.handelUploadedFile() : () => {}
                    }
                    style={{
                      backgroundColor: this.state.audio ? '#196cca' : '#939598',
                    }}
                    disabled={!isSuperAdminWithPermission('ADD')}
                    className="UploadCallertune"
                  >
                    Upload
                  </button>
                )}
              </div>
            </div>
          ) : (
            <>
              <div style={{ marginTop: '20px' }}>
                {audioPlayed ? (
                  <audio
                    style={{
                      height: '24px',
                      marginTop: '3px',
                      marginBottom: '-2px',
                    }}
                    controls
                    autoPlay
                    // ref="audio_tag"
                    src={callerTuneUrl}
                  />
                ) : (
                  <React.Fragment>
                    <button
                      onClick={this.onClickPlayAudio}
                      className="AddCallertune"
                    >
                      {' '}
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginLeft: '8px', marginRight: '9px' }}>
                          <PlayCallerTuneIcon />
                        </div>{' '}
                        <span>Play Callertune</span>
                      </div>
                    </button>
                  </React.Fragment>
                )}
                <p
                  style={{
                    color: '#818a91',
                    fontSize: '14px',
                    marginTop: '3px',
                  }}
                >
                  {' '}
                  WAV supported <br />
                  Max size - 500kb, Max audio duration 20s
                </p>
              </div>
              <p>
                <button
                  onClick={this.onClickRemoveAudio}
                  className="removeCallerTune"
                >
                  Remove Callertune
                </button>
              </p>
            </>
          )}
        </div>
        {deletePopup && (
          <RemovePopup
            closePopup={this.closePopup}
            deletePopup
            handleRemoveCallerTune={this.handleRemoveCallerTune}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  balance: state.topbar.balance,
  name: state.topbar.name,
  logo: state.topbar.logo,
  minuteBalance: state.topbar.minuteBalance,
  callForwardingStatus: state.topbar.call_forwarding_status,
  callerTuneUrl: state.topbar.callerTuneUrl,
});

export default commonStyle(
  connect(mapStateToProps, { getTopBarData })(GlobalCallerTune)
);

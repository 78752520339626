import React, { useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import {
  DELETE_CONTACT_BUTTON,
  ADMIN_DELETE_CONTACT_BUTTON,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';

export default function DeleteContactPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const userRole = localStorage.getItem('doosra-biz-user-role');
  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="PopupContainer">
        <div className="PopupHeaderContainer">
          <p>Delete Contact</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => setPopupOpen(false)}
          />
        </div>
        <div className="PopupBody">
          <p className="PopupBodyTitle">
            Are you sure you want to remove this contact?
          </p>

          <button
            className="ButtonFullWidth BgRed"
            onClick={() => {
              props.deleteContactHandler(props.id, props.userId);
              setPopupOpen(false);
            }}
          >
            REMOVE
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <React.Fragment>
      <img
        alt=""
        onClick={() => {
          logPostHogEvent(
            userRole === 'ADMIN'
              ? ADMIN_DELETE_CONTACT_BUTTON
              : DELETE_CONTACT_BUTTON
          );
          setPopupOpen(true);
        }}
        src={Delete}
        style={{ cursor: 'pointer' }}
      />
      {popper}
    </React.Fragment>
  );
}

import React from "react";
import { Bar } from "react-chartjs-2"
import {Chart as ChartJS } from "chart.js/auto"
import { plugins } from "pretty-format";

function BarChart(props) {
    const obj = props.chartData.weeklyCallsData
    const labelsKeys = []
    const total = [];
    const answered = [];
    const unAnswered = [];
    if(obj) {
        for( const key of obj) {
            const dateLabel = key.date.split("T")[0]
            labelsKeys.push(dateLabel)
           total.push(key.totalCalls);
           answered.push(key.totalAnsweredCalls);
           unAnswered.push(key.totalUnAnsweredCalls)
        }
    }
    

    const chartDisplayData = {
        labels: labelsKeys,
        datasets: [{
            label: ["Total Calls"],
            data: total,
            backgroundColor:["#FEDD00"]
        },
        {
            label: [ "Answered Calls"],
            data: answered,
            backgroundColor:["#ffbf80"]
        },
        {
            label: ["Not Answered Calls"],
            data: unAnswered,
            backgroundColor:["#0066ff"]
        }]
    }
    return <Bar data = {chartDisplayData} options =  {{ plugins: {
        title: {
            display: true,
            text: 'Calls Per day for previous week'
        }
    },
    responsive: true,
    maintainAspectRatio: false
}}/>
    
}

export default BarChart
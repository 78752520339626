import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import mailIcon from '../../../assets/images/icons8-mail-48.png';
import { commonStyle } from '../../../assets/css/css';
import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import { Button, Card, Container, Spinner } from 'react-bootstrap';
import { POST } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;

export function UploadKyc(props) {
  const [kycDoc, setKycDoc] = useState(null);
  let isKycSubmitted = localStorage.getItem('is_kyc_submitted');
  isKycSubmitted = isKycSubmitted ? (isKycSubmitted === 'true' ? true : false) : false;
  const [docUploaded, setDocUploaded] = useState(isKycSubmitted);
  const [loading, setLoading] = useState(false);
  const orgId = localStorage.getItem('doosra-biz-organisation-id');


  const onChangeFile = (e) => {
    setKycDoc(e.target.files[0]);
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData()
    formData.append('kycDoc', kycDoc);
    formData.append('organisationId', orgId);
    setLoading(true);
    return await POST(orgUrl + 'v1/kyc-documents', formData)
      .then((res) => {
        if (res.data?.response?.updateOrg) {
          setLoading(false);
          setDocUploaded(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };


  return (
    <div className={`${props.className}`}>
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <br />
          <Container>
            <Card className="text-center" border='0'>
              <Card.Header ><h3>UPLOAD KYC DOCUMENT</h3></Card.Header>
              <Card.Body>
                <Card.Title></Card.Title>
                <Card.Text>

                  {loading && (<Spinner animation="border" />)}
                  {
                    docUploaded ? (<div>
                      <img alt="" src={mailIcon} />
                      <p style={{ color: 'green' }}>Thank you for showing interest. Your submission is transfered to our kyc verification team, kindly wait for an email confirmation, which will be sent to your mail.</p>
                    </div>) :
                      (
                        <form onSubmit={onSubmit}>
                          <div className="form-group">

                            <input type="file" onChange={onChangeFile} />
                            <Button className="btn btn-primary" type="submit">Upload</Button>

                          </div>
                          <p style={{ color: "red" }}>*only pdf files are allowed</p>
                        </form>)
                  }
                </Card.Text>
              </Card.Body>
              <Card.Footer className="text-muted" ></Card.Footer>
            </Card>
          </Container>
        </div>
      </div>
    </div >
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
});
export default commonStyle(connect(mapStateToProps, {})(UploadKyc));

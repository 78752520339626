import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { plugins } from 'pretty-format';

function DonoutChart(props) {
  const obj = props.chartData.tagToCountMapper;
  const lables = [];
  const values = [];
  const selectedColours = [];
  const colours = [
    ' #ffb366',
    '#ffd633',
    '#99e600',
    '#8080ff',
    '#0099ff',
    '#66ff99',
    '#3399ff',
    '#d65cad',
    '#b380ff',
    '#666699',
    '#cca300',
    '#008040',
    '#0099cc',
    '#66ccff',
  ];
  let i = 0;
  for (const key in obj) {
    lables.push(key);
    values.push(obj[key]);
    selectedColours.push(colours[i]);
    i = (i + 1) % 15;
  }

  const chartDisplayData = {
    labels: lables,
    datasets: [
      {
        data: values,
        backgroundColor: selectedColours,
      },
    ],
  };
  return (
    <Doughnut
      data={chartDisplayData}
      options={{
        plugins: {
          title: {
            display: true,
            text: 'Leads Dispositions Bucket',
          },
          legend: {
            position: 'left',
            lables: {
              usePointStyle: true,
              // padding: 100
            },
          },
        },
        responsive: true,
        maintainAspectRatio: true,
      }}
    />
  );
}

export default DonoutChart;

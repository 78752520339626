/* eslint-disable */
import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { POST, GET } from '../../../services/HttpRequests';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import downloadIcon from '../../../assets/images/DownloadButton.svg';
import '../../../assets/css/SearchBar.css';
import cancelIcon from '../../../assets/images/cancelIconCircleGreen.png';
import downloadIconGreen from '../../../assets/images/downloadIconGreen.png';
import './styles.css';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import { Spinner } from 'react-bootstrap';
const userUrl = process.env.REACT_APP_USER_API;
const URL = process.env.REACT_APP_SAMPLE_FILE_URL;
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;

const SampleFile = () => {
  return (
    <>
      <CsvDownloader
        style={{
          cursor: 'pointer',
          // color: '#196cca',
          textDecoration: 'none',
          textAlign: 'center',
          marginBottom: '16px',
        }}
        filename="LeadList"
        // text="Sample File"
        datas={[
          {
            number: '1234567890',
          },
          {
            number: '1234567890',
          },
        ]}
      >
        <a
          style={{
            color: '#13ba0c',
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          Download Sample Sheet{' '}
          <img
            src={downloadIconGreen}
            alt=""
            style={{ margin: '0 0 3px 8px' }}
          ></img>
        </a>
      </CsvDownloader>

      <div
        style={{
          width: '100%',
          height: '56px',
          fontSize: '12px',
          color: '#939598',
          marginBottom: '24px',
          backgroundColor: '#f4f4f4',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        Download the sample sheet and fill it in the
        <br />
        same format without changing rows or columns
      </div>
    </>
  );
};

export default function DeleteLeadsPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userRole = localStorage.getItem('doosra-biz-user-role');

  const submitFileForDelete = async () => {
    try {
      if (!file) {
        toast.error('Please upload file');
        return;
      }
      let formData = new FormData();
      formData.append('file', file);
      formData.append('campaignId', props.campaignId);
      const organisationId = localStorage.getItem('doosra-biz-organisation-id');
      formData.append('organisationId', organisationId);
      let undeletedNumbers = [];
      setIsLoading(true);
      const res = await POST(
        `${outGoingUrl}v1/organisations/outbound-call/bulk-delete-campaign-leads`,
        formData,
        { 'Content-Type': 'multipart/form-data' }
      );
      if (res.data.response?.undeletedNumbers) {
        undeletedNumbers = res?.data?.response?.undeletedNumbers || [];
      }
      if (undeletedNumbers.length > 0) {
        let csvstring = 'number\n';
        for (let i = 0; i < undeletedNumbers.length; i += 1) {
          const tempString = undeletedNumbers[i];
          csvstring = `${csvstring + tempString}\n`;
        }
        const blob = new Blob([csvstring], { type: 'text/csv' });
        const URL = window.URL || window.webkitURL;
        const downloadFileUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadFileUrl;
        a.download = 'un_deleted_numbers.csv';
        a.style = 'display:none';
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
      props.handleFetchCampaignDetails(props.campaignId);
      setIsLoading(false);
      toast.success(`Successfully Deleted !!!`);
      setFile(null);
      setPopupOpen(false);
    } catch (e) {
      toast.error(e.response.data.error.reason);
      setIsLoading(false);
      setFile(null);
      setPopupOpen(false);
    }
  };

  const handleRemoveFile = () => {
    document.getElementById('fileInput').value = '';
    setFile(null);
  };

  const reset = () => {
    setFile(null);
  };

  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="PopupContainer" style={{ overflow: 'auto' }}>
        <div className="PopupHeaderContainer">
          <p>Bulk Delete</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => {
              setPopupOpen(false);
              reset();
            }}
          />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Upload File</label>
            <ul style={{ listStyleType: 'none', padding: '0' }}>
              {file && (
                <li
                  key={1}
                  style={{
                    display: 'flex',
                    gap: '16px',
                    marginBottom: '8px',
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      width: '562px',
                      height: '48px',
                      backgroundColor: 'rgba(7, 170, 63, 0.16)',
                      borderRadius: '4px',
                      textDecoration: 'none',
                      margin: 0,
                      fontSize: '16px',
                      fontWeight: 500,
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px',
                    }}
                  >
                    {file?.name}
                  </p>
                  <img
                    style={{ paddingRight: '20px' }}
                    src={cancelIcon}
                    onClick={() => handleRemoveFile()}
                  />
                </li>
              )}
            </ul>
            <input
              className="PopupInput"
              id="fileInput"
              onChange={(e) => {
                const selectedFile = e.target.files[0];
                setFile(selectedFile);
              }}
              type="file"
            />
            <br />
            <br />
            <SampleFile />
          </div>
          <div className="ErrorContainer ErrorContainerMargin">
            {isLoading && (
              <div style={{ color: '#287dc9' }}>
                Please wait, we're working on it...
              </div>
            )}
            {error ? (
              <React.Fragment>
                <img
                  alt=""
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
                {error}
              </React.Fragment>
            ) : null}
          </div>
          <button
            className="ButtonFullWidth"
            style={{ marginTop: '10px' }}
            type="submit"
            onClick={submitFileForDelete}
          >
            {isLoading && <Spinner animation="border"></Spinner>}
            {!isLoading && 'DELETE'}
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex' }}>
      <span>
        <button
          className="Otherbutton"
          disabled={!isSuperAdminWithPermission('DELETE')}
          style={{
            margin: '11%',
            marginLeft: '0%',
            cursor: 'pointer',
            backgroundColor: '#287dc9',
            color: 'white',
          }}
          onClick={() => setPopupOpen(true)}
        >
          BULK DELETE
        </button>
        {popper}
      </span>
    </div>
  );
}

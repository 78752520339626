/* eslint-disable no-unused-vars */
import adapter from 'webrtc-adapter';
import './StickyDialer.css';
import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import JsSIP from 'jssip';
import { toast } from 'react-toastify';
import AlohaaLogoWhite from "../../assets/images/AlohaaLogoWhite.png";
import AlohaaWhiteLogoNoBackground from "../../assets/images/AlohaaWhiteLogoNoBackground.svg";
import HangupLogo from "../../assets/images/RecoveryHangup.webp";
import HoldLogo from "../../assets/images/PauseIcon.png";
import MuteLogo from "../../assets/images/MicLogo.png";
import activeStatus from "../../assets/images/activeStatus.svg";
import tea from "../../assets/images/tea.svg";
import lunch from "../../assets/images/lunch.svg";
import { useLocation } from 'react-router-dom';
import meeting from "../../assets/images/meeting.svg";
import training from "../../assets/images/training.svg";
import biobreak from "../../assets/images/biobreak.svg";
import offline from "../../assets/images/offline.svg";
import busy from "../../assets/images/busy.svg";
import ringing from "../../assets/images/ringingStatus.svg";
import { RiRefreshFill } from "react-icons/ri";
import { DEV_INHOUSE_PBX_SERVERS, PROD_INHOUSE_PBX_SERVERS, TURN_SERVER_CONFIG } from '../../assets/constants';
import { GET, POST, PUT } from '../../services/HttpRequests';
import { useSocket } from "../../providers/SocketProvider";
import {
  setWebRtcConnectionStatus,
  setDialerCallId,
  setIsSingleCall,
  changeDispostionStatus,
  changeAgentActiveStatus,
  setWebRtcSessions,
  setCallId,
  setSingleCallStatus,
  setBatchCallId,
  setCampaignId
} from "../../actions/recoveryXActions";
import PreLoader from '../../common/component/preLoader/PreLoader';
import { MenuItem, Select } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
JsSIP.debug.enable('JsSIP:*');
const sip_details = JSON.parse(localStorage.getItem("sip_details"));
const username = sip_details?.username;
const password = sip_details?.password;
const pbxServicesUrl = process.env.REACT_APP_ARI_OUTGOING;
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
let userRole = localStorage.getItem('doosra-biz-user-role');
const StickyDialer = (props) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchParamCampaignId = searchParams.get('campaignId');
  let showErrorMessage = true;
  const [isOpen, setIsOpen] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isDialing, setIsDialing] = useState(false);
  const [isCallConnected, setIsCallConnected] = useState(false);
  const [WebRTCEnabled, SetWebRTCEnabled] = useState(JSON.parse(localStorage.getItem("is_webrtc_enabled")));
  const [UAList, setUAList] = useState([]);
  const [DialData, setDialData] = useState([]);
  const [CallStatus, setCallStatus] = useState("No active calls.");
  const [ConnectedCallData, setConnectedCallData] = useState();
  const [currentCampaignName, setCurrentCampaignName] = useState(null);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [campaignId, setCampaignId] = useState("");
  const [isCallOnHold, setIsCallOnHold] = useState(false);
  const [isCallOnMute, setIsCallOnMute] = useState(false);
  const [connectedCallId, setConnectedCallId] = useState("");
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedAgentStatus, setSelectedAgentStatus] = useState('offline');
  const [ringingWaitingCalls, setRingingWaitingCalls] = useState({ waitingCount: 0, ringingCount: 0 });
  const [agentMetric, setAgentMetric] = useState(null);
  const [selectedDisposition, setSelectedDisposition] = useState("select");
  const [dispositions, setDispositions] = useState([]);
  const [timeDelayForChangeStatus, setTimeDelayForChangeStatus] = useState(0);
  const [checkTimeDelayForChangeStatus, setCheckTimeDelayForChangeStatus] = useState(false);
  const [timer, setTimer] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [runRingingWaitingCallsInfo, setRunRingingWaitingCallsInfo] = useState(false);
  const [activePbxServers, setActivePbxServers] = useState([]);
  const [showConnectionLostMessage, setShowConnectionLostMessage] = useState(true);
  const [isAgentStatusChangePermitted, setIsAgentStatusChangePermitted] = useState(false);
  const [isPersistentCall, setIsPersistentCall] = useState(false);
  const [RTCSessionData, setRTCSessionData] = useState(null);
  const INHOUSE_PBX_SERVERS = useSelector((state) => state.recovery.domainsOfCampaign);
  const callingStarted = useRef(false);
  const optionschangeAgentActiveStatus = [
    { value: 'active', label: 'Active', image: activeStatus, color: '#4daa3e', disable: false },
    { value: 'tea', label: 'Tea', image: tea, color: '#196cca', disable: false },
    { value: 'lunch', label: 'Lunch', image: lunch, color: '#196cca', disable: false },
    { value: 'meeting', label: 'Meeting', image: meeting, color: '#196cca', disable: false },
    { value: 'training', label: 'Training', image: training, color: '#196cca', disable: false },
    { value: 'biobreak', label: 'Biobreak', image: biobreak, color: '#196cca', disable: false },
    { value: 'offline', label: 'Offline', image: offline, color: '#939598', disable: false },
    { value: 'busy', label: 'Busy', image: busy, color: '#F5221B', disable: true },
    { value: 'calling', label: 'Dailing', image: ringing, color: '#F2C911', disable: true },
  ];
  useEffect(() => {
    if (runTimer) {
      const interval = setInterval(() => {
        setTimer(t => t + 1);
      }, 1000);
      return () => {
        clearInterval(interval);
      }
    }
  }, [timer, runTimer]);

  useEffect(() => {
    const isPersistentConnectionEnabled = localStorage.getItem("is_persist_connection_enabled") === "true" ? true : false;
    setIsPersistentCall(isPersistentConnectionEnabled);
    console.log("isPersistentConnectionEnabled", isPersistentConnectionEnabled);
  }, [])

  useEffect(() => {
    if (runRingingWaitingCallsInfo) {
      const interval = setInterval(() => {
        getRingingWaitingCalls();
      }, 5000);
      return () => {
        clearInterval(interval);
      }
    }
  }, [runRingingWaitingCallsInfo]);

  useEffect(() => {
    if (location.pathname === "/static-campaign" || location.pathname === "/dynamic-campaign") {
      setIsAgentStatusChangePermitted(true);
    } else {
      setIsAgentStatusChangePermitted(false);
    }
  }, [location, isAgentStatusChangePermitted])

  useEffect(() => {
    if (checkTimeDelayForChangeStatus) {
      const interval = setInterval(() => {
        setTimeDelayForChangeStatus(t => t + 1);
        if (timeDelayForChangeStatus === 30) {
          setCheckTimeDelayForChangeStatus(false);
          setTimeDelayForChangeStatus(0);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      }
    }
  }, [timeDelayForChangeStatus, checkTimeDelayForChangeStatus]);

  let options = {
    mediaConstraints: { audio: true, video: false },
    pcConfig: {}
  };
  let socket = useSocket();
  const userId = localStorage.getItem('doosra-biz-user-id');
  useEffect(() => {
    if (socket) {
      socket.on('dailedNumbers', (message, cb) => {
        console.log("ReceivedEvent dailedNumbers", message)
        const data = message.destinationNumberObjs;
        setDialData(data);
        setIsDialing(true);
        setIsCallConnected(false);
        setTimer(0);
        setRunTimer(false);
        cb({
          status: "Acknowledged"
        });
      });
      socket.on('answeredNumber', (message, cb) => {
        console.log("ReceivedEvent answeredNumber", message)
        const data = message.answeredLead;
        const tableInfo = message.data;
        setConnectedCallData(data);
        setCampaignId(message.campaignId);
        setTableData(tableInfo);
        setIsDialing(false);
        setIsCallConnected(true);
        setRunTimer(true);
        cb({
          status: "Acknowledged"
        });
      });
      socket.on('singleCall', (message, cb) => {
        console.log("ReceivedEvent singleCall", message)
        if (message.isSingleCall) {
          const data = message.user;
          const tableInfo = message.data;
          const userObj = data[0];
          props.setIsSingleCall({ status: true });
          setDialData(data);
          setConnectedCallData({ name: userObj?.name || "-", number: userObj?.number || "-" });
          setCurrentCampaignName(message?.campaignName || "-");
          setTableData(tableInfo);
          setIsDialing(true);
          setCallStatus("");
        }
        if (message.isSingleEnded || message.isSingleCallEnded) {
          props.setIsSingleCall({ status: false })
          setIsDialing(false);
          setIsCallConnected(false);
          setDialData([]);
          setTimer(0);
          setRunTimer(false);
          setConnectedCallData([]);
          setCallStatus("No active calls.");
          props.changeDispostionStatus({ status: true });
          props.setCallId("");
          props.setSingleCallStatus({ singleCallStatus: false });
          props.setDialerCallId({ id: "" });
        }
        cb({
          status: "Acknowledged"
        });
      })
      socket.on("changeStatus", (message, cb) => {
        console.log("ReceivedEvent changeStatus ", message)
        if (message !== "") {
          setCallStatus(message);
          if (message === "Paused for 10 seconds" || message === "Automated calling paused for 30 sec") {
            setIsDialing(false);
            setIsCallConnected(false);
            setTimer(0);
            setRunTimer(false);
          }
          if (message === "Connected") {
            setIsDialing(false);
            setIsCallConnected(true);
            setRunTimer(true);
          }
        }
        cb({
          status: "Acknowledged"
        });
      });
      socket.on("callId", (message, cb) => {
        console.log("ReceivedEvent callId ", message);
        setConnectedCallId(message);
        props.setDialerCallId({ id: message });
        setSelectedDisposition("select");
        cb({
          status: "Acknowledged"
        });
      });
      socket.on("changeAgentStatus", (message, cb) => {
        setSelectedAgentStatus(message.status);
        cb({
          status: "Acknowledged"
        });
      });
      socket.on("callCompleted", (message, cb) => {
        console.log("ReceivedEvent callCompleted ", message);
        setCallStatus("Waiting for a call ...");
        setIsCallConnected(false);
        setIsDialing(false);
        setSelectedDisposition("select");
        setTimer(0);
        setRunTimer(false);
        props.setCallId("");
        cb({
          status: "Acknowledged"
        });
      });
      socket.on("campaignCompleted", (message, cb) => {
        console.log("ReceivedEvent campaignCompleted ", message);
        setCallStatus("The ongoing campaign has finished.");
        setIsCallConnected(false);
        setIsDialing(false);
        setTimer(0);
        setRunTimer(false);
        setRunRingingWaitingCallsInfo(false);
        callingStarted.current = false;
        props.setCallId("");
        cb({
          status: "Acknowledged"
        });
      });
      socket.on("answeredCall", (message, cb) => {
        console.log("ReceivedEvent answeredCall ", message);
        setCurrentCampaignName(message?.destinationLead[0].campaignName);
        setConnectedCallData(message?.destinationLead[0].matchedLead);
        setIsCallConnected(true);
        setSelectedDisposition("select");
        setCampaignId(message.campaignId);
        props.setDialerCallId({ id: message?.campaignId });
        setIsDialing(false);
        setRunTimer(true);
        setConnectedCallId(message?.callId);
        cb({
          status: "Acknowledged"
        });
      });
      socket.on("campaignId", (message, cb) => {
        console.log("RECEIVED CAmpaing", message);
        setCampaignId(message);
        cb({
          status: "Acknowledged"
        });
      })
    }
  }, [socket])
  useEffect(() => {
    if (socket) {
      socket.on('stopBatchCall', (message, cb) => {
        console.log("ReceivedEvent stopBatchCall ", message)
        setCallStatus("No active calls.")
        if (props.dialerCallId) {
          setIsDialing(false);
          setIsCallConnected(false);
          hangUpActiveCall();
        }
        cb({
          status: "Acknowledged"
        });
      });
      return () => {
        socket.removeAllListeners("stopBatchCall")
      }
    }
  }, [socket, props.dialerCallId, props.isSingleCall])
  useEffect(() => {
    // getAgentMetric();
    getDispositions();
    handleAgentStatus({ target: { value: 'offline' } }, false);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [])
  // Reload  conformation 
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    props.setWebRtcConnectionStatus({ status: false });
    disconnectWebRtcConnection();
    destroyWebsocketConnection();
    resetValues();
  };

  const resetValues = () => {
    setIsCallOnHold(false);
    setIsCallOnMute(false);
    setRunTimer(false);
    setTimer(0);
    setConnectedCallId("");
    props.setDialerCallId({ id: "" });
    setSelectedDisposition("select");
    if (props.isSingleCall) {
      setIsDialing(false);
    }
    setIsDialing(false);
    setIsCallConnected(false);
  }

  useEffect(() => {
    UAList.forEach(({ ua: UA }) => {
      UA.on('disconnected', (e) => {
        UA.unregister({ all: true });
        const disconnectedSocket = e.socket._url
        const domainName = getDomainName(disconnectedSocket);
        sendSocketConnectionStatus(domainName, "disconnected")
      })
      UA.on('connected', (e) => {
        const connectedSocket = e.socket.socket._url
        console.log("user registered successfully", connectedSocket)
        /*
          1. DONT USE CONNECTED EVENT TO SEND THE CONNECTION STATUS BECAUSE IT IS 
            TRIGGERED BEFORE THE WEBSOCKET CONNECTION IS ESTABLISHED COMPLETELY.
          2. USE REGISTERED EVENT TO SEND THE CONNECTION STATUS BECAUSE IT IS THE MAIN 
            EVENT THAT IS TRIGGERED AFTER THE WEBSOCKET CONNECTION IS ESTABLISHED COMPLETELY.
        */
        // const domainName = getDomainName(connectedSocket);
        // setTimeout(() => {
        //   sendSocketConnectionStatus(domainName, "connected")
        // }, 1000);
      })
      UA.on('registered', (e) => {
        console.log("user registered successfully")
        const connectedSocket = e.response.from._uri._host
        setTimeout(() => {
          sendSocketConnectionStatus(connectedSocket, "connected")
        }, 1000);
        // setIsAuthenticatedUser(true);
      })
      UA.on('registrationFailed', (e) => {
        console.log("user registration failed")
        // setIsAuthenticatedUser(false);
      })
      UA.on('unregistered', () => {
        console.log("user unregistration event")
      })
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UAList]);
  useEffect(() => {
    const RTCSessions = [];
    UAList.forEach(({ ua: UA }) => {
      UA.on('newRTCSession', (e) => {
        console.log("Setting the newRTCSession");
        const newSession = e.session;
        /* START ICE CONFIG */
        let iceServers = [
          {
            urls: ["stun:stun.l.google.com:19302"],
            // eslint-disable-next-line no-dupe-keys
          }, {
            urls: ["stun:stun.ekiga.net"]
          }
        ]
        const pbx = newSession._ua._configuration.hostport_params
        iceServers.push(TURN_SERVER_CONFIG[pbx])
        options.pcConfig["iceServers"] = iceServers
        newSession.on("icecandidate", (candidate) => {
          console.log("iceCandidate gathered - ", candidate);
        })
        /* END ICE CONFIG */
        if (newSession.direction === 'incoming' && newSession.status === 4) {
          setRTCSessionData(newSession)
          RTCSessions.push(newSession);
          newSession.on("accepted", function (event) {
            console.log(" Call Accepted ")
          });
          newSession.on("confirmed", function (event) {
            if (event.originator === "remote") {
            }
          });
          // Call failed event
          newSession.on('failed', (event) => {
            console.log(" Call failed ");
            // resetValues();
          });
          // Call hungup event
          newSession.on('ended', (event) => {
            console.log(" Call hangup ");
            // resetValues();
          });
          // Answer Call
          if (newSession.status === 4) {
            newSession.answer(options);
            console.log("iceConnectionState - ", newSession);
          }
          // Audio connection  
          newSession.connection.addEventListener('addstream', (e) => {
            var audio = new Audio();
            audio.srcObject = e.stream;
            audio.play();
          });
        }
      });
      return () => {
        UA.removeAllListeners("newRTCSession")
      }
    });
    props.setWebRtcSessions({ sessions: RTCSessions });
  }, [UAList]);
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (UAList.length) {
  //       const data = {
  //         initiatorNumber: localStorage.getItem("doosra-biz-user-number"),
  //         domains: INHOUSE_PBX_SERVERS
  //       }
  //       POST(pbxServicesUrl + 'v1/call-center/connect', data)
  //         .then((res) => {
  //           const activeConnections = res?.data?.response?.connections;
  //           if (activeConnections.length > 0) {
  //             emitEvent(userId, "connectionStatus", { connected: true })
  //             props.setWebRtcConnectionStatus({ status: true })
  //           }
  //           setLoading(false);
  //           toast.success("Connected Successfully");
  //           return true;
  //         })
  //         .catch((err) => {
  //           toast.error(err?.response?.data?.error?.reason);
  //           setLoading(false);
  //           return false;
  //         });
  //     }
  //   }, 2000)
  // }, [UAList]);
  const createWebsocketConnection = () => {
    GET(
      cloudCallCenterUrl + 'hosted-pbx-domains',
    )
      .then((res) => {
        if (res?.data?.response?.length > 0) {
          const pbxServerObjs = res?.data?.response;
          let activePbxServersList = pbxServerObjs.filter((pbxObj) => pbxObj.isActive);
          activePbxServersList = activePbxServersList.map((pbxObj) => pbxObj.hostedDomain);
          const activeServersToConnect = INHOUSE_PBX_SERVERS.filter((server) => activePbxServersList.includes(server));
          console.log(activeServersToConnect);
          const uaList = activeServersToConnect.map((server) => {
            const socket = new JsSIP.WebSocketInterface(`wss://${server}/ws`);
            const uaConfig = {
              uri: `sip:${username}@${server}`,
              password: password,
              sockets: [socket],
              connection_recovery_min_interval: 5
            };
            const ua = new JsSIP.UA(uaConfig);
            ua.start();
            return { ua: ua };
          });
          setUAList(uaList);
          return uaList;
        }
      })
      .catch((err) => {
        toast.error(err);
      });

  }
  const destroyWebsocketConnection = (domain) => {
    if (domain) {
      UAList.forEach(({ ua }) => {
        if (ua.hostport_params === domain) {
          ua.stop();
        }
      })
    } else {
      UAList.forEach(({ ua }) => {
        ua.stop();
      })
    }
  }
  const disconnectWebRtcConnection = () => {
    const data = {
      initiatorNumber: localStorage.getItem("doosra-biz-user-number"),
    }
    if (isPersistentCall) {
      POST(pbxServicesUrl + 'v1/call-center/disconnect', data)
        .then((res) => {
          return true;
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          return false;
        });
    }
    const updateUserWebSocketData = {
      "userId": localStorage.getItem('doosra-biz-user-id'),
      "organisationId": localStorage.getItem('doosra-biz-organisation-id'),
      eventType: "disconnected"
    };
    PUT(
      cloudCallCenterUrl + 'websocket/update-user-websockets',
      {},
      updateUserWebSocketData,
    ).then((res) => console.log("Removed all the websocket")).catch((err) => toast.error(err?.response?.data?.error?.reason))
  }
  const handleWebSocketConnect = (data) => {
    const parsedData = JSON.parse(data);
    const postData = {
      initiatorNumber: localStorage.getItem("doosra-biz-user-number"),
      domains: [parsedData.webSocket],
    };
    if (isPersistentCall) {
      POST(pbxServicesUrl + 'v1/call-center/connect', postData)
        .then((res) => {
          PUT(
            cloudCallCenterUrl + 'websocket/update-user-websockets',
            {},
            data,
          ).then((res) => {
            setLoading(false);
            const activeWebsockets = res.data.response.webSockets
            if (activeWebsockets.length > 0) {
              emitEvent(userId, "connectionStatus", { connected: true })
              props.setWebRtcConnectionStatus({ status: true })
            }
            if (activeWebsockets.length === 0) {
              emitEvent(userId, "connectionStatus", { connected: false })
              props.setWebRtcConnectionStatus({ status: false })
              // disconnectWebRtcConnection()
              if (showErrorMessage) {
                showErrorMessage = false;
                toast.error("Disconnected from all the servers", { position: toast.POSITION.BOTTOM_RIGHT });
              }
            }
            return true;
          }).catch((err) => {
            setLoading(false);
            toast.error(err?.response?.data?.error?.reason);
            return false;
          });
          return true;
        }).catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          return false;
        });
    } else {
      PUT(
        cloudCallCenterUrl + 'websocket/update-user-websockets',
        {},
        data,
      ).then((res) => {
        setLoading(false);
        const activeWebsockets = res.data.response.webSockets
        if (activeWebsockets.length > 0) {
          emitEvent(userId, "connectionStatus", { connected: true })
          props.setWebRtcConnectionStatus({ status: true })
        }
        if (activeWebsockets.length === 0) {
          emitEvent(userId, "connectionStatus", { connected: false })
          props.setWebRtcConnectionStatus({ status: false })
          // disconnectWebRtcConnection()
          if (showErrorMessage) {
            showErrorMessage = false;
            toast.error("Disconnected from all the servers", { position: toast.POSITION.BOTTOM_RIGHT });
          }
        }
        return true;
      }).catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
      return true;
    }
  }
  const handleWebSocketDisConnect = (data) => {
    PUT(
      cloudCallCenterUrl + 'websocket/update-user-websockets',
      {},
      data,
    ).then((res) => {
      const activeWebsockets = res.data.response.webSockets
      if (activeWebsockets.length === 0) {
        emitEvent(userId, "connectionStatus", { connected: false })
        props.setWebRtcConnectionStatus({ status: false })
        // disconnectWebRtcConnection()
        if (showErrorMessage) {
          showErrorMessage = false;
          toast.error("Disconnected from all the servers", { position: toast.POSITION.BOTTOM_RIGHT });
        }
        setLoading(false);
      }
      return true;
    }).catch((err) => {
      toast.error(err?.response?.data?.error?.reason);
      setLoading(false);
      return false;
    });
    if (isPersistentCall) {
      const parsedData = JSON.parse(data);
      const postData = {
        initiatorNumber: localStorage.getItem("doosra-biz-user-number"),
        domainName: parsedData.webSocket
      };
      POST(pbxServicesUrl + 'v1/call-center/disconnect', postData)
        .then((res) => {
          destroyWebsocketConnection(parsedData.webSocket);
          return true;
        }).catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          return false;
        });
    }
  }
  const sendSocketConnectionStatus = (socket, type) => {
    if (userRole === "USER") {
      var data = JSON.stringify({
        "userId": localStorage.getItem('doosra-biz-user-id'),
        "organisationId": localStorage.getItem('doosra-biz-organisation-id'),
        "webSocket": socket,
        eventType: type
      });
      if (type === "connected") {
        handleWebSocketConnect(data);
      } else {
        handleWebSocketDisConnect(data);
      }
    }
  }
  const emitEvent = (userId, eventName, message) => {
    var body = {
      user_id: userId,
      event_name: eventName,
      data: message
    }
    return PUT(
      cloudCallCenterUrl + 'campaign/emit-event',
      {},
      body,
    ).then((res) => {
      if (message?.connected && !callingStarted?.current) {
        callingStarted.current = true;
        startCalling();
      }
      return true;
    }).catch((err) => {
      toast.error(err?.response?.data?.error?.reason);
      return false;
    });
  }
  // End the call
  const endCall = () => {
    hangUpActiveCall();
  }
  // Hold the Call
  const HoldCall = () => {
    if (props.webRTCSessions.length > 0) {
      props.webRTCSessions.forEach((WebRTCSession) => {
        WebRTCSession.hold()
      })
    }
  }
  // UnHold the Call
  const UnHoldCall = () => {
    if (props.webRTCSessions.length > 0) {
      props.webRTCSessions.forEach((WebRTCSession) => {
        WebRTCSession.unhold()
      })
    }
  }
  // Mute the Call
  const MuteCall = () => {
    if (props.webRTCSessions.length > 0) {
      props.webRTCSessions.forEach((WebRTCSession) => {
        WebRTCSession.mute()
      })
    }
  }
  // UnMute the Call
  const UnMuteCall = () => {
    if (props.webRTCSessions.length > 0) {
      props.webRTCSessions.forEach((WebRTCSession) => {
        WebRTCSession.unmute()
      })
    }
  }
  const hangUpActiveCall = (showToast = true) => {

    if (connectedCallId) {
      if (isPersistentCall) {
        let endUrl = 'v1/call-center/agent-hangup-single-call';
        let data = {
          callId: connectedCallId
        };
        if (!props.isSingleCall && currentCampaign.campaignType === 'dynamic' && currentCampaign.dailerType === 'predictive') {
          console.log("inside", data, endUrl);
          endUrl = 'v1/call-center/agent-hangup-predictive';
        }
        POST(pbxServicesUrl + endUrl, data)
          .then((res) => {
            setIsCallOnHold(false);
            setIsCallOnMute(false);
            setRunTimer(false);
            setTimer(0);
            setConnectedCallId("");
            props.setDialerCallId({ id: "" });
            setSelectedDisposition("select");
            if (props.isSingleCall) {
              setIsDialing(false);
            }
            return true;
          })
          .catch((err) => {
            if (showToast) {
              toast.error(err?.response?.data?.error?.reason);
            }
            return false;
          });
      } else {
        console.log("Setting the RTCSessions", RTCSessionData);
        setIsCallOnHold(false);
        setIsCallOnMute(false);
        setRunTimer(false);
        setTimer(0);
        setConnectedCallId("");
        props.setDialerCallId({ id: "" });
        setSelectedDisposition("select");
        if (props.isSingleCall) {
          setIsDialing(false);
        }
        if (RTCSessionData) {
          RTCSessionData.terminate();
        }
        props.setWebRtcSessions({ sessions: [] });
        return true;
      }

    }
  }
  const getDomainName = (socketUrl) => {
    const startIndex = socketUrl.indexOf('//') + 2;
    const endIndex = socketUrl.indexOf('/', startIndex);
    const domainName = socketUrl.substring(startIndex, endIndex);
    return domainName;
  }
  const toggleSwitch = (active = false) => {
    if (active && navigator.onLine) {
      console.log("------------[", props?.callType)
      if (props?.callType === "VOIP") {
        if (WebRTCEnabled) {
          createWebsocketConnection();
        } else {
          toast.error("Enable Webrtc to place the calls");
        }
      } else {
        emitEvent(userId, "connectionStatus", { connected: true })
        props.setWebRtcConnectionStatus({ status: true })
      }
    }
    else {
      emitEvent(userId, "connectionStatus", { connected: false });
      hangUpActiveCall(false);
      // disconnectWebRtcConnection();
      props.setWebRtcConnectionStatus({ status: false })
      destroyWebsocketConnection();
    }
  };
  const handleMute = () => {
    setIsCallOnMute(!isCallOnMute)
    if (!isCallOnMute) {
      MuteCall()
    }
    else {
      UnMuteCall()
    }
  }
  const handleHold = () => {
    setIsCallOnHold(!isCallOnHold)
    if (!isCallOnHold) {
      HoldCall()
    }
    else {
      UnHoldCall()
    }
  }
  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  const getRingingWaitingCalls = async () => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: currentCampaign?._id?.toString()
    };
    if (!data.campaignId) {
      console.log('no proper data in ringin waitng');
      return;
    }
    await GET(cloudCallCenterUrl + 'campaign/get-ringing-waiting-details', data)
      .then((res) => {
        if (res?.data?.response) {
          setRingingWaitingCalls(res.data.response);
        }
      }).catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  }

  const handleDisposition = async (e) => {
    console.log(e.target.value);
    setSelectedDisposition(e.target.value);
    addDispositionForConnectedCall(e.target.value);
  }
  const handleAgentStatus = async (e, showToast = true) => {

    if (checkTimeDelayForChangeStatus) {
      toast.error("Please try after 30 seconds.");
      return;
    }

    const agentSelectedStatus = e.target.value;
    if ((selectedAgentStatus === "busy" || selectedAgentStatus === "calling"
      || selectedAgentStatus === "active") && agentSelectedStatus === "active") {
      toast.error("Agent is in active status");
      return;
    }


    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      agentId: localStorage.getItem('doosra-biz-user-id'),
      breakSwitch: "",
      breakReason: e.target.value,
    }
    if (e.target.value === 'active') {
      data.breakSwitch = 'off';
    }
    else {
      data.breakSwitch = 'on';
    }
    if (e.logout) {
      data.logout = e.logout;
    }
    console.log(data);
    await PUT(cloudCallCenterUrl + 'metric/update-agent-break-time', {}, data)
      .then((res) => {
        if (showToast) {
          // toast.success(res?.data?.response?.msg);
        }
        let active = false;

        if (e.target.value === 'active') {
          setIsActive(true);
          props.changeAgentActiveStatus({ status: true });
          active = true;
        }
        else {
          setIsActive(false);
          setCurrentCampaign(null);
          props.changeAgentActiveStatus({ status: false });
        }
        callingStarted.current = false;
        setSelectedAgentStatus(e.target.value);
        toggleSwitch(active);
        if (showToast) {
          setCheckTimeDelayForChangeStatus(true);
          setTimeDelayForChangeStatus(0);
        }
        setLoading(false);

      })
      .catch((err) => {
        if (showToast) {
          toast.error(err?.response?.data?.error?.reason);
        }
        setLoading(false);
        return false;
      });
  }
  const getDispositions = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(
      cloudCallCenterUrl + 'disposition',
      data
    )
      .then((res) => {
        setDispositions(res.data.response.dispositions);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  }

  const refreshAndGetConnectedCallDetails = () => {
    const data = {
      user_id: localStorage.getItem('doosra-biz-user-id'),
    };
    return GET(
      cloudCallCenterUrl + 'campaign/refresh-lead-details',
      data
    )
      .then((res) => {
        if (res?.data?.response?.leadDetails?.length > 0) {
          const resp = res?.data?.response;
          const leadData = res.data.response?.leadDetails;
          if (leadData?.length > 0) {
            setConnectedCallData(resp?.leadDetails[0]);
            setCurrentCampaignName(resp?.campaignName);
            setIsCallConnected(true);
            setSelectedDisposition("select");
            setCampaignId(resp.campaignId);
            props.setDialerCallId({ id: resp?.campaignId });
            setIsDialing(false);
            setRunTimer(true);
            setConnectedCallId(resp?.callId);
          }
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  const DailingUi = () => {
    return (
      <div className='dialing-group'>
        {DialData.map((obj, index) =>
        (<div className={`dialing-line-${index}`}>
          <span className="dialing-line-name">{obj.name}</span>
          <span className="dialing-line-number">{obj.number}</span>
          <span className="dialing-line-status">Dialing</span>
        </div>)
        )}
      </div>
    )
  }
  const addDispositionForConnectedCall = async (callTag) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      callTag,
      callComment: '',
      number: ConnectedCallData?.number,
      secondaryNumber: '',
      campaignId,
    }
    await POST(cloudCallCenterUrl + 'campaign/add-call-tag', data).then((res) => {
      toast.success('Updated Successfully!!!');
    }).catch((err) => {
      toast.error(err?.response?.data?.error?.reason);
    });
  }
  const startCalling = async () => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      agentId: localStorage.getItem('doosra-biz-user-id'),
      campaignId: searchParamCampaignId,
      isPersistentCall
    };
    await POST(cloudCallCenterUrl + 'cloud-call/start-calls-upon-active-status', data).then((res) => {
      setCurrentCampaign(res.data.response?.campaignObj);
      const agentId = localStorage.getItem('doosra-biz-user-id');
      const campaignId = res.data.response?.campaignObj?._id;

      if (res.data.response?.campaignObj?.campaignType === 'dynamic') {
        // toast.success(`The dynamic campaign : ${res.data.response?.campaignObj?.campaignName} has commenced calling !!!`);
        toast.success("Calls Initiated");
        if (res.data.response?.campaignObj?.dailerType === 'predictive') {
          setRunRingingWaitingCallsInfo(true);
        }
        else {
          props.setBatchCallId({ batchCallId: res.data.response?.batchCallId || "" });
          props.setCampaignId({ campaignId });
        }
      } else {
        // toast.success(`Currently, there are no dynamic campaign calls; consider using static options instead!`);
      }
    }).catch((err) => {
      callingStarted.current = false;
      toast.error(err?.response?.data?.error?.reason);
    });
  }
  const connectedLineUi = () => {
    return (
      <div className='connected-group'>
        <div className='connected-line'>
          <span className='connected-line-name'>{ConnectedCallData?.name || "-"}</span>
          <span className='connected-line-number'>{ConnectedCallData?.number || "-"}</span>
          <span className='connected-line-timer'>{formatTime(timer) || "-"}</span>
          <span className='connected-line-status'>connected</span>
        </div>
        <div className='connected-line-info'>
          <div className="email">Email: {ConnectedCallData?.email || "-"}</div>
          <div className="details">
            <div className="amount">
              <span className="label">Due amount</span>
              <span className="value">₹ {ConnectedCallData?.due || "0"}.00</span>
            </div>
            <div className="date">
              <span className="label">Due date</span>
              <span className="value">{ConnectedCallData?.date || "-"}</span>
            </div>
          </div>
          <div className="overdue">campaign : {currentCampaignName || "-"} </div>
        </div>
      </div>
    )
  }

  const ringingWaitingCount = () => {
    return (

      <div className='counts'>
        <div className="amount">
          <span className="label">Ongoing Calls: {ringingWaitingCalls.ringingCount} </span>
        </div>
        <div className="amount">
          <span className="label">Waiting Calls: {ringingWaitingCalls.waitingCount} </span>
        </div>
      </div>
    )
  }

  const connectedLineDispositionJSX = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Select
          className="Rectangle-10578"
          style={{ color: 'white' }}
          value={selectedDisposition}
          onChange={handleDisposition}
        >
          <MenuItem
            value={"select"}
            style={{ boxShadow: 'none', fontSize: '15px' }}
            disabled
          >
            <span
              style={{
                padding: '5px',
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontStretch: 'normal',
                fontStyle: 'normal',
              }}
            >
              Select Disposition
            </span>
          </MenuItem>
          {dispositions.map((option) => (
            <MenuItem
              value={option.dispositon}
              style={{ boxShadow: 'none', fontSize: '15px' }}
            >
              <span
                style={{
                  padding: '5px',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: '600',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                }}
              >
                {option.dispositon}
              </span>
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }
  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    let formattedTime;
    if (hours > 0) {
      formattedTime = `${hours.toString().padStart(2, '0')}: ${minutes.toString().padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    }
    else {
      formattedTime = `${minutes.toString().padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    }
    return formattedTime;
  }
  const buttonGroup = () => {
    return (

      <div className='connected-group'>
        {
          (isCallConnected && props?.callType === 'VOIP') ?
            <>
              {!isCallOnMute ?
                <button className='mute-button'
                  onClick={handleMute}
                >
                  <img src={MuteLogo} alt="mute"></img>
                </button> :
                <button className='mute-button-pressed'
                  onClick={handleMute}
                >
                  <img src={MuteLogo} alt="mute"></img>
                </button>
              }
              {!isCallOnHold ?
                <button className='hold-button'
                  onClick={handleHold}
                >
                  <img src={HoldLogo} alt="hold"></img>
                </button> :
                <button className='hold-button-pressed'
                  onClick={handleHold}
                >
                  <img src={HoldLogo} alt="hold"></img>
                </button>
              }
            </>
            : null
        }
        <button className='call-end-button'
          onClick={endCall}
        >
          <img src={HangupLogo} alt="hangup"></img>
        </button>
      </div>
    )
  }

  const predictiveDialer = () => {
    return (<>
      {isActive ? (!isDialing && !isCallConnected) ? <div className='waiting-info'><h6 className='call-status'>{CallStatus}</h6></div> : null :
        <div className='disconnet-info'>Connect to server to start calling leads</div>
      }
      {
        currentCampaign && runRingingWaitingCallsInfo && ringingWaitingCount()
      }
      {
        isActive && isCallConnected && !isDialing ? connectedLineUi() : null
      }
      {
        props.isSingleCall && isActive && isDialing && !isCallConnected ? DailingUi() : null
      }
      {
        isActive && isCallConnected && !isDialing ? connectedLineDispositionJSX() : null
      }
      {
        isActive && isDialing && !isCallConnected ?
          <div className='waiting-info'><h6 className='call-status'>{CallStatus}</h6></div> : null
      }
      {
        isActive && isCallConnected && !isDialing ? buttonGroup() : null
      }
      {
        isActive && isDialing && !isCallConnected ? buttonGroup() : null
      }
    </>);
  }

  const staticDialer = () => {
    return (<>
      {isActive ? (!isDialing && !isCallConnected) ? <div className='waiting-info'><h6 className='call-status'>{CallStatus}</h6></div> : null :
        <div className='disconnet-info'>Connect to server to start calling leads</div>
      }
      {
        isActive && isCallConnected && !isDialing ? connectedLineUi() : null
      }
      {
        isActive && isDialing && !isCallConnected ? DailingUi() : null
      }
      {
        isActive && (isCallConnected || isDialing) ? connectedLineDispositionJSX() : null
      }
      {
        isActive && isDialing && !isCallConnected ?
          <div className='waiting-info'><h6 className='call-status'>{CallStatus}</h6></div> : null
      }
      {
        isActive && isCallConnected && !isDialing ? buttonGroup() : null
      }
      {
        isActive && isDialing && !isCallConnected ? buttonGroup() : null
      }
    </>);
  }

  const simpleDynamicDialer = () => {
    return (<>
      {isActive ? (!isDialing && !isCallConnected) ? <div className='waiting-info'><h6 className='call-status'>{CallStatus}</h6></div> : null :
        <div className='disconnet-info'>Connect to server to start calling leads</div>
      }
      {
        isActive && isCallConnected && !isDialing ? connectedLineUi() : null
      }
      {
        isActive && isDialing && !isCallConnected ? DailingUi() : null
      }
      {
        isActive && (isCallConnected || isDialing) ? connectedLineDispositionJSX() : null
      }
      {
        isActive && isDialing && !isCallConnected ?
          <div className='waiting-info'><h6 className='call-status'>{CallStatus}</h6></div> : null
      }
      {
        isActive && isCallConnected && !isDialing ? buttonGroup() : null
      }
      {
        isActive && isDialing && !isCallConnected ? buttonGroup() : null
      }
    </>);
  }

  return (
    <div className="panel-container">
      {loading && <PreLoader />}
      <div className={`panel ${isOpen ? 'open' : ''}`}>
        {isActive ? <div className="panel-content-refresh tooltip-container">
          <div class="btn btn-success panel-content-button tooltip-button"
            onClick={refreshAndGetConnectedCallDetails}>
            Fetch Data <RiRefreshFill />
          </div>
          <div className="tooltip-text left" data-tip-position="left">
            Click here to get data
            < span className="arrow-right"></span>
          </div>
        </div> : null}
        <div className="panel-content-logo">
          <img src={AlohaaWhiteLogoNoBackground} alt="Alohaa-White" class="white-logo-no-background" />
        </div>
        <div className='line-break'></div>
        <div>
          <div className="tooltip-container">
            <div className="tooltip-button">
              <Select
                className="Rectangle-10578"
                value={selectedAgentStatus}
                onChange={handleAgentStatus}
                disabled={(isAgentStatusChangePermitted || CallStatus !== "No active calls.") ? false : true}
              >
                {optionschangeAgentActiveStatus.map((option) => (
                  <MenuItem
                    value={option.value}
                    disabled={option.disable}
                    style={{ boxShadow: 'none', fontSize: '15px' }}
                  >
                    <span
                      key={option.value}
                      style={{
                        display: 'flex',
                        color: option.color,
                      }}
                    >
                      &nbsp;&nbsp;&nbsp;
                      <img
                        src={option.image}
                        style={{ fill: option.color }}
                        alt=".."
                      />
                      &nbsp;
                      <span
                        style={{
                          padding: '5px',
                          fontFamily: 'Montserrat',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          fontStretch: 'normal',
                          fontStyle: 'normal',
                        }}
                      >
                        {option.label}
                      </span>
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="tooltip-text left" data-tip-position="left">
              {(isAgentStatusChangePermitted || CallStatus !== "No active calls.") ?
                "Change Status" :
                "Please Select Campaign To Change Status"}
              < span className="arrow-right"></span>
            </div>
          </div>
        </div>
        {/* <span className="panel-content-header">Alohaa Dialer</span>
        <div className={`switch-button ${ isOn ? 'on' : 'off' } `} onClick={toggleSwitch}>
          <div className={`switch-knob ${ isOn ? 'on' : 'off' } `}></div>
        </div> */}
        {
          (currentCampaign?.campaignType === 'dynamic' && currentCampaign?.dailerType === 'predictive')
            ? predictiveDialer() :
            null
        }
        {
          (currentCampaign?.campaignType === 'dynamic' && currentCampaign?.dailerType === 'simple')
            ? simpleDynamicDialer() :
            null
        }
        {
          (currentCampaign?.campaignType === 'static')
            ? staticDialer() :
            null
        }

      </div>
      {
        (isActive && isCallConnected && !isDialing) ?
          <div className="toggle-area-call" onClick={togglePanel}>
            <img src={AlohaaLogoWhite} alt='Alohaa' className='toggle-area-image'></img>
            <span className='timer'>{formatTime(timer)}</span>
          </div>
          :
          <div className="toggle-area" onClick={togglePanel}>
            <img src={AlohaaLogoWhite} alt='Alohaa' className='toggle-area-image'></img>
          </div>
      }
    </div>
  );
};
const mapStateToProps = (state) => ({
  isWebRtcConnected: state.recovery.isWebRtcConnected,
  dialerCallId: state.recovery.dialerCallId,
  isSingleCall: state.recovery.isSingleCall,
  dispositionStatus: state.recovery.dispositionStatus,
  webRTCSessions: state.recovery.webRTCSessions,
  callType: state.recovery.callType
});
export default (connect(mapStateToProps, {
  setWebRtcConnectionStatus,
  setDialerCallId,
  setIsSingleCall,
  changeDispostionStatus,
  changeAgentActiveStatus,
  setWebRtcSessions,
  setCallId,
  setSingleCallStatus,
  setBatchCallId,
  setCampaignId
})(StickyDialer))
import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';
const recoveryCallUrl = process.env.REACT_APP_RECOVERY_CALL_API;

export default function ExportRecoveryLogsPopup(props) {
  const [list, setList] = useState([]);
  const [leadListName, setLeadListName] = useState('');
  const [userName, setUserName] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';
  let columns = [
    { id: 'agentName', displayName: 'AGENT NAME' },
    { id: 'leadName', displayName: 'LEAD NAME' },
    { id: 'destinationNumber', displayName: 'PRIMARY NO' },
    { id: 'email', displayName: 'EMAIL ID' },
    { id: 'initiatorNumber', displayName: 'INITIATOR NUMBER' },
    { id: 'status', displayName: 'CALL STATUS' },
    { id: 'duration', displayName: 'DURATION' },
    { id: 'created_at_date', displayName: 'DATE' },
    { id: 'created_at_time', displayName: 'TIME' },
    { id: 'callTag', displayName: 'Disposition' },
    { id: 'callComment', displayName: 'dispositionComment' },
  ];

  const openPopup = () => {
    setIsPopupOpen(true);
    GET(
      `${recoveryCallUrl}v1/organisations/outbound-call/export`,
      props.leadListId
    )
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        setList(res.exportCallData);
        setLeadListName(res.leadListName);
        setUserName(res.userName);
        setDataFetched(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <button className="Otherbutton" onClick={openPopup}>
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download call logs</p>
            <img
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsPopupOpen(false);
                setDataFetched(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file.
            </p>
            <p className="PopupNote">
              Max download limit - Recent 10,000 entries
            </p>
            {dataFetched ? (
              <CsvDownloader
                filename={`${userName}_${leadListName}_${new Date().toLocaleDateString()}`}
                separator=","
                columns={columns}
                datas={
                  list.length
                    ? list.map((detail) => {
                        console.log(detail);
                        return {
                          agentName: detail.agentName,
                          leadName: detail.name,
                          destinationNumber: detail.destinationNumber,
                          email: detail.email,
                          initiatorNumber: detail.initiatorNumber,
                          status:
                            detail.status === 'not_answered'
                              ? 'Not Answered'
                              : 'Answered',
                          duration: detail.duration,
                          created_at_date: new Date(
                            detail.createdAt
                          ).toLocaleDateString(),
                          created_at_time: new Date(
                            detail.createdAt
                          ).toLocaleTimeString(),
                          callTag: detail.callTag,
                          callComment: detail.callComment,
                        };
                      })
                    : [{}]
                }
                className="ButtonFullWidth BgGreen"
                text="Download CSV"
              />
            ) : (
              <button
                className="ButtonFullWidth BgGrey"
                style={{ cursor: 'default' }}
              >
                SAVE
              </button>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}

import { useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { HorizontalDotsIcon } from '../../../assets/Icons/Icons';
import { POST } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
const PopupContainer = {
  width: '400px',
};
const scrollEffect = {
  overflowY: 'auto',
};

export default function CampaignOptions(props) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const handlePauseCampaign = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const handleResumeCampaign = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditCampaign = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const handleDuplicateCampaign = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteCampaign = async () => {
    try {
      let data = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        campaignId: props.id,
        status: "DELETE",
      };

      POST(
        cloudCallCenterUrl + 'campaigns/handle-campaign-status',
        data
      )
        .then((res) => {
          toast.success("Deleted the campaign successfully");
          setIsOpen(false);
          props.fetchCampaignListHandler();
          return true;

        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          setIsOpen(false);
          return false;
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Popup open={isOpen}>
        <img
          style={{ left: '96%', top: '-5%', zIndex: '1' }}
          alt=""
          className="PopupClose"
          src={Close}
          onClick={() => setIsOpen(false)}
        />
        <div
          className="PopupContainer"
          style={{ ...PopupContainer, ...scrollEffect }}
        >
          <div className="PopupHeaderContainer" style={{ ...PopupContainer }}>
            <p>Campaign Options</p>
          </div>
          <div className="PopupBody" style={{ ...PopupContainer }}>
            {props?.campaignStatus && (
              <button className="ButtonSecondary">Resume</button>
            )}
            {props?.campaignStatus && (
              <button className="ButtonSecondary">Pause</button>
            )}
            <button
              className="ButtonSecondary"
              disabled={props?.status === 'COMPLETED' ? true : false}
              style={props?.status === 'COMPLETED' ? {
                color: "grey",
                border: "solid 1px grey",
                backgroundColor: "lightgrey",
                cursor: 'default'
              } : {}}
              onClick={() => {
                history.push(
                  `/create-campaign?edit=true&id=${props.id}`
                );
              }}
            >
              Edit
            </button>
            <button className="ButtonSecondary"
              onClick={() => {
                history.push(
                  `/create-campaign?duplicate=true&id=${props.id}`
                );
              }}
            >Duplicate</button>
            <button
              className="ButtonCancelFullWidth"
              style={{ marginTop: '16px' }}
              onClick={handleDeleteCampaign}
            >
              Delete
            </button>
          </div>
        </div>
      </Popup>
      <HorizontalDotsIcon onClick={() => setIsOpen(true)} />
    </>
  );
}

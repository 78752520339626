/* eslint-disable */
import adapter from 'webrtc-adapter';
import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import '../../../assets/css/SearchBar.css';
import { commonStyle } from '../../../assets/css/css';
import phoneWhite from '../../../assets/images/phoneWhiteRecoveryCall.svg';
import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import DataTable from '../../../common/component/table/DataTable';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import AddCallRemarksPopup from '../component/AddCallRemarksPopup';
import GetCallHistory from '../component/GetCallHistory';
import AllLeadsCallHistoryPopup from '../component/AllLeadsCallHistoryPopup';
import LeadListSearchBar from '../component/LeadListSearchBar';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import SideModal from "../component/SideModal";
import SingleCallPopup from '../component/SingleCallPopup';
import { useSocket } from "../../../providers/SocketProvider";
import activeStatus from "../../../assets/images/activeStatus.svg";
import tea from "../../../assets/images/tea.svg";
import lunch from "../../../assets/images/lunch.svg";
import meeting from "../../../assets/images/meeting.svg";
import training from "../../../assets/images/training.svg";
import biobreak from "../../../assets/images/biobreak.svg";
import offline from "../../../assets/images/offline.svg";
import away from "../../../assets/images/away.svg";
import phoneGreen from '../../../assets/images/phoneGreen.png';
import { toast } from 'react-toastify';
const moment = require('moment-timezone');

//Importing JsSIP for WebRTC
import JsSIP from 'jssip';
JsSIP.debug.enable('JsSIP:*');
import UpgradeServices from '../../../common/component/upgrade-services/UpgradeServices';
import { DEV_INHOUSE_PBX_SERVERS, PROD_INHOUSE_PBX_SERVERS, TURN_SERVER_CONFIG } from '../../../assets/constants';

import {
  setCallDetails,
  setBatchCallId,
  setCallId,
  setCallStatus,
  toggleAutoCalling,
  setAutoCalling,
  setSingleCallStatus,
  setDestinationName,
  setDestinationNumber,
  setRTCSession,
  setCallFilterLeads,
  setRTCCallStatus,
  setHeaders,
} from "../../../actions/recoveryXActions"
import WebRTCCallPopup from "../component/WebRTCCallPopup";
import { isSuperAdminWithPermission } from '../../../utility/utility';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
const webRTCOutGoingUrl = process.env.REACT_APP_WEBRTC_RECOVERY_API_URL;
const INHOUSE_PBX_SERVERS = process.env.REACT_APP_ENV === "DEV" ? DEV_INHOUSE_PBX_SERVERS : PROD_INHOUSE_PBX_SERVERS;
export function RecoveryCallingPage(props) {
  const csvLink = useRef(null);
  let socket = useSocket();
  let displayData;
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [leadListId, setLeadListId] = useState('');
  const [leadListContacts, setLeadListContacts] = useState([]);
  const [exportBy, setExportBy] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [leadLists, setLeadLists] = useState([]);
  const [dispositions, setDispositions] = useState([]);
  const [reset, setReset] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [callCommentNumber, setCallCommentNumber] = useState("");
  const [showSideModal, setShowSideModal] = useState(true);
  const [isCallDetailsUpdated, setIsCallDetailsUpdated] = useState(false);

  /* AGENT ACTIVE STATUS */
  const optionsAgentActiveStatus = [
    { value: 'active', label: 'Active', image: activeStatus, color: '#4daa3e' },
    { value: 'tea', label: 'Tea', image: tea, color: '#196cca' },
    { value: 'lunch', label: 'Lunch', image: lunch, color: '#196cca' },
    { value: 'meeting', label: 'Meeting', image: meeting, color: '#196cca' },
    { value: 'training', label: 'Training', image: training, color: '#196cca' },
    { value: 'biobreak', label: 'Biobreak', image: biobreak, color: '#196cca' },
    //{ value: 'away', label: 'Away', image: away, color: '#f8bb44' },
    { value: 'offline', label: 'Offline', image: offline, color: '#939598' },
  ];
  const [selectedAgentStatus, setSelectedAgentStatus] = useState("");
  const [agentMetric, setAgentMetric] = useState(null);

  /* START OF WEBRTC CALLS RELATED CODE */

  const [WebRTCEnabled, SetWebRTCEnabled] = useState(JSON.parse(localStorage.getItem("is_webrtc_enabled")));
  const [UAList, setUAList] = useState([]);
  const [UA, setUA] = useState(null);
  // const [RTCcallStatus, setRTCCallStatus] = useState("Connecting to Call server");
  const [incomingCall, setIncommingCall] = useState(false);
  const [isWebSocketConnected, setIsWebSocketConnected] = useState(false);
  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(false);

  var audio = new Audio();
  const sip_details = JSON.parse(localStorage.getItem("sip_details"));
  const username = sip_details?.username;
  const password = sip_details?.password;
  const sip_user_id = sip_details?.id;

  const [resumeAutoCalling, setResumeAutoCalling] = useState(true);
  const statusMapper = {
    'answered': 'Answered',
    'not_answered': "Not Answered"
  };

  // Register a user agent to Asterisk server. UseEffet makes the code run only once.
  useEffect(() => {
    if (WebRTCEnabled && navigator.onLine) {
      createWebsocketConnection();
    }

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      stopUA();
    };
  }, [WebRTCEnabled])


  const sendSocketConnectionStatus = (socket, type) => {
    if(userRole === "USER"){
      var data = JSON.stringify({
        "userId": localStorage.getItem('doosra-biz-user-id'),
        "organisationId": localStorage.getItem('doosra-biz-organisation-id'),
        "webSocket": socket,
        eventType: type
      });
      return PUT(
        outGoingUrl + 'v1/organisations/outbound-call/update-user-websockets',
        {},
        data,
      ).then((res) => {
        const activeWebsockets = res.data.response.webSockets
        if(activeWebsockets.length > 0){
          setIsWebSocketConnected(true);
        }
        if(activeWebsockets.length === 0) {
          setIsWebSocketConnected(false);
          toast.error("Not connected to server. Please check internet connection and refresh to continue")
        }
        return true;
      }).catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      }); 
    }
  }


  const checkCallTimings = () => {
    const recoveryCallsStartTime = localStorage.getItem(
      'recovery_calls_start_time',
    );
    const recoveryCallsEndTime = localStorage.getItem(
      'recovery_calls_end_time',
    );
    const [startTimeHour, startTimeMinutes] = recoveryCallsStartTime.split(":");
    const [endTimeHour, endTimeMinutes] = recoveryCallsEndTime.split(":");

    const currentTime = moment().tz("Asia/Kolkata");
    const startTime = moment().tz("Asia/Kolkata").hour(startTimeHour).minute(startTimeMinutes);
    const endTime = moment().tz("Asia/Kolkata").hour(endTimeHour).minute(endTimeMinutes);

    const isInBetween = currentTime.isBetween(startTime, endTime);
    return isInBetween
  }
  const createWebsocketConnection = () => {
    console.log("INHOUSE_PBX_SERVERS - ", INHOUSE_PBX_SERVERS);
    const uaList = INHOUSE_PBX_SERVERS.map((server) => {
      const socket = new JsSIP.WebSocketInterface(`wss://${server}/ws`);
      const uaConfig = {
        uri: `sip:${username}@${server}`,
        password: password,
        sockets: [socket],
        connection_recovery_min_interval: 2
      };
      const ua = new JsSIP.UA(uaConfig);
      ua.start();
      return { pbx: server, ua: ua, maxReconnects: 5 };
    });
    setUAList(uaList);
    return uaList
  }

  const getDomainName = (socketUrl) => {
    const startIndex = socketUrl.indexOf('//') + 2;
    const endIndex = socketUrl.indexOf('/', startIndex);
    const domainName = socketUrl.substring(startIndex, endIndex);
    return domainName;
  }

  const stopUA = () => {
    UAList.forEach(({ ua }) => {
      ua.stop();
    })
  }

  const handleOffline = () => {
    toast.error("Network disconnected. Please check internet connection")
    setIsWebSocketConnected(false);
  }

  const handleOnline = () => {
    toast.success("Network is up. Please refresh to reconnect to server")
  }

  // Reload  conformation 
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    if (WebRTCEnabled) {
      props.setBatchCallId("");
      props.setSingleCallStatus(false);
      props.setAutoCalling(false);
      props.setDestinationName("");
      props.setDestinationNumber("")
      props.setCallId("");
      props.setRTCSession({ RTCSession: null });
      props.setRTCCallStatus({ RTCcallStatus: "" });
    }
  };

  // const options = {
  //   mediaConstraints: { audio: true, video: false },
  //   pcConfig: {
  //     iceServers : [
  //       {
  //         urls: ["stun:stun.l.google.com:19302"]
  //       },
  //       TURN_SERVER_CONFIG
  //     ]
  //   }
  // };

  let options = {
    mediaConstraints: { audio: true, video: false },
    pcConfig: {}
  };

  const initiateWebRTCRecoveryCall = (number, name) => {
    const isValidTimeToMakeCall = checkCallTimings()
    if(isValidTimeToMakeCall === false) {
      toast.error("Cannot place calls outside of the timings");
      return;
    }
    if (!isWebSocketConnected) {
      toast.error("Not connected to server");
      return;
    }
    if (!isAuthenticatedUser) {
      toast.error("User Registration Failed. Please Refresh the page");
      return;
    }
    if (callId || singleCallStatus || autoCalling) {
      toast.error("There is an Active call");
      return;
    }
    if (localStorage.getItem("doosra-biz-user-number") === number) {
      toast.error("Cannot Call To The Same Number");
      return;
    }
    setSingleCallStatus(true);
    localStorage.setItem("singleCallStatus", true);
    props.setSingleCallStatus({ singleCallStatus: true })
    let data = {
      number,
      name,
      leadListId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      isRecoveryCall: true,
    };
    POST(webRTCOutGoingUrl + 'v1/organisations/outbound-call/recovery', data)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  }

  const initiateWebRTCBatchRecoveryCall = (filter = {}) => {
    const isValidTimeToMakeCall = checkCallTimings()
    if(isValidTimeToMakeCall === false) {
      toast.error("Cannot place calls outside of the timings");
      return;
    }
    if (!isWebSocketConnected) {
      toast.error("Not connected to server");
      return;
    }
    if (!isAuthenticatedUser) {
      toast.error("User Registration Failed. Please Refresh the page");
      return;
    }
    if (singleCallStatus || callId) {
      toast.error("There is an active call");
      return;
    }
    if (batchCallId) {
      toast.error("There is an active batch call");
      return;
    }
    const data = {};
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.leadListId = leadListId;
    if (filter.isFilterSelected && filter.filteredLeads) {
      data.isFilterSelected = true;
      data.selectedDispositions = filter.filteredLeads
    }
    return POST(
      webRTCOutGoingUrl + "v1/organisations/outbound-call/batch-call-init",
      data
    ).then((res) => {
      props.setBatchCallId({ batchCallId: res.data.response });
      props.setAutoCalling({ autoCalling: true });
      return true;
    }).catch((err) => {

      toast.error(err?.response?.data?.error?.reason);
      return false;
    });
  }
  const filterAndInitiatebatchCallHandler = (filteredLeads) => {
    const data = {
      isFilterSelected: true,
      filteredLeads
    }
    if (WebRTCEnabled) {
      initiateWebRTCBatchRecoveryCall(data)
    } else {
      batchCallInit(data)
    }

  }

  useEffect(() => {
    UAList.forEach(({ ua: UA, maxReconnects }) => {
      UA.on('disconnected', (e) => {
        UA.unregister({all:true});
        const disconnectedSocket = e.socket._url
        const domainName = getDomainName(disconnectedSocket);
        sendSocketConnectionStatus(domainName, "disconnected")
      })
      UA.on('connected', (e) => {
        const connectedSocket = e.socket.socket._url
        const domainName = getDomainName(connectedSocket);
        setTimeout(()=> {
          sendSocketConnectionStatus(domainName, "connected")
        },1000);
      })
      UA.on('registered', (e) => {
        console.log("user registered successfully")
        setIsAuthenticatedUser(true);
      })
      UA.on('registrationFailed', (e) => {
        console.log("user registration failed")
        setIsAuthenticatedUser(false);
      })
      UA.on('unregistered', ()=> {
        console.log("user unregistration event")
      })
      // Incoming call Event Auto Answer
      UA.on('newRTCSession', (e) => {
        const newSession = e.session;

        /* START ICE CONFIG */
        let iceServers = [
          {
            urls: ["stun:stun.l.google.com:19302"]
          }
        ]
        const pbx = newSession._ua._configuration.hostport_params
        iceServers.push(TURN_SERVER_CONFIG[pbx])
        options.pcConfig["iceServers"] = iceServers
        newSession.on("icecandidate", (candidate) => {
          console.log("iceCandidate gathered - ", candidate);
        })
        /* END ICE CONFIG */
        
        props.setCallId({ callId: e.request.headers.Uniqueid[0].raw });
        if (newSession.direction === 'incoming' && newSession.status === 4) {
          props.setRTCSession({ RTCSession: newSession });
          props.setRTCCallStatus({ RTCcallStatus: "connecting" });
          props.setSingleCallStatus({ singleCallStatus: true })
          setIncommingCall(true);
          newSession.on("accepted", function (event) {
            console.log(" Call Accepted ")
          });

          newSession.on("confirmed", function (event) {
            if (event.originator === "remote") {
            }
            props.setRTCCallStatus({ RTCcallStatus: "Ringing" });
          });

          // Call failed event
          newSession.on('failed', (event) => {
            console.log(" Call failed ");
            resetValues();
          });

          // Call hungup event
          newSession.on('ended', (event) => {
            console.log(" Call hangup ");
            resetValues();
          });

          // Answer Call
          if (newSession.status === 4) {
            newSession.answer(options);
            console.log("iceConnectionState - ", newSession);
          }

          // Audio connection  
          newSession.connection.addEventListener('addstream', (e) => {
            audio.srcObject = e.stream;
            audio.play();
          });
        }
      });
    });
  }, [UAList]);
  // Reseting values after call ends or fails
  const resetValues = () => {
    props.setRTCCallStatus({ RTCcallStatus: "Call Connecting" })
    props.setSingleCallStatus({ singleCallStatus: false });
    localStorage.setItem("singleCallStatus", false);
    props.setRTCSession({ RTCSession: null });
    props.setDestinationNumber({ destinationNumber: "" });
    props.setDestinationName({ destinationName: "" });
    setIncommingCall(false);
    audio.remove();
    props.setCallId({ callId: "" })
  }

  // Mute the call
  const muteCall = () => {
    if (!RTCSession) {
      toast.error("No active calls");
      return;
    }
    RTCSession.mute();
  }

  // UnMute the call
  const unMuteCall = () => {
    if (!RTCSession) {
      toast.error("No active calls");
      return;
    }
    RTCSession.unmute();
  }

  // Hold the call
  const holdCall = () => {
    if (!RTCSession) {
      toast.error("No active calls");
      return;
    }
    RTCSession.hold();
    props.setRTCCallStatus({ RTCcallStatus: 'call-on-hold' });
  };

  // Resume the call
  const resumeCall = () => {
    if (!RTCSession) {
      toast.error("No active calls");
      return;
    }
    RTCSession.unhold();
    props.setRTCCallStatus({ RTCcallStatus: 'connected' });
  };

  // End the call
  const endCall = () => {
    if (!RTCSession) {
      // toast.error("No active calls");
      resetValues();
      return;
    }
    RTCSession.terminate();
    resetValues();

  }

  /* END OF WEBRTC CALLS RELATED CODE */

  let {
    userDetails,
    callStatus,
    position,
    autoCalling,
    batchCallId,
    callId,
    singleCallStatus,
    callFilterLeads,
  } = props;
  let destinationName = useSelector((state) => state.recovery.destinationName)
  let destinationNumber = useSelector(state => state.recovery.destinationNumber)
  let RTCSession = useSelector(state => state.recovery.RTCSession)
  let RTCcallStatus = useSelector(state => state.recovery.RTCcallStatus);
  let headers = useSelector(state => state.recovery.headers);
  const tempFunction = (destinationNumber, status, duration) => {

    const tempLeadsData = callFilterLeads.map(lead => {
      if (lead.number === destinationNumber || lead.secondaryNumber === destinationNumber) {
        lead.status = status ? status : "-",
          lead.duration = duration ? duration : "-",
          lead.callTag = "-"
      }
      return lead;
    })
    setLeadListContacts(tempLeadsData)
  }

  useEffect(() => {
    if (socket) {
      socket.on("test", (message) => {
        console.log("RECEIVED THE TEST MESSAGE", message)
        props.setCallDetails(message)
        props.setDestinationName({ destinationName: message.userDetails });
        props.setDestinationNumber({ destinationNumber: message.position });
      });
      socket.on("changeStatus", (message) => {
        props.setCallStatus({ status: message });
        if (WebRTCEnabled) {
          if (message === "Connected") {
            props.setRTCCallStatus({ RTCcallStatus: "Connected" })
          } else { props.setRTCCallStatus({ RTCcallStatus: "Automated calling paused for 30 sec" }) }
        }
      });
      socket.on("setBatchCallId", (message) => {
        props.setBatchCallId(message);
      });
      socket.on("toggleButton", () => {
        props.toggleAutoCalling({ autoCalling: false })
      });
      socket.on("callId", (message) => {
        props.setCallId({ callId: message });
        // props.setRTCCallStatus({RTCcallStatus:""})
        // props.setCallStatus({status: ""});
        // props.setCallDetails("")
        // props.setDestinationName({destinationName: ""});
        // props.setDestinationNumber({destinationNumber: ""});
      })
      socket.on("changeSingleCallStatus", (message) => {
        props.setSingleCallStatus({ singleCallStatus: false });
      })

      socket.on("changeSingleCallDetails", (message) => {
        // let isUpdated = isCallDetailsUpdated;
        // if(!isUpdated) {
        //   setIsCallDetailsUpdated(true);
        const { destinationNumber, status, duration } = message;
        //   const tempLeadsData = leadListContacts.map( lead => { if(lead.number === destinationNumber) {
        //     lead.status = status ? status : "-",
        //     lead.duration = duration ? duration : "-"}
        // return lead;})
        // setLeadListContacts(tempLeadsData);
        // }
        tempFunction(destinationNumber, status, duration)
      })
    }
  }, [socket, callFilterLeads])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  const hangupCall = () => {
    if (!callId) {
      toast.error("No active connected calls to hangup");
      return;
    }
    const data = { callId }
    return POST(
      outGoingUrl + "v1/organisations/outbound-call/hangup-call",
      data
    ).then((res) => {
      toast.success("Disconnected");
      return true;
    }).catch((err) => {
      toast.error(err?.response?.data?.error?.reason);
      return false;
    });
  }
  const batchCallInit = (filter = {}) => {
    const isValidTimeToMakeCall = checkCallTimings();
    if(isValidTimeToMakeCall === false) {
      toast.error("Cannot place calls outside of the timings");
      return;
    }
    if (singleCallStatus || callId) {
      toast.error("There is an active call");
      return;
    }
    if (batchCallId) {
      toast.error("There is an active batch call");
      return;
    }
    const data = {};
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.leadListId = leadListId;
    if (filter.isFilterSelected && filter.filteredLeads) {
      data.isFilterSelected = true;
      data.selectedDispositions = filter.filteredLeads
    }
    return POST(
      outGoingUrl + "v1/organisations/outbound-call/batch-call-init",
      data
    ).then((res) => {
      props.setBatchCallId({ batchCallId: res.data.response });
      props.setAutoCalling({ autoCalling: true });
      return true;
    }).catch((err) => {

      toast.error(err?.response?.data?.error?.reason);
      return false;
    });

  }

  const resumeAutoCallingTimeHandler = () => {
    setTimeout(() => {
      setResumeAutoCalling(true);
    }, 40000);
  };

  const batchCallHadler = () => {
    if (singleCallStatus === true && !WebRTCEnabled) {
      toast.error("There is an active single call");
      return;
    }
    const newValue = !autoCalling
    props.setAutoCalling({ autoCalling: newValue })
    if (newValue === true && !resumeAutoCalling) {
      toast.error("Please wait for 30 seconds before resuming the auto calling");
      props.setAutoCalling({ autoCalling: !newValue });
      return;
    }
    if (newValue === true && resumeAutoCalling) {
      var data = JSON.stringify({
        "batchCallId": batchCallId,
        "organisationId": localStorage.getItem('doosra-biz-organisation-id'),
      });
      return POST(
        outGoingUrl + 'v1/organisations/outbound-call/batch-call-resume',
        data,
      ).then((res) => {
        const id = res.data.leadList._id;
        setLoading(false);
        return true;
      }).catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
    } else {
      var data = JSON.stringify({
        "batchCallId": batchCallId,
        "organisationId": localStorage.getItem('doosra-biz-organisation-id'),
        "pauseAutoCalling": true,
      });
      setResumeAutoCalling(false);
      resumeAutoCallingTimeHandler();
      return POST(
        outGoingUrl + 'v1/organisations/outbound-call/batch-call-resume',
        data,
      ).then((res) => {
        const id = res.data.leadList._id;
        setLoading(false);
        return true;
      }).catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
    }
  }
  const addLeadListHandler = (data) => {
    if (!data.leadListName) {
      toast.error("lead list name is required");
      return;
    }
    if (!data.userId) {
      toast.error("Please select a user from dropdown");
      return;
    }
    if (!data.leadList) {
      toast.error("lead list file is required");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('leadList', data.leadList);
    formData.append('leadListName', data.leadListName);
    formData.append('organisationId', data.organisationId);
    formData.append('userId', data.userId);

    return POST(
      outGoingUrl + 'v1/organisations/outbound-call/upload-lead-list',
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    )
      .then((res) => {
        const id = res.data.leadList._id;
        getLeadLists()
        fetchLeadListContactsHandler({ leadListId: id });
        setLoading(false);
        toast.success(res.data.status);
        if (res.data.filteredNumbers.length > 0) {
          const filteredNumbers = res.data.filteredNumbers;
          let csvstring = 'name,number,secondaryNumber,reason\n';
          for (let i = 0; i < filteredNumbers.length; i += 1) {
            const tempString = `${filteredNumbers[i].name},${filteredNumbers[i].number},${filteredNumbers[i].secondaryNumber},${filteredNumbers[i].reason}`
            csvstring = `${csvstring + tempString}\n`;
          }
          const blob = new Blob([csvstring], { type: 'text/csv' });
          const URL = window.URL || window.webkitURL;
          const downloadFileUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = downloadFileUrl;
          a.download = 'un_uploaded_leads.csv';
          a.style = 'display:none';
          document.body.appendChild(a);
          a.click();
          a.remove();
          return true;
        }
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const addBulkLeadListHandler = (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('leadList', data.leadList);
    formData.append('leadListName', data.leadListName);
    formData.append('organisationId', data.organisationId);

    return POST(
      outGoingUrl + 'v1/organisations/outbound-call/bulk-upload-lead-list',
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    )
      .then((res) => {
        const id = res?.data?.leadListId?._id;
        if (id) {
          getLeadLists()
          fetchLeadListContactsHandler({ leadListId: id });
        }
        setLoading(false);
        toast.success(res.data.status);
        if (res.data.filteredNumbers.length > 0) {
          const filteredNumbers = res.data.filteredNumbers;
          let csvstring = 'name,number,secondaryNumber,agent,reason\n';
          for (let i = 0; i < filteredNumbers.length; i += 1) {
            const tempString = `${filteredNumbers[i].name},${filteredNumbers[i].number},${filteredNumbers[i].secondaryNumber},${filteredNumbers[i].agent},${filteredNumbers[i].reason}`
            csvstring = `${csvstring + tempString}\n`;
          }
          const blob = new Blob([csvstring], { type: 'text/csv' });
          const URL = window.URL || window.webkitURL;
          const downloadFileUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = downloadFileUrl;
          a.download = 'un_uploaded_leads.csv';
          a.style = 'display:none';
          document.body.appendChild(a);
          a.click();
          a.remove();
          return true;
        }
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const callTagHandler = (data) => {
    data.leadListId = leadListId;
    return POST(outGoingUrl + 'v1/organisations/outbound-call/add-call-tag', data).then((res) => {
      setLoading(false);
      toggleModal();
      toast.success('Added Comment Successfully');
      return true;
    }).catch((err) => {
      setLoading(false);
      toast.error(err?.response?.data?.error?.reason);
      return false;
    });

  }
  const getLeadListsHandler = (event) => {
    event.preventDefault();
    setLeadListId(event.target.value);
    const data = { leadListId: event.target.value };
    fetchLeadListContactsHandler(data);
  };
  useEffect(() => {
    getLeadLists();
    getDispositions();
    getAgentMetric();
  }, []);
  // useEffect(() => {
  //    setUserDetails(localStorage.getItem("userDetails")); 
  //     setCallStatus(localStorage.getItem("status")); 
  //     setPosition(localStorage.getItem("position")); 
  //     setBatchCallId(localStorage.getItem("batchCallId"));
  //     setCallId(localStorage.getItem("callId"));
  //     if (localStorage.getItem("autoCalling") === null) {
  //       setAutoCalling(localStorage.getItem("autoCalling") || false);
  //     } else {
  //       setAutoCalling(localStorage.getItem("autoCalling") === "false" ? false : true);
  //     }
  //     setSingleCallStatus(localStorage.getItem("singleCallStatus") === "true" ? true : false);
  // }, []);
  const getLeadLists = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(
      outGoingUrl + 'v1/organisations/outbound-call/get-lead-lists',
      data
    )
      .then((res) => {
        setLeadLists(res.data.leadLists);
        setCount(res.data.count);
        setLeadListId(res.data.leadLists[res.data.leadLists.length - 1]._id);
        fetchLeadListContactsHandler({ leadListId: res.data.leadLists[res.data.leadLists.length - 1]._id })
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };
  const getDispositions = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(
      outGoingUrl + 'v1/organisations/outbound-call/get-dispositions',
      data
    )
      .then((res) => {
        setDispositions(res.data.response.dispositions)
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  }

  const getLeadsCount = (count) => {
    setCount(count);
  };
  const resetFetchLeadListContactsHandler = (data) => {
    setSearchKey("");
    setSearchValue("");
    setReset(true);
    data.reset = true;
    fetchLeadListContactsHandler(data);
  }
  const initiateRecoveryCall = (destinationNumber, name, isLeadListIndependentCall = false) => {
    const isValidTimeToMakeCall = checkCallTimings()
    if(isValidTimeToMakeCall === false) {
      toast.error("Cannot place calls outside of the timings");
      return;
    }
    if (callId || singleCallStatus || autoCalling) {
      toast.error("There is an Active call");
      return;
    }
    if (localStorage.getItem("doosra-biz-user-number") === destinationNumber) {
      toast.error("Cannot Call To The Same Number");
      return;
    }
    setSingleCallStatus(true);
    localStorage.setItem("singleCallStatus", true);
    props.setSingleCallStatus({ singleCallStatus: true })
    let data = {
      number: destinationNumber,
      name,
      leadListId: (isLeadListIndependentCall ? "" : leadListId),
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      isRecoveryCall: true,
    };
    POST(outGoingUrl + 'v1/organisations/outbound-call/recovery', data)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const getContactCallLogs = (destinationNumber) => { };

  const updateCallDispositonTag = (data) => {
    console.log(data)
    const tempLeadsData = callFilterLeads.map(lead => {
      if (lead.number === data.number || (lead.secondaryNumber !== "" && lead.secondaryNumber === data.secondaryNumber)) {
        lead.callTag = data.callTag
      }
      return lead;
    })
    setLeadListContacts(tempLeadsData)
  }

  const deleteLeadListContact = (destinationNumber) => {
    setLoading(true);
    let data = {
      number: destinationNumber,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      leadListId,
    };

    PUT(
      outGoingUrl + 'v1/organisations/outbound-call/remove-lead-list-contact',
      data
    )
      .then((res) => {
        setLoading(false);
        fetchLeadListContactsHandler({ leadListId });
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const deleteLeadList = (leadListId) => {
    setLoading(true);
    let data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      leadListDocumentId: leadListId,
    };

    DELETE(
      outGoingUrl + 'v1/organisations/outbound-call/delete-lead-list',
      data
    )
      .then((res) => {
        setLoading(false);
        getLeadLists();
        toast.success("Deleted the list successfully");
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };
  const toggleLeadListVisibility = (leadListId, isHidden) => {
    setLoading(true);
    let data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      leadListId: leadListId,
      isHidden,
    };

    POST(
      outGoingUrl + 'v1/organisations/outbound-call/toggle-leadlist-visibility',
      data
    )
      .then((res) => {
        setLoading(false);
        getLeadLists();
        // toast.success("Toggled the view successfully");
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  }
  const fetchLeadListContactsHandler = (data) => {
    setLoading(true);
    if (data.searchKey && data.searchValue) { setSearchKey(data.searchKey); setSearchValue(data.searchValue) }
    if (!data?.reset && (searchKey && searchValue)) { data.searchKey = searchKey; data.searchValue = searchValue; }
    if (leadListId === '') { setLeadListId(data.leadListId); }
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    setExportBy(data);
    if (!data.pageNo) data.pageNo = 1;
    setCurrentPage(data.pageNo);
    if (!data.pageSize) data.pageSize = 15;
    data.pageNo = data.pageNo - 1;
    if (data.leadListId === "all") {
      GET(outGoingUrl + 'v1/organisations/outbound-call/search-leads', data)
        .then((res) => {
          return res.data.response;
        })
        .then((res) => {
          setLeadListContacts([]);
          setLeadListContacts(res.leads);
          props.setHeaders({ headers: ["name","number","secondaryNumber"] })
          setTotalPages(res.totalPages);
          setCount(res.count);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
      return;
    }
    GET(outGoingUrl + 'v1/organisations/outbound-call/get-lead-list', data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        setLeadListContacts([]);
        setLeadListContacts(res.leads);
        props.setCallFilterLeads({ callFilterLeads: res.leads })
        props.setHeaders({ headers: res.headerKeys })
        setTotalPages(res.totalPages);
        setCount(res.count);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const addLeadHandler = (data) => {
    if (leadListId === "all") {
      toast.error("Cannot add lead when all leads are selected");
      return;
    }
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.leadListId = leadListId;
    return POST(
      outGoingUrl + "v1/organisations/outbound-call/add-contact",
      data
    ).then((res) => {
      setLeadListContacts(res.data.response.leads);
      props.setCallFilterLeads({ callFilterLeads: res.data.response.leads });
      toast.success("Added Lead Successfully");
      return true;
    }).catch((err) => {
      toast.error(err?.response?.data?.error?.reason);
      return false;
    });
  }

  const stopBatchCall = () => {
    if (WebRTCEnabled && RTCSession) {
      if (RTCSession.terminate) {
        RTCSession.terminate();
      }
      resetValues()
    }
    if (!batchCallId) {
      props.setSingleCallStatus({ singleCallStatus: false });
      props.setCallId({ callId: '' })
      return;
    }
    props.setAutoCalling({ autoCalling: false });
    props.setBatchCallId({ batchCallId: "" });
    props.setSingleCallStatus({ singleCallStatus: false });
    props.setCallId({ callId: '' });
    var data = JSON.stringify({
      "batchCallId": batchCallId,
      "organisationId": localStorage.getItem('doosra-biz-organisation-id'),
      "pauseAutoCalling": true,
    });
    return POST(
      outGoingUrl + 'v1/organisations/outbound-call/batch-call-resume',
      data,
    ).then((res) => {
      const id = res.data.leadList._id;
      setLoading(false);
      return true;
    }).catch((err) => {
      setLoading(false);
      toast.error(err?.response?.data?.error?.reason);
      return false;
    });
  }
  if (loading) displayData = <PreLoader />;
  if (leadListContacts.length > 0) {
    displayData = leadListContacts.map((row) => (
      <DataTable.Row key={row.number}>
        {/* <DataTable.Cell>{row.name ||"-"}</DataTable.Cell>
        <DataTable.Cell>
          {!row.number || row.number === 'NA' ? '-' : row.number}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.secondaryNumber || row.secondaryNumber === 'NA' ? '-' : row.secondaryNumber}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.email || row.email === 'NA' ? '-' : row.email}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.due || row.due === 'NA' ? '-' : row.due}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.date || row.date === 'NA' ? '-' : row.date}
          </DataTable.Cell> */}

        {headers && headers.length > 0 && headers.map((header) => {
          return (
            <DataTable.Cell>
              {!row[header] || row[header] === 'NA' ? '-' : row[header]}
            </DataTable.Cell>
          )
        })}
        {leadListId !== "all" ?
          <>
            <DataTable.Cell>
              {!row.status || row.status === 'NA' ? '-' : statusMapper[row.status]}
            </DataTable.Cell>
            <DataTable.Cell>
              {!row.duration || row.duration === 'NA' ? '-' : row.duration}
            </DataTable.Cell>
            <DataTable.Cell>{row.callDate !== "" ? new Date(
              row.callDate
            ).toLocaleDateString() : "-"}</DataTable.Cell>
            <DataTable.Cell>
              {!row.callTag || row.callTag === 'NA' ? '-' : row.callTag}
            </DataTable.Cell>
            <DataTable.Cell>
              <GetCallHistory destinationNumber={row.number} secondaryNumber={row.secondaryNumber} leadListId={leadListId}></GetCallHistory>
            </DataTable.Cell>
            <DataTable.Cell>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '64px',
                  justifyContent: "end",
                }}
              >
                <>
                  {userRole === 'USER' ? (!row.secondaryNumber ?
                    (<button
                      className="ActivateButton"
                      disabled={selectedAgentStatus !== 'active' && agentMetric}
                      onClick={() => { WebRTCEnabled ? initiateWebRTCRecoveryCall(row.number, row.name) : initiateRecoveryCall(row.number, row.name) }}
                      style={{
                        width: "32px", height: "32px",
                        backgroundColor: (selectedAgentStatus !== 'active' && agentMetric ? "lightgrey" : "#07aa3f"),
                      }}
                    >
                      <img
                        alt=""
                        src={phoneWhite}
                        className="SidebarItemIcon"
                        style={{ width: "16px", height: "16px" }}
                      />
                    </button>) :
                    <SingleCallPopup
                      selectedAgentStatus={selectedAgentStatus}
                      agentMetric={agentMetric}
                      primaryNumber={row.number}
                      secondaryNumber={row.secondaryNumber}
                      WebRTCEnabled={WebRTCEnabled}
                      initiateRecoveryCall={initiateRecoveryCall}
                      initiateWebRTCRecoveryCall={initiateWebRTCRecoveryCall}
                      name={row.name}
                    />
                  ) : (
                    (!row.status || row.status === 'NA') && leadListContacts.length > 1 && !isSuperAdminWithPermission('DELETE') ? (
                      <button style={{ background: "none", border: "none" }}>
                        <img
                          onClick={() => deleteLeadListContact(row.number)}
                          src={Delete}

                        >
                        </img>
                      </button>
                    ) : (
                      <button style={{ background: "none", border: "none" }}>
                        <img
                          style={{ opacity: 0.5 }}
                          src={Delete}

                        >
                        </img>
                      </button>)
                  )}
                  {userRole === "USER" ? <AddCallRemarksPopup open={isModalOpen} toggle={toggleModal} number={row.number} secondaryNumber={row.secondaryNumber} dispositions={dispositions} updateCallDispositonTag={updateCallDispositonTag} leadListId={leadListId} isDisabled={(!row.status || row.status === 'NA')} ></AddCallRemarksPopup> : null}
                </>

              </div>
            </DataTable.Cell> </> : 
            <>
            <DataTable.Cell>
              {!row.agentName || row.agentName === 'NA' ? '-' : row.agentName}
            </DataTable.Cell>
            <DataTable.Cell>
            {!row.status || row.status === 'NA' ? '-' :  statusMapper[row.status]}
            </DataTable.Cell>
            <DataTable.Cell>
            {!row.duration || row.duration === 'NA' ? '-' : row.duration}
            </DataTable.Cell>
            <DataTable.Cell>
            {!row.callTag || row.callTag === 'NA' ? '-' : row.callTag}
            </DataTable.Cell>
            <DataTable.Cell>{row.callDate !== "" ? new Date(
              row.callDate
            ).toLocaleDateString() : "-"}</DataTable.Cell>
            <DataTable.Cell>{row.callDate !== "" ? new Date(
              row.callDate
            ).toLocaleTimeString() : "-"}</DataTable.Cell>
            <DataTable.Cell>
            <AllLeadsCallHistoryPopup destinationNumber={row.number} secondaryNumber={row.secondaryNumber} leadListId={leadListId}></AllLeadsCallHistoryPopup>
            </DataTable.Cell>
          </>}
      </DataTable.Row>
    ));
  }

  const tabs = [];
  tabs.push({
    title: 'Leads',
    subtitle: `${count}`,
    active: true,
  });
  if (userRole === "ADMIN") {
    tabs.push({
      title: 'Agent Reports',
      onClick: () => props.history.push('/report'),
    });
    tabs.push({
      title: 'Organisation Report',
      onClick: () => props.history.push('/charts'),
    });
  }
  tabs.push({
    title: 'Calls',
    onClick: () => props.history.push('/recovery-calls'),
  });

  if (props.isReocveryXEnabled === false) {
    return (
      <div className={`${props.className}`}>
        <Sidebar
        />
        <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />
          <div className="ContentContainer">
            <UpgradeServices
              icon="recoveryX"
              heading="RecoveryX"
              text="Supercharge your collections team with RecoveryX - which boasts one of the highest call-to-connect ratio in the industry!"
              emailSent={props.isRecoveryXEmailSent}
            />
          </div>
        </div>
      </div>
    )
  }

  const getAgentMetric = async () => {
    if (userRole === "ADMIN") {
      setAgentMetric(null);
    }
    else {
      const data = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        agentId: localStorage.getItem('doosra-biz-user-id')
      };
      await POST(webRTCOutGoingUrl + 'metrics/create-find-agent-metric', data)
        .then((res) => {
          console.log(res.data.response);
          const metricObj = res?.data?.response?.metricObj;
          setAgentMetric(metricObj || null);
          setSelectedAgentStatus(metricObj.breakReason);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          return false;
        });
    }
  }

  const handleAgentActiveStatus = async (e) => {
    console.log(e);
    setSelectedAgentStatus(e.target.value);

    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      agentId: localStorage.getItem('doosra-biz-user-id'),
      breakSwitch: "",
      breakReason: e.target.value,
    }
    if (e.target.value === 'active') {
      data.breakSwitch = 'off';
    }
    else {
      data.breakSwitch = 'on';
    }
    if (e.logout) {
      data.logout = e.logout;
    }
    console.log(data);
    await PUT(webRTCOutGoingUrl + 'metrics/update-agent-break-time', {}, data)
      .then((res) => {
        toast.success(res?.data?.response?.msg);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  }


  return (
    <div className={`${props.className}`}>
      <Sidebar
        agentMetric={agentMetric}
        handleAgentActiveStatus={handleAgentActiveStatus}
      />
      {!navigator.onLine ? <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around', height: '40px', backgroundColor: '#ed3833' }}>
        <span style={{ fontSize: '16px', fontWeight: '600', color: '#f1f2f2', padding: 10 }}>You are offline!</span>
      </div> : null}
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        {
          (userRole === "USER" && (batchCallId || singleCallStatus) && !WebRTCEnabled) ?
            <SideModal
              isOpen={showSideModal}
              hangupCall={(e) => hangupCall(e)}
              value={autoCalling}
              contact={userDetails}
              status={callStatus}
              progress={position}
              batchCallHadler={(e) => batchCallHadler(e)}
            >
            </SideModal> : null
        }
        {
          // For WebRTC Call Popup
          (userRole === "USER" && (batchCallId ? true : false || singleCallStatus) && WebRTCEnabled) ?
            <WebRTCCallPopup
              isOpen={WebRTCEnabled ? (batchCallId ? true : false || singleCallStatus) : false}
              muteCall={muteCall}
              unmuteCall={unMuteCall}
              holdCall={holdCall}
              resumeCall={resumeCall}
              endCall={endCall}
              RTCcallStatus={RTCcallStatus}
              number={destinationNumber}
              leadListContacts={leadListContacts}
              name={destinationName}
              isSingleCall={singleCallStatus}
              batchCallId={batchCallId}
              batchCallHadler={(e) => batchCallHadler(e)}
              value={autoCalling}
            >
            </WebRTCCallPopup> : null
        }
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar tabs={tabs}>
            <SearchBar
              type="recoveryCalling"
              disableTitle
              addLeadListHandler={(e) => addLeadListHandler(e)}
              agentMetric={agentMetric}
              handleAgentActiveStatus={handleAgentActiveStatus}
              optionsAgentActiveStatus={optionsAgentActiveStatus}
              selectedAgentStatus={selectedAgentStatus}
              addBulkLeadListHandler={(e) => addBulkLeadListHandler(e)}
              count={count}
              leadListId={exportBy}
              fetchLeadListContactsHandler={(e) =>
                fetchLeadListContactsHandler(e)
              }
              refreshList={() => getDispositions()}
              getLeadListsHandler={(e) => getLeadListsHandler(e)}
              getLeadsCount={(e) => getLeadsCount(e)}
              deleteLeadList={(e) => deleteLeadList(e)}
              toggleLeadListVisibility={(e, f) => toggleLeadListVisibility(e, f)}
              leadLists={leadLists}
              dispositions={dispositions}
              stopBatchCall={(e) => stopBatchCall(e)}
              batchCallInit={(e) => WebRTCEnabled ? initiateWebRTCBatchRecoveryCall() : batchCallInit()}
              filterAndInitiatebatchCallHandler={(e) => WebRTCEnabled ? filterAndInitiatebatchCallHandler(e) : filterAndInitiatebatchCallHandler(e)}
              headers={headers}
            />
          </TabSearchBar>
          <LeadListSearchBar
            selectedAgentStatus={selectedAgentStatus}
            agentMetric={agentMetric}
            leadListId={leadListId} search={fetchLeadListContactsHandler} reset={resetFetchLeadListContactsHandler} addLeadHandler={addLeadHandler} initiateRecoveryCall={initiateRecoveryCall} isAddUserButtonVisible={(leadListContacts && (leadListContacts.length > 0 || leadListId !== '')) || false} headers={headers} />
          <DataTable.Table style={{ overflow: "auto" }}>
            <DataTable.Head>

              {/* <DataTable.CellHead>NAME</DataTable.CellHead>
              <DataTable.CellHead>PRIMARY NO</DataTable.CellHead>
              <DataTable.CellHead>SECONDARY NO</DataTable.CellHead>
              <DataTable.CellHead>EMAIL ID</DataTable.CellHead>
              <DataTable.CellHead>DUE AMOUNT</DataTable.CellHead>
              <DataTable.CellHead>DUE DATE</DataTable.CellHead> */}
              {(headers && headers.length > 0) ? headers.map((header) => <DataTable.CellHead>{header.toUpperCase()}</DataTable.CellHead>) : null}
              {leadListId !== "all" ?
                <>
                  <DataTable.CellHead>LAST CALL STATUS</DataTable.CellHead>
                  <DataTable.CellHead>LAST CALL DURATION</DataTable.CellHead>
                  <DataTable.CellHead>LAST CALL DATE</DataTable.CellHead>
                  <DataTable.CellHead>DISPOSITION</DataTable.CellHead>
                  <DataTable.CellHead>HISTORY</DataTable.CellHead>
                  <DataTable.CellHead>ACTIONS</DataTable.CellHead>
                </>
                : 
                <>
                  <DataTable.CellHead>AGENT</DataTable.CellHead>
                  <DataTable.CellHead>LAST CALL STATUS</DataTable.CellHead>
                  <DataTable.CellHead>LAST CALL DURATION</DataTable.CellHead>
                  <DataTable.CellHead>DISPOSITION</DataTable.CellHead>
                  <DataTable.CellHead>DATE</DataTable.CellHead>
                  <DataTable.CellHead>TIME</DataTable.CellHead>
                  <DataTable.CellHead>HISTORY</DataTable.CellHead>
                </>}
                
            </DataTable.Head>
            <DataTable.Body>{displayData}</DataTable.Body>
          </DataTable.Table>
          <Paginator
            currentPage={currentPage}
            lastPage={totalPages}
            getInfo={(value) => fetchLeadListContactsHandler({
              pageNo: value,
              leadListId
            })}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isReocveryXEnabled: state.topbar.isReocveryXEnabled,
  isNumberMaskingEnabled: state.topbar.isNumberMaskingEnabled,
  userDetails: state.recovery.userDetails,
  callStatus: state.recovery.callStatus,
  position: state.recovery.position,
  autoCalling: state.recovery.autoCalling,
  batchCallId: state.recovery.batchCallId,
  // destinationNumber: state.recovery.destinationNumber,
  // destinationName: state.recovery.destinationName,
  // RTCSession: state.recovery.RTCSession,
  callId: state.recovery.callId,
  callFilterLeads: state.recovery.callFilterLeads,
  singleCallStatus: state.recovery.singleCallStatus,
  // RTCcallStatus: state.recovery.RTCcallStatus,
  isRecoveryXEmailSent: state.topbar.isRecoveryXEmailSent,
});
export default commonStyle(connect(mapStateToProps, {
  setCallDetails,
  setBatchCallId,
  setCallId,
  setCallStatus,
  setAutoCalling,
  toggleAutoCalling,
  setSingleCallStatus,
  setDestinationNumber,
  setDestinationName,
  setRTCSession,
  setCallFilterLeads,
  setRTCCallStatus,
  setHeaders,
})(RecoveryCallingPage));

import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import DataTable from '../../../common/component/table/DataTable';
import { GET, PUT } from '../../../services/HttpRequests';
import { useEffect, useState } from 'react';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import { toast } from 'react-toastify';
import moment from 'moment';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import DNDSearchBar from '../component/DidSearchBar'

const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

function DNDList(props) {

  const deleteDNDNumber = (dndNumber) => {
    const data = {
      action: "DELETE",
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      blackListNumber: dndNumber
    }
    PUT(`${cloudCallCenterUrl}blacklist`, {}, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        props.fetchDataHandler();
        toast.success('DND Number Deleted');
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <div style={{ overflowX: 'auto' }}>
      <DataTable.Table style={{ minWidth: '100%' }}>
        <DataTable.Head>
          <DataTable.CellHead width="50">#</DataTable.CellHead>
          <DataTable.CellHead width="150">NAME</DataTable.CellHead>
          <DataTable.CellHead width="150">NUMBER</DataTable.CellHead>
          <DataTable.CellHead width="150">CREATED AT</DataTable.CellHead>
          <DataTable.CellHead width="150">TYPE</DataTable.CellHead>
          <DataTable.CellHead width="150">EXPIRY DATE</DataTable.CellHead>
          <DataTable.CellHead width="150" >
            ACTION
          </DataTable.CellHead>
        </DataTable.Head>
        <DataTable.Body>
          {console.log(props.data?.BlackListNumbers)}
          {props.data && props.data?.BlackListNumbers?.map((blacklist, index) =>
          (<DataTable.Row>
            <DataTable.Cell>{index + 1}</DataTable.Cell>
            <DataTable.Cell>{blacklist.name || "-"}</DataTable.Cell>
            <DataTable.Cell>{blacklist.number}</DataTable.Cell>
            <DataTable.Cell>
              {blacklist.createdAt ?
                moment(blacklist.createdAt).tz("Asia/kolkata").format("YYYY-MM-DD, hh:mm a") : "-"
              }
            </DataTable.Cell>
            <DataTable.Cell style={{ textTransform: 'capitalize' }}>{blacklist?.type || '-'}</DataTable.Cell>
            <DataTable.Cell>
              {(blacklist.expiryDate && blacklist.type === 'temporary') ? moment(blacklist.expiryDate).tz("Asia/kolkata").format("YYYY-MM-DD, hh:mm a") : "-"}
            </DataTable.Cell>
            <DataTable.Cell>
              <DataTable.Cell>
                <img alt=""
                  onClick={() => { deleteDNDNumber(blacklist.number) }}
                  style={{ "cursor": "pointer", }}
                  src={Delete} />
              </DataTable.Cell>
            </DataTable.Cell>
          </DataTable.Row>)
          )}
        </DataTable.Body>
      </DataTable.Table>
    </div>
  );
}

export function BlackList(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const tabs = [];
  tabs.push({
    title: 'Overview Dashboard',
    onClick: () => props.history.push('/dashboard'),
    active: false,
  });
  tabs.push({
    title: 'Campaigns List',
    onClick: () => props.history.push('/cc-campaigns'),
    active: false,
  });
  tabs.push({
    title: 'Agents',
    onClick: () => props.history.push('/agents'),
    active: false,
  });
  tabs.push({
    title: 'Calls',
    onClick: () => props.history.push('/calls'),
    active: false,
  });
  tabs.push({
    title: 'BlackList',
    onClick: () => props.history.push('/blacklist'),
    active: true,
  });

  const fetchDataHandler = (options = {}) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      ...options
    }
    setIsLoading(true);
    GET(`${cloudCallCenterUrl}blacklist`, data)
      .then((res) => {
        setData(res?.data?.response || {});
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDataHandler();
  }, [])

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar
            tabs={tabs}
            type="BlackList"
            refreshList={fetchDataHandler}
          />
          <DNDSearchBar fetchData={fetchDataHandler} />
          {isLoading ? <PreLoader /> :
            <DNDList
              fetchDataHandler={fetchDataHandler}
              data={data} />
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(BlackList));

/* eslint-disable no-dupe-keys */
import adapter from 'webrtc-adapter';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect, useSelector } from 'react-redux';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import phoneWhite from '../../../assets/images/phoneWhiteRecoveryCall.svg';
import historyIcon from '../../../assets/images/callHistoryRecoveryCall.svg';
import activeStatus from '../../../assets/images/activeStatus.svg';
import tea from '../../../assets/images/tea.svg';
import lunch from '../../../assets/images/lunch.svg';
import meeting from '../../../assets/images/meeting.svg';
import training from '../../../assets/images/training.svg';
import biobreak from '../../../assets/images/biobreak.svg';
import offline from '../../../assets/images/offline.svg';
import away from '../../../assets/images/away.svg';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import { useEffect, useState } from 'react';
import { useSocket } from "../../../providers/SocketProvider";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import JsSIP from 'jssip';
import "../component/Tooltip.css";
import { DEV_INHOUSE_PBX_SERVERS, PROD_INHOUSE_PBX_SERVERS, TURN_SERVER_CONFIG } from '../../../assets/constants';
import WebRTCCallPopup from "../component/WebRTCCallPopup";
import {
  setCallDetails,
  setBatchCallId,
  setCallId,
  setCallStatus,
  toggleAutoCalling,
  setAutoCalling,
  setSingleCallStatus,
  setDestinationName,
  setDestinationNumber,
  setRTCSession,
  setCallFilterLeads,
  setRTCCallStatus,
  setHeaders,
  setCampaignId
} from "../../../actions/recoveryXActions"
import PreLoader from '../../../common/component/preLoader/PreLoader';
JsSIP.debug.enable('JsSIP:*');
const webRTCOutGoingUrl = process.env.REACT_APP_WEBRTC_RECOVERY_API_URL;
JsSIP.debug.enable('JsSIP:*');
// import { ReportIcon } from '../../../assets/Icons/Icons';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
export function RecoveryXAgentCampaigns(props) {
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const [campaignsList, setCamapignsList] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState("ACTIVE");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState(null);
  const [filterCampaignStatus, setFilterCampaignStatus] = useState('ACTIVE');
  const [totalPages, setTotalPages] = useState(1);
  let socket = useSocket();
  const tabs = [];
  const INHOUSE_PBX_SERVERS = process.env.REACT_APP_ENV === "DEV" ? DEV_INHOUSE_PBX_SERVERS : PROD_INHOUSE_PBX_SERVERS;
  let {
    autoCalling,
    batchCallId,
    callId,
    singleCallStatus,
  } = props;
  let destinationName = useSelector((state) => state.recovery.destinationName)
  let destinationNumber = useSelector(state => state.recovery.destinationNumber)
  let RTCSession = useSelector(state => state.recovery.RTCSession)
  let RTCcallStatus = useSelector(state => state.recovery.RTCcallStatus);
  let campaignUniqueId = useSelector(state => state.recovery.campaignId)
  console.log("the campaign unique id is", campaignUniqueId);
  /* AGENT ACTIVE STATUS */
  const optionsAgentActiveStatus = [
    { value: 'active', label: 'Active', image: activeStatus, color: '#4daa3e' },
    { value: 'tea', label: 'Tea', image: tea, color: '#196cca' },
    { value: 'lunch', label: 'Lunch', image: lunch, color: '#196cca' },
    { value: 'meeting', label: 'Meeting', image: meeting, color: '#196cca' },
    { value: 'training', label: 'Training', image: training, color: '#196cca' },
    { value: 'biobreak', label: 'Biobreak', image: biobreak, color: '#196cca' },
    // { value: 'away', label: 'Away', image: away, color: '#f8bb44' },
    { value: 'offline', label: 'Offline', image: offline, color: '#939598' },
  ];
  const [selectedAgentStatus, setSelectedAgentStatus] = useState('');
  const [agentMetric, setAgentMetric] = useState(null);
  if (userRole === "ADMIN") {
    tabs.push({
      title: 'Overview Dashboard',
      onClick: () => props.history.push('/recovery-x-dashboard'),
      active: false,
    });
  }
  tabs.push({
    title: 'Campaigns List',
    onClick: () => props.history.push('/campaigns'),
    active: true,
  });
  if (userRole === "ADMIN") {
    tabs.push({
      title: 'Agents',
      onClick: () => props.history.push('/recovery-x-agents'),
      active: false,
    });
  }
  tabs.push({
    title: 'Calls',
    onClick: () => props.history.push('/recovery-x-calls'),
    active: false,
  });
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }
  const fetchCampaignListHandler = (status = "ACTIVE", value) => {
    let temp = value || currentPage;
    if (current !== status) {
      temp = 1;
    }
    setCurrent(status);
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      userId: localStorage.getItem('doosra-biz-user-id'),
      campaignStatus: status,
      pageNo: temp,
      pageSize: pageSize
    };
    GET(outGoingUrl + 'v1/campaigns/get-agents-campaigns', data)
      .then((res) => {
        setCamapignsList(res?.data?.response?.userCampaignsObjs);
        setCurrentPage(res?.data?.response?.pageNo);
        setTotalPages(res?.data?.response?.totalPages);
        setAgentId(res?.data?.response?.agentId);
        setLoading(false);
      })
      .catch((err) => { console.log(err); setLoading(false); });
  };
  function CampaignList(props) {
    const history = useHistory();
    return (
      <DataTable.Table>
        <DataTable.Head>
          <DataTable.CellHead width="20">#</DataTable.CellHead>
          <DataTable.CellHead width="100">CAMPAIGN</DataTable.CellHead>
          <DataTable.CellHead width="100">DESCRIPTION</DataTable.CellHead>
          <DataTable.CellHead width="80">CAMPAIGN TYPE</DataTable.CellHead>
          <DataTable.CellHead width="80">CALLS</DataTable.CellHead>
          <DataTable.CellHead width="80">CONNECTED CALLS</DataTable.CellHead>
          {/* <DataTable.CellHead width="40">UNANSWERED CALLS</DataTable.CellHead>
          <DataTable.CellHead width="40">CALL ANSWER RATE</DataTable.CellHead> */}
          <DataTable.CellHead width="80">START DATE</DataTable.CellHead>
          <DataTable.CellHead width="80">END DATE</DataTable.CellHead>
          <DataTable.CellHead width="80">START TIME</DataTable.CellHead>
          <DataTable.CellHead width="80">END TIME</DataTable.CellHead>
          <DataTable.CellHead width="80">HISTORY</DataTable.CellHead>
          {/* <DataTable.CellHead width="140" style={{ textAlign: 'end' }}>
            START CALLING
          </DataTable.CellHead> */}
        </DataTable.Head>
        <DataTable.Body>
          {props?.campaignsList?.length >= 0 &&
            props?.campaignsList.map((item, index) => (
              <DataTable.Row key={item._id}>
                <DataTable.Cell>{((currentPage - 1) * pageSize) + index + 1}</DataTable.Cell>
                <DataTable.Cell
                  style={{ cursor: 'pointer', textDecoration: "underline" }}
                  onClick={() => {
                    if (item?.campaignType === 'static') {
                      history.push(`/campaign/static?agentId=${agentId}&campaignId=${item._id}`);
                    }
                    if (item?.campaignType === 'dynamic') {
                      history.push(`/campaign/dynamic?agentId=${agentId}&campaignId=${item._id}`);
                    }
                  }}
                >
                  {item?.campaignName}
                </DataTable.Cell>
                <DataTable.Cell>{item?.campaignDescription}</DataTable.Cell>
                <DataTable.Cell style={{ textTransform: "capitalize" }}>
                  {item?.campaignType}
                </DataTable.Cell>
                <DataTable.Cell>{item?.totalCampaignCalls}</DataTable.Cell>
                <DataTable.Cell>{item?.totalCampaignAnsweredCalls}</DataTable.Cell>
                {/* <DataTable.Cell>{item?.totalCampaignUnAnsweredCalls}</DataTable.Cell>
                <DataTable.Cell>{item?.campaignAnswerRate.toFixed(2)}</DataTable.Cell> */}
                <DataTable.Cell>{item?.campaignStartDate.split("T")[0]}</DataTable.Cell>
                <DataTable.Cell>{item?.campaignEndDate.split("T")[0]}</DataTable.Cell>
                <DataTable.Cell>{moment(item?.campaignStartTime.slice(0, 5), 'HH:mm').format('hh:mm A')}</DataTable.Cell>
                <DataTable.Cell>{moment(item?.campaignEndTime.slice(0, 5), 'HH:mm').format('hh:mm A')}</DataTable.Cell>
                <DataTable.Cell>
                  <span>
                    <button
                      className="Otherbutton"
                      style={{ marginRight: 0, width: "32px", height: "32px", display: "flex", alignContent: "center", justifyContent: "center" }}
                      onClick={() => {
                        history.push(`/campaign?agentId=${agentId}&campaignId=${item._id}`);
                      }}
                    >
                      <img
                        alt=""
                        src={historyIcon}
                        style={{ width: '16px', height: '16px' }}
                      />
                    </button>
                  </span>
                </DataTable.Cell>
                {/* <DataTable.Cell
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '8px',
                  }}
                >
                  <div className="tooltip-container">
                    <div className="tooltip-button">
                      {props.selectedAgentStatus === 'active' ? (
                        item?.isValidCampaign ?
                          campaignUniqueId === "" || !campaignUniqueId ?
                            <button
                              className="ActivateButton"
                              onClick={() => initiateCampaignCallsToAgent(item._id)}
                              style={{ width: "32px", height: "32px" }}
                            >
                              <img
                                alt=""
                                src={phoneWhite}
                                className="SidebarItemIcon"
                                style={{ width: "16px", height: "16px" }}
                              />
                            </button> : campaignUniqueId === item._id ?
                              <button
                                className="DeactivateButton"
                                onClick={() => stopCampaignCallToAgent(item._id)}
                                style={{ width: "32px", height: "32px" }}
                              >
                                <img
                                  alt=""
                                  src={phoneWhite}
                                  className="SidebarItemIcon"
                                  style={{ width: "16px", height: "16px" }}
                                />
                              </button>
                              :
                              <button
                                className="ActivateButton"
                                onClick={() => initiateCampaignCallsToAgent(item._id)}
                                style={{ width: "32px", height: "32px", opacity: "0.5" }}
                                disabled={true}
                              >
                                <img
                                  alt=""
                                  src={phoneWhite}
                                  className="SidebarItemIcon"
                                  style={{ width: "16px", height: "16px" }}
                                />
                              </button>
                          :
                          <button
                            className="ActivateButton"
                            style={{ width: "32px", height: "32px", opacity: "0.5" }}
                            disabled={true}
                          >
                            <img
                              alt=""
                              src={phoneWhite}
                              className="SidebarItemIcon"
                              style={{ width: "16px", height: "16px" }}
                            />
                          </button>
                      ) : (<button
                        className="ActivateButton"
                        style={{ width: "32px", height: "32px", opacity: "0.5", cursor: "default" }}
                        disabled={true}
                      >
                        <img
                          alt=""
                          src={phoneWhite}
                          className="SidebarItemIcon"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </button>)
                      }
                    </div>
                    <div className="tooltip-text left" data-tip-position="left">
                      {props.selectedAgentStatus === 'active' ? (item?.isValidCampaign ?
                        (campaignUniqueId === item._id ? "End Call" : "Start Call") :
                        "campaign is not valid") : "You are in Inactive Status"}
                      < span className="arrow-right"></span>
                    </div>
                  </div>
                </DataTable.Cell> */}
              </DataTable.Row>
            ))}
        </DataTable.Body>
      </DataTable.Table >
    );
  }
  function FilterTab(props) {
    return (
      <div
        style={{
          display: 'flex',
          gap: '16px',
          padding: '16px',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            margin: 0,
            fontSize: '12px',
            fontWeight: 500,
            color: '#808080',
          }}
        >
          Filter
        </p>
        <div
          className={
            current === 'ACTIVE' ? 'ActiveFilterTab' : 'InactiveFilterTab'
          }
          onClick={() => fetchCampaignListHandler("ACTIVE")}
        >
          ACTIVE
        </div>
        {/* <div
          className={
            current === 'PAUSED' ? 'ActiveFilterTab' : 'InactiveFilterTab'
          }
        >
          109 PAUSED
        </div> */}
        <div
          className={
            current === 'COMPLETED'
              ? 'ActiveFilterTab'
              : 'InactiveFilterTab'
          }
          onClick={() => fetchCampaignListHandler("COMPLETED")}
        >
          COMPLETED
        </div>
      </div>
    );
  }
  const getAgentMetric = async () => {
    if (userRole === 'ADMIN') {
      setAgentMetric(null);
    } else {
      const data = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        agentId: localStorage.getItem('doosra-biz-user-id'),
      };
      await POST(webRTCOutGoingUrl + 'metrics/create-find-agent-metric', data)
        .then((res) => {
          console.log(res.data.response);
          const metricObj = res?.data?.response?.metricObj;
          setAgentMetric(metricObj || null);
          setSelectedAgentStatus(metricObj.breakReason);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          return false;
        });
    }
  };
  const handleAgentActiveStatus = async (e) => {
    console.log(e);
    setSelectedAgentStatus(e.target.value);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      agentId: localStorage.getItem('doosra-biz-user-id'),
      breakSwitch: '',
      breakReason: e.target.value,
    };
    if (e.logout) {
      data.logout = e.logout;
    }
    if (e.target.value === 'active') {
      data.breakSwitch = 'off';
    } else {
      data.breakSwitch = 'on';
    }
    if (e.logout) {
      data.logout = e.logout;
    }
    console.log(data);
    await PUT(webRTCOutGoingUrl + 'metrics/update-agent-break-time', {}, data)
      .then((res) => {
        toast.success(res?.data?.response?.msg);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };
  function initiateCampaignCallsToAgent(campaignId) {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      userId: localStorage.getItem("doosra-biz-user-id"),
      campaignId
    };
    POST(outGoingUrl + 'v1/organisations/outbound-call/start-campaign-calls', data)
      .then((res) => {
        console.log("set campaign id", campaignId)
        props.setCampaignId({ campaignId });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
      });
  };
  function stopCampaignCallToAgent(campaignId) {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      userId: agentId,
      campaignId
    }
    POST(outGoingUrl + 'v1/organisations/outbound-call/stop-campaign-calls', data)
      .then((res) => {
        props.setCampaignId({ campaignId: "" });
        toast.success("Campaign calls ended.")
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
      });
  }
  function updateCampaignsStatuses() {
    PUT(outGoingUrl + 'v1/organisations/outbound-call/update-campaigns-status')
      .then((res) => {
        console.log(res.data.response);
        fetchCampaignListHandler();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
      });
  }
  useEffect(() => {
    updateCampaignsStatuses();
    //fetchCampaignListHandler();
    //getAgentMetric();
  }, []);
  return (
    <div className={`${props.className}`}>
      <Sidebar
        agentMetric={agentMetric}
        handleAgentActiveStatus={handleAgentActiveStatus}
      />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar tabs={tabs}>
            <SearchBar
              type="recovery-x-campaign"
              disableTitle
              agentMetric={agentMetric}
              handleAgentActiveStatus={handleAgentActiveStatus}
              optionsAgentActiveStatus={optionsAgentActiveStatus}
              selectedAgentStatus={selectedAgentStatus}
              toggleModal={() => toggleModal()}
            // count={this.state.count}
            // reset={this.getFreshList}
            // search={this.seachHandler}
            />
          </TabSearchBar>
          <FilterTab current="ACTIVE" />
          {loading && <PreLoader />}
          {!loading && <CampaignList selectedAgentStatus={selectedAgentStatus} campaignsList={campaignsList} />}
          <Paginator
            currentPage={currentPage}
            lastPage={totalPages}
            getInfo={(value) => fetchCampaignListHandler(current, value)}
          />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isReocveryXEnabled: state.topbar.isReocveryXEnabled,
  isNumberMaskingEnabled: state.topbar.isNumberMaskingEnabled,
  userDetails: state.recovery.userDetails,
  callStatus: state.recovery.callStatus,
  position: state.recovery.position,
  autoCalling: state.recovery.autoCalling,
  batchCallId: state.recovery.batchCallId,
  // destinationNumber: state.recovery.destinationNumber,
  // destinationName: state.recovery.destinationName,
  // RTCSession: state.recovery.RTCSession,
  callId: state.recovery.callId,
  callFilterLeads: state.recovery.callFilterLeads,
  singleCallStatus: state.recovery.singleCallStatus,
  RTCcallStatus: state.recovery.RTCcallStatus,
  isRecoveryXEmailSent: state.topbar.isRecoveryXEmailSent,
});
export default commonStyle(connect(mapStateToProps, {
  setCallDetails,
  setBatchCallId,
  setCallId,
  setCallStatus,
  setAutoCalling,
  toggleAutoCalling,
  setSingleCallStatus,
  setDestinationNumber,
  setDestinationName,
  setRTCSession,
  setCallFilterLeads,
  setRTCCallStatus,
  setHeaders,
  setCampaignId,
})(RecoveryXAgentCampaigns));
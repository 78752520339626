/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import '../../../assets/css/SearchBar.css';
import { commonStyle } from '../../../assets/css/css';
import phoneWhite from '../../../assets/images/ioscall.png';
import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import AudioPlayer from '../component/AudioPlayer';
import DataTable from '../../../common/component/table/DataTable';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import AddIndividualCallDispositionsPopup from "../component/AddIndividualCallDispositionsPopup"
import PreLoader from '../../../common/component/preLoader/PreLoader';
import moment from 'moment';
import { toast } from 'react-toastify';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;

export function RecoveryCallingPage(props) {
  const statusMapper = {
    'answered':'Answered',
    'not_answered':"Not Answered"
  };
  let displayData;
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [leadListId, setLeadListId] = useState('');
  const [totalOrganisationCalls, setTotalOrganisationCalls] = useState(0);
  const [activeLoginUsersCount, setActiveLoginUsersCount] = useState(0);
  const [totalOrganisationDuration, setTotalOrganisationDuration] = useState(0);
  const [leadListContacts, setLeadListContacts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dispositions, setDispositions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");


  const getLeadsCount = (count) => {
    setCount(count);
  };
  const getDispositions = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(
      outGoingUrl + 'v1/organisations/outbound-call/get-dispositions',
      data
    )
      .then((res) => {
        setDispositions(res.data.response.dispositions)
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  }

  useEffect(() => {fetchOrganisationReport();getLeadListsHandler();getDispositions();}, []);

  const updateCallDispositonTag = (data) => {
    const tempLeadsData = leadListContacts.map( lead => { if(lead._id === data.callId) {
      lead.callTag = data.callTag}
  return lead;})
  setLeadListContacts(tempLeadsData)
  }

  const getLeadListsHandler = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(
      outGoingUrl + 'v1/organisations/outbound-call/get-lead-lists',
      data
    )
      .then((res) => {
       
        setCount(res.data.count);
       
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  const fetchOrganisationReport = ( pageNo = 1, filter = {}, reset = false) => {
    setLoading(true);
    let data = {"pageNo": pageNo, "pageSize": "15"};
    if((filter.searchKey && filter.searchCondition)) {
      data.searchKey = filter.searchKey;
      data.searchValue = filter.searchCondition;
    }
    if((filter.searchKey === "date" && filter.from_date && filter.to_date)) {
      data.searchKey = filter.searchKey;
      data.fromDate = filter.from_date;
      data.toDate = filter.to_date;
    }

    if(searchKey  && searchValue && !reset)
    {
      data.searchKey = searchKey;
      data.searchValue = searchValue;
    }
   
    if(searchKey && searchKey === "date" && fromDate && toDate && !reset)
    {
      data.searchKey = searchKey;
      data.searchValue = searchValue;
      data.fromDate = fromDate;
      data.toDate = toDate;
    }
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    GET(outGoingUrl + 'v1/organisations/outbound-call/get-calls', data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        setLeadListContacts(res.callsData);
        setTotalPages(res.totalPages)
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  if (loading) displayData = <PreLoader />;
  if (leadListContacts.length > 0) {
    displayData = leadListContacts.map((row) => (
      <DataTable.Row key={row._id}>
        <DataTable.Cell>
          {!row.user || row.user === 'NA' ? '-' : row.user.zvr_name}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.initiatorNumber || row.initiatorNumber === 'NA' ? '-' : row.initiatorNumber}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.destinationNumber || row.destinationNumber === 'NA'
            ? '-'
            : row.destinationNumber}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.status || row.status === 'NA'
            ? '-'
            :  statusMapper[row.status]}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.duration || row.duration === 'NA'
            ? '-'
            : row.duration}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.createdAt || row.createdAt === 'NA'
            ? '-'
            : moment(row.createdAt).format('HH:mm:ss')}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.createdAt || row.createdAt === 'NA'
            ? '-'
            : moment(row.createdAt).format('DD-MM-YYYY')}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.callTag || row.callTag === 'NA'
            ? '-'
            : row.callTag}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.duration || row.duration  === 'NA' || row.duration === 0
            ? ""
            : (
                <AudioPlayer
                  callRecordingUrl={row.recording}
                  callId={row._id}
                />
              )}
        </DataTable.Cell>
        {userRole === "USER" ? 
         <DataTable.Cell>
        <AddIndividualCallDispositionsPopup open={isModalOpen} toggle={toggleModal} dispositions= {dispositions} callId = {row._id} updateCallDispositonTag = {updateCallDispositonTag}>
        </AddIndividualCallDispositionsPopup>
        </DataTable.Cell>
         : 
        null}
      </DataTable.Row>
    ));
  }

  const tabs = [];
  tabs.push({
    title: 'Leads',
    subtitle: `${count}`,
    onClick: () => props.history.push('/recovery-calling'),
  });
  if(userRole === "ADMIN")
  {
    tabs.push({
      title: 'Agent Reports',
      onClick: () => props.history.push('/report'),
    });
    tabs.push({
      title: 'Organisation Report',
      onClick: () => props.history.push('/charts'),
    });
  }
  tabs.push({
    title: 'Calls',
    active: true
  });

  const tempfunction = (data =  {} ) => {
    setSearchKey(data.searchKey);
    setSearchValue(data.searchCondition)
    if(data.searchKey === "date") {
      setFromDate(data.from_date)
      setToDate(data.to_date)
    }
    fetchOrganisationReport(1, data)
  }
  const resetFunction = () => {
    setSearchKey("");
    setSearchValue("");
    setFromDate("");
    setToDate("");
    setCurrentPage(1);
    fetchOrganisationReport(1, {}, true)
  }

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar tabs={tabs}>
            <SearchBar
              type="recovery-call-logs"
              disableTitle
              count={count}
              getLeadsCount={(e) => getLeadsCount(e)}
              search={(e) => tempfunction(e)}
              reset={(e) => resetFunction(e)}
            />
          </TabSearchBar>
          {/* <ReportSearchBar
            totalOrganisationCalls={totalOrganisationCalls}
            totalOrganisationDuration={totalOrganisationDuration}
            activeLoginUsersCount={activeLoginUsersCount}
            search={fetchOrganisationReport}
            reset={fetchOrganisationReport}
          /> */}
          <DataTable.Table>
            <DataTable.Head>
            <DataTable.CellHead>INITIATOR NAME</DataTable.CellHead>
              <DataTable.CellHead>INITIATOR NUMBER</DataTable.CellHead>
              <DataTable.CellHead>DESTINATION NUMBER</DataTable.CellHead>
              <DataTable.CellHead>CALL STATUS</DataTable.CellHead>
              <DataTable.CellHead>DURATION</DataTable.CellHead>
              <DataTable.CellHead>TIME</DataTable.CellHead>
              <DataTable.CellHead>DATE</DataTable.CellHead>
              <DataTable.CellHead>DISPOSITION</DataTable.CellHead>
              <DataTable.CellHead>RECORDING</DataTable.CellHead>
              {userRole === "USER" ? <DataTable.CellHead>ACTION</DataTable.CellHead>: null}
            </DataTable.Head>
            <DataTable.Body>{displayData}</DataTable.Body>
          </DataTable.Table>
          <Paginator
              currentPage={currentPage}
              lastPage={totalPages}
              getInfo={(value) => {
                setCurrentPage(value)
                fetchOrganisationReport(value)}}
            />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
});
export default commonStyle(connect(mapStateToProps, {})(RecoveryCallingPage));

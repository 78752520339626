/* eslint-disable */
import React, { useState } from 'react';
import './styles.css';
import '../../../assets/css/Popups.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import historyIcon from '../../../assets/images/callHistoryRecoveryCall.svg';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import DataTable from '../../../common/component/table/DataTable';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import AudioPlayer from './AudioPlayer';

// export default function GetCallHistory(props) {
//   const [show, setShow] = useState(false);
//   const [selectedData, setSelectedData] = useState([]);
//   const hanldeClick = () => {
//     setShow(true);
//     let data = { destinationNumber: props.destinationNumber };
//     GET(outGoingUrl + 'v1/organisations/outbound-call/get-calls-history', data)
//       .then((res) => {
//         return res.data.response;
//       })
//       .then((res) => {
//         setSelectedData(res);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const hideModal = () => {
//     setShow(false);
//   };

//   return (
//     <div style={{ display: 'flex' }}>
//       <span>
//         <button
//           className="Otherbutton"
//           style={{ marginRight: "0px", fontSize:"12px", width:"86px", height:"32px"}}
//           onClick={hanldeClick}
//         >
//           <img
//         alt=""
//         src={historyIcon}
//         className="SidebarItemIcon"
//         style={{marginRight:"8px", width:"16px", height:"16px", paddingBottom:"4px"}}
//       />
//           VIEW
//         </button>
//         {show && <Modal details={selectedData} handleClose={hideModal} />}
//       </span>
//     </div>
//   );
// }

// const Modal = ({ handleClose, details }) => {
//   const statusMapper = {
//     'answered':'Answered',
//     'not_answered':"Not Answered"
//   };
//   let displayData;
//   if (details.length > 0) {
//     displayData = details.map((data) => (
//       <tr>
//         <td>{data.initiatorNumber}</td>
//         <td>{data.destinationNumber}</td>
//         <td>{statusMapper[data.status]}</td>
//         <td>{data.duration}</td>
//         <td>{data.callTag || "-"}</td>
//         <td>{data.callComment || "-"}</td>
//       </tr>
//     ));
//   }
//   return (
//     <div className="modal display-block">
//       <section className="modal-main">
//         <div className="App">
//           <table class="table">
//             <thead>
//               <tr>
//                 <th scope="col">INITIATOR NUMBER</th>
//                 <th scope="col">CUSTOMER PRIMARY NO</th>
//                 <th scope="col">CALL STATUS</th>
//                 <th scope="col">DURATION</th>
//                 <th scope="col">TAG</th>
//                 <th scope="col">COMMENT</th>
//               </tr>
//             </thead>
//             <tbody>{displayData}</tbody>
//           </table>
//         </div>
//         <button onClick={handleClose}>close</button>
//       </section>
//     </div>
//   );
// };

export default function CallHistoryPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const hanldeClick = () => {
    setPopupOpen(true);
    setLoading(true);
    let data = {
      destinationNumber: props.destinationNumber,
      campaignId: props.campaignId,
      leadListId: props.leadListId,
      secondaryNumber: props.secondaryNumber,
    };
    GET(outGoingUrl + 'v1/organisations/outbound-call/get-calls-history', data)
      .then((res) => {
        setLoading(false);
        return res.data.response;
      })
      .then((res) => {
        setLoading(false);
        setSelectedData(res);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const statusMapper = {
    answered: 'Answered',
    not_answered: 'Not Answered',
  };
  const PopupContainer = {
    width: '1200px',
  };
  const scrollEffect = {
    overflowY: 'auto',
  };
  let displayData;
  if (loading) displayData = <PreLoader />;
  if (!loading && selectedData.length > 0) {
    displayData = selectedData.map((row) => (
      <DataTable.Row>
        <DataTable.Cell>{row.userName || '-'}</DataTable.Cell>
        <DataTable.Cell>{row.destinationNumber || '-'}</DataTable.Cell>
        <DataTable.Cell>{statusMapper[row.status] || '-'}</DataTable.Cell>
        <DataTable.Cell>{row.duration || '-'}</DataTable.Cell>
        <DataTable.Cell>
          {new Date(row.createdAt).toLocaleDateString() || '-'}
        </DataTable.Cell>
        <DataTable.Cell>
          {new Date(row.createdAt).toLocaleTimeString() || '-'}
        </DataTable.Cell>
        <DataTable.Cell>{row.callTag || '-'}</DataTable.Cell>
        <DataTable.Cell>{row.callComment || '-'}</DataTable.Cell>
        <DataTable.Cell>
          {row.recording ? (
            <AudioPlayer callRecordingUrl={row?.recording} callId={row._id} />
          ) : (
            '-'
          )}
        </DataTable.Cell>
      </DataTable.Row>
    ));
  }
  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div
        className="PopupContainer"
        style={{ width: '1200px', overflow: 'scroll' }}
      >
        <div className="PopupHeaderContainer" style={{ ...PopupContainer }}>
          <p>Call History</p>
          <img
            style={{ left: '95%' }}
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => setPopupOpen(false)}
          />
        </div>
        <div className="PopupBody" style={{ ...PopupContainer }}>
          <DataTable.Table style={{ ...PopupContainer }}>
            <DataTable.Head>
              <DataTable.CellHead>INITIATOR NAME</DataTable.CellHead>
              <DataTable.CellHead>CUSTOMER PRIMARY NO</DataTable.CellHead>
              <DataTable.CellHead>CALL STATUS</DataTable.CellHead>
              <DataTable.CellHead>DURATION (SECONDS)</DataTable.CellHead>
              <DataTable.CellHead>DATE</DataTable.CellHead>
              <DataTable.CellHead>TIME</DataTable.CellHead>
              <DataTable.CellHead>DISPOSITION</DataTable.CellHead>
              <DataTable.CellHead>COMMENT</DataTable.CellHead>
              <DataTable.CellHead>RECORDING</DataTable.CellHead>
            </DataTable.Head>
            <DataTable.Body>{displayData}</DataTable.Body>
          </DataTable.Table>
        </div>
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <span>
        <button
          className="Otherbutton"
          style={{
            marginRight: 0,
            width: '32px',
            height: '32px',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            setPopupOpen(true);
            hanldeClick();
          }}
        >
          <img
            alt=""
            src={historyIcon}
            style={{ width: '16px', height: '16px' }}
          />
        </button>
        {popper}
      </span>
    </div>
  );
}

import React, { useState } from 'react';
import '../styles/webhook.css';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { DELETE } from '../../../services/HttpRequests';
import { isSuperAdminWithPermission } from '../../../utility/utility';

const organizationUrl = process.env.REACT_APP_ORGANIZATION_API;

const DeletePopUp = (props) => {
  const accessKey = props.Response.access_key;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const organisationId = localStorage.getItem('doosra-biz-organisation-id');
  const params = { organisationId, accessKey, type: props.type };
  const deleteWebhook = () => {
    props.setIsLoading(true);
    DELETE(`${organizationUrl}v1/organisation/web-hook`, params)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        props.toast.success('Deleted Successfully');
        props.setIsLoading(false);
        props.clearFields();
        props.setIsWebhookAvailable(false);
        setIsPopupOpen(false);
      })
      .catch((err) => {
        console.log(err);
        props.toast.error(err.response.data.error.reason);
      });
  };
  return (
    <div>
      <button
        className="secondary-btn-2"
        onClick={() => setIsPopupOpen(true)}
        disabled={!isSuperAdminWithPermission('DELETE')}
      >
        <span className="sec-btn-txt-2">DELETE</span>
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Delete Webhook</p>
            <img
              alt="CloseImage"
              className="PopupClose"
              src={Close}
              onClick={() => setIsPopupOpen(false)}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfoForDelete">
              Are you sure you want to delete this webhook?
            </p>
            <p className="PopupMoreInfo">
              This action is permanent and can’t be undone.
            </p>
            <button className="ButtonFullWidth" onClick={deleteWebhook}>
              DELETE
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default DeletePopUp;

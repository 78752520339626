import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect, useSelector } from 'react-redux';
import Button from '../../../common/component/button/Button';
import CsvDownloader from 'react-csv-downloader';
import { useEffect, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import Close from '../../../assets/images/popupClose.png';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import downloadIconGreen from '../../../assets/images/downloadIconGreen.png';
import cancelIcon from '../../../assets/images/cancelIconCircleGreen.png';
import DataCard from '../component/DataCard';
import DataTable from '../../../common/component/table/DataTable';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import AddLeadToLeadListPopup from '../component/AddLeadToLeadListPopup';
import { setHeaders } from "../../../actions/recoveryXActions";
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import DeleteLeadsPopup from '../component/DeleteLeadsPopup';
import Popup from '../../../common/component/popups/popups';
import ModifyHeadersPopUp from '../component/ModifyHeadersPopUp';

const userUrl = process.env.REACT_APP_USER_API;
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

function CampaignReportTable(props) {
  return (
    <DataTable.Table>
      <DataTable.Head>
        <DataTable.CellHead width="20">#</DataTable.CellHead>
        {props?.headers?.length > 0 &&
          props?.headers?.map((header) => (
            (<DataTable.CellHead width="50">{header.toUpperCase()}</DataTable.CellHead>)))
        }

        {/* {props.campaignType === 'static' && <DataTable.CellHead width="50">AGENT</DataTable.CellHead>}
        <DataTable.CellHead width="60">LEAD NUMBER</DataTable.CellHead>
        <DataTable.CellHead width="40">LEAD SECONDARYNUMBER</DataTable.CellHead>
        <DataTable.CellHead width="70">EMAIL</DataTable.CellHead>
        <DataTable.CellHead width="50">
          DUE
        </DataTable.CellHead>
        <DataTable.CellHead width="50">
          DATE
        </DataTable.CellHead> */}
        {props.modify !== 'false' ? <DataTable.CellHead width="100" style={{ textAlign: 'end' }}>
          ACTIONS
        </DataTable.CellHead> : ""}
      </DataTable.Head>
      <DataTable.Body>
        {props?.agentsList?.length > 0 &&
          props?.agentsList?.map((agent, index) => (

            < DataTable.Row id={agent.userId} >
              <DataTable.Cell width="20">{1 + index}</DataTable.Cell>
              {agent && props?.headers?.length > 0 && props?.headers?.map((header) => (
                (<DataTable.Cell width="50">{agent[header] || "-"}</DataTable.Cell>)))
              }
              {props.modify !== 'false' &&
                < DataTable.Cell width="100" style={{ textAlign: 'end' }}>
                  {(agent?.status?.toLowerCase() === 'not_initiated' && props.campaignStatus !== 'COMPLETED') ?
                    <img alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => props.deleteLeadHandler(agent, props.campaignType === 'static')}
                      src={Delete} /> :
                    <img alt=""
                      style={{ opacity: 0.5 }}
                      src={Delete} />
                    // <span style={{ fontSize: "20px", color: 'grey' }} onClick={() => toast.error("can not delete.")}> <RiDeleteBin5Fill /> </span>
                  }
                </DataTable.Cell>
              }
            </DataTable.Row>
          ))
        }
      </DataTable.Body >
    </DataTable.Table >
  );
}

export function CampaignLeads(props) {
  const [campaignData, setCampaignData] = useState({});
  const [campaignId, setCampaignId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [filteredHeaders, setFilteredHeaders] = useState(["name", "number", "secondaryNumber", "email"]);
  const history = useHistory();
  const location = useLocation();
  let headers = useSelector(state => state.recovery.headers);
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('campaignId');
  const modify = searchParams.get('modify');
  const [isHeadersPopUpOpen, setIsHeadersPopUpOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  const updateFilteredHeader = (header, isSelected) => {
    if (isSelected) {
      setFilteredHeaders(headers => [...headers, header])
    } else {
      const unFilteredHeaders = filteredHeaders.filter((headerType) => headerType !== header)
      setFilteredHeaders(unFilteredHeaders);
    }
  }

  const addLeadHandler = (data) => {

    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.campaignId = campaignId;
    data.agentId = data.selectedUser;
    if (campaignData.campaignType === 'static') {
      if (!data.selectedUser) {
        toast.error("Please Select Agent !!! ");
        return;
      }
      const user = users.find((i) => i._id.toString() === data.selectedUser);
      data.agent = user.zvr_email;
    }
    setLoading(true);
    for (const key of headers) {
      if (!data.hasOwnProperty(key)) {
        data[key] = '';
      }
    }
    if (data.selectedUser) {

      delete data["selectedUser"]
    }

    return POST(
      cloudCallCenterUrl + "campaigns/add-contact-to-campaign",
      data
    ).then(async (res) => {
      toast.success("Added Lead Successfully");
      setSelectedUser("");
      await handleFetchCampaignDetails(campaignId);
      setLoading(false);
      return true;
    }).catch((err) => {
      setSelectedUser("");
      toast.error(err?.response?.data?.error?.reason);
      setLoading(false);
      return false;
    });

  }

  const deleteLeadHandler = (data, checkStaticCampaign) => {
    if (modify === 'false') {
      return;
    }
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.campaignId = campaignId;
    delete data['agentName'];

    if (campaignData?.campaignLeads?.length === 1) {
      toast.error("Cannot delete lead as this is last one!!!");
      return;
    }
    setLoading(true);

    return DELETE(
      cloudCallCenterUrl + "campaigns/delete-contact-to-campaign", {},
      data
    ).then(async (res) => {
      toast.success(" Deleted Lead Successfully !!! ");
      await handleFetchCampaignDetails(campaignId);
      setLoading(false);
      return true;
    }).catch((err) => {
      toast.error(err?.response?.data?.error?.reason);
      setLoading(false);
      return false;
    });
  }

  const handleFetchCampaignDetails = async (id) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: id,
    };
    setLoading(true);
    await GET(cloudCallCenterUrl + 'campaign/get-campaign', data)
      .then((res) => {
        setCampaignData(res.data.response);
        setLoading(false);
        props.setHeaders({ headers: res?.data?.response?.headerKeys })
        setCampaignId(id);
      })
      .catch((err) => { console.log(err); setLoading(false); });
  };

  const getAgents = async () => {
    GET(userUrl + `v1/user`, {
      search: '',
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      pageNo: 1,
      pageSize: 100,
    })
      .then((res) => {
        const data = res?.data?.response?.users?.docs;
        if (data) {
          setUsers(
            data.filter(
              (item) => item?.organisation?.is_onboard
            )
          );
        }
      })
      .catch((err) => { console.log(err); setLoading(false); });
  };

  const addAgentName = (agents) => {
    if (agents) {
      for (let agent of [...agents]) {
        const temp = users.filter((user) => user._id.toString() === agent.agentId);
        agent.agentName = temp[0]?.zvr_name || "-";
      }
    }
    return agents;
  }

  useEffect(() => {
    if (id) {
      handleFetchCampaignDetails(id);
      getAgents();
    }
  }, [id]);

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.goBack();
                }
                }
                alt=""
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {campaignData?.campaignName || ''}
              </p>
              {campaignData?.campaignName && modify !== 'false' && campaignData?.campaignStatus !== "COMPLETED" &&
                <><AddLeadToLeadListPopup
                  open={isModalOpen}
                  toggle={toggleModal}
                  addLeadHandler={addLeadHandler}
                  campaignData={campaignData}
                  headers={headers}
                  users={users}
                  campaignLeads={campaignData.campaignLeads}
                  campaignType={campaignData.campaignType}
                  setSelectedUser={setSelectedUser}
                  selectedUser={selectedUser}>
                </AddLeadToLeadListPopup>
                  <DeleteLeadsPopup
                    campaignId={id}
                    handleFetchCampaignDetails={handleFetchCampaignDetails}
                  />
                  <ModifyHeadersPopUp
                    headers={campaignData?.headerKeys}
                    updateFilteredHeader={updateFilteredHeader}
                    filteredHeaders={filteredHeaders}
                    isPopUpOpen={isHeadersPopUpOpen}
                    openHeadersPopUp={() => setIsHeadersPopUpOpen(true)}
                    closeHeadersPopUp={() => setIsHeadersPopUpOpen(false)}
                  />
                </>
              }

            </div>
          </TabSearchBar>
          {loading && <PreLoader />}
          {!loading && <CampaignReportTable modify={modify}
            headers={filteredHeaders}
            users={users}
            deleteLeadHandler={deleteLeadHandler}
            agentsList={addAgentName(campaignData?.campaignLeads)}
            campaignType={campaignData.campaignType}
            campaignStatus={campaignData.campaignStatus}
          />}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
  headers: state => state.recovery.headers,
});

export default commonStyle(
  connect(mapStateToProps, { setHeaders })(CampaignLeads)
);

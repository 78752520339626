import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import whiteLoader from '../../../assets/images/white_preloader_s.gif';
const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
const contentStyle = {
  background: 'transparent',
  border: 'none',
  width: 'auto',
};
class PreLoader extends Component {
  render() {
    return (
      <Popup
        open={true}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        {...{ overlayStyle, contentStyle }}
      >
        <div>
          <img alt="" src={whiteLoader} />
        </div>
      </Popup>
    );
  }
}

export default PreLoader;

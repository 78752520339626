import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import { EditIcon } from '../../../assets/Icons/Icons';
import smsGreen from '../../../assets/images/smsGreen.png';
import styled from '@emotion/styled';
import './styles.css';
import Close from '../../../assets/images/popupClose.png';
import { toast } from 'react-toastify';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import callCommentIcon from '../../../assets/images/callCommentRecoveryX.svg';
const overlayStyle = { background: 'rgba(0,0,0,0.9)' };
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

export default class AddIndividualCallDispositionsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: '',
      comment: '',
      open: false,
      userRole: localStorage.getItem('doosra-biz-user-role'),
    };
    this.initialState = this.state;
  }
  selectTag = (e) => {
    this.setState({
      tag: e.target.value,
    });
  };

  toggle = () => {
    if (this.props.isDisabled) {
      return;
    }
    this.setState({
      open: !this.state.open,
    });
  };

  reset = () => {
    this.setState(this.initialState);
    this.toggle();
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  callTagHandler = (e) => {
    const data = {
      disposition: this.state.tag,
      callComment: this.state.comment,
      callId: this.props.callId,
    };
    if (data.callTag === '') {
      toast.error('Please select a tag');
      return;
    }
    const callTag = this.state.tag;
    const callComment = this.state.comment;
    console.log(data);
    return PUT(cloudCallCenterUrl + 'outbound/update-disposition-outbound-call', {}, data)
      .then((res) => {
        toast.success('Added Disposition Successfully');
        this.toggle();
        this.setState(this.initialState);
        this.props.updateCallDispositonTag();
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        this.setState(this.initialState);
        this.toggle();
        return false;
      });
  };

  render() {
    let userRole = localStorage.getItem('doosra-biz-user-role');
    let popper = (
      <Popup
        open={this.state.open}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        {...{ overlayStyle }}
      >
        <div className="AddCallRemarksPopup">
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>Add Disposition</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.reset}
              />
            </div>
            <div className="PopupBody">
              <div className="PopupInputContainer">
                <select
                  className="PopupInput"
                  id="tags"
                  onChange={this.selectTag}
                >
                  <option value={'-'}>Select Disposition</option>
                  {/* <option value={"Interested"}>Interested</option>
               <option value={"Not Interested"}>Not Interested</option>
               <option value={"Call Later"}>Call Later</option>
               <option value={"Switch Off"}>Switch Off</option>
               <option value={"Wrong Number"}>Wrong Number</option>
               <option value={"Call Busy"}>Call Busy</option>
               <option value={"Call Rejected"}>Call Rejected</option>
               <option value={"Ringing/CNP"}>Ringing/CNP</option> */}

                  {this.props.dispositions.length > 0
                    ? this.props.dispositions.map((item) => (
                      <option value={item.dispositon}>
                        {item.dispositon}
                      </option>
                    ))
                    : null}
                  <option value={'Other'}>Other</option>
                </select>
              </div>
            </div>
            {/* {this.state.tag === 'Other' ? ( */}
            <div className="PopupInputContainer">
              <input
                id="comment"
                value={this.state.comment}
                onChange={this.changeHandler}
                className="PopupInput"
                placeholder="Enter comment ..."
              />
            </div>
            {/* ) : null} */}
            {this.state.open === true ? (
              <button
                className="ButtonFullWidth"
                style={{ marginTop: '10px' }}
                type="submit"
                onClick={this.callTagHandler}
              >
                SAVE
              </button>
            ) : (
              <button
                className="ButtonFullWidth"
                style={{ cursor: 'default', marginTop: '10px' }}
              >
                SAVE
              </button>
            )}
            <button className="ButtonCancelFullWidth" onClick={this.reset}>
              CANCEL
            </button>
          </div>
        </div>
      </Popup>
    );
    return (
      <React.Fragment>
        {/* <button
          onClick={this.openPopup}
          className="BlueButton">
          <FontAwesomeIcon icon={faUser} style={{ fontSize: 17 }} />
        </button> */}

        <div className="tooltip-container">
          {this.props.isDisabled ? (
            <button
              onClick={this.toggle}
              className="callCommentButton tooltip-button"
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                opacity: 0.5,
              }}
            >
              <img
                alt=""
                src={callCommentIcon}
                onClick={this.toggle}
                style={{ width: '16px', height: '16px' }}
              />
            </button>
          ) : (
            <button
              onClick={this.toggle}
              className="callCommentButton tooltip-button"
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                alt=""
                src={callCommentIcon}
                onClick={this.toggle}
                style={{ width: '16px', height: '16px' }}
              />
            </button>
          )}
          <div className="tooltip-text left" data-tip-position="left">
            {this.props.isDisabled
              ? 'Call is already disposed'
              : 'Click to change disposition'}
            <span className="arrow-right"></span>
          </div>
        </div>
        {popper}
      </React.Fragment>
    );
  }
}
import React, { useEffect, useState } from 'react';
import '../../../assets/css/Popups.css';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import TagIcon from '../../../assets/images/tag.png';
import CrossRedCircle from '../../../assets/images/Cross_red_circle.svg';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import InfoRed from '../../../assets/images/infoRed.png';
import { ADD_ANOTHER_TAG_BUTTON, SAVE_TAG_BUTTON, DELETE_TAG_BUTTON } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";

const userUrl = process.env.REACT_APP_USER_API;
const organisationId = localStorage.getItem('doosra-biz-organisation-id');

export default function Tags(props) {
    const contactId = props.contactId;
    const [tagsLoading, setTagsLoading] = useState(false);
    const [tagsList, setTagsList] = useState([]);
    const [showTagInput, setShowTagInput] = useState(props.action == 'ADD' ? true : false);
    const [tagText, setTagText] = useState("");
    const [tagTotal, setTagTotal] = useState(0);
    const [error, setError] = useState(null);

    const fetchTagsList = () => {
        if (contactId && organisationId) {
            setTagsLoading(true);
            let data = {
                contactId,
                organisationId,
                userId: props?.userId,
                pageNum: 1,
                pageSize: 1000,
            };
            GET(userUrl + 'v1/user/tags', data)
                .then((res) => {
                    return res.data.response;
                })
                .then((res) => {
                    setTagsList(res.tags);
                    setTagTotal(res?.tags?.length);
                    setTagsLoading(false);
                })
                .catch((err) => {
                    console.log("Tags: error", { err })
                    setTagsLoading(false);
                });
        }
    };

    const SaveTags = (text) => {
        const tag = text || tagText;
        if (!tag) {
            setError("Please Enter Tag");
        }
        if (tag) {
            const data = {
                contactId,
                organisationId,
                tag
            }
            setTagsLoading(true);
            POST(userUrl + 'v1/user/tag', data)
                .then((res) => {
                    setTagsLoading(false);
                    fetchTagsList();
                    toast.success("Add Tag Successfully");
                })
                .catch((err) => {
                    setTagsLoading(false);
                    toast.error(err?.response?.data?.error?.reason);
                });
            setError(null);
            setShowTagInput(false);
        }

    }

    const deleteTag = (id) => {
        logPostHogEvent(DELETE_TAG_BUTTON);
        if (!id) {
            setShowTagInput(false);
        }
        if (id) {
            setTagsLoading(true);
            setTagsList([]);
            PUT(userUrl + 'v1/user/tag', null, { id })
                .then((res) => {
                    return res?.data?.response;
                }).then((res) => {
                    setTagsLoading(false);
                    fetchTagsList();
                    toast.success("Delete Tag Successfully");
                })
                .catch((err) => {
                    console.log("deleteTag:err", err);
                    setTagsLoading(false);
                    toast.error(err?.response?.data?.error?.reason);
                });
        }
    }

    useEffect(() => {
        fetchTagsList();
    }, []);

    return (
        <div
            className="PopupInputContainer" style={{ marginTop: "50px" }}>
            <label className="PopupInputLabel" >Tags  ({tagTotal})</label>
            {tagsLoading && <PreLoader />}
            <div className={(!showTagInput && tagTotal > 0) ? 'TagOutLay' : ''} style={{ display: (showTagInput) ? 'none' : 'block', marginBottom: "7px" }}>
                {tagsList?.length > 0 &&
                    tagsList.map((item, index) => {
                        return (
                            <div key={index} className='TagsText'>

                                <img
                                    alt="tag"
                                    src={TagIcon}
                                    style={{ width: "15px", height: "15px", marginRight: 7 }}
                                />
                                <span>{item.tag}</span>
                                <img src={CrossRedCircle}
                                    className='TagsCloseBtn'
                                    style={{ display: (props.action == 'VIEW') ? 'none' : 'block' }}
                                    onClick={() => { deleteTag(item._id) }} />
                            </div>
                        )
                    })
                }
            </div>

            <div style={{ position: 'relative' }} >
                {showTagInput && (<>
                    <textarea
                        placeholder='Add a tag like, Sales, Important, Call back.'
                        className="PopupInput"
                        id="tags"
                        value={tagText}
                        onChange={(e) => { setTagText(e.target.value); }}
                    />
                    <img src={CrossRedCircle}
                        className='TagsCloseBtn'
                        style={{ display: (props.action == 'VIEW') ? 'none' : 'block', right: "25px" }}
                        onClick={(e) => deleteTag()} />
                </>)}
                <div style={{ display: 'flex' }}>
                    <div className='ContactAddBtn' style={{ display: (props.action == 'VIEW') ? 'none' : 'block' }}
                        onClick={() => {
                            logPostHogEvent(ADD_ANOTHER_TAG_BUTTON);
                            setShowTagInput(true);
                            setTagText('');
                        }}> +Add another tag </div>
                    <div className='ContactSaveBtn'
                        onClick={(e) => {
                            logPostHogEvent(SAVE_TAG_BUTTON);
                            SaveTags();
                        }}
                        style={{ display: !showTagInput ? 'none' : 'block' }}>Save Tag</div>
                </div>

            </div>

            <div>
                {error ? (
                    <div className="ErrorContainer ErrorContainerMargin">
                        <img
                            alt=""
                            src={InfoRed}
                            style={{
                                paddingRight: '5px',
                                position: 'relative',
                                top: '-2px',
                            }}
                        />
                        {error}
                    </div>
                ) : null}

            </div>
        </div>
    );
};



import React, { useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { toast } from 'react-toastify';
import {
  ADMIN_ADD_AUTO_ATTENDANT_BUTTON,
  ADMIN_SAVE_AUTO_ATTENDANT_BUTTON,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';

export default function AddAutoAttendantPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [autoAttendName, setAutoAttendantName] = useState('');
  const [audioDescription, setAutoAttendantDescription] = useState('');
  const [audioFile, setAudioFile] = useState(null);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const addAutoAttendantHandler = () => {
    logPostHogEvent(ADMIN_SAVE_AUTO_ATTENDANT_BUTTON);
    if (processing) return;
    if (!autoAttendName) return toast.error('Provide auto attendant name');
    if (!audioFile) return toast.error('Provide auto attendant audio file');
    setProcessing(true);
    const formData = new FormData();
    formData.append('name', autoAttendName);
    if (audioDescription !== '') {
      formData.append('description', audioDescription);
    }
    formData.append('audio', audioFile);
    formData.append(
      'organisationId',
      localStorage.getItem('doosra-biz-organisation-id')
    );

    props
      .addAutoAttendantHandler(formData)
      .then((res) => {
        if (res === true) {
          toast.success('Auto Attendant Successfully created');
          setAutoAttendantName('');
          setAutoAttendantDescription('');
          setAudioFile(null);
          setPopupOpen(false);
          setProcessing(false);
        }
      })
      .catch((err) => {
        setProcessing(false);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    let size = 1;
    if (
      localStorage.getItem('doosra-biz-organisation-id') ===
        'd9c67ec2-fe1d-4284-89e6-acb173c55229' ||
      localStorage.getItem('doosra-biz-organisation-id') ===
        '259150fd-6c5a-4886-9eb7-901690b9f024'
    ) {
      size = 2;
    }

    if (file && file.size <= size * 1024 * 1024) {
      setAudioFile(file);
      setError(null);
    } else {
      setAudioFile(null);
      setError('Please upload an audio file within the size limit (1 MB).');
    }
  };

  const closePopup = () => {
    if (processing) return;
    setPopupOpen(false);
    setAutoAttendantName('');
    setAutoAttendantDescription('');
    setAudioFile(null);
  };

  const popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="PopupContainer">
        <div className="PopupHeaderContainer">
          <p>Add Auto Attendant</p>
          <img alt="" className="PopupClose" src={Close} onClick={closePopup} />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Auto Attendant Name</label>
            <input
              className="PopupInput"
              id="emp_id"
              value={autoAttendName}
              onChange={(e) => setAutoAttendantName(e.target.value)}
            />
          </div>
          <div
            className="PopupInputContainer"
            style={{ height: '180px', overflow: 'auto' }}
          >
            <label className="PopupInputLabel">Description</label>
            <input
              className="PopupInput"
              id="emp_id"
              value={audioDescription}
              onChange={(e) => setAutoAttendantDescription(e.target.value)}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">WAV File (Max 1 MB)</label>
            <input type="file" accept="audio/wav" onChange={handleFileChange} />
          </div>
          <div className="ErrorContainer ErrorContainerMargin">
            {error && (
              <React.Fragment>
                <img
                  alt=""
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',

                    top: '-2px',
                  }}
                />
                {error}
              </React.Fragment>
            )}
          </div>
          <button
            className="ButtonFullWidth"
            style={{ marginTop: '10px' }}
            type="submit"
            onClick={addAutoAttendantHandler}
            disabled={processing}
          >
            {processing ? 'SAVING...' : 'SAVE'}
          </button>
          <button className="ButtonCancelFullWidth" onClick={closePopup}>
            CANCEL
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex' }}>
      <span>
        <button
          className="Otherbutton"
          style={{ marginRight: 0, width: 170 }}
          onClick={() => {
            logPostHogEvent(ADMIN_ADD_AUTO_ATTENDANT_BUTTON);
            setPopupOpen(true);
          }}
        >
          ADD AUTO ATTENDANT
        </button>
        {popper}
      </span>
    </div>
  );
}

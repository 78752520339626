import { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { toast } from 'react-toastify';
import { audioFileMaxSize } from '../../../common/constants';

class UploadPopup extends Component {
  constructor() {
    super();
    this.state = {
      audio: null,
    };
  }

  closePopup = () => {
    this.props.closePopup();
  };

  fileUploadHandler = (e) => {
    let audio = e.target.files;
    for (let i = 0; i <= audio.length - 1; i++) {
      audio = audio[i];
    }
    if (audio.type !== 'audio/wav') {
      return toast.error('use WAV format only');
    }
    if (audio.size > audioFileMaxSize) {
      return toast.error('use file size less then 500kB');
    }
    if (audio) this.setState({ audio });
  };

  handleFileUpload = () => {
    let formData = new FormData();
    formData.append('audio', this.state.audio);
    this.props.handelUploadedFile(formData);
  };

  render() {
    return (
      <Popup
        open={this.props.uploadPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Add Callertune</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupBody">
            <div style={{ margin: '10px 10px 10px 30px' }}>
              <input
                id="audio"
                type="file"
                name="audio"
                accept="audio/wav"
                style={{ marginTop: '20px', marginLeft: '20px' }}
                onChange={this.fileUploadHandler}
              />
              <p
                style={{
                  color: '#818a91',
                  fontSize: '14px',
                  marginTop: '3px',
                  marginLeft: '19px',
                }}
              >
                {' '}
                WAV supported <br />
                Max size - 500kb, Max audio duration 20s
              </p>
            </div>
            <button
              className="uploadAudio"
              style={{
                backgroundColor: this.state.audio ? '#196cca' : '#d2d3d4',
              }}
              onClick={() => this.handleFileUpload()}
            >
              UPLOAD
            </button>
          </div>
        </div>
      </Popup>
    );
  }
}

export default UploadPopup;

import React, { Component } from 'react';
import '../../../assets/css/SearchBar.css';
import styled from '@emotion/styled';
import PopTwo from '../../../pages/recoveryX/component/AddDND';
import AddBlackList from '../../../pages/cloudCallCenter/component/AddBlackList';
import ExportRecoveryAgents from '../../../pages/recoveryX/component/ExportRecoveryAgents';
import { Link } from 'react-router-dom';

class TabSearchBar extends Component {
  constructor() {
    super();
    this.state = {};
    this.initialState = this.state;
  }

  render() {
    const { tabs, className, children } = this.props;
    return (
      <div className={className} key={Math.random()}>
        <div className="Tabs">
          {tabs?.map(({ title, subtitle, onClick, active }) => (
            <div
              key={title}
              onClick={() => onClick(title)}
              className={'Tab ' + (active ? 'active' : '')}
            >
              <h6>{title}</h6>
              <p>
                {typeof subtitle === 'function'
                  ? subtitle()
                  : typeof subtitle === 'string'
                    ? subtitle
                    : null}
              </p>
              {active && <div className="TabIndicator" />}
            </div>
          ))}
        </div>
        {children}
        {this.props.type === 'agentList' && (
          <div style={{ display: 'flex' }}>
            <ExportRecoveryAgents
              agentList={this.props.agentList}
              fromDate={this.props.fromDate}
              toDate={this.props.toDate}
            />
          </div>
        )}
        {this.props.type === 'dndList' && (
          <div style={{ display: 'flex' }}>
            <PopTwo refreshList={this.props.refreshList} />
          </div>
        )}
        {this.props.type === 'BlackList' && (
          <div style={{ display: 'flex' }}>
            <AddBlackList refreshList={this.props.refreshList} />
          </div>
        )}
        {
          localStorage.getItem('doosra-biz-user-role') === "ADMIN" && this.props.type === "campaigns" && (
            <div>
              <Link to="/create">
                <button 
                className="Rectangle-9041"
                >ADD CAMPAIGN</button>
              </Link>
          </div>
          )
        }
      </div>
    );
  }
}

export default styled(TabSearchBar)`
  height: 54px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  flex-direction: row;

  .Tabs {
    display: flex;
    flex-direction: row;
  }

  .Tab {
    cursor: pointer;
    position: relative;
    min-width: 136px;
    height: 54px;
    padding: 13px 16px;
    background-color: ${(props) => props.theme.colors.tabColor};
    display: flex;
    flex-direction: column;
    justify-content: center;

    h6 {
      margin: 0;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #939598;

      font-size: 12px;
      font-weight: bold;
      color: ${(props) => props.theme.colors.tabTextColor};
    }

    p {
      font-size: 11px;
      font-weight: bold;
      margin: 0;
      letter-spacing: 1.2px;
      color: ${(props) => props.theme.colors.tabTextColor};
      margin: 0;
      opacity: 0.8;
      font-family: Montserrat;
      font-weight: 600;
      line-height: 1.4;
      text-align: left;
    }

    &.active {
      background-color: ${(props) => props.theme.colors.tabSelectedColor};

      h6,
      p {
        color: ${(props) => props.theme.colors.tabSelectedTextColor};
      }
    }
  }

  .TabIndicator {
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.colors.tabSelectedTextColor};
  }
`;

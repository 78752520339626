import React from 'react';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';
// import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import TabSearchBarComponent from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import FormComponent from '../components/FormComponent';
import { Redirect } from 'react-router-dom';
import UpgradeServices from '../../../common/component/upgrade-services/UpgradeServices';

const CallWebhookPage = (props) => {
  const userRole = localStorage.getItem('doosra-biz-user-role');
  const isSMSApiAvailable = localStorage.getItem('is_sms_webhook_enabled');
  const isCallApiAvailable = localStorage.getItem('is_call_webhook_enabled');
  const isOutgoingCallApiAvailable = localStorage.getItem(
    'is_outgoing_call_webhook_enabled'
  );
  const isVoiceApiAvailable = localStorage.getItem(
    'is_voice_api_webhook_enabled'
  );
  const isFreeFormEnabled = localStorage.getItem(
    'is_free_form_enabled'
  );
  const isNumberMaskingEnabled = localStorage.getItem('is_number_masking_enabled');
  const tabs = [];
  // if (userRole === 'ADMIN' && isSMSApiAvailable === 'true') {
  //   tabs.push({
  //     title: 'SMS',
  //     subtitle: 'webhook',
  //     onClick: () => props.history.push('/sms-webhook'),
  //   });
  // }
  if (userRole === 'ADMIN' && isCallApiAvailable === 'true') {
    tabs.push({
      title: 'INCOMING CALL',
      subtitle: 'webhook',
      active: true,
    });
  }
  if (userRole === 'ADMIN' && isOutgoingCallApiAvailable === 'true') {
    tabs.push({
      title: 'OUTGOING CALL',
      subtitle: 'webhook',
      onClick: () => props.history.push('/outgoing-call-webhook'),
    });
  }
  if (userRole === 'ADMIN' && isVoiceApiAvailable === 'true') {
    tabs.push({
      title: 'VOICE API',
      subtitle: 'webhook',
      onClick: () => props.history.push('/voice-api-webhook'),
    });
  }
  if (userRole === 'ADMIN' && isNumberMaskingEnabled === 'true' && isFreeFormEnabled === 'true') {
    tabs.push({
      title: 'NUMBER MASKING',
      subtitle: 'webhooks',
      onClick: () => props.history.push('/number-masking-api-webhook'),
    });
  }

  const formProps = {
    title: 'CallWebHook',
    webhook_type: ['CALL'],
    access_key: props.accessKeyForCallWebhook,
  };

  if (isSMSApiAvailable === 'false' && isCallApiAvailable === 'false') {
    return (
      <div className={`${props.className}`}>
        <Sidebar />
        <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />
          <div className="ContentContainer">
            <UpgradeServices
              icon="webhooks"
              heading="WebHooks"
              text="A webhook enables Alohaa to push real-time SMS and Call details to your app.
                  You can use these responses to execute actions in your backend systems"
              emailSent={props.isWebhookEmailSent}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBarComponent tabs={tabs} />
          <FormComponent {...formProps} />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  accessKeyForCallWebhook: state.sidebar.accessKeyForCallWebhook,
  isWebhookEmailSent: state.topbar.isWebhookEmailSent,
});
export default commonStyle(connect(mapStateToProps, {})(CallWebhookPage));

/* eslint-disable no-dupe-keys */
import adapter from 'webrtc-adapter';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect, useSelector } from 'react-redux';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import { useEffect, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
import AddCallRemarksPopup from '../component/AddCallRemarksPopup';
import phoneWhite from '../../../assets/images/phoneWhiteRecoveryCall.svg';
import BatchCallPopup from '../component/BatchCallPopup';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import { useSocket } from '../../../providers/SocketProvider';
import SideModal from '../../recoveryCalling/component/SideModal';
import DataCard from '../component/DataCard';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import DataTable from '../../../common/component/table/DataTable';
import CallHistoryPopup from '../component/CallHistoryPopup';
import EditLeadPopup from '../component/EditLeadPopup';
import JsSIP from 'jssip';
import {
  DEV_INHOUSE_PBX_SERVERS,
  PROD_INHOUSE_PBX_SERVERS,
  TURN_SERVER_CONFIG,
} from '../../../assets/constants';
import WebRTCCallPopup from '../component/WebRTCCallPopup';
import {
  setCallDetails,
  setBatchCallId,
  setCallId,
  setCallStatus,
  toggleAutoCalling,
  setAutoCalling,
  setSingleCallStatus,
  setDestinationName,
  setDestinationNumber,
  setRTCSession,
  setCallFilterLeads,
  setRTCCallStatus,
  setCampaignId,
} from '../../../actions/recoveryXActions';
import moment from 'moment';
import PreLoader from '../../../common/component/preLoader/PreLoader';
JsSIP.debug.enable('JsSIP:*');
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
const webRTCOutGoingUrl = process.env.REACT_APP_WEBRTC_RECOVERY_API_URL;
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

export function RecoveryXAgentStaticCampaignView(props) {
  const [campaignData, setCampaignData] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [callOptions, setCallOptions] = useState(false);
  const [callOptionNumber, setCallOptionNumber] = useState(null);
  const [callOptionSecondaryNumber, setCallOptionSecondaryNumber] =
    useState(null);
  const [dispositions, setDispositions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSearchKey, setDataSearchKey] = useState('');
  const [dataSearchValue, setDataSearchValue] = useState('');
  const [resumeBatchCallId, setResumeBatchCallId] = useState('');
  const [callOptionDetails, setCallOptionDetails] = useState([]);
  const [isCallOnHold, setIsCallOnHold] = useState(true);
  const history = useHistory();
  const location = useLocation();
  var audio = new Audio();
  const searchParams = new URLSearchParams(location.search);
  const agentId = searchParams.get('agentId');
  const campaignId = searchParams.get('campaignId');
  let socket = useSocket();
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const [WebRTCEnabled, SetWebRTCEnabled] = useState(
    JSON.parse(localStorage.getItem('is_webrtc_enabled'))
  );
  let {
    userDetails,
    callStatus,
    position,
    autoCalling,
    batchCallId,
    callId,
    // singleCallStatus,
    // RTCSession,
    // destinationName,
    // destinationNumber,
    // callFilterLeads
  } = props;
  let singleCallStatus = useSelector(
    (state) => state.recovery.singleCallStatus
  );
  let callFilterLeads = useSelector((state) => state.recovery.callFilterLeads);
  let destinationName = useSelector((state) => state.recovery.destinationName);
  let destinationNumber = useSelector(
    (state) => state.recovery.destinationNumber
  );
  let RTCSession = useSelector((state) => state.recovery.RTCSession);
  let RTCcallStatus = useSelector((state) => state.recovery.RTCcallStatus);
  let campaignUniqueId = useSelector((state) => state.recovery.campaignId);
  const [headers, setHeaders] = useState([]);

  // const filterCampaignCallsData = (calls, leads) => {
  //   const combinedData = leads.map((obj1) => {
  //     const matchingObject = calls.find((obj2) => (obj1.number === obj2.destinationNumber) || (obj1.secondaryNumber === obj2.destinationNumber));
  //     if (matchingObject) {
  //       return { ...obj1, ...matchingObject };
  //     } else {
  //       return obj1;
  //     }
  //   });
  //   console.log(calls, leads, combinedData);
  //   setTableData(combinedData);
  //   props.setCallFilterLeads({ callFilterLeads: combinedData })
  // }

  const handleFetchCampaignDetails = (
    agentId,
    campaignId,
    searchKey = null,
    searchValue = null,
    value,
    pageSize = 10
  ) => {
    console.log('the values are', dataSearchKey, dataSearchValue);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
      agentId,
      pageNo: value ? (value === 1 ? 0 : value - 1) : currentPage,
      pageSize,
    };
    if (searchKey && searchValue) {
      data.searchKey = searchKey;
      data.searchValue = searchValue;
    }
    if (dataSearchKey && dataSearchValue) {
      data.searchKey = dataSearchKey;
      data.searchValue = dataSearchValue;
    }
    GET(outGoingUrl + 'v1/campaigns/get-static-campaign-leads', data)
      .then((res) => {
        setCampaignData(res.data.response);
        props.setCallFilterLeads({ callFilterLeads: res.data.response.leads });
        setResumeBatchCallId(res.data.response.batchCallId);
        setCurrentPage(res.data.response.pageNo);
        setTotalPages(res.data.response.totalPages);
        setHeaders(res.data.response.headerKeys);
      })
      .catch((err) => console.log(err));
    // GET(outGoingUrl + 'v1/organisations/outbound-call/get-agent-campaign-calls', data)
    //   .then((res) => {
    //     setCampaignData(res.data.response);
    //     setCampaignCallsData(res.data.response.agentCalls);
    //     setResumeBatchCallId(res.data.response.batchCallId)
    //     setCampaignLeads(res.data.response?.leads);
    //     filterCampaignCallsData(res.data.response.agentCalls, res.data.response.leads);
    //   })
    //   .catch((err) => console.log(err));
  };

  const setPaginationSize = (e) => {
    setPageSize(e.target.value);
    handleFetchCampaignDetails(
      agentId,
      campaignId,
      null,
      null,
      1,
      e.target.value
    );
  };

  const resetHandleFetchCampaignDetails = (agentId, campaignId, value) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
      agentId,
      pageNo: value ? (value === 1 ? 0 : value - 1) : currentPage,
      pageSize: pageSize,
    };
    GET(outGoingUrl + 'v1/campaigns/get-static-campaign-leads', data)
      .then((res) => {
        setCampaignData(res.data.response);
        props.setCallFilterLeads({ callFilterLeads: res.data.response.leads });
        setCurrentPage(res.data.response.pageNo);
        setTotalPages(res.data.response.totalPages);
        setResumeBatchCallId(res.data.response.batchCallId);
      })
      .catch((err) => console.log(err));
    // GET(outGoingUrl + 'v1/organisations/outbound-call/get-agent-campaign-calls', data)
    //   .then((res) => {
    //     setCampaignData(res.data.response);
    //     // setCampaignCallsData(res.data.response.agentCalls);
    //     setResumeBatchCallId(res.data.response.batchCallId)
    //     setCampaignLeads(res.data.response?.leads);
    //     //filterCampaignCallsData(res.data.response.agentCalls, res.data.response.leads);
    //   })
    //   .catch((err) => console.log(err));
  };

  const tempFunction = (destinationNumber, status, duration) => {
    const tempLeadsData = callFilterLeads.map((lead) => {
      if (
        lead.number === destinationNumber ||
        lead.secondaryNumber === destinationNumber
      ) {
        lead.status = status;
        lead.duration = duration;
        lead.callTag = (status === "not_answered") ? "RNR" : "";
      }
      return lead;
    });
    campaignData.leads = tempLeadsData;
    setCampaignData(campaignData);
  };

  useEffect(() => {
    if (agentId && campaignId) {
      handleFetchCampaignDetails(agentId, campaignId);
      getDispositions();
    }
  }, [agentId, campaignId]);

  /* START OF WEBRTC CALLS RELATED CODE */

  const [UAList, setUAList] = useState([]);
  const [isWebSocketConnected, setIsWebSocketConnected] = useState(false);
  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(false);
  const INHOUSE_PBX_SERVERS =
    process.env.REACT_APP_ENV === 'DEV'
      ? DEV_INHOUSE_PBX_SERVERS
      : PROD_INHOUSE_PBX_SERVERS;

  var audio = new Audio();
  const sip_details = JSON.parse(localStorage.getItem('sip_details'));
  const username = sip_details?.username;
  const password = sip_details?.password;

  useEffect(() => {
    if (socket) {
      socket.on('test', (message) => {
        console.log('RECEIVED THE TEST MESSAGE', message);
        props.setCallDetails(message);
        props.setDestinationName({ destinationName: message.userDetails });
        props.setDestinationNumber({ destinationNumber: message.position });
      });
      socket.on('changeStatus', (message) => {
        props.setCallStatus({ status: message });
        props.setRTCCallStatus({ RTCcallStatus: message });
        // if(WebRTCEnabled) {
        //   if(message === "Connected") {
        //      props.setRTCCallStatus({RTCcallStatus:"Connected"})
        //   } else { props.setRTCCallStatus({RTCcallStatus:"Automated calling paused for 30 sec"})}
        // }
      });
      socket.on('setBatchCallId', (message) => {
        props.setBatchCallId(message);
        props.setCampaignId(message);
      });
      socket.on('toggleButton', () => {
        props.toggleAutoCalling({ autoCalling: false });
      });
      socket.on('callId', (message) => {
        props.setCallId({ callId: message });
      });
      socket.on('changeSingleCallStatus', (message) => {
        props.setSingleCallStatus({ singleCallStatus: false });
      });
      socket.on('changeSingleCallDetails', (message) => {
        const { destinationNumber, status, duration } = message;
        tempFunction(destinationNumber, status, duration);
      });
    }
  }, [socket, callFilterLeads]);

  // Register a user agent to Asterisk server. UseEffet makes the code run only once.
  useEffect(() => {
    if (WebRTCEnabled && navigator.onLine) {
      createWebsocketConnection();
    }

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      stopUA();
    };
  }, [WebRTCEnabled]);

  const createWebsocketConnection = async () => {
    GET(cloudCallCenterUrl + 'hosted-pbx-domains')
      .then((res) => {
        if (res?.data?.response?.length > 0) {
          const pbxServerObjs = res?.data?.response;
          let activePbxServersList = pbxServerObjs.filter(
            (pbxObj) => pbxObj.isActive
          );
          activePbxServersList = activePbxServersList.map(
            (pbxObj) => pbxObj.hostedDomain
          );
          console.log('______', activePbxServersList);

          const activeServersToConnect = INHOUSE_PBX_SERVERS.filter((server) =>
            activePbxServersList.includes(server)
          );

          const uaList = activeServersToConnect.map((server) => {
            const socket = new JsSIP.WebSocketInterface(`wss://${server}/ws`);
            const uaConfig = {
              uri: `sip:${username}@${server}`,
              password: password,
              sockets: [socket],
              connection_recovery_min_interval: 5,
            };
            const ua = new JsSIP.UA(uaConfig);
            ua.start();
            return { pbx: server, ua: ua, maxReconnects: 5 };
          });
          setUAList(uaList);
          return uaList;
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  // const reconnectWebsocket = (socketToReconnect) => {
  //   if (navigator.onLine && !isWebSocketConnected) {
  //     const domainName = getDomainName(socketToReconnect);
  //     const socket = new JsSIP.WebSocketInterface(socketToReconnect);
  //     const uaConfig = {
  //       uri: `sip:${username}@${domainName}`,
  //       password: password,
  //       sockets: [socket],
  //       connection_recovery_min_interval: 5
  //     };
  //     const ua = new JsSIP.UA(uaConfig);
  //     ua.start();
  //     if (ua) {
  //       const newUaList = []
  //       let reconnectsLeft
  //       UAList.forEach((uaObj) => {
  //         if (domainName !== uaObj.pbx) {
  //           newUaList.push(uaObj)
  //         }
  //         else {
  //           reconnectsLeft = uaObj.maxReconnects
  //         }
  //       })
  //       reconnectsLeft = reconnectsLeft - 1
  //       newUaList.push({ pbx: domainName, ua: ua, maxReconnects: reconnectsLeft })
  //       setUAList(newUaList);
  //     }
  //   }
  // }

  const getDomainName = (socketUrl) => {
    const startIndex = socketUrl.indexOf('//') + 2;
    const endIndex = socketUrl.indexOf('/', startIndex);
    const domainName = socketUrl.substring(startIndex, endIndex);
    return domainName;
  };

  const stopUA = () => {
    UAList.forEach(({ ua }) => {
      ua.stop();
    });
  };

  const handleOffline = () => {
    toast.error('Network disconnected. Please check internet connection', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    setIsWebSocketConnected(false);
  };

  const handleOnline = () => {
    toast.success('Network is up. Please refresh to reconnect to server', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  // Reload  conformation
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    if (WebRTCEnabled) {
      props.setBatchCallId('');
      props.setSingleCallStatus(false);
      props.setAutoCalling(false);
      props.setDestinationName('');
      props.setDestinationNumber('');
      props.setCallId('');
      props.setRTCSession({ RTCSession: null });
      props.setRTCCallStatus({ RTCcallStatus: '' });
    }
  };

  // const options = {
  //   mediaConstraints: { audio: true, video: false },
  //   pcConfig: {
  //     iceServers: [
  //       {
  //         urls: ["stun:stun.l.google.com:19302"],
  //         urls: ["stun:stun.ekiga.net"]
  //       },
  //       TURN_SERVER_CONFIG
  //     ]
  //   }
  // };

  const options = {
    mediaConstraints: { audio: true, video: false },
    pcConfig: {},
  };

  const sendSocketConnectionStatus = (socket, type) => {
    if (userRole === 'USER') {
      var data = JSON.stringify({
        userId: localStorage.getItem('doosra-biz-user-id'),
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        webSocket: socket,
        eventType: type,
      });
      return PUT(
        outGoingUrl + 'v1/organisations/outbound-call/update-user-websockets',
        {},
        data
      )
        .then((res) => {
          const activeWebsockets = res.data.response.webSockets;
          if (activeWebsockets.length > 0) {
            setIsWebSocketConnected(true);
          }
          if (activeWebsockets.length === 0) {
            setIsWebSocketConnected(false);
            toast.error(
              'Not connected to server. Please check internet connection and refresh to continue',
              { position: toast.POSITION.BOTTOM_RIGHT }
            );
          }
          return true;
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          return false;
        });
    }
  };

  useEffect(() => {
    UAList.forEach(({ ua: UA, maxReconnects }) => {
      UA.on('disconnected', (e) => {
        UA.unregister({ all: true });
        const disconnectedSocket = e.socket._url;
        const domainName = getDomainName(disconnectedSocket);
        sendSocketConnectionStatus(domainName, 'disconnected');
      });
      UA.on('connected', (e) => {
        const connectedSocket = e.socket.socket._url;
        const domainName = getDomainName(connectedSocket);
        setTimeout(() => {
          sendSocketConnectionStatus(domainName, 'connected');
        }, 1000);
      });
      UA.on('registered', (e) => {
        console.log('user registered successfully');
        setIsAuthenticatedUser(true);
      });
      UA.on('registrationFailed', (e) => {
        console.log('user registration failed');
        setIsAuthenticatedUser(false);
      });
      UA.on('unregistered', () => {
        console.log('user unregistration event');
      });
      // Incoming call Event Auto Answer
      UA.on('newRTCSession', (e) => {
        const newSession = e.session;
        /* START ICE CONFIG */
        let iceServers = [
          {
            urls: ['stun:stun.l.google.com:19302'],
            urls: ['stun:stun.ekiga.net'],
          },
        ];
        const pbx = newSession._ua._configuration.hostport_params;
        iceServers.push(TURN_SERVER_CONFIG[pbx]);
        options.pcConfig['iceServers'] = iceServers;
        newSession.on('icecandidate', (candidate) => {
          console.log('iceCandidate gathered - ', candidate);
        });
        /* END ICE CONFIG */
        props.setCallId({ callId: e.request.headers.Uniqueid[0].raw });
        if (newSession.direction === 'incoming' && newSession.status === 4) {
          props.setRTCSession({ RTCSession: newSession });
          setIsCallOnHold(false);
          props.setRTCCallStatus({ RTCcallStatus: 'connecting' });
          props.setSingleCallStatus({ singleCallStatus: true });
          newSession.on('accepted', function (event) {
            setIsCallOnHold(true);
            console.log(' Call Accepted ');
          });

          newSession.on('confirmed', function (event) {
            if (event.originator === 'remote') {
            }
            props.setRTCCallStatus({ RTCcallStatus: 'Ringing' });
          });

          // Call failed event
          newSession.on('failed', (event) => {
            console.log(' Call failed ');
            resetValues();
          });

          // Call hungup event
          newSession.on('ended', (event) => {
            console.log(' Call hangup ');
            resetValues();
          });

          // Answer Call
          if (newSession.status === 4) {
            newSession.answer(options);
            console.log('iceConnectionState - ', newSession);
          }

          // Audio connection
          newSession.connection.addEventListener('addstream', (e) => {
            audio.srcObject = e.stream;
            audio.play();
          });
        }
      });
    });
  }, [UAList]);
  // Reseting values after call ends or fails
  // const resetValues = () => {
  //   props.setRTCCallStatus({ RTCcallStatus: "Call Connecting" })
  //   props.setSingleCallStatus({ singleCallStatus: false });
  //   localStorage.setItem("singleCallStatus", false);
  //   props.setRTCSession({ RTCSession: null });
  //   props.setDestinationNumber({ destinationNumber: "" });
  //   props.setDestinationName({ destinationName: "" });
  //   audio.remove();
  //   props.setCallId({ callId: "" })
  // }

  // Mute the call
  const muteCall = () => {
    if (!RTCSession) {
      toast.error('No active calls');
      return;
    }
    RTCSession.mute();
  };

  // UnMute the call
  const unMuteCall = () => {
    if (!RTCSession) {
      toast.error('No active calls');
      return;
    }
    RTCSession.unmute();
  };

  // Hold the call
  const holdCall = () => {
    if (!RTCSession) {
      toast.error('No active calls');
      return;
    }
    RTCSession.hold();
    props.setRTCCallStatus({ RTCcallStatus: 'call-on-hold' });
  };

  // Resume the call
  const resumeCall = () => {
    if (!RTCSession) {
      toast.error('No active calls');
      return;
    }
    RTCSession.unhold();
    props.setRTCCallStatus({ RTCcallStatus: 'connected' });
  };

  // End the call
  const endCall = () => {
    console.log('in the end call');
    if (!RTCSession) {
      resetValues();
      return;
    }
    RTCSession.terminate();
    resetValues();
  };

  /* END OF WEBRTC CALLS RELATED CODE */

  const initiateRecoveryCall = (
    destinationNumber,
    name,
    campaignId,
    callId
  ) => {
    if (callId) {
      toast.error('There is an Active call');
      return;
    }
    if (localStorage.getItem('doosra-biz-user-number') === destinationNumber) {
      toast.error('Cannot Call To The Same Number');
      return;
    }
    console.log('props is ', name, destinationNumber);
    let data = {
      number: destinationNumber,
      name,
      campaignId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      isRecoveryCall: true,
      isIndividualCall: true,
    };
    console.log('data is ', data);
    props.setSingleCallStatus({ singleCallStatus: true });
    POST(outGoingUrl + 'v1/campaigns/recovery', data)
      .then((res) => {
        setCallOptions(false);
        console.log('setting the single call statsus to true');
        return true;
      })
      .catch((err) => {
        setCallOptions(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const initiateWebRTCRecoveryCall = (number, name) => {
    if (!WebRTCEnabled) {
      toast.error("Webrtc Is Not Enabled For The Agent");
      return;
    }

    if (!isWebSocketConnected) {
      toast.error('Not connected to server');
      return;
    }
    if (!isAuthenticatedUser) {
      toast.error('User Registration Failed. Please Refresh the page');
      return;
    }
    if (callId || singleCallStatus || autoCalling) {
      toast.error('There is an Active call');
      return;
    }
    if (localStorage.getItem('doosra-biz-user-number') === number) {
      toast.error('Cannot Call To The Same Number');
      return;
    }
    setSingleCallStatus(true);
    localStorage.setItem('singleCallStatus', true);
    props.setSingleCallStatus({ singleCallStatus: true });
    let data = {
      number,
      name,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      isRecoveryCall: true,
      campaignId,
    };
    console.log('tge data for the single call is', data);
    POST(webRTCOutGoingUrl + 'v1/campaigns/recovery', data)
      .then((res) => {
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const addSecondaryNumber = (e) => {
    setLoading(true);
    const body = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
      number: e.primaryNumber,
      action: 'UPDATE_SECONDARY_NUMBER',
      data: e.number,
    };
    PUT(webRTCOutGoingUrl + 'v1/campaigns/update-lead', {}, body)
      .then((res) => {
        handleFetchCampaignDetails(agentId, campaignId);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  function CampaignReportTable(props) {
    const getStatus = (status) => {
      let res = '';
      if (status) {
        if (status === 'not_answered') {
          res = 'Not Answered';
        }
        if (status === 'answered') {
          res = 'Answered';
        }
      }
      return res;
    };
    const onClickCall = (agent) => {
      if (props.isValidCampaign && props.isActive) {
        if (agent?.secondaryNumber) {
          setCallOptions(true);
          setCallOptionNumber(agent?.number);
          setCallOptionSecondaryNumber(agent?.secondaryNumber);
          setCallOptionDetails([agent?.name, props.campaignId, props.callId]);
        } else {
          initiateRecoveryCall(
            agent?.number,
            agent?.name,
            props.campaignId,
            props.callId
          );
        }
      }
    };
    return (
      <div>
        <DataTable.Table style={{ width: '100%' }}>
          <DataTable.Head>
            <DataTable.CellHead width="20">#</DataTable.CellHead>
            {props.headers &&
              props.headers.map((header) => (
                <DataTable.CellHead width="60px">
                  {header.toUpperCase()}
                </DataTable.CellHead>
              ))}
            {/* <DataTable.CellHead width="60px">LEAD NAME</DataTable.CellHead>
            <DataTable.CellHead width="60px">LEAD NUMBER</DataTable.CellHead>
            <DataTable.CellHead width="60px">LEAD SECONDARY NUMBER</DataTable.CellHead>
            <DataTable.CellHead width="60px">DUE</DataTable.CellHead> */}
            <DataTable.CellHead width="90px">
              LAST CALL STATUS
            </DataTable.CellHead>
            <DataTable.CellHead width="80">
              LAST CALL DURATION (s)
            </DataTable.CellHead>
            <DataTable.CellHead width="60">CALL DATE</DataTable.CellHead>
            <DataTable.CellHead width="60">CALL TIME</DataTable.CellHead>
            <DataTable.CellHead width="60">DISPOSITION</DataTable.CellHead>
            <DataTable.CellHead width="80">
              DISPOSITION COMMENT
            </DataTable.CellHead>
            <DataTable.CellHead width="50" style={{ textAlign: 'end' }}>
              HISTORY
            </DataTable.CellHead>
            <DataTable.CellHead width="50" style={{ textAlign: 'end' }}>
              ACTIONS
            </DataTable.CellHead>
          </DataTable.Head>
          <DataTable.Body>
            {props?.agentsList?.length > 0 &&
              props?.agentsList?.map((agent, index) => (
                <DataTable.Row id={agent.userId}>
                  <DataTable.Cell>
                    {props.pageNo * props.pageSize + 1 + index}
                  </DataTable.Cell>
                  {props.headers &&
                    props.headers.map((header) => (
                      <DataTable.Cell width="60px">
                        {agent[header]}
                      </DataTable.Cell>
                    ))}
                  {/* <DataTable.Cell>{agent?.name}</DataTable.Cell>
                  <DataTable.Cell>{agent?.number}</DataTable.Cell>
                  <DataTable.Cell>{agent?.secondaryNumber}</DataTable.Cell>
                  <DataTable.Cell>{agent?.due}</DataTable.Cell> */}
                  <DataTable.Cell>
                    {getStatus(agent?.status) || '-'}
                  </DataTable.Cell>
                  <DataTable.Cell>{agent?.duration || '-'}</DataTable.Cell>
                  <DataTable.Cell>
                    {agent?.callDate
                      ? new Date(agent?.callDate).toLocaleDateString()
                      : '-'}
                  </DataTable.Cell>
                  <DataTable.Cell>
                    {agent?.callDate
                      ? new Date(agent?.callDate).toLocaleTimeString()
                      : '-'}
                  </DataTable.Cell>
                  <DataTable.Cell>{agent?.callTag || '-'}</DataTable.Cell>
                  <DataTable.Cell>{agent?.callComment || '-'}</DataTable.Cell>
                  <DataTable.Cell>
                    <CallHistoryPopup
                      campaignId={campaignId}
                      destinationNumber={agent?.number}
                      secondaryNumber={agent?.secondaryNumber}
                    ></CallHistoryPopup>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <div style={{ display: 'flex', placeContent: 'flex-end' }}>
                      <div>
                        <EditLeadPopup
                          isDisabled={agent?.secondaryNumber !== ''}
                          primaryNumber={agent?.number}
                          addSecondaryNumber={(e) => addSecondaryNumber(e)}
                        ></EditLeadPopup>
                      </div>
                      <div>
                        <AddCallRemarksPopup
                          agentHandleDisposition={
                            agent?.status?.toLowerCase() === 'not_initiated'
                          }
                          agentId={agentId}
                          handleFetchCampaignDetails={
                            handleFetchCampaignDetails
                          }
                          dispositions={dispositions}
                          number={agent?.number}
                          secondaryNumber={agent?.secondaryNumber}
                          campaignId={campaignId}
                          isButtonDisabled={
                            agent.callTag !== '' && agent.callTag !== 'RNR'
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="tooltip-container">
                        <div className="tooltip-button">
                          <button
                            className="ActivateButton"
                            onClick={() => onClickCall(agent)}
                            style={{
                              width: '32px',
                              height: '32px',
                              backgroundColor: '#07aa3f',
                              opacity:
                                props.isValidCampaign && props.isActive
                                  ? 1
                                  : 0.5,
                              cursor:
                                props.isValidCampaign && props.isActive
                                  ? 'pointer'
                                  : 'default',
                            }}
                          >
                            <img
                              alt=""
                              src={phoneWhite}
                              className="SidebarItemIcon"
                              style={{ width: '16px', height: '16px' }}
                            />
                          </button>
                        </div>
                        <div
                          className="tooltip-text left"
                          data-tip-position="left"
                        >
                          {props.isActive
                            ? props.isValidCampaign
                              ? 'Start Call'
                              : 'campaign is not valid'
                            : 'You are in Inactive Status'}
                          <span className="arrow-right"></span>
                        </div>
                      </div>
                    </div>
                  </DataTable.Cell>
                </DataTable.Row>
              ))}
          </DataTable.Body>
        </DataTable.Table>
      </div>
    );
  }

  const getDispositions = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(
      outGoingUrl + 'v1/organisations/outbound-call/get-dispositions',
      data
    )
      .then((res) => {
        setDispositions(res.data.response.dispositions);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const fetchWithFilter = (filter) => {
    console.log('the set values are', filter.searchKey, filter.searchValue);
    if (filter.searchKey === 'call_status') {
      filter.searchKey = 'status';
    }
    setDataSearchKey(filter.searchKey);
    setDataSearchValue(filter.searchCondition);
    handleFetchCampaignDetails(
      agentId,
      campaignId,
      filter.searchKey,
      filter.searchCondition
    );
  };

  const reset = (agentId, campaignId) => {
    setDataSearchKey('');
    setDataSearchValue('');
    resetHandleFetchCampaignDetails(agentId, campaignId);
  };
  const resetValues = () => {
    props.setRTCCallStatus({ RTCcallStatus: 'Call Connecting' });
    props.setSingleCallStatus({ singleCallStatus: false });
    localStorage.setItem('singleCallStatus', false);
    props.setRTCSession({ RTCSession: null });
    props.setDestinationNumber({ destinationNumber: '' });
    props.setDestinationName({ destinationName: '' });
    audio.remove();
    props.setCallId({ callId: '' });
  };

  const initiateWebRTCBatchRecoveryCall = (filter = {}) => {
    if (!WebRTCEnabled) {
      toast.error("Webrtc Is Not Enabled For The Agent");
      return;
    }
    if (singleCallStatus || callId) {
      toast.error('There is an active call');
      return;
    }
    if (campaignUniqueId) {
      toast.error('There is an active campaign call');
      return;
    }
    const data = {};
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.campaignId = campaignId;
    if (filter.isFilterSelected && filter.filteredLeads) {
      data.isFilterSelected = true;
      data.selectedDispositions = filter.filteredLeads;
    }

    console.log('the data is ', data);
    return POST(
      webRTCOutGoingUrl +
        'v1/organisations/outbound-call/start-static-campaign-calls',
      data
    )
      .then((res) => {
        props.setBatchCallId({ batchCallId: res.data.response });
        props.setCampaignId({ campaignId: campaignId });
        props.setAutoCalling({ autoCalling: true });
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const filterAndInitiatebatchCallHandler = (filteredLeads) => {
    const data = {
      isFilterSelected: true,
      filteredLeads,
    };
    if (WebRTCEnabled) {
      initiateWebRTCBatchRecoveryCall(data);
    }
    // else {
    //   batchCallInit(data);
    // }
  };
  const resumeBatchCallHadler = () => {
    if (!WebRTCEnabled) {
      toast.error("Webrtc Is Not Enabled For The Agent");
      return;
    }
    if (singleCallStatus === true && !WebRTCEnabled) {
      toast.error('There is an active single call');
      return;
    }
    if (resumeBatchCallId === '' || !resumeBatchCallId) {
      toast.error('There is no previous batch call');
      return;
    }
    // if (!resumeAutoCalling) {
    //   toast.error(
    //     'Please wait for 30 seconds before resuming the auto calling'
    //   );
    //   return;
    // }
    var data = JSON.stringify({
      batchCallId: resumeBatchCallId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    });
    return POST(
      outGoingUrl +
        'v1/organisations/outbound-call/resume-static-campaign-calls',
      data
    )
      .then((res) => {
        props.setBatchCallId({ batchCallId: resumeBatchCallId });
        props.setCampaignId({ campaignId });
        props.setAutoCalling({ autoCalling: true });
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const stopBatchCall = () => {
    if (WebRTCEnabled && RTCSession) {
      if (RTCSession.terminate) {
        RTCSession.terminate();
      }
      resetValues();
    }
    if (!campaignUniqueId) {
      props.setSingleCallStatus({ singleCallStatus: false });
      props.setCallId({ callId: '' });
      return;
    }
    props.setAutoCalling({ autoCalling: false });
    props.setBatchCallId({ batchCallId: '' });
    props.setCampaignId({ campaignId: '' });
    props.setSingleCallStatus({ singleCallStatus: false });
    props.setCallId({ callId: '' });
    var data = JSON.stringify({
      batchCallId: batchCallId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    });
    return POST(
      outGoingUrl + 'v1/organisations/outbound-call/stop-static-campaign-calls',
      data
    )
      .then((res) => {
        // setResumeAutoCalling(false);
        // resumeAutoCallingTimeHandler();
        // const id = res.data.leadList._id;
        setResumeBatchCallId(batchCallId);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };
  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        {
          // For WebRTC Call Popup
          userRole === 'USER' &&
          (campaignUniqueId ? true : false || singleCallStatus) &&
          WebRTCEnabled ? (
            <WebRTCCallPopup
              isOpen={
                WebRTCEnabled
                  ? campaignUniqueId
                    ? true
                    : false || singleCallStatus
                  : false
              }
              muteCall={muteCall}
              unmuteCall={unMuteCall}
              holdCall={holdCall}
              resumeCall={resumeCall}
              endCall={endCall}
              RTCcallStatus={RTCcallStatus}
              number={destinationNumber}
              name={destinationName}
              isSingleCall={singleCallStatus}
              batchCallId={batchCallId}
              value={autoCalling}
              isCallOnHold={isCallOnHold}
            ></WebRTCCallPopup>
          ) : null
        }
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                alt=""
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/campaigns')}
              />
              <div
                style={{
                  width: '200px',
                  height: '50px',
                  // overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                  padding: '10px',
                  color: '#00515a',
                  margin: 0,
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {campaignData?.campaignName
                  ? `${campaignData?.campaignName}`
                  : ''}
              </div>

              <div style={{ display: 'flex' }}>
                <BatchCallPopup
                  dispositions={dispositions}
                  isActive={campaignData.isActive}
                  isValidCampaign={campaignData.isValidCampaign}
                  batchCallHandler={() => initiateWebRTCBatchRecoveryCall()}
                  filterAndInitiatebatchCallHandler={(e) =>
                    filterAndInitiatebatchCallHandler(e)
                  }
                  resumeBatchCallHanlder={() => resumeBatchCallHadler()}
                  resumeBatchCallId={resumeBatchCallId || ""}
                  isConnected={true}
                >
                </BatchCallPopup>
                <button
                  className="Otherbutton"
                  style={{
                    width: 200,
                    color: 'white',
                    backgroundColor:
                      campaignData.isActive === false ||
                      campaignData.isValidCampaign === false
                        ? 'lightgrey'
                        : '#ed3833',
                  }}
                  onClick={() => stopBatchCall()}
                  disabled={
                    campaignData.isActive === false ||
                    campaignData.isValidCampaign === false
                  }
                >
                  Stop Calling Leads
                </button>
              </div>
            </div>
            <SearchBar
              type="campaign-agent"
              subType="static"
              isPlaceACallButtonVisible={true}
              hideTableHeaders={true}
              search={(e) => fetchWithFilter(e)}
              reset={(e) => reset(agentId, campaignId)}
              toggleModal={() => toggleModal()}
              initiateRecoveryCall={(e) => initiateWebRTCRecoveryCall(e)}
              agentStatus={
                campaignData.isValidCampaign && campaignData.isActive
              }
            ></SearchBar>
          </TabSearchBar>
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            <DataCard title="Calls" count={campaignData?.totalCalls} />
            <DataCard
              title="Connected Calls"
              count={campaignData?.answeredCalls}
            />
            {/* <DataCard
              title="Calls Unanswered"
              count={campaignData?.unAnsweredCalls}
            />
            <DataCard
              title="Call Answer Rate (%)"
              count={campaignData?.answerPercentage}
            /> */}
          </div>
          {loading && <PreLoader />}
          {!loading && <div>
            <CampaignReportTable
              isActive={campaignData.isActive}
              isValidCampaign={campaignData.isValidCampaign}
              agentsList={campaignData.leads}
              campaignId={campaignId}
              pageNo={currentPage}
              pageSize={pageSize}
              headers={headers}
              callId={callId} />
            <Paginator
              currentPage={currentPage + 1}
              lastPage={totalPages}
              getInfo={(value) => {
                handleFetchCampaignDetails(agentId, campaignId, null, null, value, pageSize);
              }}
              setPaginationSize={(e) => setPaginationSize(e)}
              isRecoveryCallLogsPage={true}
            />
            {callOptions && <Popup open={callOptions} closeOnDocumentClick={false}>
              <div className="PopupContainer">
                <div className="PopupHeaderContainer">
                  <p>SELECT NUMBER TO CALL</p>
                  {/* <p style={{ padding: 10, fontSize: 16, fontWeight: 600, textAlign: "center" }} className="PopupInputLabel">Call Balance: {this.state.currentCallBalance}</p> */}
                  <img
                    alt=""
                    className="PopupClose"
                    src={Close}
                    onClick={() => setCallOptions(false)}
                  />
                </div >
                <div className="PopupBody">

                  <button
                    className="ButtonCancelFullWidth"
                    style={{ cursor: 'pointer' }}
                    onClick={() => initiateRecoveryCall(callOptionNumber, ...callOptionDetails)}>
                    PRIMARY NUMBER
                  </button>
                  <button
                    className="ButtonCancelFullWidth"
                    style={{ cursor: 'pointer' }}
                    onClick={() => initiateRecoveryCall(callOptionSecondaryNumber, ...callOptionDetails)}>
                    SECONDARY NUMBER
                  </button>

                </div >
                <br />
              </div >
            </Popup >}
          </div>}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isReocveryXEnabled: state.topbar.isReocveryXEnabled,
  isNumberMaskingEnabled: state.topbar.isNumberMaskingEnabled,
  userDetails: state.recovery.userDetails,
  callStatus: state.recovery.callStatus,
  position: state.recovery.position,
  autoCalling: state.recovery.autoCalling,
  batchCallId: state.recovery.batchCallId,
  // destinationNumber: state.recovery.destinationNumber,
  // destinationName: state.recovery.destinationName,
  // RTCSession: state.recovery.RTCSession,
  callId: state.recovery.callId,
  callFilterLeads: state.recovery.callFilterLeads,
  singleCallStatus: state.recovery.singleCallStatus,
  // RTCcallStatus: state.recovery.RTCcallStatus,
  isRecoveryXEmailSent: state.topbar.isRecoveryXEmailSent,
});

export default commonStyle(
  connect(mapStateToProps, {
    setCallDetails,
    setBatchCallId,
    setCallId,
    setCallStatus,
    setAutoCalling,
    toggleAutoCalling,
    setSingleCallStatus,
    setDestinationNumber,
    setDestinationName,
    setRTCSession,
    setCallFilterLeads,
    setRTCCallStatus,
    setCampaignId,
  })(RecoveryXAgentStaticCampaignView)
);

/* eslint-disable */
import React, { useState,useCallback } from 'react';
import styled from '@emotion/styled';
import '../../../assets/css/SearchBar.css';

const DropDownOptions = [
  {
    value : '',
    text : 'Select'
  },
  {
    value : 'name',
    text : 'Name'
  },{
    value : 'dndNumber',
    text : 'Number'
  },
];

const initialState = {
  searchType : '',
  searchText : '',
  reset : false
}

const SearchBar  = ({ fetchData= () =>{} ,className}) => {
 
  const [state, setState] = useState(initialState)

  const handleDropDown = useCallback((evt) =>{
    setState({...initialState, searchType : evt.target.value })
  },[])

  const handleSearch = useCallback((current,search=false) => {
      const { reset ,...rest } = current;
      fetchData(rest);
      setState(search ? { ...rest,reset :true } : initialState )
    },[fetchData]);

  
    return (
      <div className={className}>
        <div className="SearchBarComponent">
          <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
          </div>
          <div style={{ flex: 1 }} />
          
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className="SearchText">SEARCH BY</div>
              <React.Fragment>
                <select
                  className="SearchDropdown"
                  onChange={handleDropDown}
                  id="searchKey"
                  value={state.searchType}
                >
                { DropDownOptions.map((option) =>  <option value={option.value}>{option.text}</option>)}
                </select>
                
                 <input
                    id="searchValue"
                    className="SearchBox"
                    placeholder="Search..."
                    onChange={(e)=>setState((prev) =>({...prev,searchText : e.target.value }))}
                    value={state.searchText}
                    disabled={state.searchType === ""}
                  />
              </React.Fragment>
            </div>

          <div>
            {state.reset ? (
              <button
                className="ClearResultButton"
                style={{
                  border: 'none',
                }}
                onClick={()=>{ handleSearch(initialState)}}
              >
                CLEAR RESULTS
              </button>
            ) : (
              <>
                <button
                  className="SearchButton"
                  style={{ width: '100px', margin: '0 8px' }}
                  onClick={()=>{handleSearch(state,true)}}
                  disabled={state.searchType === "" ? true : false}
                >
                  SEARCH
                </button>
              </>
            )}
          </div>
        </div>
      </div >
    );
}

export default styled(SearchBar)`
display: flex;
flex: 1;

input::placeholder {
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}
input {
  color-scheme: ${(props) => props.theme.colors.dateCalenderIconColor};
}
.SearchBarComponent {
  width: 100%;
  height: 54px;
  background-color: ${(props) => props.theme.colors.searchBarBgcolor};
  display: flex;
  grid-template-columns: 136px auto auto 116px;
  align-items: center;
  border-radius: 1px;
  box-shadow: 0px -15px 10px -15px rgb(0, 0, 0, 0.4);
  border: solid 1px ${(props) => props.theme.colors.searchBarBgcolor};
}

.SearchText {
  font-size: 12px;
  font-weight: 500;
  margin: auto 10px;
  /* letter-spacing: 1.2px; */
  text-align: left;
  color: ${(props) => props.theme.colors.searchByColor};
}

.SearchDropdown {
  width: 136px;
  height: 32px;
  font-size: 13px;
  margin: auto 5px;
  padding: 0 5px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchBox {
  width: 136px;
  height: 32px;
  margin: auto 5px;
  font-size: 13px;
  padding: 0 10px;
  /* padding: 8px 16px 9px; */
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchButton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.ClearResultButton{
  width: 120px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
  width: '128px',
  border: none,
}
.Otherbutton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.theme.colors.lightGreyBgColor};
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.SelectedContainer {
  width: 136px;
  height: 54px;
  background-color: #196cca;
}
.Agents-Logged-In {
  display: flex;
  height: 18px;
  margin: 0 0 0 0;
  
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.Total-call-duration {
  height: 18px;
  margin: 0 4px 0 0;
  
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SelectedContainer h6 {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding-top: 11px;
  padding-left: 14px;
  color: #ffffff;
}

.SelectedContainer p {
  font-size: 16px;
  font-weight: bold;
  padding-left: 14px;
  margin: 0;
  letter-spacing: 1.2px;
  color: #ffffff;
}


@media only screen and (max-width: 900px) {
  .SearchBarComponent {
    width: fit-content;
    grid-template-columns: 48px 136px 77px auto 150px;
  }
}
`;

/* eslint-disable */
import React, { useState } from 'react';
import './styles.css';
import '../../../assets/css/Popups.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import historyIcon from '../../../assets/images/callHistoryRecoveryCall.svg';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
import RightArrow from '../../../assets/images/rightArrow.png';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import DataTable from '../../../common/component/table/DataTable';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import AudioPlayer from './AudioPlayer';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import { useHistory } from 'react-router-dom';
import { MenuItem, Select, TablePagination } from '@material-ui/core';
import { toast } from 'react-toastify';
import cancelIcon from '../../../assets/images/cancelIconCircleGreen.png';

export default function DuplicateCampaignLeadsPopup(props) {
  const [seletedLeads, setSelectedLeads] = useState(props.campaignLeads);
  const [loading, setLoading] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [leadFilter, setLeadFilter] = useState(props.leadFilter);
  const [leadFilterdisposition, setLeadFilterDisposition] = useState(
    props.leadFilterdisposition
  );
  const history = useHistory();
  const [selectedOptions, setSelectedOptions] = useState(
    props.leadFilterdisposition
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowsData, setRowsData] = useState(
    props.campaignLeads.slice(0, rowsPerPage)
  );
  const [deletedLeads, setDeletedLeads] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRowsData(
      seletedLeads.slice(newPage * rowsPerPage, (newPage + 1) * rowsPerPage)
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setRowsData(seletedLeads.slice(0, event.target.value));
  };

  const ActiveFilterTab = {
    padding: '8px 14px',
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#07aa3f',
    borderRadius: '2px',
    backgroundColor: '#d3edda',
    cursor: 'pointer',
  };

  const SaveButton = {
    padding: '8px 14px',
    fontWeight: 'bold',
    fontSize: '12px',
    backgroundColor: '#196cca',
    borderRadius: '2px',
    color: 'white',
    cursor: 'pointer',
    width: '150px',
    display: 'flex', // Use flex to center the content
    alignItems: 'center', // Center vertically
    justifyContent: 'center', // Center horizontally
  };

  const InactiveFilterTab = {
    padding: '8px 14px',
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#767171',
    borderRadius: '2px',
    backgroundColor: '#f3f3f3',
    cursor: 'pointer',
  };
  const deleteLeadInDuplicate = (number) => {
    deletedLeads.push(number);
    setDeletedLeads(deletedLeads);
    const newSelectedLeads = seletedLeads.filter((x) => x.number !== number);
    setSelectedLeads(newSelectedLeads);
    setPage(0);
    setRowsData(newSelectedLeads.slice(0, rowsPerPage));
  };

  const getStatus = (status) => {
    let res = '';
    if (status) {
      if (status === 'not_answered') {
        res = 'Not Answered';
      }
      if (status === 'answered') {
        res = 'Answered';
      }
    }
    return res;
  };

  const PopupContainer = {
    width: '1200px',
  };

  let displayData;
  if (loading) displayData = <PreLoader />;
  if (!loading && seletedLeads.length > 0) {
    displayData = rowsData.map((lead, index) => (
      <DataTable.Row>
        <DataTable.Cell>{page * rowsPerPage + index + 1}</DataTable.Cell>
        {props.headers &&
          props.headers.map((header) => (
            <DataTable.Cell>{lead[header]}</DataTable.Cell>
          ))}
        {/* <DataTable.Cell>{lead.name || "-"}</DataTable.Cell>
        <DataTable.Cell>{lead.number || "-"}</DataTable.Cell>
        <DataTable.Cell>{lead.secondaryNumber || "-"}</DataTable.Cell>
        <DataTable.Cell>{lead.email || "-"}</DataTable.Cell>
        <DataTable.Cell>{lead.due || "-"}</DataTable.Cell> */}
        <DataTable.Cell>{getStatus(lead.status) || '-'}</DataTable.Cell>
        <DataTable.Cell>{lead.disposition || '-'}</DataTable.Cell>
        <DataTable.Cell>
          <img
            alt=""
            onClick={() => deleteLeadInDuplicate(lead.number)}
            style={{ cursor: 'pointer' }}
            src={Delete}
          />
        </DataTable.Cell>
      </DataTable.Row>
    ));
  }
  const handleOption = (disposition) => {
    let values = [];
    if (selectedOptions.indexOf(disposition) > -1) {
      values = selectedOptions.filter((i) => i !== disposition);
    } else {
      values = [...selectedOptions, disposition];
    }
    setDeletedLeads([]);
    setSelectedOptions(values);
    submitFilterLeads('DISPOSITIONS', values);
  };
  const submitFilterLeads = (option, values) => {
    const filterDisposition = values;
    let filteredData = [];
    setDeletedLeads([]);
    if (option === 'ALL') {
      filteredData = props.unfilteredCampaignLeads;
    } else if (option === 'NOT ANSWERED') {
      filteredData = props.unfilteredCampaignLeads.filter(
        (i) => i.status && i.status?.toLowerCase() === 'not_answered'
      );
    } else if (option !== 'DISPOSITIONS') {
      filteredData = props.unfilteredCampaignLeads.filter(
        (i) => i.status && i.status?.toLowerCase() === option.toLowerCase()
      );
    } else {
      filteredData = props.unfilteredCampaignLeads.filter(
        (i) => i.disposition && filterDisposition.indexOf(i.disposition) > -1
      );
    }
    setSelectedLeads(filteredData);
    setPage(0);
    setRowsData(filteredData.slice(0, rowsPerPage));
  };

  const onChangeFilter = (option) => {
    setLeadFilter(option);
    setDeletedLeads([]);
    if (option === 'DISPOSITIONS') {
      setSelectedOptions([]);
      setSelectedLeads(props.unfilteredCampaignLeads);
      return;
    }
    submitFilterLeads(option, {});
  };

  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div
        className="PopupContainer"
        style={{ width: '1200px', overflow: 'scroll' }}
      >
        <div className="PopupHeaderContainer" style={{ ...PopupContainer }}>
          <p>Preview Leads</p>
          <img
            style={{ left: '95%' }}
            alt=""
            className="PopupClose"
            src={Close}
            onClick={(e) => {
              setLeadFilter(props.leadFilter);
              setSelectedOptions(props.leadFilterdisposition);
              setSelectedLeads(props.campaignLeads);
              setPopupOpen(false);
            }}
          />
        </div>
        <div
          className="PopupBody"
          style={{
            ...PopupContainer,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '16px',
              padding: '16px',
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: '12px',
                fontWeight: 500,
                color: '#808080',
                padding: '8px 14px',
              }}
            >
              Filter
            </p>
            {['ALL', 'ANSWERED', 'NOT ANSWERED', 'DISPOSITIONS'].map(
              (option) => (
                <div
                  style={
                    leadFilter === option ? ActiveFilterTab : InactiveFilterTab
                  }
                  onClick={() => onChangeFilter(option)}
                >
                  {option}
                </div>
              )
            )}

            {/* {leadFilter === 'DISPOSITIONS' && <div style={{
              "padding": "8px 14px",
              "fontWeight": "bold",
              "fontSize": "13px",
              "borderRadius": "2px",
              "cursor": "pointer",
              "width": "136px",
              "height": "32px",
              "margin": "auto 5px",
              "padding": "0 5px",
            }}>
              <Select
                multiple
                value={leadFilterdisposition}
                onChange={(e) => onChangeFilter('DISPOSITIONS', e)}
              >
                <MenuItem
                  value={'select'}
                  key='select'
                  style={{ boxShadow: 'none', fontSize: '15px' }}
                  disabled
                >
                  select disposition
                </MenuItem>
                {props.dispositions.map((option) => (
                  <MenuItem
                    key={option.dispositon}
                    value={option.dispositon}
                    style={{ boxShadow: 'none', fontSize: '15px' }}
                  >
                    {option.dispositon}
                  </MenuItem>
                ))}
              </Select>
            </div>} */}
          </div>
          <div
            style={{
              display: 'flex',
              gap: '16px',
              padding: '16px',
              alignItems: 'center',
            }}
          >
            <div
              style={SaveButton}
              onClick={() => {
                props.setLeadFilter(leadFilter);
                props.setLeadFilterDisposition(selectedOptions);
                props.setCampaignLeads(seletedLeads);
                props.setDuplicateDeleteFilterLeads(deletedLeads);
                toast.success('Leads Updated !!!');
                setPopupOpen(false);
              }}
            >
              SAVE
            </div>
          </div>
        </div>
        {leadFilter === 'DISPOSITIONS' && (
          <div
            className="PopupBody"
            style={{
              ...PopupContainer,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '16px',
                paddingLeft: '16px',
                paddingBottom: '0px',
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: '12px',
                  fontWeight: 500,
                  color: '#808080',
                  padding: '8px 14px',
                }}
              >
                Select Disposition
              </p>

              <div
                style={{
                  display: 'flex',
                  gap: '16px',
                  paddingLeft: '16px',
                  paddingBottom: '10px',
                  overflowX: 'scroll',
                  width: '1000px',
                }}
              >
                {props.dispositions.map((option) => (
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      marginBottom: '10px',
                      padding: '8px 14px',
                      fontWeight: 'bold',
                      fontSize: '12px',
                      border:
                        selectedOptions.indexOf(option.dispositon) > -1
                          ? '1px solid #ccc'
                          : 'none',
                      color:
                        selectedOptions.indexOf(option.dispositon) > -1
                          ? 'green'
                          : '#767171',
                      borderRadius: '2px',
                      backgroundColor:
                        selectedOptions.indexOf(option.dispositon) > -1
                          ? 'white'
                          : '#f3f3f3',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleOption(option.dispositon)}
                  >
                    {option.dispositon} &nbsp;
                    {selectedOptions.indexOf(option.dispositon) > -1 ? (
                      <img src={cancelIcon} />
                    ) : (
                      <img src={RightArrow} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <DataTable.Table style={{ ...PopupContainer }}>
          <DataTable.Head>
            <DataTable.CellHead>#</DataTable.CellHead>
            {props.headers &&
              props.headers.map((header) => (
                <DataTable.CellHead>{header.toUpperCase()}</DataTable.CellHead>
              ))}
            {/* <DataTable.CellHead>NAME</DataTable.CellHead>
            <DataTable.CellHead>LEAD NUMBER</DataTable.CellHead>
            <DataTable.CellHead>LEAD SECONDARYNUMBER</DataTable.CellHead>
            <DataTable.CellHead>EMAIL</DataTable.CellHead>
            <DataTable.CellHead>DUE</DataTable.CellHead> */}
            <DataTable.CellHead>LAST CALL STATUS</DataTable.CellHead>
            <DataTable.CellHead>LAST CALL DISPOSITION</DataTable.CellHead>
            <DataTable.CellHead>ACTIONS</DataTable.CellHead>
          </DataTable.Head>
          <DataTable.Body>{displayData}</DataTable.Body>
        </DataTable.Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={seletedLeads.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <span
        style={{
          color: 'blue',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (props.duplicate !== 'true') {
            history.push(`/recovery-x-campaign/leads?campaignId=${props.id}`);
          } else {
            setPopupOpen(true);
          }
        }}
      >
        Edit
      </span>
      <span>{popper}</span>
    </div>
  );
}

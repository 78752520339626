import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import LiveDemoIcon from '../../../assets/images/liveDemo.png';
import VideoTourIcon from '../../../assets/images/videoTour.png';
const overlayStyle = { background: 'rgba(0,0,0,0.9)' };
const contentStyle = {
  width: 'fit-content',
  fontFamily: 'Inter',
  padding: '40px 42px',
  borderRadius: '8px',
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
};

const ZohoBookingEmbed = () => {
  useEffect(() => {
    const url =
      'https://crm.zoho.in/bookings/Quickonlinedemo?rid=a69b2e8fdccc7a99dc23fa8493b94cd69b0d2e0b1f8c3da5055b42839666b3167b3e2c09d10e3db6c2f5ec4899ed3312gide7a5c6519833c83ffa18f55a74c8f9f0527042fef6de5dcc3fbdbc424311bea3&option=embed';
    const width = window.innerWidth <= 1024 ? '90%' : '1100px';

    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.style.width = width;
    iframe.style.height = '400px';

    const embedContainer = document.getElementById('embed');
    embedContainer.appendChild(iframe);

    // Cleanup function to remove the iframe when the component is unmounted
    return () => {
      embedContainer.removeChild(iframe);
    };
  }, []);

  return <div id="embed"></div>;
};

const VideoPopup = (props) => {
  return (
    <Popup
      open={props.open}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      style={{ height: '600px', width: '900px' }}
      {...{ overlayStyle, contentStyle }}
    >
      <p
        onClick={props.close}
        style={{
          color: '#f8153b',
          fontWeight: 'bold',
          textAlign: 'right',
          cursor: 'pointer',
        }}
      >
        Close
      </p>
      <div style={{ height: '500px', width: '800px' }}>
        <iframe
          src="https://app.supademo.com/embed/clx51acqu0av5fd4u0tbsohpx"
          title="Alohaa Demo"
          allow="clipboard-write"
          frameBorder="0"
          webkitAllowFullScreen="true"
          mozAllowFullScreen="true"
          allowFullScreen
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    </Popup>
  );
};

const DemoPopup = (props) => {
  return (
    <Popup
      open={props.open}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      {...{ overlayStyle, contentStyle }}
    >
      <p
        onClick={props.close}
        style={{
          color: '#f8153b',
          fontWeight: 'bold',
          textAlign: 'right',
          cursor: 'pointer',
        }}
      >
        Close
      </p>
      <ZohoBookingEmbed />
    </Popup>
  );
};

export default function TopbarExtras() {
  const [showVideo, setShowVideo] = useState(false);
  const [showDemo, setShowDemo] = useState(false);

  return (
    <div style={{ display: 'flex' }}>
      <VideoPopup open={showVideo} close={() => setShowVideo(false)} />
      <DemoPopup open={showDemo} close={() => setShowDemo(false)} />

      <div
        onClick={() => setShowVideo(true)}
        style={{
          height: '26px',
          width: '130px',
          backgroundColor: '#196cca1a',
          color: '#196cca',
          borderRadius: '8px',
          cursor: 'pointer',
          fontSize: '12px',
          fontWeight: 600,
          display: 'flex',

          justifyContent: 'center',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <img style={{ width: '12.5px' }} src={VideoTourIcon} />
        Interactive tour
      </div>
      <div
        onClick={() => setShowDemo(true)}
        style={{
          height: '26px',
          width: '100px',
          backgroundColor: '#07aa3f1a',
          color: '#07aa3f',
          borderRadius: '8px',
          cursor: 'pointer',
          fontSize: '12px',
          fontWeight: 600,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '4px',
          marginLeft: '8px',
        }}
      >
        <img style={{ width: '12.5px' }} src={LiveDemoIcon} />
        Live demo
      </div>
    </div>
  );
}

import React, { Component } from 'react';
import { commonStyle } from '../../../assets/css/css';
import DataTable from '../../../common/component/table/DataTable';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { GET, PUT } from '../../../services/HttpRequests';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import UploadPopup from './CallerTuneUploadPopup';
import RemovePopup from './RemoveCallerTunePopup';
import { toast } from 'react-toastify';
import PlayIcon from '../../../assets/images/playIconGreen.svg';
import { CallerTuneToggle } from '../../Users/component/CallForwardToggles';
import { getTopBarData } from '../../../actions/topBarActions';
import { connect } from 'react-redux';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import { ADMIN_AGENT_CALLERTUNE_SEARCH_BUTTON, ADMIN_ADD_AGENT_CALLERTUNE_BUTTON, ADMIN_REMOVE_AGENT_CALLERTUNE_BUTTON, ADMIN_AGENT_CALLERTUNE_UPLOAD_BUTTON } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";
const userUrl = process.env.REACT_APP_USER_API;
const callerTuneUrl = process.env.REACT_APP_CALLER_TUNE_API;

class UserCallerTune extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      count: 0,
      totalPages: 0,
      pageSize: 15,
      currentPage: 1,
      usersList: [],
      orgId: localStorage.getItem('doosra-biz-organisation-id'),
      currentItem: {},
      uploadPopup: false,
      audioPlayed: false,
      deletePopup: false,
    };
  }

  componentDidMount = () => {
    this.getFreshList();
    if (this.props.onRegisterSearchHandlers) {
      this.props.onRegisterSearchHandlers(this.getFreshList, this.searchHandler);
    }
  };

  searchHandler = async (data) => {
    logPostHogEvent(ADMIN_AGENT_CALLERTUNE_SEARCH_BUTTON);
    data.pageNo = 1;
    data.pageSize = this.state.pageSize;
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');

    GET(userUrl + `v1/user`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          usersList: res.users.docs,
          count: res.users.count,
          totalPages: Math.ceil(res.users.count / this.state.pageSize),
          pageSize: res.pageSize,
          loading: false,
          searchData: {},
        });
      })
      .catch((err) => {
        if (err) {
          this.setState({ loading: false });
        }
      });
  };

  getFreshList = () => {
    this.setState({ loading: true });
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    let data = {
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
      organisationId: orgId,
    };
    GET(userUrl + `v1/user`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          usersList: res.users.docs,
          count: res.users.count,
          totalPages: Math.ceil(res.users.count / this.state.pageSize),
          pageSize: res.pageSize,
          loading: false,
          searchData: {},
        });
      })
      .catch((err) => {
        if (err) {
          this.setState({ loading: false });
        }
      });
  };

  closePopup = () => {
    this.setState({
      uploadPopup: false,
      deletePopup: false,
    });
  };

  onClickAddCallerTune = (item) => {
    logPostHogEvent(ADMIN_ADD_AGENT_CALLERTUNE_BUTTON);
    this.setState({
      currentItem: item,
      uploadPopup: true,
    });
  };

  onClickRemoveCallerTune = (item) => {
    logPostHogEvent(ADMIN_REMOVE_AGENT_CALLERTUNE_BUTTON);
    this.setState({
      currentItem: item,
      deletePopup: true,
    });
  };

  handelUploadedFile = async (formData) => {
    try {
      logPostHogEvent(ADMIN_AGENT_CALLERTUNE_UPLOAD_BUTTON);
      this.setState({ loading: true });
      formData.append(
        'organisationId',
        localStorage.getItem('doosra-biz-organisation-id')
      );
      formData.append('id', this.state.currentItem.user_id);
      formData.append('type', 'basic_user');
      const uploadRespose = await PUT(
        `${callerTuneUrl}v1/organisations/caller-tune-set`,

        null,
        formData,
        { 'Content-Type': 'multipart/form-data' }
      );
      const removeGlobalAudio = await PUT(
        `${callerTuneUrl}v1/organisations/caller-tune-enable`,
        null,
        {
          organisationId: localStorage.getItem('doosra-biz-organisation-id'),
          id: this.state.currentItem.user_id,
          type: 'basic_user',
        }
      );
      this.setState({
        uploadPopup: false,
        audioPlayed: false,
        loading: false,
      });
      this.getFreshList();
      toast.success(`Succesfully set Agent CallerTune`);
    } catch (err) {
      this.setState({ loading: false });
      if (err?.response?.data?.error?.code === 1027) {
        this.setState({ uploadPopup: false });
        this.getFreshList();
      }
      toast.error(err.response.data.error.reason);
    }
  };

  handleRemoveCallerTune = async () => {
    try {
      const removeGlobalAudio = await PUT(
        `${callerTuneUrl}v1/organisations/caller-tune-unset`,
        null,
        {
          organisationId: localStorage.getItem('doosra-biz-organisation-id'),
          id: this.state.currentItem.user_id,
          type: 'basic_user',
        }
      );
      if (!this.props.globalCallerTuneUrl) {
        await PUT(
          `${callerTuneUrl}v1/organisations/caller-tune-disable`,
          null,
          {
            organisationId: localStorage.getItem('doosra-biz-organisation-id'),
            id: this.state.currentItem.user_id,
            type: 'basic_user',
          }
        ).then((res) => {
          console.log("User Caller Tune", { res });
        }).catch((err) => {
          console.log("User Caller Tune", { err });
        });
      }
      this.setState({
        deletePopup: false,
        audioPlayed: false,
      });
      this.getFreshList();
    } catch (err) {
      console.log(err);
    }
  };

  onClickPlayAudio = (item) => {
    this.setState({
      currentItem: item,
      audioPlayed: true,
    });
  };

  changeOutboundCallStatus = async (item) => {
    try {
      let orgId = localStorage.getItem('doosra-biz-organisation-id');
      const removeGlobalAudio = await PUT(
        `${callerTuneUrl}v1/organisations/${item.is_caller_tune_enable
          ? 'caller-tune-disable'
          : 'caller-tune-enable'
        }`,
        null,
        {
          organisationId: localStorage.getItem('doosra-biz-organisation-id'),
          id: item.user_id,
          type: 'basic_user',
        }
      );
      this.setState({
        deletePopup: false,
      });
      this.getFreshList();
    } catch (err) {
      console.log(err);
    }
  };

  fetchPageData = (value) => {
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    let data = {
      pageNo: value,
      pageSize: this.state.pageSize,
      organisationId: orgId,
    };
    data = { ...data, ...this.state.searchData };
    GET(userUrl + `v1/user`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          usersList: res.users.docs,
          count: res.users.count,
          currentPage: value,
          pageSize: res.pageSize,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const {
      currentPage,
      totalPages,
      loading,
      usersList,
      uploadPopup,
      deletePopup,
      currentItem,
      audioPlayed,
    } = this.state;
    
    return (
      <>
        {loading && <PreLoader />}
        <DataTable.Table>
          <DataTable.Head>
            <DataTable.CellHead style={{ width: '2%' }}></DataTable.CellHead>
            <DataTable.CellHead style={{ width: '60%' }}>
              AGENT NAME
            </DataTable.CellHead>
            <DataTable.CellHead style={{ width: '16%' }}>
              ACTION
            </DataTable.CellHead>
            <DataTable.CellHead style={{ width: '14%' }}>
              AUDIO ACTION
            </DataTable.CellHead>
            <DataTable.CellHead style={{ width: '8%' }}>
              CURRENT STATUS
            </DataTable.CellHead>
            <DataTable.CellHead style={{ width: '2%' }}></DataTable.CellHead>
          </DataTable.Head>
          <DataTable.Body>
            {(usersList || []).map((item) => (
              <DataTable.Row>
                <DataTable.Cell> </DataTable.Cell>
                <DataTable.Cell>{item.zvr_name}</DataTable.Cell>
                <DataTable.Cell>
                  {!isSuperAdminWithPermission('ADD') ? item.callerTuneUrl ? (
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{ color: '#ed3833', marginTop: '6px' }}
                      >
                        Remove Callertune
                      </div>
                      <div style={{ marginLeft: '20px' }}></div>
                    </div>
                  ) : (
                    <div
                      style={{ color: '#287dc9', marginTop: '7px' }}
                    >
                      Add Callertune
                    </div>
                  ) : item.callerTuneUrl ? (
                    <div style={{ display: 'flex' }}>
                      <a
                        style={{ color: '#ed3833', marginTop: '6px' }}
                        href="#"
                        onClick={() => this.onClickRemoveCallerTune(item)}
                      >
                        Remove Callertune
                      </a>
                      <div style={{ marginLeft: '20px' }}></div>
                    </div>
                  ) : (
                    <a
                      style={{ color: '#287dc9', marginTop: '7px' }}
                      href="#"
                      onClick={() => this.onClickAddCallerTune(item)}
                    >
                      Add Callertune
                    </a>
                  )}
                </DataTable.Cell>
                <DataTable.Cell>
                  {item.callerTuneUrl ? (
                    currentItem._id === item._id && audioPlayed ? (
                      <audio
                        style={{
                          height: '24px',
                          marginTop: '3px',
                          marginBottom: '-2px',
                        }}
                        controls
                        autoPlay
                        // ref="audio_tag"
                        src={item.callerTuneUrl}
                      />
                    ) : (
                      <React.Fragment>
                        <a
                          style={{ color: '#0d9f3a', marginTop: '7px' }}
                          href="#"
                          onClick={() => this.onClickPlayAudio(item)}
                        >
                          Play
                        </a>
                      </React.Fragment>
                    )
                  ) : (
                    <></>
                  )}
                </DataTable.Cell>
                <DataTable.Cell>
                  <CallerTuneToggle
                    value={item.is_caller_tune_enable}
                    globalCallerTuneUrl={this.props.globalCallerTuneUrl}
                    callerTuneUrl={item.callerTuneUrl}
                    onChange={() => this.changeOutboundCallStatus(item)}
                  />
                </DataTable.Cell>
                <DataTable.Cell> </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable.Body>
        </DataTable.Table>
        <Paginator
          currentPage={currentPage}
          lastPage={totalPages}
          getInfo={(value) => this.fetchPageData(value)}
        />
        {uploadPopup && (
          <UploadPopup
            closePopup={this.closePopup}
            uploadPopup
            handelUploadedFile={this.handelUploadedFile}
          />
        )}
        {deletePopup && (
          <RemovePopup
            closePopup={this.closePopup}
            deletePopup
            handleRemoveCallerTune={this.handleRemoveCallerTune}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  balance: state.topbar.balance,
  name: state.topbar.name,
  logo: state.topbar.logo,
  minuteBalance: state.topbar.minuteBalance,
  callForwardingStatus: state.topbar.call_forwarding_status,
  globalCallerTuneUrl: state.topbar.callerTuneUrl,
});

export default commonStyle(
  connect(mapStateToProps, { getTopBarData })(UserCallerTune)
);

import React, { useContext, useState, useEffect } from "react";

export const ThemeContext = React.createContext()

export default function CustomeThemeProvider({ children }) {
    console.log('calling the function');
    const [themeType, setThemeType] = useState(localStorage.getItem('doosra-business-theme') || 'light');

    useEffect(() => {
        const type = localStorage.getItem('doosra-business-theme');
        if(!type) {
            localStorage.setItem('doosra-business-theme', 'light')
        }
    }, [])

    const onChangeTheme = (item) => {
        console.log('item123', item)
        localStorage.setItem('doosra-business-theme', item);
        setThemeType(item);
    }

    const ctxValue = {
        themeType,
        onChangeTheme
    }
    return <ThemeContext.Provider value={ctxValue}>
        {children}
    </ThemeContext.Provider>
}

export function useTheme() {
    return useContext(ThemeContext);
}

/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './index.css';
import styled from '@emotion/styled';
import '../../../assets/css/SearchBar.css';
import { commonStyle } from '../../../assets/css/css';
import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import { GET, POST } from '../../../services/HttpRequests';
import DataCard from '../component/DataCard';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import TableHeaders from '../../../common/component/TableHeaders/TableHeaders';
import DataTable from '../../../common/component/table/DataTable';
import { useHistory } from 'react-router-dom';
import { ReportIcon } from '../../../assets/Icons/Icons';
import CampaignOptions from '../component/CampaignOptions';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import EyeVisiible from '../../../assets/images/EyeVisible.svg';
import EyeNotVisiible from '../../../assets/images/EyeNotVisible.svg';
import moment from 'moment';
import { CAMPAIGNS_MAPPER } from '../../../common/constants';
import { toast } from 'react-toastify';
import { setCampaignTableHeaders } from '../../../actions/recoveryXActions';

const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

export function Campaigns(props) {
  console.log('The data inside is', props);
  const [loading, setLoading] = useState(false);
  const [callsData, setCallsData] = useState([]);
  const [current, setCurrent] = useState('ACTIVE');
  const [campaignsList, setCamapignsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [filteredHeaders, setFilteredHeaders] = useState(
    props.campaignTableHeaders || []
  );
  const [isHeadersPopUpOpen, setIsHeadersPopUpOpen] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [showClearResultsButton, setShowClearResultsButton] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [endDate, setEndDate] = useState('');
  let userRole = localStorage.getItem('doosra-biz-user-role');

  useEffect(() => {
    setFilteredHeaders(
      props?.campaignTableHeaders || [
        'CAMPAIGN NAME',
        'DESCRIPTION',
        'LEADS',
        'CONNECTED CALLS',
      ]
    );
  }, [props?.campaignTableHeaders]);

  useEffect(() => {
    fetchOrganisationReport();
  }, []);
  useEffect(() => {
    fetchCampaignListHandler(true);
  }, [current]);
  const fetchOrganisationReport = () => {
    setLoading(true);
    let data = {};
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    GET(cloudCallCenterUrl + 'reports/organisation-report', data)
      .then((res) => {
        setCallsData(res.data.response);
        setLoading(false);
        return res.data.response;
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const tabs = [];
  tabs.push({
    title: 'Campaigns',
    onClick: () => props.history.push('/campaigns-v2'),
    active: true,
  });
  tabs.push({
    title: 'Calls',
    onClick: () => props.history.push('/calls-v2'),
    active: false,
  });

  function FilterTab(props) {
    return (
      <div
        style={{
          display: 'flex',
          gap: '16px',
          padding: '16px',
          justifyContent: 'left',
          alignItems: 'center',
          marginLeft: '32px',
        }}
      >
        <div
          className={
            current === 'ACTIVE' ? 'ActiveFilterTab' : 'InactiveFilterTab'
          }
          onClick={() => { setCurrent('ACTIVE'); setIsHeadersPopUpOpen(false); }}
        >
          ACTIVE
        </div>
        <div
          className={
            current === 'COMPLETED' ? 'ActiveFilterTab' : 'InactiveFilterTab'
          }
          onClick={() => { setCurrent('COMPLETED'); setIsHeadersPopUpOpen(false); }}
        >
          COMPLETED
        </div>
      </div>
    );
  }

  function CampaignList(props) {
    const history = useHistory();
    const toggleLeadListVisibility = (leadListId, isHidden) => {
      let data = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        campaignId: leadListId,
        isHidden,
      };

      POST(cloudCallCenterUrl + 'campaigns/toggle-campaign-visibility', data)
        .then((res) => {
          fetchCampaignListHandler(true);
          return true;
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          return false;
        });
    };
    return (
      <DataTable.Table>
        <DataTable.Head>
          <DataTable.CellHead>#</DataTable.CellHead>
          {filteredHeaders.map((header) => (
            <DataTable.CellHead>{header}</DataTable.CellHead>
          ))}
          <DataTable.CellHead style={{ textAlign: 'end' }}>
            ACTIONS
          </DataTable.CellHead>
        </DataTable.Head>
        <DataTable.Body>
          {props?.campaignsList?.length >= 0 &&
            props?.campaignsList.map((item, index) => (
              <DataTable.Row key={item._id}>
                <DataTable.Cell>
                  {(currentPage - 1) * pageSize + index + 1}
                </DataTable.Cell>
                {filteredHeaders.map((header) => {
                  switch (header) {
                    case 'CAMPAIGN NAME':
                      return (
                        <DataTable.Cell
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={() => {
                            history.push(
                              `/campaign-leads?campaignId=${item._id}`
                            );
                          }}
                        >
                          {item?.campaignName}
                        </DataTable.Cell>
                      );
                    case 'DESCRIPTION':
                    case 'LEADS':
                    case 'AGENTS':
                    case 'CALLS':
                    case 'CONNECTED CALLS':
                      return (
                        <DataTable.Cell>
                          {item[CAMPAIGNS_MAPPER[header]] || '-'}
                        </DataTable.Cell>
                      );
                    case 'START DATE':
                    case 'END DATE':
                      return (
                        <DataTable.Cell>
                          {item[CAMPAIGNS_MAPPER[header]].slice(0, 10)}
                        </DataTable.Cell>
                      );
                    case 'START TIME':
                    case 'END TIME':
                      return (
                        <DataTable.Cell>
                          {moment(
                            item[CAMPAIGNS_MAPPER[header]].slice(0, 5),
                            'HH:mm'
                          ).format('hh:mm A')}
                        </DataTable.Cell>
                      );
                    case 'CAMPAIGN TYPE':
                      return (
                        <DataTable.Cell style={{ textTransform: 'capitalize' }}>
                          {item?.campaignType || 'dynamic'}
                        </DataTable.Cell>
                      );
                    case 'WAITING CALLS':
                      return (
                        <DataTable.Cell>
                          {item?.waitingRingingMetrics?.waitingCount || 0}
                        </DataTable.Cell>
                      );
                  }
                })}
                <DataTable.Cell
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '8px',
                  }}
                >
                  <CampaignOptions
                    id={item._id}
                    status={item.campaignStatus}
                    fetchCampaignListHandler={fetchCampaignListHandler}
                    toggleLeadListVisibility={toggleLeadListVisibility}
                    isHidden={item?.isHidden}
                  />
                </DataTable.Cell>
              </DataTable.Row>
            ))}
        </DataTable.Body>
      </DataTable.Table>
    );
  }

  const fetchCampaignListHandler = (filter = null) => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignStatus: current,
      pageNo: 1,
      pageSize: 15,
    };
    if (searchBy === 'name' && filter) {
      data.searchKey = searchBy;
      data.searchValue = searchValue;
    }
    if (searchBy === 'startDate' || (searchBy === 'endDate' && filter)) {
      data.searchKey = searchBy;
      data.fromDate = fromDate;
      data.toDate = endDate;
    }
    GET(cloudCallCenterUrl + 'campaign/get-campaigns', data)
      .then((res) => {
        setCamapignsList(res?.data?.response?.campaignData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateSearchBy = (e) => {
    console.log('The value of the selected is', e.target.value);
    setSearchBy(e.target.value);
  };

  const updateFilteredHeader = (header, isSelected) => {
    if (isSelected) {
      setFilteredHeaders((headers) => [...headers, header]);
      props.setCampaignTableHeaders({
        campaignTableHeaders: [...filteredHeaders, header],
      });
    } else {
      const unFilteredHeaders = filteredHeaders.filter(
        (headerType) => headerType !== header
      );
      setFilteredHeaders(unFilteredHeaders);
      props.setCampaignTableHeaders({
        campaignTableHeaders: unFilteredHeaders,
      });
    }
  };

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        {loading && <PreLoader />}
        {!loading && (
          <div className="ContentContainer">
            <TabSearchBar type={'campaigns'} tabs={tabs}></TabSearchBar>
            <div className="campaignsMainContainer">
              <span className="Overview">Overview</span>
              <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
                <DataCard
                  title="Number of campaigns "
                  count={callsData.totalOrgCampaigns}
                />
                <DataCard
                  title="Number of calls made"
                  count={callsData.totalCalls}
                />
                <DataCard
                  title="Number of calls connected"
                  count={callsData.totalAnsweredCalls}
                />
              </div>
              <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
                <DataCard
                  title="Total calls duration"
                  count={`${Math.ceil(callsData.totalDuration / 60)} m`}
                />
                <DataCard
                  title="Average call duration"
                  count={`${callsData.averageDurationOfCalls || 0} sec`}
                />
                <DataCard
                  title="Calls disposed"
                  count={callsData?.callsDisposed || 0}
                />
              </div>
            </div>
            <div className="TableFilter" style={{ marginLeft: '24px' }}>
              <TableHeaders
                headers={Object.keys(CAMPAIGNS_MAPPER)}
                updateFilteredHeader={updateFilteredHeader}
                filteredHeaders={filteredHeaders}
                isPopUpOpen={isHeadersPopUpOpen}
                openHeadersPopUp={() => setIsHeadersPopUpOpen(true)}
                closeHeadersPopUp={() => setIsHeadersPopUpOpen(false)}
              />
              <FilterTab />
              <div className="CampaignSearchBar">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div className="SearchText">SEARCH BY</div>
                  <select
                    className="SearchDropdown"
                    id="searchBy"
                    onChange={updateSearchBy}
                  >
                    <option>Select</option>
                    <React.Fragment>
                      <option value="name" selected={searchBy === "name" ? true : false}>CAMPAIGN NAME</option>
                      <option value="startDate" selected={searchBy === "startDate" ? true : false}>START DATE</option>
                      <option value="endDate" selected={searchBy === "endDate" ? true : false}>END DATE</option>
                    </React.Fragment>
                  </select>
                </div>
                {searchBy ? (
                  searchBy === 'name' ? (
                    <input
                      id="searchValue"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className="SearchBox"
                      placeholder="Search..."
                    />
                  ) : (
                    <React.Fragment>
                      <input
                        id="fromDate"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        className="SearchBox"
                        style={{ padding: '0px' }}
                        type="date"
                      />
                      <input
                        id="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        style={{ padding: '0px' }}
                        className="SearchBox"
                        type="date"
                      />
                    </React.Fragment>
                  )
                ) : null}
                <div>
                  {showClearResultsButton ? (
                    <button
                      className="ClearResultButton"
                      style={{
                        border: 'none',
                      }}
                      onClick={() => {
                        setSearchBy('');
                        setSearchValue('');
                        setFromDate('');
                        setEndDate('');
                        setShowClearResultsButton(false);
                        fetchCampaignListHandler(false);
                      }}
                    >
                      CLEAR RESULTS
                    </button>
                  ) : (
                    <button
                      className="SearchButton"
                      style={{ width: '100px', margin: '0 8px' }}
                      onClick={() => {
                        fetchCampaignListHandler(true);
                        setShowClearResultsButton(true);
                      }}
                    >
                      SEARCH
                    </button>
                  )}
                </div>
              </div>
            </div>

            <CampaignList campaignsList={campaignsList} />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  campaignTableHeaders: state.recovery.campaignTableHeaders,
});
export default styled(
  commonStyle(connect(mapStateToProps, { setCampaignTableHeaders })(Campaigns))
)`
.SearchBox {
  width: 136px;
  height: 32px;
  margin: auto 5px;
  font-size: 13px;
  padding: 0 10px;
  /* padding: 8px 16px 9px; */
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchDropdown {
  width: 136px;
  height: 32px;
  font-size: 13px;
  margin: auto 5px;
  padding: 0 5px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchButton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.ClearResultButton{
  width: 120px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
  width: '128px',
  border: none,
}

.SearchText {
  font-size: 12px;
  font-weight: 500;
  margin: auto 10px;
  /* letter-spacing: 1.2px; */
  text-align: left;
  color: ${(props) => props.theme.colors.searchByColor};
}

`;

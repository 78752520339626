import { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';

class RemovePopup extends Component {
  constructor() {
    super();
    this.state = {
      audio: null,
    };
  }

  closePopup = () => {
    this.props.closePopup();
  };

  handleRemoveCallerTune = () => {
    this.props.handleRemoveCallerTune();
  };

  render() {
    return (
      <Popup
        open={this.props.deletePopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Remove Callertune?</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupBody">
            <p className="callerTuneBodyTitle">
              Are you sure you want to remove this Callertune?
            </p>
            <p className="PopupNote">
              Note: The global Callertune will become the default Callertune for
              this users/group if you choose to remove their custom Callertune.
            </p>
            <button
              className="ButtonFullWidth BgRed"
              onClick={() => this.handleRemoveCallerTune()}
            >
              REMOVE
            </button>
          </div>
        </div>
      </Popup>
    );
  }
}

export default RemovePopup;

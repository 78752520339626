import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.button`
  width: 289px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #196cca;
  cursor: pointer;
  background-color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  // &:hover {
  //   background-color: navy;
  // }
`;

export default function Button(props) {
  return (
    <StyledButton
      onClick={props.onClick}
      style={props.style}
      className="CustomBtn"
    >
      {props.leftIcon && <img src={props.leftIcon} />}
      {props.label}
      {props.rightIcon && <img src={props.rightIcon} />}
    </StyledButton>
  );
}

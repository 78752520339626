import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { RiErrorWarningFill } from 'react-icons/ri';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { POST } from '../../../services/HttpRequests';
import PasswordCheck from '../../../common/component/passwordCheck/PasswordCheckComponent';
import { useAuth } from '../../../providers/AuthProvider';

import { commonStyle } from '../../../assets/css/css';
import '../../../assets/css/Content.css';
import Logo from '../../../assets/images/alohaaLogoAndBottomText.png';
import Wall from '../../../assets/images/loginPageWall.png';
import loaderIcon from '../../../assets/json/loader.json';
import Lottie from 'react-lottie';
const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderIcon,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const userUrl = process.env.REACT_APP_USER_API;
const planUrl = process.env.REACT_APP_PLAN_API;

export function SignUpPage(props) {
  const { saveSession } = useAuth();
  const [orgName, setOrgName] = useState('');
  const [orgEmail, setOrgEmail] = useState('');
  const [orgPhoneNumber, setOrgPhoneNumber] = useState('');
  const [orgCity, setOrgCity] = useState('');
  const [orgState, setOrgState] = useState('');
  const [orgStreet, setOrgStreet] = useState('');
  const [orgContactName, setOrgContactName] = useState('');
  const [adminName, setAdminName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPhoneNumber, setAdminPhoneNumber] = useState('');
  const [step, setStep] = useState(1);
  const [subStep, setsSubStep] = useState(1);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [secret, setSecret] = useState('');
  const [otp, setOtp] = useState('');
  const [sentOTP, setSentOTP] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState(false);
  const [userId, setUserId] = useState('');
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const planCodeParam = urlParams.get('plan_code');
  const isTrialParam = urlParams.get('is_trial');

  useEffect(() => {
    if (planCodeParam) {
      localStorage.setItem('alohaa_plan_code', planCodeParam);
      localStorage.setItem('alohaa_plan_is_trial', isTrialParam);
    }
  }, []);

  const validateEmail = (email) => {
    const filter = /^([a-zA-Z0-9_+\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return filter.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phoneNumber);
  };

  const sendOtp = async () => {
    if (!orgName) {
      setError('Please enter company name');
      return;
    }
    if (!adminName) {
      setError('Please enter name');
      return;
    }
    if (!adminEmail) {
      setError('Please enter email');
      return;
    }
    if (!adminPhoneNumber) {
      setError('Please enter phone number');
      return;
    }
    if (!validateEmail(adminEmail)) {
      setError('Invalid email address');
      return;
    }
    if (!validatePhoneNumber(adminPhoneNumber)) {
      setError('Invalid phone number');
      return;
    }
    if (checkMissingStep(1, 3)) {
      setError('fill all field');
    } else {
      setIsLoading(true);
      await POST(`${orgUrl}signup/send-otp`, { phoneNumber: adminPhoneNumber })
        .then((res) => {
          setSecret(res.data.response?.otpObj?.secretKey);
          setIsLoading(false);
          setSentOTP(true);
          setError('');
          goToStep(2, 3);
        })
        .catch((err) => {
          setError(err?.response?.data?.error?.reason);
          setIsLoading(false);
        });
    }
  };

  const resendOTP = async () => {
    setIsLoading(true);
    setIsActive(true);
    setSeconds(60);
    await POST(`${orgUrl}signup/send-otp`, { phoneNumber: adminPhoneNumber })
      .then((res) => {
        setSecret(res.data.response?.otpObj?.secretKey);
        setIsLoading(false);
        setSentOTP(true);
      })
      .catch((err) => {
        setError(err?.response?.data?.error?.reason);
        setIsLoading(false);
      });
  };

  const passwordChecker = (result) => {
    setCheckPassword(result);
  };

  const goToLogin = async (userId, organisations) => {
    try {
      setIsLoading(true);
      if (step === 3) {
        const response = await POST(`${userUrl}v1/auth/signup-login`, {
          userId: userId,
        });
        setError('');
        resetData();
        // localStorage.setItem('is_free_trial_enabled', true);
        // localStorage.setItem('free_trial_start_date', true);
        // localStorage.setItem('free_trial_end_date', true);
        saveSession(response.data.response, false);
        window.location.reload();
      }
    } catch (err) {
      setError(err?.response?.data?.error?.reason);
      setIsLoading(false);
      console.error('Error during login:', err);
    }
  };

  const submitCredentialsHandler = async () => {
    if (!checkPassword) {
      setError('meet all the requirements');
    } else if (newPassword !== confirmPassword) {
      setError('passswords are not matched');
    }
    setError('');
    console.log('submitted --- ');
    const formData = new FormData();
    formData.append('orgName', orgName);
    formData.append('orgCity', orgCity);
    formData.append('orgContactName', orgContactName);
    formData.append('orgEmail', orgEmail);
    formData.append(
      'orgAddress',
      '{"city":"' +
      orgCity +
      '","state":"' +
      orgState +
      '", "street":"' +
      orgStreet +
      '"}'
    );
    formData.append('orgPhoneNumber', orgPhoneNumber);
    formData.append('adminEmail', adminEmail);
    formData.append('adminPhoneNumber', adminPhoneNumber);
    formData.append('adminName', adminName);
    formData.append('secret', secret);
    formData.append('token', otp);
    formData.append('newPassword', newPassword);
    formData.append('confirmPassword', confirmPassword);
    formData.append('signupType', 'SELF');
    const respBody = {
      orgName,
      orgCity,
      orgContactName,
      orgEmail,
      orgAddress: { city: orgCity, state: orgState, street: orgStreet },
      orgPhoneNumber,
      adminEmail,
      adminPhoneNumber,
      adminName,
      secret,
      otp,
    };
    setIsLoading(true);
    if (
      !(checkMissingStep(1) && checkMissingStep(2) && checkMissingStep(3)) &&
      sentOTP
    ) {
      console.log(respBody);
      await POST(`${orgUrl}v1/free-trail-organisation`, formData)
        .then(async (res) => {
          console.log(res.data.response);
          setError('');
          resetData();
          await goToLogin(
            res.data.response?.admins?.user_id,
            res.data.response?.organisations
          );
        })
        .catch((err) => {
          setError(err?.response?.data?.error?.reason);
          setIsLoading(false);
        });
    } else {
      setError('check all the fields properly');
    }
  };

  const resetData = () => {
    setOrgName('');
    setOrgEmail('');
    setOrgPhoneNumber('');
    setOrgCity('');
    setOrgState('');
    setOrgStreet('');
    setOrgContactName('');
    setAdminName('');
    setAdminEmail('');
    setAdminPhoneNumber('');
    setSentOTP(false);
    setStep(1);
    setIsLoading(false);
    setError('');
    setSecret('');
    setOtp('');
    setNewPassword('');
    setConfirmPassword('');
    setNewPasswordVisible(false);
    setConfirmPasswordVisible(false);
    setCheckPassword(false);
  };

  const errorContainer = () => {
    return (
      <div className="ErrorContainer ErrorContainerMargin">
        {error ? (
          <React.Fragment>
            <div style={{ fontSize: 14 }}>
              <RiErrorWarningFill />
              &nbsp;
              {error}{' '}
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  };

  function ProgressStep({ currentStep }) {
    const steps = [1, 2, 3];
    const labels = ['Account Details', 'Verify OTP', 'Create Password'];

    return (
      <div className="steps">
        {steps.map((step, index) => (
          <div
            className={`step ${step <= currentStep ? 'active' : ''}`}
            key={index}
          >
            <div className="outer-circle">
              <div className="inner-circle">
                {step < currentStep ? '\u2713' : step}
              </div>
            </div>
            <div className="step-label">{labels[index]}</div>
          </div>
        ))}
      </div>
    );
  }

  const checkMissingStep = (step, subStep) => {
    if (step === 1 && subStep === 1) {
      if (!orgName || !orgEmail || !orgPhoneNumber || !orgContactName) {
        return true;
      }
      return false;
    } else if (step === 1 && subStep === 2) {
      if (!orgStreet || !orgCity || !orgState) {
        return true;
      }
      return false;
    } else if (step === 1 && subStep === 3) {
      if (!adminPhoneNumber || !adminEmail) {
        return true;
      }
      return false;
    } else if (step === 2) {
      if (!otp) {
        return true;
      }
      return false;
    } else if (step === 3) {
      if (!newPassword || !confirmPassword) {
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem('doosra-business-sessionToken') &&
      localStorage.getItem('doosra-business-sessionToken') !== ''
    ) {
      props.history.push('/');
    }
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, props.history, seconds, step]);

  const goToStep = (step, subStep) => {
    let subStepPage = subStep ? subStep - 1 : null;
    let stepPage = 1;
    if (step > 1) {
      stepPage = step - 1;
      subStepPage = null;
    }
    if (checkMissingStep(stepPage, subStepPage)) {
      setError('fill all field');
    } else {
      setStep(step);
      setsSubStep(subStep);
      setError('');
    }
  };

  return (
    <div className="login-main">
      <div className="section-1">
        <div className="login-container">
          <>
            <img className="login-container-logo" alt="" src={Logo} />
            <ProgressStep currentStep={step} />
          </>

          <div>
            {step === 1 && subStep === 1 ? (
              <div>
                <div className="AuthHeadContainer">
                  <p className="AuthSubHeader">Create an account</p>
                </div>
                <input
                  id="Company"
                  className="InputBox"
                  placeholder="Company"
                  onChange={(e) => setOrgName(e.target.value)}
                  value={orgName}
                />
                <input
                  id="adminName"
                  className="InputBox"
                  placeholder="Name"
                  onChange={(e) => setAdminName(e.target.value)}
                  value={adminName}
                />
                <input
                  id="adminEmail"
                  className="InputBox"
                  placeholder="Email"
                  onChange={(e) => setAdminEmail(e.target.value)}
                  value={adminEmail}
                />
                <input
                  id="adminPhone"
                  className="InputBox"
                  placeholder="Phone Number"
                  type="number"
                  onChange={(e) => setAdminPhoneNumber(e.target.value)}
                  value={adminPhoneNumber}
                />
                <br />
                {errorContainer()}
                {isLoading ? <Spinner animation="border"></Spinner> : null}
                <button className="AuthButton" onClick={sendOtp}>
                  SEND OTP
                </button>
              </div>
            ) : null}
            {step === 1 && subStep === 2 ? (
              <div>
                <div className="AuthHeadContainer">
                  <p className="AuthSubHeader">
                    Enter your company’s address details
                  </p>
                </div>
                <input
                  id="Street"
                  className="InputBox"
                  placeholder="Street"
                  onChange={(e) => setOrgStreet(e.target.value)}
                  value={orgStreet}
                />
                <input
                  id="City"
                  className="InputBox"
                  placeholder="City"
                  onChange={(e) => setOrgCity(e.target.value)}
                  value={orgCity}
                />
                <input
                  id="State"
                  className="InputBox"
                  placeholder="State"
                  onChange={(e) => setOrgState(e.target.value)}
                  value={orgState}
                />
                {errorContainer()}

                <button className="AuthButton" onClick={() => goToStep(1, 3)}>
                  NEXT
                </button>
              </div>
            ) : null}
            {step === 1 && subStep === 3 ? (
              <div>
                <div className="AuthHeadContainer">
                  <p className="AuthSubHeader">Create an admin</p>
                </div>
                <input
                  id="adminName"
                  className="InputBox"
                  placeholder="Name"
                  onChange={(e) => setAdminName(e.target.value)}
                  value={adminName}
                />
                <input
                  id="adminEmail"
                  className="InputBox"
                  placeholder="Email"
                  onChange={(e) => setAdminEmail(e.target.value)}
                  value={adminEmail}
                />
                <input
                  id="adminPhone"
                  className="InputBox"
                  placeholder="Phone Number"
                  type="number"
                  onChange={(e) => setAdminPhoneNumber(e.target.value)}
                  value={adminPhoneNumber}
                />
                <br />
                {errorContainer()}
                {isLoading ? <Spinner animation="border"></Spinner> : null}
                <button className="AuthButton" onClick={sendOtp}>
                  NEXT
                </button>
              </div>
            ) : null}

            {step === 2 ? (
              <div>
                <div className="AuthHeadContainer">
                  <p className="AuthSubHeader">
                    Verify the OTP we just sent you
                  </p>
                  <p className="otp-send-text">
                    OTP sent successfully to +91 {adminPhoneNumber}
                  </p>
                </div>

                <input
                  id="validateOtp"
                  className="InputBox"
                  placeholder="Enter OTP"
                  type="number"
                  onChange={(e) => setOtp(e.target.value)}
                  value={otp}
                />
                <br />
                {errorContainer()}
                <button className="AuthButton" onClick={() => goToStep(3)}>
                  NEXT
                </button>
                <div className="AlreadyHaveAccount">
                  Didn’t receive an OTP?{' '}
                  <button className="ResendButton" onClick={resendOTP}>
                    Resend
                  </button>
                </div>
              </div>
            ) : null}
            {step === 3 ? (
              <div>
                <div className="AuthHeadContainer">
                  <p className="AuthSubHeader">Create a new password</p>
                </div>
                <div>
                  <div className="PasswordInputHolder">
                    <input
                      id="password"
                      className="PasswordInput"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      type={newPasswordVisible ? 'text' : 'password'}
                      placeholder="Enter your new password"
                    />
                    {newPasswordVisible ? (
                      <AiFillEye
                        onClick={() => setNewPasswordVisible(false)}
                        style={{ color: 'red', fontSize: '22' }}
                      ></AiFillEye>
                    ) : (
                      <AiFillEyeInvisible
                        onClick={() => setNewPasswordVisible(true)}
                        style={{ color: 'grey', fontSize: '22' }}
                      ></AiFillEyeInvisible>
                    )}
                  </div>
                  <div className="PasswordInputHolder">
                    <input
                      id="confPassword"
                      className="PasswordInput"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type={confirmPasswordVisible ? 'text' : 'password'}
                      placeholder="Re-enter your new password"
                    />
                    {confirmPasswordVisible ? (
                      <AiFillEye
                        onClick={() => setConfirmPasswordVisible(false)}
                        style={{ color: 'red', fontSize: '22' }}
                      ></AiFillEye>
                    ) : (
                      <AiFillEyeInvisible
                        onClick={() => setConfirmPasswordVisible(true)}
                        style={{ color: 'grey', fontSize: '22' }}
                      ></AiFillEyeInvisible>
                    )}
                  </div>

                  <PasswordCheck
                    password={newPassword}
                    method="signup"
                    passwordChecker={passwordChecker}
                  />
                  {errorContainer()}
                  <button
                    className="AuthButton"
                    onClick={submitCredentialsHandler}
                  >
                    CREATE ACCOUNT
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <img alt="img" style={{ height: '100vh' }} src={Wall} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
});
export default commonStyle(connect(mapStateToProps, {})(SignUpPage));

import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import Button from '../../../common/component/button/Button';
import CsvDownloader from 'react-csv-downloader';
import { useEffect, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
import Close from '../../../assets/images/popupClose.png';
import Popup from '../../../common/component/popups/popups';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import downloadIconGreen from '../../../assets/images/downloadIconGreen.png';
import cancelIcon from '../../../assets/images/cancelIconCircleGreen.png';
import ModifyHeadersPopUp from '../component/ModifyHeadersPopUp';
import CallHistoryPopup from '../component/CallHistoryPopup';
import DataCard from '../component/DataCard';
import DataTable from '../../../common/component/table/DataTable';
import ExportAgentCampaignReport from '../component/ExportAgentCampaignReport';
import AudioPlayer from '../component/AudioPlayer';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import {ANENT_CALL_LOGS_MAPPER} from "../../../common/constants";
import { setCampaignCallsTableHeaders } from "../../../actions/recoveryXActions";

const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;


function CampaignReportTable(props) {
  const getStatus = (status) => {
    let res = "";
    if (status) {
      if (status === 'not_answered') {
        res = "Not Answered";
      }
      if (status === 'answered') {
        res = "Answered";
      }
    }
    return res;
  }

  const getHeader = (header) => {
    switch(header) {
      case "LEAD NAME" :
      case "LAST CALL DISPOSITION":
        return (<DataTable.CellHead width="60">{header}</DataTable.CellHead>)
      case "LEAD NUMBER": 
        return (<DataTable.CellHead width="40">{header}</DataTable.CellHead>)
      
      case "LAST CALL STATUS": 
        return (<DataTable.CellHead width="90">{header}</DataTable.CellHead>)
     
      case "LAST CALL DURATION":
      case "DATE AND TIME":
        return (<DataTable.CellHead width="100">{header}</DataTable.CellHead>)
  
      case "HISTORY": 
        return (<DataTable.CellHead width="60" style={{ textAlign: 'end' }}>{header}</DataTable.CellHead>)

      case "CALL RECORDING":
        return (<DataTable.CellHead width="160"  style={{ textAlign: 'end', paddingRight: "15px" }}>
          {header}
        </DataTable.CellHead>)

    }
  }

  const getHeaderData = (campaignId, agent, header) => {
    switch(header) {
      case "LEAD NAME" :
      case "LAST CALL DISPOSITION":
        return <DataTable.CellHead width="60">{agent?.[ANENT_CALL_LOGS_MAPPER[header]]}</DataTable.CellHead>;
      case "LEAD NUMBER": 
        return <DataTable.CellHead width="40">{agent?.[ANENT_CALL_LOGS_MAPPER[header]]}</DataTable.CellHead>;
      case "LAST CALL STATUS": 
        return <DataTable.CellHead width="90">{getStatus(agent?.[ANENT_CALL_LOGS_MAPPER[header]])}</DataTable.CellHead>;
      case "LAST CALL DURATION":
      case "DATE AND TIME":
        return <DataTable.CellHead width="100">{agent?.[ANENT_CALL_LOGS_MAPPER[header]]}</DataTable.CellHead>;
      case "HISTORY": 
        return <DataTable.CellHead width="60" style={{ textAlign: 'end' }}>
          <CallHistoryPopup campaignId={campaignId} destinationNumber={agent?.destinationNumber} />
        </DataTable.CellHead>;
      case "CALL RECORDING":
        return (
          <DataTable.CellHead width="160"  style={{ textAlign: 'end', paddingRight: "15px" }}>
            <AudioPlayer
                  callRecordingUrl={agent?.recording}
                  callId={agent._id}
                />
          </DataTable.CellHead>
        );
    }
  }

  
  return (
    <DataTable.Table>
      <DataTable.Head>
        <DataTable.CellHead width="20">#</DataTable.CellHead>
        {props?.headers?.length > 0 &&
          props?.headers?.map((header) => (getHeader(header)))
        }
      </DataTable.Head>
      <DataTable.Body>
        {props?.agentsList?.length > 0 &&
          props?.agentsList?.map((agent, index) => (
            <DataTable.Row id={agent.userId}>
              <DataTable.Cell width="20">{1 + index}</DataTable.Cell>
              {props?.headers?.length > 0 &&
          props?.headers?.map((header) => (getHeaderData(props.campaignId ,agent, header)))
        }
            </DataTable.Row>
          ))}
      </DataTable.Body>
    </DataTable.Table>
  );
}

export function CampaignCalls(props) {
  const [campaignData, setCampaignData] = useState({});
  const [filteredHeaders, setFilteredHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const agentId = searchParams.get('agentId');
  const campaignId = searchParams.get('campaignId');
  const [isHeadersPopUpOpen, setIsHeadersPopUpOpen] = useState(false);

  useEffect(() => {
    setFilteredHeaders(props.campaignCallsTableHeaders || [
      "LEAD NAME", 
      "LEAD NUMBER",
      "LAST CALL STATUS",
      "LAST CALL DURATION",
      "HISTORY"
    ])
  }, [props.campaignCallsTableHeaders])

  const handleFetchCampaignDetails = (agentId, campaignId) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
      agentId,
    };
    setLoading(true);
    GET(cloudCallCenterUrl + 'cloud-call-logs/get-agent-campaign-calls', data)
      .then((res) => {
        console.log('res', res.data.response);
        setCampaignData(res.data.response);
        setLoading(false);
      })
      .catch((err) => { console.log(err); setLoading(false); });
  };
  useEffect(() => {
    setFilteredHeaders(props.campaignCallsTableHeaders || [
      "LEAD NAME", 
      "LEAD NUMBER",
      "LAST CALL STATUS",
      "LAST CALL DURATION",
      "HISTORY"
    ])
  }, [props.campaignCallsTableHeaders])

  useEffect(() => {
    if (agentId && campaignId) {
      handleFetchCampaignDetails(agentId, campaignId);
    }
  }, [agentId, campaignId]);

  const updateFilteredHeader = (header, isSelected) => {
    console.log(header, isSelected)
    if(isSelected) {
      setFilteredHeaders(headers => [...headers, header])
      props.setCampaignCallsTableHeaders({campaignCallsTableHeaders: [...filteredHeaders, header]});
    } else {
      const unFilteredHeaders = filteredHeaders.filter((headerType) => headerType !== header)
      setFilteredHeaders(unFilteredHeaders);
      props.setCampaignCallsTableHeaders({campaignCallsTableHeaders: unFilteredHeaders});
    }
  }

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        {loading && <PreLoader />}
        {!loading && <div className="ContentContainer">

          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => history.goBack()}
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {campaignData?.campaignName ? `${campaignData?.campaignName} / ${campaignData?.agentName}` : 'name'}
              </p>
              <ExportAgentCampaignReport
                campaignName={campaignData?.campaignName}
                agentName={campaignData?.agentName}
                campaignData={campaignData?.agentCalls} 
              />
              <ModifyHeadersPopUp
                headers={Object.keys(ANENT_CALL_LOGS_MAPPER)}
                updateFilteredHeader={updateFilteredHeader}
                filteredHeaders={filteredHeaders}
                isPopUpOpen= { isHeadersPopUpOpen }
                openHeadersPopUp ={ () => setIsHeadersPopUpOpen(true )}
                closeHeadersPopUp= { () => setIsHeadersPopUpOpen(false)}
              />
            </div>
          </TabSearchBar>
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            <DataCard
              title="Calls"
              count={campaignData?.totalCalls}
            />
            <DataCard
              title="Connected Calls"
              count={campaignData?.answeredCalls}
            />
            {/* <DataCard
              title="Calls Unanswered"
              count={campaignData?.unAnsweredCalls}
            />
            <DataCard
              title="Call Answer Rate (%)"
              count={campaignData?.answerPercentage}
            /> */}
          </div>

          <CampaignReportTable 
          agentsList={campaignData?.agentCalls} 
          campaignId={campaignId} 
          headers={filteredHeaders
          }
          />
        </div>}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
  campaignCallsTableHeaders: state.recovery.campaignCallsTableHeaders,
});

export default commonStyle(connect(mapStateToProps, {setCampaignCallsTableHeaders})(CampaignCalls));

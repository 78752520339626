import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import DataCard from '../component/DataCard';
import DataTable from '../../../common/component/table/DataTable';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import { GET } from '../../../services/HttpRequests';
import Online from '../../../assets/images/smallCircleGreen.png';
import Offline from '../../../assets/images/smallCircleYellow.png';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import activeStatus from '../../../assets/images/activeStatus.svg';
import tea from '../../../assets/images/tea.svg';
import lunch from '../../../assets/images/lunch.svg';
import meeting from '../../../assets/images/meeting.svg';
import training from '../../../assets/images/training.svg';
import biobreak from '../../../assets/images/biobreak.svg';
import offline from '../../../assets/images/offline.svg';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;

function AgentsList(props) {
  /* AGENT ACTIVE STATUS */
  const optionsAgentActiveStatus = {
    'active': { label: 'Active', image: activeStatus, color: '#4daa3e' },
    'tea': { label: 'Tea', image: tea, color: '#196cca' },
    'lunch': { label: 'Lunch', image: lunch, color: '#196cca' },
    'meeting': { label: 'Meeting', image: meeting, color: '#196cca' },
    'training': { label: 'Training', image: training, color: '#196cca' },
    'biobreak': { label: 'Biobreak', image: biobreak, color: '#196cca' },
    //  'away', label: 'Away', image: away, color: '#f8bb44' },
    'offline': { label: 'Offline', image: offline, color: '#939598' },
    'Not Logged In': { label: 'Offline', image: offline, color: '#939598' },
    'busy': { label: 'Busy', image: offline, color: '#4daa3e' },
    'calling': { label: 'Ringing', image: offline, color: '#4daa3e' },
  };
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  return (
    <DataTable.Table>
      <DataTable.Head>
        <DataTable.CellHead width="20">#</DataTable.CellHead>
        <DataTable.CellHead width="100">AGENT NAME</DataTable.CellHead>
        <DataTable.CellHead width="40">CALLS</DataTable.CellHead>
        <DataTable.CellHead width="210">CONNECTED CALLS</DataTable.CellHead>
        <DataTable.CellHead width="140" style={{ textAlign: "End" }}>
          STATUS
        </DataTable.CellHead>
      </DataTable.Head>
      <DataTable.Body>
        {props?.agentsList?.length > 0 &&
          props?.agentsList?.map((agent, index) => (
            <DataTable.Row id={agent.agentId}>
              <DataTable.Cell>{1 + index}</DataTable.Cell>
              <DataTable.Cell
                style={{ cursor: 'pointer', textDecoration: "underline" }}
                onClick={() => {
                  history.push(`/recovery-x-campaign-view?agentId=${agent.agentId}&campaignId=${id}`);
                }}
              >{agent?.agentName}</DataTable.Cell>
              <DataTable.Cell>{agent?.agentTotalCampaignCalls}</DataTable.Cell>
              <DataTable.Cell>{agent?.agentTotalCampaignAnsweredCalls}</DataTable.Cell>
              <DataTable.Cell style={{ textAlign: "End" }}>
                <span
                  key={agent.status}
                  style={{
                    padding: '5px',
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    color: optionsAgentActiveStatus[agent.status]?.color,
                  }}
                >
                  {agent.status !== 'Not Logged In' &&
                    <>
                      <img
                        src={optionsAgentActiveStatus[agent.status]?.image}
                        style={{ fill: optionsAgentActiveStatus[agent.status].color }}
                        alt=".."
                      />
                      &nbsp;&nbsp;</>}
                  {agent.status.toUpperCase()}
                </span>
              </DataTable.Cell>
            </DataTable.Row>
          ))}
      </DataTable.Body>
    </DataTable.Table>
  );
}

export function RecoveryXCampaignDetails(props) {
  const [campaignData, setCampaignData] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  const fetchCampaignDetailsHandler = (campaignId) => {
    if (!campaignId) return;
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
    };
    setLoading(true);
    GET(outGoingUrl + 'v1/organisations/outbound-call/get-campaign', data)
      .then((res) => {
        setCampaignData(res?.data?.response);
        setLoading(false);
      })
      .catch((err) => { console.log(err); setLoading(false); });
  };

  const viewLeads = () => {
    console.log("Leads");
  }

  useEffect(() => {
    fetchCampaignDetailsHandler(id);
  }, [id]);

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        {loading && <PreLoader />}
        {!loading && <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => history.goBack()}
                alt=""
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {campaignData?.campaignName}
              </p>
              <button
                className="Otherbutton"
                style={{ marginRight: 0, color: "#07aa3f", border: "1px solid #07aa3f", background: "white" }}
                onClick={() => {
                  history.push(`/recovery-x-campaign/leads?campaignId=${id}`)
                }}
              >
                VIEW LEADS
              </button>
            </div>
          </TabSearchBar>
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            <DataCard
              title="Active Agents"
              count={campaignData?.activeAgents}
            />
            <DataCard
              title="Calls"
              count={campaignData?.campaignTotalCalls}
            />
            {/* <DataCard
              title="Number of calls not connected"
              count={campaignData?.campaignUnAnsweredCalls}
            />
            <DataCard
              title="Answer Percentage"
              count={campaignData?.answerPercentage}
            /> */}
          </div>
          <AgentsList agentsList={campaignData?.agentsData} />
        </div>}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(
  connect(mapStateToProps, {})(RecoveryXCampaignDetails)
);

import { useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { HorizontalDotsIcon } from '../../../assets/Icons/Icons';
import { PUT, DELETE } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import PreLoader from '../../../common/component/preLoader/PreLoader';
const broadcastUrl = process.env.REACT_APP_BROADCAST_API;

const PopupContainer = {
  width: '400px',
};
const scrollEffect = {
  overflowY: 'auto',
};

export default function BroadcastActions(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const history = useHistory();

  const deleteCampaignHandler = async () => {
    setLoading(true);
    DELETE(broadcastUrl + 'v1/voice-broadcast', { id: props.id })
      .then((res) => {
        setLoading(false);
        setIsDelete(false);
        props.refetch();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  const startStopCampaignHandler = async (action) => {
    setLoading(true);
    PUT(broadcastUrl + 'v1/voice-broadcast/status', null, {
      id: props.id,
      status: action,
    })
      .then((res) => {
        setLoading(false);
        props.refetch();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  return (
    <>
      {loading && <PreLoader />}
      <Popup open={isOpen}>
        <img
          style={{ left: '96%', top: '-5%', zIndex: '1' }}
          alt=""
          className="PopupClose"
          src={Close}
          onClick={() => {
            setIsOpen(false);
            setIsDelete(false);
          }}
        />
        {isDelete && (
          <div
            className="PopupContainer"
            style={{ ...PopupContainer, ...scrollEffect }}
          >
            <div className="PopupHeaderContainer" style={{ ...PopupContainer }}>
              <p>Delete Broadcast</p>
            </div>
            <div className="PopupBody" style={{ ...PopupContainer }}>
              <p className="PopupBodyTitle">
                Are you sure you want to delete the broadcast
              </p>
              <button
                className="ButtonCancelFullWidth"
                style={{ marginTop: '16px' }}
                onClick={deleteCampaignHandler}
              >
                Delete
              </button>
            </div>
          </div>
        )}
        {!isDelete && (
          <div
            className="PopupContainer"
            style={{ ...PopupContainer, ...scrollEffect }}
          >
            <div className="PopupHeaderContainer" style={{ ...PopupContainer }}>
              <p>Broadcast Options</p>
            </div>
            <div className="PopupBody" style={{ ...PopupContainer }}>
              {/* {props.status === 'PAUSED' && (
                <button
                  className="ButtonSecondary"
                  onClick={() => startStopCampaignHandler('INPROGRESS')}
                >
                  Resume
                </button>
              )} */}
              {/* {props.status === 'INPROGRESS' && (
                <button
                  className="ButtonSecondary"
                  onClick={() => startStopCampaignHandler('PAUSED')}
                >
                  Pause
                </button>
              )} */}

              <button
                className="ButtonSecondary"
                onClick={() => startStopCampaignHandler('STOPPED')}
              >
                Stop
              </button>

              {/* <button
                className="ButtonCancelFullWidth"
                style={{ marginTop: '16px' }}
                onClick={() => setIsDelete(true)}
              >
                Delete
              </button> */}
            </div>
          </div>
        )}
      </Popup>
      <HorizontalDotsIcon onClick={() => setIsOpen(true)} />
    </>
  );
}

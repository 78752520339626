import React, { Component } from 'react';
import '../../../assets/css/SearchBar.css';
import styled from "@emotion/styled";

class TabSearchBar extends Component {
  constructor() {
    super();
    this.state = {};
    this.initialState = this.state;
  }

  render() {
    const { tabs, className, children } = this.props;
      return (
        <div className={className} style={{ backgroundColor: '#f1f2f2', marginTop: '7px' }}>
        <div className="Tabs">
          {
            tabs?.map(({ title, subtitle, onClick, active }) => <div style={{marginRight:'2px'}}
                onClick={() => onClick(title)} className={"Tab " + (active ? "active" : "")}
            >
              <h6>{title}</h6>
              <p>
                {
                  typeof subtitle === 'function' ? subtitle()
                    : (typeof subtitle === 'string' ? subtitle : null)
                }
              </p>
              {active && <div className='TabIndicator' />}
            </div>)
          }
        </div>
      </div>
    );
  }
}

export default styled(TabSearchBar)`
  height: 54px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;  
  align-items: center;
  flex-direction: row;

  .Tabs {
    display: flex;
    flex-direction: row;
  }

  .Tab {
    cursor: pointer;
    position: relative;
    min-width: 136px;
    height: 54px;
    padding: 13px 16px;
    background-color: ${props => props.theme.colors.tabColor};
    display: flex;
    flex-direction: column;
    justify-content: center;

    h6 {
      margin: 0;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #939598;

      font-size: 12px;
      font-weight: bold;
      color: ${props => props.theme.colors.tabTextColor};
    }

    p {
      font-size: 11px;
      font-weight: bold;
      margin: 0;
      letter-spacing: 1.2px;
      color: ${props => props.theme.colors.tabTextColor};
      margin: 0;
      opacity: 0.8;
      font-family: Montserrat;
      font-weight: 600;
      line-height: 1.4;
      text-align: left;
    }

    &.active {
      background-color: ${props => props.theme.colors.tabSelectedColor};

      h6, p {
        color: ${props => props.theme.colors.tabSelectedTextColor};
      }
    }
  }

  .TabIndicator {
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${props => props.theme.colors.tabSelectedTextColor};
  }
`;

const initialState = {
  callStatus: '',
  callId: '',
  destinationNumber: '',
  destinationName: '',
  RTCSession: null,
  headers: [],
  isWebRtcConnected: false,
  dialerCallId: '',
  agentActiveStatus: false,
  webRTCSessions: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  console.log('action', action);
  switch (action.type) {
    case 'SET_CALL_DETAILS':
      return {
        ...state,
        callStatus: action.payload.status,
      };
    case 'SET_BATCH_CALL_ID':
      return {
        ...state,
        batchCallId: action.payload.batchCallId,
      };
    case 'SET_CALL_ID':
      return {
        ...state,
        callId: action.payload.callId,
      };
    case 'SET_CALL_STATUS':
      return {
        ...state,
        callStatus: action.payload.status,
      };
    case 'TOGGLE_AUTO_CALLING':
      return {
        ...state,
        autoCalling: action.payload.autoCalling,
      };
    case 'SET_AUTO_CALLING':
      return {
        ...state,
        autoCalling: action.payload.autoCalling,
      };
    case 'AGENT_ACTIVE_STATUS':
      console.log('action', action);
      return {
        ...state,
        agentActiveStatus: action.payload.status,
      };
    case 'SET_WEBRTC_CONN':
      return {
        ...state,
        isWebRtcConnected: action.payload.status,
      };
    case 'SET_DESTINATION_NUMBER':
      return {
        ...state,
        destinationNumber: action.payload.destinationNumber,
      };
    case 'SET_DESTINATION_NAME':
      return {
        ...state,
        destinationName: action.payload.destinationName,
      };
    case 'SET_RTC_SESSION':
      return {
        ...state,
        RTCSession: action.payload.RTCSession,
      };
    case 'SET_HEADERS':
      return {
        ...state,
        headers: action.payload.headers,
      };
    case 'SET_WEBRTC_CONN':
      return {
        ...state,
        isWebRtcConnected: action.payload.status,
      };
    case 'SET_WEBRTC_SESSIONS':
      return {
        ...state,
        webRTCSessions: action.payload.sessions,
      };
    default:
      return state;
  }
}

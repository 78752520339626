import React, { useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import Delete from '../../../assets/images/deleteWRedBackground.png';

export default function DeleteMessagePopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="PopupContainer">
        <div className="PopupHeaderContainer">
          <p>Delete {props.count} message(s) permanently?</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => setPopupOpen(false)}
          />
        </div>
        <div className="PopupBody">
          {/* <p className="PopupBodyTitle">This action cannot be undone.</p> */}
          <button
            className="ButtonFullWidth BgRed"
            style={{ marginTop: '16px', color: 'white' }}
            onClick={() => {
              props.deleteMessageHandler();
              setPopupOpen(false);
            }}
          >
            DELETE
          </button>
          <button
            style={{
              backgroundColor: 'white',
              color: 'red',
              border: '1px solid red',
              marginTop: '16px',
            }}
            className="ButtonFullWidth BgRed"
            onClick={() => {
              setPopupOpen(false);
            }}
          >
            CANCEL
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <React.Fragment>
      {
        <img
          alt=""
          onClick={() => setPopupOpen(true)}
          src={Delete}
          style={{ margin: '8px', cursor: 'pointer' }}
        />
      }
      {popper}
    </React.Fragment>
  );
}

import React, { useEffect, useState } from 'react';
import '../../../assets/css/Popups.css';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import CrossRedCircle from '../../../assets/images/Cross_red_circle.svg';
import InfoRed from '../../../assets/images/infoRed.png';
import moment from 'moment';
import { ADD_ANOTHER_NOTES_BUTTON, SAVE_NOTES_BUTTON, DELETE_NOTES_BUTTON } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";

const noteUrl = process.env.REACT_APP_NOTES_SERVICES;
const organisationId = localStorage.getItem('doosra-biz-organisation-id');

export default function Notes(props) {
    const contactId = props.contactId;
    const [notesLoading, setNotesLoading] = useState(false);
    const [hide, setHide] = useState(false);
    const [noteList, setNoteList] = useState([]);
    const [showNoteInput, setShowNoteInput] = useState(props.action == 'ADD' ? true : false);
    const [noteText, setNoteText] = useState("");
    const [noteTotal, setNoteTotal] = useState(0);
    const [error, setError] = useState(null);
    const fetchNotesList = () => {
        if (contactId && organisationId) {
            setNotesLoading(true);

            let data = {
                contactId,
                organisationId,
                pageNum: 1,
                pageSize: 1000,
                userId: props?.userId
            };
            GET(noteUrl + `v1/notes`, data)
                .then((res) => {
                    return res.data.response;
                })
                .then((res) => {
                    setNoteList(res?.notes || []);
                    setNoteTotal(res?.metadata?.total || 0)
                    setNotesLoading(false);
                })
                .catch((err) => {
                    console.error("fetchNotesList: error", { err })
                    setNotesLoading(false);
                });
        }
    };

    const SaveNotes = (text) => {
        const note = text || noteText;
        if (!note) {
            setError("Please Enter Notes");
        }
        if (note) {
            const data = {
                contactId,
                organisationId,
                note
            }
            setNotesLoading(true);
            POST(noteUrl + `v1/notes`, data)
                .then((res) => {
                    setNotesLoading(false);
                    fetchNotesList();
                    toast.success("Add Notes Successfully");
                })
                .catch((err) => {
                    setNotesLoading(false);
                    toast.error(err?.response?.data?.error?.reason);
                });
            setError(null);
            setShowNoteInput(false);
            setHide(false);
        }

    }

    const deleteNote = (id) => {
        logPostHogEvent(DELETE_NOTES_BUTTON);
        if (!id) {
            setShowNoteInput(false);
            setHide(false);
        }
        if (id) {
            setNotesLoading(true);
            setNoteList([]);
            DELETE(noteUrl + 'v1/notes', null, { id })
                .then((res) => {
                    return res?.data?.response;
                }).then((res) => {
                    setNotesLoading(false);
                    fetchNotesList();
                    toast.success("Delete Notes Successfully");
                })
                .catch((err) => {
                    console.error("deleteNote:error", { err });
                    setNotesLoading(false);
                    toast.error(err?.response?.data?.error?.reason);
                });
        }
    }


    useEffect(() => { fetchNotesList(); }, []);

    return (
        <div
            className="PopupInputContainer" style={{ marginTop: "50px" }}>
            <label className="PopupInputLabel" >Notes ({noteTotal})</label>
            {notesLoading && <PreLoader />}
            <div style={{ display: (showNoteInput) ? 'none' : 'block', marginBottom: "7px" }}>
                {noteList?.length > 0 &&
                    noteList.map((item, index) => {
                        if (!hide && index > 0) {
                            return (<></>)
                        } else {
                            const datFotmat = moment(item.createdAt).format("MMM DD,YYYY, hh:mm a");
                            return (
                                <div key={index} className="TagOutLay" style={{ marginBottom: 10 }}>
                                    <span key={index}>{item.note}</span>
                                    <div key={index} className='NotesDateTxt'>{datFotmat}</div>
                                    {/* <a className='NotesDeleteBtn' onClick={(e) => deleteNote(item._id)}
                                    style={{ display: (props.action == 'VIEW') ? 'none' : 'block' }}
                                >Delete</a> */}
                                    <img src={CrossRedCircle}
                                        className='TagsCloseBtn'
                                        style={{ display: (props.action == 'VIEW') ? 'none' : 'block' }}
                                        onClick={(e) => deleteNote(item._id)} />
                                </div>)
                        }
                    })}
            </div>
            {showNoteInput && (
                <div style={{ height: "82px", position: 'relative' }}>
                    <textarea
                        placeholder='write a note'
                        className="PopupInput"
                        id="notes"
                        value={noteText}
                        onChange={(e) => {
                            setNoteText(e.target.value);
                        }}
                        style={{ height: "76px" }}
                    />
                    <img src={CrossRedCircle}
                        className='TagsCloseBtn'
                        style={{ display: (props.action == 'VIEW') ? 'none' : 'block', right: "25px" }}
                        onClick={(e) => deleteNote()} />
                </div>
            )}

            <div style={{ display: 'flex' }}>
                <div className='ContactAddBtn'>
                    <div
                        style={{ display: (hide || props.action == 'VIEW') ? 'none' : 'block' }}
                        onClick={() => {
                            logPostHogEvent(ADD_ANOTHER_NOTES_BUTTON);
                            setShowNoteInput(true);
                            setNoteText('');
                        }}
                    >
                        +Add another notes
                    </div>
                    <div
                        style={{ display: hide ? 'block' : 'none' }}
                        onClick={() => {
                            setShowNoteInput(false);
                            setHide(false);
                        }}
                    >
                        Hide all notes
                    </div>
                </div>
                <div className='ContactSaveBtn'>
                    <div onClick={(e) => {
                        logPostHogEvent(SAVE_NOTES_BUTTON);
                        SaveNotes();
                    }}
                        style={{ display: !showNoteInput ? 'none' : 'block' }}> Save Note</div>
                    <div onClick={(e) => {
                        setShowNoteInput(false);
                        setHide(true);
                    }}
                        style={{ display: (hide || showNoteInput || noteTotal <= 0) ? 'none' : 'block' }}
                    >
                        View All Notes</div>
                </div>
            </div>

            {error ? (
                <div className="ErrorContainer ErrorContainerMargin">
                    <img
                        alt=""
                        src={InfoRed}
                        style={{
                            paddingRight: '5px',
                            position: 'relative',
                            top: '-2px',
                        }}
                    />
                    {error}
                </div>
            ) : null}

        </div>
    );
};



import React, { useState } from 'react';
import Close from '../../../assets/images/popupClose.png';
import Popup from '../../../common/component/popups/popups';

export default function ModifyHeadersPopUp(props) {
  const [isSelectHeadersPopUpOpen, setIsSelectHeadersPopUpOpen] = useState(props.isPopUpOpen || false);
  const buttonJSX = (
    <button
      className="Otherbutton"
      style={{
        width: "150px",
        color: 'black',
        backgroundColor: "#ebf2ed"
      }}
      onClick={() => {
        setIsSelectHeadersPopUpOpen(
          isPopUpOpen => !isPopUpOpen
        );
        if (props.openHeadersPopUp) {
          props.openHeadersPopUp();
        }
      }}
    >
      TABLE HEADERS
    </button>
  )
  const popUpJSX =
    isSelectHeadersPopUpOpen ?
      (
        <Popup
          open={isSelectHeadersPopUpOpen} onClose={
            () => { setIsSelectHeadersPopUpOpen(isPopUpOpen => !isPopUpOpen) }
          }
        >
          <div className="AddCallRemarksPopup">
            <div className="PopupContainer">
              <div className="PopupHeaderContainer">
                <p>Select Headers</p>
                <img
                  alt=""
                  className="PopupClose"
                  src={Close}
                  onClick={() => {
                    setIsSelectHeadersPopUpOpen(isPopUpOpen => !isPopUpOpen)
                    props.closeHeadersPopUp()
                  }}
                />
              </div>
              <div className="PopupBody">
                <div className="PopupInputContainer">
                  {
                    props.headers.map((header) =>
                    (
                      <div style={
                        {
                          display: "flex",
                          borderBottom: "2px solid black",
                          marginTop: "10px",
                          alignItems: "center"
                        }}
                      >
                        <label className='switch'

                        >
                          <input
                            type="checkbox"
                            style={{ marginLeft: "55px" }}
                            value={header}
                            onChange={(e) => props.updateFilteredHeader(header, e.target.checked)}
                            checked={props.filteredHeaders.indexOf(header) === -1 ? false : true}
                          />
                          <span class="slider round"></span>

                        </label>
                        <p
                          style={{ marginLeft: "10px", width: "350px" }}
                        >{header}</p>
                      </div>
                    )
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </Popup>
      )
      : null
  return (
    isSelectHeadersPopUpOpen ? popUpJSX : buttonJSX
  )
}
import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import UpgradeServices from '../../../common/component/upgrade-services/UpgradeServices';
import GlobalCallerTune from '../component/GlobalCallerTune';
import UserCallerTune from '../component/UserCallerTune';
import GroupCallerTune from '../component/GroupCallerTune';
import { ADMIN_GLOBAL_CALLERTUNE_TAB, ADMIN_GROUP_CALLERTUNE_TAB, ADMIN_AGENT_CALLERTUNE_TAB } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";

import { commonStyle } from '../../../assets/css/css';

const searchHandlersReducer = (state, action) => {
  switch (action.type) {
    case 'agent':
      return {
        ...state,
        agent: { reset: action.reset, search: action.search },
      };
    case 'group':
      return {
        ...state,
        group: { reset: action.reset, search: action.search },
      };
    default:
      return state;
  }
};

const CallerTunePage = ({
  className,
  sidebarClosed,
  callPriorityRoutingStatus,
  isCallerTuneEnabled,
  callForwardingStatus,
  isCallerTuneEmailSent,
}) => {
  const createTabs = useCallback(() => {
    const baseTabs = [
      { title: 'Global Callertune', active: true },
      { title: 'Agent CallerTune', active: false },
    ];
    if (callPriorityRoutingStatus) {
      baseTabs.push({ title: 'Group Callertune', active: false });
    }
    return baseTabs;
  }, [callPriorityRoutingStatus]);

  const [tabs, setTabs] = useState(createTabs);
  const [searchHandlers, dispatch] = useReducer(searchHandlersReducer, {
    agent: { reset: null, search: null },
    group: { reset: null, search: null },
  });

  const onChangeTab = (title) => {
    if (title === "Global Callertune") {
     logPostHogEvent(ADMIN_GLOBAL_CALLERTUNE_TAB);
    }
    if (title === "Group Callertune") {
      logPostHogEvent(ADMIN_GROUP_CALLERTUNE_TAB);
    }
    if (title === "Agent Callertune") {
      logPostHogEvent(ADMIN_AGENT_CALLERTUNE_TAB);
    }
    setTabs(tabs.map((tab) => ({ ...tab, active: tab.title === title })));
  };

  useEffect(() => {
    setTabs(createTabs());
  }, [createTabs]);

  const isEmailUserServiceEnabled = (
    userRole,
    callForwardingStatus,
    isCallerTuneEnabled
  ) => {
    return (userRole === 'USER' || callForwardingStatus) && isCallerTuneEnabled;
  };

  const userRole = localStorage.getItem('doosra-biz-user-role');
  const enableEmailUserService = isEmailUserServiceEnabled(
    userRole,
    callForwardingStatus,
    isCallerTuneEnabled
  );

  if (!enableEmailUserService) {
    return (
      <div className={`${className}`}>
        <Sidebar />
        <div className={sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />
          <div className="ContentContainer">
            <UpgradeServices
              icon="callerTune"
              heading="Caller tunes"
              text="You can customize caller tunes for each agent, group or for your whole org."
              emailSent={isCallerTuneEmailSent}
            />
          </div>
        </div>
      </div>
    );
  }

  const getSearchBarType = () => {
    if (tabs[1].active) {
      return 'agent-caller-tune';
    } else if (tabs[2]?.active) {
      return 'group-caller-tune';
    }
  };

  const activeSearchHandlers = tabs[1].active
    ? searchHandlers.agent
    : searchHandlers.group;

  return (
    <div className={className}>
      <Sidebar />
      <div className={sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar
            tabs={tabs.map((tab) => ({
              ...tab,
              onClick: () => onChangeTab(tab.title),
            }))}
          >
            {(tabs[1].active || tabs[2]?.active) && (
              <SearchBar
                type={getSearchBarType()}
                disableTitle
                reset={activeSearchHandlers.reset}
                search={activeSearchHandlers.search}
              />
            )}
          </TabSearchBar>
          {tabs[0].active && <GlobalCallerTune />}
          {tabs[1].active && (
            <UserCallerTune
              onRegisterSearchHandlers={(reset, search) =>
                dispatch({ type: 'agent', reset, search })
              }
            />
          )}
          {tabs[2]?.active && (
            <GroupCallerTune
              onRegisterSearchHandlers={(reset, search) =>
                dispatch({
                  type: 'group',
                  reset,
                  search,
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  callPriorityRoutingStatus: state.topbar.call_priority_routing,
  isCallerTuneEnabled: state.topbar.isCallerTuneEnabled,
  isCallerTuneEmailSent: state.topbar.isCallerTuneEmailSent,
  callForwardingStatus: state.topbar.call_forwarding_status,
});

export default commonStyle(connect(mapStateToProps, {})(CallerTunePage));

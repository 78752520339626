import moment from 'moment';
import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';

import '../../../assets/css/Content.css';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import InfoRed from '../../../assets/images/infoRed.png';
import { EditIcon } from '../../../assets/Icons/Icons';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import { toast } from 'react-toastify';
import {
  ADMIN_DID_CALL_BACK_TAB,
  ADMIN_INCOMING_TAB,
  ADMIN_BLACKLIST_TAB,
  ADMIN_OUTGOING_TAB,
  ADMIN_BLACKLIST_ADD_NUMBER_BUTTON,
  ADMIN_BLACKLIST_DELETE_NUMBER_BUTTON,
  ADMIN_BLACKLIST_EDIT_NUMBER_BUTTON,
  ADMIN_BLACKLIST_UNBLOCK_NUMBER_TOGGLE,
  ADMIN_BLACKLIST_BLOCK_NUMBER_TOGGLE,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';

const outgoingCallsUrl = process.env.REACT_APP_OUTGOING_CALLS_API;
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;
const organisationId = localStorage.getItem('doosra-biz-organisation-id');

const CallBlackListPage = (props) => {
  const [callBlackList, setCallBlackList] = useState([]);
  const [callBlackListCount, setCallBlackListCount] = useState(0);
  const [didCallBackCount, setDidCallBackCount] = useState(0);
  const [outgoingCallLogCount, setOutgoingCallLogCount] = useState(0);
  const [incomingCallLogCount, setIncomingCallLogCount] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [error, setError] = useState('');
  const [type, setType] = useState('INCOMING');
  const [blockType, setBlockType] = useState('block');
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [blackListObj, setBlackListObj] = useState({});
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    console.log('inside UseEffect');
    fetchCallBlackList();
    // getDIDCallBackCount();
    getOutgoingCallLogCount();
    getIncomingCallLogCount();
  }, []);

  const fetchCallBlackList = async () => {
    console.log('inside fetchCallBlackList');
    GET(`${incomingCallsUrl}v1/organisations/black-list`, {
      pageNo: 1,
      pageSize: 20,
      organisationId,
    })
      .then((res) => {
        setCallBlackList(res?.data?.response?.payload?.docs);
        setCallBlackListCount(res?.data?.response?.payload?.count);
        setTotalPages(Math.ceil(res?.data?.response?.payload?.count / 20));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  const handlePagination = async (pageNumber) => {
    setCurrentPage(pageNumber);
    GET(`${incomingCallsUrl}v1/organisations/black-list`, {
      pageNo: pageNumber,
      pageSize: 20,
      organisationId,
    })
      .then((res) => {
        setCallBlackList(res?.data?.response?.payload?.docs);
        setCallBlackListCount(res?.data?.response?.payload?.count);
        setTotalPages(Math.ceil(res?.data?.response?.payload?.count / 20));
      })
      .catch((err) => console.log(err));
  };

  const getOutgoingCallLogCount = () => {
    GET(`${outgoingCallsUrl}v1/organisations/outbound-call/call-log/list`, {
      pageNo: 1,
      pageSize: 10,
      organisationId,
    })
      .then((res) => res.data.response)
      .then((res) => setOutgoingCallLogCount(res?.metadata?.total))
      .catch((err) => null);
  };
  const getDIDCallBackCount = () => {
    GET(`${incomingCallsUrl}v1/organisations/did-callback-list`, {
      pageNo: 1,
      pageSize: 10,
      organisationId,
    })
      .then((res) => res.data.response)
      .then((res) => setDidCallBackCount(res?.payload?.count))
      .catch((err) => console.log(err));
  };

  const getIncomingCallLogCount = () => {
    GET(`${incomingCallsUrl}v1/organisations/calls`, {
      pageNo: 0,
      pageSize: 1,
      organisation_id: organisationId,
    })
      .then((res) => res.data.response)
      .then((res) => setIncomingCallLogCount(res?.payload?.count))
      .catch((err) => console.log(err));
  };

  const handlePopup = () => {
    setIsPopupOpen(true);
  };

  const handleCreateBlackList = () => {
    logPostHogEvent(ADMIN_BLACKLIST_ADD_NUMBER_BUTTON);
    if (!name || !number) {
      setError('Name, Phone Number are required fields.');
    } else {
      const body = {
        name,
        number,
        type,
        status: 'block',
        organisationId,
      };
      POST(`${incomingCallsUrl}v1/organisations/black-list`, body)
        .then((res) => {
          setName('');
          setNumber('');
          setType('INCOMING');
          setIsPopupOpen(false);
          fetchCallBlackList();
        })
        .catch((err) => {
          console.log('err======', err);
          toast.error(err?.response?.data?.error?.reason || 'An error occured');
        });
    }
  };

  const handleDeleteBlackList = () => {
    logPostHogEvent(ADMIN_BLACKLIST_DELETE_NUMBER_BUTTON);
    const body = {
      documentId: blackListObj?._id,
      organisationId,
    };
    DELETE(`${incomingCallsUrl}v1/organisations/black-list`, {}, body)
      .then((res) => {
        setIsDeletePopupOpen(false);
        fetchCallBlackList();
      })
      .catch((err) => {
        console.log('err======', err);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  const handleEidtBlackListPopup = (item) => {
    setIsEditPopupOpen(true);
    setBlackListObj(item);
    setName(item?.name);
    setNumber(item?.phone_number);
    setType(item?.blockFor);
    setBlockType(item?.block === true ? 'block' : 'unblock');
  };

  const handleEditBlackList = () => {
    logPostHogEvent(ADMIN_BLACKLIST_EDIT_NUMBER_BUTTON);
    const body = {
      name,
      number,
      type,
      status: blockType,
      documentId: blackListObj?._id,
      organisationId,
    };
    PUT(`${incomingCallsUrl}v1/organisations/black-list`, {}, body)
      .then((res) => {
        setIsEditPopupOpen(false);
        fetchCallBlackList();
        setName('');
        setNumber('');
      })
      .catch((err) => {
        console.log('err======', err);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  const userRole = localStorage.getItem('doosra-biz-user-role');
  let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
  isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
  const tabs = [];

  if (
    (userRole === 'ADMIN' && props.callForwardingStatus) ||
    // (userRole === 'USER' && callForwardingStatus && isCallForwardingAllowed)
    (userRole === 'USER' && props.callForwardingStatus)
  )
    tabs.push({
      title: 'Incoming',
      subtitle: `${incomingCallLogCount}`,
      onClick: () => {
        logPostHogEvent(ADMIN_INCOMING_TAB);
        props.history.push('/call-logs');
      },
    });

  if (
    (userRole === 'ADMIN' && props.isOrgOutboundCallEnabled) ||
    (userRole === 'USER' &&
      props.isOutboundCallEnabled &&
      props.isOrgOutboundCallEnabled)
  ) {
    // if (!isFreeTrail) {
    //   tabs.push({
    //     title: 'Callback',
    //     subtitle: `${didCallBackCount}`,
    //     onClick: () => {
    //       logPostHogEvent(ADMIN_DID_CALL_BACK_TAB);
    //       props.history.push('/did-call-back');
    //     },
    //   });
    // }
    tabs.push({
      title: 'Outgoing',
      subtitle: `${outgoingCallLogCount}`,
      onClick: () => {
        logPostHogEvent(ADMIN_OUTGOING_TAB);
        props.history.push('/outgoing-call-logs');
      },
    });
  }

  if (userRole === 'ADMIN') {
    tabs.push({
      title: 'BlackList',
      subtitle: `${callBlackListCount}`,
      onClick: () => {
        logPostHogEvent(ADMIN_BLACKLIST_TAB);
        props.history.push('/black-list');
      },
      active: true,
    });
  }
  return (
    <div className={props.className}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar tabs={tabs}>
            <button
              style={{ backgroundColor: '#07aa3f', color: 'white' }}
              className="Otherbutton"
              onClick={handlePopup}
            >
              ADD NUMBER
            </button>
          </TabSearchBar>
          <DataTable.Table>
            <DataTable.Head>
              <DataTable.CellHead width="70"> NAME </DataTable.CellHead>
              <DataTable.CellHead width="70">NUMBER</DataTable.CellHead>
              <DataTable.CellHead width="70">TYPE</DataTable.CellHead>
              <DataTable.CellHead width="70">STATUS</DataTable.CellHead>
              <DataTable.CellHead width="70">CREATED AT</DataTable.CellHead>
              <DataTable.CellHead width="70">ACTIONS</DataTable.CellHead>
            </DataTable.Head>
            <DataTable.Body>
              {(callBlackList || []).map((item) => (
                <DataTable.Row key={item?._id}>
                  <DataTable.Cell>{item?.name}</DataTable.Cell>
                  <DataTable.Cell>{item?.phone_number}</DataTable.Cell>
                  <DataTable.Cell>{item?.blockFor}</DataTable.Cell>
                  <DataTable.Cell>
                    {item?.block ? 'Blocked' : 'Unblocked'}
                  </DataTable.Cell>
                  <DataTable.Cell>
                    {moment(item?.createdAt).format('DD/MM/YYYY')}
                  </DataTable.Cell>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ padding: '5px' }}>
                      <EditIcon
                        onClick={() => {
                          handleEidtBlackListPopup(item);
                        }}
                      />
                    </div>
                    <div style={{ padding: '5px' }}>
                      <img
                        alt=""
                        onClick={() => {
                          setIsDeletePopupOpen(true);
                          setBlackListObj(item);
                        }}
                        src={Delete}
                      />
                    </div>
                  </div>
                </DataTable.Row>
              ))}
              {callBlackList.length <= 0 && (
                <DataTable.Row>
                  <DataTable.Cell colSpan={9} style={{ textAlign: 'center' }}>
                    No Records Found
                  </DataTable.Cell>
                </DataTable.Row>
              )}
            </DataTable.Body>
          </DataTable.Table>

          <Paginator
            currentPage={currentPage}
            lastPage={totalPages}
            getInfo={(value) => handlePagination(value)}
          />
        </div>
      </div>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>ADD BLACKLIST NUMBER</p>
            <img
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsPopupOpen(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">NAME</label>
              <input
                className="PopupInput"
                id="emp_id"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">NUMBER</label>
              <input
                type="number"
                className="PopupInput"
                id="emp_id"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </div>
            {error ? (
              <div className="ErrorContainer ErrorContainerMargin">
                <img
                  alt=""
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
                {error}
              </div>
            ) : null}
            <button
              className="ButtonFullWidth BgGreen"
              onClick={handleCreateBlackList}
              style={{ marginTop: '20px' }}
            >
              SAVE
            </button>
          </div>
        </div>
      </Popup>

      <Popup
        open={isDeletePopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>DELETE BLACKLIST</p>
            <img
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsDeletePopupOpen(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupBodyTitle">
              Are you sure you want to remove this blacklist ?
            </p>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">NAME</label>
              <input
                className="PopupInput"
                id="emp_id"
                value={blackListObj?.name}
                disabled="disabled"
              />
            </div>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">NUMBER</label>
              <input
                type="number"
                className="PopupInput"
                id="emp_id"
                value={blackListObj?.phone_number}
                disabled="disabled"
              />
            </div>
            <button
              className="ButtonFullWidth BgGreen"
              onClick={handleDeleteBlackList}
              style={{ marginTop: '20px' }}
            >
              DELETE
            </button>
          </div>
        </div>
      </Popup>

      <Popup
        open={isEditPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>EDIT BLACKLIST</p>
            <img
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsEditPopupOpen(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">NAME</label>
              <input
                className="PopupInput"
                id="emp_id"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">NUMBER</label>
              <input
                type="number"
                className="PopupInput"
                id="emp_id"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </div>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">TYPE</label>
            </div>
            <div className="PopupInputContainer">
              <label>
                <input
                  type="radio"
                  value="standard"
                  checked={blockType === 'block'}
                  onChange={() => {
                    logPostHogEvent(ADMIN_BLACKLIST_BLOCK_NUMBER_TOGGLE);
                    setBlockType('block');
                  }}
                  style={{ marginRight: '5px' }}
                />
                Block
              </label>
              <label>
                <input
                  type="radio"
                  value="standard"
                  checked={blockType === 'unblock'}
                  onChange={() => {
                    logPostHogEvent(ADMIN_BLACKLIST_UNBLOCK_NUMBER_TOGGLE);
                    setBlockType('unblock');
                  }}
                  style={{ marginRight: '5px', marginLeft: '10px' }}
                />
                Unblock
              </label>
            </div>
            <button
              className="ButtonFullWidth BgGreen"
              onClick={handleEditBlackList}
              style={{ marginTop: '20px' }}
            >
              UPDATE
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  callPriorityRoutingStatus: state.topbar.call_priority_routing,
  callForwardingStatus: state.topbar.call_forwarding_status,
  isOrgOutboundCallEnabled: state.topbar.isOrgOutboundCallEnabled,
  isOutboundCallEnabled: state.topbar.isOutboundCallEnabled,
});

export default commonStyle(connect(mapStateToProps, {})(CallBlackListPage));

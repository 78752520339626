import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import DataCard from '../component/DataCard';
import DataTable from '../../../common/component/table/DataTable';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import { GET } from '../../../services/HttpRequests';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import moment from 'moment';
import { toast } from 'react-toastify';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import ExportVoiceApiPopup from '../component/ExportVoiceApiPopup';
import { convertSecondsToMinSeconds } from '../../../common/utility/commonUtils';
const voiceApiUrl = process.env.REACT_APP_VOICE_API;

function PartyDataCard(props) {
    const { partyData } = props;
    return (
        <div className="DataCard">
            <h3 style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{props.title || '-'}</h3>
            <div style={{
                display: 'flex',
                gap: '16px',
                paddingLeft: '16px',
                paddingBottom: '10px',
                overflowX: "scroll",
                width: "600px",
                paddingTop: "20px",
            }}>
                {Object.entries(partyData).map(([key, value]) => (
                    < div key={key}
                        style={{
                            whiteSpace: 'nowrap',
                            display: "flex",
                            marginBottom: "10px",
                            "padding": "8px 14px",
                            "fontWeight": "bold",
                            "fontSize": "20px",
                            border: '1px solid #ccc',
                            "color": "green",
                            "borderRadius": "2px",
                            "backgroundColor": "white",
                            "cursor": "pointer",
                        }}
                    >
                        {`${key}: ${value}`}

                    </div>
                ))}
            </div>
        </div>
    );
}

export function VoiceApiDetails(props) {
    let organisationId = localStorage.getItem('doosra-biz-organisation-id');
    const [campaignData, setCampaignData] = useState({});
    const [fetching, setFetching] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const location = useLocation();
    const history = useHistory();
    const [metricsData, setMetricsData] = useState(null);
    const [surveyMetricsData, setSurveyMetricsData] = useState({});
    const [dtmfCount, setDtmfCount] = useState(0);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    const fetchCampaignDetailsHandler = (id, pageNo) => {
        if (!id) return;
        setFetching(true);
        GET(voiceApiUrl + 'v1/voice', { id, pageNo, pageSize: 15, organisationId })
            .then((res) => {
                setCampaignData(res?.data?.response.voiceApi);
                setFetching(false);
            })
            .catch((err) => {
                setFetching(false);
                toast.error('An error occured');
            });
    };

    const fetchVoiceBroadcastMetrics = async () => {
        setFetching(true);
        GET(voiceApiUrl + 'v1/voice/metrics', {
            organisationId,
            campaignId: id
        })
            .then((res) => {
                setMetricsData(res?.data?.response);
            })
            .catch((err) => {
                setFetching(false);
                toast.error(err?.response?.data?.error?.reason || 'An error occured');
            });
    }
    const fetchSurveyMetrics = async () => {
        setFetching(true);
        GET(voiceApiUrl + 'v1/voice/survey-metrics', {
            campaignId: id,
            organisationId,
        })
            .then((res) => {

                const responseData = res?.data?.response;
                console.log(responseData?.dtmfKeysCount);
                setSurveyMetricsData(responseData?.dtmfKeysCount);
                setDtmfCount(responseData?.dtmfCount);
            })
            .catch((err) => {
                setFetching(false);
                toast.error(err?.response?.data?.error?.reason || 'An error occured');
            });
    }

    useEffect(() => {
        fetchVoiceBroadcastMetrics();
    }, []);

    useEffect(() => {
        fetchSurveyMetrics();
    }, []);

    useEffect(() => {
        fetchCampaignDetailsHandler(id);
    }, [id]);

    const fetchCampaignCallDetails = (pageNo) => {
        fetchCampaignDetailsHandler(id, pageNo);
        setPageNo(pageNo);
    };


    return (
        <div className={`${props.className}`}>
            <Sidebar />
            <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
                <Topbar />
                <div className="ContentContainer">
                    <TabSearchBar>
                        <div
                            style={{
                                marginLeft: '24px',
                                display: 'flex',
                                gap: '24px',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={BackIcon}
                                style={{ cursor: 'pointer' }}
                                onClick={() => history.push('/voice-api-list')}
                                alt=""
                            />
                            <p
                                style={{
                                    margin: 0,
                                    color: '#00515a',
                                    fontSize: '24px',
                                    fontWeight: 500,
                                }}
                            >
                                {campaignData?.name}
                            </p>
                        </div>
                        <ExportVoiceApiPopup id={id} />
                    </TabSearchBar>
                    {fetching && <PreLoader />}
                    <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
                        <DataCard title="Total Contacts" count={metricsData?.totalLeads} />
                        <DataCard
                            title="Total answered calls"
                            count={metricsData?.totalConnectedCalls}
                        />
                        <DataCard
                            title="Average call duration"
                            count={convertSecondsToMinSeconds(Math.floor(metricsData?.averageDuration))}
                        />
                        <DataCard
                            title="Total dtmf count"
                            count={dtmfCount}
                        />
                    </div>
                    <div style={{ display: 'flex', gap: '32px', padding: '0 16px 16px' }}>
                        <DataCard
                            title="Total No. of calls under 3 seconds"
                            count={metricsData?.totalCallsUnder3Sec}
                        />
                        <DataCard
                            title="Total No. of calls made"
                            count={metricsData?.totalCalls}
                        />
                        <PartyDataCard title="Survey Metrics" partyData={surveyMetricsData} />
                    </div>

                    <DataTable.Table>
                        <DataTable.Head>
                            <DataTable.CellHead width="20">#</DataTable.CellHead>
                            <DataTable.CellHead width="40">LEAD'S NUMBER</DataTable.CellHead>
                            <DataTable.CellHead width="100">CALL STATUS</DataTable.CellHead>
                            {campaignData?.category === "SURVEY" &&
                                <DataTable.CellHead width="100">DTMF CODE</DataTable.CellHead>}
                            <DataTable.CellHead width="100">
                                CALLED STARTED AT
                            </DataTable.CellHead>
                            <DataTable.CellHead width="100">
                                CALLED ENDED AT
                            </DataTable.CellHead>
                        </DataTable.Head>
                        <DataTable.Body>
                            {campaignData?.call_details?.length > 0 &&
                                campaignData?.call_details?.map((number, index) => (
                                    <DataTable.Row id={number.id}>
                                        <DataTable.Cell>{1 + index}</DataTable.Cell>
                                        <DataTable.Cell>{number.number}</DataTable.Cell>
                                        <DataTable.Cell>{number.status}</DataTable.Cell>
                                        {campaignData?.category === "SURVEY" &&
                                            <DataTable.Cell>{number.dtmfKey}</DataTable.Cell>}
                                        <DataTable.Cell>
                                            {number?.call_started_at
                                                ? moment(number.call_started_at).format(
                                                    'DD/MM/YYYY, h:mm:ss a'
                                                )
                                                : '-'}
                                        </DataTable.Cell>
                                        <DataTable.Cell>
                                            {number?.call_ended_at
                                                ? moment(number.call_ended_at).format(
                                                    'DD/MM/YYYY, h:mm:ss a'
                                                )
                                                : '-'}
                                        </DataTable.Cell>
                                    </DataTable.Row>
                                ))}
                        </DataTable.Body>
                    </DataTable.Table>
                    <Paginator
                        currentPage={pageNo}
                        lastPage={Math.ceil(campaignData?.totalLeads / 15)}
                        getInfo={(value) => fetchCampaignCallDetails(value)}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    sidebarClosed: state.sidebar.sidebarClosed,
    userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(VoiceApiDetails));

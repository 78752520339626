/* eslint-disable */
import React, { Component, createRef } from 'react';
import styled from '@emotion/styled';

import '../../../assets/css/SearchBar.css';
import { MenuItem, Select } from '@material-ui/core';

const callStatuses = [
  { displayValue: 'Answered', backEndValue: '^answered' },
  { displayValue: 'Not Answered', backEndValue: 'not_answered' },
];

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: '',
      searchValue: '',
      fromDate: new Date().toISOString().slice(0, 10),
      toDate: new Date().toISOString().slice(0, 10),
      btnOnReset: false,
      userRole: localStorage.getItem('doosra-biz-user-role'),
      isFocused: false,
      campaignName: props?.type === 'agent-campaign-leads' ? "Select Campaign" : "All Campaigns",
      campaignId: "",
    };
    this.inputRef = createRef();
    this.initialState = this.state;
  }

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value, btnOnReset: false });
  };

  getCampaignCalls = (id) => {
    this.props.search({ campaignId: id });
  }

  onSearch = () => {
    let data = {};
    if (
      this.state.searchKey === '' ||
      this.state.fromDate === '' ||
      this.state.toDate === ''
    )
      return;
    data.searchKey = this.state.searchKey;
    if (this.state.searchKey === 'date') {
      data.from_date = this.state.fromDate;
      data.to_date = this.state.toDate;
    }
    if (this.state.campaignId) {
      data.campaignId = this.state.campaignId;
    }
    data.searchCondition = this.state.searchValue;
    this.props.search(data);
    this.setState({ btnOnReset: true });
  };

  onReset = () => {
    const campaignName = this.state.campaignName;
    const campaignId = this.state.campaignId;
    this.setState(this.initialState);
    this.setState({ campaignId });
    this.setState({ campaignName })
    this.props.reset({ campaignId });
  };

  componentDidMount() {
    this.inputRef.current.addEventListener('focus', this.handleFocus);
    this.inputRef.current.addEventListener('blur', this.handleBlur);
  }

  componentWillUnmount() {
    this.inputRef.current.removeEventListener('focus', this.handleFocus);
    this.inputRef.current.removeEventListener('blur', this.handleBlur);
  }

  updateCampaignName(name) {

    this.props.filterCampaigns(name);
    this.setState({ campaignName: name })
  }

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
  };

  render() {
    return (
      <div className={`${this.props.className}`}>
        <div className="SearchBarComponent">
          <div style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative' }}>
            <div style={{ position: 'relative', width: '100%' }}>
              <input
                id="filter"
                ref={this.inputRef}
                type="text"
                value={this.state.campaignName}
                className="search-input"
                onChange={(e) => this.updateCampaignName(e.target.value)}
              ></input>
              <div
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '49%',
                  transform: 'translateY(-50%) scaleX(1.3)',
                  pointerEvents: 'none',
                  color: '#196cca',
                  fontSize: '10px',
                }}
              >
                ▼
              </div>
            </div>
            {this.state.isFocused ? this.props.campaignsList ? (
              <div className='main-search-container' style={{ overflowY: 'scroll', height: '200px' }}>
                {this.props.campaignsList ? (
                  <ul className="ul-main">
                    <li className="ul-items" key={null} value={null} onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      this.setState({ campaignName: e.target.innerText })
                      this.setState({ campaignId: e.target.getAttribute('value') })
                      this.handleBlur();
                      this.inputRef.current.blur();
                      this.getCampaignCalls(e.target.getAttribute('value'))
                    }}
                    >{this.props.type === 'agent-campaign-leads' ? "Select Campaign" : "All Campaigns"}</li>
                    {this.props.campaignsList.map((item) => (
                      <li className="ul-items" key={item._id} value={item._id} onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        console.log("clicked")
                        this.setState({ campaignName: e.target.innerText })
                        this.setState({ campaignId: e.target.getAttribute('value') })
                        this.handleBlur();
                        this.inputRef.current.blur();
                        this.getCampaignCalls(e.target.getAttribute('value'))
                      }}
                      >
                        {item.campaignName}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            ) : null : null}
          </div>
          <div style={{ flex: 1 }} />
          {this.props.type === 'agent-campaign-leads' ?
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className="SearchText">SEARCH BY</div>
              <React.Fragment>
                <select
                  className="SearchDropdown"
                  onChange={this.changeHandler}
                  id="searchKey"
                  value={this.state.searchKey}
                >
                  <option value="">Select</option>
                  <option value="name">Name</option>
                  <option value="number">Number</option>
                </select>
                <input
                  id="searchValue"
                  className="SearchBox"
                  placeholder="Search..."
                  onChange={this.changeHandler}
                  value={this.state.searchValue}
                  disabled={this.state.searchKey === '' ? true : false}
                />
              </React.Fragment>
            </div> :
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className="SearchText">SEARCH BY</div>
              <React.Fragment>
                <select
                  className="SearchDropdown"
                  onChange={this.changeHandler}
                  id="searchKey"
                  value={this.state.searchKey}
                >
                  <option value="">Select</option>
                  {this.state.userRole !== 'USER' ? (
                    <option value="user">Initiator Name</option>
                  ) : null}
                  <option value="initiatorNumber">Initiator Number</option>
                  <option value="destinationNumber">Destination Number</option>
                  <option value="disposition">Disposition</option>
                  <option value="date">Date</option>
                  <option value="status">Call Status</option>
                </select>
                {this.state.searchKey !== 'date' &&
                  this.state.searchKey !== 'user' &&
                  this.state.searchKey !== 'disposition' &&
                  this.state.searchKey !== 'status' && (
                    <input
                      id="searchValue"
                      className="SearchBox"
                      placeholder="Search..."
                      onChange={this.changeHandler}
                      value={this.state.searchValue}
                      disabled={this.state.searchKey === '' ? true : false}
                    />
                  )}
                {this.state.searchKey === 'date' && (
                  <React.Fragment>
                    <input
                      id="fromDate"
                      value={this.state.fromDate}
                      onChange={this.changeHandler}
                      className="SearchBox"
                      style={{ padding: '0px' }}
                      type="date"
                    />
                    <input
                      id="toDate"
                      value={this.state.toDate}
                      onChange={this.changeHandler}
                      style={{ padding: '0px' }}
                      className="SearchBox"
                      type="date"
                    />
                  </React.Fragment>
                )}
                {this.state.searchKey === 'user' && (
                  <select
                    className="SearchBox"
                    id="searchValue"
                    onChange={this.changeHandler}
                  >
                    <option value={'-'}>{'Select from dropdown'}</option>
                    {this.props.userData.length > 0 &&
                      this.props.userData.map((user) => (
                        <option value={user.user_id}>{user.userName}</option>
                      ))}
                  </select>
                )}
                {this.state.searchKey === 'disposition' && (
                  <select
                    className="SearchBox"
                    id="searchValue"
                    onChange={this.changeHandler}
                  >
                    <option value={'-'}>{'Select from dropdown'}</option>
                    {this.props.dispositions.length > 0 &&
                      this.props.dispositions.map((dispositionObj) => (
                        <option value={dispositionObj.dispositon}>
                          {dispositionObj.dispositon}
                        </option>
                      ))}
                    <option value={'Other'}>{'Other'}</option>
                  </select>
                )}
                {this.state.searchKey === 'status' && (
                  <select
                    className="SearchBox"
                    id="searchValue"
                    onChange={this.changeHandler}
                  >
                    <option value={'-'}>{'Select from dropdown'}</option>
                    {callStatuses.map((item) => (
                      <option value={item.backEndValue}>
                        {item.displayValue}
                      </option>
                    ))}
                  </select>
                )}
              </React.Fragment>
            </div>
          }

          <div>
            {this.state.btnOnReset ? (
              <button
                className="ClearResultButton"
                style={{
                  border: 'none',
                }}
                onClick={this.onReset}
              >
                CLEAR RESULTS
              </button>
            ) : (
              <>
                <button
                  className="SearchButton"
                  style={{ width: '100px', margin: '0 8px' }}
                  onClick={this.onSearch}
                  disabled={this.state.searchKey === '' ? true : false}
                >
                  SEARCH
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default styled(SearchBar)`
display: flex;
flex: 1;

input::placeholder {
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}
input {
  color-scheme: ${(props) => props.theme.colors.dateCalenderIconColor};
}
.SearchBarComponent {
  width: 100%;
  height: 54px;
  background-color: ${(props) => props.theme.colors.searchBarBgcolor};
  display: flex;
  grid-template-columns: 136px auto auto 116px;
  align-items: center;
  border-radius: 1px;
  box-shadow: 0px -15px 10px -15px rgb(0, 0, 0, 0.4);
  border: solid 1px ${(props) => props.theme.colors.searchBarBgcolor};
}

.SearchText {
  font-size: 12px;
  font-weight: 500;
  margin: auto 10px;
  /* letter-spacing: 1.2px; */
  text-align: left;
  color: ${(props) => props.theme.colors.searchByColor};
}

.SearchDropdown {
  width: 136px;
  height: 32px;
  font-size: 13px;
  margin: auto 5px;
  padding: 0 5px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchBox {
  width: 136px;
  height: 32px;
  margin: auto 5px;
  font-size: 13px;
  padding: 0 10px;
  /* padding: 8px 16px 9px; */
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchButton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.ClearResultButton{
  width: 120px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
  width: '128px',
  border: none,
}
.Otherbutton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.theme.colors.lightGreyBgColor};
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.SelectedContainer {
  width: 136px;
  height: 54px;
  background-color: #196cca;
}
.Agents-Logged-In {
  display: flex;
  height: 18px;
  margin: 0 0 0 0;
  
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.Total-call-duration {
  height: 18px;
  margin: 0 4px 0 0;
  
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SelectedContainer h6 {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding-top: 11px;
  padding-left: 14px;
  color: #ffffff;
}

.SelectedContainer p {
  font-size: 16px;
  font-weight: bold;
  padding-left: 14px;
  margin: 0;
  letter-spacing: 1.2px;
  color: #ffffff;
}

.main-search-container {
  width: 182px;
  height: 223px;
  margin: 0px 312px 115px 6px;
  padding: 0 6px 16px 0;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d1d3d4;
  background-color: #fff;
  z-index: 10;
  position: absolute;
  top: 40px;
}

.search-input {
  width: 182px;
  height: 32px;
  margin: 6px 0 10px 6px;
  border-radius: 4px;
  border: solid 1px #196cca;
  background-color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  padding-left: 8px;
  margin-top: 4px;
}

.ul-main {
  list-style-type: none; /* Removes bullet points */
  margin-left: 6px;             /* Removes default margin */
  padding: 0;            /* Removes default padding */
}
.ul-items {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  padding-left: 8px;
  margin-top: 4px;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .SearchBarComponent {
    width: fit-content;
    grid-template-columns: 48px 136px 77px auto 150px;
  }
}
`;

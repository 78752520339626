import React, {useEffect} from "react";
import { useAuth } from '../../providers/AuthProvider';
import { usePostHog } from 'posthog-js/react';
import posthog from "posthog-js";

export default function PostHog() {
  const { isLoggedIn } = useAuth();
  const posthog = usePostHog()
  useEffect(() => {
    if (isLoggedIn) {
        const userId = localStorage.getItem('doosra-biz-user-id');
        const email = localStorage.getItem('doosra-biz-user-email');
        posthog?.identify(userId, {
          email,
      })
    }
  }, [isLoggedIn])
  return null;
}
export const logPostHogEvent = (event = "") => {
    posthog?.capture(event); 
}
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';
import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import DataTable from '../../../common/component/table/DataTable';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import moment from 'moment';
import DeleteContactPopup from '../component/DeleteContactPopup';
import EditContactPopup from '../component/EditContactPopup';
import ViewContactPopup from '../component/ViewContactPopup';
import { toast } from 'react-toastify';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import TagIcon from '../../../assets/images/tag.png';
import { SEARCH_CONTACT_BUTTON, ADMIN_SEARCH_CONTACT_BUTTON } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";
import NumberDialer from '../../../common/component/number-dialer/NumberDialer';
import {
  setIsOpen,
  setDestinationNumber,
  setContactId,
  setCallId
} from "../../../actions/dailPadActions";

const userUrl = process.env.REACT_APP_USER_API;

export function ContactsPage(props) {
  const [contactsList, setContactsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [searchData, setSearchData] = useState({});
  const [stopViewPopup, setStopViewPopup] = useState(true);
  const [usersList, setUsersList] = useState(null);
  const [receiverNo, setReceiverNo] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [openNumberDail, setOpenNumberDail] = useState(false);
  const userRole = localStorage.getItem('doosra-biz-user-role');

  useEffect(() => {
    fetchContactsList();
    fetchUsersList();
  }, []);

  const trimNumber = (number) => {
    if (number && number.length == 12) return number.substring(2);
    return number;
  };
  const openDialer = (e, number, id) => {
    setReceiverNo(number);
    setContactId(id);
    if (localStorage.getItem('is_dialer_widget_enabled') === 'true') {
      if (props.agentStatus === 'Online') {
        props.setIsOpen({ isOpen: true });
        props.setDestinationNumber({ destinationNumber: number });
        props.setContactId({ contactId: id });
        props.setCallId({ callId: "" });
      } else {
        if (this.props.agentStatus === "Busy") {
          toast.error("There is a active call");
        }
        else {
          toast.error('Please go to Online');
        }
      }
    } else {
      setOpenNumberDail(true);
    }
  };
  const formatContactNumbers = (list, id) => {
    if (list.length <= 0) return '-';
    const l = [];
    for (let i = 0; i < list.length; i++) {
      if (userRole === 'USER') {
        l.push(<><a className='AddContactNo' style={{ cursor: 'pointer' }}
          onClick={(e) => openDialer(e, list[i], id)} >{list[i]}</a>&nbsp;</>)
      } else {
        l.push(<>{list[i]}&nbsp;, &nbsp;</>)
      }

    }
    return l;
  };

  const fetchContactsList = (searchParams, page, reset) => {
    setLoading(true);
    let data = {
      pageNo: page || currentPage,
      pageSize: pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    if (searchParams) {
      logPostHogEvent(userRole === 'ADMIN' ? ADMIN_SEARCH_CONTACT_BUTTON : SEARCH_CONTACT_BUTTON);
      setSearchData(searchParams);
      setCurrentPage(1);
      data.pageNo = 1;
      data = { ...data, ...searchParams };
    }
    data = { ...data, ...searchData };
    if (reset) {
      setSearchData({});
      setCurrentPage(1);
      data = {};
      data.pageNo = 1;
      data.pageSize = pageSize;
      data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    }

    if (localStorage.getItem('doosra-biz-user-role') === 'USER') {
      data.userId = localStorage.getItem('doosra-biz-user-id');
    }
    GET(userUrl + `v1/user/contacts`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        const data = res.contacts;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(", ");
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        setContactsList(data);
        setCount(res.totalCount);
        setTotalPages(Math.ceil(res.totalCount / pageSize));
        setPageSize(res.pageSize);
        setCurrentPage(parseInt(res.pageNo));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const addContactHandler = (data) => {
    setLoading(true);
    return POST(userUrl + 'v1/user/contact', data)
      .then((res) => {
        setLoading(false);
        fetchContactsList();
        return res;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const fetchUsersList = async () => {
    try {
      const res = await GET(userUrl + `v1/user`, {
        pageNo: 1,
        pageSize: 1000,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      });
      const users = res?.data?.response?.users?.docs;
      console.log("fetchUsersList:list:", users);
      if (users.length > 0) {
        setUsersList(users);
      }
    } catch (err) { console.log("fetchUsersList:err:", err); }
  };

  // const updateContactHandler = (data) => {
  //   setLoading(true);
  //   return PUT(userUrl + 'v1/user/contact', null, data)
  //     .then((res) => {
  //       setLoading(false);
  //       fetchContactsList();
  //       return true;
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       toast.error(err?.response?.data?.error?.reason);
  //       return false;
  //     });
  // };

  const deleteContactHandler = (id, userId) => {
    setLoading(true);
    DELETE(userUrl + 'v1/user/contact', null, { id, userId })
      .then((res) => {
        if (!res?.data?.response?.success) {
          toast.error('unable to delete contact');
        } else {
          toast.success('Successfully deleted contact');
          fetchContactsList();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
        console.log("err", err);
        toast.error('unable to delete contact');
      });
  };

  let displayData;

  if (loading) displayData = <PreLoader />;

  if (contactsList.length > 0) {
    displayData = contactsList.map((row) => (
      <DataTable.Row key={row._id}>
        <DataTable.Cell>
          {moment(row.createdAt).format('DD/MM/YYYY')}
        </DataTable.Cell>
        <DataTable.Cell>
          <ViewContactPopup
            key={row._id}
            contactInfo={row}
            getList={fetchContactsList}
            editFrom=""
            userId={row.userId}
            userName={row.userName}
          />
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.primaryNo || row.primaryNo === 'NA'
            ? '-'
            : trimNumber(row.primaryNo)}
        </DataTable.Cell>
        <DataTable.Cell>
          {row.name}
        </DataTable.Cell>
        <DataTable.Cell>
          {formatContactNumbers(row.phoneNumbers, row._id)}
        </DataTable.Cell>
        <DataTable.Cell>
          <div className="tableNotes">{row.notes || '-'}</div></DataTable.Cell>
        <DataTable.Cell>
          {row?.tags?.length > 0 &&
            row.tags.map((tag, index) => {
              return (
                <div key={index} className="tableTags">
                  <img
                    alt="tag"
                    src={TagIcon}
                    style={{ width: "15px", height: "15px", marginRight: 7 }}
                  />
                  <span>{tag}</span>
                </div>
              )
            })
          }
        </DataTable.Cell>
        <DataTable.Cell>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <ViewContactPopup
              key={row._id}
              contactInfo={row}
              getList={fetchContactsList}
              editFrom=""
              userId={row.userId}
            />
            {isSuperAdminWithPermission('EDIT') && (
              <EditContactPopup
                key={row._id}
                contactInfo={row}
                getList={fetchContactsList}
                editFrom="contacts"
                stopViewPopup={setStopViewPopup}
              />
            )}
            {isSuperAdminWithPermission('DELETE') && (
              <DeleteContactPopup
                key={row._id}
                deleteContactHandler={(id, userId) =>
                  deleteContactHandler(id, userId)
                }
                id={row._id}
                userId={row.userId}
              />
            )}
          </div>
        </DataTable.Cell>
      </DataTable.Row>
    ));
  }

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <SearchBar
            type="contacts"
            count={count}
            search={fetchContactsList}
            reset={() => fetchContactsList(null, null, { reset: true })}
            refreshList={fetchContactsList}
            basicUserDownloadDetail={contactsList}
            addContactHandler={(e) => addContactHandler(e)}
            usersList={usersList}
          />
          <DataTable.Table>
            <DataTable.Head>
              <DataTable.CellHead>ADDED ON</DataTable.CellHead>
              <DataTable.CellHead>AGENT NAME</DataTable.CellHead>
              <DataTable.CellHead>AGENT PRIMARY NO</DataTable.CellHead>
              <DataTable.CellHead>CONTACT NAME</DataTable.CellHead>
              <DataTable.CellHead>CONTACT NO</DataTable.CellHead>
              <DataTable.CellHead>Notes</DataTable.CellHead>
              <DataTable.CellHead>Tags</DataTable.CellHead>
              <DataTable.CellHead style={{ width: '7%' }}>ACTIONS</DataTable.CellHead>
            </DataTable.Head>
            <DataTable.Body>{displayData}</DataTable.Body>
          </DataTable.Table>
          <Paginator
            currentPage={currentPage}
            lastPage={totalPages}
            getInfo={(value) => {
              fetchContactsList(null, value);
            }}
          />
        </div>
      </div>
      {openNumberDail && (<NumberDialer
        receiverNo={receiverNo}
        contactId={contactId}
        openPopup={openNumberDail}
        closePopup={setOpenNumberDail} />)}

    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  destinationNumber: state.dailpad.destinationNumber,
  agentStatus: state.dailpad.agentStatus
});
export default commonStyle(connect(mapStateToProps, {
  setIsOpen,
  setDestinationNumber,
  setContactId,
  setCallId
})(ContactsPage));

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
class ViewPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
    let trialEndAt = localStorage.getItem('free_trial_end_date');
    let now = moment();
    let gap = moment(trialEndAt).diff(now, 'days');
    gap = gap + 1;
    if (!isFreeTrail) return null;

    return (
      <>
        <div className="free-trial-banner">
          <span className="free-trial-text">
            {gap} days remain in your free trial
            <span className="free-trial-upgrade">Upgrade</span>
          </span>
        </div>
        <div style={{ height: '36px' }}></div>
      </>
    );
  }
}

export default ViewPlan;

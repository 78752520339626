import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import DataCard from '../component/DataCard';
import DataTable from '../../../common/component/table/DataTable';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import { GET } from '../../../services/HttpRequests';
import Online from '../../../assets/images/smallCircleGreen.png';
import Offline from '../../../assets/images/smallCircleYellow.png';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { lightBlue } from '@material-ui/core/colors';
import ReportSearchBar from '../component/ReportSearchBar';
import activeStatus from '../../../assets/images/activeStatus.svg';
import tea from '../../../assets/images/tea.svg';
import lunch from '../../../assets/images/lunch.svg';
import meeting from '../../../assets/images/meeting.svg';
import training from '../../../assets/images/training.svg';
import biobreak from '../../../assets/images/biobreak.svg';
import offline from '../../../assets/images/offline.svg';
import away from '../../../assets/images/away.svg';

const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

function AgentsList(props) {
  /* AGENT ACTIVE STATUS */
  const optionsAgentActiveStatus = {
    'active': { label: 'Active', image: activeStatus, color: '#4daa3e' },
    'tea': { label: 'Tea', image: tea, color: '#196cca' },
    'lunch': { label: 'Lunch', image: lunch, color: '#196cca' },
    'meeting': { label: 'Meeting', image: meeting, color: '#196cca' },
    'training': { label: 'Training', image: training, color: '#196cca' },
    'biobreak': { label: 'Biobreak', image: biobreak, color: '#196cca' },
    //  'away', label: 'Away', image: away, color: '#f8bb44' },
    'offline': { label: 'Offline', image: offline, color: '#939598' },
    'Not Logged In': { label: 'Offline', image: offline, color: '#939598' },
    'busy': { label: 'Busy', image: activeStatus, color: '#4daa3e' },
    'calling': { label: 'Dailing', image: activeStatus, color: '#4daa3e' },
  };


  const history = useHistory();
  return (
    <div style={{ overflowX: 'auto' }}>
      <DataTable.Table style={{ minWidth: '100%' }}>
        <DataTable.Head>
          <DataTable.CellHead width="50">#</DataTable.CellHead>
          <DataTable.CellHead width="150">AGENT NAME</DataTable.CellHead>
          <DataTable.CellHead width="150">CALLS</DataTable.CellHead>
          <DataTable.CellHead width="150">CONNECTED CALLS</DataTable.CellHead>
          <DataTable.CellHead width="150">CALL TIME (s)</DataTable.CellHead>
          <DataTable.CellHead width="150">RECOVERY SCORE</DataTable.CellHead>
          <DataTable.CellHead width="150" >
            STATUS
          </DataTable.CellHead>
        </DataTable.Head>
        <DataTable.Body>
          {props?.agentsList?.length > 0 &&
            props?.agentsList?.map((agent, index) => (
              <DataTable.Row id={agent._id}>
                <DataTable.Cell>{props?.currentPage + index}</DataTable.Cell>
                <DataTable.Cell
                  style={{ cursor: 'pointer', textDecoration: "underline" }}
                  onClick={() => {
                    history.push(`/agent-campaigns-details?id=${agent._id}`);
                  }}
                >
                  {agent?.results.zvr_name}
                </DataTable.Cell>
                <DataTable.Cell>{agent?.totalCalls || "-"}</DataTable.Cell>
                <DataTable.Cell>{agent?.totalAnsweredCalls || "-"}</DataTable.Cell>
                <DataTable.Cell>{agent?.totalCallDuration || "-"}</DataTable.Cell>
                <DataTable.Cell>{agent?.recoveryScore || "-"}</DataTable.Cell>
                <DataTable.Cell >
                  <span
                    key={agent._id}
                    style={{
                      padding: '5px',
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      display: 'flex',
                      color: (optionsAgentActiveStatus[agent.status]?.color || "red"),
                    }}
                  >
                    {agent?.status !== 'Not Logged In' &&
                      <>
                        <img
                          src={optionsAgentActiveStatus[agent.status]?.image}
                          style={{ fill: optionsAgentActiveStatus[agent.status].color }}
                          alt=".."
                        />
                        &nbsp;&nbsp;</>}
                    {agent?.status?.toUpperCase() || 'error'}
                  </span>
                </DataTable.Cell>
              </DataTable.Row>
            ))}
        </DataTable.Body>
      </DataTable.Table>
    </div>
  );
}

const DurationCard = (props) => {
  return (
    <div style={{ display: 'flex' }}>
      <div>Day</div>
      <div>Week</div>
      <div>Month</div>
      <div>Year</div>
    </div>
  );
};

export function Agents(props) {
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const [agentsData, setAgentsData] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [agentReportFromDate, setAgentReportFromDate] = useState(new Date().toISOString().slice(0, 10));
  const [agentReportToDate, setAgentReportToDate] = useState(new Date().toISOString().slice(0, 10));

  const tabs = [];
  tabs.push({
    title: 'Overview Dashboard',
    onClick: () => props.history.push('/dashboard'),
    active: false,
  });
  tabs.push({
    title: 'Campaigns List',
    onClick: () => props.history.push('/cc-campaigns'),
    active: false,
  });
  tabs.push({
    title: 'Agents',
    onClick: () => props.history.push('/agents'),
    active: true,
  });

  if (userRole === 'ADMIN') {
    // tabs.push({
    //   title: 'Agent Reports',
    //   onClick: () => props.history.push('/recovery-x-agent-report'),
    //   active: false,
    // });
  }
  tabs.push({
    title: 'Calls',
    onClick: () => props.history.push('/calls'),
    active: false,
  });

  if (userRole === 'ADMIN') {
    tabs.push({
      title: 'BlackList',
      onClick: () => props.history.push('/blacklist'),
      active: false,
    });
  }

  const fetchAgentsListHandler = (filter = {}) => {
    setIsLoading(true);
    let data = { ...filter };
    console.log(JSON.stringify(data));
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    GET(cloudCallCenterUrl + 'reports/agent-report', data)
      .then((res) => {
        setAgentsData(res?.data?.response);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const fetchCampaignListHandler = () => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      pageNo: 1,
      pageSize: 1000000,
      fetchOnlyCampaignsList: true,
    };
    GET(cloudCallCenterUrl + 'campaign/get-campaigns', data)
      .then((res) => {
        setCampaignsList(res?.data?.response?.campaignData);
      })
      .catch((err) => console.log(err));
  };


  useEffect(() => {
    fetchAgentsListHandler();
    fetchCampaignListHandler();
  }, []);

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar
            tabs={tabs}
            type="agentList"
            agentList={agentsData.response}
            fromDate={agentReportFromDate}
            toDate={agentReportToDate} />
          <ReportSearchBar
            changeFilter={(e) => console.log(e)}
            filters={[{ campaignName: "all", _id: "1" }]}
            campaignsList={campaignsList}
            setSelectedCampaign={setSelectedCampaign}
            selectedCampaign={selectedCampaign}
            search={fetchAgentsListHandler}
            reset={fetchAgentsListHandler}
            setAgentReportFromDate={setAgentReportFromDate}
            setAgentReportToDate={setAgentReportToDate}
          />
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            <DataCard
              title="Active Agents"
              count={agentsData?.activeLoginUsersCount}
            />
            <DataCard
              title="Calls"
              count={
                agentsData?.totalOrganisationCalls
              }
            />
            {/* <DataCard
              title="Connected Calls"
              count={
                agentsData?.totalOrganisationUniqueCalls
              }
            /> */}
            <DataCard
              title="Connected Calls"
              count={
                agentsData?.totalOrganisationAnsweredCount
              }
            />
            <DataCard
              title="Calls Duration (minutes)"
              count={
                agentsData?.totalOrganisationDuration
              }
            />
            {/* <DataCard
              title="Call duration under 7 seconds"
              count={agentsData?.totalCallsUnderSevenSecondDuration}
            /> */}
          </div>
          {isLoading && <PreLoader />}
          {!isLoading && <AgentsList
            agentsList={agentsData?.response}
            currentPage={currentPage}
          />}
          <Paginator
            currentPage={1}
            lastPage={1}
          //   getInfo={(value) => this.fetchPageData(value)}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(Agents));

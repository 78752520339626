// import React, { useEffect, useCallback } from 'react';
// import { GET } from '../../../services/HttpRequests';
// const ChargebeePortalButton = () => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://js.chargebee.com/v2/chargebee.js';
//     script.onload = initializeChargebee;
//     script.onerror = () => {
//       console.error('Failed to load Chargebee script.');
//     };
//     document.body.appendChild(script);
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);
//   const initializeChargebee = () => {
//     window.Chargebee && window.Chargebee.init({ site: 'doosra-test' });
//   };
//   const fetchPortalSession = async (params) => {
//     const response = await GET(
//       `${process.env.REACT_APP_PLAN_API}v1/alohaa/chargebee-portal`,
//       params
//     );
//     console.log(response.data);
//     return response.data.response;
//   };
//   const handlePortalOpen = useCallback(async () => {
//     if (!window.Chargebee) return;
//     const params = { customerId: localStorage.getItem('doosra-biz-organisation-id') };
//     try {
//       const data = await fetchPortalSession(params);
//       const chargebeeInstance = window.Chargebee.getInstance();
//       chargebeeInstance.setPortalSession(() =>
//         Promise.resolve(data.hostedPageId)
//       );
//       chargebeeInstance.createChargebeePortal().open();
//     } catch (error) {
//       console.error('Error fetching the portal session: ', error);
//     }
//   }, []);
//   return (
//     <button
//       className="Otherbutton"
//       style={{ color: "white", backgroundColor: "#05AA3F" }}
//       onClick={handlePortalOpen}
//     >
//       Portal Checkout
//     </button>
//   );
// };
// export default ChargebeePortalButton;
import { useEffect, useCallback } from 'react';
import { GET } from '../../../services/HttpRequests';
export const useChargebeePortal = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.chargebee.com/v2/chargebee.js';
        script.onload = initializeChargebee;
        script.onerror = () => {
            console.error('Failed to load Chargebee script.');
        };
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const initializeChargebee = () => {
        window.Chargebee && window.Chargebee.init({ site: 'doosra-test' });
    };
    const fetchPortalSession = async (params) => {
        const response = await GET(
            `${process.env.REACT_APP_PLAN_API}v1/alohaa/chargebee-portal`,
            params
        );
        console.log(response.data);
        return response.data.response;
    };
    const handlePortalOpen = useCallback(async () => {
        if (!window.Chargebee) return;
        const params = { customerId: localStorage.getItem('doosra-biz-organisation-id') };
        try {
            const data = await fetchPortalSession(params);
            const chargebeeInstance = window.Chargebee.getInstance();
            chargebeeInstance.setPortalSession(() =>
                Promise.resolve(data.hostedPageId)
            );
            chargebeeInstance.createChargebeePortal().open();
        } catch (error) {
            console.error('Error fetching the portal session: ', error);
        }
    }, []);
    return handlePortalOpen;
};
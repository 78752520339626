import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Logo from '../../../assets/images/alohaaLogoAndBottomText.png';
import Success from '../../../assets/images/greenSuccessBig.png';
import GreenTick from '../../../assets/images/greenTickSmall.png';
import InfoRed from '../../../assets/images/infoRed.png';
import invisible from '../../../assets/images/invisibleEye.png';
import RightArrow from '../../../assets/images/rightArrow.png';
import visible from '../../../assets/images/visibleEye.png';
import { POST } from '../../../services/HttpRequests';
import { generateRecaptcha } from '../../../services/Recaptcha';
import PasswordCheck from '../../../common/component/passwordCheck/PasswordCheckComponent';
import Wall from '../../../assets/images/loginPageWall.png';
import '../../../assets/css/LoginNew.css';
const userUrl = process.env.REACT_APP_USER_API;
class ResetPasswordPage extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      passwordVisible: false,
      confPasswordVisible: false,
      orgId: '',
      adminId: '',
      username: '',

      otp: '',
      token: '',
      password: '',
      confPassword: '',
      otpResent: false,
      error: '',
      is_user: false,
      phoneNumber: '',
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  passwordVisiblityToggleHandler = (second) => {
    if (second) {
      this.setState({ confPasswordVisible: !this.state.confPasswordVisible });
    } else {
      this.setState({ passwordVisible: !this.state.passwordVisible });
    }
  };

  switchStep = (step) => {
    this.setState({ step: step });
  };

  submitEmailHandler = async (e) => {
    if (e) e.preventDefault();
    // let recaptcha = await generateRecaptcha('BUSINESS_FORGOT_PASSWORD_EMAIL');
    let reqBody = {
      username: this.state.username,
    };
    POST(`${userUrl}v1/user/forgot-password`, reqBody)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          step: 2,
          orgId:
            res?.user?.organisation?.organisation_id ||
            res?.user?.organisation_id,
          adminId: res.user.user_id,
          error: '',
          is_user: res?.user?.isAdmin ? false : true,
          phoneNumber: res.user.phoneNumber || res.user.phone_number,
        });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({ error: err.response.data.error.reason });
        }
      });
  };

  resendOTPHandler = () => {
    this.submitEmailHandler();
    this.setState({ otpResent: true });
  };

  submitOTPHandler = async (e) => {
    e.preventDefault();
    let recaptcha = await generateRecaptcha('BUSINESS_FORGOT_PASSWORD_OTP');
    let reqBody = {
      phone_number: this.state.phoneNumber,
      otp: this.state.otp,
      is_user: this.state.is_user,
      recaptchaToken: recaptcha,
      organisationId: this.state.orgId,
      userId: this.state.adminId,
    };
    POST(`${userUrl}v1/user/signup/verify-otp`, reqBody)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({ step: 3, token: res.token });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({ error: err.response.data.error.reason });
        }
      });
  };

  setPasswordHandler = async (e) => {
    e.preventDefault();
    let recaptcha = await generateRecaptcha('BUSINESS_FORGOT_PASSWORD_SET');
    let reqBody = {
      password: this.state.password,
      confirm_password: this.state.confPassword,
      token: this.state.token,
      is_user: this.state.is_user,
      recaptchaToken: recaptcha,
      source: 'WEB',
      organisationId: this.state.orgId,
      userId: this.state.adminId,
    };
    POST(`${userUrl}v1/user/signup/set-password`, reqBody)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({ step: 4 });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({ error: err.response.data.error.reason });
        }
      });
  };

  redirectToLogin = () => {
    this.props.history.push('/login');
  };

  render() {
    if (
      localStorage.getItem('doosra-business-sessionToken') &&
      localStorage.getItem('doosra-business-sessionToken') !== ''
    ) {
      return <Redirect to="call-logs" />;
    }

    if (this.state.step === 4) {
      return (
        <div className="login-main">
          <div className="section-1">
            <div className="login-container">
              <img className="login-container-logo" alt="" src={Logo} />

              <div className="AuthHeadContainer">
                {/* <p className="DoosraBusinessHeader">
                  <span className="DoosraDefaultColor"></span>alohaa
                </p> */}
                <p className="AuthSubHeader">Password Reset Successful!</p>
              </div>
              <form>
                <div className="AuthSuccessHolder">
                  <img alt="" src={Success} />
                </div>
                <Link to="/login">
                  <div className="SignIntoDashboardContainer">
                    <p className="SignIntoDashboard">SIGN INTO THE DASHBOARD</p>
                    <img
                      alt=""
                      className="SignIntoRightArrowIcon"
                      src={RightArrow}
                    />
                  </div>
                </Link>
              </form>
            </div>
          </div>
          <div>
            <img src={Wall} style={{ height: '100vh' }} />
          </div>
        </div>
      );
    }
    if (this.state.step === 3) {
      return (
        <div className="login-main">
          <div className="section-1">
            <div className="login-container">
              <img className="login-container-logo" alt="" src={Logo} />

              <div className="AuthHeadContainer">
                {/* <p className="DoosraBusinessHeader">
                  <span className="DoosraDefaultColor"></span>alohaa
                </p> */}
                <p className="AuthSubHeader">Reset your password</p>
              </div>

              <div className="PasswordInputHolder">
                <input
                  id="password"
                  className="PasswordInput"
                  value={this.state.password}
                  onChange={this.handleChange}
                  type={this.state.passwordVisible ? 'text' : 'password'}
                  placeholder="Enter your new password"
                />
                {this.state.passwordVisible ? (
                  <img
                    alt=""
                    onClick={() => this.passwordVisiblityToggleHandler()}
                    className="PasswordInvisibleToggle"
                    src={invisible}
                  />
                ) : (
                  <img
                    alt=""
                    onClick={() => this.passwordVisiblityToggleHandler()}
                    className="PasswordVisibleToggle"
                    src={visible}
                  />
                )}
              </div>
              <div className="PasswordInputHolder">
                <input
                  id="confPassword"
                  value={this.state.confPassword}
                  onChange={this.handleChange}
                  className="PasswordInput"
                  type={this.state.confPasswordVisible ? 'text' : 'password'}
                  placeholder="Re-enter your new password"
                />
                {this.state.confPasswordVisible ? (
                  <img
                    alt=""
                    onClick={() => this.passwordVisiblityToggleHandler(true)}
                    className="PasswordInvisibleToggle"
                    src={invisible}
                  />
                ) : (
                  <img
                    alt=""
                    onClick={() => this.passwordVisiblityToggleHandler(true)}
                    className="PasswordVisibleToggle"
                    src={visible}
                  />
                )}
              </div>

              <PasswordCheck password={this.state.password} />

              <div className="ErrorContainer ErrorContainerMargin">
                {this.state.error ? (
                  <React.Fragment>
                    <img
                      alt=""
                      src={InfoRed}
                      style={{
                        paddingRight: '5px',
                        position: 'relative',
                        top: '-2px',
                      }}
                    />
                    {this.state.error}
                  </React.Fragment>
                ) : null}
              </div>

              <button className="AuthButton" onClick={this.setPasswordHandler}>
                NEXT
              </button>
              <button
                className="AuthButton AuthButtonInvert"
                onClick={() => {
                  this.switchStep(2);
                }}
              >
                BACK
              </button>
            </div>
          </div>
          <div>
            <img src={Wall} style={{ height: '100vh' }} />
          </div>
        </div>
      );
    }
    if (this.state.step === 2) {
      return (
        <div className="login-main">
          <div className="section-1">
            <div className="login-container">
              <img className="login-container-logo" alt="" src={Logo} />

              <div className="AuthHeadContainer">
                {/* <p className="DoosraBusinessHeader">
                  <span className="DoosraDefaultColor"></span>alohaa
                </p> */}
                <p className="AuthSubHeader">Enter OTP</p>
              </div>

              <input
                id="otp"
                value={this.state.otp}
                onChange={this.handleChange}
                className="InputBox"
                placeholder="Enter your OTP"
              />
              <p className="AuthForgotPassword" onClick={this.resendOTPHandler}>
                Resend-OTP
              </p>
              {this.state.otpResent && (
                <div className="OTPResent">
                  <img src={GreenTick} />
                  <p>OTP resent successfully</p>
                </div>
              )}
              <div className="ErrorContainer ErrorContainerMargin">
                {this.state.error ? (
                  <React.Fragment>
                    <img
                      alt=""
                      src={InfoRed}
                      style={{
                        paddingRight: '5px',
                        position: 'relative',
                        top: '-2px',
                      }}
                    />
                    {this.state.error}
                  </React.Fragment>
                ) : null}
              </div>

              <button className="AuthButton" onClick={this.submitOTPHandler}>
                NEXT
              </button>
              <button
                className="AuthButton AuthButtonInvert"
                onClick={() => {
                  this.switchStep(1);
                }}
              >
                CANCEL
              </button>
            </div>
          </div>
          <div>
            <img src={Wall} style={{ height: '100vh' }} />
          </div>
        </div>
      );
    }
    if (this.state.step === 1) {
      return (
        <div className="login-main">
          <div className="section-1">
            <div className="login-container">
              <img className="login-container-logo" alt="" src={Logo} />

              <div className="AuthHeadContainer">
                {/* <p className="DoosraBusinessHeader">
                  <span className="DoosraDefaultColor"></span>alohaa
                </p> */}
                <p className="AuthSubHeader">Reset your password</p>
              </div>

              <input
                id="username"
                value={this.state.username}
                onChange={this.handleChange}
                className="InputBox"
                placeholder="Enter your phone number"
              />

              <div className="ErrorContainer ErrorContainerMargin">
                {this.state.error ? (
                  <React.Fragment>
                    <img
                      alt=""
                      src={InfoRed}
                      style={{
                        paddingRight: '5px',
                        position: 'relative',
                        top: '-2px',
                      }}
                    />
                    {this.state.error}
                  </React.Fragment>
                ) : null}
              </div>

              <button className="AuthButton" onClick={this.submitEmailHandler}>
                NEXT
              </button>
              <button
                className="AuthButton AuthButtonInvert"
                onClick={this.redirectToLogin}
              >
                CANCEL
              </button>
            </div>
          </div>
          <div>
            <img src={Wall} style={{ height: '100vh' }} />
          </div>
        </div>
      );
    }
  }
}

export default ResetPasswordPage;

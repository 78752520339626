import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';
const recoveryCallUrl = process.env.REACT_APP_RECOVERY_CALL_API;
export default function ExportRecoveryAgents(props) {
  const [list, setList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  let columns = [
    { id: 'agentName', displayName: 'AGENT NAME' },
    { id: 'callsMade', displayName: 'CALLS MADE' },
    { id: 'uniqueCallsMade', displayName: 'UNIQUE CALLS MADE' },
    { id: 'connectedCalls', displayName: 'CONNECTED CALLS' },
    { id: 'notConnectedCalls', displayName: 'NOT CONNECTED CALLS' },
    { id: 'totalCallTime', displayName: 'TOTAL CALL TIME(SECONDS)' },
    { id: 'recoveryScore', displayName: 'RECOVERY SCORE' },
  ];
  const openPopup = () => {
    setIsPopupOpen(true);
    console.log(props.agentList);
    setList(props.agentList || []);
    setDataFetched(true);
  };
  return (
    <div>
      <button
        className="Otherbutton"
        style={{ backgroundColor: '#07aa3f', color: 'white' }}
        onClick={openPopup}
      >
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download Recovery Agent List</p>
            <img
              className="PopupClose"
              src={Close}
              alt=""
              onClick={() => {
                setIsPopupOpen(false);
                setDataFetched(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file.
            </p>
            <p className="PopupNote">
              Max download limit - Recent 10,000 entries
            </p>
            {dataFetched ? (
              <CsvDownloader
                filename={`agent_report_list_between_${
                  props.fromDate
                    ? props.fromDate
                    : new Date().toISOString().slice(0, 10)
                }_and_${
                  props.toDate
                    ? props.toDate
                    : new Date().toISOString().slice(0, 10)
                }`}
                separator=","
                columns={columns}
                datas={
                  list.length
                    ? list.map((row) => {
                        return {
                          agentName: row.results.zvr_name,
                          callsMade:
                            !row.totalCalls || row.totalCalls === 'NA'
                              ? '-'
                              : row.totalCalls,
                          uniqueCallsMade:
                            !row.uniqueDestNumbersCount ||
                            row.uniqueDestNumbersCount === 'NA'
                              ? '-'
                              : row.uniqueDestNumbersCount,
                          connectedCalls:
                            !row.totalAnsweredCalls ||
                            row.totalAnsweredCalls === 'NA'
                              ? '-'
                              : row.totalAnsweredCalls,
                          notConnectedCalls:
                            !row.totalUnAnsweredCalls ||
                            row.totalUnAnsweredCalls === 'NA'
                              ? '-'
                              : row.totalUnAnsweredCalls,
                          totalCallTime:
                            !row.totalCallDuration ||
                            row.totalCallDuration === 'NA'
                              ? '-'
                              : row.totalCallDuration,
                          recoveryScore:
                            !row.recoveryScore || row.recoveryScore === 'NA'
                              ? '-'
                              : row.recoveryScore,
                        };
                      })
                    : [{}]
                }
                className="ButtonFullWidth BgGreen"
                text="Download CSV"
              />
            ) : (
              <button
                className="ButtonFullWidth BgGrey"
                style={{ cursor: 'default' }}
              >
                SAVE
              </button>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}

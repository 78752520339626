/* eslint-disable no-dupe-keys */
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect, useSelector } from 'react-redux';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import { useEffect, useRef, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
import AddCallRemarksPopup from '../component/AddCallRemarksPopup';
import phoneWhite from '../../../assets/images/phoneWhiteRecoveryCall.svg';
import BatchCallPopup from '../component/BatchCallPopupV2';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import { useSocket } from '../../../providers/SocketProvider';
import DataCard from '../component/DataCard';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import DataTable from '../../../common/component/table/DataTable';
import CallHistoryPopup from '../component/CallHistoryPopup';
import EditLeadPopup from '../component/EditLeadPopup';
import DiscreteCallPopup from '../../../pages/recoveryX/component/DiscreteCallPopupV2';
import ModifyHeadersPopUp from '../component/ModifyHeadersPopUp';
import { setStaticCampaignTableHeaders } from '../../../actions/recoveryXActions';
import {
  setCallDetails,
  setBatchCallId,
  setCallId,
  setCallStatus,
  setSingleCallStatus,
  setDestinationName,
  setDestinationNumber,
  setRTCSession,
  setCallFilterLeads,
  setRTCCallStatus,
  setCampaignId,
  changeDispostionStatus,
  changeAgentActiveStatus,
  setDomainsOfCampaign,
  setCallType,
} from '../../../actions/recoveryXActions';

import PreLoader from '../../../common/component/preLoader/PreLoader';

const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

export function StaticCampaign(props) {
  let campaignUniqueId = useSelector((state) => state.recovery.campaignId);
  let batchCallId = useSelector((state) => state.recovery.batchCallId);
  const batchCallIdRef = useRef(batchCallId);
  const campaignUniqueIdRef = useRef(campaignUniqueId);
  const [campaignData, setCampaignData] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [callOptions, setCallOptions] = useState(false);
  const [callOptionNumber, setCallOptionNumber] = useState(null);
  const [callOptionSecondaryNumber, setCallOptionSecondaryNumber] =
    useState(null);
  const [dispositions, setDispositions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSearchKey, setDataSearchKey] = useState('');
  const [dataSearchValue, setDataSearchValue] = useState('');
  const [resumeBatchCallId, setResumeBatchCallId] = useState('');
  const [callOptionDetails, setCallOptionDetails] = useState([]);
  const [resumeAutoCalling, setResumeAutoCalling] = useState(true);
  const [filteredHeaders, setFilteredHeaders] = useState([]);
  const [isHeadersPopUpOpen, setIsHeadersPopUpOpen] = useState(false);
  const [hideTableHeaders, setHideTableHeaders] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const agentId = searchParams.get('agentId');
  const campaignId = searchParams.get('campaignId');
  let socket = useSocket();
  batchCallId = props?.batchCallId;
  let callId = useSelector((state) => state.recovery.dialerCallId);
  let singleCallStatus = useSelector(
    (state) => state.recovery.singleCallStatus
  );
  let callFilterLeads = useSelector((state) => state.recovery.callFilterLeads);

  let isConnected = useSelector((state) => state.recovery.isWebRtcConnected);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    setFilteredHeaders(props.staticCampaignTableHeaders || ['name', 'number']);
  }, [props.staticCampaignTableHeaders]);
  useEffect(() => {
    campaignUniqueIdRef.current = campaignUniqueId;
    batchCallIdRef.current = batchCallId;
    console.log(
      'updated refs',
      campaignUniqueIdRef.current,
      batchCallIdRef.current
    );
  }, [campaignUniqueId, batchCallId]);

  const updateFilteredHeader = (header, isSelected) => {
    if (isSelected) {
      setFilteredHeaders((headers) => [...headers, header]);
      props.setStaticCampaignTableHeaders({
        staticCampaignTableHeaders: [...filteredHeaders, header],
      });
    } else {
      const unFilteredHeaders = filteredHeaders.filter(
        (headerType) => headerType !== header
      );
      setFilteredHeaders(unFilteredHeaders);
      props.setStaticCampaignTableHeaders({
        staticCampaignTableHeaders: unFilteredHeaders,
      });
    }
  };
  const handleFetchCampaignDetails = (
    agentId,
    campaignId,
    searchKey = null,
    searchValue = null,
    value
  ) => {
    console.log('the values are', dataSearchKey, dataSearchValue);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
      agentId,
      pageNo: value ? (value === 1 ? 0 : value - 1) : currentPage,
      pageSize: pageSize,
    };
    if (searchKey && searchValue) {
      data.searchKey = searchKey;
      data.searchValue = searchValue;
    }
    if (dataSearchKey && dataSearchValue) {
      data.searchKey = dataSearchKey;
      data.searchValue = dataSearchValue;
    }
    GET(cloudCallCenterUrl + 'campaigns/get-static-campaign-leads', data)
      .then((res) => {
        console.log('the domains are', res.data.response.domainsOfCampaign);
        setCampaignData(res.data.response);
        props.setDomainsOfCampaign({
          domainsOfCampaign: res.data.response.domainsOfCampaign || [
            'pbx1.dev.notring.com',
          ],
        });
        props.setCallFilterLeads({ callFilterLeads: res.data.response.leads });
        setResumeBatchCallId(res.data.response.batchCallId);
        setCurrentPage(res.data.response.pageNo);
        setTotalPages(res.data.response.totalPages);
        setHeaders(res.data.response.headerKeys);
        setHideTableHeaders(res.data.response.uploadWithOutLeads || false);
        props.setCallType({ callType: res.data.response.callType });
        props.changeDispostionStatus({ status: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetHandleFetchCampaignDetails = (agentId, campaignId, value) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
      agentId,
      pageNo: value ? (value === 1 ? 0 : value - 1) : currentPage,
      pageSize: pageSize,
    };
    GET(cloudCallCenterUrl + 'campaigns/get-static-campaign-leads', data)
      .then((res) => {
        setCampaignData(res.data.response);
        props.setCallFilterLeads({ callFilterLeads: res.data.response.leads });
        setCurrentPage(res.data.response.pageNo);
        setTotalPages(res.data.response.totalPages);
        setResumeBatchCallId(res.data.response.batchCallId);
      })
      .catch((err) => console.log(err));
  };

  const tempFunction = (destinationNumber, status, duration) => {
    const tempLeadsData = callFilterLeads.map((lead) => {
      if (
        lead.number === destinationNumber ||
        lead.secondaryNumber === destinationNumber
      ) {
        lead.status = status;
        lead.duration = duration;
      }
      return lead;
    });
    campaignData.leads = tempLeadsData;
    setCampaignData(campaignData);
  };

  useEffect(() => {
    if (agentId && campaignId) {
      handleFetchCampaignDetails(agentId, campaignId);
      getDispositions();
    }
  }, [agentId, campaignId]);

  useEffect(() => {
    let shouldRefreshCallDetails = true;
    if (socket) {
      let shouldRefreshCallDetails = true;
      socket.on('setBatchCallId', (message, cb) => {
        props.setBatchCallId(message);
        props.setCampaignId(message);
        cb({
          status: 'Acknowledged',
        });
      });
      socket.on('callId', (message, cb) => {
        props.setCallId({ callId: message });
        shouldRefreshCallDetails = true;
        cb({
          status: 'Acknowledged',
        });
      });
      socket.on('changeSingleCallStatus', (message, cb) => {
        props.setSingleCallStatus({ singleCallStatus: false });
        cb({
          status: 'Acknowledged',
        });
      });
      socket.on('refreshCampaignCallDetails', (message, cb) => {
        if (shouldRefreshCallDetails) {
          shouldRefreshCallDetails = false;
          tempFunction();
        }
        cb({
          status: 'Acknowledged',
        });
      });
      socket.on('connectionStatus', (message, cb) => {
        if (!message.connected) {
          stopBatchCall();
        }
        cb({
          status: 'Acknowledged',
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    if (props.dispositionStatus === true && agentId && campaignId) {
      handleFetchCampaignDetails(agentId, campaignId);
    }
  }, [props.dispositionStatus]);

  // Register a user agent to Asterisk server. UseEffet makes the code run only once.
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      // stopUA();
    };
  }, [campaignUniqueId]);

  const handleOffline = () => {
    toast.error('Network disconnected. Please check internet connection');
    // setIsWebSocketConnected(false);
  };

  const handleOnline = () => {
    toast.success('Network is up. Please refresh to reconnect to server');
  };

  // Reload  conformation
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    stopBatchCall();
  };

  const initiateRecoveryCall = (
    destinationNumber,
    name,
    campaignId,
    callId
  ) => {
    if (!isConnected) {
      toast.error('create connection to place calls');
      return;
    }

    if (callId) {
      toast.error('There is an Active call');
      return;
    }
    if (campaignUniqueId) {
      toast.error('There is an Active Campaign');
      return;
    }
    if (localStorage.getItem('doosra-biz-user-number') === destinationNumber) {
      toast.error('Cannot Call To The Same Number');
      return;
    }
    console.log('props is ', name, destinationNumber);
    let data = {
      number: destinationNumber,
      name,
      campaignId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      isRecoveryCall: true,
      isIndividualCall: true,
      isV2Call: true,
      isSingleCall: true,
      isPersistentCall: false,
    };
    console.log('data is ', data);
    props.setSingleCallStatus({ singleCallStatus: true });
    POST(cloudCallCenterUrl + 'cloud-call/initiate-call', data)
      .then((res) => {
        setCallOptions(false);
        console.log('++++++++++++++', res?.data?.response?.callId);
        props.setCallId({ callId: res?.data?.response?.callId });
        console.log('setting the single call statsus to true');
        return true;
      })
      .catch((err) => {
        setCallOptions(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const initiateWebRTCRecoveryCall = (number, name) => {
    if (!isConnected) {
      toast.error('create connection to place calls');
      return;
    }
    if (callId || singleCallStatus) {
      console.log('In the if condition ', callId, singleCallStatus);
      toast.error('There is an Active call');
      return;
    }
    if (campaignUniqueId) {
      toast.error('There is an Active Campaign');
      return;
    }
    if (localStorage.getItem('doosra-biz-user-number') === number) {
      toast.error('Cannot Call To The Same Number');
      return;
    }
    setSingleCallStatus(true);
    localStorage.setItem('singleCallStatus', true);
    props.setSingleCallStatus({ singleCallStatus: true });
    let data = {
      number,
      name,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      isRecoveryCall: true,
      campaignId,
      isV2Call: true,
      isSingleCall: true,
      isPersistentCall: false,
    };
    console.log('tge data for the single call is', data);
    POST(cloudCallCenterUrl + 'cloud-call/initiate-call', data)
      .then((res) => {
        props.setCallId({ callId: res?.data?.response?.callId });
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const addSecondaryNumber = (e) => {
    setLoading(true);
    const body = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
      number: e.primaryNumber,
      action: 'UPDATE_SECONDARY_NUMBER',
      data: e.number,
    };
    PUT(cloudCallCenterUrl + 'campaigns/update-lead', {}, body)
      .then((res) => {
        handleFetchCampaignDetails(agentId, campaignId);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  function CampaignReportTable(props) {
    const getStatus = (status) => {
      let res = '';
      if (status) {
        if (status === 'not_answered') {
          res = 'Not Answered';
        }
        if (status === 'answered') {
          res = 'Answered';
        }
      }
      return res;
    };
    const onClickCall = (agent) => {
      if (props.isValidCampaign && props.isActive) {
        if (agent?.secondaryNumber) {
          setCallOptions(true);
          setCallOptionNumber(agent?.number);
          setCallOptionSecondaryNumber(agent?.secondaryNumber);
          setCallOptionDetails([agent?.name, props.campaignId, props.callId]);
        } else {
          initiateRecoveryCall(
            agent?.number,
            agent?.name,
            props.campaignId,
            props.callId
          );
        }
      }
    };
    return (
      <div>
        <DataTable.Table style={{ width: '100%' }}>
          <DataTable.Head>
            <DataTable.CellHead width="20">#</DataTable.CellHead>
            {props.headers &&
              props.headers.map((header) => (
                <DataTable.CellHead width="60px">
                  {header.toUpperCase()}
                </DataTable.CellHead>
              ))}
            {/* <DataTable.CellHead width="60px">LEAD NAME</DataTable.CellHead>
            <DataTable.CellHead width="60px">LEAD NUMBER</DataTable.CellHead>
            <DataTable.CellHead width="60px">LEAD SECONDARY NUMBER</DataTable.CellHead>
            <DataTable.CellHead width="60px">DUE</DataTable.CellHead> */}
            <DataTable.CellHead width="90px">
              LAST CALL STATUS
            </DataTable.CellHead>
            <DataTable.CellHead width="80">
              LAST CALL DURATION (s)
            </DataTable.CellHead>
            <DataTable.CellHead width="60">CALL DATE</DataTable.CellHead>
            <DataTable.CellHead width="60">CALL TIME</DataTable.CellHead>
            <DataTable.CellHead width="60">DISPOSITION</DataTable.CellHead>
            <DataTable.CellHead width="80">
              DISPOSITION COMMENT
            </DataTable.CellHead>
            <DataTable.CellHead width="50" style={{ textAlign: 'end' }}>
              HISTORY
            </DataTable.CellHead>
            <DataTable.CellHead width="50" style={{ textAlign: 'end' }}>
              ACTIONS
            </DataTable.CellHead>
          </DataTable.Head>
          <DataTable.Body>
            {props?.agentsList?.length > 0 &&
              props?.agentsList?.map((agent, index) => (
                <DataTable.Row id={agent.userId}>
                  <DataTable.Cell>
                    {props.pageNo * props.pageSize + 1 + index}
                  </DataTable.Cell>
                  {props.headers &&
                    props.headers.map((header) => (
                      <DataTable.Cell width="60px">
                        {agent[header]}
                      </DataTable.Cell>
                    ))}
                  {/* <DataTable.Cell>{agent?.name}</DataTable.Cell>
                  <DataTable.Cell>{agent?.number}</DataTable.Cell>
                  <DataTable.Cell>{agent?.secondaryNumber}</DataTable.Cell>
                  <DataTable.Cell>{agent?.due}</DataTable.Cell> */}
                  <DataTable.Cell>
                    {getStatus(agent?.status) || '-'}
                  </DataTable.Cell>
                  <DataTable.Cell>{agent?.duration || '-'}</DataTable.Cell>
                  <DataTable.Cell>
                    {agent?.callDate
                      ? new Date(agent?.callDate).toLocaleDateString()
                      : '-'}
                  </DataTable.Cell>
                  <DataTable.Cell>
                    {agent?.callDate
                      ? new Date(agent?.callDate).toLocaleTimeString()
                      : '-'}
                  </DataTable.Cell>
                  <DataTable.Cell>{agent?.disposition || '-'}</DataTable.Cell>
                  <DataTable.Cell>{agent?.callComment || '-'}</DataTable.Cell>
                  <DataTable.Cell>
                    <CallHistoryPopup
                      campaignId={campaignId}
                      destinationNumber={agent?.number}
                      secondaryNumber={agent?.secondaryNumber}
                    ></CallHistoryPopup>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <div style={{ display: 'flex', placeContent: 'flex-end' }}>
                      <div>
                        <EditLeadPopup
                          isDisabled={agent?.secondaryNumber !== ''}
                          primaryNumber={agent?.number}
                          addSecondaryNumber={(e) => addSecondaryNumber(e)}
                        ></EditLeadPopup>
                      </div>
                      <div>
                        <AddCallRemarksPopup
                          agentHandleDisposition={
                            agent?.status?.toLowerCase() === 'not_initiated'
                          }
                          isButtonDisabled={
                            agent.disposition &&
                            agent.disposition !== '' &&
                            agent.disposition !== 'RNR'
                              ? true
                              : false
                          }
                          agentId={agentId}
                          handleFetchCampaignDetails={
                            handleFetchCampaignDetails
                          }
                          dispositions={dispositions}
                          number={agent?.number}
                          secondaryNumber={agent?.secondaryNumber}
                          campaignId={campaignId}
                        />
                      </div>
                      <div className="tooltip-container">
                        <div className="tooltip-button">
                          <button
                            className="ActivateButton"
                            onClick={() => onClickCall(agent)}
                            style={{
                              width: '32px',
                              height: '32px',
                              backgroundColor: '#07aa3f',
                              opacity:
                                props.isValidCampaign && props.isActive
                                  ? 1
                                  : 0.5,
                              cursor:
                                props.isValidCampaign && props.isActive
                                  ? 'pointer'
                                  : 'default',
                            }}
                          >
                            <img
                              alt=""
                              src={phoneWhite}
                              className="SidebarItemIcon"
                              style={{ width: '16px', height: '16px' }}
                            />
                          </button>
                        </div>
                        <div
                          className="tooltip-text left"
                          data-tip-position="left"
                        >
                          {props.isActive
                            ? props.isValidCampaign
                              ? 'Start Call'
                              : 'campaign is not valid'
                            : 'You are in Inactive Status'}
                          <span className="arrow-right"></span>
                        </div>
                      </div>
                    </div>
                  </DataTable.Cell>
                </DataTable.Row>
              ))}
          </DataTable.Body>
        </DataTable.Table>
      </div>
    );
  }

  const getDispositions = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(cloudCallCenterUrl + 'disposition', data)
      .then((res) => {
        setDispositions(res.data.response.dispositions);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const fetchWithFilter = (filter) => {
    console.log('the set values are', filter.searchKey, filter.searchValue);
    if (filter.searchKey === 'call_status') {
      filter.searchKey = 'status';
    }
    setDataSearchKey(filter.searchKey);
    setDataSearchValue(filter.searchCondition);
    handleFetchCampaignDetails(
      agentId,
      campaignId,
      filter.searchKey,
      filter.searchCondition
    );
  };

  const reset = (agentId, campaignId) => {
    setDataSearchKey('');
    setDataSearchValue('');
    resetHandleFetchCampaignDetails(agentId, campaignId);
  };
  const resetValues = () => {
    props.setSingleCallStatus({ singleCallStatus: false });
    localStorage.setItem('singleCallStatus', false);
    props.setCallId({ callId: '' });
  };

  const initiateWebRTCBatchRecoveryCall = (filter = {}) => {
    console.log('The batch call id is', batchCallId);
    if (!resumeAutoCalling) {
      toast.error('Please try after 30 seconds.');
      return;
    }
    if (singleCallStatus || callId) {
      toast.error('There is an active call');
      return;
    }
    if (batchCallId) {
      toast.error('There is an active campaign call');
      return;
    }
    setLoading(true);
    const data = {};
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.campaignId = campaignId;
    data.isV2Call = true;
    data.isPersistentCall = false;
    if (filter.isFilterSelected && filter.filteredLeads) {
      data.isFilterSelected = true;
      data.selectedDispositions = filter.filteredLeads;
    }

    console.log('the data is ', data);
    return POST(cloudCallCenterUrl + 'cloud-call/initiate-call', data)
      .then((res) => {
        props.setBatchCallId({ batchCallId: res.data.response.callId });
        props.setCampaignId({ campaignId: campaignId });
        setLoading(false);
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        setLoading(false);
        return false;
      });
  };

  const filterAndInitiatebatchCallHandler = (filteredLeads) => {
    const data = {
      isFilterSelected: true,
      filteredLeads,
    };
    initiateWebRTCBatchRecoveryCall(data);
  };
  const resumeBatchCallHadler = () => {
    if (singleCallStatus === true) {
      toast.error('There is an active single call');
      return;
    }
    if (resumeBatchCallId === '' || !resumeBatchCallId) {
      toast.error('There is no previous batch call');
      return;
    }

    var data = JSON.stringify({
      batchCallId: resumeBatchCallId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      isV2Call: true,
    });
    return POST(cloudCallCenterUrl + 'cloud-call/resume-batch-call', data)
      .then((res) => {
        props.setBatchCallId({ batchCallId: resumeBatchCallId });
        props.setCampaignId({ campaignId });
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const resumeAutoCallingTimeHandler = () => {
    props.setRTCCallStatus({ RTCcallStatus: '' });
    setTimeout(() => {
      setResumeAutoCalling(true);
    }, 31000);
  };

  const emitEvent = (userId, eventName, message) => {
    var body = {
      user_id: userId,
      event_name: eventName,
      data: message,
    };
    return PUT(cloudCallCenterUrl + 'campaign/emit-event', {}, body)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const stopBatchCall = () => {
    const userId = localStorage.getItem('doosra-biz-user-id');
    emitEvent(userId, 'stopBatchCall', { isBatchCallStopped: true });
    resetValues();
    if (!campaignUniqueIdRef?.current) {
      props.setSingleCallStatus({ singleCallStatus: false });
      props.setCallId({ callId: '' });
      return;
    }
    const batchId = batchCallIdRef.current;
    props.setBatchCallId({ batchCallId: '' });
    props.setCampaignId({ campaignId: '' });
    props.setSingleCallStatus({ singleCallStatus: false });
    props.setCallId({ callId: '' });
    var data = JSON.stringify({
      batchCallId: batchId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: searchParams.get('campaignId'),
      userId,
    });
    return POST(cloudCallCenterUrl + 'cloud-call/stop-batch-call', data)
      .then((res) => {
        setResumeAutoCalling(false);
        resumeAutoCallingTimeHandler();
        setResumeBatchCallId(batchCallId);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };
  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                alt=""
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  emitEvent(
                    localStorage.getItem('doosra-biz-user-id'),
                    'isInCampaign',
                    false
                  );
                  history.push('/agent-campaigns');
                }}
              />
              <div
                style={{
                  width: '200px',
                  height: '50px',
                  // overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                  padding: '10px',
                  color: '#00515a',
                  margin: 0,
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {campaignData?.campaignName
                  ? `${campaignData?.campaignName}`
                  : ''}
              </div>
            </div>
          </TabSearchBar>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '15px',
              marginTop: '5px',
            }}
          >
            {isConnected && (campaignUniqueId || batchCallId) ? (
              <button
                className="Otherbutton"
                style={{
                  width: 200,
                  color: 'white',
                  backgroundColor:
                    props.agentActiveStatus === false ||
                    campaignData.isValidCampaign === false
                      ? 'lightgrey'
                      : '#ed3833',
                }}
                onClick={() => stopBatchCall()}
                disabled={
                  props.agentActiveStatus === false ||
                  campaignData.isValidCampaign === false
                }
              >
                Stop Calling Leads
              </button>
            ) : (
              <BatchCallPopup
                dispositions={dispositions}
                isActive={props.agentActiveStatus}
                isValidCampaign={campaignData.isValidCampaign}
                leadsCount={campaignData?.leads?.length || 0}
                batchCallHandler={() => initiateWebRTCBatchRecoveryCall()}
                filterAndInitiatebatchCallHandler={(e) =>
                  filterAndInitiatebatchCallHandler(e)
                }
                resumeBatchCallHanlder={() => resumeBatchCallHadler()}
                resumeBatchCallId={resumeBatchCallId || ''}
                isConnected={isConnected}
              ></BatchCallPopup>
            )}
            {isConnected ? (
              <DiscreteCallPopup
                // open={this.state.isModalOpen}
                toggle={() => toggleModal()}
                initiateRecoveryCall={(e) => initiateWebRTCRecoveryCall(e)}
                isActive={props.agentActiveStatus}
                isValidCampaign={true}
              ></DiscreteCallPopup>
            ) : null}

            {/* <ModifyHeadersPopUp
              headers={headers}
              updateFilteredHeader={updateFilteredHeader}
              filteredHeaders={filteredHeaders}
              isPopUpOpen={isHeadersPopUpOpen}
              openHeadersPopUp={() => setIsHeadersPopUpOpen(true)}
              closeHeadersPopUp={() => setIsHeadersPopUpOpen(false)}
            /> */}
          </div>
          <div
            style={{
              display: 'flex',
              gap: '32px',
              padding: '8px 16px 2px 16px',
            }}
          >
            <DataCard title="Calls" count={campaignData?.totalCalls} />
            <DataCard
              title="Connected Calls"
              count={campaignData?.answeredCalls}
            />
          </div>
          <div>
            <SearchBar
              type="campaign-agent"
              subType="static"
              isPlaceACallButtonVisible={false}
              search={(e) => fetchWithFilter(e)}
              reset={(e) => reset(agentId, campaignId)}
              toggleModal={() => toggleModal()}
              initiateRecoveryCall={(e) => initiateWebRTCRecoveryCall(e)}
              agentStatus={
                campaignData.isValidCampaign && campaignData.isActive
              }
              headers={headers}
              updateFilteredHeader={updateFilteredHeader}
              filteredHeaders={filteredHeaders}
              isPopUpOpen={isHeadersPopUpOpen}
              openHeadersPopUp={() => setIsHeadersPopUpOpen(true)}
              closeHeadersPopUp={() => setIsHeadersPopUpOpen(false)}
              hideTableHeaders={hideTableHeaders}
            ></SearchBar>
          </div>
          {loading && <PreLoader />}
          {!loading && (
            <div>
              <CampaignReportTable
                isActive={props.agentActiveStatus}
                isValidCampaign={campaignData.isValidCampaign}
                agentsList={campaignData.leads}
                campaignId={campaignId}
                pageNo={currentPage}
                pageSize={pageSize}
                headers={filteredHeaders}
                callId={callId}
              />
              <Paginator
                currentPage={currentPage + 1}
                lastPage={totalPages}
                getInfo={(value) => {
                  handleFetchCampaignDetails(
                    agentId,
                    campaignId,
                    null,
                    null,
                    value
                  );
                }}
              />
              {callOptions && (
                <Popup
                  open={callOptions}
                  closeOnDocumentClick={false}
                  closeOnEscape={false}
                >
                  <div className="PopupContainer">
                    <div className="PopupHeaderContainer">
                      <p>SELECT NUMBER TO CALL</p>
                      <img
                        alt=""
                        className="PopupClose"
                        src={Close}
                        onClick={() => setCallOptions(false)}
                      />
                    </div>
                    <div className="PopupBody">
                      <button
                        className="ButtonCancelFullWidth"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          initiateRecoveryCall(
                            callOptionNumber,
                            ...callOptionDetails
                          )
                        }
                      >
                        PRIMARY NUMBER
                      </button>
                      <button
                        className="ButtonCancelFullWidth"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          initiateRecoveryCall(
                            callOptionSecondaryNumber,
                            ...callOptionDetails
                          )
                        }
                      >
                        SECONDARY NUMBER
                      </button>
                    </div>
                    <br />
                  </div>
                </Popup>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isReocveryXEnabled: state.topbar.isReocveryXEnabled,
  isNumberMaskingEnabled: state.topbar.isNumberMaskingEnabled,
  userDetails: state.recovery.userDetails,
  callStatus: state.recovery.callStatus,
  position: state.recovery.position,
  batchCallId: state.recovery.batchCallId,
  callId: state.recovery.callId,
  callFilterLeads: state.recovery.callFilterLeads,
  singleCallStatus: state.recovery.singleCallStatus,
  isRecoveryXEmailSent: state.topbar.isRecoveryXEmailSent,
  dispositionStatus: state.recovery.dispositionStatus,
  agentActiveStatus: state.recovery.agentActiveStatus,
  staticCampaignTableHeaders: state.recovery.staticCampaignTableHeaders,
});

export default commonStyle(
  connect(mapStateToProps, {
    setCallDetails,
    setBatchCallId,
    setCallId,
    setCallStatus,
    setSingleCallStatus,
    setDestinationNumber,
    setDestinationName,
    setRTCSession,
    setCallFilterLeads,
    setRTCCallStatus,
    setCampaignId,
    changeDispostionStatus,
    changeAgentActiveStatus,
    setDomainsOfCampaign,
    setCallType,
    setStaticCampaignTableHeaders,
  })(StaticCampaign)
);

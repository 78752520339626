import React, { useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import { EditIcon } from '../../../assets/Icons/Icons';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import Notes from './Notes';
import Tags from './Tags';
import { PUT } from '../../../services/HttpRequests';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { toast } from 'react-toastify';
// import Tooltip from './Tooltip';
import {
  EDIT_CONTACT_BUTTON,
  ADD_ANOTHER_CONTACT_BUTTON,
  SAVE_CONTACT_BUTTON,
  ADMIN_EDIT_CONTACT_BUTTON,
  ADMIN_ADD_ANOTHER_CONTACT_BUTTON,
  ADMIN_SAVE_CONTACT_BUTTON,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';
const userUrl = process.env.REACT_APP_USER_API;
const userRole = localStorage.getItem('doosra-biz-user-role');

const ContactInput = (props) => {
  return (
    <div key={props.index} style={{ height: '62px' }}>
      <input
        className="PopupInput"
        id="emp_id"
        disabled
        value={props.number}
        onChange={(e) => props.updateNumber(e.target.value)}
      />
      {/* <img
        alt=""
        style={{ position: 'relative', top: '-45px', left: '328px', cursor: "pointer" }}
        onClick={props.removeHandler}
        src={Delete}
      /> */}
    </div>
  );
};

export default function EditContactPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [contactName, setContactName] = useState(props?.contactInfo?.name);
  const [contactNumbers, setContactNumbers] = useState(
    props?.contactInfo?.phoneNumbers
  );
  const [error, setError] = useState(null);

  const removeNumberHandler = (i) => {
    const updatedList = [...contactNumbers];
    updatedList.splice(i, 1);
    setContactNumbers(updatedList);
  };

  const addNumberHandler = () => {
    logPostHogEvent(
      userRole === 'ADMIN'
        ? ADMIN_ADD_ANOTHER_CONTACT_BUTTON
        : ADD_ANOTHER_CONTACT_BUTTON
    );
    // if (contactNumbers[contactNumbers.length - 1] === '') return;
    const updatedList = [...contactNumbers, ''];
    setContactNumbers(updatedList);
  };

  const updateContactNumbers = (index, value) => {
    const list = [...contactNumbers];
    list[index] = value;
    setContactNumbers(list);
  };

  const updateContactHandler = () => {
    logPostHogEvent(
      userRole === 'ADMIN' ? ADMIN_SAVE_CONTACT_BUTTON : SAVE_CONTACT_BUTTON
    );
    let wrongNos = false;
    contactNumbers.forEach((no) => {
      if (!no.match('^[0-9]{10}$')) {
        setError('Please enter 10 digit number');
        wrongNos = true;
      }
    });
    if (!contactName) {
      setError('Please Enter Contact Name');
      wrongNos = true;
    }
    const data = {
      id: props.contactInfo._id,
      name: contactName,
      phoneNumbers: contactNumbers,
      userId: props.contactInfo.userId,
    };
    if (!wrongNos) {
      setLoading(true);
      PUT(userUrl + 'v1/user/contact', null, data)
        .then((res) => {
          setLoading(false);
          props?.stopViewPopup(false);
          toast.success('Updated Successfully');
          setError(null);
          wrongNos = false;
        })
        .catch((err) => {
          setLoading(false);
          props?.stopViewPopup(false);
          toast.error(err?.response?.data?.error?.reason);
        });
    }
  };

  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div
        className="PopupContainer"
        style={{
          maxHeight: '600px',
          overflow: 'scroll',
          marginBottom: 30,
        }}
      >
        <div className="PopupHeaderContainer">
          <p>Edit Contact Profile</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => {
              setPopupOpen(false);
              props.getList();
              props?.stopViewPopup(false);
            }}
          />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Contact Name</label>
            <input
              className="PopupInput"
              id="emp_id"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Contact Numbers</label>
            {contactNumbers?.length > 0 &&
              contactNumbers.map((item, index) => (
                <ContactInput
                  number={item}
                  index={index}
                  updateNumber={(number) => updateContactNumbers(index, number)}
                  removeHandler={() => removeNumberHandler(index)}
                />
              ))}

            <div style={{ display: 'flex' }}>
              <div className="ContactAddBtn">
                {' '}
                <a onClick={addNumberHandler}> +Add another number </a>
              </div>
              <div
                onClick={(e) => {
                  updateContactHandler();
                }}
                className="ContactSaveBtn"
              >
                Save Contact
              </div>
            </div>
          </div>
          {error ? (
            <div className="ErrorContainer ErrorContainerMargin">
              <img
                alt=""
                src={InfoRed}
                style={{
                  paddingRight: '5px',
                  position: 'relative',
                  top: '-2px',
                }}
              />
              {error}
            </div>
          ) : null}

          {loading && <PreLoader />}
          {userRole === 'USER' && (
            <>
              <Tags contactId={props?.contactInfo?._id} action="EDIT" />
              <Notes contactId={props?.contactInfo?._id} action="EDIT" />
            </>
          )}
          <button
            className="ButtonCancelFullWidth"
            onClick={() => {
              setPopupOpen(false);
              props.getList();
              props?.stopViewPopup(false);
            }}
            style={{ marginTop: 30 }}
          >
            CLOSE
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <React.Fragment>
      <div>
        {props.editFrom == 'contacts' && (
          // <Tooltip
          //   tooltipText="Edit Contact"
          //   position="top"
          // >
          <div style={{ padding: '5px', cursor: 'pointer' }}>
            <EditIcon
              onClick={() => {
                logPostHogEvent(
                  userRole === 'ADMIN'
                    ? ADMIN_EDIT_CONTACT_BUTTON
                    : EDIT_CONTACT_BUTTON
                );
                setPopupOpen(true);
              }}
            />
          </div>
          // </Tooltip>
        )}
        {props.editFrom == 'callLog' && (
          <button
            className="ButtonFullWidth"
            style={{ marginTop: '30px', cursor: 'pointer' }}
            type="submit"
            onClick={() => {
              setPopupOpen(true);
            }}
          >
            EDIT
          </button>
        )}
      </div>
      {popper}
    </React.Fragment>
  );
}

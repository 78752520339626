import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import { toast } from 'react-toastify';
import { POST } from '../../../services/HttpRequests';
const userUrl = process.env.REACT_APP_USER_API;

class UnlockUserPopup extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ popupOpen: true });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  unBlockHandler = () => {
    const { user_id } = this.props;

    this.setState({ loading: true });

    if (user_id && user_id !== '') {
      return POST(`${userUrl}v1/user/unblock-account`, {
        user_id,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      })
        .then((res) => res.data.response)
        .then((response) => {
          if (response && response.msg) {
            this.setState({ loading: false });
            this.closePopup();
            this.props.onSuccess && this.props.onSuccess();
            toast.success(response.msg);
          } else {
            toast.error('Unable to unlock');
            this.setState({ loading: false });
          }
        })
        .catch(() => {
          this.closePopup();
          this.setState({ loading: false });
          toast.error('Unable to unlock');
        });
    }

    return false;
  };

  render() {
    return (
      <React.Fragment>
        <a href="#" style={{ color: '#ED3833' }} onClick={this.openPopup}>
          Unlock Account
        </a>
        <Popup
          open={this.state.popupOpen}
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>Unlock this account?</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            <div className="PopupBody">
              <p className="PopupBodyTitle">
                We locked this account following 5 consecutive incorrect login
                attempts.
              </p>
              <button
                className="ButtonFullWidth BgRed"
                onClick={this.unBlockHandler}
              >
                Unlock
              </button>
            </div>
          </div>
        </Popup>
      </React.Fragment>
    );
  }
}

export default UnlockUserPopup;

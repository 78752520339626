import React, { useContext, useState, useEffect } from 'react';
import { useAuth } from "./AuthProvider";
import { io } from "socket.io-client";
const recoveryCallUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

export const SocketContext = React.createContext();

const socketOptions = {
  transports: ["websocket"],
  upgrade: false,
  reconnectionDelay: 2000,
  reconnectionAttempts: 30,
  reconnectionDelayMax: 2000
};

export default function SocketProvider({ children }) {
  const { isLoggedIn } = useAuth();
  const [socket, setSocket] = useState(null);

  useEffect(() => {

    setTimeout(() => {
      console.log("is_cloud_call_center_enabled", localStorage.getItem('is_cloud_call_center_enabled'));
      if (isLoggedIn) {
        let socketUrl = process.env.REACT_APP_RECOVERY_CALL_API;
        if (localStorage.getItem('is_cloud_call_center_enabled') === 'true' || localStorage.getItem("is_dialer_widget_enabled") === "true") {
          socketUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
        }

        const socket = io(`${socketUrl}?userId=${localStorage.getItem('doosra-biz-user-id')}`, socketOptions);

        socket.on('connect', () => {
          console.log(`Connected to server with id: ${socket.id}`);
        });

        socket.on('disconnect', () => {
          console.log('Disconnected from server');
        });

        socket.on('reconnect', () => {
          console.log(`Reconnected to server with id: ${socket.id}`);
        });

        setSocket(socket);
      }
    }, 2000);

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [isLoggedIn]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
}

export function useSocket() {
  return useContext(SocketContext);
}

/* eslint-disable */
import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import DataTable from '../../../common/component/table/DataTable';
import Close from '../../../assets/images/popupClose.png';
import { POST, GET } from '../../../services/HttpRequests';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import downloadIcon from '../../../assets/images/DownloadButton.svg';
import EyeVisiible from '../../../assets/images/EyeVisible.svg';
import EyeNotVisiible from '../../../assets/images/EyeNotVisible.svg';
import AddLeadToLeadListPopup from './AddLeadToLeadListPopup';
import '../../../assets/css/SearchBar.css';
import './styles.css';
import { isSuperAdminWithPermission } from '../../../utility/utility';
const userUrl = process.env.REACT_APP_USER_API;
const URL = process.env.REACT_APP_SAMPLE_FILE_URL;

export default function DeleteLeadListPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leadList, setLeadList] = useState(props.leadLists[0]?._id || '');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const deleteLeadListHandler = (leadListId) => {
    console.log(leadListId);
    props.deleteLeadList(leadListId);
  };

  const toggleLeadListVisibility = (leadListId, isHidden) => {
    props.toggleLeadListVisibility(leadListId, isHidden);
  };
  const PopupContainer = {
    width: '1000px',
  };
  let displayData;
  if (loading) displayData = <PreLoader />;
  if (props.leadLists.length > 0) {
    displayData = props.leadLists.map((row) => (
      <DataTable.Row key={row._id}>
        <DataTable.Cell>{row.leadListName || '-'}</DataTable.Cell>
        <DataTable.Cell>
          {!row.count || row.count === 'NA' ? 0 : row.count}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.userName || row.userName === 'NA' ? '-' : row.userName}
        </DataTable.Cell>
        <DataTable.Cell>
          {new Date(row.createdAt).toLocaleDateString() || '-'}
        </DataTable.Cell>
        <DataTable.Cell>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <>
              {!isSuperAdminWithPermission('DELETE') ? (
                <div
                  style={{
                    background: 'none',
                    border: 'none',
                    marginLeft: '120px',
                  }}
                >
                  <img src={Delete}></img>
                </div>
              ) : (
                <button
                  style={{
                    background: 'none',
                    border: 'none',
                    marginLeft: '120px',
                  }}
                >
                  <img
                    onClick={() => deleteLeadListHandler(row._id)}
                    src={Delete}
                  ></img>
                </button>
              )}
              {row.isHidden ? (
                !isSuperAdminWithPermission('EDIT') ? (
                  <div
                    style={{
                      border: 'none',
                      height: 32,
                      width: 32,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={EyeNotVisiible}></img>
                  </div>
                ) : (
                  <button
                    style={{
                      border: 'none',
                      height: 32,
                      width: 32,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      onClick={() => toggleLeadListVisibility(row._id, false)}
                      src={EyeNotVisiible}
                    ></img>
                  </button>
                )
              ) : !isSuperAdminWithPermission('EDIT') ? (
                <div
                  style={{
                    border: 'none',
                    height: 32,
                    width: 32,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src={EyeVisiible}></img>
                </div>
              ) : (
                <button
                  style={{
                    border: 'none',
                    height: 32,
                    width: 32,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    onClick={() => toggleLeadListVisibility(row._id, true)}
                    src={EyeVisiible}
                  ></img>
                </button>
              )}
            </>
          </div>
        </DataTable.Cell>
      </DataTable.Row>
    ));
  }
  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div
        className="PopupContainer"
        style={{ ...PopupContainer, overflow: 'auto' }}
      >
        <div className="PopupHeaderContainer" style={{ ...PopupContainer }}>
          <p>Master Lead List Sheet</p>
          <img
            style={{ left: '95%' }}
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => setPopupOpen(false)}
          />
        </div>
        <div className="PopupBody" style={{ ...PopupContainer }}>
          <DataTable.Table style={{ ...PopupContainer }}>
            <DataTable.Head>
              <DataTable.CellHead>NAME</DataTable.CellHead>
              <DataTable.CellHead>LEADS</DataTable.CellHead>
              <DataTable.CellHead>AGENT</DataTable.CellHead>
              <DataTable.CellHead>DATE ADDED</DataTable.CellHead>
              <DataTable.CellHead style={{ textAlign: 'end', width: '20%' }}>
                ACTIONS
              </DataTable.CellHead>
            </DataTable.Head>
            <DataTable.Body>{displayData}</DataTable.Body>
          </DataTable.Table>
        </div>
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex' }}>
      <span>
        <button
          className="Otherbutton"
          style={{ marginRight: 0 }}
          onClick={() => {
            setPopupOpen(true);
          }}
        >
          MASTER LIST
        </button>
        {popper}
      </span>
    </div>
  );
}

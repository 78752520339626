import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import Button from '../../../common/component/button/Button';
import CsvDownloader from 'react-csv-downloader';
import { useEffect, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
import AddDID from '../component/AddDID';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import downloadIconGreen from '../../../assets/images/downloadIconGreen.png';
import cancelIcon from '../../../assets/images/cancelIconCircleGreen.png';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
const broadcastUrl = process.env.REACT_APP_BROADCAST_API;

const PopupContainer = {
  width: '400px',
};
const scrollEffect = {
  overflowY: 'auto',
};

const Label = (props) => {
  return (
    <label
      style={{
        marginBottom: '16px',
        width: '100%',
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#282828',
      }}
    >
      {props.children}
    </label>
  );
};

const Input = (props) => {
  return (
    <input
      style={{
        fontSize: '16px',
        width: '100%',
        padding: '8px',
        border: 'solid 1px #939598',
        borderRadius: '4px',
        marginBottom: '24px',
      }}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const SampleFile = () => {
  return (
    <>
      <CsvDownloader
        style={{
          cursor: 'pointer',
          textDecoration: 'none',
          textAlign: 'center',
          marginBottom: '16px',
        }}
        filename="broadcast_list"
        datas={[
          {
            number: '1234567890',
          },
          {
            number: '2323232323',
          },
        ]}
      >
        <a
          style={{
            color: '#13ba0c',
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          Download Sample Sheet{' '}
          <img
            src={downloadIconGreen}
            alt=""
            style={{ margin: '0 0 3px 8px' }}
          ></img>
        </a>
      </CsvDownloader>

      <div
        style={{
          width: '100%',
          height: '56px',
          fontSize: '12px',
          color: '#939598',
          marginBottom: '24px',
          backgroundColor: '#f4f4f4',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        Download the sample sheet and fill it in the
        <br />
        same format without changing rows or columns
      </div>
    </>
  );
};

const Day = (props) => {
  return (
    <div
      className={
        props.weekDays.includes(props.thisDay) ? 'DaySelected' : 'DayDefault'
      }
      onClick={() => {
        props.handleWeekDaySelection(props.thisDay);
      }}
    >
      {props?.thisDay?.charAt(0)}
    </div>
  );
};

export function CreateMessageBroadcast(props) {
  const [smsTemplateId, setSmsTemplateId] = useState();
  const [smsTemplateText, setSmsTemplateText] = useState('');
  const [smsTemplatesList, setSmsTemplatesList] = useState([]);
  const [smsTemplatesValueList, setSmsTemplatesValueList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(null);

  const [weekDays, setWeekDays] = useState([]);
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const edit = searchParams.get('edit');
  const duplicate = searchParams.get('duplicate');

  const handleFetchCampaignDetails = (id) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: id,
    };
    setIsLoading(true);
    GET(broadcastUrl + 'v1/sms', data)
      .then((res) => {
        const {
          campaignName,
          agentsData,
          campaignDescription,
          campaignStartDate,
          campaignEndDate,
          weekDays,
        } = res?.data?.response;
        setName(campaignName);
        setDescription(campaignDescription);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const getSMSTemplatesList = () => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    console.log({ data })
    GET(broadcastUrl + 'v1/smscampaigntemplate/list', data)
      .then((res) => {

        const { templates } = res?.data?.response;
        console.log({ templates })
        setSmsTemplatesValueList(templates);

        // const keys = templates.keys(templates);
        setSmsTemplatesList(templates);
      })
      .catch((err) => {
        console.log('getSMSTemplatesList:', err);
      });
  };

  useEffect(() => {
    getSMSTemplatesList();
  }, []);

  useEffect(() => {
    setIsLoading(false);
    if (edit === 'true' || duplicate === 'true') {
      handleFetchCampaignDetails(id);
    }
  }, [edit, duplicate, id]);

  const submitFormHandler = async () => {
    try {
      if (uploading) return;
      if (!name) {
        toast.error('Campaign name is required');
        return;
      }

      if (!file && edit !== 'true') {
        toast.error('Please upload one lead list for campaign');
        return;
      }

      if (!smsTemplateId) {
        toast.error('SMS Template Id is required');
        return;
      }


      setUploading(true);
      let formData = new FormData();
      formData.append(
        'organisationId',
        localStorage.getItem('doosra-biz-organisation-id')
      );
      formData.append('name', name);
      // formData.append('smsCampaignType', true);
      formData.append('smsTemplateId', smsTemplateId);
      formData.append('description', description);
      // for (let i = 0; i < files.length; i++) {
      formData.append('leadLists', file);
      // }

      if (edit === 'true') {
        for (let entry of formData.entries()) {
          console.log(entry[0], entry[1]);
        }
        await PUT(`${broadcastUrl}v1/sms?id=${id}`, {}, formData, {
          'Content-Type': 'multipart/form-data',
        });
        toast.success(`Succesfully edited Campaign`);
      } else {
        await POST(`${broadcastUrl}v1/sms`, formData, {
          'Content-Type': 'multipart/form-data',
        });
        toast.success(`Succesfully added Campaign`);
      }

      setUploading(false);
      history.push(`/sms-broadcasts-list`);
    } catch (err) {
      setUploading(false);
      console.log(err);
      toast.error(err?.response?.data?.error?.reason || 'An error occured');
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    // const fileList = [...e.dataTransfer.files];
    // do something with the files, e.g. upload to server

    // const updatedFiles = [...files];
    // for (let i = 0; i < fileList.length; i++) {
    //   updatedFiles.push(fileList[i]);
    // }
    const file = e.dataTransfer.files[0];

    setFile(file);
    // clear the input field to allow uploading of the same file again
    e.target.value = '';
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // const updatedFiles = [...files];

    // for (let i = 0; i < fileList.length; i++) {
    //   updatedFiles.push(fileList[i]);
    // }

    setFile(file);
    // onFilesSelected(updatedFiles);
    event.target.value = '';
  };

  const handleRemoveFile = (fileToRemove) => {
    // const updatedFiles = files.filter((file) => file !== fileToRemove);

    setFile(null);
    // onFilesSelected(updatedFiles);
  };

  // const handleWeekDaySelection = (day) => {
  //   if (weekDays.includes(day)) {
  //     setWeekDays(weekDays.filter((val) => val !== day));
  //   } else {
  //     setWeekDays([...weekDays, day]);
  //   }
  // };

  const cancelCampaignCreation = () => {
    history.push('/sms-broadcasts-list');
  };

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={cancelCampaignCreation}
                alt=""
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {duplicate === 'true' ? (
                  <>Duplicating Campaign</>
                ) : edit === 'true' ? (
                  <>Edit Campaign</>
                ) : (
                  <>Create a SMS Broadcast Campaign</>
                )}
              </p>
            </div>
          </TabSearchBar>
          {isLoading && <PreLoader />}
          {uploading && <PreLoader />}
          <Popup open={isOpen}>
            <img
              style={{ left: '96%', top: '-5%', zIndex: '1' }}
              alt=""
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsOpen(false);
              }}
            />
            <div
              className="PopupContainer"
              style={{ ...PopupContainer, ...scrollEffect }}
            >
              <div
                className="PopupHeaderContainer"
                style={{ ...PopupContainer }}
              >
                <p>Create Broadcast Confirmation</p>
              </div>
              <div className="PopupBody" style={{ ...PopupContainer }}>
                <p className="PopupBodyTitle">
                  The broadcast campaign will start on creation. Are you sure
                  you want to create the broadcast?
                </p>
                <button
                  className="ButtonCancelFullWidth"
                  style={{ marginTop: '16px' }}
                  onClick={submitFormHandler}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Popup>
          {/* {!isLoading && ( */}
          <div
            style={{ width: '594px', marginLeft: '40px', marginTop: '40px' }}
          >
            <Label>Campaign Title</Label>
            <input
              style={{
                fontSize: '16px',
                width: '100%',
                padding: '8px',
                border: 'solid 1px #939598',
                borderRadius: '4px',
                marginBottom: '24px',
              }}
              placeholder="Enter the campaign title here…"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={edit === 'true' ? true : false}
            />
            <Label>Campaign Description (optional)</Label>
            <Input
              placeholder="Enter the campaign description here…"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Label>Add Leads</Label>
            <p
              style={{
                fontWeight: 500,
                fontSize: '16px',
                color: '#939598',
                marginTop: '-8px',
              }}
            >
              You can add a single CSV file to  to a campaign
            </p>
            {edit === 'true' && (
              <div style={{ display: 'flex' }}>
                <ul style={{ listStyleType: 'none', padding: '0' }}>
                  <li>
                    <p
                      style={{
                        width: '595px',
                        height: '48px',
                        backgroundColor: 'rgba(7, 170, 63, 0.16)',
                        borderRadius: '4px',
                        textDecoration: 'none',
                        margin: 0,
                        fontSize: '16px',
                        fontWeight: 500,
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                      }}
                    >
                      oldLeads
                    </p>
                  </li>
                  <li>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <span
                        style={{
                          color: 'blue',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          history.push(
                            `/recovery-x-campaign/leads?campaignId=${id}&edit=true`
                          );
                        }}
                      >
                        Preview
                      </span>
                    </div>
                  </li>
                </ul>
                {/* <Button
                  style={{ marginBottom: '8px' }}
                  label="PREVIEW"
                /> */}
              </div>
            )}
            <ul style={{ listStyleType: 'none', padding: '0' }}>
              {file && (
                <li
                  key={1}
                  style={{
                    display: 'flex',
                    gap: '16px',
                    marginBottom: '8px',
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      width: '562px',
                      height: '48px',
                      backgroundColor: 'rgba(7, 170, 63, 0.16)',
                      borderRadius: '4px',
                      textDecoration: 'none',
                      margin: 0,
                      fontSize: '16px',
                      fontWeight: 500,
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px',
                    }}
                  >
                    {file.name}
                  </p>
                  <img
                    src={cancelIcon}
                    onClick={() => handleRemoveFile(file)}
                  />
                </li>
              )}
            </ul>
            <div
              className={`drag-and-drop ${dragging ? 'dragging' : ''}`}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              // onClick={() => document.getElementById('fileInput').click()} // added this line to click the file input element
              onChange={handleFileChange}
            >
              <input
                className="dnd-file-input"
                type="file"
                accept=".csv"
                id="fileInput"
              // onChange={handleFileChange}
              // multiple
              />
              <label className="DnD-label" htmlFor="fileInput">
                Drag and drop files here or click to select files
              </label>
            </div>
            <SampleFile />

            <div
              style={{
                marginTop: '30px',
                marginBottom: '10px',
              }}
            >
              <span
                style={{
                  marginTop: 10,
                  fontWeight: '600',
                }}
              >
                SMS TEMPLATES
              </span>
              <div style={{ display: 'flex' }}>
                <select
                  className="PopupInput"
                  style={{
                    padding: 8,
                  }}
                  id="smsTemplateId"
                  onChange={(e) => {
                    const selectedSmsTemplate = smsTemplatesList.filter((obj) => obj._id == e.target.value);
                    setSmsTemplateId(e.target.value);
                    console.log({ setSmsTemplateId: e.target.value })
                    if (selectedSmsTemplate.length > 0) {
                      var text = selectedSmsTemplate[0].message;
                      text = text.replaceAll('+', ' ');
                      text = text.replaceAll('%0D%0A', '\n');
                      text = text.replaceAll('%27', "'");
                      text = text.replaceAll('%2C', ',');
                      setSmsTemplateText(text);
                    }
                  }}
                  value={smsTemplateId}
                >
                  <option value="" selected>
                    Select
                  </option>
                  {smsTemplatesList && smsTemplatesList.map((item) => (
                    <option value={item._id}>{item.template_name}</option>
                  ))}
                </select>
                <div style={{ width: 500, overflow: 'scroll', marginLeft: 10 }}>
                  {smsTemplateText}
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: '40px',
                display: 'flex',
                gap: '16px',
                marginBottom: '50px',
              }}
            >
              <Button
                label={edit === 'true' ? 'EDIT CAMPAIGN' : 'SAVE CAMPAIGN'}
                style={{ backgroundColor: '#196cca', color: '#ffffff' }}
                onClick={() => setIsOpen(true)}
              />
              <Button
                onClick={cancelCampaignCreation}
                label="CANCEL"
                style={{ color: '#ed3833', borderColor: '#ed3833' }}
              />
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(
  connect(mapStateToProps, {})(CreateMessageBroadcast)
);

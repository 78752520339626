import React, { Component, useEffect, useState } from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../../assets/images/alohaaLogoAndBottomText.png';
import InfoRed from '../../assets/images/infoRed.png';
import Wall from '../../assets/images/loginPageWall.png';
import '../../assets/css/Login.css';
import '../../assets/css/LoginNew.css';
import Alert from '../../assets/images/alertBlackCircle.png';
import Shield from '../../assets/images/shieldYellow.png';
import Check from '../../assets/images/checkGreenSmall.png';
import { getTopBarData } from '../../actions/topBarActions';
import { GET } from '../../services/HttpRequests';
import { toast } from 'react-toastify';
import PreLoader from '../../common/component/preLoader/PreLoader';
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;

const KYCPending = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    if (location.pathname !== '/kyc-pending') {
      history.push('/kyc-pending');
    }
    setFetching(true);
    props.getTopBarData();
    GET(`${orgUrl}v1/organisation/basic/details`, {
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => {
        const a = res.data.response.organisation;
        if (a.is_kyc_completed) {
          setTimeout(function () {
            history.push('/call-logs');
            window.location.reload();
          }, 500);
        } else {
          setTimeout(function () {
            setFetching(false);
          }, 500);
        }
      })
      .catch((err) => {
        setFetching(false);
        toast.error('an error occured');
      });
  }, []);

  const list = [
    '1 virtual phone number',
    '1000 free minute balance',
    'Up to 20 agents seats',
    'Incoming and outgoing calling',
    'PSTN calling',
    'IVR & call routing',
    'Voice broadcast',
    'Webhook Integration',
    'Mobile app',
  ];

  if (fetching) {
    return <PreLoader />;
  }

  return (
    <div className="login-main" style={{ height: '100vh' }}>
      <div className="section-1" style={{ flex: 2 }}>
        <div className="login-container">
          <img
            className="login-container-logo"
            alt=""
            src={Logo}
            style={{ marginBottom: '52px' }}
          />
          <div className="AuthHeadContainer">
            <img src={Alert} style={{ marginBottom: '32px' }} />
            <p
              style={{
                fontSize: '22px',
                fontFamily: 'Inter',
                fontWeight: '500',
              }}
            >
              KYC pending
            </p>
            <p>
              Our team will contact you within 12 hours to complete your KYC
              verification.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          color: '#ffffff',
          fontFamily: 'Inter',
          backgroundColor: '#1a1a1a',
          flex: 3,
          paddingLeft: '100px',
          paddingTop: '52px',
        }}
      >
        <img src={Shield} style={{ marginBottom: '32px' }} />
        <p
          style={{
            fontSize: '1.8rem',
            fontWeight: '800',
            textAlign: 'left',
            marginBottom: '28px',
            color: '#fdde4b',
            width: '500px',
          }}
        >
          Complete your KYC to access Alohaa free for 30 days
        </p>
        <p
          style={{
            fontSize: '1.2rem',
            marginBottom: '24px',
            fontWeight: 500,
            width: '450px',
          }}
        >
          Get access to essential features and try out Alohaa for free. Unlock
          even more advanced features with our paid plan.
        </p>
        <ul style={{ padding: 0 }}>
          {list.map((item, index) => (
            <li
              style={{
                fontSize: '1rem',
                fontWeight: 500,
                listStyleType: 'none',
                marginBottom: '2px',
              }}
              key={index}
            >
              <img
                src={Check}
                style={{ paddingRight: '8px', height: '18px' }}
              />
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default connect(null, { getTopBarData })(KYCPending);

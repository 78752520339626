export const darkColorSchema = {
  colors: {
    secondaryColor1: '#000000',
    primaryColor1: '#F8153B',
    hoverColor: '#939598',
    whiteColor: '#ffffff',
    tabColor: "transparent",
    tabTextColor: "#939598",
    tabSelectedColor: "#287dc9",
    tabSelectedTextColor: "white",
    topBarBg: '#282828',
    topBarAccTextColor: '#ffffff',
    popupNotesTextColor: '#ffffff',
    columnHeaderBg: '#454545',
    sidebarBgColor: '#000000',
    sidebarHeaderBgColor: '#282828',
    searchBarBgcolor: '#282828',
    lightGreyBgColor: '#07aa3f',
    topbarTabsBgColor: '#282828',
    searchByColor: '#ffffff',
    contentContainerBorder: '#939598',
    inputBgColor: '#282828',
    disableInputBGColor: '#575757',
    tableBgColor: '#282828',
    tableLineColor: '#414141',
    tableContentColor: '#d2d3d4',
    tableHeaderColor: '#454545',
    tableBodyColor: '#282828',
    tableHeaderTextColor: '#ffffff',
    endisToggleBgColor: '#343434',
    endisToggleTextColor: '#d2d3d4',
    acccountsettingBgColor: '#282828',
    CallRoutingCardViewBgColor: '#333333',
    callRoutingDescritionColor: '#d2d3d4',
    callRoutingMenuDotBgColor: '#282828',
    callRoutingMenuBgColor: '#454545',
    callRoutingMunuItamBgColor: '#282828',
    callRoutingMenuItemTextColor: '#d2d3d4',
    popupColumnHeaderBgColor: '#505050',
    popupColumnHeaderTextColor: '#d2d3d4',
    popupInputLabel: '#939598',
    inputPlaceholderColor: '#d2d3d4',
    paginationBoxBgColor: '#282828',
    paginationBoxTextColor: '#d2d3d4',
    dateCalenderIconColor: 'dark',
    toggleHandler: '#333333',
    goodToKnowContainerBgColor: 'dark',
  }
}

export const lightColorSchema = {
  colors: {
    primaryColor: '#ed3833',
    primaryColor1: '#F8153B',
    secondaryColor: '#00515a',
    secondaryColor1: '#282828',
    secondaryColor2: '#d2d3d4',
    topBarBg: '#ffffff',
    tabColor: "#e8e8e8",
    tabTextColor: "#939598",
    tabSelectedColor: "#287dc9",
    tabSelectedTextColor: "white",
    topBarAccTextColor: '#000000',
    popupNotesTextColor: '#282828',
    columnHeaderBg: '#f1f2f2',
    sidebarBgColor: '#000000',
    sidebarHeaderBgColor: '#282828',
    searchBarBgcolor: '#ffffff',
    lightGreyBgColor: '#07aa3f',
    topbarTabsBgColor: '#d2d3d4',
    searchByColor: '#282828',
    contentContainerBorder: '#d1d3d4',
    inputBgColor: '#f7f7f7',
    disableInputBGColor: '#f7f7f7',
    tableBgColor: '#ffffff',
    tableLineColor: '#d1d3d4',
    tableContentColor: '#282828',
    tableHeaderColor: '#f1f2f2',
    tableBodyColor: 'white',
    tableHeaderTextColor: '#282828',
    endisToggleBgColor: '#e6e6e6',
    endisToggleTextColor: '#373a3c',
    acccountsettingBgColor: '#ffffff',
    CallRoutingCardViewBgColor: '#ffffff',
    callRoutingDescritionColor: '#939598',
    callRoutingMenuDotBgColor: '#f2f5f6',
    callRoutingMenuBgColor: '#ffffff',
    callRoutingMunuItamBgColor: '#f4f5f6',
    callRoutingMenuItemTextColor: '#777777',
    popupColumnHeaderBgColor: '#f1f2f2',
    popupColumnHeaderTextColor: '#000000',
    popupInputLabel: '#000000',
    inputPlaceholderColor: '#282828',
    paginationBoxBgColor: '#d1d3d4',
    paginationBoxTextColor: '#000000',
    dateCalenderIconColor: 'light',

    whiteColor: '#ffffff',
    lightGreenColor: '#4caf50',
    yellowishOrangeColor: '#f1bb37',
    lightDreyColor: '#f1f2f2',

    lighterGreyInputBgColor: '#f7f7f7',
    midGreyColor: '#d1d3d4',
    lightGreyInputBgColor: '#e3e3e3',
    blackColor: '#000000',
    redColor: '#ee3048',
    textGreenColor: '#07aa3f',
    blueColor: '#0a82ff',
    textGreyColor: '#6d6e71',
    hoverColor: '#939598',
    bluewishBlueColor: '#42a1e2',
    toggleHandler: '#d8d9d9',
    goodToKnowContainerBgColor: "#f7f7f7",
    lightGreyTagBgColor: '#fcfcfc'
  }
}

import React, { useState } from 'react';
import PlayIcon from '../../../assets/images/playIconGreen.svg';
import { GET, POST } from '../../../services/HttpRequests';
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;

export default function AudioPlayer(props) {
  const [audioUrl, setAudioUrl] = useState('');
  const [isURLFetched, setIsURLFetched] = useState(false);

  const fetchUrl = () => {

    setAudioUrl(props.callRecordingUrl || '');

    // block any other active audio player
    window.addEventListener(
      'play',
      function (evt) {
        if (
          window.$_currentlyPlaying &&
          window.$_currentlyPlaying != evt.target
        ) {
          window.$_currentlyPlaying.pause();
        }
        window.$_currentlyPlaying = evt.target;
      },
      true
    );

    setIsURLFetched(true);
  };
  console.log(props.callRecordingUrl);
  if (!props.callRecordingUrl) return '-';
  if (isURLFetched === false) {
    return (
      <React.Fragment>
        <img
          src={PlayIcon}
          onClick={fetchUrl}
          style={{ margin: '3px 0 3px 14px', ...(props.style || {}) }}
        />
      </React.Fragment>
    );
  } else {
    if (setAudioUrl === '') return 'No audio';
    return (
      <span>
        <audio
          style={{
            height: '24px',
            marginTop: '3px',
            marginBottom: '-2px',
          }}
          controls
          autoPlay
          // ref="audio_tag"
          src={audioUrl ? audioUrl : ''}
        />
      </span>
    );
  }
}

import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { GET } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import Success from '../../../assets/images/greenSuccessBig.png';
import { LockCloseIcon } from '../../../assets/Icons/Icons.js';

const planUrl = process.env.REACT_APP_PLAN_API;

const overlayStyle = { background: 'rgba(0,0,0,0.9)' };
const contentStyle = {
  width: '556px',
  padding: '80px 82px',
  borderRadius: '8px',
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
};
const WelcomeText = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.82;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #f07038;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const SubHead = styled.p`
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin: 0;
  margin-bottom: 40px;
`;

const Text = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin: 0;
`;

const ProceedButton = styled.button`
  width: 336px;
  height: 48px;
  border: none;
  border-radius: 4px;
  background-color: #07aa3f;
  font-size: 15px;
  font-weight: bold;
  line-height: 3.4;
  letter-spacing: 0.75px;
  color: #fff;
  margin: 0;
  margin-bottom: 24px;
`;

export default function AccountLocked() {
  return (
    <Popup
      open={true}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      {...{ overlayStyle, contentStyle }}
    >
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <LockCloseIcon />
        <WelcomeText>Your 7 day free trial is over</WelcomeText>
        <SubHead>Access to Alohaa has expired</SubHead>
        <Text>This does not have to be the end, to continue</Text>
        <Text style={{ marginBottom: '24px' }}>
          accessing all features, please select a plan.
        </Text>
        <ProceedButton style={{ backgroundColor: '#000' }}>
          SELECT A PLAN
        </ProceedButton>
      </div>
    </Popup>
  );
}

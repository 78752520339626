/* eslint-disable */
import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { POST, GET } from '../../../services/HttpRequests';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import downloadIcon from '../../../assets/images/DownloadButton.svg';
import '../../../assets/css/SearchBar.css';
import './styles.css';
import { isSuperAdminWithPermission } from '../../../utility/utility';
const userUrl = process.env.REACT_APP_USER_API;
const URL = process.env.REACT_APP_SAMPLE_FILE_URL;

export default function AddLeadListPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [leadListName, setLeadListName] = useState('');
  const [leadListFile, setLeadListFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [users, setUsers] = useState([]);
  const [leadListUser, setLeadListUser] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(null);
  const userRole = localStorage.getItem('doosra-biz-user-role');
  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setLeadListFile(file);
    setFileName(file?.name);
  };
  function handleCheckboxChange(event) {
    setIsChecked(event.target.checked);
  }

  function reset() {
    setLeadListName('');
    setLeadListFile('');
    setFileName('');
    setIsChecked(false);
  }
  const setUserForLeadList = (event) => {
    event.preventDefault();
    const user = event.target.value;
    setLeadListUser(user);
  };
  const fetchUserData = () => {
    let data = {
      pageNo: 1,
      pageSize: 100,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    GET(userUrl + 'v1/user', data)
      .then((res) => {
        return res.data.response.users.docs;
      })
      .then((res) => {
        setUsers(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addLeadListHandler = () => {
    if (!leadListName) {
      toast.error('File Name is required');
      return;
    }
    if (!leadListFile) {
      toast.error('File is required');
      return;
    }
    if (!leadListUser) {
      toast.error('Please select a user');
      return;
    }
    props
      .addLeadListHandler({
        leadListName: leadListName,
        leadList: leadListFile,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        userId: leadListUser
          ? leadListUser
          : localStorage.getItem('doosra-biz-user-id'),
      })
      .then((res) => {
        if (res === true) {
          setLeadListName('');
          setLeadListFile(null);
          setFileName('');
          setPopupOpen(false);
        }
      })
      .catch((err) => console.log('err'));
  };

  const addBulkLeadListHandler = () => {
    if (!leadListName) {
      toast.error('File Name is required');
      return;
    }
    if (!leadListFile) {
      toast.error('File is required');
      return;
    }
    props
      .addBulkLeadListHandler({
        leadListName: leadListName,
        leadList: leadListFile,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      })
      .then((res) => {
        if (res === true) {
          setLeadListName('');
          setLeadListFile(null);
          setFileName('');
          setPopupOpen(false);
        }
      })
      .catch((err) => console.log('err'));
  };

  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="PopupContainer" style={{ overflow: 'auto' }}>
        <div className="PopupHeaderContainer">
          <p>Upload Lead List Info</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => {
              setPopupOpen(false);
              reset();
            }}
          />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Lead List Name</label>
            <input
              className="PopupInput"
              id="lead_id"
              value={leadListName}
              onChange={(e) => setLeadListName(e.target.value)}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Upload Lead List</label>
            <div className="PopupInputDotted">
              <label className="uploadRecoveryLeadFile">
                {'UPLOAD FILE'}
                <input
                  type="file"
                  accept=".csv"
                  id="lead_file_id"
                  onChange={handleFileChange}
                  style={{ opacity: 0 }}
                />
              </label>
            </div>
          </div>
          {fileName ? (
            <div
              className="PopupInputContainer"
              style={{ textAlign: 'center' }}
            >
              <label>{fileName}</label>
            </div>
          ) : null}
          <div className="PopupInputContainer" style={{ textAlign: 'center' }}>
            <CsvDownloader
              style={{
                cursor: 'pointer',
                color: '#196cca',
                textDecoration: 'none',
              }}
              filename="LeadList"
              // text="Sample File"
              datas={
                isChecked
                  ? [
                      {
                        name: 'lead-name',
                        number: '9346090366',
                        secondaryNumber: '9032586386',
                        // email: 'lead-email@sample.com',
                        // due: '25000',
                        // date: "2023-12-31",
                        agent: 'sample-agent@sample.com',
                      },
                    ]
                  : [
                      {
                        name: 'lead-name',
                        number: '9346090366',
                        secondaryNumber: '9032586386',
                        // email: 'lead-email@sample.com',
                        // due: '25000',
                        // date: "2023-12-31",
                        agent: 'sample-agent@sample.com',
                      },
                    ]
              }
            >
              <a
                style={{
                  paddingRight: 18,
                  cursor: 'pointer',
                  color: '#196cca',
                  textDecoration: 'none',
                }}
              >
                Download Sample Sheet{' '}
                <img
                  src={downloadIcon}
                  style={{ margin: '3px 0 3px 8px' }}
                ></img>
              </a>
            </CsvDownloader>
          </div>
          <div className="Rectangle-9826">
            <p className="Download-the-sample-sheet">
              Download the sample sheet and fill it in the same format without
              changing rows or columns
            </p>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                style={{ marginLeft: '55px' }}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              Automatically Distribute the leads
            </label>
          </div>
          {userRole === 'ADMIN' && !isChecked && (
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Assign An Agent</label>
              <select
                className="PopupInput"
                id="users"
                onChange={setUserForLeadList}
              >
                <option value={'-'}>{'Select from dropdown'}</option>
                {users.length > 0 &&
                  users.map((user) => (
                    <option value={user.user_id}>{user.zvr_name}</option>
                  ))}
              </select>
            </div>
          )}

          <div className="ErrorContainer ErrorContainerMargin">
            {error ? (
              <React.Fragment>
                <img
                  alt=""
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
                {error}
              </React.Fragment>
            ) : null}
          </div>
          {popupOpen === true ? (
            <button
              className="ButtonFullWidth"
              style={{ marginTop: '10px' }}
              type="submit"
              onClick={isChecked ? addBulkLeadListHandler : addLeadListHandler}
            >
              SAVE
            </button>
          ) : (
            <button
              className="ButtonFullWidth"
              style={{ cursor: 'default', marginTop: '10px' }}
            >
              SAVE
            </button>
          )}
          <button
            className="ButtonCancelFullWidth"
            onClick={() => {
              setPopupOpen(false);
              reset();
            }}
          >
            CANCEL
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex' }}>
      <span>
        <button
          className="Otherbutton"
          disabled={!isSuperAdminWithPermission('ADD')}
          style={{ marginRight: 0 }}
          onClick={() => {
            setPopupOpen(true);
            fetchUserData();
          }}
        >
          ADD LEAD LIST
        </button>
        {popper}
      </span>
    </div>
  );
}

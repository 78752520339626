export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const toggleSidebar = () => dispatch => {
  dispatch({
    type: TOGGLE_SIDEBAR
  })
}

export const INIT_CALL = "INIT_CALL";

export const initCall = (data) => dispatch => {
  dispatch({
    type: INIT_CALL,
    payload: data
  })
}

export const SET_SMS_ACCESS_KEY = "SET_SMS_ACCESS_KEY"

export const setSMSAccessKey = (data) => dispatch => {
  dispatch({
    type: SET_SMS_ACCESS_KEY,
    payload: data
  })
}

export const SET_CALL_ACCESS_KEY = "SET_CALL_ACCESS_KEY"

export const setCallAccessKey = (data) => dispatch => {
  dispatch({
    type: SET_CALL_ACCESS_KEY,
    payload: data
  })
}

export const SET_VOICE_API_ACCESS_KEY = "SET_VOICE_API_ACCESS_KEY"
export const SET_NUMBER_MASKING_ACCESS_KEY = "SET_NUMBER_MASKING_ACCESS_KEY"
export const SET_NUMBER_MASKING_CALL_DETAILS_ACCESS_KEY = "SET_NUMBER_MASKING_CALL_DETAILS_ACCESS_KEY"

export const setVoiceAccessKey = (data) => dispatch => {
  dispatch({
    type: SET_VOICE_API_ACCESS_KEY,
    payload: data
  })
}

export const setNumberMaskingAccessKey = (data) => dispatch => {
  dispatch({
    type: SET_NUMBER_MASKING_ACCESS_KEY,
    payload: data
  })
}

export const setNumberMaskingCallDetailsAccessKey = (data) => dispatch => {
  dispatch({
    type: SET_NUMBER_MASKING_CALL_DETAILS_ACCESS_KEY,
    payload: data
  })
}
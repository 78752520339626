import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { POST } from '../../../services/HttpRequests';
import Delete from '../../../assets/images/deleteWRedBackground.png';

const ContactInput = (props) => {
  return (
    <div key={props.index} style={{ height: '62px' }}>
      <input
        className="PopupInput"
        id="emp_id"
        value={props.number}
        onChange={(e) => props.updateNumber(e.target.value)}
      />
    </div>
  );
};

export default function AddVerifiedContactPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [contactName, setContactName] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [contactNumber, setContactNumber] = useState('');
  const [otp, setOTP] = useState('');
  const [secret, setSecret] = useState('');
  const [error, setError] = useState(null);

  const sendOtpVerifiedContact = async () => {
    await props
      .sendOtpVerifiedContact({
        name: contactName,
        phoneNumber: contactNumber,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        orgId: localStorage.getItem('doosra-biz-organisation-id'),
      })
      .then((res) => {
        if (res?.data?.response?.success) {
          setOtpSent(true);
          setSecret(res.data.response.otpObj.secretKey);
        }
      })
      .catch((err) => console.log('err'));
  };

  const verifyOtpVerifiedContact = async () => {
    console.log('line 53' + secret);

    await props
      .verifyOtpVerifiedContact({
        name: contactName,
        phoneNumber: contactNumber,
        token: otp,
        secret,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        orgId: localStorage.getItem('doosra-biz-organisation-id'),
      })
      .then((res) => {
        console.log(res);
        if (res?.data?.response?.success) {
          setContactName('');
          setContactNumber('');
          setOtpSent(false);
          setPopupOpen(false);
        }
      })
      .catch((err) => console.log('err'));
  };

  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="PopupContainer">
        <div className="PopupHeaderContainer">
          <p>Add Contact Profile</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => setPopupOpen(false)}
          />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Contact Name</label>
            <input
              className="PopupInput"
              id="emp_id"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
            />
          </div>
          <div
            className="PopupInputContainer"
            style={{ height: '180px', overflow: 'auto' }}
          >
            <label className="PopupInputLabel">Contact Number</label>
            <input
              className="PopupInput"
              id="emp_id"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
            />
          </div>
          {otpSent ? (
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">OTP</label>
              <input
                className="PopupInput"
                id="emp_id"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
              />
            </div>
          ) : null}
          <div className="ErrorContainer ErrorContainerMargin">
            {error ? (
              <React.Fragment>
                <img
                  alt=""
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
                {error}
              </React.Fragment>
            ) : null}
          </div>
          {otpSent === false ? (
            <button
              className="ButtonFullWidth"
              style={{ marginTop: '10px', color: '#ed3833' }}
              type="submit"
              onClick={sendOtpVerifiedContact}
            >
              SEND OTP
            </button>
          ) : (
            <button
              className="ButtonFullWidth"
              style={{ cursor: 'default', marginTop: '10px', color: '#ed3833' }}
              onClick={verifyOtpVerifiedContact}
            >
              VERIFY OTP
            </button>
          )}
          <button
            className="ButtonCancelFullWidth"
            onClick={() => setPopupOpen(false)}
          >
            CANCEL
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex' }}>
      <span>
        <button
          className="Otherbutton"
          style={{ marginRight: 0 }}
          onClick={() => setPopupOpen(true)}
        >
          ADD CONTACT
        </button>
        {popper}
      </span>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import downArrow from '../../../assets/images/expand_circle_down.svg';
import {
  setAgentStatus,
  setIsAgentStatusUpdated,
} from '../../../actions/dailPadActions';
import { setIsAgentMarkedAsUnAvailableByAdmin } from '../../../actions/recoveryXActions';
import { commonStyle } from '../../../assets/css/css';
import { connect, useSelector } from 'react-redux';
import { useTheme } from '../../../providers/customeThemeProvider';
import { toast } from 'react-toastify';
import { PUT } from '../../../services/HttpRequests';
import { logPostHogEvent } from '../../posthog/posthog';
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;
const cloudCallUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

const AgentStatusDropDown = (props) => {
  const userRole = localStorage.getItem('doosra-biz-user-role');
  const selectedStatus = useSelector((state) => state.dailpad.agentStatus);
  const isCallInProgress = useSelector(
    (state) => state.dailpad.isCallInProgress
  );
  const isAgentStatusUpdated = useSelector(
    (state) => state.dailpad.isAgentStatusUpdated
  );
  const isCallForwardingAllowed = useSelector(
    (state) => state.topbar.isCallForwardingAllowed
  );
  const isCampaignInProgress = useSelector(
    (state) => state.dailpad.isCampaignInProgress
  );
  const [status, setStatus] = useState(selectedStatus);
  const { themeType } = useTheme();
  const handleSelect = (eventKey, event) => {
    if (isCallInProgress) {
      event.preventDefault();
      toast.error('There is a active call');
      return;
    }
    if (isCampaignInProgress) {
      event.preventDefault();
      toast.error('There is a active campaign');
      return;
    }
    setStatus(eventKey);
    props.setAgentStatus({ agentStatus: eventKey });
    localStorage.setItem('agentStatus', eventKey);
    console.log('The event key is', eventKey);
    if (userRole === 'USER') {
      if (eventKey === 'Online') {
        props.setIsAgentMarkedAsUnAvailableByAdmin({
          isAgentMarkedAsUnAvailableByAdmin: false,
        });
        callForwardingStateHandler({ is_call_forwarding: true });
      } else {
        callForwardingStateHandler({ is_call_forwarding: false });
      }
    }
    handleAgentStatus(eventKey);
  };

  useEffect(() => {
    if (selectedStatus === 'Online' && !isAgentStatusUpdated) {
      if (localStorage.getItem('doosra-biz-user-role') === 'USER') {
        callForwardingStateHandler({ is_call_forwarding: true });
        props.setIsAgentMarkedAsUnAvailableByAdmin({
          isAgentMarkedAsUnAvailableByAdmin: false,
        });
      }
      handleAgentStatus('Online');
      props.setIsAgentStatusUpdated({ isAgentStatusUpdated: true });
    }
  }, []);

  const handleAgentStatus = async (eventKey) => {
    const breakReasonMap = {
      Break: 'break',
      Online: 'active',
      Busy: 'busy',
    };
    let breakReason = breakReasonMap[eventKey] || 'offline';
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      agentId: localStorage.getItem('doosra-biz-user-id'),
      breakSwitch: '',
      breakReason,
    };
    if (eventKey === 'Online') {
      data.breakSwitch = 'off';
    } else {
      data.breakSwitch = 'on';
    }
    console.log(data);
    await PUT(cloudCallUrl + 'metric/update-agent-break-time', {}, data)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  };

  const callForwardingStateHandler = (data) => {
    if (isCallForwardingAllowed) {
      logPostHogEvent('Availability_Toggle');
      data.user_id = localStorage.getItem('doosra-biz-user-id');
      data.organisation_id = localStorage.getItem('doosra-biz-organisation-id');
      PUT(`${incomingCallsUrl}v1/organisations/call-forwarding-state`, data)
        .then((res) => {
          return res?.data?.response;
        })
        .then((res) => {
          console.log('res', res);
          this.setState({
            callForwarding: res?.doc?.organisation?.is_call_forwarding,
          });
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    setStatus(selectedStatus);
  }, [selectedStatus]);
  return (
    <div className="status-dropdown">
      <Dropdown onSelect={handleSelect} defaultValue={selectedStatus}>
        <Dropdown.Toggle
          variant="secondary"
          id="dropdown-basic"
          style={{
            background: themeType === 'light' ? 'white' : '#282828',
            color: themeType === 'light' ? 'black' : 'white',
          }}
        >
          <span className={`status-indicator ${status.toLowerCase()}`}></span>{' '}
          {status}
          <img style={{ paddingLeft: '30px' }} src={downArrow}></img>
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{ background: themeType === 'light' ? 'white' : '#282828' }}
        >
          <Dropdown.Item
            eventKey="Online"
            style={{ color: themeType === 'light' ? 'black' : 'white' }}
          >
            <span className="status-indicator online"></span> Online
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="Busy"
            disabled
            style={{ color: themeType === 'light' ? 'grey' : 'grey' }}
          >
            <span className="status-indicator busy"></span> Busy
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="Break"
            style={{ color: themeType === 'light' ? 'black' : 'white' }}
          >
            <span className="status-indicator break"></span> Break
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="Offline"
            style={{ color: themeType === 'light' ? 'black' : 'white' }}
          >
            <span className="status-indicator offline"></span> Offline
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default commonStyle(
  connect(mapStateToProps, {
    setAgentStatus,
    setIsAgentStatusUpdated,
    setIsAgentMarkedAsUnAvailableByAdmin,
  })(AgentStatusDropDown)
);

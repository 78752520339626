import styled from "@emotion/styled";


const DataTable = {};

DataTable.Table = styled.table`
    background: ${props => props.theme.colors.tableBodyColor};
margin-bottom: 48px;
`;
DataTable.Head = styled.thead`
    height: 48px;
    background: ${props => props.theme.colors.tableHeaderColor};
`;
DataTable.Body = styled.tbody``;
DataTable.Row = styled.tr`
    min-height: 30px;
    border-bottom: 1px solid ${props => props.theme.colors.tableLineColor};
`;

DataTable.CellHead = styled.th`
    padding: 8px;
    font-size: 12px;
    font-weight: 600;
    color: ${props => props.theme.colors.tableHeaderTextColor};
`;

DataTable.Cell = styled.td`
    padding: 8px;
    font-size: 13px;
    font-weight: 500;
    word-break: break-word;
    align-self: center;
    color: ${props => props.theme.colors.tableContentColor};
`;

DataTable.CellMissedCall = styled(DataTable.Cell)`
    color: #EC3833;
`;

DataTable.CellAnsweredCall = styled(DataTable.Cell)`
    color: #04A93F;
`;

export default DataTable;
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import DataCard from '../component/DataCard';
import DataTable from '../../../common/component/table/DataTable';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import { GET } from '../../../services/HttpRequests';
import Online from '../../../assets/images/smallCircleGreen.png';
import Offline from '../../../assets/images/smallCircleYellow.png';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import ExportAgentCampaignCalls from '../component/ExportAgentCampaignCalls';
import PreLoader from '../../../common/component/preLoader/PreLoader';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;

function AgentsList(props) {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  return (
    <DataTable.Table>
      <DataTable.Head>
        <DataTable.CellHead width="20">#</DataTable.CellHead>
        <DataTable.CellHead width="100">CAMPAIGNS</DataTable.CellHead>
        <DataTable.CellHead width="100">CAMPAIGN TYPE</DataTable.CellHead>
        <DataTable.CellHead width="40">CALLS</DataTable.CellHead>
        <DataTable.CellHead width="210">CONNECTED CALLS</DataTable.CellHead>
      </DataTable.Head>
      <DataTable.Body>
        {props?.campaignsData?.length > 0 &&
          props?.campaignsData?.map((campaign, index) => (
            <DataTable.Row id={campaign.campaignName}>
              <DataTable.Cell>{props?.currentPage + index}</DataTable.Cell>
              <DataTable.Cell
                style={{ cursor: 'pointer', textDecoration: "underline" }}
                onClick={() => {
                  history.push(`/recovery-x-campaign-view?agentId=${id}&campaignId=${campaign.campaignId}`);
                }}
              >{campaign?.campaignName}</DataTable.Cell>
              <DataTable.Cell>{campaign?.campaignType}</DataTable.Cell>
              <DataTable.Cell>{campaign?.campaignTotalCalls}</DataTable.Cell>
              <DataTable.Cell>{campaign?.agentTotalCampaignAnsweredCalls}</DataTable.Cell>
            </DataTable.Row>
          ))}
      </DataTable.Body>
    </DataTable.Table>
  );
}

export function RecoveryXAgentDetails(props) {
  const [agentsData, setAgentsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  const fetchAgentsListHandler = (agentId) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      agentId,
    };
    setLoading(true);
    GET(
      outGoingUrl + 'v1/organisations/outbound-call/get-agent-campaign-details',
      data
    )
      .then((res) => {
        setAgentsData(res?.data?.response);
        setLoading(false);
      })
      .catch((err) => { console.log(err); setLoading(false); });
  };

  useEffect(() => {
    fetchAgentsListHandler(id);
  }, [id]);

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        {loading && <PreLoader />}
        {!loading && <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/recovery-x-agents')}
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {agentsData.agentName}
              </p>
              <ExportAgentCampaignCalls
                agentsData={agentsData}
              />
            </div>
          </TabSearchBar>
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            <DataCard
              title="Calls made today"
              count={agentsData?.todayAgentCalls}
            />
            <DataCard
              title="Calls made this month"
              count={agentsData?.monthlyAgentCalls}
            />
            {/* <DataCard
              title="Number of calls not connected"
              count={
                agentsData?.monthlyAgentCalls -
                agentsData?.monthlyAgentAnsweredCalls
              }
            />
            <DataCard
              title="Call duration under 7 seconds"
              count={agentsData?.agnetCallsUnderSevenSeconds}
            /> */}
          </div>
          <AgentsList
            campaignsData={agentsData?.campaignsData}
            currentPage={currentPage}
          />
        </div>}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(RecoveryXAgentDetails));

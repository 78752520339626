import React, { useState } from 'react';
import './SideModal.css';
import RecoveryHangup from "../../../assets/images/RecoveryHangup.webp";
import IconFeatherLoader from "../../../assets/images/IconFeatherLoader.webp";
import Switch from "./Switch"

const SideModal = (props) => {

  const [value, setValue] = useState(true);
  return (
    props.isOpen && (
       
        <div className='Rectangle-9993'>
          <div>
            
            <span className='Gautam-Adani'>{props.contact}{props.number}</span>
            <br></br>
            <img src={IconFeatherLoader} alt={"Loader Icon"}/><span className='Connecting'>{props.status}</span>
            <br></br>
            <span className='Call-23500'>{props.progress}</span>
            <br></br>
            </div>
            <div className="Rectangle-9991" onClick = {() => props.hangupCall()}>
              <span><img className='Icon-material-call-end' src={RecoveryHangup} alt={"Hangup"}></img></span>
            </div>
            <div className="Rectangle-9992" style={{alignItems:"center"}}>
              <span className='Auto-calling'>Auto Calling</span>
              <div>
              <Switch
                isOn={props.value}
                handleToggle={() => props.batchCallHadler()}
              />
              </div>
            </div>
            
            
            
        </div>
        
       
    )
  );
};

export default SideModal;

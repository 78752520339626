import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';

import '../../../assets/css/Content.css';
import { GET } from '../../../services/HttpRequests';
import AudioPlayer from '../../collLogs/components/AudioPlayer';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import UpgradeServices from '../../../common/component/upgrade-services/UpgradeServices';

const numberMaskingUrl = process.env.REACT_APP_NUMBER_MASKING_API;

class NumberMaskingCallLogsPage extends Component {
  constructor() {
    super();
    this.state = {
      totalPages: 0,
      currentPage: 1,
      pageSize: 15,
      bindingsCount: 0,
      callsCount: 0,
      didsCount: 0,
      callList: [],
      loading: false,
      searchData: {},
    };
  }

  componentDidMount() {
    this.getCallsList();
    this.getBindingsCount();
    this.getDidsCount();
  }

  getCallsList = async () => {
    this.setState({ loading: true });
    let data = {
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    };

    try {
      const res = await this.getCallData(numberMaskingUrl, data);
      this.updateCallListState(res);
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  getBindingsCount = () => {
    GET(`${numberMaskingUrl}v1/bindings`, {
      pageNo: 0,
      pageSize: 1,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ bindingsCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  getDidsCount = () => {
    GET(`${numberMaskingUrl}v1/did-list`, {
      pageNo: 0,
      pageSize: 1,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ didsCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  seachHandler = async (data) => {
    data.pageNo = 1;
    data.pageSize = this.state.pageSize;
    data.organisation_id = localStorage.getItem('doosra-biz-organisation-id');

    try {
      const res = await this.getCallData(numberMaskingUrl, data);
      this.updateCallListState(res, data);
    } catch (err) {
      if (err) {
        this.setState({ loading: false });
      }
    }
  };

  fetchPageData = async (value) => {
    let data = {
      pageNo: value,
      pageSize: this.state.pageSize,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    };
    data = {
      ...this.state.searchData,
      ...data,
    };

    try {
      const res = await this.getCallData(numberMaskingUrl, data);
      this.updateCallListState(res, data);
      this.setState({ currentPage: value });
    } catch (err) {
      console.log(err);
    }
  };

  getCallData = async (url, data) => {
    const response = await GET(`${url}v1/calls`, data);
    return response.data.response;
  };

  updateCallListState = (res, searchData = {}) => {
    let count = Math.ceil(res.payload.count / this.state.pageSize);
    if (count === 0) count = 1;

    this.setState({
      callsCount: res.payload.count,
      callList: res.payload.docs,
      totalPages: count,
      pageSize: res.pageSize,
      loading: false,
      searchData,
    });
  };

  render() {
    const { callList, count, loading, currentPage, totalPages } = this.state;
    const userRole = localStorage.getItem('doosra-biz-user-role');
    const { callForwardingStatus, isNumberMaskingEnabled, isFreeFormEnabled } = this.props;

    const tabs = this.createTabs(userRole, callForwardingStatus, isFreeFormEnabled);

    if (!isNumberMaskingEnabled) {
      return (
        <div className={`${this.props.className}`}>
          <Sidebar />
          <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
            <Topbar />
            <div className="ContentContainer">
              <UpgradeServices
                icon="numberMasking"
                heading="Number Masking"
                text="Receive and read SMSes right from your Alohaa dashboard"
                emailSent={this.props.isNumberMaskingEmailSent}
              />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={`${this.props.className}`}>
        <Sidebar />
        <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />
          <div className="ContentContainer">
            <TabSearchBar tabs={tabs}>
              <SearchBar
                type='number-masking-calls'
                disableTitle
                count={count}
                reset={this.getCallsList}
                search={this.seachHandler}
                isFreeFormEnabled={isFreeFormEnabled}
              />
            </TabSearchBar>
            <div style={{ overflowX: "auto" }}>
              <DataTable.Table style={{ width: "100%" }}>
                {loading && <PreLoader />}
                <DataTable.Head>{this.renderTableHeaders()}</DataTable.Head>
                <DataTable.Body>
                  {this.renderTableRows(callList, userRole)}
                </DataTable.Body>
              </DataTable.Table>
            </div>

            <Paginator
              currentPage={currentPage}
              lastPage={totalPages}
              getInfo={(value) => this.fetchPageData(value)}
            />
          </div>
        </div>
      </div>
    );
  }

  createTabs(userRole, callForwardingStatus, isFreeFormEnabled) {
    const tabs = [];
    console.log("the bindings count is", this.state.bindingsCount);
    if (!isFreeFormEnabled) {
      tabs.push({
        title: 'Bindings',
        subtitle: `${this.state.bindingsCount}`,
        onClick: () => this.props.history.push('/number-masking-bindings'),
      });
    }
    tabs.push({
      title: 'Calls',
      subtitle: `${this.state.callsCount}`,
      active: true,
    });
    tabs.push({
      title: 'DID',
      subtitle: `${this.state.didsCount}`,
      onClick: () => this.props.history.push('/number-masking-did'),
    });
    return tabs;
  }

  renderTableHeaders() {
    return (
      <>
        <DataTable.CellHead width="70">CALLER NUMBER</DataTable.CellHead>
        <DataTable.CellHead width="70">RECEIVER NUMBER</DataTable.CellHead>
        <DataTable.CellHead width="70">MASKED NUMBER</DataTable.CellHead>
        <DataTable.CellHead width="70">CALL STATUS</DataTable.CellHead>
        <DataTable.CellHead width="70">DURATION</DataTable.CellHead>
        <DataTable.CellHead width="70">CALL RECORD</DataTable.CellHead>
        <DataTable.CellHead width="50">TIME</DataTable.CellHead>
        <DataTable.CellHead width="70">DATE</DataTable.CellHead>
      </>
    );
  }

  renderTableRows(callList, userRole) {
    return (callList || []).map((item) => (
      <DataTable.Row>
        <DataTable.Cell>
          {item.caller_primary_number}
        </DataTable.Cell>
        <DataTable.Cell>
          {item.receiver_primary_number}
        </DataTable.Cell>
        <DataTable.Cell>
          {`0${item.did.slice(-10)}`}
        </DataTable.Cell>
        <DataTable.Cell>{item.event === 'CallConnected' ? 'Answered' : 'Missed'}</DataTable.Cell>
        <DataTable.Cell>
          {
            item.billing_sec === 0
              ? '-'
              : moment('1900-01-01 00:00:00')
                .add(item.billing_sec, 'seconds')
                .format('HH:mm:ss')
          }
        </DataTable.Cell>
        <DataTable.Cell>
          <AudioPlayer
            callRecordingUrl={item?.recording_s3_url}
            callId={item?._id}
            callType={"NumberMasking"}
          />
        </DataTable.Cell>
        <DataTable.Cell>
          {moment(item.created_at).format('HH:mm:ss')}
        </DataTable.Cell>
        <DataTable.Cell>
          {' '}
          {moment(item.created_at).format('DD-MM-YYYY')}
        </DataTable.Cell>
      </DataTable.Row>
    ));
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isNumberMaskingEnabled: state.topbar.isNumberMaskingEnabled,
  isNumberMaskingEmailSent: state.topbar.isNumberMaskingEmailSent,
  isFreeFormEnabled: state.topbar.isFreeFormEnabled,
});

export default commonStyle(
  connect(mapStateToProps, {})(NumberMaskingCallLogsPage)
);

import React, { useEffect, useState } from 'react';
import Popup from '../../../../common/component/popups/popups';
import DataTable from '../../../../common/component/table/DataTable';
import { GET } from '../../../../services/HttpRequests';
import PreLoader from '../../../../common/component/preLoader/PreLoader';
import moment from 'moment';
import Close from '../../../../assets/images/popupClose.png';

const messageUrl = process.env.REACT_APP_MESSAGE_API;

export default function WhiteListSenderIdsPopup() {
  const [list, seList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const [searchCondition, setSearchCondition] = useState('');
  const [searchKey, setSearchKey] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [btnOnReset, setBtnOnReset] = useState(false);

  useEffect(() => {
    console.log('useEffect-', btnOnReset);
    fetchSenderIdsList(!btnOnReset);
  }, [btnOnReset]);

  const fetchSenderIdsList = (reset) => {
    setLoading(true);
    let data = {
      pageNo: currentPage,
      pageSize: pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };

    if (searchCondition && searchKey) {
      setCurrentPage(1);
      data.pageNo = 1;
      data.searchKey = searchKey;
      data.searchCondition = searchCondition;
    }

    if (reset) {
      setSearchKey('');
      setSearchCondition('');
      setCurrentPage(1);
      data = {};
      data.pageNo = 1;
      data.pageSize = pageSize;
      data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    }
    data = { ...data, portal: 'business' };
    GET(messageUrl + `v1/whitelistsenderids/list`, data)
      .then((res) => {
        console.log('fetchSenderIdsList-', { res });
        return res.data.response;
      })
      .then((res) => {
        seList(res.list);
        setPageSize(res.pageSize);
        setCurrentPage(parseInt(data.pageNo));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        seList([]);
      });
  };

  let displayData;
  if (loading) displayData = <PreLoader />;

  if (list && list.length > 0) {
    console.log({ list });
    displayData = list.map((row) => (
      <DataTable.Row key={row._id}>
        <DataTable.Cell>
          {moment(row.createdAt).format('DD/MM/YYYY')}
        </DataTable.Cell>
        <DataTable.Cell>{row.sender_id}</DataTable.Cell>
        <DataTable.Cell>{row.desc}</DataTable.Cell>
      </DataTable.Row>
    ));
  } else {
    displayData = (
      <DataTable.Row>
        <DataTable.Cell colSpan={4} style={{ textAlign: 'center' }}>
          {' '}
          {'No Data Found'}{' '}
        </DataTable.Cell>{' '}
      </DataTable.Row>
    );
  }

  const openPopup = () => {
    setIsPopupOpen(true);
    fetchSenderIdsList(true);
  };

  return (
    <div>
      <button className="Otherbutton" onClick={openPopup}>
        SENDER IDs
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupHeaderContainer" style={{ width: '800px' }}>
          <p>WHITE LIST SENDER IDs</p>
          <img
            style={{ left: '93%' }}
            className="PopupClose"
            src={Close}
            onClick={() => {
              setIsPopupOpen(false);
            }}
          />
        </div>
        <div className="PopupBody" style={{ paddingBottom: '50px' }}>
          <div className="ContentContainer">
            <div
              style={{
                display: 'flex',
                paddingBottom: '15px',
                justifyContent: 'center',
              }}
            >
              <div
                className="PopupInputContainer"
                style={{ paddingTop: '7px' }}
              >
                <label
                  className="PopupInputLabel"
                  style={{ marginRight: '7px' }}
                >
                  SEARCH BY
                </label>
                <select
                  className="PopupInput"
                  id="searchBy"
                  style={{
                    width: '150px',
                    height: '45px',
                    padding: '5px 5px 5px 5px',
                  }}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  disabled={btnOnReset}
                  value={searchKey}
                >
                  <option>Select</option>
                  <option value="sender_id">Sender Id</option>
                </select>
              </div>
              <div className="PopupInputContainer">
                <input
                  id="searchCondition"
                  className="InputBox"
                  placeholder="Search..."
                  disabled={btnOnReset}
                  value={searchCondition}
                  onChange={(e) => setSearchCondition(e.target.value)}
                />
              </div>

              <div className="PopupInputContainer">
                {btnOnReset ? (
                  <button
                    className="SearchButton"
                    style={{
                      border: 'none',
                      width: '150px',
                      height: '40px',
                      fontSize: '14px',
                    }}
                    onClick={() => {
                      setBtnOnReset(false);
                    }}
                  >
                    CLEAR RESULTS
                  </button>
                ) : (
                  <button
                    className="SearchButton"
                    style={{ width: '100px', height: '40px', fontSize: '14px' }}
                    onClick={() => {
                      if (searchCondition && searchKey) setBtnOnReset(true);
                    }}
                  >
                    SEARCH
                  </button>
                )}
              </div>
            </div>

            <DataTable.Table style={{ width: '100%', marginBottom: '0px' }}>
              <DataTable.Head>
                <DataTable.CellHead>CREATED AT</DataTable.CellHead>
                <DataTable.CellHead>SENDER ID</DataTable.CellHead>
                <DataTable.CellHead>DESCRIPTION</DataTable.CellHead>
              </DataTable.Head>
              <DataTable.Body>{displayData}</DataTable.Body>
            </DataTable.Table>
          </div>
        </div>
      </Popup>
    </div>
  );
}

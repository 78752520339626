import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../../common/component/popups/popups';
import '../../../../assets/css/Popups.css';
import Close from '../../../../assets/images/popupClose.png';
import { GET } from '../../../../services/HttpRequests';
const messageUrl = process.env.REACT_APP_MESSAGE_API;
let columns = [
  { id: 'created_at_date', displayName: 'DATE' },
  { id: 'created_at_time', displayName: 'TIME' },
  { id: 's_mobile_no', displayName: 'SENDER' },
  { id: 'zvr_name', displayName: 'USER' },
  { id: 'v_mobile_no', displayName: 'VIRTUAL NUMBER' },
  { id: 'message', displayName: 'MESSAGE' },
];

export default function ExportSMSPopup() {
  const [list, setList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const csvLink = useRef(null);

  const formatMessage = (message) => {
    let decodeMessage = message;
    if (message.length % 4 === 0 && isUniCodeUTF16(message)) {
      const chunksString = chunk(message, 4).join('\\u');
      decodeMessage = unicodeToChar(chunksString);
    }
    return decodeMessage.replace(/'/g, '’');
  };
  const unicodeToChar = (text) => {
    const emojiString = text.replace(/\\u[\dA-F]{4}/gi, function (match) {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
    if (text === emojiString) {
      return text.replace(/\\u/g, '');
    }
    return emojiString;
  };

  const chunk = (str, n) => {
    var ret = [''];
    var i;
    var len;
    for (i = 0, len = str.length; i < len; i += n) {
      ret.push(str.substr(i, n));
    }
    return ret;
  };

  const isUniCodeUTF16 = (str) => {
    const hexaDecimalRegex = /^[0-9a-fA-F]+$/;
    const whiteSpacesRegex = /\s/;
    if (whiteSpacesRegex.test(str)) {
      return false;
    } else {
      return hexaDecimalRegex.test(str);
    }
  };

  let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';

  const openPopup = () => {
    setIsPopupOpen(true);
    setFetching(false);
    setFromDate(null);
    setToDate(null);
  };

  const fetchDataHandler = async () => {
    try {
      if (fetching) return;
      setFetching(true);
      const res = await GET(`${messageUrl}v1/organisations/sms`, {
        pageNo: 1,
        pageSize: 1000,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        searchKey: 'date',
        search_key: 'date',
        from_date: fromDate,
        to_date: toDate,
      });

      setList(res.data.response.payload.docs);
      csvLink?.current?.link.click();
      setFetching(false);
      setIsPopupOpen(false);
    } catch (err) {
      console.log(err);
      setFetching(false);
    }
  };

  return (
    <div>
      <button className="Otherbutton" onClick={openPopup}>
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download SMSes</p>
            <img
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsPopupOpen(false);
                setFetching(false);
                setFromDate(null);
                setToDate(null);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file
            </p>
            <div style={{ margin: '8px 0 0 40px' }}>
              <p style={{ margin: 0 }}>From Date</p>
              <input
                type="date"
                onChange={(e) => setFromDate(e.target.value)}
                value={fromDate}
              />
            </div>
            <div style={{ margin: '8px 0 0 40px' }}>
              <p style={{ margin: 0 }}>To Date</p>
              <input
                min={fromDate}
                disabled={!fromDate}
                type="date"
                onChange={(e) => setToDate(e.target.value)}
                value={toDate}
              />
            </div>
            <p className="PopupNote">
              Max download limit - Recent 1000 entries
            </p>

            <CSVLink
              filename={`${org_name}_sms_list_${new Date().toLocaleDateString()}`}
              ref={csvLink}
              style={{ display: 'none' }}
              data={list.map((detail) => {
                return {
                  Date: new Date(detail.created_at).toLocaleDateString(),
                  Time: new Date(detail.created_at).toLocaleTimeString(),
                  Sender: detail.s_mobile_no,
                  User: detail.zvr_name,
                  'Virtual Number': detail.v_mobile_no,
                  Message: formatMessage(detail.message),
                };
              })}
            />

            <button
              className={
                !fromDate || !toDate
                  ? 'ButtonFullWidth BgGrey'
                  : 'ButtonFullWidth'
              }
              style={
                !fromDate || !toDate || fetching ? { cursor: 'default' } : null
              }
              disabled={!fromDate || !toDate}
              onClick={fetchDataHandler}
            >
              {fetching ? 'Loading ...' : 'Download CSV'}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import '../../../assets/css/dragAndDrop.css';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import DataTable from '../../../common/component/table/DataTable';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import { setHeaders } from '../../../actions/recoveryXActions';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import AddLeads from '../component/AddLeads';
import React from 'react';
import TableHeaders from '../../../common/component/TableHeaders/TableHeaders';
import './index.css';

const userUrl = process.env.REACT_APP_USER_API;
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

function CampaignReportTable(props) {
  return (
    <DataTable.Table>
      <DataTable.Head>
        <DataTable.CellHead width="20">#</DataTable.CellHead>
        {props?.headers?.length > 0 &&
          props?.headers?.map((header) => (
            <DataTable.CellHead width="50">
              {header.toUpperCase()}
            </DataTable.CellHead>
          ))}

        {/* {props.campaignType === 'static' && <DataTable.CellHead width="50">AGENT</DataTable.CellHead>}
        <DataTable.CellHead width="60">LEAD NUMBER</DataTable.CellHead>
        <DataTable.CellHead width="40">LEAD SECONDARYNUMBER</DataTable.CellHead>
        <DataTable.CellHead width="70">EMAIL</DataTable.CellHead>
        <DataTable.CellHead width="50">
          DUE
        </DataTable.CellHead>
        <DataTable.CellHead width="50">
          DATE
        </DataTable.CellHead> */}
        {props.modify !== 'false' ? (
          <DataTable.CellHead width="100" style={{ textAlign: 'end' }}>
            ACTIONS
          </DataTable.CellHead>
        ) : (
          ''
        )}
      </DataTable.Head>
      <DataTable.Body>
        {props?.agentsList?.length > 0 &&
          props?.agentsList?.map((agent, index) => (
            <DataTable.Row id={agent.userId}>
              <DataTable.Cell width="20">{1 + index}</DataTable.Cell>
              {agent &&
                props?.headers?.length > 0 &&
                props?.headers?.map((header) => (
                  <DataTable.Cell width="50">
                    {agent[header] || '-'}
                  </DataTable.Cell>
                ))}
              {props.modify !== 'false' && (
                <DataTable.Cell width="100" style={{ textAlign: 'end' }}>
                  {
                    agent?.status?.toLowerCase() === 'not_initiated' &&
                      props.campaignStatus !== 'COMPLETED' ? (
                      <img
                        alt=""
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          props.deleteLeadHandler(
                            agent,
                            props.campaignType === 'static'
                          )
                        }
                        src={Delete}
                      />
                    ) : (
                      <img alt="" style={{ opacity: 0.5 }} src={Delete} />
                    )
                    // <span style={{ fontSize: "20px", color: 'grey' }} onClick={() => toast.error("can not delete.")}> <RiDeleteBin5Fill /> </span>
                  }
                </DataTable.Cell>
              )}
            </DataTable.Row>
          ))}
      </DataTable.Body>
    </DataTable.Table>
  );
}

function CampaignLeads(props) {
  const [campaignData, setCampaignData] = useState({});
  const [campaignId, setCampaignId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [campaignHeaders, setCampaignHeaders] = useState([]);
  const [filteredHeaders, setFilteredHeaders] = useState(['name', 'number', 'secondaryNumber']);
  const [searchBy, setSearchBy] = useState('');
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const location = useLocation();
  let headers = useSelector((state) => state.recovery.headers);
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('campaignId');
  const modify = searchParams.get('modify');
  const [isHeadersPopUpOpen, setIsHeadersPopUpOpen] = useState(false);
  const [reset, setReset] = useState(false);

  const updateSearchBy = (e) => {
    const search = e.target.value;
    setSearchBy(search);
  };
  useEffect(() => {
    const updateHeaders = headers.length > 0 ? headers : ['name', 'number', 'secondaryNumber']
    setFilteredHeaders(
      updateHeaders
    );
  }, [headers]);

  const toggleModal = () => {
    console.log('Toggling Modal');
    setIsModalOpen(!isModalOpen);
  };

  const updateFilteredHeader = (header, isSelected) => {
    if (isSelected) {
      setFilteredHeaders((headers) => [...headers, header]);
      props.setHeaders({ headers: [...filteredHeaders, header] });
    } else {
      const unFilteredHeaders = filteredHeaders.filter(
        (headerType) => headerType !== header
      );
      setFilteredHeaders(unFilteredHeaders);
      props.setHeaders({ headers: unFilteredHeaders });
    }
  };

  const addLeadHandler = (data) => {
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.campaignId = campaignId;
    data.agentId = data.selectedUser;
    if (campaignData.campaignType === 'static') {
      if (!data.selectedUser) {
        toast.error('Please Select Agent !!! ');
        return;
      }
      const user = users.find((i) => i._id.toString() === data.selectedUser);
      data.agent = user.zvr_email;
    }
    setLoading(true);
    for (const key of headers) {
      if (!data.hasOwnProperty(key)) {
        data[key] = '';
      }
    }
    if (data.selectedUser) {
      delete data['selectedUser'];
    }

    return POST(cloudCallCenterUrl + 'campaigns/add-contact-to-campaign', data)
      .then(async (res) => {
        toast.success('Added Lead Successfully');
        setSelectedUser('');
        await handleFetchCampaignDetails(campaignId);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setSelectedUser('');
        toast.error(err?.response?.data?.error?.reason);
        setLoading(false);
        return false;
      });
  };

  const deleteLeadHandler = (data, checkStaticCampaign) => {
    if (modify === 'false') {
      return;
    }
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.campaignId = campaignId;
    delete data['agentName'];

    if (campaignData?.campaignLeads?.length === 1) {
      toast.error('Cannot delete lead as this is last one!!!');
      return;
    }
    setLoading(true);

    return DELETE(
      cloudCallCenterUrl + 'campaigns/delete-contact-to-campaign',
      {},
      data
    )
      .then(async (res) => {
        toast.success(' Deleted Lead Successfully !!! ');
        await handleFetchCampaignDetails(campaignId);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        setLoading(false);
        return false;
      });
  };

  const handleFetchCampaignDetails = async (id, filter = true) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: id,
    };
    if (searchBy && searchValue && filter) {
      data.searchKey = searchBy;
      data.searchValue = searchValue;
    }
    setLoading(true);
    await GET(cloudCallCenterUrl + 'campaign/get-campaign', data)
      .then((res) => {
        setCampaignData(res.data.response);
        setLoading(false);
        setCampaignHeaders(res?.data?.response?.headerKeys);
        setCampaignId(id);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getAgents = async () => {
    GET(userUrl + `v1/user`, {
      search: '',
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => {
        const data = res?.data?.response?.users?.docs;
        if (data) {
          setUsers(data.filter((item) => item?.organisation?.is_onboard));
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const addAgentName = (agents) => {
    if (agents) {
      for (let agent of [...agents]) {
        const temp = users.filter(
          (user) => user._id.toString() === agent.agentId
        );
        agent.agentName = temp[0]?.zvr_name || '-';
      }
    }
    return agents;
  };

  useEffect(() => {
    if (id) {
      handleFetchCampaignDetails(id);
      getAgents();
    }
  }, [id]);

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.goBack();
                }}
                alt=""
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {campaignData?.campaignName || ''}
              </p>
              {/* <AddLeads
                open={isModalOpen}
                toggle={toggleModal}
                addLeadHandler={addLeadHandler}
                campaignData={campaignData}
                campaignId={id}
                headers={headers}
                users={users}
                campaignLeads={campaignData.campaignLeads}
                campaignType={campaignData.campaignType}
                setSelectedUser={setSelectedUser}
                selectedUser={selectedUser}
              ></AddLeads> */}
            </div>
          </TabSearchBar>
          {loading && <PreLoader />}
          <div
            className="TableFilter"
            style={{ marginLeft: '24px', height: '50px' }}
          >
            <TableHeaders
              headers={campaignHeaders}
              updateFilteredHeader={updateFilteredHeader}
              filteredHeaders={filteredHeaders}
              isPopUpOpen={isHeadersPopUpOpen}
              openHeadersPopUp={() => setIsHeadersPopUpOpen(true)}
              closeHeadersPopUp={() => setIsHeadersPopUpOpen(false)}
            />
            <div className="CampaignSearchBar">
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className="SearchText">SEARCH BY</div>
                <select
                  className="SearchDropdown"
                  id="searchBy"
                  onChange={(e) => updateSearchBy(e)}
                  value={searchBy}
                >
                  <option>Select</option>
                  <React.Fragment>
                    {campaignHeaders.map((header) => (
                      <option value={header}>{header.toUpperCase()}</option>
                    ))}
                  </React.Fragment>
                </select>
                {searchBy ? (
                  <input
                    id="searchValue"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className="SearchBox"
                    placeholder="Search..."
                  />
                ) : null}
              </div>
              <div>
                {reset ? (
                  <button
                    className="ClearResultButton"
                    style={{
                      border: 'none',
                    }}
                    onClick={() => {
                      setSearchBy("");
                      setSearchValue("");
                      setReset(false);
                      handleFetchCampaignDetails(id, false)
                    }}
                  >
                    CLEAR RESULTS
                  </button>
                ) : (
                  <button
                    className="SearchButton"
                    style={{ width: '100px', margin: '0 8px' }}
                    onClick={() => { setReset(true); handleFetchCampaignDetails(id) }}
                  >
                    SEARCH
                  </button>
                )}
              </div>
            </div>
          </div>
          {!loading && (
            <CampaignReportTable
              modify={modify}
              headers={filteredHeaders}
              users={users}
              deleteLeadHandler={deleteLeadHandler}
              agentsList={addAgentName(campaignData?.campaignLeads)}
              campaignType={campaignData.campaignType}
              campaignStatus={campaignData.campaignStatus}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
  headers: (state) => state.recovery.headers,
});

export default styled(
  commonStyle(connect(mapStateToProps, { setHeaders })(CampaignLeads))
)`
.SearchBox {
  width: 136px;
  height: 32px;
  margin: auto 5px;
  font-size: 13px;
  padding: 0 10px;
  /* padding: 8px 16px 9px; */
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchDropdown {
  width: 136px;
  height: 32px;
  font-size: 13px;
  margin: auto 5px;
  padding: 0 5px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchButton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.ClearResultButton{
  width: 120px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
  width: '128px',
  border: none,
}

.SearchText {
  font-size: 12px;
  font-weight: 500;
  margin: auto 10px;
  /* letter-spacing: 1.2px; */
  text-align: left;
  color: ${(props) => props.theme.colors.searchByColor};
}

`;
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as Flatted from "flatted";
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';

export const transformCircular = createTransform(
  (inboundState, key) => Flatted.stringify(inboundState),
  (outboundState, key) => Flatted.parse(outboundState),
)

const persistConfig = {
  key: 'recovery',
  storage: storage,
  whitelist: ['recovery', 'onboardingtour'],
  transforms: [transformCircular]
  
};

const pReducer = persistReducer(persistConfig, rootReducer);


const initialState = {};

const middleware = [thunk];

const store = createStore(
  pReducer,
  initialState,
  composeWithDevTools(
    applyMiddleware(...middleware),
    )
);

const persistor = persistStore(store);
export {persistor, store};

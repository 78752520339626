import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css'; // Import the CSS file for the popup styles
import '../../../assets/css/Tooltip.css';
import { RiInformationLine } from 'react-icons/ri';

const Tooltip = ({ triggerText, tooltipText, creditBalance, pulseCost, pulseDuration }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span style={{ cursor: 'pointer' }}>
        <p className="TopbarAccBalText">Voice Credits</p>
        <div style={{
          display: "flex", flexDirection: "row", width: "100%", alignItems: "flex-start"
        }}>
          <div style={{ width: "auto" }}>
            <p className="TopbarAccBalAmt" style={{ width: "100%", marginRight: "5px" }} > {Math.floor((creditBalance * 100) / pulseCost).toLocaleString('en-IN')}</p>
          </div>
          <div style={{ position: 'relative', marginTop: '7px' }}>
            <RiInformationLine color='#306cca' size="20px" style={{ cursor: "pointer", marginTop: "-7px", position: "absolute" }} />
            {isOpen && (
              <div className="tooltip-text"
                // className='toot'
                style={{
                  backgroundColor: '#306cca',
                  color: '#ffffff',
                  fontSize: '13px',
                  padding: '14px',
                  left: '-55px',
                  top: '25px',
                  position: "absolute"
                }}
              >
                {/* <p style={{ margin: 0 }}>Pulse Cost</p>
                <p style={{ margin: 0 }}>
                  <strong>₹ {(pulseCost / 100)}</strong>
                </p> */}
                <p style={{ margin: 0 }}>Pulse Duration</p>
                <p style={{ margin: 0 }}>
                  <strong>{pulseDuration} seconds</strong>
                </p>
                < span className="arrow-top"> </span>
              </div>
            )}
          </div>
        </div>
      </span>
    </div>
  );
};

export default Tooltip;

import React, { useState } from 'react';
import PlayIcon from '../../../assets/images/playIconGreen.svg';
import { GET, POST } from '../../../services/HttpRequests';
import { CALL_RECORDING_PLAY_BUTTON, ADMIN_CALL_RECORDING_PLAY_BUTTON } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;
const numberMaskingUrl = process.env.REACT_APP_NUMBER_MASKING_API;

export default function AudioPlayer(props) {
  const [audioUrl, setAudioUrl] = useState('');
  const [isURLFetched, setIsURLFetched] = useState(false);
  const userRole = localStorage.getItem('doosra-biz-user-role');
  const fetchUrl = () => {
    logPostHogEvent(userRole === 'ADMIN' ? ADMIN_CALL_RECORDING_PLAY_BUTTON : CALL_RECORDING_PLAY_BUTTON);
    let callRecordingUrl = props.callRecordingUrl ? props.callRecordingUrl : '';
    console.log({ callRecordingUrl })
    if (callRecordingUrl.indexOf('cloudphone') > -1) {
      setAudioUrl(callRecordingUrl);
      setIsURLFetched(true);
    } else {
      let url = incomingCallsUrl;
      if (props.callType === "NumberMasking") {
        url = numberMaskingUrl;
      }
      GET(`${url}v1/organisations/call-recording-temp-url`, {
        organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
        call_id: props.callId,
      })
        .then((res) => {
          setAudioUrl(res?.data?.response?.url || '');
          // block any other active audio player
          window.addEventListener(
            'play',
            function (evt) {
              console.log({ evt });
              if (
                window.$_currentlyPlaying &&
                window.$_currentlyPlaying != evt.target
              ) {
                console.log("in if", { evt });
                window.$_currentlyPlaying.pause();
              }
              window.$_currentlyPlaying = evt.target;
            },
            true
          );

          setIsURLFetched(true);
        })
        .catch((err) => {
          console.log('err fetching audio url');
        });
    }
    console.log({ audioUrl })
  };
  if (!props.callRecordingUrl) return '-';
  if (isURLFetched === false) {
    return (
      <React.Fragment>
        <img
          src={PlayIcon}
          onClick={fetchUrl}
          style={{ margin: '3px 0 3px 14px', ...(props.style || {}) }}
        />
      </React.Fragment>
    );
  } else {
    if (setAudioUrl === '') return 'No audio';
    return (
      <span>
        <audio
          style={{
            height: '24px',
            marginTop: '3px',
            marginBottom: '-2px',
          }}
          controls
          autoPlay
          // ref="audio_tag"
          src={audioUrl ? audioUrl : ''}
        />
      </span>
    );
  }
}

import './style.css';

const Toggle = ({ isOn, handleToggle }) => {
    return (
        <>
            <input
                checked={isOn}
                onChange={handleToggle}
                className="react-toggle-checkbox"
                id={`react-toggle-new`}
                type="checkbox"
            />
            <label
                style={{ background: isOn && '#06D6A0' }}
                className="react-toggle-label"
                htmlFor={`react-toggle-new`}
            >
                <span className={`react-toggle-button`} />
            </label>
        </>
    );
};

export default Toggle;
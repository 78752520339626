import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Popup from '../../../common/component/popups/popups';
import { ThemeProvider } from '@emotion/react';
import { useTheme } from '../../../providers/customeThemeProvider';
import {
  darkColorSchema,
  lightColorSchema,
} from '../../../utility/colourSchema';
import Close from '../../../assets/images/popupClose.png';
import { connect } from 'react-redux';
import { PUT } from '../../../services/HttpRequests';

const userUrl = process.env.REACT_APP_USER_API;

export function FreeTrialUpgrade(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [upgradeRequested, setUpgradeRequested] = useState(false);
  const { themeType } = useTheme();

  const submitHandler = async () => {
    try {
      setLoading(true);
      await PUT(
        `${userUrl}v1/user/send-upgrade-service-email`,
        {},
        {
          organisationId: localStorage.getItem('doosra-biz-organisation-id'),
          userId: localStorage.getItem('doosra-biz-user-id'),
          featureName: 'Free Trial Upgrade',
          featureKey: 'freeTrialUpgrade',
          sendEmail: true,
        }
      );
      setLoading(false);
      setUpgradeRequested(true);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.error?.reason || 'An error occured');
    }
  };

  let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
  let trialEndAt = localStorage.getItem('free_trial_end_date');
  console.log('---- trial end', trialEndAt);
  let now = moment();
  let gap = moment(trialEndAt).diff(now, 'days');
  gap = gap + 1;
  if (!isFreeTrail) return null;

  if (!isFreeTrail) {
    return null;
  }

  return (
    <ThemeProvider
      theme={themeType === 'light' ? lightColorSchema : darkColorSchema}
    >
      <Popup
        open={popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        {props.alreadyRequested || upgradeRequested ? (
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>Upgrade requested</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={() => setPopupOpen(false)}
              />
            </div>
            <div className="PopupBody">
              <p className="PopupInfo" style={{ padding: '0 10px' }}>
                Our team has received your upgrade request. We will reach out to
                you shortly.
              </p>

              <button
                className="ButtonFullWidth BgRed"
                onClick={() => {
                  setPopupOpen(false);
                }}
              >
                CLOSE
              </button>
            </div>
          </div>
        ) : (
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>Ready for a game-changing upgrade?</p>
              {/* <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={() => {
                  setPopupOpen(false);
                }}
              /> */}
            </div>
            <div
              className="PopupBody"
              style={{ height: '500px', overflow: 'auto' }}
            >
              <p className="PopupInfo" style={{ padding: '0 10px' }}>
                Connect with our experts to unlock the full potential of Alohaa
                with exclusive features, and dedicated support tailored for you.
                Please make the labels editable.
              </p>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Org Name</label>
                <input
                  className="PopupInput"
                  id="emp_id"
                  value={props.orgName}
                  disabled={true}
                />
              </div>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">User Name</label>
                <input
                  className="PopupInput"
                  id="emp_id"
                  value={props.userName}
                  disabled={true}
                />
              </div>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Email Id</label>
                <input
                  className="PopupInput"
                  id="emp_id"
                  value={props.email}
                  disabled={true}
                />
              </div>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Number</label>
                <input
                  className="PopupInput"
                  id="emp_id"
                  value={props.number}
                  disabled={true}
                />
              </div>
              <button
                disabled={loading}
                className="ButtonFullWidth"
                style={{ marginTop: '15px' }}
                onClick={submitHandler}
              >
                Confirm
              </button>
              <button
                disabled={loading}
                className="ButtonCancelFullWidth"
                style={{ marginTop: '15px', marginBottom: '15px' }}
                onClick={() => {
                  setPopupOpen(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
      <div className="free-trial-banner">
        <span className="free-trial-text">
          {gap} days remain in your free trial
          <span
            className="free-trial-upgrade"
            onClick={() => {
              setPopupOpen(true);
            }}
          >
            Upgrade
          </span>
        </span>
      </div>
      <div style={{ height: '36px' }}></div>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  topbar: state.topbar,
  orgName: state.topbar.name,
  userName: state.topbar.user_name,
  userId: state.topbar.user_id,
  email: state.topbar.user_email,
  number: state.topbar.phone_number,
  alreadyRequested: state.topbar.isFreeTrialEmailSent,
});

export default connect(mapStateToProps, {})(FreeTrialUpgrade);

import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import EyeVisiible from '../../../assets/images/EyeVisible.svg';
import EyeNotVisiible from '../../../assets/images/EyeNotVisible.svg';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
// import CampaignOptions from '../component/CampaignOptions';
import { ReportIcon } from '../../../assets/Icons/Icons';
// import CampaignSearchBar from '../component/CampaignSearchBar';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import BroadcastActions from '../component/BroadcastActions';
import BroadcastLeads from '../component/BroadcastLeads';
import DataCard from '../component/DataCard';
import { isSuperAdminWithPermission } from '../../../utility/utility';
const broadcastUrl = process.env.REACT_APP_BROADCAST_API;
const organisationId = localStorage.getItem('doosra-biz-organisation-id');

function FilterTab(props) {
  return (
    <div
      style={{
        display: 'flex',
        gap: '16px',
        padding: '16px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p
        style={{
          margin: 0,
          fontSize: '12px',
          fontWeight: 500,
          color: '#808080',
        }}
      >
        Filter
      </p>
      <div
        className={
          props.current === 'ACTIVE' ? 'ActiveFilterTab' : 'InactiveFilterTab'
        }
      >
        43 ACTIVE
      </div>
      <div
        className={
          props.current === 'PAUSED' ? 'ActiveFilterTab' : 'InactiveFilterTab'
        }
      >
        109 PAUSED
      </div>
      <div
        className={
          props.current === 'COMPLETED'
            ? 'ActiveFilterTab'
            : 'InactiveFilterTab'
        }
      >
        349 COMPLETED
      </div>
    </div>
  );
}

export function MessageBroadcastsList(props) {
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const [campaignsList, setCamapignsList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [searchFor, setSearchFor] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [campaignsCount, setCampaignsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [broadcastInfo, setBroadcastInfo] = useState({});
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  const [metricsData, setMetricsData] = useState(null);
  const history = useHistory();
  const rowCount = 15;

  const tabs = [];
  if (userRole === 'ADMIN') {
    tabs.push({
      title: 'Broadcasts List',
      onClick: () => props.history.push('/sms-broadcasts-list'),
      active: true,
    });
  }

  const fetchCampaignListHandler = async (pageNo, searchFor, searchValue) => {
    setLoading(true);
    GET(broadcastUrl + 'v1/sms/list', {
      pageNo,
      searchFor,
      searchValue,
      pageSize: rowCount,
      // type: 'SMS',
    })
      .then((res) => {
        if (searchFor) {
          setSearchFor(searchFor);
        } else {
          setSearchFor(null);
        }
        if (searchValue) {
          setSearchValue(searchValue);
        } else {
          setSearchValue(null);
        }
        setPageNo(pageNo);
        setCamapignsList(res?.data?.response?.list?.list);
        setCampaignsCount(res?.data?.response?.list?.count);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  const searchHandler = async ({ searchKey, searchCondition }) => {
    await fetchCampaignListHandler(1, searchKey, searchCondition);
  };

  const resetHandler = async () => {
    await fetchCampaignListHandler(1, null, null);
  };

  useEffect(() => {
    // if (!props.isMessageBroadcastEnabled) history.push('sms-inbox');
    fetchCampaignListHandler(1, null, null);
    fetchSmsBroadcastMetrics();
  }, []);

  const fetchDetailsHandler = async (id) => {
    setLoading(true);
    GET(broadcastUrl + 'v1/sms', { id })
      .then((res) => {
        const updatedItem = res.data.response.broadcast;
        setCamapignsList((list) =>
          list.map((item) =>
            item._id === updatedItem._id ? updatedItem : item
          )
        );
        setBroadcastInfo(res.data.response.broadcast);
        setIsInfoPopupOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  const fetchSmsBroadcastMetrics = async () => {
    setLoading(true);
    GET(broadcastUrl + 'v1/sms/metrics', {
      organisationId
    })
      .then((res) => {
        setMetricsData(res?.data?.response);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      })
  }

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar tabs={tabs}>
            {isSuperAdminWithPermission('ADD') && <button
              className="Otherbutton"
              style={{ backgroundColor: '#07aa3f', color: 'white' }}
              onClick={() => props.history.push('/create-sms-broadcast')}
            >
              CREATE
            </button>}
            <SearchBar
              type="sms-broadcast"
              count={campaignsCount}
              disableTitle
              reset={resetHandler}
              search={searchHandler}
            />
          </TabSearchBar>
          {/* <CampaignSearchBar
            search={fetchCampaignsWithFilterHandler}
            reset={fetchCampaignListHandler}
          /> */}
          {/* <FilterTab current="ACTIVE" /> */}
          {loading && <PreLoader />}
          <BroadcastLeads
            info={broadcastInfo}
            open={isInfoPopupOpen}
            close={() => {
              setBroadcastInfo({});
              setIsInfoPopupOpen(false);
            }}
          />
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            <DataCard
              title="Total campaigns"
              count={metricsData?.totalCampaigns}
            />
            <DataCard
              title="Total Contacts"
              count={metricsData?.totalLeads}
            />
            <DataCard
              title="Total SMS’es delivered"
              count={metricsData?.totalSmsDelivered}
            />
            <DataCard
              title="Total SMS’es undelivered"
              count={metricsData?.totalSmsUndelivered}
            />
          </div>
          <DataTable.Table>
            <DataTable.Head>
              <DataTable.CellHead width="20">#</DataTable.CellHead>
              <DataTable.CellHead width="100">CAMPAIGN NAME</DataTable.CellHead>
              <DataTable.CellHead width="140">DESCRIPTION</DataTable.CellHead>
              <DataTable.CellHead width="100">TEMPLATE ID</DataTable.CellHead>
              <DataTable.CellHead width="100">STATUS</DataTable.CellHead>
              <DataTable.CellHead width="40">TOTAL LEADS</DataTable.CellHead>
              <DataTable.CellHead width="40" style={{ textAlign: 'end' }}>
                ACTIONS
              </DataTable.CellHead>
            </DataTable.Head>
            <DataTable.Body>
              {campaignsList?.length >= 0 &&
                campaignsList.map((item, index) => (
                  <DataTable.Row key={item._id}>
                    <DataTable.Cell>{index + 1}</DataTable.Cell>
                    <DataTable.Cell
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={() => {
                        history.push(`/sms-broadcast-details?id=${item._id}`);
                      }}
                    >
                      {item?.name}
                    </DataTable.Cell>
                    <DataTable.Cell>{item?.description}</DataTable.Cell>
                    <DataTable.Cell>
                      {item?.smsTemplateId || '-'}
                    </DataTable.Cell>
                    <DataTable.Cell>{item?.status}</DataTable.Cell>
                    <DataTable.Cell>{item?.total_leads}</DataTable.Cell>
                    <DataTable.Cell
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '8px',
                      }}
                    >
                      {/* <BroadcastActions
                          id={item._id}
                          status={item.status}
                          refetch={fetchCampaignListHandler}
                        />
                       */}
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}
            </DataTable.Body>
          </DataTable.Table>
          <Paginator
            currentPage={pageNo}
            lastPage={Math.ceil(campaignsCount / 15)}
            getInfo={(value) => fetchCampaignListHandler(value)}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
  isMessageBroadcastEnabled: state.topbar.isMessageBroadcastEnabled,
});

export default commonStyle(connect(mapStateToProps, {})(MessageBroadcastsList));

import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import styled from '@emotion/styled';
const overlayStyle = { background: 'rgba(0,0,0,0.9)' };
const contentStyle = {
  background: 'transparent',
  border: 'none',
  width: 'auto',
};
class Popups extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Popup
        open={this.props.open}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        {...{ overlayStyle, contentStyle }}
      >
        <div className={`${this.props.className}`}>{this.props.children}</div>
      </Popup>
    );
  }
}

export default styled(Popups)`
  input::placeholder {
    color: ${(props) => props.theme.colors.inputPlaceholderColor};
  }
  .PopupContainer {
    width: 400px;
    border-radius: 4px;
    padding-bottom: 16px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
    background-color: ${(props) => props.theme.colors.topBarBg};
    max-height: 700px;
  }

  .PopupHeaderContainer {
    width: 400px;
    height: 64px;
    padding: 20px 5px;
    background-color: ${(props) => props.theme.colors.primaryColor1};
    border-radius: 4px 4px 0 0;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: var(--white-color);
  }

  .PopupDeleteHeaderContainer {
    width: 400px;
    height: 64px;
    padding: 20px 5px;
    background-color: #000;
    border-radius: 4px 4px 0 0;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: var(--white-color);
  }

  .PopupClose {
    position: absolute;
    top: 15px;
    left: 88%;
    cursor: pointer;
  }

  .ButtonSecondary{
    color: #196cca;
    border: solid 1px #196cca;
    background-color: var(--white-color);;
    width: 368px;
    height: 56px;
    margin: 16px 16px 0 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
  }

  .PopupSearchInput {
    width: 224px;
    height: 32px;
    margin: 8px 9px;
    /* padding: 9px 75px 8px 76px; */
    text-align: center;
    border-radius: 4px;
    border: solid 1px var(--content-container-border);
    background-color: var(--lighter-grey-input-bg-col);
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .PopupSearchInput:focus,
  .PopupSearchInput:focus {
    outline: none;
  }

  .ButtonMidSize {
    width: 128px;
    height: 32px;
    margin: 8px 16px;
    border: none;
    font-size: 12px;
    font-weight: bold;
    color: var(--white-color);
    border-radius: 4px;
    background: #4caf50;
  }

  .ButtonFullWidth {
    width: 368px;
    height: 56px;
    margin: 40px 16px 0;
    /* padding: 17px 164px 20px; */
    border-radius: 4px;
    border: none;
    /* --- */
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    color: var(--white-color);
    background: ${(props) => props.theme.colors.primaryColor1};
  }

  .ButtonSecondary{
    color: #196cca;
    border: solid 1px #196cca;
    background-color: var(--white-color);;
    width: 368px;
    height: 56px;
    margin: 16px 16px 0 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
  }

  .AddUserFullWidth {
    width: 368px;
    height: 56px;
    margin: 40px 16px 0;
    /* padding: 17px 164px 20px; */
    border-radius: 4px;
    border: none;
    /* --- */
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    border: solid 1px #196cca;
    color: #196cca;
    background: ${(props) => props.theme.colors.topBarBg};
  }

  .HR {
    border-top: 1px solid ${(props) => props.theme.colors.tableLineColor};
    width: 100%;
    margin: auto;
  }

  .HR.TableRowDivider {
    margin: 0 10px;
    width: calc(100%);
  }

  .ButtonCancelFullWidth {
    width: 368px;
    height: 56px;
    margin: 10px 16px 0px 16px;
    /* padding: 17px 164px 20px; */
    border-radius: 4px;
    border: solid 1px #ed3833;
    /* --- */
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #ed3833;
    background: ${(props) => props.theme.colors.topBarBg};
  }

  .PopupInputContainer {
    margin: 10px 0px 0px 16px;
  }

  .PopupInputContainerForUpdate {
    width: 519px;
    margin: 10px 0px 0px 16px;
  }

  .PopupInputLabel {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    text-align: left;
    opacity: 0.6;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }
  .CallBalanceInfo {
    padding-top: 8px;
    text-align: center;
    padding-right: 32px;
    padding-left: 32px;
    opacity: 0.3;
    font-weight: 600;
  }
  .AvailableCAllBalanceLabel {
    display: block;
    font-weight: 600;
  }
  .PopupInputLabelForUpdate {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  .ClearButton {
    margin-left: 144px;
    cursor: pointer;
    color: #f8153b;
    display: block;
    padding-top: 20px;
    background-color: white;
    width: 120px;
  }
  .ClearCallBalance {
    margin-left: 132px;
    cursor: pointer;
    color: blue;
    display: block;
    padding-top: 20px;
    background-color: white;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }

  .AddContactNo {
    width: 90%;
    cursor: pointer;
    text-decoration: underline;
    float: right;
    margin-right: 16px;
    color: #419bf9;
    text-align: center;
    margin-top: 10px;
  }

  .TagOutLay {
    width: 370px;
    position:relative;
    padding: 5px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
    background-color: ${(props) => props.theme.colors.lightGreyTagBgColor};
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .TagsText {
    // width: fit-content;
    padding: 7px 5px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
    background-color: #ffffff;
    color: #419bf9;
    margin:7px;
    position: relative;
  }

  .TagsCloseBtn {
    position: absolute; 
    cursor: pointer;
    right:8px;
    top:9px;
    width:18px;
    height:18px;
  }
  .NotesDeleteBtn {
    position: absolute;
    color: white;
    color: red;
    font-weight: 400;
    cursor: pointer;
    right:20px;
    top:10px;
  }
  .NotesDateTxt {
    padding-top: 10px;
    font-size: 11px;
    bottom: 5px;
    left:5px
  }
  .NotesSaveBtn {
    position: relative;
    top: -8px;
    left: 280px;
    cursor: pointer;
    color: #419bf9;
    width: 100px;
  }
  .NotesText {
    width: fit-content;
    padding: 7px 5px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
    background-color: ${(props) => props.theme.colors.inputBgColor};
    color: #419bf9;
    margin:7px;
  }
  .ContactSaveBtn {
    cursor: pointer;
    color: #419bf9;
    width: 100%;
    text-align:right;
    padding-right:18px;
    font-size:14px;
    text-decoration: underline;
  }

  .ContactAddBtn {
    cursor: pointer;
    color: #419bf9;
    width: 100%;
    text-align:left;
    padding-left:5px;
    text-decoration: underline;
    font-size:15px;
  }

  .PopupInput {
    width: 368px;
    height: 56px;
    /* margin: 4px 16px 18px; */
    padding: 17px 12px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
    background-color: ${(props) => props.theme.colors.inputBgColor};
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .PopupInputDotted {
    width: 368px;
    height: 56px;
    /* margin: 4px 16px 18px; */
    padding: 17px 4px;
    border-radius: 4px;
    border-radius: 4px;
    border: solid 1px #ed3833;
    text-align: center;
  }
  .uploadRecoveryLeadFile {
    width: auto
  height: 19px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ed3833;
  }

  .ReadOnlyInput {
    background-color: ${(props) => props.theme.colors.disableInputBGColor};
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .PopupNote {
    margin: 12px 21px 0 21px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    opacity: 1;
    text-align: center;
    color: ${(props) => props.theme.colors.popupNotesTextColor};
  }

  .PopupNoteForUpdate {
    margin: 12px 21px 0 21px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    opacity: 1;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #939598;
  }

  .PopupBodyTitle {
    margin-top: 26px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }
  .PopupBody{
    background: ${(props) => props.theme.colors.topBarBg};
  }

  .callerTuneBodyTitle {
    margin-top: 26px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .callerTunePopupNote {
    margin-top: 26px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .PopupInfo {
    font-family: Montserrat;
    text-align: center;
    font-weight: bold;
    margin: 0;
    margin-top: 40px;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }
  .PopupNote {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;

    margin-top: 16px;
    letter-spacing: normal;
    text-align: center;
    color: #ed3833;
  }

  .PopupMoreInfo {
    margin: 0;
    margin-top: 6px;
    opacity: 0.5;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .PopupInfoForDelete {
    margin: 0;
    margin-top: 20px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .PopupColumnHeaders {
    display: grid;
    grid-template-columns: 60% 40%;
    text-align: center;
    font-size: 12px;
    color: ${(props) => props.theme.colors.popupColumnHeaderTextColor};
    letter-spacing: 1.2px;
    background-color: ${(props) => props.theme.colors.popupColumnHeaderBgColor};
  }
  .SmallTextSize {
    font-size: 14px;
    font-weight: 500;
  }

  .ColorRed {
    color: var(--red-color);
  }

  .BgBlue {
    background-color: #196cca;
  }

  .BgBlue {
    background-color: #196cca;
  }

  .BgWhite {
    background-color: #ffffff;
  }

  .BgRed {
    background-color: var(--red-color);
  }

  .BgGrey {
    background-color: var(--mid-grey-color);
  }

  .BgYellow {
    background-color: var(--yellowish-orange-color);
  }

  .EnDisToggleHolder {
    width: 75px;
    height: 37px;
    padding-top: 3px;
    text-align: center;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.toggleHandler};
  }
  .uploadAudio {
    width: 368px;
    height: 56px;
    margin: 10px 16px 0;
    border-radius: 4px;
    border: none;
    text-align: center;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
  }
  .ButtonAssigned {
    width: 128px;
    height: 32px;
    margin: 8px 16px;
    border: none;
    font-size: 12px;
    font-weight: bold;
    color: var(--white-color);
    border-radius: 4px;
    background: grey;
  }

  .PopupListShowHideText {
    padding-left: 16px;
    color: #006cca;
    cursor: pointer;
  }

  .DropdownWrapper {
    position: relative;
    width: 368px;
}

.PopupDropDown {
    width: 100%;
    height: 56px;
    padding: 17px 12px;
    border-radius: 4px;
    border: solid 1px #d1d3d4;
    background-color: #f7f7f7;
    color: #000000;
    -webkit-appearance: none;  /* For Chrome */
    -moz-appearance: none;     /* For Firefox */
    appearance: none;          /* For modern browsers */
    font-size: 16px;           /* Adjust as needed */
    line-height: 1.2;          /* Adjust as needed */
}

.DropdownWrapper::after {
    content: '▼';
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #000000;
}

.PopupDropDown option {
    background-color: #ffffff;
    color: #000000;
    padding: 10px; /* Adjust as needed */
}


`;

import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';

export default function ExportCampaignWiseReport(props) {
  const [list, setList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  let columns = [
    { id: 'sno', displayName: 'SNO' },
    { id: 'campaigns', displayName: 'AGENT' },
    { id: 'leadsCalled', displayName: 'LEAD NAME' },
    { id: 'leadnumber', displayName: 'LEAD NUMBER' },
    { id: 'callStatus', displayName: 'CALL STATUS' },
    { id: 'duration', displayName: 'DURATION' },
    { id: 'created_at_date', displayName: 'DATE' },
    { id: 'created_at_time', displayName: 'TIME' },
    { id: 'callTag', displayName: 'CALL DISPOSITION' },
    { id: 'callComment', displayName: 'DISPOSITION COMMENT' },
  ];
  const openPopup = () => {
    setIsPopupOpen(true);
    console.log(props.campaignData);
    setList(props.campaignData);
    setDataFetched(true);
  };
  return (
    <div>
      <button
        className="Otherbutton"
        style={{ color: 'white', backgroundColor: '#05AA3F' }}
        onClick={openPopup}
      >
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download campaign calls</p>
            <img
              className="PopupClose"
              src={Close}
              alt=""
              onClick={() => {
                setIsPopupOpen(false);
                setDataFetched(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file.
            </p>
            <p className="PopupNote">
              Max download limit - Recent 10,000 entries
            </p>
            {dataFetched ? (
              <CsvDownloader
                filename={`${props.campaignName}_report`}
                separator=","
                columns={columns}
                datas={
                  props.campaignData.length
                    ? props.campaignData.map((agent, index) => {
                        return {
                          sno: 1 + index,
                          campaigns: agent?.agentName,
                          leadsCalled: agent?.leadName || '-',
                          leadnumber: agent?.destinationNumber || '-',
                          callStatus: agent?.status || '-',
                          duration: agent?.duration || '-',
                          created_at_date: new Date(
                            agent.createdAt
                          ).toLocaleDateString(),
                          created_at_time: new Date(
                            agent.createdAt
                          ).toLocaleTimeString(),
                          callTag: agent?.callTag || '-',
                          callComment: agent?.callComment || '-',
                        };
                      })
                    : [{}]
                }
                className="ButtonFullWidth BgGreen"
                text="Download CSV"
              />
            ) : (
              <button
                className="ButtonFullWidth BgGrey"
                style={{ cursor: 'default' }}
              >
                SAVE
              </button>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}

import React, { useState } from 'react';
import '../styles/webhook.css';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { PUT } from '../../../services/HttpRequests';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import {
  ADMIN_WEBHOOK_EDIT_BUTTON,
  ADMIN_WEBHOOK_UPDATE_BUTTON,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';

const organizationUrl = process.env.REACT_APP_ORGANIZATION_API;

const UpdatePopup = (props) => {
  const webhook_type = props.webhook_type[0];
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { domain, apiKey, apiKeyValue, requestType } = props.Response;
  // const API_Endpoint = meta_data.verified_domain
  // const API_Key = meta_data.api_key ? meta_data.api_key.value : ""
  // const API_Key_Label = meta_data.api_key ? meta_data.api_key.label : ""
  // const Request_Type = meta_data.request_type ? meta_data.request_type : "post"
  const [updatedEndpoint, setUpdatedEndPoint] = useState(domain);
  const [updatedAPIKey, setUpdatedHeaders] = useState(apiKey);
  const [updatedRequestType, setUpdatedRequestType] = useState(
    requestType || 'post'
  );
  const [updatedAPIKeyValue, setUpdatedAPIKeyValue] = useState(apiKeyValue);

  const updateWebhook = () => {
    logPostHogEvent(ADMIN_WEBHOOK_UPDATE_BUTTON);
    props.setIsLoading(true);
    const organisationId = localStorage.getItem('doosra-biz-organisation-id');
    const accessKey = props.Response.access_key;
    const params = {
      organisation_id: organisationId,
      access_key: accessKey,
      web_hook: {
        end_point: updatedEndpoint,

        request_type: updatedRequestType || 'post',
      },
      type: webhook_type,
      request_type: updatedRequestType,
    };
    if (updatedAPIKey && updatedAPIKeyValue) {
      let headers = {};
      headers[updatedAPIKey] = updatedAPIKeyValue;
      params.web_hook['headers'] = headers;
      params.web_hook['api_key'] = {
        label: updatedAPIKey,
        value: updatedAPIKeyValue,
      };
    }

    PUT(`${organizationUrl}v1/organisation/webhook-domain`, {}, params)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        props.toast.success('Updated Successfully');
        props.setIsLoading(false);
        props.setAreFieldsUpdated(true);
        setIsPopupOpen(false);
      })
      .catch((err) => {
        console.log(err);
        props.toast.error(err.response.data.error.reason);
        props.setIsLoading(false);
      });
  };
  return (
    <div>
      <button
        className="primary-btn-2"
        type="submit"
        disabled={!isSuperAdminWithPermission('EDIT')}
        onClick={() => {
          logPostHogEvent(ADMIN_WEBHOOK_EDIT_BUTTON);
          setIsPopupOpen(true);
        }}
      >
        <span className="pri-btn-txt-2">EDIT</span>
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Edit Webhook</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={() => setIsPopupOpen(false)}
            />
          </div>
          <div className="PopupBody">
            <div className="PopupInputContainer">
              <span className="PopupInputLabel">API Endpoint</span>
              <input
                className="PopupInput"
                type="text"
                value={updatedEndpoint}
                onChange={(event) => setUpdatedEndPoint(event.target.value)}
              />
            </div>
            <div className="PopupInputContainer">
              <span className="PopupInputLabel">API Key</span>
              <input
                className="PopupInput"
                type="text"
                value={updatedAPIKey}
                onChange={(event) => setUpdatedHeaders(event.target.value)}
              />
            </div>
            <div className="PopupInputContainer">
              <span className="PopupInputLabel">API Key Value</span>
              <input
                className="PopupInput"
                type="text"
                value={updatedAPIKeyValue}
                onChange={(event) => setUpdatedAPIKeyValue(event.target.value)}
              />
            </div>
            <div className="PopupInputContainer">
              <span className="PopupInputLabel">Request Type</span>
              <select
                name="request-type"
                id="request"
                className="PopupInput"
                value={updatedRequestType}
                onChange={(event) => setUpdatedRequestType(event.target.value)}
              >
                {/*<option value="get" className='drp-dwn-txt'>GET</option>*/}
                <option value="post" className="drp-dwn-txt">
                  POST
                </option>
              </select>
            </div>
            <p className="PopupNoteForUpdate">
              Alohaa doesn’t validate your endpoints or API key, so be sure to
              double check your API Endpoint and API key details
            </p>
            <button className="ButtonFullWidth BgBlue" onClick={updateWebhook}>
              UPDATE
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default UpdatePopup;

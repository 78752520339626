/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import '../../../assets/css/SearchBar.css';
import { commonStyle } from '../../../assets/css/css';
import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import { GET } from '../../../services/HttpRequests';
import BarChart from '../component/BarChart';
import PieChart from '../component/PieChart';
import DonoutChart from '../component/DonoutChart';
import DataCard from '../component/DataCard';
import PreLoader from '../../../common/component/preLoader/PreLoader';

const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;

export function RecoveryChartsPage(props) {
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [callsData, setCallsData] = useState([])

  let userRole = localStorage.getItem('doosra-biz-user-role');

  const getLeadsCount = (count) => {
    setCount(count);
  };
  useEffect(() => { fetchOrganisationReport(); }, []);
  const fetchOrganisationReport = () => {
    setLoading(true);
    let data = {};
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    GET(outGoingUrl + 'v1/organisations/outbound-call/get-report-data', data)
      .then((res) => {
        setCallsData(res.data.response);
        setLoading(false);
        return res.data.response;
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const tabs = [];
  tabs.push({
    title: 'Leads',
    subtitle: `${count}`,
    onClick: () => props.history.push('/recovery-calling'),
  });
  if (userRole === "ADMIN") {
    tabs.push({
      title: 'Agent Reports',
      onClick: () => props.history.push('/report'),
    });
    tabs.push({
      title: 'Organisation Report',
      active: true
    });
  }
  tabs.push({
    title: 'Calls',
    onClick: () => props.history.push('/recovery-calls'),
  });

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        {loading && <PreLoader />}
        {!loading && <div className="ContentContainer">
          <TabSearchBar tabs={tabs}>
            <SearchBar
              type="report"
              disableTitle
              count={count}
              getLeadsCount={(e) => getLeadsCount(e)}
            />
          </TabSearchBar>
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            <DataCard title="Number of calls made" count={callsData.totalCalls} />
            <DataCard title="Number of Unique calls made" count={callsData.totalUniqueCalls} />
            <DataCard
              title="Number of calls connected"
              count={callsData.totalAnsweredCalls}
            />
            <DataCard
              title="Number of calls unique calls connected"
              count={callsData.totalUniqueAnswerdCalls}
            />
          </div>
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            {/* <DataCard
              title="Connectivity ratio "
              count={`${((callsData.totalUniqueCalls/callsData.totalAnsweredCalls)* 100).toFixed(2)}`}
            /> */}
            <DataCard
              title="Call duration under 7 seconds"
              count={callsData.totalCallsUnderSevenSeconds}
            />
            <DataCard
              title="Total calls duration"
              count={`${Math.ceil(callsData.totalDuration / 60)} m`}
            />
            <DataCard
              title="Average call duration"
              count={`${callsData.averageDurationOfCalls} s`}
            />
            <DataCard
              title="Total dispositions"
              count={callsData.dispositionsCount}
            />
          </div>
          <div style={{ display: 'flex', gap: '80px', padding: '16px' }}>
            <span style={{ width: "40%", height: "400px" }}>
              <DonoutChart chartData={callsData}></DonoutChart>
            </span>
            <span style={{ width: "40%", height: "500px" }}>
              <BarChart chartData={callsData}></BarChart>
            </span>
          </div>
          {/* <div style={{width:"800px", height:"800px", marginTop:"100px"}}>
            <PieChart chartData = {callsData}></PieChart>
          </div> */}
        </div>}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
});
export default commonStyle(connect(mapStateToProps, {})(RecoveryChartsPage));

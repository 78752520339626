import React, { useEffect, useState } from 'react';
import { GET } from '../../../services/HttpRequests';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';
import Topbar from '../../../common/component/topbar/Topbar';
import Sidebar from '../../../common/component/sidebar/Sidebar';

const orgUrl = process.env.REACT_APP_ORGANIZATION_API;

const KycCompleted = JSON.parse(localStorage.getItem('is_kyc_completed'));
let kycStatusText = KycCompleted ? 'VERIFIED' : 'PENDING';

const KYCPendingStatus = (props) => {
  const [kycStatus, setKycStatus] = useState(false);

  const fetchKYCStatus = () => {
    GET(`${orgUrl}v1/organisation/basic/details`, {
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        localStorage.setItem(
          'is_kyc_completed',
          !!res?.organisation?.is_kyc_completed
        );
        localStorage.setItem('show_payment_history', res?.organisation?.show_payment_history);
        localStorage.setItem('is_plan_available', !!res?.organisation?.plan_id);
        setKycStatus(!!res.organisation.is_kyc_completed);
        if (res.organisation.is_kyc_completed) {
          kycStatusText = !!res.organisation.is_kyc_completed
            ? 'VERIFIED'
            : 'PENDING';
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchKYCStatus();
  }, []);

  console.log({ KycCompleted, kycStatus, kycStatusText });

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <div style={{ width: '90%', margin: '16px auto' }}>
            <span style={{ fontSize: 24, fontWeight: 500, color: '#196cca' }}>
              {/* eKYC details */}
            </span>
            <div className="PlanBox">
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <span>eKYC Status </span>
                <span
                  style={{
                    fontSize: '12px',
                    color: '#fff',
                    backgroundColor: KycCompleted ? '#07aa3f' : '#f07038',
                    borderRadius: '4px',
                    padding: '4px',
                  }}
                >
                  {kycStatusText}
                </span>
              </div>

              <p style={{ margin: 0 }}>
                {KycCompleted
                  ? `Your eKYC has been successfully verified, and your account is now active. You can now enjoy full access to all the features available in your plan.`
                  : `Your eKYC is pending. Our support executives will be getting in touch with you shortly to collect the necessary documents.`}
              </p>
              <p>
                If you haven’t heard from anyone yet please contact us at
                <a href="mailto:support@alohaa.ai">support@alohaa.ai</a>
              </p>
              {/* <Button className="btn btn-danger" onClick={logoutHandler}>Logout</Button> */}
            </div>
          </div>

          <p style={{ textAlign: 'center', paddingTop: '42px' }}>
            Contact our support team at{' '}
            <a href="mailto:support@alohaa.ai">support@alohaa.ai</a>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
});

export default commonStyle(connect(mapStateToProps, {})(KYCPendingStatus));

/* eslint-disable */
import React, { Component } from 'react';
import styled from '@emotion/styled';

import '../../../assets/css/SearchBar.css';
import { MenuItem, Select } from '@material-ui/core';

const callStatuses = [
  { displayValue: 'Answered', backEndValue: '^answered' },
  { displayValue: 'Not Answered', backEndValue: 'not_answered' }
];

class SearchBar extends Component {
  constructor() {
    super();
    this.state = {
      searchKey: '',
      searchValue: '',
      fromDate: new Date().toISOString().slice(0, 10),
      toDate: new Date().toISOString().slice(0, 10),
      btnOnReset: false,
      userRole: localStorage.getItem('doosra-biz-user-role'),
    };
    this.initialState = this.state;
  }

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value, btnOnReset: false });
  };

  onSearch = () => {
    let data = {};
    if (this.state.searchKey === '' || this.state.fromDate === '' || this.state.toDate === '') return;
    data.searchKey = this.state.searchKey;
    if (this.state.searchKey === "date") {
      data.from_date = this.state.fromDate;
      data.to_date = this.state.toDate;
    }
    data.searchCondition = this.state.searchValue;
    this.props.search(data);
    this.setState({ btnOnReset: true });
  };

  onReset = () => {
    this.setState(this.initialState);
    this.props.reset();
  };

  render() {
    return (
      <div className={`${this.props.className}`}>
        <div className="SearchBarComponent">
          <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
          </div>
          <div style={{ flex: 1 }} />
          {
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className="SearchText">SEARCH BY</div>
              <React.Fragment>
                <select
                  className="SearchDropdown"
                  onChange={this.changeHandler}
                  id="searchKey"
                  value={this.state.searchKey}
                >
                  <option value="">Select</option>
                  {this.state.userRole !== "USER" ? <option value="user">Initiator Name</option> : null}
                  <option value="initiatorNumber">Initiator Number</option>
                  <option value="destinationNumber">
                    Destination Number
                  </option>
                  <option value="disposition">
                    Disposition
                  </option>
                  <option value="date">Date</option>
                  <option value="status">
                    Call Status
                  </option>
                </select>
                {this.state.searchKey !== "date" &&
                  this.state.searchKey !== "user" &&
                  this.state.searchKey !== "disposition" &&
                  this.state.searchKey !== "status" &&
                  <input
                    id="searchValue"
                    className="SearchBox"
                    placeholder="Search..."
                    onChange={this.changeHandler}
                    value={this.state.searchValue}
                    disabled={this.state.searchKey === "" ? true : false}
                  />}
                {this.state.searchKey === "date" &&
                  <React.Fragment>
                    <input
                      id="fromDate"
                      value={this.state.fromDate}
                      onChange={this.changeHandler}
                      className="SearchBox"
                      style={{ padding: '0px' }}
                      type="date"
                    />
                    <input
                      id="toDate"
                      value={this.state.toDate}
                      onChange={this.changeHandler}
                      style={{ padding: '0px' }}
                      className="SearchBox"
                      type="date"
                    />
                  </React.Fragment>
                }
                {
                  this.state.searchKey === "user" &&
                  <select
                    className="SearchBox"
                    id="searchValue"
                    onChange={this.changeHandler}
                  >
                    <option value={"-"}>{"Select from dropdown"}</option>
                    {this.props.userData.length > 0 &&
                      this.props.userData.map((user) => (
                        <option value={user.user_id}>{user.userName}</option>
                      ))}
                  </select>
                }
                {
                  this.state.searchKey === "disposition" &&
                  <select
                    className="SearchBox"
                    id="searchValue"
                    onChange={this.changeHandler}
                  >
                    <option value={"-"}>{"Select from dropdown"}</option>
                    {this.props.dispositions.length > 0 &&
                      this.props.dispositions.map((dispositionObj) => (
                        <option value={dispositionObj.dispositon}>{dispositionObj.dispositon}</option>
                      ))
                    }
                    <option value={"Other"}>{"Other"}</option>
                  </select>
                }
                {
                  this.state.searchKey === "status" &&
                  <select
                    className="SearchBox"
                    id="searchValue"
                    onChange={this.changeHandler}
                  >
                    <option value={"-"}>{"Select from dropdown"}</option>
                    {
                      callStatuses.map((item) => (
                        <option value={item.backEndValue}>{item.displayValue}</option>
                      ))
                    }
                  </select>
                }
              </React.Fragment>
            </div>
          }

          <div>
            {this.state.btnOnReset ? (
              <button
                className="ClearResultButton"
                style={{
                  border: 'none',
                }}
                onClick={this.onReset}
              >
                CLEAR RESULTS
              </button>
            ) : (
              <>
                <button
                  className="SearchButton"
                  style={{ width: '100px', margin: '0 8px' }}
                  onClick={this.onSearch}
                  disabled={this.state.searchKey === "" ? true : false}
                >
                  SEARCH
                </button>
              </>
            )}
          </div>
        </div>
      </div >
    );
  }
}

export default styled(SearchBar)`
display: flex;
flex: 1;

input::placeholder {
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}
input {
  color-scheme: ${(props) => props.theme.colors.dateCalenderIconColor};
}
.SearchBarComponent {
  width: 100%;
  height: 54px;
  background-color: ${(props) => props.theme.colors.searchBarBgcolor};
  display: flex;
  grid-template-columns: 136px auto auto 116px;
  align-items: center;
  border-radius: 1px;
  box-shadow: 0px -15px 10px -15px rgb(0, 0, 0, 0.4);
  border: solid 1px ${(props) => props.theme.colors.searchBarBgcolor};
}

.SearchText {
  font-size: 12px;
  font-weight: 500;
  margin: auto 10px;
  /* letter-spacing: 1.2px; */
  text-align: left;
  color: ${(props) => props.theme.colors.searchByColor};
}

.SearchDropdown {
  width: 136px;
  height: 32px;
  font-size: 13px;
  margin: auto 5px;
  padding: 0 5px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchBox {
  width: 136px;
  height: 32px;
  margin: auto 5px;
  font-size: 13px;
  padding: 0 10px;
  /* padding: 8px 16px 9px; */
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchButton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.ClearResultButton{
  width: 120px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
  width: '128px',
  border: none,
}
.Otherbutton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.theme.colors.lightGreyBgColor};
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.SelectedContainer {
  width: 136px;
  height: 54px;
  background-color: #196cca;
}
.Agents-Logged-In {
  display: flex;
  height: 18px;
  margin: 0 0 0 0;
  
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.Total-call-duration {
  height: 18px;
  margin: 0 4px 0 0;
  
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SelectedContainer h6 {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding-top: 11px;
  padding-left: 14px;
  color: #ffffff;
}

.SelectedContainer p {
  font-size: 16px;
  font-weight: bold;
  padding-left: 14px;
  margin: 0;
  letter-spacing: 1.2px;
  color: #ffffff;
}


@media only screen and (max-width: 900px) {
  .SearchBarComponent {
    width: fit-content;
    grid-template-columns: 48px 136px 77px auto 150px;
  }
}
`;

const MAINTAINANCE = 'MAINTAINANCE';

const initialState = {
  isUnderMaintainance: false,
  maintainanceMessage: '',
};
export default function (state = initialState, action) {
  switch (action.type) {
    case MAINTAINANCE:
      return {
        ...state,
        isUnderMaintainance: true,
        maintainanceMessage: action.payload,
      };

    default:
      return {
        ...state,
        isUnderMaintainance: false,
        maintainanceMessage: '',
      };
  }
}

import {
  INIT_CALL,
  TOGGLE_SIDEBAR,
  SET_SMS_ACCESS_KEY,
  SET_CALL_ACCESS_KEY,
  SET_VOICE_API_ACCESS_KEY,
  SET_NUMBER_MASKING_ACCESS_KEY,
  SET_NUMBER_MASKING_CALL_DETAILS_ACCESS_KEY,
} from '../actions/sidebarActions';

const initialState = {
  sidebarClosed: true,
  initCallPopup: null,
  accessKeyForSMSWebhook: "",
  accessKeyForCallWebhook: "",
  accessKeyForVoiceApiWebhook: "",
  accessKeyForNumberMaskingWebhook: "",
  accessKeyForNumberMaskingCallDetailsWebhook: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarClosed: !state.sidebarClosed,
      };
    case INIT_CALL:
      return {
        ...state,
        initCallPopup: action.payload,
      };
    case SET_SMS_ACCESS_KEY:
      return {
        ...state,
        accessKeyForSMSWebhook: action.payload
      }
    case SET_CALL_ACCESS_KEY:
      return {
        ...state,
        accessKeyForCallWebhook: action.payload
      }
    case SET_VOICE_API_ACCESS_KEY:
      return {
        ...state,
        accessKeyForVoiceApiWebhook: action.payload
      }
    case SET_NUMBER_MASKING_ACCESS_KEY:
      return {
        ...state,
        accessKeyForNumberMaskingWebhook: action.payload
      }
    case SET_NUMBER_MASKING_CALL_DETAILS_ACCESS_KEY:
      return {
        ...state,
        accessKeyForNumberMaskingCallDetailsWebhook: action.payload
      }
    default:
      return state;
  }
}

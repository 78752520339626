import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { PUT } from '../../../services/HttpRequests';
import { isSuperAdminWithPermission } from '../../../utility/utility';
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
class PopTwo extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      dndNumber: '',
      error: '',
      name: '',
      numberType: 'permanent',
      expiryDate: null,
    };
    this.initialState = this.state;
  }
  openPopup = () => {
    this.setState({ popupOpen: true });
  };
  closePopup = () => {
    this.setState(this.initialState);
  };
  inputHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleNumberType = (e) => {
    this.setState({ numberType: e.target.value });
  };

  manualDataHandler = () => {
    if (!this.state.name) {
      toast.error('DND Number is required');
      return;
    }
    if (!this.state.dndNumber || isNaN(parseInt(this.state.dndNumber))) {
      toast.error('DND Number is required');
      return;
    }
    if (this.state.dndNumber?.length !== 10) {
      toast.error('DND Number should be 10 digit phone number');
      return;
    }
    let data = {
      blackListNumber: this.state.dndNumber,
      name: this.state.name,
      action: 'ADD',
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      expiryDate:
        this.state.numberType === 'temporary'
          ? this.state.expiryDate
          : undefined,
      type: this.state.numberType.toLowerCase(),
    };
    PUT(`${cloudCallCenterUrl}blacklist`, {}, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.closePopup();
        this.props.refreshList();
        toast.success('DND Number Added');
        this.setState({
          popupOpen: false,
          dndNumber: '',
          error: '',
        });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({ error: err.response.data.error.reason });
          toast.error(err.response.data.error.reason);
        }
      });
  };
  render() {
    let currentView;
    currentView = (
      <div className="PopupContainer" style={{ overflow: 'auto' }}>
        <div className="PopupHeaderContainer">
          <p>ADD BLACKLIST NUMBER</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={this.closePopup}
          />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">NAME</label>
            <input
              className="PopupInput"
              id="name"
              value={this.state.name}
              onChange={this.inputHandler}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">NUMBER</label>
            <input
              className="PopupInput"
              id="dndNumber"
              value={this.state.dndNumber}
              onChange={this.inputHandler}
            />
          </div>
          <div className="PopupInputContainer">
            <div>
              {' '}
              <label className="PopupInputLabel"> TYPE </label>
            </div>
            <label style={{ fontSize: '16px' }}>
              <input
                type="radio"
                value="permanent"
                name="numberType"
                onChange={this.handleNumberType}
                checked={this.state.numberType === 'permanent'}
              />
              &nbsp; Permanent
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <label style={{ fontSize: '16px' }}>
              <input
                type="radio"
                value="temporary"
                name="numberType"
                checked={this.state.numberType === 'temporary'}
                onChange={this.handleNumberType}
              />
              &nbsp; Temporary
            </label>
          </div>
          {this.state.numberType === 'temporary' && (
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">EXPIRY DATE</label>
              <input
                className="PopupInput"
                id="expiryDate"
                type="datetime-local"
                value={this.state.expiryDate}
                onChange={this.inputHandler}
              />
            </div>
          )}
          {this.state.error ? (
            <div className="ErrorContainer ErrorContainerMargin">
              <img
                alt=""
                src={InfoRed}
                style={{
                  paddingRight: '5px',
                  position: 'relative',
                  top: '-2px',
                }}
              />
              {this.state.error}
            </div>
          ) : null}
          <button
            className="ButtonFullWidth BgGreen"
            onClick={this.manualDataHandler}
            style={{ marginTop: '20px' }}
          >
            SAVE
          </button>
        </div>
      </div>
    );
    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        {currentView}
      </Popup>
    );
    return (
      <div style={{ display: 'flex' }}>
        <span>
          <button
            className="Otherbutton"
            style={{
              marginRight: 0,
              backgroundColor: '#07aa3f',
              color: 'white',
            }}
            onClick={this.openPopup}
            disabled={!isSuperAdminWithPermission('ADD')}
          >
            ADD NUMBER
          </button>
          {popper}
        </span>
      </div>
    );
  }
}
export default PopTwo;

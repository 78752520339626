/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import '../../../assets/css/SearchBar.css';
import { commonStyle } from '../../../assets/css/css';
import phoneWhite from '../../../assets/images/ioscall.png';
import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import DataTable from '../../../common/component/table/DataTable';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import AddLeadListPopup from '../../../pages/recoveryCalling/component/AddLeadListPopup';
import GetCallHistory from '../component/GetCallHistory';
import ReportSearchBar from '../component/ReportSearchBar';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import moment from 'moment';
import { toast } from 'react-toastify';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;

export function RecoveryCallingPage(props) {
  let displayData;
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [leadListId, setLeadListId] = useState('');
  const [totalOrganisationCalls, setTotalOrganisationCalls] = useState(0);
  const [activeLoginUsersCount, setActiveLoginUsersCount] = useState(0);
  const [uniqueCallsCount, setUniqueCallsCount] = useState(0);
  const [totalOrganisationAnsweredCalls, setTotalOrganisationAnsweredCalls] = useState(0);
  const [totalOrganisationDuration, setTotalOrganisationDuration] = useState(0);
  const [leadListContacts, setLeadListContacts] = useState([]);
  const [agentReportFromDate, setAgentReportFromDate] = useState(new Date().toISOString().slice(0, 10));
  const [agentReportToDate, setAgentReportToDate] = useState(new Date().toISOString().slice(0, 10));

  const getLeadsCount = (count) => {
    setCount(count);
  };
  useEffect(() => { fetchOrganisationReport(); getLeadListsHandler(); }, []);
  const getLeadListsHandler = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(
      outGoingUrl + 'v1/organisations/outbound-call/get-lead-lists',
      data
    )
      .then((res) => {

        setCount(res.data.count);

        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };
  const fetchOrganisationReport = (filter = {}) => {
    setLoading(true);
    let data = { ...filter };
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    GET(outGoingUrl + 'v1/organisations/outbound-call/report', data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        setLeadListContacts(res.response);
        setTotalOrganisationCalls(res.totalOrganisationCalls);
        setTotalOrganisationDuration(res.totalOrganisationDuration);
        setActiveLoginUsersCount(res.activeLoginUsersCount);
        setUniqueCallsCount(res.totalOrganisationUniqueCalls)
        setTotalOrganisationAnsweredCalls(res.totalOrganisationAnsweredCount);
        setAgentReportFromDate(data.fromDate);
        setAgentReportToDate(data.toDate);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  if (leadListContacts.length > 0) {
    displayData = leadListContacts.map((row) => (
      <DataTable.Row key={row.number}>
        <DataTable.Cell style={row.totalCalls > 40 ? { color: "green" } : { color: "red" }}>{row.results.zvr_name}</DataTable.Cell>
        <DataTable.Cell>
          {!row.totalCalls || row.totalCalls === 'NA' ? '-' : row.totalCalls}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.uniqueDestNumbersCount || row.uniqueDestNumbersCount === 'NA' ? '-' : row.uniqueDestNumbersCount}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.totalAnsweredCalls || row.totalAnsweredCalls === 'NA'
            ? '-'
            : row.totalAnsweredCalls}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.uniqueDestAnsweredNumbersCount || row.uniqueDestAnsweredNumbersCount === 'NA'
            ? '-'
            : (row.uniqueDestAnsweredNumbersCount - 1)}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.totalCallDuration || row.totalCallDuration === 'NA'
            ? '-'
            : row.totalCallDuration}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.recoveryScore || row.recoveryScore === 'NA'
            ? '-'
            : row.recoveryScore}
        </DataTable.Cell>
        <DataTable.Cell>
          {!row.totalDispositions || row.totalDispositions === 'NA'
            ? '-'
            : row.totalDispositions}
        </DataTable.Cell>
        {agentReportFromDate === agentReportToDate ?
          <><DataTable.Cell>
            {!row.idleTime || row.idleTime === 'NA'
              ? '-'
              : moment.utc(row.idleTime * 1000).format('HH:mm:ss')}
          </DataTable.Cell><DataTable.Cell>
              {!row.breakTime || row.breakTime === 'NA'
                ? '-'
                : moment.utc(row.breakTime * 1000).format('HH:mm:ss')}
            </DataTable.Cell></> :
          null}
      </DataTable.Row>
    ));
  }

  const tabs = [];
  tabs.push({
    title: 'Leads',
    subtitle: `${count}`,
    onClick: () => props.history.push('/recovery-calling'),
  });
  if (userRole === "ADMIN") {
    tabs.push({
      title: 'Agent Reports',
      active: true,
    });
    tabs.push({
      title: 'Organisation Report',
      onClick: () => props.history.push('/charts'),
    });
  }
  tabs.push({
    title: 'Calls',
    onClick: () => props.history.push('/recovery-calls'),
  });

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        {loading && <PreLoader />}
        {!loading && <div className="ContentContainer">
          <TabSearchBar tabs={tabs}>
            <SearchBar
              type="report"
              disableTitle
              exportReport="agentReport"
              count={count}
              getLeadsCount={(e) => getLeadsCount(e)}
              agentList={leadListContacts}
              fromDate={agentReportFromDate}
              toDate={agentReportToDate}
            />
          </TabSearchBar>
          <ReportSearchBar
            totalOrganisationCalls={totalOrganisationCalls}
            totalOrganisationDuration={totalOrganisationDuration}
            activeLoginUsersCount={activeLoginUsersCount}
            totalUniqueCallsMade={uniqueCallsCount}
            totalOrganisationAnsweredCalls={totalOrganisationAnsweredCalls}
            search={fetchOrganisationReport}
            reset={fetchOrganisationReport}
          />
          <DataTable.Table>
            <DataTable.Head>
              <DataTable.CellHead>AGENT NAME</DataTable.CellHead>
              <DataTable.CellHead>CALLS MADE</DataTable.CellHead>
              <DataTable.CellHead>UNIQUE CALLS MADE</DataTable.CellHead>
              <DataTable.CellHead>CONNECTED CALLS</DataTable.CellHead>
              <DataTable.CellHead>UNIQUE CONNECTED CALLS</DataTable.CellHead>
              <DataTable.CellHead>TOTAL CALL TIME(SECONDS)</DataTable.CellHead>
              <DataTable.CellHead>RECOVERY SCORE</DataTable.CellHead>
              <DataTable.CellHead>DISPOSITIONS COUNT</DataTable.CellHead>
              {agentReportFromDate === agentReportToDate ?
                <><DataTable.CellHead>IDLE TIME</DataTable.CellHead>
                  <DataTable.CellHead>BREAK TIME</DataTable.CellHead>
                </>
                : null}
            </DataTable.Head>
            <DataTable.Body>{displayData}</DataTable.Body>
          </DataTable.Table>
        </div>}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
});
export default commonStyle(connect(mapStateToProps, {})(RecoveryCallingPage));

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import closeIcon from '../../../assets/images/CloseIcon.svg';
class ViewPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true, // Set to true by default
    };
  }
  handleCloseClick = () => {
    this.setState({ visible: false });
  };
  render() {
    let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
    if (!this.state.visible || !isFreeTrail) {
      return null; // If not visible, don't render anything
    }
    return (
      <div className="free-trial-banner">
        <span className="free-trial-text">
          7 days remain in your free trial{' '}
          <Link to="/plans" className="view-plan-link">
            View Plan
          </Link>
          .
        </span>
        <button className="close-button" onClick={this.handleCloseClick}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>
    );
  }
}
export default ViewPlan;
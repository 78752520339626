import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { POST } from '../../../services/HttpRequests';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import {
  ADMIN_ADD_AGENT,
  ADMIN_ADD_AGENT_MANUALLY,
  ADMIN_ADD_AGENT_UPLOAD_CSV,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';
import AddLeadToLeadListPopup from './AddLeadToLeadListPopup';
import AddLeadListPopup from './AddLeadListPopup';
import RemoveLeadsPopup from './RemoveLeadsPopup';
const URL = process.env.REACT_APP_SAMPLE_FILE_URL;
const userUrl = process.env.REACT_APP_USER_API;
class AddLeads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      name: '',
      number: '',
      email: '',
      step: 1,
      csvFile: null,
      error: '',
      emp_id: '',
      is_webrtc_enabled: false,
      processing: false,
      isModalOpen: false,
    };
    this.initialState = this.state;
  }

  openPopup = () => {
    logPostHogEvent(ADMIN_ADD_AGENT);
    this.setState({ popupOpen: true });
  };
  closePopup = () => {
    this.setState(this.initialState);
  };
  inputHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  checkboxToggleHandler = () => {
    this.setState({ is_webrtc_enabled: !this.state.is_webrtc_enabled });
  };

  fileUploadHandler = (e) => {
    this.setState({ csvFile: e.target.files[0] });
  };

  switchStepHandler = (step) => {
    this.setState({ step: step });
  };

  manualDataHandler = () => {
    if (!this.state.name || !this.state.email || !this.state.number) {
      this.setState({
        error: 'Name, Phone Number and Email are required fields.',
      });
      return;
    }
    if (this.state.processing) return;
    this.setState({ processing: true });

    let data = {
      emp_id: this.state.emp_id,
      name: this.state.name,
      phone_number: this.state.number,
      email: this.state.email,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      is_webrtc_enabled: this.props.isWebrtcEnabled || false,
    };
    POST(`${userUrl}v1/user`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.closePopup();
        // window.location.reload()
        this.props.refreshList();
        toast.success('Agent added Successfully');
        this.setState({
          popupOpen: false,
          name: '',
          number: '',
          email: '',
          step: 1,
          csvFile: null,
          error: '',
          emp_id: '',
          processing: false,
        });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({
            error: err.response.data.error.reason,
            processing: false,
          });
          toast.error(err.response.data.error.reason);
        }
      });
  };

  csvUploadHandler = () => {
    if (this.state.processing) return;
    if (!this.state.csvFile) return;
    this.setState({ processing: true });
    let formData = new FormData();
    formData.append('users', this.state.csvFile, this.state.csvFile.name);
    formData.append(
      'organisationId',
      localStorage.getItem('doosra-biz-organisation-id')
    );

    POST(`${userUrl}v1/user/bulk`, formData)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        // window.location.reload()

        this.closePopup();
        this.props.refreshList();
        toast.success(res.status);
        if (res.existing_users.length > 0)
          toast.warning(`${res.existing_users.length} duplicate(s) found`);
        if (res.un_mapped_users.length > 0)
          toast.warning(
            `${res.un_mapped_users.length} Primary number(s) cannot be mapped as Virtual number(s).`
          );
        if (res.existing_users.length > 0) {
          // eslint-disable-next-line array-callback-return
          res.existing_users.map((item) => {
            toast.error(`${item.phone_number} already exists`);
          });
        }
        if (res.un_mapped_users.length > 0) {
          // eslint-disable-next-line array-callback-return
          res.existing_users.map((item) => {
            toast.error(`${item.phone_number} not mapped`);
          });
        }
        this.setState({ processing: false });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({ error: err.response.data.error.reason });
          toast.error(err.response.data.error.reason);
          this.setState({ processing: false });
        }
      });
  };

  toggleModal = () => {
    this.setState({isModalOpen: !(this.state.isModalOpen)})
    if(this.state.isModalOpen) {
      this.switchStepHandler(1);
    }

  }

  render() {
    let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';
    let currentView;
    const basicUserDetailDownloadCSV = this.props.basicUserDownloadDetail;
    let columns = [
      { id: 'emp_id', displayName: 'EMPLOYEE ID' },
      { id: 'zvr_name', displayName: 'AGENT NAME' },
      { id: 'v_mobile_no', displayName: 'VIRTUAL NO' },
      { id: 'zvr_mobile_no', displayName: 'PERSONAL PHONE NO' },
      { id: 'created_at', displayName: 'ADDED ON' },
    ];
    let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
    isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
    switch (this.state.step) {
      case 3:
        currentView = (
          <AddLeadToLeadListPopup
            open={this.state.isModalOpen}
            toggle={() => this.toggleModal()}
            addLeadHandler={this.props.addLeadHandler}
            campaignData={this.props.campaignData}
            headers={this.props.headers}
            users={this.props.users}
            campaignLeads={this.props.campaignData.campaignLeads}
            campaignType={this.props.campaignData.campaignType}
            setSelectedUser={this.props.setSelectedUser}
            selectedUser={this.props.selectedUser}
          />
        );
        break;
      case 2:
        currentView = <AddLeadListPopup
        open={this.state.isModalOpen}
            toggle={() => this.toggleModal()}
        ></AddLeadListPopup>
        break;
      default:
      case 1:
        currentView = (
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>ADD LEADS</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            <div className="PopupBody">
              <button
                className="AddUserFullWidth"
                style={{ marginTop: '15px' }}
                onClick={() => {
                  this.toggleModal()
                  this.switchStepHandler(3);
                }}
              >
                ADD MANUALLY
              </button>

              <button
                className="AddUserFullWidth"
                style={{ marginTop: '15px' }}
                onClick={() => {
                  this.toggleModal()
                  this.switchStepHandler(2);
                }}
              >
                BULK UPLOAD
              </button>
            </div>
          </div>
        );
        break;
    }

    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        {currentView}
      </Popup>
    );
    return (
      <div style={{ display: 'flex', position: "absolute", right: "16px" }}>
        {this.state.processing && <PreLoader />}
        <span>
          <button
            className="Otherbutton"
            style={{ marginRight: 0, background: "#07aa3f", color: 'white' }}
            onClick={this.openPopup}
            disabled={!isSuperAdminWithPermission('ADD')}
          >
            ADD LEADS
          </button>
          {popper}
        </span>
        <span>
          <RemoveLeadsPopup campaignId= {this.props.campaignId}/>
        </span>
      </div>
    );
  }
}

export default AddLeads;

import React from 'react';
import styled from '@emotion/styled';
import Logo from '../../../assets/images/alohaa_logo.svg';
import Browser from '../../../assets/images/browser.svg';
import RedirectIcon from '../../../assets/images/redirectIcon.png';

import '../../../assets/css/maintainancePage.css';
import Maintainance from '../../../assets/images/maintainance.png';

const Container = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.p`
  font-size: 25px;
  font-weight: 600;
  margin: 0;
`;

const Link = styled.a`
  font-size: 25px;
  font-weight: 600;
  color: #196cca;
  margin-bottom: 80px;
`;

const Button = styled.div`
  width: 374px;
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ed3833;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
`;

export default function WebsiteShiftedPage(props) {
  return (
    <Container>
      <img src={Logo} alt="Logo" style={{ marginBottom: '60px' }} />
      <img src={Browser} alt="Browser" style={{ marginBottom: '40px' }} />
      <Text>Alohaa has a new home at</Text>
      <Link href="https://business.alohaa.ai/" target="_blank" rel="noreferrer">
        business.alohaa.ai
      </Link>
      <Button
        onClick={() => {
          window.open('https://business.alohaa.ai/', '_blank');
        }}
      >
        <p></p>
        <p style={{ margin: '0px' }}>Go</p>
        <img
          src={RedirectIcon}
          alt="redirect-icon"
          style={{ marginRight: '16px' }}
        />
      </Button>
    </Container>
  );
}

import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import Button from '../../../common/component/button/Button';
import CsvDownloader from 'react-csv-downloader';
import { useEffect, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
import AddAgent from '../component/AddAgent';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import downloadIconGreen from '../../../assets/images/downloadIconGreen.png';
import cancelIcon from '../../../assets/images/cancelIconCircleGreen.png';
import CallHistoryPopup from '../component/CallHistoryPopup';
import DataCard from '../component/DataCard';
import DataTable from '../../../common/component/table/DataTable';
import ExportAgentCampaignReport from '../component/ExportAgentCampaignReport';
import AudioPlayer from '../component/AudioPlayer';
import PreLoader from '../../../common/component/preLoader/PreLoader';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;

function CampaignReportTable(props) {
  const getStatus = (status) => {
    let res = "";
    if (status) {
      if (status === 'not_answered') {
        res = "Not Answered";
      }
      if (status === 'answered') {
        res = "Answered";
      }
    }
    return res;
  }
  return (
    <DataTable.Table>
      <DataTable.Head>
        <DataTable.CellHead width="20">#</DataTable.CellHead>
        <DataTable.CellHead width="60">LEAD NAME</DataTable.CellHead>
        <DataTable.CellHead width="40">LEAD NUMBER</DataTable.CellHead>
        <DataTable.CellHead width="90">LAST CALL STATUS</DataTable.CellHead>
        <DataTable.CellHead width="100">
          LAST CALL DURATION
        </DataTable.CellHead>
        <DataTable.CellHead width="100">DATE AND TIME</DataTable.CellHead>
        <DataTable.CellHead width="60">LAST CALL DISPOSITION</DataTable.CellHead>
        <DataTable.CellHead width="60" style={{ textAlign: 'end' }}>HISTORY</DataTable.CellHead>
        <DataTable.CellHead width="160"  style={{ textAlign: 'end', paddingRight: "15px" }}>
          CALL RECORDING
        </DataTable.CellHead>
      </DataTable.Head>
      <DataTable.Body>
        {props?.agentsList?.length > 0 &&
          props?.agentsList?.map((agent, index) => (
            <DataTable.Row id={agent.userId}>
              <DataTable.Cell width="20">{1 + index}</DataTable.Cell>
              <DataTable.Cell width="60">{agent?.leadName}</DataTable.Cell>
              <DataTable.Cell width="40">{agent?.destinationNumber}</DataTable.Cell>
              <DataTable.Cell width="90">{getStatus(agent?.status)}</DataTable.Cell>
              <DataTable.Cell width="100">{agent?.duration}</DataTable.Cell>
              <DataTable.Cell width="100">{new Date(agent?.createdAt).toLocaleString()}</DataTable.Cell>
              <DataTable.Cell width="60">{agent?.callTag || "-"}</DataTable.Cell>
              <DataTable.Cell width="60" style={{ textAlign: 'end' }}>
                <CallHistoryPopup campaignId={props.campaignId} destinationNumber={agent?.destinationNumber} />
              </DataTable.Cell>
              <DataTable.Cell width="160"  style={{ textAlign: 'end', paddingRight: "15px"}}>
                <AudioPlayer
                  callRecordingUrl={agent?.recording}
                  callId={agent._id}
                />
              </DataTable.Cell>
            </DataTable.Row>
          ))}
      </DataTable.Body>
    </DataTable.Table>
  );
}

export function RecoveryXCampaignView(props) {
  const [campaignData, setCampaignData] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const agentId = searchParams.get('agentId');
  const campaignId = searchParams.get('campaignId');

  const handleFetchCampaignDetails = (agentId, campaignId) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
      agentId,
    };
    setLoading(true);
    GET(outGoingUrl + 'v1/organisations/outbound-call/get-agent-campaign-calls', data)
      .then((res) => {
        console.log('res', res.data.response);
        setCampaignData(res.data.response);
        setLoading(false);
      })
      .catch((err) => { console.log(err); setLoading(false); });
  };

  useEffect(() => {
    if (agentId && campaignId) {
      handleFetchCampaignDetails(agentId, campaignId);
    }
  }, [agentId, campaignId]);

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        {loading && <PreLoader />}
        {!loading && <div className="ContentContainer">

          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => history.goBack()}
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {campaignData?.campaignName ? `${campaignData?.campaignName} / ${campaignData?.agentName}` : 'name'}
              </p>
              <ExportAgentCampaignReport
                campaignName={campaignData?.campaignName}
                agentName={campaignData?.agentName}
                campaignData={campaignData?.agentCalls} />
            </div>
          </TabSearchBar>
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            <DataCard
              title="Calls"
              count={campaignData?.totalCalls}
            />
            <DataCard
              title="Connected Calls"
              count={campaignData?.answeredCalls}
            />
            {/* <DataCard
              title="Calls Unanswered"
              count={campaignData?.unAnsweredCalls}
            />
            <DataCard
              title="Call Answer Rate (%)"
              count={campaignData?.answerPercentage}
            /> */}
          </div>

          <CampaignReportTable agentsList={campaignData?.agentCalls} campaignId={campaignId} />
        </div>}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(RecoveryXCampaignView));

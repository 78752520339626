import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';
const broadcastUrl = process.env.REACT_APP_BROADCAST_API;

export default function ExportMessageBroadcastPopup(props) {
  const [list, setList] = useState([]);
  const [broadcastName, setBroadcastName] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';
  let columns = [
    { id: 'number', displayName: "LEAD'S NUMBER" },
    { id: 'status', displayName: 'STATUS' },
  ];

  const openPopup = () => {
    setIsPopupOpen(true);
    GET(`${broadcastUrl}v1/sms`, {
      id: props.id,
      pageNo: 1,
      pageSize: 50000,
    })
      .then((res) => {
        return res.data.response.broadcast;
      })
      .then((res) => {
        setList(res.call_details);
        setBroadcastName(res.name);
        console.log('-----', res.name);
        setDataFetched(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <button
        className="Otherbutton"
        style={{ backgroundColor: '#07aa3f', color: 'white' }}
        onClick={openPopup}
      >
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download Sms logs</p>
            <img
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsPopupOpen(false);
                setDataFetched(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file.
            </p>
            <p className="PopupNote">
              Max download limit - Recent 50,000 entries
            </p>
            {dataFetched ? (
              <CsvDownloader
                filename={`${broadcastName}_${new Date().toLocaleDateString()}`}
                separator=","
                columns={columns}
                datas={
                  list.length
                    ? list.map((detail) => {
                        return {
                          number: detail?.number?.leadNumber,
                          status: detail?.number?.status || '-',
                          // started_at: detail?.call_started_at
                          //   ? new Date(
                          //     detail.call_started_at
                          //   ).toLocaleDateString()
                          //   : '-',
                          // ended_at: detail?.call_ended_at
                          //   ? new Date(
                          //     detail.call_ended_at
                          //   ).toLocaleDateString()
                          //   : '-',
                        };
                      })
                    : [{}]
                }
                className="ButtonFullWidth BgGreen"
                text="Download CSV"
              />
            ) : (
              <button
                className="ButtonFullWidth BgGrey"
                style={{ cursor: 'default' }}
              >
                SAVE
              </button>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}

import React, { useRef, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;

export default function ExportDIDCallBackLogsPopup() {
  const [list, setList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const csvLink = useRef(null);

  let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';
  let columns = [
    { id: 's_mobile_no', displayName: 'CALLER' },
    { id: 'call_status_text', displayName: 'CALL STATUS' },
    { id: 'zvr_name', displayName: 'AGENT NAME' },
    { id: 'v_mobile_no', displayName: 'DID' },
    { id: 'call_duration', displayName: 'DURATION' },
    { id: 'created_at_date', displayName: 'DATE' },
    { id: 'created_at_time', displayName: 'TIME' },
  ];

  const openPopup = () => {
    setIsPopupOpen(true);
    setFetching(false);
    setFromDate(null);
    setToDate(null);
  };

  const fetchDataHandler = async () => {
    try {
      if (fetching) return;
      setFetching(true);
      const res = await GET(
        `${incomingCallsUrl}v1/organisations/did-callback-list`,
        {
          pageNo: 1,
          pageSize: 1000,
          organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
          searchKey: 'date',
          fromDate: fromDate,
          toDate: toDate,
        }
      );
      setList(res.data.response.payload.docs);
      csvLink.current.handleClick();
      setFetching(false);
      setIsPopupOpen(false);
    } catch (err) {
      console.log(err);
      setFetching(false);
    }
  };

  return (
    <div>
      <button className="Otherbutton" onClick={openPopup}>
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download callback logs</p>
            <img
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsPopupOpen(false);
                setFetching(false);
                setFromDate(null);
                setToDate(null);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file.
            </p>
            <div style={{ margin: '8px 0 0 40px' }}>
              <p style={{ margin: 0 }}>From Date</p>
              <input
                type="date"
                onChange={(e) => setFromDate(e.target.value)}
                value={fromDate}
              />
            </div>
            <div style={{ margin: '8px 0 0 40px' }}>
              <p style={{ margin: 0 }}>To Date</p>
              <input
                min={fromDate}
                disabled={!fromDate}
                type="date"
                onChange={(e) => setToDate(e.target.value)}
                value={toDate}
              />
            </div>
            <p className="PopupNote">
              Max download limit - Recent 1000 entries
            </p>
            <CsvDownloader
              filename={`${org_name}_did_callback_list_${new Date().toLocaleDateString()}`}
              separator=","
              columns={columns}
              ref={csvLink}
              datas={
                list.length
                  ? list.map((detail) => {
                    return {
                      ...detail,
                      created_at_date: new Date(
                        detail.created_at
                      ).toLocaleDateString(),
                      call_status_text: `${detail.call_status_text ? detail.call_status_text : ''
                        }`,
                      created_at_time: new Date(
                        detail.created_at
                      ).toLocaleTimeString(),
                    };
                  })
                  : [{}]
              }
              text=""
              style={{ display: 'none' }}
            />

            <button
              className={
                !fromDate || !toDate
                  ? 'ButtonFullWidth BgGrey'
                  : 'ButtonFullWidth'
              }
              style={
                !fromDate || !toDate || fetching ? { cursor: 'default' } : null
              }
              disabled={!fromDate || !toDate}
              onClick={fetchDataHandler}
            >
              {fetching ? 'Loading ...' : 'Download CSV'}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

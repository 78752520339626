
export const startRun = (data) => dispatch => {
    console.log("START_RIDE");
    dispatch({
      type: "START_RIDE",
      payload: data,
    })
  }
  export const EndRun = (data) => dispatch => {
    console.log("END_RIDE");
    dispatch({
      type: "END_RIDE",
      payload: data,
    })
  }
  export const increaseStepIndex = (data) => dispatch => {
    console.log("increaseStepIndex");
    dispatch({
      type: "INCREASE_STEP_INDEX",
      payload: data,
    })
  }
  export const decreaseStepIndex = (data) => dispatch => {
    console.log("increaseStepIndex");
    dispatch({
      type: "DECREASE_STEP_INDEX",
      payload: data,
    })
  }
  export const setStepIndexToZero = (data) => dispatch => {
    console.log("STEP_INDEX_TO_ZERO");
    dispatch({
      type: "STEP_INDEX_TO_ZERO",
      payload: data,
    })
  }
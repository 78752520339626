import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import { MinusRedIcon } from '../../../assets/Icons/Icons';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { POST, PUT } from '../../../services/HttpRequests';
import { isSuperAdminWithPermission } from '../../../utility/utility';
const URL = process.env.REACT_APP_SAMPLE_FILE_URL;
const outGoingUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

class PopTwo extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      dispositonScore: '',
      number: '',
      email: '',
      step: 1,
      csvFile: null,
      error: '',
      dispositionData: '',
    };
    this.initialState = this.state;
  }

  openPopup = () => {
    this.setState({ popupOpen: true });
  };
  closePopup = () => {
    this.setState(this.initialState);
  };
  inputHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  fileUploadHandler = (e) => {
    this.setState({ csvFile: e.target.files[0] });
  };

  switchStepHandler = (step) => {
    this.setState({ step: step });
  };

  manualDataHandler = () => {
    if (!this.state.dispositionData) {
      toast.error('Disposition is required');
      return;
    }
    if (
      !this.state.dispositonScore ||
      isNaN(parseInt(this.state.dispositonScore))
    ) {
      toast.error('Dispositon score is required');
      return;
    }
    if (this.state.dispositonScore < 1 || this.state.dispositonScore > 5) {
      toast.error('Score should be between 1 and 5');
      return;
    }
    let data = {
      dispositionData: this.state.dispositionData,
      dispositonScore: this.state.dispositonScore,
      action: 'ADD',
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    PUT(`${outGoingUrl}disposition`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.closePopup();
        this.props.refreshList();
        toast.success('Dispositon Added');
        this.setState({
          popupOpen: false,
          dispositonScore: '',
          step: 1,
          dispositionData: '',
        });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({ error: err.response.data.error.reason });
          toast.error(err.response.data.error.reason);
        }
      });
  };

  csvUploadHandler = () => {
    let formData = new FormData();
    formData.append('users', this.state.csvFile, this.state.csvFile.name);
    formData.append(
      'organisationId',
      localStorage.getItem('doosra-biz-organisation-id')
    );

    POST(`${outGoingUrl}v1/user/bulk`, formData)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        // window.location.reload()

        this.closePopup();
        this.props.refreshList();
        toast.success(res.status);
        if (res.existing_users.length > 0)
          toast.warning(`${res.existing_users.length} duplicate(s) found`);
        if (res.un_mapped_users.length > 0)
          toast.warning(
            `${res.un_mapped_users.length} Primary number(s) cannot be mapped as Virtual number(s).`
          );
        if (res.existing_users.length > 0) {
          // eslint-disable-next-line array-callback-return
          res.existing_users.map((item) => {
            toast.error(`${item.phone_number} already exists`);
          });
        }
        if (res.un_mapped_users.length > 0) {
          // eslint-disable-next-line array-callback-return
          res.existing_users.map((item) => {
            toast.error(`${item.phone_number} not mapped`);
          });
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({ error: err.response.data.error.reason });
          toast.error(err.response.data.error.reason);
        }
      });
  };
  deleteDisposition = (item) => {
    console.log(item);
    let data = {
      dispositionData: item.dispositon,
      action: 'DELETE',
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    console.log(data);
    PUT(`${outGoingUrl}disposition`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.closePopup();
        this.props.refreshList();
        toast.success('Dispositon Removed');
        this.setState({
          popupOpen: false,
          dispositonScore: '',
          step: 1,
          dispositionData: '',
        });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({ error: err.response.data.error.reason });
          toast.error(err.response.data.error.reason);
        }
      });
  };
  dispositionsJSX = () => {
    const result = this.props.dispositions;
    return result.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item.dispositon}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {item.dispositon}
          </p>
          <p style={{ height: '28px', width: '128px', margin: '8px 92px' }}>
            <MinusRedIcon onClick={() => this.deleteDisposition(item)} />
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };

  render() {
    let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';

    let currentView;
    let columns = [
      { id: 'dispositionData', displayName: 'ENTER DISPOSITION' },
      { id: 'dispositonScore', displayName: 'ENTER DISPOSITION SCORE' },
    ];
    switch (this.state.step) {
      case 3:
        currentView = (
          <div className="PopupContainer" style={{ overflow: 'auto' }}>
            <div className="PopupHeaderContainer">
              <p>Add Disposition</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            <div className="PopupBody">
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Disposition</label>
                <input
                  className="PopupInput"
                  id="dispositionData"
                  value={this.state.dispositionData}
                  onChange={this.inputHandler}
                />
              </div>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Disposition Score</label>
                <input
                  className="PopupInput"
                  id="dispositonScore"
                  value={this.state.dispositonScore}
                  onChange={this.inputHandler}
                />
              </div>
              {this.state.error ? (
                <div className="ErrorContainer ErrorContainerMargin">
                  <img
                    alt=""
                    src={InfoRed}
                    style={{
                      paddingRight: '5px',
                      position: 'relative',
                      top: '-2px',
                    }}
                  />
                  {this.state.error}
                </div>
              ) : null}
              <button
                className="ButtonFullWidth BgGreen"
                onClick={this.manualDataHandler}
                style={{ marginTop: '20px' }}
              >
                SAVE
              </button>
            </div>
          </div>
        );
        break;
      case 2:
        currentView = (
          <div className="PopupContainer" style={{ height: '530px' }}>
            <div className="PopupHeaderContainer">
              <p>Dispositions</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            {this.props.dispositions?.length > 0 && (
              <>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '60% 40%',
                    textAlign: 'center',
                    fontSize: '12px',
                    'letter-spacing': '1.2px',
                  }}
                  className="PopupColumnHeaders"
                >
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'left',
                      paddingLeft: '20px',
                    }}
                  >
                    Disposition
                  </p>
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    REMOVE
                  </p>
                </div>
                <div
                  style={{
                    maxHeight: '410px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  {this.dispositionsJSX()}
                </div>
              </>
            )}
          </div>
        );
        break;
      default:
      case 1:
        currentView = (
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>Dispositions</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            <div className="PopupBody">
              <button
                className="AddUserFullWidth"
                style={{ marginTop: '15px' }}
                onClick={() => this.switchStepHandler(3)}
              >
                ADD DISPOSITION
              </button>
              <button
                className="AddUserFullWidth"
                style={{ marginTop: '15px' }}
                onClick={() => this.switchStepHandler(2)}
              >
                VIEW DISPOSITIONS
              </button>
            </div>
          </div>
        );
        break;
    }

    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        {currentView}
      </Popup>
    );
    return (
      <div style={{ display: 'flex' }}>
        <span>
          <button
            className="Otherbutton"
            style={{ marginRight: 0 }}
            onClick={this.openPopup}
            disabled={!isSuperAdminWithPermission('ADD')}
          >
            DISPOSITIONS
          </button>
          {popper}
        </span>
      </div>
    );
  }
}

export default PopTwo;

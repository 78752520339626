import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import Notes from './Notes';
import Tags from './Tags';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import {
  ADD_CONTACT_BUTTON,
  SAVE_CONTACT_BUTTON,
  ADD_ANOTHER_CONTACT_BUTTON,
  ADMIN_ADD_ANOTHER_CONTACT_BUTTON,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';

const ContactInput = (props) => {
  return (
    <div key={props.index} style={{ height: '62px' }}>
      <input
        className="PopupInput"
        id="emp_id"
        value={props.number}
        onChange={(e) => props.updateNumber(e.target.value)}
      />
      <img
        alt=""
        style={{ position: 'relative', top: '-45px', left: '328px' }}
        onClick={props.removeHandler}
        src={Delete}
      />
    </div>
  );
};

export default function AddContactPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [contactName, setContactName] = useState('');
  const [contactNumbers, setContactNumbers] = useState(['']);
  const [error, setError] = useState(null);
  const [showNotes, setShowNotes] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [loading, setLoading] = useState(false);

  const userRole = localStorage.getItem('doosra-biz-user-role');

  const removeNumberHandler = (i) => {
    const updatedList = [...contactNumbers];
    updatedList.splice(i, 1);
    setContactNumbers(updatedList);
  };

  const addNumberHandler = () => {
    logPostHogEvent(
      userRole === 'ADMIN'
        ? ADMIN_ADD_ANOTHER_CONTACT_BUTTON
        : ADD_ANOTHER_CONTACT_BUTTON
    );
    // if (contactNumbers[contactNumbers.length - 1] === '') return;
    const updatedList = [...contactNumbers, ''];
    setContactNumbers(updatedList);
  };

  const updateContactNumbers = (index, value) => {
    const list = [...contactNumbers];
    list[index] = value;
    setContactNumbers(list);
  };

  const addContactHandler = async () => {
    logPostHogEvent(SAVE_CONTACT_BUTTON);
    let wrongNos = false;
    contactNumbers.forEach((no) => {
      if (!no.match('^[0-9]{10}$')) {
        setError('Please enter 10 digit number');
        wrongNos = true;
      }
    });
    if (!contactName) {
      setError('Please Enter Contact Name');
      wrongNos = true;
    }
    if (!wrongNos) {
      setLoading(true);
      await props
        .addContactHandler({
          name: contactName,
          phoneNumbers: contactNumbers,
          organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        })
        .then((res) => {
          console.log({ res });
          setContactId(res?.data?.response?.contact?._id);
          if (res?.data?.success === true) {
            setLoading(false);
            toast.success('Add Contact Successfully');
            setError(null);
            wrongNos = false;
            setShowNotes(true);
          }
        })
        .catch((err) => {
          toast.error('Contact Not Added');
          console.log('addContactHandler:err', { err });
          setShowNotes(false);
          setError(null);
          wrongNos = false;
          setLoading(false);
        });
    }
  };

  const closePopup = async () => {
    setContactName('');
    setContactNumbers(['']);
    await props.getList();
    setPopupOpen(false);
  };

  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div
        className="PopupContainer"
        style={{
          maxHeight: '600px',
          overflow: 'scroll',
          marginBottom: 30,
        }}
      >
        <div className="PopupHeaderContainer">
          <p>Add Contact Profile</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => {
              closePopup();
            }}
          />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Contact Name</label>
            <input
              className="PopupInput"
              id="emp_id"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              key="contactname"
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Contact Numbers</label>
            {contactNumbers?.length > 0 &&
              contactNumbers.map((item, index) => (
                <ContactInput
                  number={item}
                  index={index}
                  updateNumber={(number) => updateContactNumbers(index, number)}
                  removeHandler={() => removeNumberHandler(index)}
                />
              ))}
            <div style={{ display: 'flex' }}>
              <div className="ContactAddBtn">
                {' '}
                <a onClick={addNumberHandler}> +Add another number </a>
              </div>
              <div
                onClick={(e) => {
                  addContactHandler();
                }}
                className="ContactSaveBtn"
              >
                Save Contact
              </div>
            </div>
          </div>

          {error ? (
            <div className="ErrorContainer ErrorContainerMargin">
              <img
                alt=""
                src={InfoRed}
                style={{
                  paddingRight: '5px',
                  position: 'relative',
                  top: '-2px',
                }}
              />
              {error}
            </div>
          ) : null}
          {loading && <PreLoader />}

          {showNotes && (
            <>
              <Tags contactId={contactId} action="ADD" />
              <Notes contactId={contactId} action="ADD" />
            </>
          )}
          <button
            className="ButtonCancelFullWidth"
            onClick={() => {
              closePopup();
            }}
            style={{ marginTop: 30 }}
          >
            CLOSE
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex' }}>
      <span>
        <button
          className="Otherbutton"
          style={{ marginRight: 0 }}
          onClick={() => {
            logPostHogEvent(ADD_CONTACT_BUTTON);
            setPopupOpen(true);
            setContactName('');
            setContactNumbers(['']);
            setShowNotes(false);
          }}
        >
          ADD CONTACT
        </button>
        {popper}
      </span>
    </div>
  );
}

import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
import AddCallRemarksPopup from '../component/AddCallRemarksPopup';
import phoneWhite from '../../../assets/images/phoneWhiteRecoveryCall.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { GET, POST } from '../../../services/HttpRequests';
import { useSocket } from "../../../providers/SocketProvider";
import SideModal from '../../recoveryCalling/component/SideModal';
import DataCard from '../component/DataCard';
import DataTable from '../../../common/component/table/DataTable';
import CallHistoryPopup from '../component/CallHistoryPopup';
import WebRTCCallPopup from "../component/WebRTCCallPopup";
import {
  setCallDetails,
  setBatchCallId,
  setCallId,
  setCallStatus,
  toggleAutoCalling,
  setAutoCalling,
  setSingleCallStatus,
  setDestinationName,
  setDestinationNumber,
  setRTCSession,
  setCallFilterLeads,
  setRTCCallStatus,
  setCampaignId,
} from "../../../actions/recoveryXActions"
import moment from 'moment';
import PreLoader from '../../../common/component/preLoader/PreLoader';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;

export function RecoveryXAgentCampaignView(props) {
  const [campaignData, setCampaignData] = useState({});
  const [campaignCallsData, setCampaignCallsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dispositions, setDispositions] = useState([]);
  const [dataSearchKey, setDataSearchKey ]= useState("");
  const [dataSearchValue, setDataSearchValue ]= useState("");
  const history = useHistory();
  const location = useLocation();
  var audio = new Audio();
  const searchParams = new URLSearchParams(location.search);
  const agentId = searchParams.get('agentId');
  const campaignId = searchParams.get('campaignId');
  let socket = useSocket();
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const [WebRTCEnabled, SetWebRTCEnabled] = useState(JSON.parse(localStorage.getItem("is_webrtc_enabled")));
  let {
    userDetails,
    callStatus,
    position,
    autoCalling,
    batchCallId,
    callId,
    // singleCallStatus,
    // RTCSession,
    // destinationName,
    // destinationNumber,
    // callFilterLeads
  } = props;
  let singleCallStatus = useSelector((state) => state.recovery.singleCallStatus);
  let callFilterLeads = useSelector((state) => state.recovery.callFilterLeads);
  let destinationName = useSelector((state) => state.recovery.destinationName)
  let destinationNumber = useSelector(state => state.recovery.destinationNumber)
  let RTCSession = useSelector(state => state.recovery.RTCSession)
  let RTCcallStatus = useSelector(state => state.recovery.RTCcallStatus);
  let campaignUniqueId = useSelector(state => state.recovery.campaignId)

  const handleFetchCampaignDetails = (agentId, campaignId, searchKey = null, searchValue = null) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
      agentId,
    };
    if(searchKey && searchValue) {
      if(searchKey === "leadNumber") {
        data.searchKey = "destinationNumber"
      } else {
        data.searchKey = searchKey;
      }
      data.searchValue = searchValue;
    }
    if (dataSearchKey && dataSearchValue) {
      if(dataSearchKey === "leadNumber") {
        data.searchKey = "destinationNumber"
      } else {
        data.searchKey = dataSearchKey;
      }
      data.searchValue = dataSearchValue;
    }
    GET(outGoingUrl + 'v1/organisations/outbound-call/get-agent-campaign-calls', data)
      .then((res) => {
        setCampaignData(res.data.response)
        setCampaignCallsData(res.data.response.agentCalls)
        props.setCallFilterLeads({ callFilterLeads: res.data.response.agentCalls })
      })
      .catch((err) => console.log(err));
  };

  const resetHandleFetchCampaignDetails = (agentId, campaignId) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
      agentId,
    };
    GET(outGoingUrl + 'v1/organisations/outbound-call/get-agent-campaign-calls', data)
      .then((res) => {
        setCampaignData(res.data.response)
        setCampaignCallsData(res.data.response.agentCalls)
        props.setCallFilterLeads({ callFilterLeads: res.data.response.agentCalls })
      })
      .catch((err) => console.log(err));
  }

  const tempFunction = (destinationNumber, status, duration) => {
    console.log({ destinationNumber, status, duration });
    console.log("the call filter leads in the temp function are", callFilterLeads);
    const tempLeadsData = callFilterLeads.map((lead) => {
      console.log("the lead obj is ", lead);
      if (lead.destinationNumber === destinationNumber) {
        lead.status = status;
        lead.duration = duration
      }
      return lead;
    })
    console.log("the temp leads data is ", tempLeadsData)
    setCampaignCallsData(tempLeadsData)
  }

  useEffect(() => {
    if (agentId && campaignId) {
      handleFetchCampaignDetails(agentId, campaignId);
      getDispositions();
    }
  }, [agentId, campaignId]);

  useEffect(() => {
    if (socket) {
      socket.on("test", (message) => {
        console.log("RECEIVED THE TEST MESSAGE", message)
        props.setCallDetails(message)
        props.setDestinationName({ destinationName: message.userDetails });
        props.setDestinationNumber({ destinationNumber: message.position });
      });
      socket.on("changeStatus", (message) => {
        props.setCallStatus({ status: message });
        props.setRTCCallStatus({ RTCcallStatus: message });
        // if(WebRTCEnabled) {
        //   if(message === "Connected") {
        //      props.setRTCCallStatus({RTCcallStatus:"Connected"})
        //   } else { props.setRTCCallStatus({RTCcallStatus:"Automated calling paused for 30 sec"})}
        // }
      });
      socket.on("setBatchCallId", (message) => {
        props.setCampaignId(message);
      });
      socket.on("toggleButton", () => {
        props.toggleAutoCalling({ autoCalling: false })
      });
      socket.on("callId", (message) => {
        props.setCallId({ callId: message });
      })
      socket.on("changeSingleCallStatus", (message) => {
        props.setSingleCallStatus({ singleCallStatus: false });
      })

      socket.on("changeSingleCallDetails", (message) => {
        // let isUpdated = isCallDetailsUpdated;
        // if(!isUpdated) {
        //   setIsCallDetailsUpdated(true);
        const { destinationNumber, status, duration } = message;
        //   const tempLeadsData = leadListContacts.map( lead => { if(lead.number === destinationNumber) {
        //     lead.status = status ? status : "-",
        //     lead.duration = duration ? duration : "-"}
        // return lead;})
        // setLeadListContacts(tempLeadsData);
        // }
        tempFunction(destinationNumber, status, duration)
      })
    }
  }, [socket, callFilterLeads])

  const initiateRecoveryCall = (destinationNumber, name, campaignId, callId) => {
    if (callId) {
      toast.error("There is an Active call");
      return;
    }
    if (localStorage.getItem("doosra-biz-user-number") === destinationNumber) {
      toast.error("Cannot Call To The Same Number");
      return;
    }
    let data = {
      number: destinationNumber,
      name,
      campaignId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      isRecoveryCall: true,
      isIndividualCall: true,
    };
    props.setSingleCallStatus({ singleCallStatus: true });
    POST(outGoingUrl + 'v1/campaigns/recovery', data)
      .then((res) => {
        console.log("setting the single call statsus to true");
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  function CampaignReportTable(props) {
    const getStatus = (status) => {
      let res = "";
      if (status) {
        if (status === 'not_answered') {
          res = "Not Answered";
        }
        if (status === 'answered') {
          res = "Answered";
        }
      }
      return res;
    }
    return (
      <div>
        <DataTable.Table style={{ width: "100%" }}>
          <DataTable.Head>
            <DataTable.CellHead width="20">#</DataTable.CellHead>
            <DataTable.CellHead width="60">LEAD NAME</DataTable.CellHead>
            <DataTable.CellHead width="40">LEAD NUMBER</DataTable.CellHead>
            <DataTable.CellHead width="50">LAST CALL STATUS</DataTable.CellHead>
            <DataTable.CellHead width="50">
              LAST CALL DURATION (s)
            </DataTable.CellHead>
            <DataTable.CellHead width="60">DATE</DataTable.CellHead>
            <DataTable.CellHead width="60">TIME</DataTable.CellHead>
            <DataTable.CellHead width="60">DISPOSITION</DataTable.CellHead>
            <DataTable.CellHead width="60">DISPOSITION COMMENT</DataTable.CellHead>
            <DataTable.CellHead width="50" style={{ textAlign: 'end' }}>
              HISTORY
            </DataTable.CellHead>
            <DataTable.CellHead width="50" style={{ textAlign: 'end' }}>
              ACTIONS
            </DataTable.CellHead>
          </DataTable.Head>
          <DataTable.Body>
            {props?.agentsList?.length > 0 &&
              props?.agentsList?.map((agent, index) => (
                <DataTable.Row id={agent.userId}>
                  <DataTable.Cell>{1 + index}</DataTable.Cell>
                  <DataTable.Cell>{agent?.leadName}</DataTable.Cell>
                  <DataTable.Cell>{agent?.destinationNumber}</DataTable.Cell>
                  <DataTable.Cell>{getStatus(agent?.status)}</DataTable.Cell>
                  <DataTable.Cell>{agent?.duration}</DataTable.Cell>
                  <DataTable.Cell>{new Date(agent?.createdAt).toLocaleDateString()}</DataTable.Cell>
                  <DataTable.Cell>{new Date(agent?.createdAt).toLocaleTimeString()}</DataTable.Cell>
                  <DataTable.Cell>{agent?.callTag}</DataTable.Cell>
                  <DataTable.Cell>{agent?.callComment}</DataTable.Cell>
                  <DataTable.Cell>
                    <CallHistoryPopup campaignId={campaignId} destinationNumber={agent?.destinationNumber}  ></CallHistoryPopup>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <div style={{ display: "flex", placeContent: "flex-end" }}>
                      <div>
                        <AddCallRemarksPopup
                          agentHandleDisposition={agent?.status.toLowerCase() === 'not_initiated'}
                          agentId={agentId}
                          handleFetchCampaignDetails={handleFetchCampaignDetails}
                          dispositions={dispositions}
                          number={agent?.destinationNumber}
                          campaignId={campaignId}
                          isButtonDisabled = {(agent.callTag!== undefined  && agent.callTag !== "" && agent.callTag !== "RNR") ? true : false}
                        />
                      </div>
                      <div className="tooltip-container">
                        <div className="tooltip-button">
                          <button
                            className="ActivateButton"
                            onClick={() => (props.isValidCampaign && props.isActive) ? initiateRecoveryCall(agent?.destinationNumber, agent?.leadName, props.campaignId, props.callId) : null}
                            style={{
                              width: "32px", height: "32px", backgroundColor: "#07aa3f",
                              opacity: ((props.isValidCampaign && props.isActive) ? 1 : 0.5),
                              cursor: ((props.isValidCampaign && props.isActive) ? "pointer" : "default")
                            }}
                          >
                            <img
                              alt=""
                              src={phoneWhite}
                              className="SidebarItemIcon"
                              style={{ width: "16px", height: "16px" }}
                            />
                          </button>
                        </div>
                        <div className="tooltip-text left" data-tip-position="left">
                          {props.isActive ? (props.isValidCampaign ?
                            "Start Call" :
                            "campaign is not valid") : "You are in Inactive Status"}
                          < span className="arrow-right"></span>
                        </div>
                      </div>
                    </div>
                  </DataTable.Cell>
                </DataTable.Row>
              ))}
          </DataTable.Body>
        </DataTable.Table>
      </div>
    );
  }
  const muteCall = () => {
    if (!RTCSession) {
      toast.error("No active calls");
      return;
    }
    RTCSession.mute();
  }

  // UnMute the call
  const unMuteCall = () => {
    if (!RTCSession) {
      toast.error("No active calls");
      return;
    }
    RTCSession.unmute();
  }

  // Hold the call
  const holdCall = () => {
    if (!RTCSession) {
      toast.error("No active calls");
      return;
    }
    RTCSession.hold();
    props.setRTCCallStatus({ RTCcallStatus: 'call-on-hold' });
  };

  // Resume the call
  const resumeCall = () => {
    if (!RTCSession) {
      toast.error("No active calls");
      return;
    }
    RTCSession.unhold();
    props.setRTCCallStatus({ RTCcallStatus: 'connected' });
  };

  // End the call
  const endCall = () => {
    console.log("in the end call");
    if (!RTCSession) {
      resetValues();
      return;
    }
    RTCSession.terminate();
    resetValues();

  }

  const getDispositions = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(
      outGoingUrl + 'v1/organisations/outbound-call/get-dispositions',
      data
    )
      .then((res) => {
        setDispositions(res.data.response.dispositions);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const fetchWithFilter = (filter) => {
    setDataSearchKey(filter.searchKey);
    setDataSearchValue(filter.searchCondition)
    handleFetchCampaignDetails(agentId, campaignId, filter.searchKey, filter.searchCondition)
  }

  const reset = (agentId, campaignId) => {
    setDataSearchKey("");
    setDataSearchValue("");
    resetHandleFetchCampaignDetails(agentId, campaignId)
  }

  const resetValues = () => {
    props.setRTCCallStatus({ RTCcallStatus: "Call Connecting" })
    props.setSingleCallStatus({ singleCallStatus: false });
    localStorage.setItem("singleCallStatus", false);
    props.setRTCSession({ RTCSession: null });
    props.setDestinationNumber({ destinationNumber: "" });
    props.setDestinationName({ destinationName: "" });
    audio.remove();
    props.setCallId({ callId: "" })
  }

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        {
          // For WebRTC Call Popup
          (userRole === "USER" && (campaignUniqueId ? true : false || singleCallStatus) && WebRTCEnabled) ?
            <WebRTCCallPopup
              isOpen={WebRTCEnabled ? (campaignUniqueId ? true : false || singleCallStatus) : false}
              muteCall={muteCall}
              unmuteCall={unMuteCall}
              holdCall={holdCall}
              resumeCall={resumeCall}
              endCall={endCall}
              RTCcallStatus={RTCcallStatus}
              number={destinationNumber}
              name={destinationName}
              isSingleCall={singleCallStatus}
              batchCallId={batchCallId}
              value={autoCalling}
            >
            </WebRTCCallPopup> : null
        }
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                alt=""
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/campaigns')}
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {campaignData?.campaignName ? `${campaignData?.campaignName} Logs` : ''}
                {/* {campaignData?.campaignName ? `${campaignData?.campaignName} - ${campaignData?.agentName}` : 'name'} */}
              </p>
            </div>
            <SearchBar
              type="campaign-agent"
              search={(e) => fetchWithFilter(e)}
              reset={(e) => reset(agentId, campaignId)}
            ></SearchBar>

          </TabSearchBar>
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            <DataCard
              title="Calls"
              count={campaignData?.totalCalls}
            />
            <DataCard
              title="Connected Calls"
              count={campaignData?.answeredCalls}
            />
            {/* <DataCard
              title="Calls Unanswered"
              count={campaignData?.unAnsweredCalls}
            />
            <DataCard
              title="Call Answer Rate (%)"
              count={campaignData?.answerPercentage}
            /> */}
          </div>
          {loading && <PreLoader />}
          {!loading && <CampaignReportTable isActive={campaignData.isActive} isValidCampaign={campaignData.isValidCampaign} agentsList={campaignCallsData} campaignId={campaignId} callId={callId} />}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isReocveryXEnabled: state.topbar.isReocveryXEnabled,
  isNumberMaskingEnabled: state.topbar.isNumberMaskingEnabled,
  userDetails: state.recovery.userDetails,
  callStatus: state.recovery.callStatus,
  position: state.recovery.position,
  autoCalling: state.recovery.autoCalling,
  batchCallId: state.recovery.batchCallId,
  // destinationNumber: state.recovery.destinationNumber,
  // destinationName: state.recovery.destinationName,
  // RTCSession: state.recovery.RTCSession,
  callId: state.recovery.callId,
  callFilterLeads: state.recovery.callFilterLeads,
  singleCallStatus: state.recovery.singleCallStatus,
  // RTCcallStatus: state.recovery.RTCcallStatus,
  isRecoveryXEmailSent: state.topbar.isRecoveryXEmailSent,
});

export default commonStyle(connect(mapStateToProps, {
  setCallDetails,
  setBatchCallId,
  setCallId,
  setCallStatus,
  setAutoCalling,
  toggleAutoCalling,
  setSingleCallStatus,
  setDestinationNumber,
  setDestinationName,
  setRTCSession,
  setCallFilterLeads,
  setRTCCallStatus,
  setCampaignId
})(RecoveryXAgentCampaignView));

import React, { useState } from 'react';
import Logo from '../../../assets/images/alohaaLogoAndBottomText.png';
import GreenTick from '../../../assets/images/greenTickSmall.png';
import InfoRed from '../../../assets/images/infoRed.png';
import { useAuth } from '../../../providers/AuthProvider';
import { POST } from '../../../services/HttpRequests';
import { generateRecaptcha } from '../../../services/Recaptcha';
import '../../../assets/css/LoginNew.css';
import Wall from '../../../assets/images/loginPageWall.png';
const userUrl = process.env.REACT_APP_USER_API;

function TwoFAAuthentication(props) {
  const { saveSession } = useAuth();
  const [otpResent, setOtpResent] = useState(false);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { is_2FA_enabled, secret, ...res } = props;
  let username = '';
  if (res.role === 'USER') {
    username = res.user.zvr_mobile_no;
  } else {
    username = res.user.email;
  }

  const handleOtpChange = (e) => {
    e.preventDefault();
    setOtp(e.target.value);
  };

  const verifyOrValidate2FAToken = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      if (otp) {
        const recaptcha = await generateRecaptcha('BUSINESS_LOGIN_TOKEN');
        const result = await POST(`${userUrl}v1/auth/validate-2fa-token`, {
          token: otp,
          username,
          secret,
          recaptchaToken: recaptcha,
          source: 'WEB',
        });
        console.log('login response', result.data.response);
        if (result.data.response?.free_trial === true) {
          localStorage.setItem('is_free_trial_enabled', true);
        }
        saveSession(result.data.response);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error('Require Token');
      }
    } catch (e) {
      setIsLoading(false);
      if (e.response && e.response.data)
        return setError(e.response.data.error.reason);
      setError('Invalid OTP');
    }
  };

  return (
    <div className="login-main">
      <div className="section-1">
        <div className="login-container">
          <img className="login-container-logo" alt="" src={Logo} />

          <div className="AuthHeadContainer">
            {/* <p className="DoosraBusinessHeader">
            <span className="DoosraDefaultColor"></span>alohaa
          </p> */}
            <p className="AuthSubHeader">Enter OTP</p>
            {/* <p>{!is_2FA_enabled && <QRCode value={secret} />}</p> */}
          </div>
          <form>
            <input
              className="InputBox"
              placeholder="Enter your OTP here"
              value={otp}
              id="otp"
              onChange={handleOtpChange}
            />
            <p
              className="AuthForgotPassword"
              onClick={() => {
                console.log('sdf');
                setOtpResent(true);
                props.resendOtp();
              }}
            >
              Resend-OTP
            </p>
            {otpResent && (
              <div className="OTPResent">
                <img src={GreenTick} />
                <p>OTP resent successfully</p>
              </div>
            )}
            <div className="ErrorContainer ErrorContainerMargin">
              {error ? (
                <React.Fragment>
                  <img
                    alt=""
                    src={InfoRed}
                    style={{
                      paddingRight: '5px',
                      position: 'relative',
                      top: '-2px',
                    }}
                  />
                  {error}
                </React.Fragment>
              ) : null}
            </div>

            <button
              className={`AuthButton ${isLoading ? 'ButtonLoading' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                verifyOrValidate2FAToken();
              }}
              disabled={!otp || isLoading}
            >
              <span className="ButtonText">{isLoading ? '' : 'NEXT'}</span>
              {isLoading && <span className="ButtonSpinner"></span>}
            </button>
            <button className="AuthButton AuthButtonInvert">CANCEL</button>
          </form>
        </div>
      </div>
      <div>
        <img src={Wall} style={{ height: '100vh' }} />
      </div>
    </div>
  );
}

export default TwoFAAuthentication;

/* eslint-disable */
import React, { Component } from 'react';
import styled from '@emotion/styled';

import '../../../assets/css/SearchBar.css';

class SearchBar extends Component {
  constructor() {
    super();
    this.state = {
      searchBy: 'Date',
      searchValue: '',
      fromDate: new Date().toISOString().slice(0, 10),
      toDate: new Date().toISOString().slice(0, 10),
      btnOnReset: false,
      userRole: localStorage.getItem('doosra-biz-user-role'),
    };
    this.initialState = this.state;
  }

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value, btnOnReset: false });
  };

  dropDownSelectHandler = (e) => {
    this.setState({
      searchBy: e.target.value,
      btnOnReset: false, //change to true
      
    });
  };

  onSearch = () => {
    let data = {};
    if (this.state.searchBy === '' || this.state.fromDate === '' || this.state.toDate === '') return;
    data.searchKey = this.state.searchBy;
    data.fromDate = this.state.fromDate;
    data.toDate = this.state.toDate;
    console.log(JSON.stringify(data));
    this.props.search(data);
    this.setState({ btnOnReset: true });
  };

  onReset = () => {
    this.setState(this.initialState);
    this.props.reset();
  };

  resetBtnHandler = (value) => {
    this.setState({ btnOnReset: value });
  };

  render() {
    let searchInput;
    const { disableTitle } = this.props;

    return (
      <div className={`${this.props.className}`}>
        <div className="SearchBarComponent">
          <div style={{ display: 'flex', justifyContent: 'flex-start'}} >
            <div className="report-stats">
              <p className="Agents-Logged-In">
                Active Agents <span className="report-stats-text">{this.props.activeLoginUsersCount}</span>
              </p>
            </div>
            <div className="report-stats">
              <p className="Agents-Logged-In">
                Total Calls Made{' '}
                <span className="report-stats-text">
                  {this.props.totalOrganisationCalls}
                </span>
              </p>
            </div>
            <div className="report-stats">
              <p className="Agents-Logged-In">
                Unique Calls Made{' '}
                <span className="report-stats-text">
                  {this.props.totalUniqueCallsMade}
                </span>
              </p>
            </div>
            <div className="report-stats">
              <p className="Agents-Logged-In">
                Total Connected Calls{' '}
                <span className="report-stats-text">
                  {this.props.totalOrganisationAnsweredCalls}
                </span>
              </p>
            </div>
            <div className="report-stats">
              <p className="Total-call-duration">
                Total Calls Duration{' '}
                <span className="report-stats-text">
                  {this.props.totalOrganisationDuration} mins
                </span>
              </p>
            </div>
          </div>
          <div style={{ flex: 1 }} />
          {
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* <div className="SearchText">SEARCH BY</div>
              <select
                className="SearchDropdown"
                id="searchBy"
                value={this.state.searchBy}
                onChange={this.dropDownSelectHandler}
              >
                <React.Fragment>
                <option value="" selected>
                    Select
                  </option>
                  <option value="date" selected>
                    Date
                  </option>
                </React.Fragment>
              </select> */}
              <React.Fragment>
                <input
                  id="fromDate"
                  value={this.state.fromDate}
                  onChange={this.changeHandler}
                  className="SearchBox"
                  style={{ padding: '0px' }}
                  type="date"
                />
                <input
                  id="toDate"
                  value={this.state.toDate}
                  onChange={this.changeHandler}
                  style={{ padding: '0px' }}
                  className="SearchBox"
                  type="date"
                   
                />
              </React.Fragment>
            </div>
          }

          <div>
            {this.state.btnOnReset ? (
              <button
                className="ClearResultButton"
                style={{
                  border: 'none',
                }}
                onClick={this.onReset}
              >
                CLEAR RESULTS
              </button>
            ) : (
              <>
                <button
                  className="SearchButton"
                  style={{ width: '100px', margin: '0 8px' }}
                  onClick={this.onSearch}
                >
                  SEARCH
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default styled(SearchBar)`
display: flex;
flex: 1;

input::placeholder {
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}
input {
  color-scheme: ${(props) => props.theme.colors.dateCalenderIconColor};
}
.SearchBarComponent {
  width: 100%;
  height: 54px;
  background-color: ${(props) => props.theme.colors.searchBarBgcolor};
  display: flex;
  grid-template-columns: 136px auto auto 116px;
  align-items: center;
  border-radius: 1px;
  box-shadow: 0px -15px 10px -15px rgb(0, 0, 0, 0.4);
  border: solid 1px ${(props) => props.theme.colors.searchBarBgcolor};
}

.SearchText {
  font-size: 12px;
  font-weight: 500;
  margin: auto 10px;
  /* letter-spacing: 1.2px; */
  text-align: left;
  color: ${(props) => props.theme.colors.searchByColor};
}

.SearchDropdown {
  width: 136px;
  height: 32px;
  font-size: 13px;
  margin: auto 5px;
  padding: 0 5px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchBox {
  width: 136px;
  height: 32px;
  margin: auto 5px;
  font-size: 13px;
  padding: 0 10px;
  /* padding: 8px 16px 9px; */
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchButton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.ClearResultButton{
  width: 120px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
  width: '128px',
  border: none,
}
.Otherbutton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.theme.colors.lightGreyBgColor};
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.SelectedContainer {
  width: 136px;
  height: 54px;
  background-color: #196cca;
}
.Agents-Logged-In {
  display: flex;
  height: 18px;
  margin: 0 0 0 0;
  
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.Total-call-duration {
  height: 18px;
  margin: 0 4px 0 0;
  
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SelectedContainer h6 {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding-top: 11px;
  padding-left: 14px;
  color: #ffffff;
}

.SelectedContainer p {
  font-size: 16px;
  font-weight: bold;
  padding-left: 14px;
  margin: 0;
  letter-spacing: 1.2px;
  color: #ffffff;
}


@media only screen and (max-width: 900px) {
  .SearchBarComponent {
    width: fit-content;
    grid-template-columns: 48px 136px 77px auto 150px;
  }
}
`;

import { useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { HorizontalDotsIcon } from '../../../assets/Icons/Icons';
import { POST } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
const PopupContainer = {
  width: '400px',
};
const scrollEffect = {
  overflowY: 'auto',
};

export default function CampaignOptions(props) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const handlePauseCampaign = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const handleResumeCampaign = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditCampaign = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const handleDuplicateCampaign = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteCampaign = async () => {
    try {
      let data = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        campaignId: props.id,
        status: 'DELETE',
      };

      POST(cloudCallCenterUrl + 'campaigns/handle-campaign-status', data)
        .then((res) => {
          toast.success('Deleted the campaign successfully');
          setIsOpen(false);
          props.fetchCampaignListHandler(true);
          return true;
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          setIsOpen(false);
          return false;
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Popup open={isOpen}>
        <img
          style={{ left: '96%', top: '-5%', zIndex: '1' }}
          alt=""
          className="PopupClose"
          src={Close}
          onClick={() => setIsOpen(false)}
        />
        <div
          className="PopupContainer"
          style={{ ...PopupContainer, ...scrollEffect }}
        >
          <div className="PopupHeaderContainer" style={{ ...PopupContainer }}>
            <p>Campaign Options</p>
          </div>
          <div className="PopupBody" style={{ ...PopupContainer }}>
            {props?.campaignStatus && (
              <button className="ButtonSecondary">Resume</button>
            )}
            {props?.campaignStatus && (
              <button className="ButtonSecondary">Pause</button>
            )}
            <button
              className="ButtonSecondary"
              onClick={() => {
                history.push(`campaign-report?id=${props.id}`); 
              }}
            >
              History
            </button>
            {props.isHidden ? (
              <button
                className="ButtonSecondary"
                onClick={() => {
                  props.toggleLeadListVisibility(props.id, false);
                }}
              >
                Un Hide
              </button>
            ) : (
              <button
                className="ButtonSecondary"
                onClick={() => {
                  props.toggleLeadListVisibility(props.id, true);
                }}
              >
                Hide
              </button>
            )}
            <button
              className="ButtonCancelFullWidth"
              style={{ marginTop: '16px' }}
              onClick={handleDeleteCampaign}
            >
              Delete
            </button>
          </div>
        </div>
      </Popup>
      <HorizontalDotsIcon type="campaigns" onClick={() => setIsOpen(true)} />
    </>
  );
}

import React, { Component } from 'react';
import { commonStyle } from '../../../assets/css/css';
import DataTable from '../../../common/component/table/DataTable';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { GET, PUT } from '../../../services/HttpRequests';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import UploadPopup from './CallerTuneUploadPopup';
import RemovePopup from './RemoveCallerTunePopup';
import { toast } from 'react-toastify';
import { CallerTuneToggle } from '../../Users/component/CallForwardToggles';
import { getTopBarData } from '../../../actions/topBarActions';
import { connect } from 'react-redux';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import { ADMIN_GROUP_CALLERTUNE_SEARCH_BUTTON, ADMIN_ADD_GROUP_CALLERTUNE_BUTTON, ADMIN_REMOVE_GROUP_CALLERTUNE_BUTTON, ADMIN_GROUP_CALLERTUNE_STATUS_TOGGLE, ADMIN_GROUP_CALLERTUNE_UPLOAD_BUTTON } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";
const priorityRoutingUrl = process.env.REACT_APP_PRIORITY_ROUTING_API;
const callerTuneUrl = process.env.REACT_APP_CALLER_TUNE_API;
class GroupCallerTune extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      page: 1,
      page_size: 8,
      total: 0,
      total_pages: 0,
      groups: [],
      orgId: localStorage.getItem('doosra-biz-organisation-id'),
      currentItem: {},
      uploadPopup: false,
      audioPlayed: false,
      deletePopup: false,
    };
  }

  componentDidMount = () => {
    this.fetchAllGroups();
    if (this.props.onRegisterSearchHandlers) {
      this.props.onRegisterSearchHandlers(this.fetchAllGroups, this.searchHandler);
    }
  };

  fetchGroups = async (params) => {
    this.setState({
      loading: true,
    });
    const allGroupsResponse = await GET(
      `${priorityRoutingUrl}v1/organisations/priority-routing/group/list`,
      params
    ).then((res) => {
      return res.data.response;
    });
    let count = Math.ceil(allGroupsResponse.metadata.total / this.state.page_size);
    this.setState({
      groups: allGroupsResponse.items,
      total: allGroupsResponse.metadata.total,
      total_pages: count,
      loading: false,
    });
  };
  
  searchHandler = async (data) => {
    logPostHogEvent(ADMIN_GROUP_CALLERTUNE_SEARCH_BUTTON);
    data.page = this.state.page - 1;
    data.page_size = this.state.page_size;
    data.organisationId = this.state.orgId;
  
    await this.fetchGroups(data);
  };
  
  fetchAllGroups = async () => {
    const params = {
      page: this.state.page - 1,
      page_size: this.state.page_size,
      organisationId: this.state.orgId,
    };
  
    await this.fetchGroups(params);
  };
  

  onChangePage = (page) => {
    this.setState(
      {
        page,
      },
      () => this.fetchAllGroups()
    );
  };

  closePopup = () => {
    this.setState({
      uploadPopup: false,
      deletePopup: false,
    });
  };

  onClickAddCallerTune = (item) => {
    logPostHogEvent(ADMIN_ADD_GROUP_CALLERTUNE_BUTTON);
    console.log();
    this.setState({
      currentItem: item,
      uploadPopup: true,
    });
  };

  onClickRemoveCallerTune = (item) => {
    logPostHogEvent(ADMIN_REMOVE_GROUP_CALLERTUNE_BUTTON);
    this.setState({
      currentItem: item,
      deletePopup: true,
    });
  };

  handelUploadedFile = async (formData) => {
    try {
      logPostHogEvent(ADMIN_GROUP_CALLERTUNE_UPLOAD_BUTTON);
      this.setState({ loading: true });
      formData.append('id', this.state.currentItem._id);
      formData.append('type', 'routing_group');
      formData.append(
        'organisationId',
        localStorage.getItem('doosra-biz-organisation-id')
      );
      const uploadRespose = await PUT(
        `${callerTuneUrl}v1/organisations/caller-tune-set`,

        null,
        formData,
        { 'Content-Type': 'multipart/form-data' }
      );
      const removeGlobalAudio = await PUT(
        `${callerTuneUrl}v1/organisations/caller-tune-enable`,
        null,
        {
          organisationId: localStorage.getItem('doosra-biz-organisation-id'),
          id: this.state.currentItem._id,
          type: 'routing_group',
        }
      );
      this.setState({
        uploadPopup: false,
        audioPlayed: false,
        loading: false,
      });
      this.fetchAllGroups();
      toast.success(`Succesfully set Group CallerTune`);
    } catch (err) {
      this.setState({ loading: false });
      if (err?.response?.data?.error?.code === 1027) {
        this.setState({ uploadPopup: false });
        this.fetchAllGroups();
      }
      toast.error(err.response.data.error.reason);
    }
  };

  handleRemoveCallerTune = async () => {
    try {
      const removeGlobalAudio = await PUT(
        `${callerTuneUrl}v1/organisations/caller-tune-unset`,
        null,
        {
          organisationId: localStorage.getItem('doosra-biz-organisation-id'),
          id: this.state.currentItem._id,
          type: 'routing_group',
        }
      );
      if (!this.props.globalCallerTuneUrl) {
        await PUT(
          `${callerTuneUrl}v1/organisations/caller-tune-disable`,
          null,
          {
            organisationId: localStorage.getItem('doosra-biz-organisation-id'),
            id: this.state.currentItem._id,
            type: 'routing_group',
          }
        ).then((res) => {
          console.log("group caller tune", { res });
        }).catch((err) => {
          console.log("group caller tune issue", { err });
        });
      }
      this.setState({
        deletePopup: false,
        audioPlayed: false,
      });
      this.fetchAllGroups();
    } catch (err) {
      console.log(err);
    }
  };

  onClickPlayAudio = (item) => {
    this.setState({
      currentItem: item,
      audioPlayed: true,
    });
  };

  changeCallertuneStatus = async (item) => {
    try {
      logPostHogEvent(ADMIN_GROUP_CALLERTUNE_STATUS_TOGGLE);
      const removeGlobalAudio = await PUT(
        `${callerTuneUrl}v1/organisations/${item.is_caller_tune_enable
          ? 'caller-tune-disable'
          : 'caller-tune-enable'
        }`,
        null,
        {
          type: 'routing_group',
          id: item._id,
          organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        }
      );
      this.setState({
        deletePopup: false,
      });
      this.fetchAllGroups();
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    const {
      page,
      total_pages,
      loading,
      groups,
      uploadPopup,
      deletePopup,
      currentItem,
      audioPlayed,
    } = this.state;
   
    return (
      <div>
        {loading && <PreLoader />}
        <DataTable.Table>
          <DataTable.Head>
            <DataTable.CellHead style={{ width: '2%' }}></DataTable.CellHead>
            <DataTable.CellHead style={{ width: '60%' }}>
              GROUP NAME
            </DataTable.CellHead>
            <DataTable.CellHead style={{ width: '16%' }}>
              ACTION
            </DataTable.CellHead>
            <DataTable.CellHead style={{ width: '14%' }}>
              AUDIO ACTION
            </DataTable.CellHead>
            <DataTable.CellHead style={{ width: '8%' }}>
              CURRENT STATUS
            </DataTable.CellHead>
            <DataTable.CellHead style={{ width: '2%' }}></DataTable.CellHead>
          </DataTable.Head>
          <DataTable.Body>
            {(groups || []).map((item) => (
              <DataTable.Row>
                <DataTable.Cell> </DataTable.Cell>
                <DataTable.Cell>{item.name}</DataTable.Cell>
                <DataTable.Cell>
                  {!isSuperAdminWithPermission('ADD') ? item.callerTuneUrl ? (
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{ color: '#ed3833', marginTop: '6px' }}
                      >
                        Remove Callertune
                      </div>
                      <div style={{ marginLeft: '20px' }}></div>
                    </div>
                  ) : (
                    <div
                      style={{ color: '#287dc9', marginTop: '7px' }}
                    >
                      Add Callertune
                    </div>
                  ) :
                    item.callerTuneUrl ? (
                      <div style={{ display: 'flex' }}>
                        <a
                          style={{ color: '#ed3833', marginTop: '6px' }}
                          href="#"
                          onClick={() => this.onClickRemoveCallerTune(item)}
                        >
                          Remove Callertune
                        </a>
                        <div style={{ marginLeft: '20px' }}></div>
                      </div>
                    ) : (
                      <a
                        style={{ color: '#287dc9', marginTop: '7px' }}
                        href="#"
                        onClick={() => this.onClickAddCallerTune(item)}

                      >
                        Add Callertune
                      </a>
                    )}
                </DataTable.Cell>
                <DataTable.Cell>
                  {item.callerTuneUrl ? (
                    currentItem._id === item._id && audioPlayed ? (
                      <audio
                        style={{
                          height: '24px',
                          marginTop: '3px',
                          marginBottom: '-2px',
                        }}
                        controls
                        autoPlay
                        // ref="audio_tag"
                        src={item.callerTuneUrl}
                      />
                    ) : (
                      <React.Fragment>
                        <a
                          style={{ color: '#0d9f3a', marginTop: '7px' }}
                          href="#"
                          onClick={() => this.onClickPlayAudio(item)}
                        >
                          Play
                        </a>
                      </React.Fragment>
                    )
                  ) : (
                    <></>
                  )}
                </DataTable.Cell>
                <DataTable.Cell>
                  <CallerTuneToggle
                    value={item.is_caller_tune_enable}
                    globalCallerTuneUrl={this.props.globalCallerTuneUrl}
                    callerTuneUrl={item.callerTuneUrl}
                    onChange={() => this.changeCallertuneStatus(item)}
                  />
                </DataTable.Cell>
                <DataTable.Cell> </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable.Body>
        </DataTable.Table>
        <Paginator
          currentPage={page}
          lastPage={total_pages}
          getInfo={this.onChangePage}
        />
        {uploadPopup && (
          <UploadPopup
            closePopup={this.closePopup}
            uploadPopup
            handelUploadedFile={this.handelUploadedFile}
          />
        )}
        {deletePopup && (
          <RemovePopup
            closePopup={this.closePopup}
            deletePopup
            handleRemoveCallerTune={this.handleRemoveCallerTune}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  balance: state.topbar.balance,
  name: state.topbar.name,
  logo: state.topbar.logo,
  minuteBalance: state.topbar.minuteBalance,
  callForwardingStatus: state.topbar.call_forwarding_status,
  globalCallerTuneUrl: state.topbar.callerTuneUrl,
});

export default commonStyle(
  connect(mapStateToProps, { getTopBarData })(GroupCallerTune)
);

import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';

import '../../../assets/css/Content.css';
import { GET } from '../../../services/HttpRequests';
import AudioPlayer from '../components/AudioPlayer';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import ViewContactPopup from '../../contacts/component/ViewContactPopup';
import AddContactNotesTagsPopup from '../../contacts/component/AddContactNotesTagsPopup';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import TagIcon from '../../../assets/images/tag.png';
import {
  INCOMING_TAB,
  OUTGOING_TAB,
  ADMIN_INCOMING_TAB,
  ADMIN_OUTGOING_TAB,
  ADMIN_BLACKLIST_TAB,
  ADMIN_DID_CALL_BACK_TAB,
  DID_CALL_BACK_TAB,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';

const outgoingCallsUrl = process.env.REACT_APP_OUTGOING_CALLS_API;
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;

class IncomingDIDCallLogsPage extends Component {
  constructor() {
    super();
    this.state = {
      count: 0,
      incomingCallLogCount: 0,
      outgoingCallLogCount: 0,
      blackListCount: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 15,
      callList: [],
      loading: false,
      searchData: {},
    };
  }

  componentDidMount() {
    this.getCallList();
    this.getOutgoingCallLogCount();
    this.getIncomingCallLogCount();
    // this.getCallBlackListCount();
  }

  fetchPageData = (value) => {
    let data = {
      ...this.state.searchData,
      pageNo: value || 1,
      pageSize: this.state.pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    this.setState({ loading: true });
    console.log('fetchPageData:', {
      data,
      value,
      searchData: this.state.searchData,
    });
    GET(`${incomingCallsUrl}v1/organisations/did-callback-list`, data)
      .then((res) => res.data.response)
      .then((res) => {
        let count = Math.ceil(res.payload.count / this.state.pageSize);
        if (count === 0) count = 1;
        const data = res.payload.docs;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(', ');
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        this.setState({
          count: res.payload.count,
          callList: data,
          loading: false,
          totalPages: count,
          currentPage: value,
        });
      })
      .catch((err) => {
        this.setState({ loading: false, callList: [] });
        console.log(err);
      });
  };

  getCallList = (search) => {
    this.setState({ loading: true });
    let data = {
      ...(search || {}),
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    console.log('getCallList:', { data });

    GET(`${incomingCallsUrl}v1/organisations/did-callback-list`, data)
      .then((res) => res.data.response)
      .then((res) => {
        let count = Math.ceil(res.payload.count / this.state.pageSize);
        if (count === 0) count = 1;
        const data = res.payload.docs;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(', ');
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        this.setState({
          count: res.payload.count,
          callList: data,
          totalPages: count,
          // pageSize: res.pageSize,
          loading: false,
          searchData: {},
        });
      })
      .catch((err) => {
        this.setState({ loading: false, callList: [] });
        console.log(err);
      });
  };

  seachHandler = (searchItem) => {
    this.setState({ loading: true });
    searchItem.pageNo = 1;
    searchItem.pageSize = this.state.pageSize;
    searchItem.organisationId = localStorage.getItem(
      'doosra-biz-organisation-id'
    );
    if (searchItem.from_date) searchItem.fromDate = searchItem.from_date;
    if (searchItem.to_date) searchItem.toDate = searchItem.to_date;
    console.log('seachHandler:', { searchItem });

    GET(`${incomingCallsUrl}v1/organisations/did-callback-list`, searchItem)
      .then((res) => res.data.response)
      .then((res) => {
        let count = Math.ceil(res.payload.count / this.state.pageSize);
        if (count === 0) count = 1;
        const data = res.payload.docs;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(', ');
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        this.setState({
          count: res.payload.count,
          callList: data,
          totalPages: count,
          currentPage: 1,
          pageSize: res.pageSize,
          loading: false,
          searchData: searchItem,
        });
      })
      .catch((err) => {
        this.setState({ loading: false, callList: [] });
        console.log(err);
      });
  };

  getIncomingCallLogCount = () => {
    GET(`${incomingCallsUrl}v1/organisations/calls`, {
      pageNo: 0,
      pageSize: 1,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ incomingCallLogCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  getOutgoingCallLogCount = () => {
    this.setState({ loading: true });
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    GET(`${outgoingCallsUrl}v1/organisations/outbound-call/call-log/list`, {
      pageNo: 1,
      pageSize: 10,
      organisationId: orgId,
    })
      .then((res) => res.data.response)
      .then((res) =>
        this.setState({
          outgoingCallLogCount: res.metadata.total,
          loading: false,
        })
      )
      .catch((err) => null);
  };

  getCallBlackListCount = () => {
    GET(`${incomingCallsUrl}v1/organisations/black-list`, {
      pageNo: 1,
      pageSize: 10,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ blackListCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  trimNumber = (number) => {
    if (number && number.length === 12) return number.substring(2);
    return number;
  };

  render() {
    const { callList } = this.state;
    const { className } = this.props;
    const userRole = localStorage.getItem('doosra-biz-user-role');
    let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
    isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
    const {
      callForwardingStatus,
      isOutboundCallEnabled,
      // isCallForwardingAllowed,
      isOrgOutboundCallEnabled,
    } = this.props;
    const tabs = [];

    if (
      (userRole === 'ADMIN' && callForwardingStatus) ||
      // (userRole === 'USER' && callForwardingStatus && isCallForwardingAllowed)
      (userRole === 'USER' && callForwardingStatus)
    )
      tabs.push({
        title: 'Incoming',
        subtitle: `${this.state.incomingCallLogCount}`,
        onClick: () => {
          logPostHogEvent(
            userRole === 'ADMIN' ? ADMIN_INCOMING_TAB : INCOMING_TAB
          );
          this.props.history.push('/call-logs');
        },
      });

    if (
      (userRole === 'ADMIN' && isOrgOutboundCallEnabled) ||
      (userRole === 'USER' && isOutboundCallEnabled && isOrgOutboundCallEnabled)
    ) {
      if (!isFreeTrail) {
        tabs.push({
          title: 'Callback',
          subtitle: `${this.state.count}`,
          onClick: () => {
            logPostHogEvent(
              userRole === 'ADMIN' ? ADMIN_DID_CALL_BACK_TAB : DID_CALL_BACK_TAB
            );
            this.props.history.push('/did-call-back');
          },
          active: true,
        });
      }
      tabs.push({
        title: 'Outgoing',
        subtitle: `${this.state.outgoingCallLogCount}`,
        onClick: () => {
          logPostHogEvent(
            userRole === 'ADMIN' ? ADMIN_OUTGOING_TAB : OUTGOING_TAB
          );
          this.props.history.push('/outgoing-call-logs');
        },
      });
    }

    // if (userRole === 'ADMIN') {
    //   tabs.push({
    //     title: 'BlackList',
    //     subtitle: `${this.state.blackListCount}`,
    //     onClick: () => {
    //       logPostHogEvent(ADMIN_BLACKLIST_TAB);
    //       this.props.history.push('/black-list');
    //     },
    //   });
    // }

    function timeStringToPulse(timeString) {
      let pulses;
      if (timeString.includes(':')) {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        const totalMinutes = hours * 60 + minutes + (seconds > 0 ? 1 : 0);
        pulses =
          Math.floor(totalMinutes / 60) + (totalMinutes % 60 > 0 ? 1 : 0);
      } else {
        const totalSeconds = Number(timeString);
        pulses = Math.ceil(totalSeconds / 60);
      }
      return pulses;
    }

    return (
      <div className={className}>
        <Sidebar />
        <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />
          <div className="ContentContainer">
            <TabSearchBar tabs={tabs}>
              <SearchBar
                type="did-call-backs"
                disableTitle
                count={this.state.count}
                reset={this.getCallList}
                search={this.seachHandler}
              />
            </TabSearchBar>
            <DataTable.Table>
              {this.state.loading && <PreLoader />}
              <DataTable.Head>
                {userRole !== 'USER' && (
                  <>
                    <DataTable.CellHead width="70">
                      AGENT NAME
                    </DataTable.CellHead>
                  </>
                )}
                <DataTable.CellHead width="70"> VIRTUAL NO </DataTable.CellHead>
                <DataTable.CellHead width="70">CALLER</DataTable.CellHead>
                <DataTable.CellHead width="70">CONTACT NAME</DataTable.CellHead>
                <DataTable.CellHead width="70">NOTES</DataTable.CellHead>
                <DataTable.CellHead width="70">TAGS</DataTable.CellHead>
                <DataTable.CellHead width="70">CALL STATUS</DataTable.CellHead>
                <DataTable.CellHead width="70">DURATION</DataTable.CellHead>
                <DataTable.CellHead width="70">PULSE COUNT</DataTable.CellHead>
                <DataTable.CellHead width="70">CALL RECORD</DataTable.CellHead>
                <DataTable.CellHead width="50">TIME</DataTable.CellHead>
                <DataTable.CellHead width="70">DATE</DataTable.CellHead>
                <DataTable.CellHead width="70">ACTIONS</DataTable.CellHead>
              </DataTable.Head>
              <DataTable.Body>
                {(callList || []).map((item) => (
                  <DataTable.Row key={item?._id}>
                    {userRole !== 'USER' && (
                      <DataTable.Cell>
                        {item.call_status_text === 'Missed' &&
                          item.is_group_call_log
                          ? '-'
                          : item.zvr_name || '-'}
                      </DataTable.Cell>
                    )}
                    <DataTable.Cell>
                      {this.trimNumber(
                        item.is_group_call_log
                          ? item.group_v_mobile_no ?? item.v_mobile_no
                          : item.v_mobile_no
                      )}
                    </DataTable.Cell>
                    <DataTable.Cell>{item.s_mobile_no}</DataTable.Cell>
                    <DataTable.Cell>{item.contact_name || '-'}</DataTable.Cell>
                    <DataTable.Cell>
                      <div className="tableNotes">{item.notes || '-'}</div>
                    </DataTable.Cell>
                    <DataTable.Cell>
                      {item?.tags?.length > 0 &&
                        item.tags.map((tag, index) => {
                          return (
                            <div key={index} className="tableTags">
                              <img
                                alt="tag"
                                src={TagIcon}
                                style={{
                                  width: '15px',
                                  height: '15px',
                                  marginRight: 7,
                                }}
                              />
                              <span>{tag}</span>
                            </div>
                          );
                        })}
                    </DataTable.Cell>
                    {/* call type */}
                    {item.call_status_text &&
                      (item.call_status_text === 'Missed' ? (
                        <DataTable.CellMissedCall>
                          {item.call_status_text}
                        </DataTable.CellMissedCall>
                      ) : item.call_status_text === 'Answered' ? (
                        <DataTable.CellAnsweredCall>
                          {item.call_status_text}
                        </DataTable.CellAnsweredCall>
                      ) : (
                        <DataTable.Cell>{item.call_status_text}</DataTable.Cell>
                      ))}

                    <DataTable.Cell>
                      {item.callBackId
                        ? item.duration === 0
                          ? '-'
                          : moment('1900-01-01 00:00:00')
                            .add(item.call_duration, 'seconds')
                            .format('HH:mm:ss')
                        : item.call_duration &&
                          item.call_duration !== '00:00:00'
                          ? item.call_duration
                          : '-'}
                    </DataTable.Cell>
                    <DataTable.Cell>
                      {timeStringToPulse(item?.call_duration)}
                    </DataTable.Cell>
                    <DataTable.Cell>
                      <AudioPlayer
                        callRecordingUrl={item?.call_recording_url}
                        callId={item.callBackId ? item?._id : item?.call_id}
                      />
                    </DataTable.Cell>
                    <DataTable.Cell>
                      {moment(item.created_at).format('HH:mm:ss')}
                    </DataTable.Cell>
                    <DataTable.Cell>
                      {' '}
                      {moment(item.created_at).format('DD-MM-YYYY')}
                    </DataTable.Cell>
                    <DataTable.Cell>
                      {item.is_contact ? (
                        <ViewContactPopup
                          key={item._id}
                          contactInfo={item.contactObj}
                          getList={this.fetchPageData}
                          editFrom="callLog"
                          userId={item.user_id}
                        />
                      ) : (
                        isSuperAdminWithPermission('EDIT') &&
                        userRole === 'USER' && (
                          <AddContactNotesTagsPopup
                            callerNo={item.s_mobile_no}
                            getList={this.fetchPageData}
                          />
                        )
                      )}
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}
                {callList.length <= 0 && (
                  <DataTable.Row>
                    <DataTable.Cell colSpan={9} style={{ textAlign: 'center' }}>
                      No Records Found
                    </DataTable.Cell>
                  </DataTable.Row>
                )}
              </DataTable.Body>
            </DataTable.Table>

            <Paginator
              currentPage={this.state.currentPage}
              lastPage={this.state.totalPages}
              getInfo={(value) => this.fetchPageData(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  callPriorityRoutingStatus: state.topbar.call_priority_routing,
  callForwardingStatus: state.topbar.call_forwarding_status,
  isOrgOutboundCallEnabled: state.topbar.isOrgOutboundCallEnabled,
  isOutboundCallEnabled: state.topbar.isOutboundCallEnabled,
});

export default commonStyle(
  connect(mapStateToProps, {})(IncomingDIDCallLogsPage)
);

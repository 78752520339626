export const generateRecaptcha = (action) => {
  return true;
  // if (isReady()) {
  //   // return
  //   console.log(action, process.env.REACT_APP_RECAPTCHA_SITE_KEY);
  //   let a = window.grecaptcha.execute(
  //     process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  //     { action: action }
  //   );
  //   console.log('a', a);
  //   return a;
  // }
  // return new Promise().reject(new Error('captcha not ready'));
};

// const isReady = () => {
//   if (window.grecaptcha && window.grecaptcha.ready) {
//     return true;
//   }
//   return false;
// };

import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';

export default function ExportAgentCampaigns(props) {
  const [list, setList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  let columns = [
    { id: 'campaigns', displayName: 'CAMPAIGNS' },
    { id: 'leadsCalled', displayName: 'LEADS CALLED' },
    { id: 'callAnswerRate', displayName: 'CALL ANSWER RATE' },
  ];
  const openPopup = () => {
    setIsPopupOpen(true);
    console.log(props.agentsData);
    setList(props.agentsData);
    setDataFetched(true);
  };
  return (
    <div>
      <button
        className="Otherbutton"
        style={{ color: 'white', backgroundColor: '#05AA3F' }}
        onClick={openPopup}
      >
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download Campaign Agent List</p>
            <img
              className="PopupClose"
              src={Close}
              alt=""
              onClick={() => {
                setIsPopupOpen(false);
                setDataFetched(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file.
            </p>
            <p className="PopupNote">
              Max download limit - Recent 10,000 entries
            </p>
            {dataFetched ? (
              <CsvDownloader
                filename={`${props.agentsData.agentName}_call_data`}
                separator=","
                columns={columns}
                datas={
                  props.agentsData.campaignsData.length
                    ? props.agentsData.campaignsData.map((row) => {
                        return {
                          campaigns: row?.campaignName,
                          leadsCalled: row?.campaignTotalCalls,
                          callAnswerRate: row?.answerPercentage,
                        };
                      })
                    : [{}]
                }
                className="ButtonFullWidth BgGreen"
                text="Download CSV"
              />
            ) : (
              <button
                className="ButtonFullWidth BgGrey"
                style={{ cursor: 'default' }}
              >
                SAVE
              </button>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}

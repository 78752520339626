import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Popup from 'reactjs-popup';
import styled from '@emotion/styled';
import { text } from '@fortawesome/fontawesome-svg-core';
import CloseIcon from '../../../assets/images/closeRedLarge.png';
import CheckIcon from '../../../assets/images/checkGreenSmall.png';
import { POST, PUT } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import PreLoader from '../preLoader/PreLoader';
import { logoutHandler } from '../../../utility/utility.js';
import InfoTooltip from '../infoTooltip/index.js';
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const overlayStyle = { background: 'rgba(0,0,0,0.9)', overflow: 'auto' };
const contentStyle = {
  width: 'fit-content',
  fontFamily: 'Inter',
  width: '432px',
  // height: '746px',
  padding: '40px 42px',
  borderRadius: '8px',
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
  textAlign: 'center',
};

const Header = styled.p`
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.82;
  color: #07aa3f;
`;

const SubHeader = styled.p`
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.18;
  color: #000;
  width: 300px;
  margin: 0 auto 30px;
`;

const Button = styled.button`
  width: 336px;
  height: 48px;
  border-radius: 4px;
  margin-bottom: 12px;
  background-color: #ed3833;
  border: 1px solid #ed3833;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
`;

const ButtonInverse = styled.button`
  width: 336px;
  height: 48px;
  border-radius: 4px;
  margin-bottom: 24px;
  background-color: #fff;
  border: 1px solid #939598;
  color: #939598;
  font-size: 15px;
  font-weight: bold;
`;

const Note = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #939598;
  width: 336px;
  margin: 0 auto 18px;
`;

const Close = styled.p`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #939598;
  cursor: 'pointer';
`;

export default function PlanCancelation() {
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCallBackRequested, setIsCallBackRequested] = useState(false);

  let isFreeTrail = JSON.parse(localStorage.getItem('is_freemium_plan_used'));

  const daysCount = localStorage.getItem('organisation_expiry_date');

  let now = moment();
  const end = moment(daysCount);
  const days = end.diff(now, 'days');

  const requestCallBackHander = async () => {
    setLoading(true);
    await POST(`${orgUrl}v1/organisation/request-callback`, {
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => {
        setLoading(false);
        setIsCallBackRequested(true);
        localStorage.setItem('is_callback_requested', true);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  const confirmPlanCancelation = async () => {
    setLoading(true);
    await PUT(`${orgUrl}v1/organisation/cancel-plan`, null, {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => {
        setLoading(false);
        setPopupOpen(false);
        if (isFreeTrail) {
          logoutHandler();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  useEffect(() => {
    setIsCallBackRequested(
      JSON.parse(localStorage.getItem('is_callback_requested'))
    );
  }, [popupOpen]);

  if (JSON.parse(localStorage.getItem('was_plan_canceled'))) {
    return null;
  }

  return (
    <>
      {loading && <PreLoader />}
      <Popup
        open={popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        {...{ overlayStyle, contentStyle }}
      >
        <div>
          <img
            src={CloseIcon}
            style={{ width: '80px', height: '80px', marginBottom: '30px' }}
          />

          {JSON.parse(localStorage.getItem('is_freemium_plan_used')) && (
            <Header>You have {days} days left</Header>
          )}
          <SubHeader>
            Are you sure you want to cancel your
            {isFreeTrail ? ' free trial' : ' plan'}
          </SubHeader>
          <Button onClick={confirmPlanCancelation}>YES</Button>
          <ButtonInverse onClick={() => setPopupOpen(false)}>NO</ButtonInverse>
          <Note>
            Would you like to contact our support team for expert guidance on
            how to best use Alohaa for your business, or for feedback?
          </Note>
          {isCallBackRequested ? (
            <p
              style={{
                fontSize: '16px',
                fontWeight: 500,
                textAlign: 'center',
                color: '#07aa3f',
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={CheckIcon} style={{ width: '16px' }} />
              <span>Call back requested</span>
            </p>
          ) : (
            <p
              style={{
                fontSize: '16px',
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.38',
                letterSpacing: 'normal',
                textAlign: 'center',
                color: '#196cca',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={requestCallBackHander}
            >
              Request call back
            </p>
          )}
          <Close
            style={{ cursor: 'pointer' }}
            onClick={() => setPopupOpen(false)}
          >
            Close
          </Close>
        </div>
      </Popup>
      <div
        style={{
          fontSize: 13,
          color: '#ed3833',
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
        onClick={() => setPopupOpen(true)}
      >
        Cancel {isFreeTrail ? 'free trial' : 'plan'}
      </div>
    </>
  );
}

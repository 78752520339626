import React, { useEffect, useRef, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';
import { ADMIN_EXPORT_CONTACT_BUTTON } from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';
const userUrl = process.env.REACT_APP_USER_API;
const noteUrl = process.env.REACT_APP_NOTES_SERVICES;

export default function ExportContactsPopup(props) {
  const [list, setList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [forUser, setForUser] = useState('ALL');
  const usersList = props.usersList || [];
  const csvLink = useRef(null);

  let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';
  let columns = [
    { id: 'userName', displayName: 'AGENT NAME' },
    { id: 'name', displayName: 'CONTACT NAME' },
    { id: 'phoneNumbers', displayName: 'PRIMARY NUMBER' },
    { id: 'notes', displayName: 'NOTES' },
    { id: 'tags', displayName: 'TAGS' },
    // { id: 'createdAt', displayName: 'TIME' },
  ];

  const openPopup = () => {
    logPostHogEvent(ADMIN_EXPORT_CONTACT_BUTTON);
    setIsPopupOpen(true);
    setFetching(false);
    setForUser('ALL');
  };

  const fetchDataHandler = async () => {
    try {
      if (fetching) return;
      setFetching(true);
      const data = {
        pageNo: 1,
        pageSize: 100,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      };
      if (localStorage.getItem('doosra-biz-user-role') === 'USER') {
        data.userId = localStorage.getItem('doosra-biz-user-id');
      }
      if (forUser !== 'ALL') {
        data.userId = forUser;
      }
      const res = await GET(userUrl + `v1/user/contacts`, data);
      const contactList = res?.data?.response?.contacts || [];
      const finalList = [];
      for (let i = 0; i != contactList.length; i++) {
        let contact = contactList[i];
        contact.userName = contact.userName
          ? contact.userName.split(',').join(' ')
          : '-';
        contact.name = contact.name ? contact.name.split(',').join(' ') : '-';
        let contactNos =
          contact.phoneNumbers && contact.phoneNumbers.length > 0
            ? contact.phoneNumbers.map((no, index) => {
                return index + 1 + ') ' + no;
              })
            : null;
        contact.phoneNumbers = contactNos ? contactNos.join('  ') : '-';

        let data = {
          contactId: contact._id,
          organisationId: contact.organisationId,
          pageNum: 1,
          pageSize: 1000,
          userId: contact.userId,
        };
        const notesArray = await GET(noteUrl + `v1/notes`, data).then((res) => {
          return res?.data?.response?.notes;
        });
        let notes = notesArray
          ? notesArray.map((obj, index) => {
              return index + 1 + ') ' + obj.note;
            })
          : [];
        if (notes && notes.length > 0) {
          notes = notes.join('  ');
          contact.notes = notes;
        }

        const tagsArray = await GET(userUrl + 'v1/user/tags', data).then(
          (res) => {
            return res?.data?.response?.tags;
          }
        );
        let tags = tagsArray
          ? tagsArray.map((obj, index) => {
              return index + 1 + ') ' + obj.tag;
            })
          : [];
        if (tags && tags.length > 0) {
          tags = tags.join(' ');
          contact.tags = tags;
        }

        finalList.push(contact);
      } //for

      setList(finalList);
      csvLink.current.handleClick();
      setFetching(false);
      setIsPopupOpen(false);
    } catch (err) {
      console.log(err);
      setFetching(false);
    }
  };

  return (
    <div>
      <button className="Otherbutton" onClick={openPopup}>
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download Contacts</p>
            <img
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsPopupOpen(false);
                setFetching(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The Contacts will be downloaded as a csv file.
            </p>

            <CsvDownloader
              filename={`${org_name}_contacts_list_${new Date().toLocaleDateString()}`}
              separator=","
              columns={columns}
              ref={csvLink}
              datas={
                list.length
                  ? list.map((detail) => {
                      return {
                        ...detail,
                        createdAt: new Date(
                          detail.createdAt
                        ).toLocaleDateString(),
                      };
                    })
                  : [{}]
              }
              text=""
              style={{ display: 'none' }}
            />
            {localStorage.getItem('doosra-biz-user-role') === 'ADMIN' && (
              <div style={{ margin: '14px 0 24px 40px' }}>
                <p style={{ margin: 0 }}>Agents</p>
                <select
                  className="SearchDropdown"
                  id="searchValue"
                  onChange={(e) => setForUser(e.target.value)}
                  value={forUser}
                >
                  <option value={'ALL'} selected>
                    ALL
                  </option>
                  {usersList.map((item) => (
                    <option value={item.user_id}>{item.zvr_name}</option>
                  ))}
                </select>
              </div>
            )}
            <button
              className={
                !forUser ? 'ButtonFullWidth BgGrey' : 'ButtonFullWidth'
              }
              style={!forUser || fetching ? { cursor: 'default' } : null}
              onClick={fetchDataHandler}
            >
              {fetching ? 'Loading ...' : 'Download CSV'}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../assets/css/Content.css';
import { GET, PUT } from '../../../services/HttpRequests';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import { CallIcon } from '../../../assets/Icons/Icons';
import AudioPlayer from '../components/AudioPlayer';
import { initCall } from '../../../actions/sidebarActions';
import { commonStyle } from '../../../assets/css/css';
import UpgradeServices from '../../../common/component/upgrade-services/UpgradeServices';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import ViewContactPopup from '../../contacts/component/ViewContactPopup';
import AddContactNotesTagsPopup from '../../contacts/component/AddContactNotesTagsPopup';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import TagIcon from '../../../assets/images/tag.png';
import {
  DID_CALL_BACK_TAB,
  INCOMING_TAB,
  ADMIN_DID_CALL_BACK_TAB,
  ADMIN_INCOMING_TAB,
  ADMIN_BLACKLIST_TAB,
  ADMIN_OUTGOING_TAB,
  OUTGOING_TAB,
} from '../../../common/posthog/events';
import { setIsOpen, setDestinationNumber, setCallId, setContactId } from '../../../actions/dailPadActions';
import { logPostHogEvent } from '../../../common/posthog/posthog';
import { toast } from 'react-toastify';
import { setOutGoingCallsTableHeaders } from "../../../actions/recoveryXActions";
import { OUTGOING_CALLS_MAPPER } from '../../../common/constants';
import AddIndividualCallDispositionsPopup from '../components/AddIndividualCallDispositionsPopup';

const outgoingCallsUrl = process.env.REACT_APP_OUTGOING_CALLS_API;
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;
const cloudCallUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

const DICT = {
  answered: 'Answered',
  not_answered: 'Unanswered',
};

class OutgoingCallLogsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      incomingCallLogCount: 0,
      blackListCount: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 15,
      callList: [],
      loading: false,
      searchData: {},
      didCallBackCount: 0,
      isModalOpen: false,
      dispositions: [],
      filteredHeaders: props.outGoingCallsTableHeaders || ["OUTGOING NUMBER",
        "DESTINATION",
        "CONTACT NAME",
        "DISPOSITION",
        "CALLCOMMENT",
        "TIME",
        "DATE"],
      isHeadersPopUpOpen: false,
      hideTableHeaders: false,
    };
  }

  componentDidMount() {
    this.getCallList();
    this.getIncomingCallLogCount();
    this.getDispositions();
    // this.getDIDCallBackCount();
    // this.getCallBlackListCount();
  }

  toggleModal = () => {
    const value = !this.state.isModalOpen;
    this.setState({ isModalOpen: value });
  };

  componentDidUpdate(prevProps) {
    if (this.props.outGoingCallsTableHeaders.length !== prevProps.outGoingCallsTableHeaders.length) {
      this.setState({ filteredHeaders: this.props.outGoingCallsTableHeaders });
    }
  }

  getCallList = (search) => {
    this.setState({ loading: true });
    let data = {
      ...(search || {}),
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    if (search) {
      this.setState({ currentPage: 1 });
      data.pageNo = 1;
    }
    console.log('outging list req:', { data });
    GET(`${outgoingCallsUrl}v1/organisations/outbound-call/call-log/list`, data)
      .then((res) => res.data.response)
      .then((res) => {
        let count = Math.ceil(res.metadata.total / this.state.pageSize);
        if (count === 0) count = 1;
        const data = res.items;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(', ');
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        this.setState({
          count: res.metadata.total,
          callList: data,
          totalPages: count,
          // pageSize: res.pageSize,
          loading: false,
          searchData: {},
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  seachHandler = (searchItem) => {
    searchItem.pageNo = 1;
    searchItem.pageSize = this.state.pageSize;
    searchItem.organisationId = localStorage.getItem(
      'doosra-biz-organisation-id'
    );
    console.log('seachHandler:', { searchItem });
    GET(
      `${outgoingCallsUrl}v1/organisations/outbound-call/call-log/list`,
      searchItem
    )
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        let count = Math.ceil(res.metadata.total / this.state.pageSize);
        if (count === 0) count = 1;
        const data = res.items;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(', ');
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        this.setState({
          count: res.metadata.total,
          callList: data,
          totalPages: count,
          currentPage: 1,
          // pageSize: res.pageSize,
          loading: false,
          searchData: searchItem,
        });
      })
      .catch((err) => {
        if (err) {
          this.setState({ loading: false });
        }
      });
  };

  fetchPageData = (value) => {
    let data = {
      ...this.state.searchData,
      pageNo: value || 1,
      pageSize: this.state.pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    this.setState({ loading: true });
    console.log('fetchPageData:', {
      data,
      value,
      searchData: this.state.searchData,
    });
    GET(`${outgoingCallsUrl}v1/organisations/outbound-call/call-log/list`, data)
      .then((res) => res.data.response)
      .then((res) => {
        let count = Math.ceil(res.metadata.total / this.state.pageSize);
        const data = res.items;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(', ');
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        this.setState({
          count: res.metadata.total,
          callList: data,
          loading: false,
          totalPages: count,
          currentPage: value,
          // pageSize: this.state.pageSize,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  getDispositions = () => {
    this.setState({ loading: true });
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(cloudCallUrl + 'disposition', data)
      .then((res) => {
        this.setState({ dispositions: res?.data?.response?.dispositions || [] })
        this.setState({ loading: false });
        return true;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };


  getIncomingCallLogCount = () => {
    GET(`${incomingCallsUrl}v1/organisations/calls`, {
      pageNo: 0,
      pageSize: 1,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ incomingCallLogCount: res.payload.count }))
      .catch((err) => console.log(err));
  };
  getDIDCallBackCount = () => {
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    GET(`${incomingCallsUrl}v1/organisations/did-callback-list`, {
      pageNo: 0,
      pageSize: 1,
      organisationId: orgId,
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ didCallBackCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  getCallBlackListCount = () => {
    GET(`${incomingCallsUrl}v1/organisations/black-list`, {
      pageNo: 1,
      pageSize: 10,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ blackListCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  trimNumber = (number) => {
    if (number && number.length === 12) return number.substring(2);
    return number;
  };

  render() {
    const { callList } = this.state;
    const { initCall, className } = this.props;
    const userRole = localStorage.getItem('doosra-biz-user-role');
    let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
    isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
    const {
      callForwardingStatus,
      isOutboundCallEnabled,
      // isCallForwardingAllowed,
      isOrgOutboundCallEnabled,
      isCallLogEmailSent,
    } = this.props;
    const tabs = [];

    if (
      (userRole === 'ADMIN' && callForwardingStatus) ||
      // (userRole === 'USER' && callForwardingStatus && isCallForwardingAllowed)
      (userRole === 'USER' && callForwardingStatus)
    )
      tabs.push({
        title: 'Incoming',
        subtitle: `${this.state.incomingCallLogCount}`,
        onClick: () => {
          logPostHogEvent(
            userRole === 'ADMIN' ? ADMIN_INCOMING_TAB : INCOMING_TAB
          );
          this.props.history.push('/call-logs');
        },
      });

    if (
      (userRole === 'ADMIN' && isOrgOutboundCallEnabled) ||
      (userRole === 'USER' && isOutboundCallEnabled && isOrgOutboundCallEnabled)
    ) {
      // if (!isFreeTrail) {
      //   tabs.push({
      //     title: 'Callback',
      //     subtitle: `${this.state.didCallBackCount}`,
      //     onClick: () => {
      //       logPostHogEvent(
      //         userRole === 'ADMIN' ? ADMIN_DID_CALL_BACK_TAB : DID_CALL_BACK_TAB
      //       );
      //       this.props.history.push('/did-call-back');
      //     },
      //   });
      // }
      tabs.push({
        title: 'Outgoing',
        subtitle: `${this.state.count}`,
        onClick: () => {
          logPostHogEvent(
            userRole === 'ADMIN' ? ADMIN_OUTGOING_TAB : OUTGOING_TAB
          );
          this.props.history.push('/outgoing-call-logs');
        },
        active: true,
      });
    }

    // if (userRole === 'ADMIN') {
    //   tabs.push({
    //     title: 'BlackList',
    //     subtitle: `${this.state.blackListCount}`,
    //     onClick: () => {
    //       logPostHogEvent(ADMIN_BLACKLIST_TAB);
    //       this.props.history.push('/black-list');
    //     },
    //   });
    // }

    const isCallLogEnabled =
      (userRole === 'ADMIN' && isOrgOutboundCallEnabled) ||
      (userRole === 'USER' &&
        isOutboundCallEnabled &&
        isOrgOutboundCallEnabled) ||
      (userRole === 'ADMIN' && callForwardingStatus === true) ||
      (userRole === 'USER' && callForwardingStatus === true);

    if (!isCallLogEnabled) {
      return (
        <div className={`${className}`}>
          <Sidebar />
          <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
            <Topbar />
            <div className="ContentContainer">
              <UpgradeServices
                icon="callLogs"
                heading="Call Logs & outgoing calling"
                text="View calls of all agents in your organisation with call recordings and a dialer."
                emailSent={isCallLogEmailSent}
              />
            </div>
          </div>
        </div>
      );
    }

    const updateFilteredHeader = (header, isSelected) => {
      if (isSelected) {
        this.setState({ filteredHeaders: [...this.state.filteredHeaders, header] });
        this.props.setOutGoingCallsTableHeaders({ outGoingCallsTableHeaders: [...this.state.filteredHeaders, header] })
      } else {
        const unFilteredHeaders = this.state.filteredHeaders.filter(
          (headerType) => headerType !== header
        );
        this.setState({ filteredHeaders: unFilteredHeaders })
        this.props.setOutGoingCallsTableHeaders({ outGoingCallsTableHeaders: unFilteredHeaders })
      }
    };

    function timeStringToPulse(time) {
      let pulses = '-';
      if (!time) return '-';
      console.log(time);
      const timeString = time + '';
      if (timeString.includes(':')) {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        const totalMinutes = hours * 60 + minutes + (seconds > 0 ? 1 : 0);
        pulses =
          Math.floor(totalMinutes / 60) + (totalMinutes % 60 > 0 ? 1 : 0);
      } else {
        const totalSeconds = Number(timeString);
        pulses = Math.ceil(totalSeconds / 60);
      }
      return pulses * 2;
    }

    return (
      <div className={className}>
        <Sidebar />
        <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />
          <div className="ContentContainer">
            <TabSearchBar tabs={tabs}>
              <SearchBar
                type="outgoing-calls"
                disableTitle
                count={this.state.count}
                reset={this.getCallList}
                search={this.seachHandler}
                headers={Object.keys(OUTGOING_CALLS_MAPPER)}
                updateFilteredHeader={updateFilteredHeader}
                filteredHeaders={this.state.filteredHeaders}
                isPopUpOpen={this.state.isHeadersPopUpOpen}
                openHeadersPopUp={() => { this.setState({ isHeadersPopUpOpen: true }) }}
                closeHeadersPopUp={() => this.setState({ isHeadersPopUpOpen: false })}
                hideTableHeaders={this.state.hideTableHeaders}
              />
            </TabSearchBar>
            <DataTable.Table>
              {this.state.loading && <PreLoader />}
              <DataTable.Head>
                {userRole !== 'USER' && (
                  <>
                    <DataTable.CellHead width="100">
                      AGENT NAME
                    </DataTable.CellHead>
                    <DataTable.CellHead width="100">
                      AGENT PRIMARY NO
                    </DataTable.CellHead>
                  </>
                )}
                {this.state.filteredHeaders &&
                  this.state.filteredHeaders.map((header) => {
                    switch (header) {
                      case "OUTGOING NUMBER":
                      case "DESTINATION":
                      case "CONTACT NAME":
                      case "CALL STATUS":
                      case "DURATION":
                      case "CALL RECORD":
                        return (
                          <DataTable.CellHead width="100">
                            {header}
                          </DataTable.CellHead>
                        )
                      case "NOTES":
                      case "TAGS":
                      case "PULSE COUNT":
                        return (
                          <DataTable.CellHead width="70">
                            {header}
                          </DataTable.CellHead>
                        )
                      case "DISPOSITION":
                      case "CALLCOMMENT":
                      case "TIME":
                      case "DATE":
                        return (
                          <DataTable.CellHead width="50">
                            {header}
                          </DataTable.CellHead>
                        )

                    }


                  })}
                <DataTable.CellHead width="50">
                  ACTION
                </DataTable.CellHead>
              </DataTable.Head>
              <DataTable.Body>
                {(callList || []).map((log) => (
                  <DataTable.Row key={log?._id}>
                    {userRole !== 'USER' && (
                      <>
                        <DataTable.Cell>
                          {log?.user?.zvr_name || '-'}
                        </DataTable.Cell>
                        <DataTable.Cell>{log.initiatorNumber}</DataTable.Cell>
                      </>
                    )}

                    {this.state.filteredHeaders && this.state.filteredHeaders.map((header) => {
                      switch (header) {
                        case "OUTGOING NUMBER":
                          return (<DataTable.Cell>{log.didNumber}</DataTable.Cell>)
                        case "DESTINATION":
                          return (
                            <DataTable.Cell>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>{log.destinationNumber}</div>
                                {userRole === 'USER' && (
                                  <div
                                    style={{
                                      backgroundColor: '#287dc9',
                                      height: 24,
                                      width: 24,
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      marginLeft: 8,
                                    }}
                                    onClick={() => {
                                      if (localStorage.getItem('is_dialer_widget_enabled') === 'true') {
                                        if (this.props.agentStatus === 'Online') {
                                          this.props.setIsOpen({ isOpen: true })
                                          this.props.setDestinationNumber({ destinationNumber: log.destinationNumber });
                                          this.props.setCallId({ callId: log._id });
                                          console.log('call id', log._id);
                                          this.props.setContactId({
                                            contactId: ""
                                          });
                                        } else {
                                          if (this.props.agentStatus === "Busy") {
                                            toast.error("There is a active call");
                                          }
                                          else {
                                            toast.error('Please go to Online');
                                          }
                                        }
                                      }
                                    }}
                                  >
                                    <CallIcon
                                      style={{
                                        backgroundColor: '',
                                        height: '10px',
                                        color: 'white',
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </DataTable.Cell>
                          )
                        case "CONTACT NAME":
                          return (<DataTable.Cell>{log.contact_name || '-'}</DataTable.Cell>)
                        case "NOTES":
                          return (<DataTable.Cell>
                            <div className="tableNotes">{log.notes || '-'}</div>
                          </DataTable.Cell>)
                        case "TAGS":
                          return (
                            <DataTable.Cell>
                              {log?.tags?.length > 0
                                ? log.tags.map((tag, index) => {
                                  return (
                                    <div key={index} className="tableTags">
                                      <img
                                        alt="tag"
                                        src={TagIcon}
                                        style={{
                                          width: '15px',
                                          height: '15px',
                                          marginRight: 7,
                                        }}
                                      />
                                      <span>{tag}</span>
                                    </div>
                                  );
                                })
                                : '-'}
                            </DataTable.Cell>
                          )
                        case "CALL STATUS":
                          return (

                            log.status ? (
                              DICT[log.status] === 'Answered' ? (
                                <DataTable.CellAnsweredCall>
                                  {DICT[log.status]}
                                </DataTable.CellAnsweredCall>
                              ) : (
                                <DataTable.CellMissedCall>
                                  {DICT[log.status]}
                                </DataTable.CellMissedCall>
                              )
                            ) : (
                              <DataTable.CellMissedCall>
                                {'Unanswered'}
                              </DataTable.CellMissedCall>
                            )

                          )
                        case "DURATION":
                          return (
                            <DataTable.Cell>
                              {log.duration === 0
                                ? '-'
                                : moment('1900-01-01 00:00:00')
                                  .add(log.duration, 'seconds')
                                  .format('HH:mm:ss')}
                            </DataTable.Cell>
                          )
                        case "DISPOSITION":
                          return (
                            <DataTable.Cell>
                              {log?.disposition || "-"}
                            </DataTable.Cell>
                          )
                        case "CALLCOMMENT":
                          return (
                            <DataTable.Cell>
                              {log?.callComment || "-"}
                            </DataTable.Cell>
                          )
                        case "PULSE COUNT":
                          return (
                            <DataTable.Cell>
                              {log?.pulseCount || timeStringToPulse(log?.duration)}
                            </DataTable.Cell>
                          )
                        case "CALL RECORD":
                          return (
                            <DataTable.Cell>
                              {!log.duration || log.duration === 0 ? (
                                '-'
                              ) : (
                                <AudioPlayer
                                  callRecordingUrl={log.recording}
                                  callId={log._id}
                                />
                              )}
                            </DataTable.Cell>
                          )
                        case "TIME":
                          return (
                            <DataTable.Cell>
                              {moment(log.createdAt).format('HH:mm:ss')}
                            </DataTable.Cell>
                          )
                        case "DATE":
                          return (
                            <DataTable.Cell>
                              {moment(log.createdAt).format('DD-MM-YYYY')}
                            </DataTable.Cell>
                          )

                      }
                    })}
                    <DataTable.Cell>
                      <div style={{ display: 'flex', gap: 5 }}>
                        <AddIndividualCallDispositionsPopup
                          open={this.state.isModalOpen}
                          toggle={this.toggleModal}
                          dispositions={this.state.dispositions}
                          callId={log._id}
                          updateCallDispositonTag={this.fetchPageData}
                          isDisabled={!log.disposition || log.disposition === 'RNR' ? false : true
                          }
                        ></AddIndividualCallDispositionsPopup>

                        {log.is_contact ? (
                          <ViewContactPopup
                            key={log._id}
                            contactInfo={log.contactObj}
                            getList={this.fetchPageData}
                            editFrom="callLog"
                            userId={log?.user?.user_id}
                          />
                        ) : (
                          isSuperAdminWithPermission('EDIT') &&
                          userRole === 'USER' && (
                            <AddContactNotesTagsPopup
                              callerNo={log.destinationNumber}
                              getList={this.fetchPageData}
                            />
                          )
                        )}
                      </div>
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}
              </DataTable.Body>
            </DataTable.Table>
            <Paginator
              currentPage={this.state.currentPage}
              lastPage={this.state.totalPages}
              getInfo={(value) => this.fetchPageData(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isOpen: state.dailpad.isOpen,
  destinationNumber: state.dailpad.destinationNumber,
  agentStatus: state.dailpad.agentStatus,
  callPriorityRoutingStatus: state.topbar.call_priority_routing,
  callForwardingStatus: state.topbar.call_forwarding_status,
  isOrgOutboundCallEnabled: state.topbar.isOrgOutboundCallEnabled,
  isOutboundCallEnabled: state.topbar.isOutboundCallEnabled,
  isCallForwardingAllowed: state.topbar.isCallForwardingAllowed,
  isCallLogEmailSent: state.topbar.isCallLogEmailSent,
  outGoingCallsTableHeaders: state.recovery.outGoingCallsTableHeaders,
});

export default commonStyle(
  connect(mapStateToProps, { initCall, setIsOpen, setDestinationNumber, setCallId, setContactId, setOutGoingCallsTableHeaders })(OutgoingCallLogsPage)
);

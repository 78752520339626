/* eslint-disable no-unused-vars */
import adapter from 'webrtc-adapter';
import './StickyDialer.css';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import JsSIP from 'jssip';
import { toast } from 'react-toastify';
import AlohaaLogoWhite from '../../assets/images/AlohaaLogoWhite.png';
import AlohaaWhiteLogoNoBackground from '../../assets/images/AlohaaWhiteLogoNoBackground.svg';
import HangupLogo from '../../assets/images/RecoveryHangup.webp';
import HoldLogo from '../../assets/images/PauseIcon.png';
import MuteLogo from '../../assets/images/MicLogo.png';
import activeStatus from '../../assets/images/activeStatus.svg';
import offline from '../../assets/images/offline.svg';
import Popup from 'reactjs-popup';
import { RiRefreshFill } from 'react-icons/ri';
import {
  DEV_INHOUSE_PBX_SERVERS,
  PROD_INHOUSE_PBX_SERVERS,
  TURN_SERVER_CONFIG,
} from '../../assets/constants';
import { GET, POST, PUT } from '../../services/HttpRequests';
import { useSocket } from '../../providers/SocketProvider';
import {
  setWebRtcConnectionStatus,
  setDialerCallId,
  setIsSingleCall,
  changeDispostionStatus,
  changeAgentActiveStatus,
  setWebRtcSessions,
} from '../../actions/recoveryXActions';
import PreLoader from '../../common/component/preLoader/PreLoader';
import phoneWhite from '../../assets/images/phoneWhiteRecoveryCall.svg';
import { MenuItem, Select } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ReceiveCall from './Incoming';
JsSIP.debug.enable('JsSIP:*');
const INHOUSE_PBX_SERVERS =
  process.env.REACT_APP_ENV === 'DEV'
    ? DEV_INHOUSE_PBX_SERVERS
    : PROD_INHOUSE_PBX_SERVERS;
const sip_details = JSON.parse(localStorage.getItem('sip_details'));
const username = sip_details?.username;
const password = sip_details?.password;
const pbxServicesUrl = process.env.REACT_APP_PBX_SERVICES;
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
const webRTCOutGoingUrl = process.env.REACT_APP_WEBRTC_RECOVERY_API_URL;
const userUrl = process.env.REACT_APP_USER_API;
const overlayStyle = { background: 'rgba(0,0,0,0)' };
const contentStyle = {
  background: 'transparent',
  border: 'none',
  width: 'auto',
  padding: '24px',
  borderRadius: '8px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  border: 'none',
  backgroundColor: '#333333',
  width: '418px', // Adjust as needed
  top: '-40%', // Adjust to position it at the center top
  left: '0', // Center horizontally
  transform: 'translateX(-50%)', // Center horizontally
};
let userRole = localStorage.getItem('doosra-biz-user-role');

const StickyDialer = (props) => {
  const history = useHistory();
  let showErrorMessage = true;
  const [isOpen, setIsOpen] = useState(true);

  const [isActive, setIsActive] = useState(false);
  const [ConnectedCallData, setConnectedCallData] = useState();

  const [loading, setLoading] = useState(false);
  const [selectedAgentStatus, setSelectedAgentStatus] = useState('offline');
  const [timer, setTimer] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [incomingCallPopup, setIncomingCallPopup] = useState(false); // top center receive popup
  const [incomingCallNo, setIncomingCallNo] = useState(null);
  const optionschangeAgentActiveStatus = [
    {
      value: 'active',
      label: 'Active',
      image: activeStatus,
      color: '#4daa3e',
      disable: false,
    },

    {
      value: 'offline',
      label: 'Offline',
      image: offline,
      color: '#939598',
      disable: false,
    },
  ];

  const [UA, setUA] = useState(null);
  const [RTCSession, setRTCSession] = useState(null);

  useEffect(() => {
    if (isActive) {
      const server = 'pbx1.dev.notring.com';
      // const username = '8446183906';
      // const password = '8446183906';

      const socket = new JsSIP.WebSocketInterface(`wss://${server}/ws`);
      const uaConfig = {
        uri: `sip:${username}@${server}`,
        password: password,
        sockets: [socket],
        connection_recovery_min_interval: 10,
      };
      const ua = new JsSIP.UA(uaConfig);
      setUA(ua);
      ua.start();
      ua.on('disconnected', (e) => {
        console.log('disconnected - ', e);
        setIsActive(false);
      });
    } else {
      // Stop the socket when "Offline" button is clicked
      if (UA) {
        UA.stop();
      }
    }
    return () => {
      // Cleanup function to stop the UA when the component is unmounted
      if (UA) {
        UA.stop();
      }
    };
  }, [isActive]);

  const options = {
    mediaConstraints: { audio: true, video: false },
    pcConfig: {
      iceServers: [
        {
          urls: ['stun:stun.l.google.com:19302'],
        },
        {
          urls: ['turn:36.255.252.90:3478'],
          username: 'doosra',
          credential: 'dmlqdHVybnNlcnZlcgo=',
        },
      ],
    },
  };

  useEffect(() => {
    if (UA) {
      UA.on('newRTCSession', (e) => {
        const newSession = e.session;
        setRTCSession(newSession);

        // Extract caller information from the session
        const callerNumber = e?.session?._request?.getHeader('initiatorNumber');

        setIncomingCallNo(callerNumber);

        newSession.on('icecandidate', (candidate) => {
          console.log('iceCandidate gathered - ', candidate);
        });

        newSession.on('icegatheringstatechange', (event) => {
          console.log(
            'icegatheringstatechange - ',
            event.target.iceGatheringState
          );
        });

        if (newSession.direction === 'incoming' && newSession.status === 4) {
          setIncomingCallPopup(true); // Show the Accept button for incoming calls
          console.log('----------------------------------4');
          newSession.on('accepted', function (event) {
            console.log('----------------------------------5');
            console.log(' Call Accepted ');
          });

          newSession.on('confirmed', function (event) {
            console.log('----------------------------------6');
            console.log('Call confirmed');
          });

          newSession.on('failed', (event) => {
            console.log('----------------------------------7');
            console.log(' Call failed ');
          });

          newSession.on('ended', (event) => {
            console.log(
              '----------------------------------8',
              'c1: call canceled from UI'
            );
            setRTCSession(null);
            setIncomingCallPopup(false);
            setRunTimer(false);
            setTimer(0);
            console.log(' Call hangup ');
          });

          if (newSession.status === 4) {
            console.log('----------------------------------9');
            // newSession.answer(options);
          }
          if (newSession.connection) {
            newSession.connection.addEventListener('addstream', (e) => {
              console.log('----------------------------------10');
              var audio = new Audio();
              audio.srcObject = e.stream;
              audio.play();
            });
          }
        }
      });
    }
  }, [UA]);

  const acceptCall = () => {
    console.log('accept call');
    if (RTCSession) {
      setRunTimer(true);
      RTCSession.answer(options);
      setIncomingCallPopup(false); // Hide the Answer popup after answering the call
    }
  };

  const endCall = () => {
    console.log('----------------------------------end');
    if (RTCSession) {
      setRunTimer(false);
      setTimer(0);
      RTCSession.terminate();
      setRTCSession(null);
      setIncomingCallPopup(false); // Hide the Answer popup when the call is ended
    }
  };

  // timer useeffect
  useEffect(() => {
    if (runTimer) {
      const interval = setInterval(() => {
        setTimer((t) => t + 1);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [timer, runTimer]);

  //component mount agent status check
  useEffect(() => {
    console.log('agent status', props.agentActiveStatus);
    console.log('is active', isActive);
    setIsActive(props.agentActiveStatus);
    setSelectedAgentStatus(props.agentActiveStatus ? 'active' : 'offline');
  }, []);

  const handleAgentStatus = async (e, showToast = true) => {
    let status = false;
    console.log('e', e.target.value);
    if (e.target.value === 'offline') {
      status = false;
    }
    if (e.target.value === 'active') {
      status = true;
    }

    await PUT(userUrl + 'v1/user/webrtc/status/toggle', null, {
      status: true,
    })
      .then((res) => {
        setIsActive(status);
        props.changeAgentActiveStatus({ status });
        setSelectedAgentStatus(e.target.value);
        toast.success(status ? 'Agent online' : 'Agent Offline now');
      })
      .catch((err) => {
        toast.error('An error occured');
      });
  };

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    let formattedTime;
    if (hours > 0) {
      formattedTime = `${hours.toString().padStart(2, '0')}: ${minutes
        .toString()
        .padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
      formattedTime = `${minutes
        .toString()
        .padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    }
    return formattedTime;
  }

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="panel-container">
      {loading && <PreLoader />}

      <Popup
        open={incomingCallPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        {...{ overlayStyle, contentStyle }}
      >
        <div>
          <p
            style={{
              fontSize: '16px',
              fontWeight: '500',
              color: '#07aa3f',
              textAlign: 'center',
              margin: '0',
            }}
          >
            Incoming Call
          </p>
          <p
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color: '#fff',
              textAlign: 'center',
            }}
          >
            {incomingCallNo}
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            <button
              style={{
                width: '178px',
                height: '52px',
                borderRadius: '8px',
                border: '#07aa3f',
                backgroundColor: '#07aa3f',
              }}
              onClick={acceptCall}
            >
              <img src={phoneWhite} />
            </button>
            <button
              style={{
                width: '178px',
                height: '52px',
                borderRadius: '8px',
                border: '#ed3833',
                backgroundColor: '#ed3833',
              }}
              onClick={endCall}
            >
              <img src={HangupLogo} alt="hangup" />
            </button>
          </div>
        </div>
      </Popup>

      <div className={`panel ${isOpen ? 'open' : ''}`}>
        <div className="panel-content-logo">
          <img
            src={AlohaaWhiteLogoNoBackground}
            alt="Alohaa-White"
            class="white-logo-no-background"
          />
        </div>
        <div className="line-break"></div>
        <div>
          <Select
            className="Rectangle-10578"
            value={selectedAgentStatus}
            onChange={handleAgentStatus}
          >
            {optionschangeAgentActiveStatus.map((option) => (
              <MenuItem
                value={option.value}
                disabled={option.disable}
                style={{ boxShadow: 'none', fontSize: '15px' }}
              >
                <span
                  key={option.value}
                  style={{
                    display: 'flex',
                    color: option.color,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;
                  <img
                    src={option.image}
                    style={{ fill: option.color }}
                    alt=".."
                  />
                  &nbsp;
                  <span
                    style={{
                      padding: '5px',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                    }}
                  >
                    {option.label}
                  </span>
                </span>
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="line-break"></div>

        {runTimer && (
          <>
            <div className="connected-line">
              <span className="connected-line-name">
                {incomingCallNo || '-'}
              </span>
              <span className="connected-line-number">
                {/* {ConnectedCallData?.number || '-'} */}
              </span>
              <span className="connected-line-timer">
                {formatTime(timer) || '-'}
              </span>
              <span className="connected-line-status">connected</span>
            </div>
            <button className="call-end-button" onClick={endCall}>
              <img src={HangupLogo} alt="hangup" />
            </button>
          </>
        )}
      </div>
      <div className="toggle-area" onClick={togglePanel}>
        <img
          src={AlohaaLogoWhite}
          alt="Alohaa"
          className="toggle-area-image"
        ></img>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // isWebRtcConnected: state.recovery.isWebRtcConnected,
  // dialerCallId: state.recovery.dialerCallId,
  // isSingleCall: state.recovery.isSingleCall,
  // dispositionStatus: state.recovery.dispositionStatus,
  // webRTCSessions: state.recovery.webRTCSessions,
  agentActiveStatus: state.incoming.agentActiveStatus,
});
export default connect(mapStateToProps, {
  setWebRtcConnectionStatus,
  setDialerCallId,
  setIsSingleCall,
  changeDispostionStatus,
  changeAgentActiveStatus,
  setWebRtcSessions,
})(StickyDialer);

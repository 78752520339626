import { useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';

const PopupContainer = {
  width: '400px',
};
const scrollEffect = {
  overflowY: 'auto',
};

export default function BroadcastLeads(props) {
  return (
    <>
      <Popup open={props.open}>
        <img
          style={{ left: '96%', top: '-5%', zIndex: '1' }}
          alt=""
          className="PopupClose"
          src={Close}
          onClick={() => {
            props.close();
          }}
        />
        <div
          className="PopupContainer"
          style={{ ...PopupContainer, ...scrollEffect }}
        >
          <div className="PopupHeaderContainer" style={{ ...PopupContainer }}>
            <p>Broadcast Leads</p>
          </div>
          <div className="PopupBody" style={{ ...PopupContainer }}>
            <div
              style={{
                maxHeight: '400px',
                overflow: 'auto',
                margin: '16px 16px',
                textAlign: 'center',
              }}
            >
              {props?.info?.leads_numbers?.length > 0 &&
                props.info.leads_numbers.map((item, index) => (
                  <p style={{ margin: 0 }}>
                    {index + 1}. {item}
                  </p>
                ))}
            </div>
            <button
              className="ButtonCancelFullWidth"
              style={{ marginTop: '16px' }}
              onClick={() => props.close()}
            >
              Close
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
}

/* eslint-disable */
import React, { Component } from 'react';
import styled from '@emotion/styled';

import '../../../assets/css/SearchBar.css';
import { toast } from 'react-toastify';

class SearchBar extends Component {
  constructor() {
    super();
    this.state = {
      searchBy: '',
      searchValue: '',
      btnOnReset: false,
      isModalOpen: false,
      fromDate: new Date().toISOString().slice(0, 10),
      toDate: new Date().toISOString().slice(0, 10),
      userRole: localStorage.getItem('doosra-biz-user-role'),
    };
    this.initialState = this.state;
  }

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value, btnOnReset: false });
  };

  toggleModal = () => {
    console.log({ isModalOpen: !(this.state.isModalOpen) })
    this.setState({ isModalOpen: !(this.state.isModalOpen) });
  }

  addLeadHandler = (data) => {
    this.props.addLeadHandler(data);
  }
  initiateRecoveryCall = (data) => {
    this.props.initiateRecoveryCall(
      data.destinationNumber,
      data.name,
      data.isLeadListIndependentCall
    )
  }

  dropDownSelectHandler = (e) => {
    this.setState({
      searchBy: e.target.value,
      btnOnReset: false, //change to true
      searchValue: '',
    });
  };

  onSearch = () => {
    let data = {};

    if (this.state.searchBy === '' ||
      (this.state.searchBy === 'name' && this.state.searchValue === '') ||
      ((this.state.searchBy === 'startDate' || this.state.searchBy === 'endDate') &&
        this.state.fromDate === '' && this.state.toDate === '')) return;
    data.searchKey = this.state.searchBy;
    data.searchValue = this.state.searchValue;
    data.pageNo = 1;
    data.pageSize = 15;
    data.fromDate = this.state.fromDate;
    data.toDate = this.state.toDate;
    console.log("the search data is", data);
    this.props.search(data);
    this.setState({ btnOnReset: true });
  };

  onReset = () => {
    this.setState(this.initialState);
    let data = {};
    data.leadListId = this.props.leadListId;
    this.props.reset(data);
  };

  resetBtnHandler = (value) => {
    this.setState({ btnOnReset: value });
  };

  render() {
    let searchInput;
    const { disableTitle } = this.props;

    return (
      <div className={`${this.props.className}`} style={{ marginBottom: '10px' }}>
        <div className="SearchBarComponent" style={{
          padding: '10px',
        }}>
          <div
            style={{
              display: 'flex',
              gap: '16px',
              padding: '16px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: '12px',
                fontWeight: 500,
                color: '#808080',
              }}
            >
              Filter
            </p>
            <div
              className={
                this.props.current === 'ACTIVE' ? 'ActiveFilterTab' : 'InactiveFilterTab'
              }
              onClick={() => this.props.fetchCampaignListHandler(null, "ACTIVE")}
            >
              ACTIVE
            </div>

            <div
              className={
                this.props.current === 'COMPLETED'
                  ? 'ActiveFilterTab'
                  : 'InactiveFilterTab'
              }
              onClick={() => this.props.fetchCampaignListHandler(null, "COMPLETED")}
            >
              COMPLETED
            </div>
          </div>

          <div style={{ flex: 1 }} />
          {
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className="SearchText">SEARCH BY</div>
              <select
                className="SearchDropdown"
                id="searchBy"
                value={this.state.searchBy}
                onChange={this.dropDownSelectHandler}
              >
                <option>Select</option>

                <React.Fragment>
                  <option value="name">CAMPAIGN NAME</option>
                  <option value="startDate">START DATE</option>
                  <option value="endDate">END DATE</option>
                </React.Fragment>
              </select>
              {(this.state.searchBy === 'startDate' || this.state.searchBy === 'endDate')
                && <React.Fragment>
                  <input
                    id="fromDate"
                    value={this.state.fromDate}
                    onChange={this.changeHandler}
                    className="SearchBox"
                    style={{ padding: '0px' }}
                    type="date"
                  />
                  <input
                    id="toDate"
                    value={this.state.toDate}
                    onChange={this.changeHandler}
                    style={{ padding: '0px' }}
                    className="SearchBox"
                    type="date"

                  />
                </React.Fragment>}
              {this.state.searchBy === 'name' && <input
                id="searchValue"
                value={this.state.searchValue}
                onChange={this.changeHandler}
                className="SearchBox"
                placeholder="Search..."
              />}

            </div>
          }

          <div>
            {this.state.btnOnReset ? (
              <button
                className="ClearResultButton"
                style={{
                  border: 'none',
                }}
                onClick={this.onReset}
              >
                CLEAR RESULTS
              </button>
            ) : (
              <>
                <button
                  className="SearchButton"
                  style={{ width: '100px', margin: '0 8px' }}
                  onClick={this.onSearch}
                >
                  SEARCH
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default styled(SearchBar)`
display: flex;
flex: 1;

input::placeholder {
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}
input {
  color-scheme: ${(props) => props.theme.colors.dateCalenderIconColor};
}
.SearchBarComponent {
  width: 100%;
  height: 54px;
  background-color: ${(props) => props.theme.colors.searchBarBgcolor};
  display: flex;
  grid-template-columns: 136px auto auto 116px;
  align-items: center;
  border-radius: 1px;
  box-shadow: 0px -15px 10px -15px rgb(0, 0, 0, 0.4);
  border: solid 1px ${(props) => props.theme.colors.searchBarBgcolor};
}

.SearchText {
  font-size: 12px;
  font-weight: 500;
  margin: auto 10px;
  /* letter-spacing: 1.2px; */
  text-align: left;
  color: ${(props) => props.theme.colors.searchByColor};
}

.SearchDropdown {
  width: 150px;
  height: 32px;
  font-size: 13px;
  margin: auto 5px;
  padding: 0 5px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchBox {
  width: 136px;
  height: 32px;
  margin: auto 5px;
  font-size: 13px;
  padding: 0 10px;
  /* padding: 8px 16px 9px; */
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchButton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.ClearResultButton{
  width: 120px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
  width: '128px',
  border: none,
}
.Otherbutton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.theme.colors.lightGreyBgColor};
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.SelectedContainer {
  width: 136px;
  height: 54px;
  background-color: #196cca;
}

.SelectedContainer h6 {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding-top: 11px;
  padding-left: 14px;
  color: #ffffff;
}

.SelectedContainer p {
  font-size: 16px;
  font-weight: bold;
  padding-left: 14px;
  margin: 0;
  letter-spacing: 1.2px;
  color: #ffffff;
}

@media only screen and (max-width: 900px) {
  .SearchBarComponent {
    width: fit-content;
    grid-template-columns: 48px 136px 77px auto 150px;
  }
}
`;

import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { GET } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import Success from '../../../assets/images/greenSuccessBig.png';

const planUrl = process.env.REACT_APP_PLAN_API;

const overlayStyle = { background: 'rgba(0,0,0,0.9)' };
const contentStyle = {
  width: '556px',
  padding: '80px 82px',
  borderRadius: '8px',
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
};
const WelcomeText = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.82;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #07aa3f;
  margin: 0;
  margin-bottom: 24px;
`;

const SubHead = styled.p`
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin: 0;
  margin-bottom: 40px;
`;

const Text = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin: 0;
`;

const ProceedButton = styled.button`
  width: 336px;
  height: 48px;
  border: none;
  border-radius: 4px;
  background-color: #07aa3f;
  font-size: 15px;
  font-weight: bold;
  line-height: 3.4;
  letter-spacing: 0.75px;
  color: #fff;
  margin: 0;
  margin-bottom: 24px;
`;

export default function InitialPaymentPopup() {
  const [fetching, setFetching] = useState(false);
  const [zohoUrl, setZohoUrl] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const planCodeParam = urlParams.get('plan_code');
  const isTrialParam = urlParams.get('is_trial');

  const plan_code = localStorage.getItem('alohaa_plan_code') || planCodeParam;
  const is_trial = localStorage.getItem('alohaa_plan_is_trial') || isTrialParam;

  console.log(
    'isTrial',
    is_trial,
    localStorage.getItem('alohaa_plan_is_trial'),
    isTrialParam
  );

  if (!plan_code) {
    window.location.href = 'https://alohaa.webflow.io/pricing-page';
  }

  useEffect(() => {
    setFetching(true);
    const data = {};
    if (plan_code) {
      data.plan_code = plan_code;
    }
    if (is_trial && is_trial !== 'null') {
      data.is_trial = is_trial;
    }

    GET(planUrl + 'v1/alohaa/plans/payment-link', data)
      .then((res) => {
        setZohoUrl(res?.data?.response || null);
        setFetching(false);
      })
      .catch((err) => {
        console.log('error', err.message);
        toast.error('An error occured');
        setFetching(false);
      });
  }, []);

  const redirectToPayment = () => {
    window.location.href = zohoUrl;
  };

  return (
    <Popup
      open={true}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      {...{ overlayStyle, contentStyle }}
    >
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img alt="" src={Success} style={{ marginBottom: '40px' }} />
        <WelcomeText>Account created, welcome to Alohaa!</WelcomeText>

        {is_trial !== 'null' ? (
          <>
            <SubHead>
              You're one step away from experiencing the 7 day free trial!
            </SubHead>
            <Text style={{ marginBottom: '24px' }}>
              {' '}
              Add your card details to proceed
            </Text>
            <ProceedButton
              style={{ backgroundColor: '#000' }}
              disabled={fetching}
              onClick={redirectToPayment}
            >
              ADD CARD DETAILS
            </ProceedButton>
            <Text style={{ color: '#939598' }}>Cancel anytime</Text>
            <Text style={{ color: '#939598' }}>Try it first, payment only</Text>
            <Text style={{ color: '#939598' }}>
              after your 7 day free trial
            </Text>
          </>
        ) : (
          <>
            <SubHead>Great choice on your plan</SubHead>
            <Text>Complete your payment now to</Text>
            <Text style={{ marginBottom: '24px' }}>
              unlock the best of Alohaa
            </Text>
            <ProceedButton
              style={{ backgroundColor: '#000' }}
              disabled={fetching}
              onClick={redirectToPayment}
            >
              ADD CARD DETAILS
            </ProceedButton>
          </>
        )}
      </div>
    </Popup>
  );
}

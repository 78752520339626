import React, { Component } from 'react';
import check from '../../../assets/images/greenTickSmall.png';
import uncheck from '../../../assets/images/greyTickSmall.png';

class PasswordCheck extends Component {
  render() {
    let charNumberValid = false;
    let specialCharValid = false;
    let uppercaseValid = false;
    let lowercaseValid = false;
    let numberValid = false;
    // eslint-disable-next-line no-useless-escape
    const spectialCharPattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    const upperCasePattern = /[A-Z]/;
    const lowerCasePattern = /[a-z]/;
    const numberPattern = /[0-9]/;

    this.props.password.length >= 6
      ? (charNumberValid = true)
      : (charNumberValid = false);
    spectialCharPattern.test(this.props.password)
      ? (specialCharValid = true)
      : (specialCharValid = false);
    upperCasePattern.test(this.props.password)
      ? (uppercaseValid = true)
      : (uppercaseValid = false);
    lowerCasePattern.test(this.props.password)
      ? (lowercaseValid = true)
      : (lowercaseValid = false);
    numberPattern.test(this.props.password)
      ? (numberValid = true)
      : (numberValid = false);

  if (this.props.method && this.props.method === "signup") {
    const result = charNumberValid && specialCharValid && uppercaseValid && lowercaseValid && numberValid;
    this.props.passwordChecker(result);
  }

    return (
      <div className="PasswordCheckContainer">
        <h1>Your password must contain</h1>
        <div className="PasswordCheckContentContainer">
          {charNumberValid ? (
            <img alt="" src={check} />
          ) : (
            <img alt="" src={uncheck} />
          )}
          {charNumberValid ? (
            <p className="GreenText">6 characters minimum</p>
          ) : (
            <p className="GreyText">6 characters minimum</p>
          )}
          {specialCharValid ? (
            <img alt="" src={check} />
          ) : (
            <img alt="" src={uncheck} />
          )}
          {specialCharValid ? (
            <p className="GreenText">At least 1 special character </p>
          ) : (
            <p className="GreyText">At least 1 special character </p>
          )}
          {uppercaseValid ? (
            <img alt="" src={check} />
          ) : (
            <img alt="" src={uncheck} />
          )}
          {uppercaseValid ? (
            <p className="GreenText">At least one upper case character</p>
          ) : (
            <p className="GreyText">At least one upper case character</p>
          )}
          {lowercaseValid ? (
            <img alt="" src={check} />
          ) : (
            <img alt="" src={uncheck} />
          )}
          {lowercaseValid ? (
            <p className="GreenText">At least one lower case character</p>
          ) : (
            <p className="GreyText">At least one lower case character</p>
          )}
          {numberValid ? (
            <img alt="" src={check} />
          ) : (
            <img alt="" src={uncheck} />
          )}
          {numberValid ? (
            <p className="GreenText">At least one number</p>
          ) : (
            <p className="GreyText">At least one number</p>
          )}
        </div>
      </div>
    );
  }
}

export default PasswordCheck;

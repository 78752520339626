import React from "react";
import '../styles/webhook.css';

const KnowledeBar = () => {
    const article_url = "https://www.getvero.com/resources/webhooks"
    const video_url = "https://www.youtube.com/watch?v=mrkQ5iLb4DM";
    return (
        <div className="Knowledge-Bar">
            <div>
                <span className="Knowledge-Centre">
                    Knowledge Centre
                </span>
                <hr className="Line-Break"></hr>
            </div>
            <div>
                <span className="Head-Text">
                    Webhooks
                </span>
                <span className="Webhook-Desc">
                    {
                        `A webhook enables Alohaa to push real-time SMS and Call details to your app. 
                        You can use these responses to execute actions in your backend systems.`
                    }
                </span>
                <span className="Link">
                    Here’s a <span> <a className="text-style-1" href={video_url} rel="noopener noreferrer" target="_blank">video</a> </span>
                    and<span> <a className="text-style-1" href={article_url} rel="noopener noreferrer" target="_blank">article </a> </span>
                    that go into detail about Webhooks
                </span>
                <span className="Support-Link">
                    For help setting this up please reach out to us
                    <span className="text-style-1"> integrations@alohaa.ai</span>
                </span>
            </div>
        </div>
    )
}
export default KnowledeBar
import React, { useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import { isSuperAdminWithPermission } from '../../../utility/utility';

export default function UnbindPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="PopupContainer">
        <div className="PopupHeaderContainer">
          <p>Unbind Numbers</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => setPopupOpen(false)}
          />
        </div>
        <div className="PopupBody">
          <p className="PopupBodyTitle">
            Are you sure you want to unbind these numbers?
          </p>

          <button
            className="ButtonFullWidth BgRed"
            onClick={() => {
              props.unbindHandler(props.item);
              setPopupOpen(false);
            }}
          >
            REMOVE
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <React.Fragment>
      <button
        style={{
          width: '92px',
          height: '27px',
          'border-radius': '3px',
          'background-color': 'black',
          'font-size': '12px',
          'font-weight': 'bold',
          'font-stretch': 'normal',
          'font-style': 'normal',
          'letter-spacing': '1.2px',
          'text-align': 'center',
          color: 'white',
          border: 'none',
          margin: '5px',
        }}
        disabled={!props.isEnabled || !isSuperAdminWithPermission('EDIT')}
        onClick={() => setPopupOpen(true)}
      >
        UNBIND
      </button>
      {popper}
    </React.Fragment>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';
import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import DataTable from '../../../common/component/table/DataTable';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import DeleteAutoAttendantPopup from '../component/DeleteAutoAttendantPopup';
import EditAutoAttendantPopup from '../component/EditAutoAttendantPopup';
import { toast } from 'react-toastify';
import UpgradeServices from '../../../common/component/upgrade-services/UpgradeServices';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import { ADMIN_SEARCH_AUTO_ATTENDANT_BUTTON, ADMIN_DELETE_AUTO_ATTENDANT_BUTTON, ADMIN_EDIT_AUTO_ATTENDANT_BUTTON } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";
const autoAttendantUrl = process.env.REACT_APP_AUTO_ATTENDED_API;

export function AutoAttendantPage(props) {
  const [autoAttendantList, setAutoAttendantList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [searchData, setSearchData] = useState({});
  const [currentAudioId, setCurrentAudioId] = useState(null);
  const currentAudioRef = useRef({});

  useEffect(() => {
    fetchAutoAttendantList();
  }, []);

  const fetchAutoAttendantList = (searchParams, page, reset) => {
    setLoading(true);
    let data = {
      pageNo: page || currentPage,
      pageSize: pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    if (searchParams) {
      logPostHogEvent(ADMIN_SEARCH_AUTO_ATTENDANT_BUTTON);
      setSearchData(searchParams);
      setCurrentPage(1);
      data.pageNo = 1;
      data = { ...data, ...searchParams };
    }
    data = { ...data, ...searchData };
    if (reset) {
      setSearchData({});
      setCurrentPage(1);
      data = {};
      data.pageNo = 1;
      data.pageSize = pageSize;
      data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    }

    GET(autoAttendantUrl + `v1/auto-attendant`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        setAutoAttendantList(res.autoAttendants);
        setCount(res.metadata.total);
        setTotalPages(Math.ceil(Number(res.metadata.total) / Number(pageSize)));
        setPageSize(Number(res.metadata.query.pageSize));
        setCurrentPage(Number(page || currentPage));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const addAutoAttendantHandler = (data) => {
    setLoading(true);
    return POST(autoAttendantUrl + 'v1/auto-attendant', data)
      .then((res) => {
        setLoading(false);
        fetchAutoAttendantList();
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        throw err;
      });
  };

  const updateAutoAttendantHandler = async (data) => {
    logPostHogEvent(ADMIN_EDIT_AUTO_ATTENDANT_BUTTON);
    setLoading(true);
    const payload = {
      name: data.name,
      description: data.description,
      id: data.id,
      organisationId: data.organisationId,
    };

    const updateAutoAttendantPromise = PUT(
      autoAttendantUrl + 'v1/auto-attendant',
      null,
      payload
    );

    try {
      await updateAutoAttendantPromise;

      if (data.audio) {
        const formData = new FormData();
        formData.append('audio', data.audio);
        formData.append('id', data.id);
        formData.append(
          'organisationId',
          localStorage.getItem('doosra-biz-organisation-id')
        );

        await PUT(
          autoAttendantUrl + 'v1/auto-attendant/update-audio',
          null,
          formData
        );
      }

      setLoading(false);
      fetchAutoAttendantList();
      setCurrentAudioId(null); // Reset the currentAudioId when audio is updated
      return true;
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.error?.reason);
      throw err;
    }
  };

  const deleteAutoAttendantHandler = (id) => {
    logPostHogEvent(ADMIN_DELETE_AUTO_ATTENDANT_BUTTON);
    setLoading(true);
    const data = {
      id,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    DELETE(autoAttendantUrl + 'v1/auto-attendant', null, { ...data })
      .then((res) => {
        if (!res?.data?.success) {
          toast.error('unable to delete Auto Attendant');
        } else {
          toast.success('Auto Attendant deleted');
          fetchAutoAttendantList();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
        console.log('err', err);
        toast.error('unable to delete Auto Attendant');
      });
  };

  const stopAllAudio = (currentId) => {
    for (const audioId in currentAudioRef.current) {
      if (audioId !== currentId && currentAudioRef.current[audioId]) {
        currentAudioRef.current[audioId].pause();
      }
    }
    setCurrentAudioId(currentId);
  };

  let displayData;
  const isSuperAdmin = () =>
    localStorage.getItem('doosra-biz-user-role-is-super-admin') === 'true';

  if (loading) displayData = <PreLoader />;

  if (autoAttendantList.length > 0) {
    displayData = autoAttendantList.map((row) => (
      <DataTable.Row key={row._id}>
        <DataTable.Cell>{row.name}</DataTable.Cell>
        <DataTable.Cell>{row.description}</DataTable.Cell>
        <DataTable.Cell>
          <div className="audio-container">
            <audio
              ref={(audio) => (currentAudioRef.current[row._id] = audio)}
              style={{
                height: '24px',
                marginTop: '3px',
                marginBottom: '-2px',
              }}
              controls
              src={row.url}
              onPlay={() => stopAllAudio(row._id)}
              onPause={() => setCurrentAudioId(null)}
              id={row._id}
            />
          </div>
        </DataTable.Cell>
        {isSuperAdminWithPermission('EDIT') && (
          <DataTable.Cell>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {
                <>
                  <EditAutoAttendantPopup
                    key={row._id}
                    autoAttendantData={row}
                    updateAutoAttendantHandler={(data) =>
                      updateAutoAttendantHandler(data)
                    }
                    autoAttendantAudio={row.url}
                  />
                  <DeleteAutoAttendantPopup
                    key={row._id}
                    deleteAutoAttendantHandler={(id) =>
                      deleteAutoAttendantHandler(id)
                    }
                    id={row._id}
                  />
                </>
              }
            </div>
          </DataTable.Cell>
        )}
      </DataTable.Row>
    ));
  }

  if (props.isAutoAttendantEnabled === false) {
    return (
      <div className={`${props.className}`}>
        <Sidebar />
        <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />
          <div className="ContentContainer">
            <UpgradeServices
              icon="autoAttendant"
              heading="Auto Attendant"
              text="Answers your business number or IVR automatically"
              emailSent={props.isRecoveryXEmailSent}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <SearchBar
            type="autoAttendant"
            count={count}
            search={fetchAutoAttendantList}
            reset={() => fetchAutoAttendantList(null, null, { reset: true })}
            refreshList={fetchAutoAttendantList}
            basicUserDownloadDetail={autoAttendantList}
            addAutoAttendantHandler={(e) => addAutoAttendantHandler(e)}
          />
          <DataTable.Table>
            <DataTable.Head>
              <DataTable.CellHead>Name</DataTable.CellHead>
              <DataTable.CellHead>Description</DataTable.CellHead>
              <DataTable.CellHead>URL</DataTable.CellHead>
              <DataTable.CellHead style={{ width: '7%' }}>
                ACTIONS
              </DataTable.CellHead>
            </DataTable.Head>
            <DataTable.Body>{displayData}</DataTable.Body>
          </DataTable.Table>
          <Paginator
            currentPage={currentPage}
            lastPage={totalPages}
            getInfo={(value) => {
              fetchAutoAttendantList(null, value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isAutoAttendantEnabled: state.topbar.isAutoAttendantEnabled,
});

export default commonStyle(connect(mapStateToProps, {})(AutoAttendantPage));

const initialState = {
    run: false,
    stepIndex: 0,
    steps: [
      {
        content: <h2>Let's begin our journey!</h2>,
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: 'center',
        target: 'body',
      },
      {
        content: <h2>Sidebar 1</h2>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 0,
        placement: 'right',
        target: '.sidebar1',
      },
      {
        content: <h2>Sidebar 2</h2>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 0,
        placement: 'right',
        target: '.sidebar2',
      },
      {
        content: <h2>Sidebar 3</h2>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 0,
        placement: 'right',
        target: '.sidebar3',
      },
      {
        content: <h2>Sidebar 4</h2>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 0,
        placement: 'right',
        target: '.sidebar4',
      },
      {
        content: <h2>Sidebar 5</h2>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 0,
        placement: 'right',
        target: '.sidebar5',
      },
      {
        content: <h2>Sidebar 6</h2>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 0,
        placement: 'right',
        target: '.sidebar6',
      },
      {
        content: <h2>Sidebar 7</h2>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 0,
        placement: 'right',
        target: '.sidebar7',
      },
      {
        content: <h2>Sidebar 8</h2>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 0,
        placement: 'right',
        target: '.sidebar8',
      },
      {
        content: <h2>Sidebar 9</h2>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 0,
        placement: 'right',
        target: '.sidebar9',
      },
    ],
    tourActive: true,
  };
  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = initialState, action) {
    switch (action.type) {
      case "START_RIDE":
        return {
          ...state,
          run: true
        }
      case "END_RIDE":
        return {
          ...state,
          run: false
        }
      case "INCREASE_STEP_INDEX":
        return {
          ...state,
          stepIndex: state.stepIndex + 1
        }
      case "STEP_INDEX_TO_ZERO":
        return {
          ...state,
          stepIndex: 0
        }
      case "DECREASE_STEP_INDEX":
        return {
          ...state,
          stepIndex: state.stepIndex - 1
        }
      default:
        return state;
    }
  }
import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import { EditIcon } from '../../../assets/Icons/Icons';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { enableFeaturesByOrg } from '../../../utility/utility';
import { ADMIN_UPDATE_AGENT_BUTTON } from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser } from '@fortawesome/free-solid-svg-icons';
class EditUserPopup extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      name: '',
      ppn: '',
      adn: '',
      nameUpdated: false,
      numberUpdated: false,
      empIdUpdated: false,
      emailUpdated: false,
      startTimeUpdated: false,
      endTimeUpdated: false,
      emp_id: '',
      email: '',
      error: '',
      is_webrtc_enabled: false,
    };
    this.initialState = this.state;
  }

  componentDidMount() {
    this.setState({
      name: this.props.name,
      ppn: this.props.userNumber,
      adn: this.props.userDoosraNumber,
      email: this.props.email,
      emp_id: this.props.emp_id,
      startTime: this.props.startTime || '',
      endTime: this.props.endTime || '',
      is_webrtc_enabled: this.props.is_webrtc_enabled || false,
    });
  }

  openPopup = () => {
    this.initialState = { ...this.initialState, ...this.state };
    this.setState(this.initialState);
    this.setState({ popupOpen: true });
  };
  closePopup = () => {
    this.setState({ ...this.initialState });
    this.setState({ popupOpen: false });
  };
  clearCallTime = () => {
    if (this.state.startTime || this.state.endTime) {
      this.setState({ endTimeUpdated: true });
    }
    this.setState({ startTime: '', endTime: '' });
  };
  inputHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    if (e.target.id === 'name') {
      if (e.target.value !== this.props.name) {
        this.setState({ nameUpdated: true });
      } else {
        this.setState({ nameUpdated: false });
      }
    }
    if (e.target.id === 'ppn') {
      if (e.target.value !== this.props.userNumber) {
        this.setState({ numberUpdated: true });
      } else {
        this.setState({ numberUpdated: false });
      }
    }
    if (e.target.id === 'emp_id') {
      if (e.target.value !== this.props.emp_id) {
        this.setState({ empIdUpdated: true });
      } else {
        this.setState({ empIdUpdated: false });
      }
    }
    if (e.target.id === 'startTime') {
      if (e.target.value !== this.props.startTime) {
        this.setState({ startTimeUpdated: true });
      } else {
        this.setState({ startTimeUpdated: false });
      }
    }
    if (e.target.id === 'endTime') {
      if (e.target.value !== this.props.endTime) {
        this.setState({ endTimeUpdated: true });
      } else {
        this.setState({ endTimeUpdated: false });
      }
    }
    if (e.target.id === 'email') {
      if (e.target.value !== this.props.email) {
        this.setState({ emailUpdated: true });
      } else {
        this.setState({ emailUpdated: false });
      }
    }
  };
  checkboxToggleHandler = () => {
    this.setState({ is_webrtc_enabled: !this.state.is_webrtc_enabled });
  };
  updateUserAndClosePopup = (userId) => {
    logPostHogEvent(ADMIN_UPDATE_AGENT_BUTTON);
    const data = {
      name: this.state.name,
      phone_number: this.state.ppn,
      virtual_mobile_number: this.state.adn,
      emp_id: this.state.emp_id,
      email: this.state.email,
      startTime: this.state.startTime,
      endTime: this.state.endTime,
      is_webrtc_enabled: this.props.is_webrtc_enabled,
    };
    if (
      this.props.isWebrtcEnabled ||
      this.props.isCCWebrtcEnabled ||
      this.props.isWebRtcCallSettingEnabled
    ) {
      data.is_webrtc_enabled = true;
    }
    this.props.editUser(userId, data, () => {
      if (this.props.editUserError) {
        this.setState({
          popupOpen: true,
          error: this.props.editUserError,
        });
      } else {
        this.setState({
          ...this.initialState,
          name: this.props.name,
          ppn: this.props.userNumber,
          emp_id: this.props.emp_id,
          startTime: this.props.startTime,
          endTime: this.props.endTime,
          email: this.props.email,
          popupOpen: false,
          is_webrtc_enabled: this.props.is_webrtc_enabled,
        });
      }
    });
  };

  render() {
    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer" style={{ overflow: 'auto' }}>
          <div className="PopupHeaderContainer">
            <p>Edit Agent Info</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupBody">
            {/* {(this.props.isWebrtcEnabled || this.props.isCCWebrtcEnabled || this.props.isWebRtcCallSettingEnabled) && (
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">WEBRTC</label>
                <input
                  type="checkbox"
                  id="is_webrtc_enabled"
                  checked={this.state.is_webrtc_enabled}
                  value={this.state.is_webrtc_enabled}
                  onChange={this.checkboxToggleHandler}
                  style={{ marginLeft: '10px' }}
                />
              </div>
            )} */}
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Employee Id</label>
              <input
                className="PopupInput"
                id="emp_id"
                value={this.state.emp_id}
                onChange={this.inputHandler}
              />
            </div>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Name</label>
              <input
                className="PopupInput"
                id="name"
                value={this.state.name}
                onChange={this.inputHandler}
              />
            </div>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Personal Phone Number</label>
              <input
                className="PopupInput"
                id="ppn"
                value={this.state.ppn}
                onChange={this.inputHandler}
              />
            </div>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Assigned Virtual Number</label>
              <input
                className="PopupInput ReadOnlyInput"
                id="adn"
                readOnly={true}
                value={this.state.adn}
                onChange={this.inputHandler}
              />
            </div>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Email</label>
              <input
                className="PopupInput"
                id="email"
                value={this.state.email}
                onChange={this.inputHandler}
              />
            </div>
            {enableFeaturesByOrg() && (
              <>
                <div className="PopupInputContainer">
                  <label className="PopupInputLabel">Start Time</label>
                  <input
                    type="time"
                    className="PopupInput"
                    id="startTime"
                    value={this.state.startTime}
                    onChange={this.inputHandler}
                  />
                </div>
                <div className="PopupInputContainer">
                  <label className="PopupInputLabelForUpdate">End Time</label>
                  <input
                    type="time"
                    className="PopupInput"
                    id="endTime"
                    value={this.state.endTime}
                    onChange={this.inputHandler}
                  />
                </div>
              </>
            )}
            <a
              className="ClearButton"
              onClick={this.clearCallTime}
              style={{ background: 'transparent' }}
            >
              <u> Reset call time </u>
            </a>

            {this.state.error ? (
              <div
                className="ErrorContainer ErrorContainerMargin"
                style={{ margin: 10 }}
              >
                <img
                  alt=""
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
                {this.state.error}
              </div>
            ) : null}

            {/* {
              this.state.nameUpdated ||
                this.state.numberUpdated ||
                this.state.empIdUpdated ||
                this.state.startTimeUpdated ||
                this.state.endTimeUpdated ||
                this.state.emailUpdated ? (
                <button
                  className="ButtonFullWidth"
                  type="submit"
                  onClick={() => this.updateUserAndClosePopup(this.props.userId)}
                >
                  SAVE
                </button>
              ) : (
                <button
                  className="ButtonFullWidth"
                  style={{ cursor: 'default' }}
                >
                  SAVE
                </button>
              )
            } */}
            <button
              className="ButtonFullWidth"
              type="submit"
              onClick={() => this.updateUserAndClosePopup(this.props.userId)}
            >
              SAVE
            </button>
            <button className="ButtonCancelFullWidth" onClick={this.closePopup}>
              CANCEL
            </button>
          </div>
        </div>
      </Popup>
    );

    return (
      <React.Fragment>
        {/* <button
          onClick={this.openPopup}
          className="BlueButton">
          <FontAwesomeIcon icon={faUser} style={{ fontSize: 17 }} />
        </button> */}

        <div style={{ padding: '5px', cursor: 'pointer' }}>
          <EditIcon onClick={this.openPopup} />
        </div>
        {popper}
      </React.Fragment>
    );
  }
}

export default EditUserPopup;

import React from 'react';
import styled from '@emotion/styled';

import '../../../assets/css/maintainancePage.css';
import Maintainance from '../../../assets/images/maintainance.png';

function MaintainancePage(props) {
  return (
    <div className={`${this.props.className}`}>
    <div className="maintainance-container">
      <img src={Maintainance} />
      <h2>We’re down for maintenance</h2>
      <h3>{'We should be back very soon, hold on tight!'}</h3>
    </div>
    </div>
  );
}

export default styled(MaintainancePage)`
.maintainance-container {
  text-align: center;
  padding-top: 200px;
}

.maintainance-container img {
  max-width: 200px;
  margin-bottom: 41px;
}

.maintainance-container h2 {
  color: #33aabb;
  font-size: 32px;
  font-weight: bold;
}

.maintainance-container h3 {
  font-size: 18px;
  color: #00515a;
}
`;

import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import { EditIcon } from '../../../assets/Icons/Icons';
import smsGreen from '../../../assets/images/smsGreen.png';
import styled from '@emotion/styled';
import './styles.css';
import Close from '../../../assets/images/popupClose.png';
import { toast } from 'react-toastify';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import callCommentIcon from '../../../assets/images/callCommentRecoveryX.svg';
const overlayStyle = { background: 'rgba(0,0,0,0.9)' };
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;

export default class AddCallRemarksPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: '',
      comment: '',
      open: false,
      userRole: localStorage.getItem('doosra-biz-user-role'),
    };
    this.initialState = this.state;
  }
  selectTag = (e) => {
    this.setState({
      tag: e.target.value,
    });
  };

  toggle = () => {
    if (this.props.isDisabled) {
      return;
    }
    this.setState({
      open: !this.state.open,
    });
  };

  reset = () => {
    this.setState(this.initialState);
    this.toggle();
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  callTagHandler = (e) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      callTag: this.state.tag,
      callComment: this.state.comment,
      number: this.props.number,
      secondaryNumber: this.props.secondaryNumber,
      campaignId: this.props.campaignId,
    };
    if (data.callTag === '') {
      toast.error('Please select a tag');
      return;
    }
    const callTag = this.state.tag;
    return POST(outGoingUrl + 'v1/campaigns/call-tag', data)
      .then((res) => {
        toast.success('Added Disposition Successfully');
        this.toggle();
        this.setState(this.initialState);
        this.props.handleFetchCampaignDetails(
          this.props.agentId,
          this.props.campaignId
        );
        // this.props.updateCallDispositonTag({callTag, number: this.props.number, secondaryNumber: this.props.secondaryNumber,})
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        this.setState(this.initialState);
        this.toggle();
        return false;
      });
  };

  render() {
    let userRole = localStorage.getItem('doosra-biz-user-role');
    let popper = (
      <Popup
        open={this.state.open}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        {...{ overlayStyle }}
      >
        <div className="AddCallRemarksPopup">
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>Add Disposition</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.reset}
              />
            </div>
            <div className="PopupBody">
              <div className="PopupInputContainer">
                <select
                  className="PopupInput"
                  id="tags"
                  onChange={this.selectTag}
                >
                  <option value={'-'}>Select Disposition</option>
                  {/* <option value={"Interested"}>Interested</option>
               <option value={"Not Interested"}>Not Interested</option>
               <option value={"Call Later"}>Call Later</option>
               <option value={"Switch Off"}>Switch Off</option>
               <option value={"Wrong Number"}>Wrong Number</option>
               <option value={"Call Busy"}>Call Busy</option>
               <option value={"Call Rejected"}>Call Rejected</option>
               <option value={"Ringing/CNP"}>Ringing/CNP</option> */}

                  {this.props.dispositions.length > 0
                    ? this.props.dispositions.map((item) => (
                        <option value={item.dispositon}>
                          {item.dispositon}
                        </option>
                      ))
                    : null}
                  <option value={'Other'}>Other</option>
                </select>
              </div>
            </div>
            {this.state.tag === 'Other' ? (
              <div className="PopupInputContainer">
                <input
                  id="comment"
                  value={this.state.comment}
                  onChange={this.changeHandler}
                  className="PopupInput"
                  placeholder="Enter comment ..."
                />
              </div>
            ) : null}
            {this.state.open === true ? (
              <button
                className="ButtonFullWidth"
                style={{ marginTop: '10px' }}
                type="submit"
                onClick={this.callTagHandler}
              >
                SAVE
              </button>
            ) : (
              <button
                className="ButtonFullWidth"
                style={{ cursor: 'default', marginTop: '10px' }}
              >
                SAVE
              </button>
            )}
            <button className="ButtonCancelFullWidth" onClick={this.reset}>
              CANCEL
            </button>
          </div>
        </div>
      </Popup>
    );
    return (
      <React.Fragment>
        <div style={{ marginTop: '5px' }}>
          {this.props.isDisabled ||
          this.props.agentHandleDisposition ||
          this.props.isButtonDisabled ? (
            <button
              className="callCommentButton"
              style={{
                display: 'flex',
                alignContent: 'center',
                cursor: 'default',
                justifyContent: 'center',
                opacity: 0.5,
              }}
            >
              <img
                alt=""
                src={callCommentIcon}
                style={{ width: '16px', height: '16px' }}
              />
            </button>
          ) : (
            <button
              onClick={this.toggle}
              className="callCommentButton"
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                alt=""
                src={callCommentIcon}
                onClick={this.toggle}
                style={{ width: '16px', height: '16px' }}
              />
            </button>
          )}
        </div>
        {popper}
      </React.Fragment>
    );
  }
}

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import CsvDownloader from 'react-csv-downloader';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET, POST } from '../../../services/HttpRequests';
import {
  ADMIN_UPLOAD_CONTACT_BUTTON,
  ADMIN_UPLOAD_CONTACT_SAVE_BUTTON,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';

const userUrl = process.env.REACT_APP_USER_API;
const organisationId = localStorage.getItem('doosra-biz-organisation-id');
export default function UploadContactPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const usersList = props.usersList || [];
  const [existingNos, setExistingNos] = useState([]);
  const [wrongNos, setWrongNos] = useState(null);
  const [uploadNos, setUploadNos] = useState(null);
  const [dataSaved, setDataSaved] = useState(false);

  const closePopup = () => {
    setPopupOpen(false);
    setSelectedAgent(false);
    setError('');
    setExistingNos([]);
    setWrongNos([]);
    setUploadNos([]);
    setDataSaved(false);
  };
  const saveContacts = async () => {
    logPostHogEvent(ADMIN_UPLOAD_CONTACT_SAVE_BUTTON);
    setError('');
    console.log({ error, csvFile, selectedAgent });
    if (!csvFile) {
      setError('Please select .csv file');
    }
    if (!selectedAgent) {
      setError('Please select Agent');
    }
    if (csvFile && selectedAgent) {
      setLoading(true);
      let formData = new FormData();
      formData.append('organisationId', organisationId);
      formData.append('userId', selectedAgent);
      formData.append('contactFile', csvFile);
      await POST(`${userUrl}v1/user/upload-contacts`, formData, {
        'Content-Type': 'multipart/form-data',
      })
        .then((res) => {
          const data = res?.data?.response?.data;
          const {
            existingPhoneNumbers,
            wrongPhoneNumbers,
            uploadedPhoneNumbers,
          } = data;
          setDataSaved(true);
          setLoading(false);
          // setPopupOpen(false);
          setSelectedAgent(false);
          setCsvFile(null);
          const existingno = [];
          if (existingPhoneNumbers && existingPhoneNumbers.length > 0) {
            existingPhoneNumbers.forEach((no) => {
              existingno.push({ contactNumber: no });
            });
          }
          setExistingNos(existingno);
          const Wno = [];
          if (wrongPhoneNumbers && wrongPhoneNumbers.length > 0) {
            wrongPhoneNumbers.forEach((no) => {
              Wno.push({ contactNumber: no });
            });
          }
          setWrongNos(Wno);
          const Upno = [];
          if (uploadedPhoneNumbers && uploadedPhoneNumbers.length > 0) {
            uploadedPhoneNumbers.forEach((no) => {
              Upno.push({ contactNumber: no });
            });
          }
          setUploadNos(Upno);
          toast.success(`Succesfully Uploded`);
          // props.refreshList();
        })
        .catch((err) => {
          setLoading(false);
          setDataSaved(false);
          console.log('saveContacts:error', { err });
          toast.error(`Not Uploded`);
          setExistingNos([]);
          setWrongNos([]);
          setUploadNos([]);
        });
    }
  };
  console.log(wrongNos);
  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div
        className="PopupContainer"
        style={{
          maxHeight: '600px',
          overflow: 'scroll',
          marginBottom: 30,
        }}
      >
        <div className="PopupHeaderContainer">
          <p>Upload Contacts</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => {
              closePopup();
            }}
          />
        </div>
        <div className="PopupBody">
          {!dataSaved && (
            <>
              <div className="PopupInputContainer" style={{ marginTop: 20 }}>
                <p style={{ margin: 0 }}>Upload CSV</p>
                <input
                  id="csvFile"
                  type="file"
                  accept=".csv"
                  onChange={(e) => {
                    setCsvFile(e.target.files[0]);
                  }}
                />
              </div>
              <div style={{ paddingLeft: 15, paddingTop: 5, width: '150px' }}>
                <CsvDownloader
                  style={{
                    cursor: 'pointer',
                    color: '#5B33FF',
                    textDecoration: 'none',
                  }}
                  filename="uploadContacts"
                  datas={[
                    {
                      contactName: 'name',
                      contactNumber: '8645663599',
                    },
                  ]}
                >
                  <a> Sample CSV file</a>
                </CsvDownloader>
              </div>
              <div className="PopupInputContainer" style={{ marginTop: 25 }}>
                <p style={{ margin: 0 }}>Agents{error}</p>
                <select
                  className="SearchDropdown"
                  id="searchValue"
                  onChange={(e) => setSelectedAgent(e.target.value)}
                  value={selectedAgent}
                  style={{ width: '350px' }}
                >
                  <option>-Select-</option>
                  {usersList.map((item) => (
                    <option value={item.user_id}>{item.zvr_name}</option>
                  ))}
                </select>
              </div>
              <div
                className="PopupInputContainer"
                style={{ marginTop: 30, marginLeft: 0 }}
              >
                <ul>
                  <li>
                    Ensure to input a 10-digit mobile number, without any
                    prefixes.
                  </li>
                  <li>
                    {' '}
                    If uploading multiple numbers for a single contact, input
                    identical contact name for each different number.
                  </li>
                </ul>
              </div>
              {error ? (
                <div className="ErrorContainer ErrorContainerMargin">
                  <img
                    alt=""
                    src={InfoRed}
                    style={{
                      paddingRight: '5px',
                      position: 'relative',
                      top: '-2px',
                    }}
                  />
                  {error}
                </div>
              ) : null}
            </>
          )}
          {loading && <PreLoader />}
          {dataSaved && (
            <>
              <hr />
              <div className="PopupInputContainer">
                <b>Upload Summary</b>
              </div>
            </>
          )}
          <div
            style={{
              paddingLeft: 15,
              paddingTop: 5,
              width: '250px',
              display: 'flex',
            }}
          >
            {existingNos?.length > 0 ? (
              <>
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{ marginTop: 5, fontSize: 14 }}
                />
                <CsvDownloader
                  style={{
                    cursor: 'pointer',
                    color: '#5B33FF',
                    textDecoration: 'none',
                    paddingLeft: 5,
                  }}
                  filename="existing_contact_nos"
                  datas={existingNos}
                >
                  <a> Existing Numbers</a>
                </CsvDownloader>
              </>
            ) : (
              dataSaved && <div>No Existing Numbers</div>
            )}
          </div>

          <div
            style={{
              paddingLeft: 15,
              paddingTop: 5,
              width: '250px',
              display: 'flex',
            }}
          >
            {wrongNos?.length > 0 ? (
              <>
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{ marginTop: 5, fontSize: 14 }}
                />
                <CsvDownloader
                  style={{
                    cursor: 'pointer',
                    color: '#5B33FF',
                    textDecoration: 'none',
                    paddingLeft: 5,
                  }}
                  filename="wrong_contact_nos"
                  datas={wrongNos}
                >
                  <a>Wrong Numbers</a>
                </CsvDownloader>
              </>
            ) : (
              dataSaved && <div>No Wrong Numbers</div>
            )}
          </div>
          <div
            style={{
              paddingLeft: 15,
              paddingTop: 5,
              width: '250px',
              display: 'flex',
            }}
          >
            {uploadNos?.length > 0 ? (
              <>
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{ marginTop: 5, fontSize: 14 }}
                />
                <CsvDownloader
                  style={{
                    cursor: 'pointer',
                    color: '#5B33FF',
                    textDecoration: 'none',
                    paddingLeft: 5,
                  }}
                  filename="uploded_contact_nos"
                  datas={uploadNos}
                >
                  <a> Uploaded Numbers</a>
                </CsvDownloader>
              </>
            ) : (
              dataSaved && <div>Not Uploaded Numbers</div>
            )}
          </div>
          {!dataSaved && (
            <button
              className="ButtonFullWidth BgGreen"
              style={{ marginTop: 30 }}
              onClick={() => {
                saveContacts();
              }}
            >
              SAVE
            </button>
          )}

          <button
            className="ButtonCancelFullWidth"
            onClick={() => {
              closePopup();
            }}
          >
            CLOSE
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex' }}>
      <span>
        <button
          className="Otherbutton"
          style={{ marginRight: 0 }}
          onClick={() => {
            logPostHogEvent(ADMIN_UPLOAD_CONTACT_BUTTON);
            setPopupOpen(true);
          }}
        >
          UPLOAD
        </button>
        {popper}
      </span>
    </div>
  );
}

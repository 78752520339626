/* eslint-disable */
import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { POST, GET } from '../../../services/HttpRequests';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import downloadIcon from '../../../assets/images/DownloadButton.svg';
import '../../../assets/css/SearchBar.css';
import './styles.css';
import { isSuperAdminWithPermission } from '../../../utility/utility';
const userUrl = process.env.REACT_APP_USER_API;
const URL = process.env.REACT_APP_SAMPLE_FILE_URL;

export default function DiscreteCallPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [error, setError] = useState(null);
  const [destinationNumber, setDestinationNumber] = useState('');
  const [name, setName] = useState('');
  const [isLeadListIndependentCall, setIsLeadListIndependentCall] =
    useState(true);
  const userRole = localStorage.getItem('doosra-biz-user-role');

  function reset() {
    setDestinationNumber('');
  }

  function changeDestinationNumber(value) {
    setDestinationNumber(value);
  }

  const addLeadListHandler = () => {
    if (!destinationNumber) {
      toast.error('Destination Number is required');
      return;
    }
    if (destinationNumber.length !== 10) {
      toast.error('Destination Number Should be 10 digits');
      return;
    }
    if (isNaN(destinationNumber)) {
      toast.error('Please enter a valid number');
      return;
    }
    props.initiateRecoveryCall({
      destinationNumber,
      name,
      isLeadListIndependentCall,
    });
    setPopupOpen(false);
    reset();
  };

  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="PopupContainer" style={{ overflow: 'auto' }}>
        <div className="PopupHeaderContainer">
          <p>Place an outgoing call</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => {
              setPopupOpen(false);
              reset();
            }}
          />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Enter Destination Number</label>
            <input
              className="PopupInput"
              id="destinationNumber"
              value={destinationNumber}
              onChange={(e) => changeDestinationNumber(e.target.value)}
            />
          </div>
          <div className="ErrorContainer ErrorContainerMargin">
            {error ? (
              <React.Fragment>
                <img
                  alt=""
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
                {error}
              </React.Fragment>
            ) : null}
          </div>
          {popupOpen === true ? (
            <button
              className="ButtonFullWidth"
              style={{ marginTop: '10px' }}
              type="submit"
              onClick={addLeadListHandler}
            >
              CALL
            </button>
          ) : (
            <button
              className="ButtonFullWidth"
              style={{ cursor: 'default', marginTop: '10px' }}
            >
              CALL
            </button>
          )}
        </div>
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex' }}>
      <span>
        <button
          className="Otherbutton"
          disabled={
            !isSuperAdminWithPermission('ADD') ||
            (props.selectedAgentStatus !== 'active' && props.agentMetric)
          }
          style={{
            marginRight: 0,
            backgroundColor:
              props.selectedAgentStatus !== 'active' && props.agentMetric
                ? 'lightgrey'
                : '#07aa3f',
          }}
          onClick={() => {
            setPopupOpen(true);
          }}
        >
          PLACE A CALL
        </button>
        {popper}
      </span>
    </div>
  );
}

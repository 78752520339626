/* eslint-disable */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import EditIcon from '../../../assets/images/editWBlueBackground.png';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { POST, GET } from '../../../services/HttpRequests';
import '../../../assets/css/SearchBar.css';
import './styles.css';
const URL = process.env.REACT_APP_SAMPLE_FILE_URL;

export default function EditLeadPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [error, setError] = useState(null);
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [isLeadListIndependentCall, setIsLeadListIndependentCall] =
    useState(true);
  const userRole = localStorage.getItem('doosra-biz-user-role');

  function reset() {
    setNumber('');
  }

  function changeSecondaryNumber(value) {
    setNumber(value);
  }

  const addLeadHandler = () => {
    if (!number) {
      toast.error('Destination Number is required');
      return;
    }
    if (number.length !== 10) {
      toast.error('Destination Number Should be 10 digits');
      return;
    }
    if (isNaN(number)) {
      toast.error('Please enter a valid number');
      return;
    }
    let primaryNumber = props.primaryNumber;
    props.addSecondaryNumber({
      primaryNumber,
      number,
    });
    setPopupOpen(false);
    reset();
  };

  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="PopupContainer" style={{ overflow: 'auto' }}>
        <div className="PopupHeaderContainer">
          <p>Add Secondary Number</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => {
              setPopupOpen(false);
              reset();
            }}
          />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Enter Secondary Number</label>
            <input
              className="PopupInput"
              id="number"
              value={number}
              onChange={(e) => changeSecondaryNumber(e.target.value)}
            />
          </div>
          <div className="ErrorContainer ErrorContainerMargin">
            {error ? (
              <React.Fragment>
                <img
                  alt=""
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
                {error}
              </React.Fragment>
            ) : null}
          </div>
          {popupOpen === true ? (
            <button
              className="ButtonFullWidth"
              style={{ marginTop: '10px' }}
              type="submit"
              onClick={addLeadHandler}
            >
              ADD
            </button>
          ) : (
            <button
              className="ButtonFullWidth"
              style={{ cursor: 'default', marginTop: '10px' }}
            >
              ADD
            </button>
          )}
        </div>
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex' }}>
      <span>
        {props.isDisabled ? (
          <img
            src={EditIcon}
            alt=""
            style={{
              width: '32px',
              height: '32px',
              opacity: '0.5',
              marginTop: '5px',
            }}
          ></img>
        ) : (
          <img
            src={EditIcon}
            alt=""
            style={{ width: '32px', height: '32px', marginTop: '5px' }}
            onClick={() => {
              setPopupOpen(true);
            }}
          ></img>
        )}

        {popper}
      </span>
    </div>
  );
}


const initialState = {
  outGoingCallsTableHeaders: ["OUTGOING NUMBER",
  "DESTINATION",
  "CONTACT NAME",
  "DISPOSITION",
  "TIME",
  "DATE"],
  campaignId: "",
  userDetails: "",
  callStatus: "",
  position: "",
  autoCalling: "",
  batchCallId: "",
  callId: "",
  singleCallStatus: false,
  destinationNumber: "",
  destinationName: "",
  RTCSession: null,
  callFilterLeads: [],
  RTCcallStatus: "Waiting For Calls",
  headers: [],
  isWebRtcConnected: false,
  dialerCallId: "",
  isSingleCall: false,
  dispositionStatus: false,
  agentActiveStatus: false,
  webRTCSessions: [],
  domainsOfCampaign: [],
  callType: '',
  callLogsTableHeaders: ["INITIATOR NAME", "INITIATOR NUMBER", "DESTINATION NUMBER", "CALL STATUS", "DATE", "DISPOSITION"],
  campaignCallHistoryTableHeaders: ["AGENT", "LEAD NAME", "LEAD NUMBER", "CALL STATUS","DATE" , "DISPOSITION", "CALL RECORDING"],
  campaignCallsTableHeaders: [
    "LEAD NAME", 
    "LEAD NUMBER",
    "LAST CALL STATUS",
    "LAST CALL DURATION",
    "HISTORY"
  ],
  agentCampaignCallsTableHeaders: ["LEAD NAME", "LEAD NUMBER", "LAST CALL STATUS", "LAST CALL DURATION", "DATE", "DISPOSITION"],
  staticCampaignTableHeaders: ["name", "number"],
  dynamicCampaignTableHeaders: ["name", "number"],
  agentStatus: "Offline",
  campaignTableHeaders: ["CAMPAIGN NAME", "DESCRIPTION", "LEADS", "CONNECTED CALLS"],
  isAgentMarkedAsUnAvailableByAdmin: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_OUTGOING_CALLS_TABLE_HEADERS":
      return {
        ...state,
        outGoingCallsTableHeaders: action.payload.outGoingCallsTableHeaders,
      }
    case "SET_CALL_LOGS_TABLE_HEADERS":
      return {
        ...state,
        callLogsTableHeaders: action.payload.callLogsTableHeaders
      }
    case "SET_CAMPAIGN_CALL_HISTORY_TABLE_HEADERS":
      return {
        ...state,
        campaignCallHistoryTableHeaders: action.payload.campaignCallHistoryTableHeaders
      }
    case "SET_IS_AGENT_MARKED_AS_UN_AVAILABLE_BY_ADMIN":
      return {
        ...state,
        isAgentMarkedAsUnAvailableByAdmin: action.payload.isAgentMarkedAsUnAvailableByAdmin,
      }
    case "SET_CAMPAIGN_CALLS_TABLE_HEADERS":
      return {
        ...state,
        campaignCallsTableHeaders: action.payload.campaignCallsTableHeaders
      }
    case "SET_AGENT_CAMPAIGN_CALLS_TABLE_HEADERS":
      return {
        ...state,
        agentCampaignCallsTableHeaders: action.payload.agentCampaignCallsTableHeaders
      }
    case "SET_STATIC_CAMPAIGN_TABLE_HEADERS":
      return {
        ...state,
        staticCampaignTableHeaders: action.payload.staticCampaignTableHeaders,
      }
    case "SET_DYNAMIC_CAMPAIGN_TABLE_HEADERS":
      return {
        ...state,
        dynamicCampaignTableHeaders: action.payload.dynamicCampaignTableHeaders,
      }
    case "SET_CAMPAIGNS_TABLE_HEADERS":
      return {
        ...state,
        campaignTableHeaders: action.payload.campaignTableHeaders,
      }
    case "SET_CALL_TYPE":
      return {
        ...state,
        callType: action.payload.callType
      }
    case "SET_CAMPAIGN_ID":
      return {
        ...state,
        campaignId: action.payload.campaignId
      }
    case "SET_CALL_DETAILS":
      return {
        ...state,
        userDetails: action.payload.userDetails,
        callStatus: action.payload.status,
        position: action.payload.position,
      };
    case "SET_BATCH_CALL_ID":
      return {
        ...state,
        batchCallId: action.payload.batchCallId,
      };
    case "SET_CALL_ID":
      return {
        ...state,
        callId: action.payload.callId,
      }
    case "SET_CALL_STATUS":
      return {
        ...state,
        callStatus: action.payload.status,
      }
    case "TOGGLE_AUTO_CALLING":
      return {
        ...state,
        autoCalling: action.payload.autoCalling,
      }
    case "SET_AUTO_CALLING":
      return {
        ...state,
        autoCalling: action.payload.autoCalling,
      }
    case "AGENT_ACTIVE_STATUS":
      return {
        ...state,
        agentActiveStatus: action.payload.status
      }
    case "SET_WEBRTC_CONN":
      return {
        ...state,
        isWebRtcConnected: action.payload.status
      }
    case "DISPOSITION_STATUS":
      return {
        ...state,
        dispositionStatus: action.payload.status
      }
    case "SET_IS_SINGLE_CALL":
      return {
        ...state,
        isSingleCall: action.payload.status
      }
    case "SET_DIALER_CALLID":
      return {
        ...state,
        dialerCallId: action.payload.id
      }
    case "SINGLE_CALL_STATUS":
      return {
        ...state,
        singleCallStatus: action.payload.singleCallStatus,
      }
    case "SET_DESTINATION_NUMBER":
      return {
        ...state,
        destinationNumber: action.payload.destinationNumber,
      }
    case "SET_DESTINATION_NAME":
      return {
        ...state,
        destinationName: action.payload.destinationName,
      }
    case "SET_RTC_SESSION":
      return {
        ...state,
        RTCSession: action.payload.RTCSession,
      }
    case "SET_CALL_FILTER_LEADS":
      return {
        ...state,
        callFilterLeads: action.payload.callFilterLeads,
      }
    case "SET_RTC_CALL_STATUS":
      return {
        ...state,
        RTCcallStatus: action.payload.RTCcallStatus,
      }
    case "SET_HEADERS":
      return {
        ...state,
        headers: action.payload.headers,
      }
    case "SET_WEBRTC_CONN":
      return {
        ...state,
        isWebRtcConnected: action.payload.status
      }
    case "SET_DIALER_CALLID":
      return {
        ...state,
        dialerCallId: action.payload.id
      }
    case "SET_IS_SINGLE_CALL":
      return {
        ...state,
        isSingleCall: action.payload.status
      }
    case "DISPOSITION_STATUS":
      return {
        ...state,
        dispositionStatus: action.payload.status
      }
    case "AGENT_ACTIVE_STATUS":
      return {
        ...state,
        agentActiveStatus: action.payload.status
      }
    case "SET_WEBRTC_SESSIONS":
      return {
        ...state,
        webRTCSessions: action.payload.sessions
      }
    case "SET_DOMAINS_OF_CAMPAIGN":
      return {
        ...state,
        domainsOfCampaign: action.payload.domainsOfCampaign
      }
    
    case "SET_AGENT_STATUS":
      return {
        ...state,
        agentStatus: action.payload.agentStatus
      }
    default:
      return state;
  }
}
